/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { FormInput } from "components/inputs";
import { GenderSelect, ProjectSelect, RegionSelect } from "components/select";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Icon, Image, Text } from "organic";
import { appGet } from "utils";
import { toast } from "react-toastify";
import CountrySelect2 from "components/select/countrySelect2";
import StateSelect2 from "components/select/stateSelect2";
import LGASelect2 from "components/select/lgaSelect2";
import { useUploadImage } from "hooks/app/farmer/useUploadImage";
import { handleFormSubmission } from "services/formSubmissionService";
import ProjectSelect2 from "components/select/projectSelect2";
import RegionSelect2 from "components/select/regionSelect2";

const IconWrapper = styled.div`
  transform: translateY(2px);
`;

const BasicInformationForm = ({
  handleSubmit,
  hasSubmitButton,
  submitButtonText,
  isMemberFarmer,
  setIsSectionValid,
  basicInfo,
  setBasicInfo,
  avatarUrl,
  setAvatarUrl,
  selectedFarmer,
  isSubmitting = false,
}) => {
  const basicInformationValidationSchema = Yup.object({
    seasonId: isMemberFarmer ? Yup.string() : Yup.string().required("Required"),
    region: isMemberFarmer ? Yup.string() : Yup.string().required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    middle_name: Yup.string(),
    phone_number: isMemberFarmer
      ? Yup.string()
          .matches(/^[0-9]+$/, "Must be a number")
          .min(11, "Phone number must be at least 11 digits")
          .required("Required")
      : Yup.string()
          .matches(/^[0-9]+$/, "Must be a number")
          .min(11, "Phone number must be at least 11 digits"),
    email: !isMemberFarmer
      ? Yup.string().email("Please enter a valid email address").required("Required")
      : Yup.string().email("Please enter a valid email address"),
    gender: Yup.string().required("Required"),
    age: Yup.number()
      .typeError("Please enter a valid number")
      .required("This field is required")
      .min(1, "Number must be greater than 0"),
    country: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    lga: Yup.string().required("Required"),
    district: Yup.string().required("Required"),
    address: isMemberFarmer ? Yup.string() : Yup.string().required("Required"),
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (avatarUrl) {
      setImageUrl(avatarUrl);
    }
  }, [avatarUrl]);

  useEffect(() => {
    if (selectedFarmer) {
      setBasicInfo({
        first_name: selectedFarmer?.first_name,
        last_name: selectedFarmer?.last_name,
        middle_name: selectedFarmer?.middle_name,
        phone_number: selectedFarmer?.phone_number || "",
        email: selectedFarmer?.email || "",
        gender: selectedFarmer?.gender,
        age: selectedFarmer?.age,
        country: selectedFarmer?.country,
        state: selectedFarmer?.state,
        lga: selectedFarmer?.lga,
        village: selectedFarmer?.village || "",
        district: selectedFarmer?.district,
        address: selectedFarmer?.address || "",
      });
    }
  }, [selectedFarmer, setBasicInfo]);

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file && file.size > 1024 * 1024) {
      // Display toast message if file size exceeds 2MB
      toast.error("File size should not exceed 1MB");
      return;
    }
    setSelectedFile(file);
    setImageUrl(""); // Reset imageUrl when a new file is selected
    setUploadProgress(0); // Reset upload progress when a new file is selected

    // Preview the image
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onUploadProgress = progressEvent => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress(percentCompleted);
  };

  const handleSuccess = url => {
    setAvatarUrl(url);
  };

  const { mutateAsync, isLoading } = useUploadImage(onUploadProgress, handleSuccess);

  const uploadImage = async file => {
    const formData = new FormData();
    formData.append("images", file);

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: formData,
      config: {},
      errorMsg: "Failed to upload image",
      successMsg: "",
      onSuccess() {},
      onError(error) {
        console.log({ error });
      },
    });
  };

  useEffect(() => {
    if (selectedFile) {
      uploadImage(selectedFile);
    }
  }, [selectedFile]);

  return (
    <>
      <Formik
        validationSchema={basicInformationValidationSchema}
        initialValues={
          basicInfo
            ? basicInfo
            : {
                first_name: "",
                last_name: "",
                middle_name: "",
                phone_number: "",
                email: "",
                gender: "",
                age: 0,
                country: "",
                state: "",
                lga: "",
                district: "",
              }
        }
        onSubmit={values => {
          handleSubmit ? handleSubmit(values) : null;
        }}
        enableReinitialize
        validateOnMount
      >
        {({ setFieldValue, values, isValid, touched }) => {
          useEffect(() => {
            if (touched) {
              setIsSectionValid?.(isValid);
            }
          }, [isValid, touched]);

          useEffect(() => {
            setBasicInfo?.(values);
          }, [values]);

          return (
            <>
              <Form>
                <Box mx={1} my={1}>
                  <Flex flexDirection="column" gap="1rem">
                    {!isMemberFarmer && (
                      <Flex alignItems="center" flexDirection="column">
                        <Flex flexDirection="column" alignItems="center" gap="1rem">
                          {!imageUrl && (
                            <Flex
                              position="relative"
                              alignItems="center"
                              justifyContent="center"
                              width="120px"
                              height="120px"
                              borderRadius="50%"
                              border="2px dashed #D0D5DD"
                              background="#F4F4F4"
                            >
                              <Icon icon="user" size="42px" color="#A6A6A6" />
                              <Flex
                                position="absolute"
                                bottom="-10px"
                                alignItems="center"
                                justifyContent="center"
                                background="#2B2B2B"
                                borderRadius="50%"
                                width="30px"
                                height="30px"
                              >
                                <IconWrapper>
                                  <Icon icon="camera" size="19px" color="#A6A6A6" />
                                </IconWrapper>
                              </Flex>
                            </Flex>
                          )}
                          {imageUrl && (
                            <Flex
                              position="relative"
                              alignItems="center"
                              justifyContent="center"
                              width="120px"
                              height="120px"
                              borderRadius="50%"
                              border="2px dashed #D0D5DD"
                              background="#F4F4F4"
                            >
                              <Box width="100%" height="100%">
                                <Image
                                  src={imageUrl}
                                  // onClick={handleClick}
                                  borderRadius="50%"
                                  size="120px"
                                  alt="Farmer image"
                                />
                              </Box>
                            </Flex>
                          )}
                          <label htmlFor="farmer_image">
                            <Box display="none">
                              <input
                                type="file"
                                name="farmer_image"
                                id="farmer_image"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={e => {
                                  isLoading ? null : handleFileChange(e);
                                }}
                              />
                            </Box>
                            <Box background="#ECFFE5" borderRadius="1px" py="10px" px="32px" cursor="pointer">
                              <Text font="smSemibold" color="primary700">
                                {`${isLoading ? "Uploading..." : "Upload Image"}`}
                              </Text>
                            </Box>
                          </label>
                        </Flex>
                      </Flex>
                    )}
                    {!isMemberFarmer && (
                      <>
                        <Box>
                          {/* <ProjectSelect
                            name="seasonId"
                            label="Season"
                            onChange={val => {
                              setFieldValue("seasonId", val);
                            }}
                            defaultValue={values.seasonId}
                            ignoreGlobalState
                          /> */}
                          <ProjectSelect2 name="seasonId" label="Project" placeholder="Select Project" required />
                        </Box>
                        <Box>
                          <RegionSelect2 name="region" label="Choose by Region" placeholder="Select Region" required />
                          {/* <RegionSelect
                            name="regions"
                            label="Choose by Region"
                            required
                            onChange={val => setFieldValue("region", val)}
                            colorScheme="default"
                            value={values.region}
                            // selectMultiple
                          /> */}
                        </Box>
                      </>
                    )}
                    <Box>
                      <FormInput label="First Name" name="first_name" />
                    </Box>
                    <Box>
                      <FormInput label="Last Name" name="last_name" />
                    </Box>
                    <Box>
                      <FormInput label="Middle Name" name="middle_name" />
                    </Box>
                    {isMemberFarmer && (
                      <Box>
                        <FormInput label="Phone Number" name="phone_number" />
                      </Box>
                    )}
                    <Box>
                      <FormInput label="Email Address" name="email" />
                    </Box>
                    <Box>
                      <GenderSelect
                        label="Gender"
                        onChange={value => {
                          setFieldValue("gender", value);
                        }}
                        value={appGet(values, "gender")}
                        dependencies={[values]}
                      />
                    </Box>
                    <Box>
                      <FormInput name="age" label="Age" type="number" />
                    </Box>
                    <Box>
                      <CountrySelect2
                        name="country"
                        stateFieldName="state"
                        lgaFieldName="lga"
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                    <Box>
                      <StateSelect2
                        name="state"
                        lgaFieldName="lga"
                        setFieldValue={setFieldValue}
                        selectedCountry={values.country}
                      />
                    </Box>
                    <Box>
                      <LGASelect2 name="lga" selectedCountry={values.country} selectedState={values.state} />
                    </Box>
                    {/* <Box>
                      <FormInput name="village" label="Village" />
                    </Box> */}
                    <Box>
                      <FormInput name="district" label="District" />
                    </Box>
                    {!isMemberFarmer && (
                      <Box>
                        <FormInput name="address" label="Address" />
                      </Box>
                    )}
                  </Flex>
                  {hasSubmitButton && (
                    <Button mt={10} display="block" type="submit" isDisabled={!isValid || isSubmitting}>
                      {submitButtonText}
                    </Button>
                  )}
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default BasicInformationForm;
