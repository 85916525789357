import { FormInput } from "components/inputs";
import { GenderSelect, LGASelect, RegionSelect, RoleSelect, StateSelect } from "components/select";
import FormGridTitle from "components/v1/Forms/FormGridTitle";
import FarmerAvatar from "components/v1/common/FarmerAvatar";
import { editAdminDetails, getAllAdmins } from "features/v1/auth";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import React from "react";
import { useDispatch } from "react-redux";
import { formatDate } from "utils";

const EditMemberDetails = ({ modal, selected }) => {
  // const [addMiddleName, setAddMiddleName] = useState(false);
  const dispatch = useDispatch();

  const handleAdminEdit = (values, formikOptions) => {
    // FIXME: restrict with useRole
    dispatch(
      editAdminDetails(values, {
        formikOptions,
        modal,
        refreshData: getAllAdmins,
      })
    );
  };

  return (
    <Formik
      initialValues={{
        ...selected,
      }}
      onSubmit={handleAdminEdit}
    >
      {({ isSubmitting, values, errors, setFieldValue, handleChange }) => (
        <Form>
          <Box>{errors.message}</Box>
          <Flex>
            <Box>
              <FarmerAvatar avatar_url={values?.avatar_url} />
            </Box>
          </Flex>
          <FormGridTitle title="User Details" />
          <Flex>
            <Box>
              <FormInput
                name="created_at"
                label="Onboarded On"
                type="text"
                readOnly
                disabled
                value={formatDate(values?.created_at)}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <FormInput name="email" type="email" label="Email" value={values.email} onChange={handleChange} />
            </Box>
          </Flex>
          <Flex>
            <Box>
              <FormInput label="First Name" name="first_name" value={values?.first_name} onChange={handleChange} />
            </Box>
            <Box>
              <FormInput label="Middle Name" name="middle_name" value={values?.middle_name} onChange={handleChange} />
            </Box>
            <Box>
              <FormInput name="last_name" label="Last Name" value={values.last_name} onChange={handleChange} />
            </Box>
          </Flex>
          <Flex>
            <Box>
              <FormInput
                name="phone_number"
                type="tel"
                label="Phone Number"
                value={values.phone_number}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <FormInput
                name="phone_number_2"
                type="tel"
                label="Secondary Phone Number"
                value={values.phone_number_2}
                onChange={handleChange}
              />
            </Box>
          </Flex>
          <Flex>
            <Box>
              <GenderSelect label="Gender" value={values.gender} onChange={value => setFieldValue("gender", value)} />
            </Box>
            <Box>
              <RoleSelect defaultValue={values?.role || ""} onChange={val => setFieldValue("role", val)} />
            </Box>
          </Flex>
          <Flex>
            <Box>
              <StateSelect label="State" value={values?.state} onChange={val => setFieldValue("state", val)} />
            </Box>
            <Box>
              <LGASelect
                state={values?.state}
                label="LGA"
                value={values?.lga}
                onChange={val => setFieldValue("lga", val)}
              />
            </Box>
          </Flex>
          <Flex>
            <Box>
              <RegionSelect label="Agent" value={values.region} onChange={val => setFieldValue("region", val)} />
            </Box>
            <Box>
              <FormInput label="Password" type="password" name="siloId" value={values.siloId} disabled />
            </Box>
          </Flex>
          <Flex>
            <Box>
              <Button type="submit" disabled={isSubmitting}>
                Save Member Details
              </Button>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default EditMemberDetails;
