/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { Avatar, Badge, Box, Button, Flex, Icon, Text, badgeConstants, organicTheme } from "organic";
import { format } from "date-fns";
import { useGetAllContractualMembers } from "hooks/app/farmer";
import { debounce } from "utils";

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eaecf0;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 16px 16px 16px 42px;
  font-size: 16px;
  flex: 1;
`;

const SearchButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const AddExistingFarmersModal = ({ handleSubmit }) => {
  const { data: allContractualMembers } = useGetAllContractualMembers({ params: {} });
  const [searchText, setSearchText] = useState("");
  const [existingFarmers, setExistingFarmers] = useState([]);
  const [selectedValue, setSelectedValue] = useState("option1");

  useEffect(() => {
    if (allContractualMembers.length) {
      const results = allContractualMembers.filter(cf => cf.isContractual);
      setExistingFarmers(results);
      if (results.length) {
        setSelectedValue(results[0].farmer_id);
      }
    }
  }, [allContractualMembers]);

  const selected = existingFarmers?.filter(farmer => farmer.checked);

  const handleSearchMembers = query => {
    const results = allContractualMembers
      .filter(cf => cf.isContractual)
      .filter(
        cf =>
          cf.first_name.toLowerCase().includes(query.toLowerCase()) ||
          cf.last_name.toLowerCase().includes(query.toLowerCase())
      );
    setExistingFarmers(results);
    if (results.length) {
      setSelectedValue(results[0].farmer_id);
    }
  };

  const debouncedHandleSearch = useCallback(debounce(handleSearchMembers, 1500), []);

  const handleRadioChange = value => {
    setSelectedValue(value);
  };

  return (
    <Box>
      <InputContainer>
        <SearchIconContainer>
          <Icon icon="search" color={organicTheme.colors.gray500} size={20} />
        </SearchIconContainer>
        <Input
          type="search"
          value={searchText}
          placeholder="Search"
          onChange={e => {
            setSearchText(e.target.value);
            debouncedHandleSearch(e.target.value);
          }}
        />
      </InputContainer>
      {/* {searchText.length < 4 && (
        <Text ml="16px" as="i" color="red" font="xsMedium" textAlign="center">
          Enter at least 3 characters to search
        </Text>
      )} */}
      <Box p="16px" backgroundColor="white">
        <Text color="gray600" mb="20px" font="smMedium">
          All Members
        </Text>
        {existingFarmers?.length ? (
          <Flex flexDirection="column" gap="20px">
            {existingFarmers.map(farmer => {
              return (
                <Flex as="label" key={uuid()} alignItems="center" justifyContent="space-between">
                  <Flex gap="15px" alignItems="center">
                    <Avatar size="lg" name={`${farmer.first_name.trim()} ${farmer.last_name.trim()}`} />
                    <Flex flexDirection="column" gap="-5px" alignItems="start">
                      <Text font="mdMedium" color="gray900">
                        {`${farmer.first_name} ${farmer.last_name}`}
                      </Text>
                      <Flex gap="10px">
                        <Text font="xsRegular" color="gray700">
                          {format(new Date(), "MM/dd/yyyy")}
                        </Text>
                        <Text font="xsRegular" color="gray700">
                          {format(new Date(), "hh:mm:ss a")}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" gap="12px">
                    <Badge size="lg" colorScheme="gray">
                      Member
                    </Badge>
                    {/* <Box
                    onClick={() => setMemberFarmersList(memberFarmersList.filter(item => item.id !== farmer.id))}
                    cursor="pointer"
                  >
                    <Icon icon="minus-circle" size="17px" color="#D92D20" />
                  </Box> */}
                    <input
                      type="radio"
                      id={farmer.farmer_id}
                      value={farmer.farmer_id}
                      checked={selectedValue === farmer.farmer_id}
                      onChange={() => {
                        handleRadioChange(farmer.farmer_id);
                      }}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <Text as="i" color="gray600" font="smMedium" textAlign="center">
            No members match your search
          </Text>
        )}

        {selected.length ? (
          <>
            <Box mt="20px">
              <Text color="gray600" font="smMedium">
                Selected
              </Text>
              <Flex mt="15px" gap="8px" flexWrap="wrap">
                {selected.map((s, index) => {
                  return (
                    <Badge key={index} colorScheme={badgeConstants.colorSchemes.SUCCESS}>
                      {s.name}
                    </Badge>
                  );
                })}
              </Flex>
            </Box>
            <Box mt={10}>
              <Button onClick={handleSubmit} display="block" type="button">
                Continue
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default AddExistingFarmersModal;
