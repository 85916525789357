import { useLegacyRole } from "hooks";
import React, { useEffect, useState } from "react";

export const ScopedView = ({ resource, children, permissionRequired = "read" }) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const { canRead, canWrite, canUpdate } = useLegacyRole();

  useEffect(() => {
    switch (permissionRequired) {
      case "read":
        if (canRead.includes(resource) || canRead.includes("all")) {
          setPermissionGranted(true);
        }
        break;
      case "write":
        if (canWrite.includes(resource) || canWrite.includes("all")) {
          setPermissionGranted(true);
        }
        break;
      case "update":
        if (canUpdate.includes(resource) || canUpdate.includes("all")) {
          setPermissionGranted(true);
        }
        break;
      default:
        setPermissionGranted(false);
        break;
    }
  }, [canRead, canUpdate, canWrite, permissionRequired, resource]);

  if (!permissionGranted) {
    return <></>;
  }
  return <>{children}</>;
};
