import axios from "axios";
import { getCurrentAosVersion, getCustomerID } from "./customerService";
import { authUtils } from "utils";

const axiosServiceRequestConfig = {
  url: "",
  method: "",
  headers: "",
  params: "",
  data: "",
  timeout: "",
  timeoutErrorMessage: "",
  signal: "",
  cancelToken: "",
};

const bootstrapRequest = (config = axiosServiceRequestConfig) => {
  const { url, data, signal, params, headers, ...restConfig } = config;

  const customerid = getCustomerID();
  const aosVersion = getCurrentAosVersion();

  const token = authUtils.getToken();

  const defaultHeaders = {
    "x-aos-customerid": customerid,
    "x-aos-version": aosVersion,
    "Access-Control-Allow-Origin": "*",
  };

  if (token) {
    defaultHeaders["Authorization"] = `Bearer ${token}`;
  }

  axios.defaults.headers.post["Content-Type"] = "application/json";

  return {
    headers: { ...defaultHeaders, ...headers },
    url,
    ...(params && { params }),
    ...(signal && { signal }),
    ...(data && { data }),
    ...restConfig,
  };
};

/** return an axios instance with good defaults and interceptors */
const axiosService = async (config = axiosServiceRequestConfig) =>
  await axios({
    ...bootstrapRequest(config),
  });

export { axiosService };
