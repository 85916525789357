import { simpleFaker, faker } from "@faker-js/faker";
import { dateConstants } from "utils";

// {
//   campaign_title: { type: String, required: true, unique: true },
//   sender_id: { type: String, required: true }, // name of organization
//   content: { type: String },
//   size: { type: String },
//   recipient: { type: Array }, // array of strings
//   status: {
//     type: String,
//     enum: ["success", "failed", "processing"],
//     default: "processing"
//   },

export const campaignStatus = {
  success: "success",
  failed: "failed",
  processing: "processing",
};

const messageStatus = {
  processing: "processing",
  sent: "sent",
  delivered: "delivered",
  notSent: "notSent",
};

export const mockSmsCampaign = () => {
  const recipientsPool = simpleFaker.helpers.multiple(() => faker.phone.number(), 1000);
  let messageSize = simpleFaker.number.int({ min: 10, max: 280 });
  let recipientCount = simpleFaker.number.int({ min: 1, max: 500 });
  const campaign = {
    _id: simpleFaker.string.uuid(),
    // campaignId: simpleFaker.helpers.fromRegExp(/TA-[0-9]{6}/),
    campaign_title: faker.helpers.fake("{{word.adjective}} {{commerce.product}}"),
    sender_id: simpleFaker.helpers.arrayElement(["ThriveAOS", "Fida", "MercyCorp"]),
    content: faker.lorem.words(messageSize),
    size: messageSize,
    recipient: simpleFaker.helpers.arrayElements(recipientsPool, recipientCount),
    created_at: simpleFaker.date
      .between({
        from: dateConstants.namedDates.START_OF_THIS_QUARTER,
        to: dateConstants.namedDates.END_OF_THIS_QUARTER,
      })
      .toISOString(),
    status: simpleFaker.helpers.arrayElement(Object.keys(campaignStatus)),
  };

  return campaign;
};

export const mockSmsCampaignOverview = () => {
  const campaignOverview = {
    total: simpleFaker.number.int({ min: 1, max: 1000000 }),
    sent: simpleFaker.number.int({ min: 1, max: 1000000 }),
    delivered: simpleFaker.number.int({ min: 0, max: 100000 }),
  };

  return campaignOverview;
};

export const mockSmsCampaignReport = () => {
  const campaignReport = {
    _id: simpleFaker.string.uuid(),
    phone: faker.phone.number(),
    status: simpleFaker.helpers.arrayElement(Object.keys(messageStatus)),
    created_at: simpleFaker.date
      .between({
        from: dateConstants.namedDates.START_OF_QUARTER_TO_DATE,
        to: dateConstants.namedDates.END_OF_QUARTER_TO_DATE,
      })
      .toISOString(),
    message: faker.lorem.word(240),
  };

  return campaignReport;
};

export const mockSmsCampaignList = ({ count } = { count: 20 }) => {
  return faker.helpers.multiple(mockSmsCampaign, { count });
};

export const mockSmsCampaignMessagesReportList = ({ count } = { count: 20 }) => {
  return faker.helpers.multiple(mockSmsCampaignReport, { count });
};
