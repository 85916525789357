const defaultBvnSurveyQuestions = [
  {
    answer_options: [],
    correct_answer: [],
    question_title: "BVN",
    question_type: "bvn",
    isCompulsory: true,
    keyboardType: "number-pad",
    maxLength: 11,
    minLength: 11,
    key: 0,
  },
];

export default defaultBvnSurveyQuestions;
