import React from "react";
import AreaChart from "./areaChart";
import PieChart from "./pieChart";
import ProgressChart from "./progressChart";
import { Flex } from "organic";

export const chartConstants = {
  variants: {
    AREA_CHART: "area",
    PIE_CHART: "pie",
    PROGRESS_CHART: "progress",
  },
};

const chartVariants = {
  area: AreaChart,
  pie: PieChart,
  progress: ProgressChart,
};
export const CHART_TOP_MARGIN = 26;
const Chart = ({ data, type, height, width, ...otherChartProps }) => {
  const ChartVariant = chartVariants[`${type}`];

  if (!type || !ChartVariant) {
    return (
      <div>
        <h3>no chart type provided</h3>
      </div>
    );
  }

  return (
    <Flex flexDirection={"column"} maxWidth="100%" height={height} marginTop={`${CHART_TOP_MARGIN}px`}>
      <ChartVariant data={data} height={height} width={width} {...otherChartProps} />
    </Flex>
  );
};

export default Chart;
