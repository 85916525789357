import { assignAliasToScale } from "organic/utils/aliases";
import { addUnit, createMediaQuery } from "organic/utils/shared-utils";

export const defaultBreakpointScale = [0, 640, 768, 1024, 1280, 1536];
export const defaultBreakpointAliases = ["xs", "sm", "md", "lg", "xl", "2xl"];
export const defaultBreakpointUnit = "px";
export const defaultBreakpoints = defaultBreakpointScale.map(n => addUnit(n, defaultBreakpointUnit));

const breakpoints = assignAliasToScale(defaultBreakpoints, defaultBreakpointAliases);

export const mediaQueries = breakpoints.map(createMediaQuery);

export default breakpoints;
