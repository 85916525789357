// endpoints to be integrated
// 1. POST admin/contractors/create - create single contractor
// 2. POST admin/contractors/createMany
// 3. GET admin/contractors/allContractors
// 4. GET admin/contractors/contractor - Get contractor by contractor id
// 5. PUT admin/contractors/update

import contractors from "api/contractors";
import { handleActionError } from "services/shared/AOSErrorService";
import { getSubmissionConfigOptions } from "utils/v1/apiUtils";
import { sanitizeMongoDoc } from "utils/v1/sanitize";

/** 
Contrator Model
const contractor = {
            "_id": "63001a3b2c88ab5c086e3481",
            "contractor_bank_details": {
                "bank_name": "European Bank",
                "bank_account_name": "Hauwa Benson",
                "bank_account_number": "1235345362",
                "bank_code": "23401",
                "bvn": "22122123234"
            },
            "organization": "62017d4a048dba00f7185f16",
            "contractor_category": "thresher",
            "contractor_name": "Hauwa Benson",
            "phone_number": "07012343562",
            "contractor_id": "TA000009",
            "created_at": "2022-08-19T23:18:19.177Z"
        }
 */
const SET_ALL_CONTRACTORS = "SET_ALL_CONTRACTORS";
const LOADING_CONTRACTORS = "LOADING_CONTRACTORS";

/** Fetch all Contractors */
export const getAllContractors = () => async dispatch => {
  try {
    dispatch({
      type: LOADING_CONTRACTORS,
      payload: true,
    });
    const response = await contractors.get("allContractors", { ignoreOrgId: true });
    if (response.status === 200) {
      dispatch({
        type: SET_ALL_CONTRACTORS,
        payload: response.data.data,
        allContractorsMeta: response.data?.meta || {},
      });
    }
    dispatch({
      type: LOADING_CONTRACTORS,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_CONTRACTORS,
      payload: false,
    });
    handleActionError(error, "GetAllContractorsError", "Something Went Wrong Fetching Contractors, kindly try again");
  }
};
export const createContractor =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions } = getSubmissionConfigOptions(config);
    try {
      // if (hasFormOptions) {
      //   config.formOptions.setSubmitting(true);
      // }
      dispatch({
        type: LOADING_CONTRACTORS,
        payload: true,
      });
      await contractors.post("createMany", [values], { ignoreOrgId: true });
      dispatch({
        type: LOADING_CONTRACTORS,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      return dispatch(getAllContractors());
    } catch (error) {
      dispatch({
        type: LOADING_CONTRACTORS,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      handleActionError(error, "Create Contractor Error", "Couldn't Create Contractor");
    }
  };
export const createBulkContractors =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions } = getSubmissionConfigOptions(config);
    try {
      // if (hasFormOptions) {
      //   config.formOptions.setSubmitting(true);
      // }
      dispatch({
        type: LOADING_CONTRACTORS,
        payload: true,
      });
      await contractors.post("createMany", values);
      dispatch({
        type: LOADING_CONTRACTORS,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
        config.formOptions.resetForm();
      }
      return dispatch(getAllContractors());
    } catch (error) {
      dispatch({
        type: LOADING_CONTRACTORS,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      handleActionError(error, "Create Contractor Error", "Couldn't Create Contractor");
    }
  };
export const createManyContractors = () => {};
export const updateContractor =
  (values, config = {}) =>
  async dispatch => {
    const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);

    try {
      dispatch({ type: LOADING_CONTRACTORS, payload: true });
      const body = sanitizeMongoDoc(values);
      await contractors.put("update", body);
      dispatch({ type: LOADING_CONTRACTORS, payload: false });
      if (hasModal) {
        config.modal.hideModal();
      }
      return dispatch(getAllContractors());
    } catch (err) {
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      dispatch({ type: LOADING_CONTRACTORS, payload: false });
      handleActionError(err, "Update Contractor Error");
    }
  };
export const deleteContractors = () => dispatch => {
  try {
    dispatch({
      type: LOADING_CONTRACTORS,
      payload: true,
    });
  } catch (err) {
    handleActionError(err, "Delete Contractor Error");
  }
};

const initialState = {
  allContractorsData: [],
  allContractorsMeta: {},
  loadingContractors: false,
};

const contractorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_CONTRACTORS:
      return {
        ...state,
        allContractorsData: action.payload,
        allContractorsMeta: action.allContractorsMeta,
      };
    case LOADING_CONTRACTORS:
      return {
        ...state,
        loadingContractors: action.payload,
      };
    default:
      return state;
  }
};

export const getAllContractorsData = state => state.legacyContractors.allContractorsData;
export const getAllContractorsMeta = state => state.legacyContractors.allContractorsMeta;
export const getLoadingContractors = state => state.legacyContractors.loadingContractors;

export default contractorsReducer;
