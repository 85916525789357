import styled, { css } from "styled-components/macro";
import shouldForwardProp from "@styled-system/should-forward-prop";
import { background, borders, color, layout, position, shadow, space, typography } from "styled-system";
import propTypes from "@styled-system/prop-types";

const Box = styled.div.withConfig({
  shouldForwardProp,
})`
  ${props => css`
    display: block;
    ${background}
    ${color}
    ${typography}
    ${space}
    ${layout}
    ${borders}
    ${position}
    ${shadow}
    ${props?.outline
      ? `
          outline: ${props.outline};
        `
      : ``}
    ${props?.cursor
      ? `
          cursor: ${props.cursor};
        `
      : ``}
  `}
`;

Box.propTypes = {
  ...propTypes.background,
  ...propTypes.color,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.border,
  ...propTypes.position,
  ...propTypes.shadow,
};

export default Box;
