import React from "react";
import { SelectInput, SelectInputOption } from ".";
import { uniqueId } from "utils";

const NumberRangeInput = ({ options = [], onChange, value, defaultValue }) => {
  return (
    <SelectInput value={value} defaultValue={defaultValue} onValueChange={onChange} rounded>
      {options?.map(option => (
        <SelectInputOption value={option} key={uniqueId()}>
          {option}
        </SelectInputOption>
      ))}
    </SelectInput>
  );
};

export { NumberRangeInput };
