import { Tooltip } from "components/modal";
import {
  ParseError,
  isValidPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberWithError,
  validatePhoneNumberLength,
} from "libphonenumber-js";
import { nanoid } from "nanoid";
import numeral from "numeral";
import { Badge, Box, Flex, Text, badgeConstants } from "organic";
import React, { useEffect, useMemo, useState } from "react";
import { isValidPhoneNumberForCountry } from "validators/phoneNumberValidators";

const RecipientsList = ({ recipients = [], onError, countryCode }) => {
  useEffect(() => {
    if (
      recipients.every(recipient => {
        return recipient;
      })
    ) {
      onError?.("Recipients list contains some errors");
    }
  }, [onError, recipients]);

  return (
    <Box maxHeight="360px" overflowY="scroll">
      {recipients?.length ? (
        <>
          <Flex my="1rem">
            <Text font="smMedium">Recipients</Text>
          </Flex>
          <Flex flexWrap="wrap" gap="0.5rem">
            {recipients.map(recipient => {
              let _recipient = `+${recipient}`;
              return (
                <Tooltip
                  content={<RecipientErrorDetails number={_recipient} countryCode={countryCode} />}
                  openOnHover
                  key={nanoid()}
                >
                  <Badge
                    // onMouseEnter={() => {
                    //   setSize(badgeConstants.sizes.LG);
                    // }}
                    // onMouseLeave={() => {
                    //   setSize(badgeConstants.sizes.SM);
                    // }}
                    colorScheme={
                      isValidPhoneNumber(_recipient)
                        ? badgeConstants.colorSchemes.PRIMARY
                        : badgeConstants.colorSchemes.ERROR
                    }
                  >
                    {isNaN(numeral(recipient).format("0")) || !isValidPhoneNumber(_recipient)
                      ? "not a valid phone no"
                      : numeral(recipient).format("0")}
                  </Badge>
                </Tooltip>
              );
            })}
          </Flex>
        </>
      ) : null}
    </Box>
  );
};

const RecipientErrorDetails = ({ number, countryCode }) => {
  const [parseFailed, setParseFailed] = useState(false);
  const [parseErrorMsg, setParseErrorMsg] = useState("");

  const numberInstance = useMemo(() => {
    try {
      const instance = parsePhoneNumber(number, countryCode);
      return instance;
    } catch (error) {
      setParseFailed(true);
      return null;
    }
  }, [countryCode, number]);

  useEffect(() => {
    try {
      parsePhoneNumberWithError(number, countryCode);
      setParseFailed(false);
    } catch (error) {
      if (error instanceof ParseError) {
        setParseFailed(true);
        setParseErrorMsg(error.message);
      }
    }
  }, [countryCode, number]);

  if (parseFailed || !numberInstance) {
    return (
      <Flex flexDirection="column" gap="1rem">
        <Text font="smSemibold" color="error900">
          Unable to procces phone number
        </Text>
        <Text font="xsRegular">
          Reason:&nbsp; &lsquo;{number}&rsquo; {parseErrorMsg}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      gap="1rem"
      backgroundColor={
        numberInstance.isValid() ? badgeConstants.colorSchemes.PRIMARY : badgeConstants.colorSchemes.ERROR
      }
    >
      <Text font="smSemibold">Number: {numberInstance.number}</Text>
      <Flex gap="0.5rem" flexDirection="column">
        <Text font="xsRegular">
          <Text as="span" font="xsSemibold">
            Number Length:&nbsp;
          </Text>
          <Text
            as="span"
            font="xsRegular"
            color={!validatePhoneNumberLength(number, countryCode) ? "success800" : "error800"}
          >
            {validatePhoneNumberLength(number, countryCode) || "Good"}
          </Text>
        </Text>
        <Text font="xsRegular">
          <Text as="span" font="xsSemibold">
            Country Code:&nbsp;
          </Text>
          <Text
            as="span"
            font="xsRegular"
            color={isValidPhoneNumberForCountry(number, countryCode) ? "success800" : "error800"}
          >
            {isValidPhoneNumberForCountry(number, countryCode) ? "Good" : "Not Valid For Selected Campaign Country"}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};

export default RecipientsList;
