import { useCallback, useMemo, useRef, useState } from "react";
import { defaultDataFilterConfig, useDataFilter } from "../app/filter/useDataFilter";
import { useCallbackRef } from "./useCallbackRef";

function useTable(config = defaultTableConfig) {
  const configWithDefaults = { ...defaultTableConfig, ...config };
  const {
    // react-table config props
    data: dataProp = [],
    columns: columnsProp,

    // server pagination config props
    useServerPagination,
    useAlternatePagination,
    serverPaginationMeta,

    // flags
    noActions,
    noEditAction,
    noViewAction,
    noSearch,
    noDateRangeFilter,
    noLocalFilter,
    showCheckboxes,
    canDelete,
    // callbacks
    onFilter,

    // other config props
    title,
    // dataFilterConfig,
  } = configWithDefaults;

  const [selected, setSelected] = useState();
  const [selectedRows, setSelectedRows] = useState();

  const { filterParams, isValidFilter } = useDataFilter();

  const ignoredfilterIdRef = useRef(0);

  const _onFilter = useCallbackRef(
    (...args) => onFilter(...args),
    [filterParams?.region, filterParams?.seasonId, filterParams?.dateRange]
  );

  // row selecthandler
  const onSelect = _selected => {
    setSelected(_selected);
  };

  // multi row select handler
  const onMultiSelect = _selectedRows => {
    setSelectedRows(_selectedRows);
  };

  // handle data filter change including pagination and search changes
  const handleFilter = useCallback(
    (params = {}) => {
      _onFilter({
        region: filterParams?.region,
        seasonId: filterParams?.seasonId,
        dateRange: filterParams?.dateRange,
        ...params,
      });
    },
    [_onFilter, filterParams?.region, filterParams?.seasonId, filterParams?.dateRange]
  );

  // metas that won't change are memoized on mount

  const _isUsingServerPagination = useMemo(() => useServerPagination === true, []);

  const _columns = useMemo(() => columnsProp, []);

  const _useAlternatePagination = useMemo(() => useAlternatePagination, []);

  const _data = useMemo(() => {
    return dataProp;
  }, [dataProp]);

  const _serverPaginatedData = useMemo(() => {
    return dataProp;
  }, [dataProp]);

  // memoized props that can change
  const _title = useMemo(() => title, [title]);
  const _noActions = useMemo(() => noActions, [noActions]);
  const _noEditAction = useMemo(() => noEditAction, [noEditAction]);
  const _noViewAction = useMemo(() => noViewAction, [noViewAction]);
  const _noSearch = useMemo(() => noSearch, [noSearch]);
  const _noDateRangeFilter = useMemo(() => noDateRangeFilter, [noDateRangeFilter]);
  const _noLocalFilter = useMemo(() => noLocalFilter, [noLocalFilter]);
  const _showCheckboxes = useMemo(() => showCheckboxes, [showCheckboxes]);
  const _canDelete = useMemo(() => canDelete, [canDelete]);
  const _serverPaginationMeta = useMemo(() => serverPaginationMeta, [serverPaginationMeta]);

  return {
    tableProps: {
      data: _isUsingServerPagination ? _serverPaginatedData : _data,
      columns: _columns,
      title: _title,
      serverPaginationMeta: _serverPaginationMeta,
      useServerPagination: _isUsingServerPagination, //deprecated bad naming choice (a hook with same name now exists)
      isServerPaginated: _isUsingServerPagination,
      useAlternatePagination: _useAlternatePagination,
      onSelect,
      onMultiSelect,
      onFilter: handleFilter,
      noActions: _noActions,
      noEditAction: _noEditAction,
      noViewAction: _noViewAction,
      noSearch: _noSearch,
      noDateRangeFilter: _noDateRangeFilter,
      noLocalFilter: _noLocalFilter,
      showCheckboxes: _showCheckboxes,
      canDelete: _canDelete,
      filterParams,
      isValidFilter,
    },
    selectedRow: selected,
    multiSeletedRows: selectedRows,
  };
}

const defaultTableConfig = {
  // react-table config props
  data: [],
  columns: [],

  // server pagination config props
  useServerPagination: false,
  useAlternatePagination: false,
  serverPaginationMeta: {},

  // flags
  noActions: false,
  noEditAction: false,
  noViewAction: false,
  noSearch: false,
  noDateRangeFilter: false,
  noLocalFilter: false,
  showCheckboxes: false,
  canDelete: false,
  // callbacks
  onFilter(__params) {},

  // other config props
  title: "Table",
  dataFilterConfig: defaultDataFilterConfig,
};

export { useTable };
