import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/accordion";
import { EmptyState } from "components/appState";
import { FormInput } from "components/inputs";
import { getCropOptions } from "features/v1/cropsAndInput";
import { updateQuestionnaireQuestions } from "features/v1/questionnaire";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Input } from "organic";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { hyphenate } from "utils";
import { initialiseQuestionnaireForMarketplace } from "utils/questionnaire-utils/questionnaire-utils";

const UpdateMarketplaceSurveyPrices = ({ selected, modal }) => {
  const dispatch = useDispatch();
  let cropOptions = useSelector(getCropOptions);

  const initialMarketplaceValues = initialiseQuestionnaireForMarketplace(selected);

  const handleUpdateMarketplaceSurvey = (values, formOptions) => {
    dispatch(
      updateQuestionnaireQuestions(values, {
        formOptions,
        id: selected._id,
        onSuccess: () => {
          modal.hideModal();
        },
        onError: () => {
          toast.warn("Questionnaire failed to update, kindly try again, if the issue persists please contact admin");
        },
      })
    );
  };
  return (
    <Formik onSubmit={handleUpdateMarketplaceSurvey} initialValues={initialMarketplaceValues}>
      {() => (
        <Form>
          <Flex flexDirection="column" gap="1rem">
            <Accordion collapsible>
              {cropOptions?.length ? (
                cropOptions.map((crop, cropIndex) => {
                  return (
                    <AccordionItem key={hyphenate(["price-list", cropIndex])} value={crop.value}>
                      <AccordionTrigger>{crop.label}</AccordionTrigger>
                      <AccordionContent>
                        <Flex flexDirection="column">
                          {crop?.crop_variety?.length ? (
                            crop.crop_variety.map((cropVariety, cropVarietyIndex) => (
                              <Flex
                                key={hyphenate([crop.label, "crop_variety", cropVarietyIndex])}
                                gap="1rem"
                                alignItems="center"
                                justifyContent="space-between"
                                my="0.5rem"
                              >
                                <Box width="100%">
                                  <Input label="Crop Variety" value={cropVariety} isDisabled />
                                </Box>
                                <Box width="100%">
                                  <FormInput
                                    name={`prices.${crop.label}.${cropVariety}`}
                                    label="Price (NGN)"
                                    // defaultValue={"0.0"}
                                    type="number"
                                    min="0.0"
                                    step="0.1"
                                    required
                                  />
                                </Box>
                              </Flex>
                            ))
                          ) : (
                            <Flex gap="1rem" alignItems="center" justifyContent="space-between">
                              <Box width="100%">
                                <Input value={crop.label} label="Crop" isDisabled />
                              </Box>
                              <Box width="100%">
                                <FormInput
                                  label="Price (NGN)"
                                  name={`prices.${crop.label}.${crop.label}`}
                                  // defaultValue={"0.0"}
                                  type="number"
                                  min="0.0"
                                  step="0.1"
                                  required
                                />
                              </Box>
                            </Flex>
                          )}
                        </Flex>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })
              ) : (
                <EmptyState title="No Crops Created" />
              )}
            </Accordion>
            <Box>
              <Button type="submit">Update Prices</Button>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateMarketplaceSurveyPrices;
