import { getApiUrl } from "utils";
import { axiosService } from "../fetchService";
import { apiConstants } from "constants/apiConstants";

export class Agent_API {
  static fetchAllAgents = async () => {};
  static searchAgents = async ({ params, signal }) => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.agent, path: "/search" });
    const { data } = await axiosService({ method: "GET", url, params, signal });
    if (!data) {
      return [];
    }
    return data;
  };
}
