import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { hyphenate } from "utils";

export const PageTabsNav = ({ tabs }) => {
  // const _tabs = React.
  // const dispatch = useDispatch()
  // let currentPageTabId = useSelector(getCurrentPageTabId);

  // const navigate = useNavigate();

  useEffect(() => {
    const isAllItemsHaveIds = tabs.every(tab => tab?.tabId);
    if (!isAllItemsHaveIds) {
      throw Error("Missing TabIds: All pageNavTabs should have 'tabId' fields");
    }
  }, []);

  return (
    <>
      <nav className="page-tabs__list">
        {tabs?.map((item, index) => (
          <NavLink
            key={hyphenate(["pagetab-nav", index, item?.tabId])}
            to={`${item.path}`}
            end
            state={{ ...item }}
            className={({ isActive }) => `page-tabs__trigger ${isActive ? "active" : ""}`}
          >
            {item.title}
          </NavLink>
        ))}
      </nav>
      <Outlet />
    </>
  );
};

PageTabsNav.defaultProps = {
  tabs: [],
};
