import { css } from "styled-components/macro";
import * as fonts from "../fonts";

const defaultFont = css`
  font-family: ${fonts.fonts.DEFAULT.join(",")};
`;

const xs = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.xs};
    line-height: ${fonts.lineHeights.xs};
    letter-spacing: ${fonts.letterSpacings.xs};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.xs};
    line-height: ${fonts.lineHeights.xs};
    letter-spacing: ${fonts.letterSpacings.xs};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.xs};
    line-height: ${fonts.lineHeights.xs};
    letter-spacing: ${fonts.letterSpacings.xs};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.xs};
    line-height: ${fonts.lineHeights.xs};
    letter-spacing: ${fonts.letterSpacings.xs};
  `,
};
const sm = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.sm};
    line-height: ${fonts.lineHeights.sm};
    letter-spacing: ${fonts.letterSpacings.sm};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.sm};
    line-height: ${fonts.lineHeights.sm};
    letter-spacing: ${fonts.letterSpacings.sm};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.sm};
    line-height: ${fonts.lineHeights.sm};
    letter-spacing: ${fonts.letterSpacings.sm};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.sm};
    line-height: ${fonts.lineHeights.sm};
    letter-spacing: ${fonts.letterSpacings.sm};
  `,
};
const md = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.md};
    line-height: ${fonts.lineHeights.md};
    letter-spacing: ${fonts.letterSpacings.md};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.md};
    line-height: ${fonts.lineHeights.md};
    letter-spacing: ${fonts.letterSpacings.md};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.md};
    line-height: ${fonts.lineHeights.md};
    letter-spacing: ${fonts.letterSpacings.md};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.md};
    line-height: ${fonts.lineHeights.md};
    letter-spacing: ${fonts.letterSpacings.md};
  `,
};
const lg = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.lg};
    line-height: ${fonts.lineHeights.lg};
    letter-spacing: ${fonts.letterSpacings.lg};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.lg};
    line-height: ${fonts.lineHeights.lg};
    letter-spacing: ${fonts.letterSpacings.lg};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.lg};
    line-height: ${fonts.lineHeights.lg};
    letter-spacing: ${fonts.letterSpacings.lg};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.lg};
    line-height: ${fonts.lineHeights.lg};
    letter-spacing: ${fonts.letterSpacings.lg};
  `,
};
const xl = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.xl};
    line-height: ${fonts.lineHeights.xl};
    letter-spacing: ${fonts.letterSpacings.xl};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.xl};
    line-height: ${fonts.lineHeights.xl};
    letter-spacing: ${fonts.letterSpacings.xl};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.xl};
    line-height: ${fonts.lineHeights.xl};
    letter-spacing: ${fonts.letterSpacings.xl};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.xl};
    line-height: ${fonts.lineHeights.xl};
    letter-spacing: ${fonts.letterSpacings.xl};
  `,
};
const displayXs = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.displayXs};
    line-height: ${fonts.lineHeights.displayXs};
    letter-spacing: ${fonts.letterSpacings.displayXs};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.displayXs};
    line-height: ${fonts.lineHeights.displayXs};
    letter-spacing: ${fonts.letterSpacings.displayXs};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.displayXs};
    line-height: ${fonts.lineHeights.displayXs};
    letter-spacing: ${fonts.letterSpacings.displayXs};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.displayXs};
    line-height: ${fonts.lineHeights.displayXs};
    letter-spacing: ${fonts.letterSpacings.displayXs};
  `,
};
const displaySm = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.displaySm};
    line-height: ${fonts.lineHeights.displaySm};
    letter-spacing: ${fonts.letterSpacings.displaySm};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.displaySm};
    line-height: ${fonts.lineHeights.displaySm};
    letter-spacing: ${fonts.letterSpacings.displaySm};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.displaySm};
    line-height: ${fonts.lineHeights.displaySm};
    letter-spacing: ${fonts.letterSpacings.displaySm};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.displaySm};
    line-height: ${fonts.lineHeights.displaySm};
    letter-spacing: ${fonts.letterSpacings.displaySm};
  `,
};
const displayMd = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.displayMd};
    line-height: ${fonts.lineHeights.displayMd};
    letter-spacing: ${fonts.letterSpacings.displayMd};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.displayMd};
    line-height: ${fonts.lineHeights.displayMd};
    letter-spacing: ${fonts.letterSpacings.displayMd};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.displayMd};
    line-height: ${fonts.lineHeights.displayMd};
    letter-spacing: ${fonts.letterSpacings.displayMd};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.displayMd};
    line-height: ${fonts.lineHeights.displayMd};
    letter-spacing: ${fonts.letterSpacings.displayMd};
  `,
};
const displayLg = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.displayLg};
    line-height: ${fonts.lineHeights.displayLg};
    letter-spacing: ${fonts.letterSpacings.displayLg};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.displayLg};
    line-height: ${fonts.lineHeights.displayLg};
    letter-spacing: ${fonts.letterSpacings.displayLg};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.displayLg};
    line-height: ${fonts.lineHeights.displayLg};
    letter-spacing: ${fonts.letterSpacings.displayLg};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.displayLg};
    line-height: ${fonts.lineHeights.displayLg};
    letter-spacing: ${fonts.letterSpacings.displayLg};
  `,
};
const displayXl = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.displayXl};
    line-height: ${fonts.lineHeights.displayXl};
    letter-spacing: ${fonts.letterSpacings.displayXl};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.displayXl};
    line-height: ${fonts.lineHeights.displayXl};
    letter-spacing: ${fonts.letterSpacings.displayXl};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.displayXl};
    line-height: ${fonts.lineHeights.displayXl};
    letter-spacing: ${fonts.letterSpacings.displayXl};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.displayXl};
    line-height: ${fonts.lineHeights.displayXl};
    letter-spacing: ${fonts.letterSpacings.displayXl};
  `,
};
const display2xl = {
  regular: css`
    font-weight: ${fonts.fontWeights.regular};
    font-size: ${fonts.fontSizes.display2xl};
    line-height: ${fonts.lineHeights.display2xl};
    letter-spacing: ${fonts.letterSpacings.display2xl};
  `,
  medium: css`
    font-weight: ${fonts.fontWeights.medium};
    font-size: ${fonts.fontSizes.display2xl};
    line-height: ${fonts.lineHeights.display2xl};
    letter-spacing: ${fonts.letterSpacings.display2xl};
  `,
  semibold: css`
    font-weight: ${fonts.fontWeights.semibold};
    font-size: ${fonts.fontSizes.display2xl};
    line-height: ${fonts.lineHeights.display2xl};
    letter-spacing: ${fonts.letterSpacings.display2xl};
  `,
  bold: css`
    font-weight: ${fonts.fontWeights.bold};
    font-size: ${fonts.fontSizes.display2xl};
    line-height: ${fonts.lineHeights.display2xl};
    letter-spacing: ${fonts.letterSpacings.display2xl};
  `,
};
export default {
  defaultFontFamily: defaultFont,
  xsRegular: xs.regular,
  xsMedium: xs.medium,
  xsSemibold: xs.semibold,
  xsBold: xs.bold,
  smRegular: sm.regular,
  smMedium: sm.medium,
  smSemibold: sm.semibold,
  smBold: sm.bold,
  mdRegular: md.regular,
  mdMedium: md.medium,
  mdSemibold: md.semibold,
  mdBold: md.bold,
  lgRegular: lg.regular,
  lgMedium: lg.medium,
  lgSemibold: lg.semibold,
  lgBold: lg.bold,
  xlRegular: xl.regular,
  xlMedium: xl.medium,
  xlSemibold: xl.semibold,
  xlBold: xl.bold,
  displayXsRegular: displayXs.regular,
  displayXsMedium: displayXs.medium,
  displayXsSemibold: displayXs.semibold,
  displayXsBold: displayXs.bold,
  displaySmRegular: displaySm.regular,
  displaySmMedium: displaySm.medium,
  displaySmSemibold: displaySm.semibold,
  displaySmBold: displaySm.bold,
  displayMdRegular: displayMd.regular,
  displayMdMedium: displayMd.medium,
  displayMdSemibold: displayMd.semibold,
  displayMdBold: displayMd.bold,
  displayLgRegular: displayLg.regular,
  displayLgMedium: displayLg.medium,
  displayLgSemibold: displayLg.semibold,
  displayLgBold: displayLg.bold,
  displayXlRegular: displayXl.regular,
  displayXlMedium: displayXl.medium,
  displayXlSemibold: displayXl.semibold,
  displayXlBold: displayXl.bold,
  display2xlRegular: display2xl.regular,
  display2xlMedium: display2xl.medium,
  display2xlSemibold: display2xl.semibold,
  display2xlBold: display2xl.bold,
};
