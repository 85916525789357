import React from "react";
// import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { format } from "date-fns";
import { Avatar, Badge, Box, Flex, Icon, Text } from "organic";
// import { Popover, PopoverContent, PopoverTrigger } from "components/popover";
import { Modal, ModalContent } from "components/modal";
import { useDisclosure } from "hooks";
import BasicInformationForm from "./basicInformationForm";
import AddExistingFarmersModal from "./addExistingFarmersModal";

// const OptionsContainer = styled.div`
//   cursor: pointer;
//   padding: 10px 15px;
//   &:hover {
//     background-color: #f9fafb;
//   }
// `;

const InviteMembers = ({ memberFarmersList, setMemberFarmersList }) => {
  const createNewMemberFarmer = useDisclosure();
  const addExistingFarmer = useDisclosure();

  return (
    <>
      <Flex mt={5} mx={1} mb={9} flexDirection="column" gap="1rem" textAlign="center">
        {memberFarmersList.length ? (
          memberFarmersList.map(farmer => {
            return (
              <Flex key={uuid()} alignItems="center" justifyContent="space-between">
                <Flex gap="15px" alignItems="center">
                  <Avatar size="lg" name={`${farmer.first_name} ${farmer.last_name}`} />
                  <Flex flexDirection="column" gap="-5px" alignItems="start">
                    <Text font="mdMedium" color="gray900">
                      {`${farmer.first_name} ${farmer.last_name}`}
                    </Text>
                    <Flex gap="10px">
                      <Text font="xsRegular" color="gray700">
                        {format(new Date(), "MM/dd/yyyy")}
                      </Text>
                      <Text font="xsRegular" color="gray700">
                        {format(new Date(), "hh:mm:ss a")}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex alignItems="center" gap="12px">
                  <Badge size="lg" colorScheme="gray">
                    Member
                  </Badge>
                  <Box
                    onClick={() => setMemberFarmersList(memberFarmersList.filter(item => item.id !== farmer.id))}
                    cursor="pointer"
                  >
                    <Icon icon="minus-circle" size="17px" color="#D92D20" />
                  </Box>
                </Flex>
              </Flex>
            );
          })
        ) : (
          <>
            <Text as="p" font="mdSemibold" color="gray500">
              Add member farmers
            </Text>
            <Text as="p" font="mdRegular" color="gray500">
              You can update member farmers on the contractual
              <br />
              farmers view at a later time.
            </Text>
          </>
        )}
        <Flex
          onClick={() => {
            createNewMemberFarmer.onOpen();
          }}
          gap="7px"
          width="fit-content"
          margin="auto"
          background="#ECFFE5"
          borderRadius="1px"
          py="10px"
          px="16px"
          cursor="pointer"
        >
          <Icon icon="user" size="20px" color="#247809" />
          <Text font="smSemibold" color="primary700">
            Add Members
          </Text>
        </Flex>
      </Flex>
      <Modal {...createNewMemberFarmer.modalProps}>
        <ModalContent title="New Member">
          {/* <CreateContractualFarmer modal={createNewMemberFarmer.modal} /> */}
          <BasicInformationForm
            isMemberFarmer
            hasSubmitButton
            submitButtonText="Submit to Add Member"
            handleSubmit={values => {
              setMemberFarmersList([
                ...memberFarmersList,
                {
                  id: uuid(),
                  ...values,
                },
              ]);
              createNewMemberFarmer.onClose();
            }}
          />
        </ModalContent>
      </Modal>
      <Modal {...addExistingFarmer.modalProps}>
        <ModalContent title="From Existing Farmers">
          {/* <CreateContractualFarmer modal={createNewMemberFarmer.modal} /> */}
          <AddExistingFarmersModal
            modal={addExistingFarmer.modal}
            handleSubmit={() => {
              setMemberFarmersList([
                ...memberFarmersList,
                {
                  id: uuid(),
                  avatar: "",
                  name: "Sani Mohammed",
                  date: "11/5/2022",
                  time: "11:27:09 AM",
                  type: "Member",
                },
              ]);
              addExistingFarmer.onClose();
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default InviteMembers;

// Keep popver stuff here in case it's needed again
{
  /* <Popover>
          <PopoverTrigger>
            <Flex
              gap="7px"
              width="fit-content"
              margin="auto"
              background="#ECFFE5"
              borderRadius="1px"
              py="10px"
              px="16px"
              cursor="pointer"
            >
              <Icon icon="user" size="20px" color="#247809" />
              <Text font="smSemibold" color="primary700">
                Add Members
              </Text>
            </Flex>
          </PopoverTrigger>
          <PopoverContent alignOffset={-100} sideOffset={10} align="end">
            <Flex flexDirection="column" borderRadius="8px" border="1px solid #f2f4f7" background="white">
              <OptionsContainer
                onClick={() => {
                  createNewMemberFarmer.onOpen();
                }}
              >
                <Text font="smMedium" color="gray700">
                  New members
                </Text>
              </OptionsContainer>
              <OptionsContainer
                onClick={() => {
                  addExistingFarmer.onOpen();
                }}
              >
                <Text font="smMedium" color="gray700">
                  From existing members
                </Text>
              </OptionsContainer>
            </Flex>
          </PopoverContent>
        </Popover> */
}
