import { authChecker } from "../../services/v1/authService";
import agents from "api/agents";
import { toast } from "react-toastify";
import { specialCharRemover } from "utils/v1/removeSpecialChars";
import generateXYData from "utils/v1/generateXYData";
// import Axios from "axios";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
import { handleActionError } from "services/shared/AOSErrorService";

// Ducks pattern
//actions
const GET_ALL_AGENTS = "GET_ALL_AGENTS";
const GET_ALL_FILTERED_AGENTS = "GET_ALL_FILTERED_AGENTS";
const GET_ALL_FILTERED_AGENTS_OVERVIEW = "GET_ALL_FILTERED_AGENTS_OVERVIEW";
const GET_ALL_AGENTS_DOWNLOAD = "GET_ALL_AGENTS_DOWNLOAD";
const LOAD_AGENTS_DOWNLOAD = "LOAD_AGENTS_DOWNLOAD";
const LOAD_AGENTS_DATA = "LOAD_AGENTS_DATA";
const GET_OVERALL_AGENT_TOTAL = "GET_OVERALL_AGENT_TOTAL";
const CHANGE_PAGE_SIZE_AGENTS = "CHANGE_PAGE_SIZE_AGENTS";
const HANDLE_PAGE_CHANGE_AGENTS = "HANDLE_PAGE_CHANGE_AGENTS";
const CHANGE_OFFSET_VALUE_AGENTS = "CHANGE_OFFSET_VALUE_AGENTS";
const SET_INFO_TO_DISPLAY_AGENTS = "SET_INFO_TO_DISPLAY_AGENTS";
const FILTER_AGENTS_DATA = "FILTER_AGENTS_DATA";
const SEARCH_TERM = "SEARCH_TERM";
const LOAD_SEARCH = "LOAD_SEARCH";

//action creators

export const getAllAgentsWithParamsOverview = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_AGENTS_DATA, payload: true });
    const agentData = await agents.get("getAllAgentsWithParamsOverview", { params });
    dispatch({
      type: GET_ALL_FILTERED_AGENTS_OVERVIEW,
      payload: agentData.data.data,
      total: agentData.data.total,
    });

    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
  } catch (getAllAgentsWithParamsOverviewError) {
    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
    handleActionError(getAllAgentsWithParamsOverviewError);
  }
};

export const getAllAgentsWithParams = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_AGENTS_DATA, payload: true });
    const agentData = await agents.get("getAllAgentsWithParams", { params });
    dispatch({
      type: GET_ALL_FILTERED_AGENTS,
      payload: agentData.data.data,
      pages: agentData.data.pages,
      agentMeta: agentData?.data?.meta || {},
      totalAgentCount: agentData.data?.meta?.totalDocCount || 0,
      agentPageCount: agentData.data?.meta?.pageCount || 0,
    });

    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
  } catch (getAllAgentsWithParamsError) {
    handleActionError(getAllAgentsWithParamsError);
    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
  }
};
export const getAllAgents = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_AGENTS_DATA, payload: true });
    const agentData = await agents.get("all", { params });
    dispatch({
      type: GET_ALL_AGENTS,
      payload: agentData.data.data,
      pages: agentData.data.pages,
      agentMeta: agentData?.data?.meta || {},
      totalAgentCount: agentData.data?.meta?.totalDocCount || 0,
      agentPageCount: agentData.data?.meta?.pageCount || 0,
    });

    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
  } catch (getAllAgentsError) {
    handleActionError(getAllAgentsError);
    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
  }
};

export const searchAllAgentsWithParams =
  (params, { abortController }) =>
  async dispatch => {
    try {
      authChecker();
      dispatch({ type: LOAD_SEARCH, payload: true });
      const agentData = await agents.get("searchEditZones", { params, signal: abortController.signal });

      dispatch({
        type: GET_ALL_FILTERED_AGENTS,
        payload: agentData.data.data,
        pages: agentData.data.pages,
        agentMeta: agentData?.data?.meta || {},
        totalAgentCount: agentData.data?.meta?.totalDocCount || 0,
        agentPageCount: agentData.data?.meta?.pageCount || 0,
      });

      dispatch({ type: LOAD_SEARCH, payload: false });
    } catch (searchAllAgentsWithParamsError) {
      handleActionError(searchAllAgentsWithParamsError);
      dispatch({ type: LOAD_SEARCH, payload: false });
    }
  };

export const getAllAGentsDownloadData = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_AGENTS_DOWNLOAD, payload: true });
    const agentDownloadData = await agents.get("/download/farmersOnboarded/allAgents2", { params });

    dispatch({
      type: GET_ALL_AGENTS_DOWNLOAD,
      payload: agentDownloadData.data,
    });

    dispatch({ type: LOAD_AGENTS_DOWNLOAD, payload: false });
  } catch (getAllAGentsDownloadDataError) {
    dispatch({ type: LOAD_AGENTS_DOWNLOAD, payload: false });
    handleActionError(getAllAGentsDownloadDataError);
  }
};

export const getAllAgentsTotal = () => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_AGENTS_DATA, payload: true });
    const agentData = await agents.get("getAllAgentsCount", {});

    dispatch({
      type: GET_OVERALL_AGENT_TOTAL,
      payload: agentData.data.data,
    });

    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
  } catch (getAllAgentsTotalError) {
    dispatch({ type: LOAD_AGENTS_DATA, payload: false });
    handleActionError(getAllAgentsTotalError);
  }
};

export const filterAgentsData =
  (values, { setSubmitting }) =>
  dispatch => {
    if (Object.keys(values).length < 1) {
      toast.error("Search box must not be empty. Please type a search term");
    }
    dispatch({
      type: FILTER_AGENTS_DATA,
      payload: values.term,
    });
    setSubmitting(false);
  };

/**
 *
 *
 * @param {string} term
 */
export const handleSearchTerm = term => dispatch => {
  const searchTerm = specialCharRemover(term);
  dispatch({
    type: SEARCH_TERM,
    payload: searchTerm,
  });
};

export const getPaginatedAgents = (page_number, per_page) => dispatch => {
  authChecker();

  const params = {
    page_number,
    per_page,
  };
  return agents
    .get("/all", { params })
    .then(agentData => {
      dispatch({
        type: GET_ALL_AGENTS,
        payload: agentData.data.data,
        pages: agentData.data.pages,
      });
    })
    .catch(__err => {
      toast.error("Something went wrong fetching Agents, try refreshing the page");
    });
};

export const editAgentDetails =
  (values, options = {}) =>
  async dispatch => {
    let hasModal = options?.modal ? true : false;
    let hasFormOptions = options?.formOptions ? true : false;
    try {
      let params = {
        id: values._id,
      };

      let sanitizedValues = sanitizeMongoDoc(values, [
        "avatar_url",
        "password_hash",
        "idx",
        "organization",
        // "siloId",
      ]);

      await agents.put("/update", sanitizedValues, { params });
      toast.success("Agent Details updated successfully!");
      if (hasFormOptions) options.formOptions.setSubmitting(false);
      if (options.refreshData) options.refreshData();
      if (hasModal) options.modal.hideModal();
      return dispatch(getAllAgents());
    } catch (err) {
      if (err?.isAxiosError) {
        toast.error(err.response.data.message);
        if (hasFormOptions) {
          options.formOptions.setErrors({
            message: err.response.data.message,
          });

          options.formOptions.setSubmitting(false);
        }
        return;
      }
      if (hasFormOptions) options.formOptions.setSubmitting(false);
    }
  };

export const changePageSize = perPage => dispatch => {
  dispatch({
    type: CHANGE_PAGE_SIZE_AGENTS,
    payload: perPage,
  });
};

export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  dispatch({
    type: HANDLE_PAGE_CHANGE_AGENTS,
    payload: pageNumber,
  });
  dispatch(handleOffset(pageNumber, pageSize));
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;

  dispatch({
    type: CHANGE_OFFSET_VALUE_AGENTS,
    payload: offsetValue,
  });
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY_AGENTS,
    payload: arrayOfInfo,
  });
};

//reducer
const initialState = {
  agentData: [],
  agentMeta: {},
  allAgents: [],
  agentPageCount: 0,
  totalAgentCount: 0,
  loadingSearch: false,
  agentChartData: [],
  agentDownloadData: [],
  loadingDownload: false,
  overAllAgentCount: 0,
  loading: false,
  pages: 1,
  pageSize: 10,
  pageNumber: 1,
  offset: 0,
};

function middlewareFilterData(state, action) {
  try {
    //console.log("============================= GET_ALL_FILTERED_AGENTS ================")
    let agentRecord = action.payload;
    // let agentInvolvedCount = 0;
    let agentChartData = generateXYData(agentRecord);
    return {
      ...state,
      agentData: agentRecord,
      agentChartData,
      agentPageCount: action.agentPageCount,
      totalAgentCount: action.totalAgentCount,
      agentMeta: action.agentMeta,
      pages: action.pages,
      total: action.total,
    };
  } catch (error) {
    //console.log({error})
  }
}
const agentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_AGENTS:
      return {
        ...state,
        agentData: action.payload,
        pages: action.pages,
        overAllAgentCount: action.payload.length,
      };
    case GET_ALL_FILTERED_AGENTS:
      return middlewareFilterData(state, action); //{ ...state, agentData: action.payload, pages: action.pages };
    case GET_ALL_FILTERED_AGENTS_OVERVIEW: {
      let agentChartData = generateXYData(action.payload);
      return {
        ...state,
        //agentData: action.payload,
        agentChartData,
        total: action.total,
      };
    }
    case GET_ALL_AGENTS_DOWNLOAD:
      return {
        ...state,
        agentDownloadData: action.payload,
      };
    case LOAD_AGENTS_DOWNLOAD:
      return {
        ...state,
        loadingDownload: action.payload,
      };
    case LOAD_SEARCH:
      return {
        ...state,
        loadingSearch: action.payload,
      };
    case LOAD_AGENTS_DATA:
      //console.log("========================== LOAD_AGENTS_DATA ================payload: ",action.payload)
      return {
        ...state,
        loading: action.payload,
      };
    case GET_OVERALL_AGENT_TOTAL:
      return {
        ...state,
        overAllAgentCount: action.payload,
      };
    case CHANGE_PAGE_SIZE_AGENTS:
      return { ...state, pageSize: action.payload };
    case HANDLE_PAGE_CHANGE_AGENTS:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE_AGENTS:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY_AGENTS: {
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize);
      return { ...state, info: infoToDisplay };
    }
    case FILTER_AGENTS_DATA: {
      const searchTerm = new RegExp(action.payload, "i");
      // prettier-ignore
      const filteredData = state
      .agentData
      .filter((agent) => Object.values(agent)
        .find(agent => searchTerm.test(agent)));

      return { ...state, filteredData };
    }
    case SEARCH_TERM: {
      const term = new RegExp(action.payload, "i");
      // prettier-ignore
      const result = state
      .agentData
      .filter((agent) => Object.values(agent)
        .find(agent => term.test(agent)));
      return { ...state, searchTerm: action.payload, filteredData: result };
    }
    default:
      return state;
  }
};

export default agentReducer;

// selector function
export const getAgentData = state => state.legacyAgent.agentData;
export const getAgentMeta = state => state.legacyAgent.agentMeta;
export const getAllTheAgents = state => state.legacyAgent.allAgents;
export const getAgentPageCount = state => state.legacyAgent.agentPageCount;
export const getTotalAgentCount = state => state.legacyAgent.totalAgentCount;
export const getLoadingSearchAgents = state => state.legacyAgent.loadingSearch;
export const getAgentChartData = state => state.legacyAgent.agentChartData;
export const getAgentDownloadData = state => state.legacyAgent.agentDownloadData;
export const getLoadingDownload = state => state.legacyAgent.loadingDownload;
export const getOverAllAgentCount = state => state.legacyAgent.overAllAgentCount;
export const getLoadingAgents = state => state.legacyAgent.loading;
