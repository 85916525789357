import React from "react";
import { Form, Formik } from "formik";
import FormGridTitle from "components/v1/Forms/FormGridTitle";
// import CustomFormField from "components/v1/Forms/CustomFormField";
import FormGridRow from "components/v1/Forms/FormGridRow";
import { Col } from "reactstrap";
import { submitSubscription } from "features/v1/subscription";
import { useDispatch } from "react-redux";
// import AOSFormButton from "components/v1/Button/AOSFormButton";
import CustomFormFieldArray from "components/v1/Forms/CustomFormFieldArray";
import CustomButton from "components/v1/Button/CustomButton";
import closeBtn from "components/v1/common/modalCloseBtn";
import { Box } from "victory";
import { FormInput } from "components/inputs";
import { initialSubscriptionValues, subscriptionPlanInput } from "views/v1/Forms/Settings/settingsInitialValues";
import { Button } from "organic";

function CreateSubscriptionForm() {
  const dispatch = useDispatch();
  const handleSubmitCreateSubscriptionPlan = (values, formOptions) => {
    dispatch(submitSubscription(values, { formOptions, hasModal: false }));
  };
  return (
    <div>
      <Formik initialValues={initialSubscriptionValues} onSubmit={handleSubmitCreateSubscriptionPlan}>
        {({ values, __setFieldValue, __errors, __handleSubmit }) => (
          <Form>
            <Box my={40}>
              <Box mb={4}>
                <FormInput
                  name="subscription_plan_name"
                  label="Subscription Plan Name"
                  placeholder="Maize farm in Ijora Bunue for Dry season"
                  required
                />
                {/* <CropTypeSelect onChange={val => setFieldValue()} /> */}
              </Box>

              <Box mb={4}>
                <FormInput name="duration" label="Duration in Months" />
              </Box>
              <Box mb={4}>
                <FormInput name="equity_contribution_amount" label="Equity Contribution Amount" />
              </Box>
              <Box mb={4}>
                <FormInput name="total_input_loan_amount" label="Total Input Loan Amount" />
              </Box>

              {/* Input Details */}
              <FormGridTitle title="Subscription Plan Input Details" />

              <CustomFormFieldArray
                name="subscription_plan_inputs"
                // TODO Refactor this section out to become reusable part of components
                render={arrayHelper => (
                  <>
                    {values.subscription_plan_inputs.map((subPlanInput, subPlanInputIndex) => (
                      <div
                        key={subPlanInputIndex.toString()}
                        className="mt-4"
                        style={{ borderBottom: "dashed 2px #ececec" }}
                      >
                        <FormGridRow>
                          <Col>
                            <h6 className="text-primary">Input {subPlanInputIndex + 1}</h6>
                          </Col>
                          <Col className="text-right">
                            {closeBtn({
                              toggle: () => arrayHelper.remove(subPlanInputIndex),
                              size: 32,
                            })}
                          </Col>
                        </FormGridRow>

                        <Box mb={4}>
                          <FormInput
                            name={`subscription_plan_inputs[${subPlanInputIndex}].input_name`}
                            label="Input Name"
                            required
                          />
                        </Box>
                        <Box mb={4}>
                          <FormInput
                            name={`subscription_plan_inputs[${subPlanInputIndex}].input_type`}
                            label="Input Type (eg. seed, fretilzer)"
                          />
                        </Box>

                        <Box mb={4}>
                          <FormInput
                            name={`subscription_plan_inputs[${subPlanInputIndex}].input_quantity`}
                            label="Input Quantity (quantity given to farmer as loan)"
                            required
                          />
                        </Box>
                        <Box mb={4}>
                          <FormInput
                            name={`subscription_plan_inputs[${subPlanInputIndex}].input_unit_measurement`}
                            label="Unit of Measurement (eg. kg)"
                          />
                        </Box>
                        <Box mb={4}>
                          <FormInput
                            name={`subscription_plan_inputs[${subPlanInputIndex}].input_unit_amount`}
                            label="Amount Per Unit"
                          />
                        </Box>
                      </div>
                    ))}
                    <CustomButton
                      type="button"
                      onClick={() => arrayHelper.push(subscriptionPlanInput)}
                      text="Add Another Input"
                    />
                  </>
                )}
              />
              {/* Output Details */}
              <FormGridTitle title="Subscription Plan Output Details" />

              <Box mb={4}>
                <FormInput name="subscription_plan_outputs.output_name" label="Output Name" required />
              </Box>
              <Box mb={4}>
                <FormInput name="subscription_plan_outputs.output_type" label="Output Type" />
              </Box>
              <Box mb={4}>
                <FormInput name="subscription_plan_outputs.output_quantity" label="Output Quantity" />
              </Box>

              <Box mb={4}>
                <FormInput
                  name="subscription_plan_outputs.input_unit_measurement"
                  label="Unit of Measurement (eg. kg)"
                  required
                />
              </Box>
              <Box mb={4}>
                <FormInput name="subscription_plan_outputs.expected_repayment" label="Expected Repayment" />
              </Box>
              <Box mb={4}>
                <FormInput name="subscription_plan_outputs.comment" label="Comment" />
              </Box>
            </Box>
            <Button colorScheme="primary" display="block" text="Submit">
              Create new subscription plan
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateSubscriptionForm;
