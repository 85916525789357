import * as turf from "@turf/turf";
import { useCallbackRef } from "hooks/ui/useCallbackRef";
import rbush from "rbush";

export function useTurf() {
  const findOverlappingPolygons = useCallbackRef(polygons => {
    const tree = new rbush();

    // Insert polygons into the spatial index
    polygons.forEach((coordinates, index) => {
      const bbox = turf.bbox(turf.polygon([coordinates]));
      tree.insert({ minX: bbox[0], minY: bbox[1], maxX: bbox[2], maxY: bbox[3], index });
    });

    const overlappingPolygons = [];

    // Check for overlaps using the spatial index
    polygons.forEach((coordinates, index) => {
      const bbox = turf.bbox(turf.polygon([coordinates]));
      const candidates = tree.search({ minX: bbox[0], minY: bbox[1], maxX: bbox[2], maxY: bbox[3] });

      candidates.forEach(candidate => {
        if (index !== candidate.index) {
          const poly1 = turf.polygon([coordinates]);
          const poly2 = turf.polygon([polygons[candidate.index]]);

          if (turf.booleanOverlap(poly1, poly2)) {
            overlappingPolygons.push(index, candidate.index);
          }
        }
      });
    });

    // Remove duplicates
    return Array.from(new Set(overlappingPolygons));
  }, []);

  return { findOverlappingPolygons };
}
