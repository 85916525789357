import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import Onboarding_API from "services/onboardingApiService";

export function useGetFarmerIdRange(params, handleSuccess) {
  const fetcher = async () => {
    const data = await Onboarding_API.getFarmerIdRange(params);
    return data;
  };

  return useQuery({
    queryKey: [rqKeys.onboarding.getFarmerIdRange, ...Object.values(params)],
    queryFn: fetcher,
    enabled: false,
    onSuccess: data => {
      handleSuccess(data);
    },
  });
}
