import styled, { css } from "styled-components/macro";
import { border, color, flexbox, layout, position, shadow, space, typography, background } from "styled-system";
import shouldForwardProp from "@styled-system/should-forward-prop";
import propTypes from "@styled-system/prop-types";

const Flex = styled.div.withConfig({
  shouldForwardProp,
})`
  ${props => css`
    display: flex;
    transition: all 0.3s ease-in-out;
    ${flexbox}
    ${space}
    ${layout}
    ${typography}
    ${position}
    ${shadow}
    ${border}
    ${color}
    ${background}
    ${props?.outline
      ? css`
          outline: ${props.outline};
        `
      : css``}
  `}
  ${props =>
    props?.gap
      ? css`
          gap: ${props.gap};
        `
      : css``}
  
      ${props =>
    props?.cursor
      ? css`
          cursor: ${props.cursor};
        `
      : css``}
`;

Flex.propTypes = {
  ...propTypes.flexbox,
  ...propTypes.space,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
  ...propTypes.shadow,
  ...propTypes.border,
};

export default Flex;
