import { FormGridHeader } from "components/layout";
import { LogisticsTypeSelect, selectConstants } from "components/select";
import { FieldArray, useFormikContext } from "formik";
import { Box, Button, Flex, Text, buttonConstants } from "organic";
import React, { useEffect } from "react";
import { sum } from "utils";
import numeral from "numeral";
import { FormErrorMessage } from "components/form";
import { FormInput } from "components/inputs";
import CurrencySelect from "components/select/currencySelect";
import { createSelectOption } from "utils/v1/selectUtils";
import { TableActions, tableConstants } from "components/table";
import { MAX_NUM_STEPS, MAX_NUM_VALUE, MIN_NUM_VALUE } from "constants/sharedConstants";
import { useDidMountEffect } from "hooks";

const getLogisticsFieldName = ({ index, fieldName }) => `logistics_type[${index}].${fieldName}`;

const calcLogisticsCost = ({ logisticsTypes }) => {
  const newLogistics = [...logisticsTypes];
  return numeral(sum(newLogistics?.map?.(type => numeral(type?.amount || 0).value())))
    .value()
    .toFixed(2);
};

const calcLogisticsAmount = ({ unit, pricePerUnit }) => {
  return numeral(unit || 0)
    .multiply(pricePerUnit || 0)
    .value()
    .toFixed(2);
};

const createLogisticType = data => {
  return getLogisticsType(data);
};

const getLogisticsType = item => {
  return {
    name: item?.type || "",
    unit: item?.unit || "",
    price_per_unit: item?.price_per_unit || "",
    amount: item?.amount || "",
  };
};

export const LogisticsTypeField = () => {
  const { values, setFieldValue } = useFormikContext();

  useDidMountEffect(() => {
    values.logistics_type.forEach((logisticsType, index) => {
      setFieldValue(
        getLogisticsFieldName({ fieldName: "amount", index }),
        calcLogisticsAmount({ unit: logisticsType.unit, pricePerUnit: logisticsType.price_per_unit })
      );
    });
  }, [values.logistics_type]);

  useEffect(() => {
    setFieldValue("logistics_cost", calcLogisticsCost({ logisticsTypes: values.logistics_type }));
  }, [values.logistics_type]);

  return (
    <FieldArray
      name="logistics_type"
      render={helpers => {
        return (
          <Flex py="4rem" flexDirection="column" gap="0.5rem">
            <FormGridHeader>Logistics Type</FormGridHeader>
            {values.logistics_type.map((logisticsType, logisticsTypeIndex) => {
              return (
                <Flex key={logisticsTypeIndex} width="100%" gap="1rem" mx="1rem" alignItems="center">
                  <Box width="30%">
                    <LogisticsTypeSelect
                      onChange={val => {
                        setFieldValue(`logistics_type[${logisticsTypeIndex}].name`, val);
                      }}
                      label={logisticsTypeIndex === 0 ? "Type" : ""}
                      defaultValue={{ value: logisticsType.name, label: logisticsType.name }}
                    />
                  </Box>
                  <Box width="15%">
                    <FormInput
                      name={getLogisticsFieldName({ fieldName: "unit", index: logisticsTypeIndex })}
                      label={logisticsTypeIndex === 0 ? "Unit" : ""}
                      type="number"
                      step={MAX_NUM_STEPS}
                      min={MIN_NUM_VALUE}
                      max={MAX_NUM_VALUE}
                    />
                    <FormErrorMessage name={getLogisticsFieldName("unit")} />
                  </Box>
                  <Box width="15%">
                    <FormInput
                      name={getLogisticsFieldName({ fieldName: "price_per_unit", index: logisticsTypeIndex })}
                      label={logisticsTypeIndex === 0 ? "Price/Unit" : ""}
                      type="number"
                      min={MIN_NUM_VALUE}
                      step={MAX_NUM_STEPS}
                      max={MAX_NUM_VALUE}
                    />
                    <FormErrorMessage name={getLogisticsFieldName("price_per_unit")} />
                  </Box>
                  <Box width="30%">
                    <FormInput
                      name={getLogisticsFieldName({ fieldName: `amount`, index: logisticsTypeIndex })}
                      label={logisticsTypeIndex === 0 ? "Amount" : ""}
                      isDisabled
                      readOnly
                      type="number"
                      min={MIN_NUM_VALUE}
                      step={MAX_NUM_STEPS}
                      max={MAX_NUM_VALUE}
                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue(`currency`, currencyValue);
                          }}
                          value={createSelectOption(values.currency, values.currency)}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                          placeholder="Currency"
                        />
                      }
                    />
                    <FormErrorMessage
                      name={getLogisticsFieldName({ fieldName: "amount", index: logisticsTypeIndex })}
                    />
                  </Box>
                  <Box width="1%" mt="20px">
                    <TableActions
                      canDelete
                      noEditAction
                      noViewAction
                      onSelect={({ actionKey }) => {
                        if (actionKey === tableConstants.defaultActionKeys.DELETE_ACTION_KEY) {
                          helpers.remove(logisticsTypeIndex);
                        }
                      }}
                      row={values.logistics_type[logisticsTypeIndex]}
                    />
                  </Box>
                </Flex>
              );
            })}
            <Flex justifyContent="flex-end" width="100%" gap="1rem" alignItems="center">
              <Flex width="30%">
                {" "}
                <Button
                  leftIcon="plus-circle"
                  colorScheme={buttonConstants.colorSchemes.TERTIARY_COLOR}
                  type="button"
                  onClick={() => helpers.push(createLogisticType())}
                >
                  <Text> Add Type</Text>
                </Button>
              </Flex>
              <Flex width="22%"></Flex>
              <Flex width="22%" justifyContent="flex-end">
                <Text font="smMedium" color="gray500">
                  Total Amount
                </Text>
              </Flex>
              <Flex width="22%" gap="0.5rem">
                <Text as="span" font="mdMedium" color="gray700">
                  {numeral(values?.logistics_cost || 0).format("0,0.00")}
                </Text>
                <Text as="span" font="mdMedium" color="gray700">
                  {values.currency}
                </Text>
              </Flex>
              <FormErrorMessage name="logistics_cost" />
            </Flex>
            <Flex width="4%"></Flex>
          </Flex>
        );
      }}
    />
  );
};
