import React from "react";
import { Flex, Text, Button, Icon, organicTheme } from "organic";
import { useDispatch } from "react-redux";
import { login } from "features/v1/auth";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
// import CustomFormField from "components/v1/Forms/CustomFormField";
import { FormInput } from "components/inputs";
import { loginValidator } from "validators/authValidators";
import { ErrorState } from "components/appState";

export default function LoginPage() {
  const dispatch = useDispatch();

  const handleLogin = (values, { setErrors, setSubmitting }) => {
    // setIsLoading(true);
    dispatch(login(values, { setErrors, setSubmitting }));
  };
  return (
    <ErrorState>
      <Formik
        initialValues={{ phone_number: "", password: "" }}
        validationSchema={loginValidator}
        // validateOnChange
        onSubmit={handleLogin}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <Text font="displaySmSemibold" color="gray900">
              Hi, Welcome back!
            </Text>
            <Text font="mdRegular" color="gray500">
              Enter your account details
            </Text>
            <Flex marginTop="48px" flexDirection="column" maxWidth="752px">
              <Flex flexDirection="row" alignItems="center">
                {errors.message?.length && (
                  <Icon icon="alert-circle" color={organicTheme.colors.error700} mr="0.5rem" />
                )}
                <Text as="span" font="smRegular" color="error700">
                  {errors.message}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <FormInput
                  name="phone_number"
                  type="tel"
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  required
                />
              </Flex>
              <Flex flexDirection="column" marginTop="20px">
                <FormInput placeholder="Password" name="password" label="Password" required type="password" />
                <Flex flexDirection="row" justifyContent="flex-end" marginTop="16px">
                  <Link to="#">
                    <Text as="span" font="smMedium" color="primary400">
                      Forgot password
                    </Text>
                  </Link>
                </Flex>
              </Flex>
              <Flex marginTop="48px">
                <Button display="block" type="submit" isDisabled={isSubmitting}>
                  Log in
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </ErrorState>
  );
}
