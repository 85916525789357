import farmActivity from "api/farmActivity";
// import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";

// actions
const GET_FARM_SUBSCRIPTION_ACTIVITIES = "GET_FARM_SUBSCRIPTION_ACTIVITIES";

// action creators
export const getFarmSubscriptionActivities = () => async dispatch => {
  try {
    const subHistory = await farmActivity.get("/allSubscriptionHistory");

    dispatch({
      type: GET_FARM_SUBSCRIPTION_ACTIVITIES,
      payload: subHistory.data,
    });
  } catch (getFarmSubscriptionActivitiesError) {
    handleActionError(getFarmSubscriptionActivitiesError);
  }
};

// reducer
const initialState = {
  subscriptionHistory: [],
  subscriptionHistoryCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FARM_SUBSCRIPTION_ACTIVITIES:
      return {
        ...state,
        subscriptionHistory: action.payload.data,
        subscriptionHistoryCount: action.payload.count,
      };
    default:
      return state;
  }
};
