const env = process.env.REACT_APP_PREFERRED_API;

const defaultAdminApi = process.env.REACT_APP_ADMIN;
const defaultMainApi = process.env.REACT_APP_USERS;

const liveAdminApi = process.env.REACT_APP_LIVE_ADMIN;
const stagingAdminApi = process.env.REACT_APP_STAGING_ADMIN;
const localAdminApi = process.env.REACT_APP_LOCAL_ADMIN;

const liveUsersApi = process.env.REACT_APP_LIVE_USERS;
const stagingUsersApi = process.env.REACT_APP_STAGING_USERS;
const localUsersApi = process.env.REACT_APP_LOCAL_USERS;

const ADMIN_BACKEND_API_ID = "admin";

const MAIN_BACKEND_API_ID = "main";

const ENDPOINTS = {
  admin: "admin",
  auth: "admin",
  agent: "agent",
  contractors: "admin/contractors",
  bulkSms: "sms",
  crops: "crops",
  season: "season",
  region: "region",
  farmerClearance: "clearFarmer", // the old key used to refer to clearFarmer model
  clearFarmer: "clearFarmer",
  farmActivity: "subscription",
  farmers: "farmers",
  farmerPayment: "farmerPayment",
  farmerSeason: "farmerSeason",
  farmMonitoring: "farmSupervision",
  farmSupervision: "farmSupervision", // the famous confusion of 021
  farmSupervisionCategory: "farmers/farmSupervisionCategory",
  harvest: "harvest",
  equityContribution: "equityContribution",
  farmMapping: "farmers/farmMapping",
  inputDistribution: "admin/inputDistribution",
  inputs: "crops/inputs",
  language: "language",
  marketplace: "marketplace",
  organization: "organization",
  questionnaire: "questionnaire",
  role: "user/role",
  subscription: "subscription",
  survey: "survey",
  warehouse: "admin/warehouse",
  activationModules: "activationModules",
};

export const apiConstants = {
  currentPrefferedBackendServer: env,
  apiNames: {
    ADMIN_API: ADMIN_BACKEND_API_ID,
    MAIN_API: MAIN_BACKEND_API_ID,
  },
  baseUrls: {
    local: {
      [ADMIN_BACKEND_API_ID]: localAdminApi,
      [MAIN_BACKEND_API_ID]: localUsersApi,
    },
    staging: {
      [ADMIN_BACKEND_API_ID]: stagingAdminApi,
      [MAIN_BACKEND_API_ID]: stagingUsersApi,
    },
    production: {
      [ADMIN_BACKEND_API_ID]: liveAdminApi,
      [MAIN_BACKEND_API_ID]: liveUsersApi,
    },
  },

  versionNames: ["", "api/v1/"],
  endpointsByBackendServer: {
    [ADMIN_BACKEND_API_ID]: [
      ENDPOINTS.admin,
      ENDPOINTS.agent,
      ENDPOINTS.warehouse,
      ENDPOINTS.role,
      ENDPOINTS.questionnaire,
      ENDPOINTS.organization,
      ENDPOINTS.contractors,
    ],
    [MAIN_BACKEND_API_ID]: [
      ENDPOINTS.marketplace,
      ENDPOINTS.bulkSms,
      ENDPOINTS.survey,
      ENDPOINTS.subscription,
      ENDPOINTS.season,
      ENDPOINTS.language,
      ENDPOINTS.inputs,
      ENDPOINTS.harvest,
      ENDPOINTS.farmSupervision,
      ENDPOINTS.farmSupervisionCategory,
      ENDPOINTS.farmerSeason,
      ENDPOINTS.farmers,
      ENDPOINTS.farmerPayment,
      ENDPOINTS.farmActivity,
      ENDPOINTS.crops,
      ENDPOINTS.clearFarmer,
      ENDPOINTS.activationModules,
    ],
  },
  endpoints: ENDPOINTS,
  defaults: {
    ADMIN_API: defaultAdminApi,
    MAIN_API: defaultMainApi,
  },
};
