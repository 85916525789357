import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text } from "organic";

const pageHeaderPropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

const PageHeader = props => {
  const { title, children, subtitle } = props;

  return (
    <Flex display="inline-flex" mb="2rem">
      <Box>
        {title ? (
          <Text as="p" font="displaySmMedium" color="gray900">
            {title}
          </Text>
        ) : null}
        {subtitle ? (
          <Text as="p" font="mdRegular" color="gray500">
            {subtitle}
          </Text>
        ) : null}
      </Box>
      {children ? children : null}
    </Flex>
  );
};

PageHeader.propTypes = pageHeaderPropTypes;

export { PageHeader };
