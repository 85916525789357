/* eslint-disable no-unused-vars */
import { FormInput } from "components/inputs";
import { CropSelect, ProjectSelect, Select } from "components/select";

import { createInputType } from "features/v1/cropsAndInput";
import { Form, Formik } from "formik";
import { Box, Button } from "organic";
import React from "react";
import { useDispatch } from "react-redux";
import { initialCreateInputValues } from "views/v1/Forms/Settings/settingsInitialValues";
import { createInputValidationSchema } from "views/v1/Forms/Settings/settingsValidationSchemas";

function EditPaymentsInfo({ selected, closeModal }) {
  const dispatch = useDispatch();

  const handleCreateInput = (values, formOptions) => {
    dispatch(createInputType(values, { formOptions, closeModal }));
  };

  return (
    <>
      <div>
        <Formik
          validationSchema={createInputValidationSchema}
          initialValues={initialCreateInputValues}
          onSubmit={handleCreateInput}
        >
          {({ setFieldValue, isSubmitting }) => {
            return (
              <>
                <Form>
                  <Box my={40}>
                    <Box mb={4}>
                      <FormInput mb={4} placeholder="Name of Input eg. NPK" label="Input Name" name="name" />
                    </Box>
                    <Box mb={4}>
                      <ProjectSelect
                        name="seasonId"
                        label="Season"
                        onChange={val => setFieldValue("seasonId", val)}
                        ignoreGlobalState
                      />
                    </Box>

                    <Box mb={4}>
                      {/* <Label>Crop</Label> */}
                      {/* <Select onChange={val => setFieldValue("cropId", val)} /> */}
                      <CropSelect label="Crop" name="cropId" onChange={val => setFieldValue("cropId", val)} />
                    </Box>
                    <Box mb={4}>
                      <FormInput
                        type="number"
                        name="input_distribution"
                        label="Input Distribution Round"
                        placeholder="1 or 2 or 3 etc..."
                      />
                    </Box>

                    <Button type="submit" isDisabled={isSubmitting} colorScheme="primary" display="block">
                      Create new inputs
                    </Button>
                  </Box>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default EditPaymentsInfo;
