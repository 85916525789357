export const stateLocations = [
  {
    id: 1,
    lat: 10.61,
    long: 7.43,
    name: "kaduna",
    isAvailable: false,
    dailyForecastSent: false,
    weeklyForecastSent: false,
  },
  {
    id: 2,
    lat: 12.43,
    long: 4.2,
    name: "kebbi",
    isAvailable: false,
    dailyForecastSent: false,
    weeklyForecastSent: false,
  },
  {
    id: 3,
    lat: 12.57,
    long: 8.94,
    name: "jigawa",
    isAvailable: false,
    dailyForecastSent: false,
    weeklyForecastSent: false,
  },
  {
    id: 4,
    lat: 12.0,
    long: 8.52,
    name: "kano",
    isAvailable: false,
    dailyForecastSent: false,
    weeklyForecastSent: false,
  },
  {
    id: 5,
    lat: 8.539,
    long: 7.7082,
    name: "nasarawa",
    isAvailable: false,
    dailyForecastSent: false,
    weeklyForecastSent: false,
  },
  {
    id: 6,
    lat: 4.66403,
    long: 6.036987,
    name: "bayelsa",
    isAvailable: false,
    dailyForecastSent: false,
    weeklyForecastSent: false,
  },
];
