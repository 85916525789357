import React from "react";
import * as SelectPrimitives from "@radix-ui/react-select";
import { Icon } from "organic";
import { classnames } from "utils";

const SelectInput = React.forwardRef(function (
  {
    children,
    className,
    __value,
    placeholder,
    __defaultValue,
    __onChange,
    rounded,
    indicatorElement,
    ...restOfSelectInputProps
  },
  forwardedRef
) {
  const _className = "select-input";
  const _placeholder = placeholder || "Select...";

  let selectInputClassNames = classnames(className, {
    [`${_className}-default`]: !rounded,
    [`${_className}-rounded`]: rounded,
  });

  let triggerClassName = `${selectInputClassNames}__trigger`;
  let contentClassName = `${selectInputClassNames}__content`;
  let viewportClassName = `${selectInputClassNames}__viewport`;
  return (
    <SelectPrimitives.Root className={selectInputClassNames} {...restOfSelectInputProps}>
      <SelectPrimitives.Trigger className={triggerClassName} ref={forwardedRef}>
        <SelectPrimitives.Value placeholder={_placeholder} />
        <SelectPrimitives.Icon>
          {indicatorElement || <Icon icon="chevron-down" size={20} paddingLeft="10px" />}
        </SelectPrimitives.Icon>
      </SelectPrimitives.Trigger>
      <SelectPrimitives.Portal>
        <SelectPrimitives.Content className={contentClassName}>
          <SelectPrimitives.ScrollUpButton>
            <Icon />
          </SelectPrimitives.ScrollUpButton>
          <SelectPrimitives.Viewport className={viewportClassName}>{children}</SelectPrimitives.Viewport>
          <SelectPrimitives.ScrollUpButton>
            <Icon />
          </SelectPrimitives.ScrollUpButton>
        </SelectPrimitives.Content>
      </SelectPrimitives.Portal>
    </SelectPrimitives.Root>
  );
});
SelectInput.displayName = "SelectInput";
SelectInput.defaultProps = {
  rounded: false,
};

const SelectInputOption = React.forwardRef(function (
  { children, className, indicatorElement, ...restOfSelectInputOptionProps },
  forwardedRef
) {
  const _className = "select-input-option";

  const selectItemClassNames = classnames(_className, className);
  return (
    <SelectPrimitives.Item className={selectItemClassNames} {...restOfSelectInputOptionProps} ref={forwardedRef}>
      <SelectPrimitives.ItemText>{children}</SelectPrimitives.ItemText>
      <SelectPrimitives.ItemIndicator>{indicatorElement}</SelectPrimitives.ItemIndicator>
    </SelectPrimitives.Item>
  );
});

SelectInputOption.displayName = "SelectInputOption";

const SelectSeperator = SelectPrimitives.Separator;
const SelectOptionGroup = SelectPrimitives.Group;

export { SelectInput, SelectInputOption, SelectSeperator, SelectOptionGroup };
