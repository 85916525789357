const alltime = new Date("November 20, 2019 00:20:18");
const now = new Date();
let secondstonow = now.getSeconds() * 1000;
let minutetonow = now.getMinutes() * (60 * 1000);
let lastHourtonow = now.getHours() * (60 * 60 * 1000);
let dayRange = new Date(now - (lastHourtonow + minutetonow + secondstonow));
let last7daysMilisec = new Date(now - 7 * 24 * 60 * 60 * 1000); //hr * mins * seconds * milisec
let last4weeksMilisec = new Date(now - 4 * 7 * 24 * 60 * 60 * 1000); //day * hr * mins * seconds * milisec
let last3monthsMilisec = new Date(now - 3 * 4 * 7 * 24 * 60 * 60 * 1000); //week * day * hr * mins * seconds * milisec
let last12monthsMilisec = new Date(now - 12 * 4 * 7 * 24 * 60 * 60 * 1000); //week * day * hr * mins * seconds * milisec
let lastMonthTodateMilisec = new Date(
  now - ((now.getDate() - 1) * (24 * 60 * 60 * 1000) + lastHourtonow + minutetonow + secondstonow)
); //week * day * hr * mins * seconds * milisec
// let lastQuarterTodateMilisec = 12 * (4 * 7 * 24 * 60 * 60 * 1000) //week * day * hr * mins * seconds * milisec
// let lastYearTodateMilisec = 12 * (4 * 7 * 24 * 60 * 60 * 1000) //week * day * hr * mins * seconds * milisec
// let last7days= new Date(today - lastMonthTodateMilisec)

function getQuarter(id) {
  let d = new Date();
  //minus d.getDate() * 60 . 60 . 24
  let quarter = Math.floor(d.getMonth() / 3);
  switch (id) {
    case "Current": {
      let currentStartDate = new Date(d.getFullYear(), quarter * 3, 1);
      let ignoredCurrentEndDateQ = new Date(currentStartDate.getFullYear(), currentStartDate.getMonth() + 3, 0);
      let currentEndDate = d;
      //let newEndDate = new Date(endDate - d.getDate() * (24 * 60 * 60 * 1000))
      return { startDate: currentStartDate, endDate: currentEndDate };
    }
    case "Previous": {
      let startDate = new Date(d.getFullYear(), quarter * 3 - 3, 1);
      let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
      return { startDate, endDate };
    }
  }
}

function getFullYear(id) {
  let d = new Date();
  switch (id) {
    case "Current": {
      let currentStartDate = new Date(d.getFullYear(), 0, 1);
      let ignoredEndDateY = new Date(currentStartDate.getFullYear(), 12, 0);
      let currentEndDate = d;
      return { startDate: currentStartDate, endDate: currentEndDate };
    }
    case "Previous": {
      let startDate = new Date(d.getFullYear() - 1, 0, 1);
      let endDate = new Date(startDate.getFullYear(), 12, 0);
      return { startDate, endDate };
    }
  }
}

let durationOption = [
  {
    value: "today",
    label: "Today",
    range: { startDate: dayRange, endDate: now },
  },
  {
    value: "lastSevenDays",
    label: "Last 7 days",
    range: { startDate: last7daysMilisec, endDate: now },
  },
  {
    value: "last4weeks",
    label: "Last 4 weeks",
    range: { startDate: last4weeksMilisec, endDate: now },
  },
  {
    value: "last3months",
    label: "Last 3 months",
    range: { startDate: last3monthsMilisec, endDate: now },
  },
  {
    value: "last12months",
    label: "Last 12 months",
    range: { startDate: last12monthsMilisec, endDate: now },
  },
  {
    value: "monthtodate",
    label: "Month to date",
    range: { startDate: lastMonthTodateMilisec, endDate: now },
  },
  {
    value: "qurtertodate",
    label: "Quarter to date",
    range: getQuarter("Current"),
  },
  { value: "yeartodate", label: "Year to date", range: getFullYear("Current") },
  {
    value: "allTime",
    label: "All time",
    range: { startDate: alltime, endDate: now },
  },
  { value: "custom", label: "Custom" },
];
// const durationOption = durationOption1.map((item, i) => {
//   item.range = '',
// })

export const getZoneOptionsFromRegionValue = (_regionOptions, _zoneOptions = [], regionValue) => {
  let regionFromRegionOption = _regionOptions.find(region => region.value === regionValue);

  if (regionFromRegionOption) {
    return regionFromRegionOption.zones.filter(zone => _zoneOptions.find(option => option._id === zone));
  }

  return [];
};

export { durationOption };
