import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { getAllRegions, getRegionData } from "features/v1/regions";
import { useDisclosure, useTable } from "hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils";
import CreateRegionForm from "views/forms/createRegionForm";

const RegionSettingsPage = () => {
  let regionData = useSelector(getRegionData);

  const dispatch = useDispatch();

  const { tableProps } = useTable({
    data: regionData,
    columns: [
      {
        Header: "Name",
        accessor: "label",
      },
      {
        Header: "RegionID",
        accessor: "value",
      },
      {
        id: "regionCreatedAt",
        Header: "Created on",
        accessor: region => formatDate(new Date(region?.created_at)),
      },
    ],
    title: "All Regions",
    noViewAction: true,
  });

  useEffect(() => {
    dispatch(getAllRegions());
  }, []);

  const createRegionDisclosure = useDisclosure();

  return (
    <div>
      <Table
        {...tableProps}
        createAction={{ label: "Add New Region" }}
        onCreateAction={() => {
          createRegionDisclosure.onOpen();
        }}
      />
      <Modal {...createRegionDisclosure.modalProps}>
        <ModalContent title="Add New Region">
          <CreateRegionForm modal={createRegionDisclosure.modal} />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default RegionSettingsPage;
