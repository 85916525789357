/* eslint-disable no-unused-vars */
import zone from "api/zone";
import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";

// actions
const GET_ALL_ZONES = "GET_ALL_ZONES";
const LOAD_GET_ALL_ZONES = "LOAD_GET_ALL_ZONES";
// const SET_SELECTED_ZONES = "SET_SELECTED_ZONES";

// action creators

/** create single zone */
export const createSingleZone =
  (values, { setSubmitting, setErrors }) =>
  async dispatch => {
    try {
      const response = await zone.post("/create", values);

      if (response.status === 200) {
        toast.success("Zone created successfully!");
        setSubmitting(false);
      } else if (response.status === 401) {
        toast.error("You are not authorized to do that!");
        setSubmitting(false);
      }
    } catch (createSingleZoneError) {
      handleActionError({ createSingleZoneError });
      setSubmitting(false);
      return dispatch(getAllZones());
    }
  };

/** create multiple zones */
export const createMultipleZones =
  (values, { setSubmitting, setErrors }) =>
  async dispatch => {
    try {
      const response = await zone.post("/createMany", values);

      if (response.status === 200) {
        toast.success("Zones created successfully!");
        dispatch(getAllZones());
        setSubmitting(false);
      } else if (response.status === 401) {
        toast.error("You are not authorized to do that!");
        setSubmitting(false);
      }
    } catch (createMultipleZonesError) {
      handleActionError({ createMultipleZonesError });
      setSubmitting(false);
    }
  };

/** get all zones */
export const getAllZones = () => async dispatch => {
  try {
    dispatch({ type: LOAD_GET_ALL_ZONES, payload: true });
    const zoneData = await zone.get();

    if (zoneData.status === 200) {
      dispatch({ type: GET_ALL_ZONES, payload: zoneData.data.data });
    }
    dispatch({ type: LOAD_GET_ALL_ZONES, payload: false });
  } catch (getAllZonesError) {
    handleActionError({ getAllZonesError });
  }
};
/** Set zones options from external source */
// eslint-disable-next-line no-unused-vars
export const setZones = _data => _dispatch => {};
/** Set zones loading state from external source */
// eslint-disable-next-line no-unused-vars
export const loadZones = _isLoading => _dispatch => {};

/** update zone */
export const updateZone =
  (_values, _config = {}) =>
  _dispatch => {};

/** delete zone */
export const deleteZone =
  (_values, _config = {}) =>
  _dispatch => {};

// initial state
const initialState = {
  zoneData: [],
  loadingZones: false,
  zoneOptions: [],
  selectedZones: null,
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ZONES:
      return {
        ...state,
        zoneData: action.payload,
        zoneOptions: action.payload,
      };
    case LOAD_GET_ALL_ZONES:
      return {
        ...state,
        loadingZones: action.payload,
      };
    // case LOAD_GET_ALL_ZONES:
    //   return {
    //     ...state,
    //     selectedZones: action.payload
    //   };
    default:
      return state;
  }
};

// selector functions
export const getZoneData = state => state.legacyZone.zoneData;
export const getZoneOptions = state => state.legacyZone.zoneOptions;
export const getLoadingZones = state => state.legacyZone.loadingZones;
export const getSelectedZones = state => state.legacyZone.selectedZones;
