import React, { useRef } from "react";
import { uniqueId } from "utils";

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
      borderBottom: "1px solid #EAECF0", // gray20
    },
  },
];

const TableElement = ({ getTableProps, getTableBodyProps, headerGroups, page, prepareRow }) => {
  let tableHeadRef = useRef(null);
  let tableBodyRef = useRef(null);

  const handleHeadScroll = () => {
    const scrollLeft = tableHeadRef.current.scrollLeft;

    tableBodyRef.current.scrollLeft = scrollLeft;
  };
  const handleBodyScroll = () => {
    const scrollLeft = tableBodyRef.current.scrollLeft;

    tableHeadRef.current.scrollLeft = scrollLeft;
  };

  return (
    <div className="table-element-wrapper">
      <div {...getTableProps()} className="table-element">
        <div className="table-head" ref={tableHeadRef} onScroll={handleHeadScroll}>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="table-head__tr" key={uniqueId()}>
              {headerGroup.headers.map(column => (
                <div className="th" {...column.getHeaderProps(headerProps)} key={uniqueId()}>
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {column.canResize && (
                    <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? "isResizing" : ""}`}>
                      {/* <Icon icon="skip-forward" size={16} /> */}
                    </div>
                  )}
                </div>
              ))}
              {/* <div className="th" /> */}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} ref={tableBodyRef} onScroll={handleBodyScroll} className="table-body">
          {page.map(row => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} key={uniqueId()} className="table-body__tr">
                {row.cells.map(cell => (
                  <div className={`td`} {...cell.getCellProps(cellProps)} key={uniqueId()}>
                    {cell.render("Cell")}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TableElement;
