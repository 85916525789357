const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     borderBottom: "1px dotted pink",
  //     color: state.isSelected ? "red" : "blue",
  //     padding: 20
  //   }),

  container: (provided, state) => {
    return {
      ...provided,
      outlineColor: state.isFocused ? "#3BC552" : "hsl(0,0%,80%)",
    };
  },
  control: (provided, __state) => {
    const height = "32px";
    const width = "302px";
    const minHeight = "32px";

    return {
      ...provided,
      height,
      width,
      minHeight,
    };
  },
  dropdownIndicatorStyles: (provided, __state) => {
    let color = "#3BC552";
    return { ...provided, color };
  },
  positioning: (provided, __state) => {
    let marginTop = "0px";
    let marginBottom = "6px";
    return { ...provided, marginTop, marginBottom };
  },
};

const customStylesSmall = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     borderBottom: "1px dotted pink",
  //     color: state.isSelected ? "red" : "blue",
  //     padding: 20
  //   }),

  control: (provided, __state) => {
    const height = "22px";
    const width = "100px";
    const minHeight = "32px";

    return {
      ...provided,
      height,
      width,
      minHeight,
    };
  },
};

const customStylesTiny = {
  container: (provided, state) => ({
    ...provided,
    outlineColor: state.isFocused ? "#3BC552" : "hsl(0,0%,80%)",
    borderColor: state.isFocused ? "#3BC552" : "hsl(0,0%,80%)",
  }),
  option: (provided, __state) => ({
    ...provided,
    // borderBottom: "1px dotted pink",
    fontSize: "12px",
    //   color: state.isSelected ? "red" : "blue",
    padding: 5,
  }),

  control: (provided, state) => ({
    ...provided,
    height: "22px",
    width: "100px",
    minHeight: "32px",
    fontSize: "12px",
    // outlineColor: state.isFocused ? "#3BC552" : "hsl(0,0%,80%)",
    borderColor: state.isHover ? "#3BC552" : "hsl(0,0%,80%)",
    // borderColor: state.isHover ? "#3BC552" : "hsl(0,0%,80%)",
    // borderColor:"#3BC552",
  }),
};

export { customStylesSmall, customStyles, customStylesTiny };
