import { FormInput } from "components/inputs";
import { selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import { useFormikContext } from "formik";
import { useDidMountEffect } from "hooks";
import numeral from "numeral";
import React from "react";
import { createSelectOption } from "utils/v1/selectUtils";

const calcTradeAnticipatedProfit = ({ sellingPrice, targetQuantity, priceToPay, logisticsCost }) => {
  console.log(sellingPrice, targetQuantity, priceToPay, logisticsCost);

  const tradeRevenue = numeral(sellingPrice || 0)
    .multiply(targetQuantity || 0)
    .value();
  const totalInvestment = numeral(priceToPay || 0)
    .multiply(targetQuantity || 0)
    .add(logisticsCost || 0)
    .value();

  console.log(tradeRevenue, totalInvestment);

  return numeral(tradeRevenue || 0)
    .subtract(totalInvestment || 0)
    .value()
    .toFixed(2);
};

export const AnticipatedProfitField = () => {
  const { values, setFieldValue } = useFormikContext();
  useDidMountEffect(() => {
    setFieldValue(
      "anticipated_profit",
      calcTradeAnticipatedProfit({
        sellingPrice: values.selling_price,
        targetQuantity: values.target_quantity,
        priceToPay: values.price_to_pay,
        logisticsCost: values.logistics_cost,
      })
    );
  }, [values.selling_price, values.target_quantity, values.price_to_pay, values.logistics_cost]);
  return (
    <FormInput
      label="Anticipated Profit"
      name="anticipated_profit"
      required
      isDisabled
      type="number"
      hint="Trade revenue - Total Investment"
      min={0}
      max={MAX_NUM_VALUE}
      readOnly
      rightAddon={
        <CurrencySelect
          onChange={currencyValue => {
            setFieldValue("currency", currencyValue);
          }}
          value={createSelectOption(values.currency)}
          colorScheme={selectConstants.colorSchemes.UNSTYLED}
          placeholder="Currency"
        />
      }
    />
  );
};
