import React, { useCallback } from "react";
import { uniqueId } from "utils";

function ChartGrid({ cols, gridData, __gridName, rowHeight, gridGap }) {
  const [cssGridLayout, setCssGridLayout] = React.useState([]);
  const gridRef = React.useRef();

  const cardHeight = rowHeight;
  const rowCount = React.useMemo(() => Math.ceil(gridData.length / cols), [cols, gridData.length]);

  const makeGrid = useCallback(
    ({ gridItems, cols = 1 }) => {
      if (!Array.isArray(gridItems)) {
        return [];
      }
      if (gridItems.length === 0) {
        return [];
      }

      let _generatedLayout = [];
      let counter = 0;
      for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
        for (let colIndex = 0; colIndex < cols; colIndex++) {
          // console.log({ rowIndex, colIndex });
          _generatedLayout[counter] = {
            index: counter.toString(),
            col: colIndex,
            row: rowIndex,
            noOfRows: rowCount,
            gridHeight: 1,
            gridWidth: 1,
            ...gridItems[counter],
          };
          counter++;
        }
      }
      // console.log({ _generatedLayout });
      return _generatedLayout;
    },
    [rowCount]
  );

  React.useEffect(() => {
    const gridLayout = makeGrid({ gridItems: gridData, cols });
    // console.log({ gridLayout });
    setCssGridLayout(gridLayout);
  }, [cols, gridData, makeGrid]);

  return (
    <div
      ref={gridRef}
      style={{
        display: "grid",
        height: "auto",
        gap: gridGap,
        gridTemplateColumns: " 1fr ".repeat(cols),
        gridTemplateRows: " 1fr ".repeat(rowCount),
      }}
    >
      {cssGridLayout.map((gridItem, __gridItemIndex) => {
        let ChartComponent = gridItem.component;
        return (
          <div key={uniqueId()}>
            {ChartComponent ? <ChartComponent cardHeight={cardHeight} chart={gridItem} /> : null}
          </div>
        );
      })}
    </div>
  );
}

ChartGrid.defaultProps = {
  gridData: [],
  gridName: "aosGlDefault",
  isEditable: false,
  cols: 1,
  rowHeight: 150,
  gridGap: 16,
};

export default ChartGrid;
