import * as yup from "yup";

export const createCropValidationSchema = yup.object().shape({
  name: yup.string().required("kindly enter crop name"),
  crop_category: yup.string().required("crop category is required").oneOf(["crop", "livestock"]),
  season: yup.string().required("kindly select a season"),
});

export const updateCropValidationSchema = yup.object().shape({
  name: yup.string().required("kindly enter crop name"),
  organization: yup.string().required(),
  crop_category: yup.string().required("crop category is required").oneOf(["crop", "livestock"]),
  season: yup.string(),
});
