import React from "react";
import * as AccordionPrimitives from "@radix-ui/react-accordion";
import { classnames } from "utils";
import { Icon } from "organic";

const Accordion = AccordionPrimitives.Root;
Accordion.defaultProps = {
  className: "accordion",
};
const AccordionItem = AccordionPrimitives.Item;
AccordionItem.defaultProps = {
  className: "accordion__item",
};

// const AccordionDemo = () => (
//   <AccordionPrimitives.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
//     <AccordionPrimitives.Item className="AccordionItem" value="item-1">
//       <AccordionTrigger>Is it accessible?</AccordionTrigger>
//       <AccordionContent>Yes. It adheres to the WAI-ARIA design pattern.</AccordionContent>
//     </AccordionPrimitives.Item>

//     <AccordionPrimitives.Item className="AccordionItem" value="item-2">
//       <AccordionTrigger>Is it unstyled?</AccordionTrigger>
//       <AccordionContent>
//         Yes. It's unstyled by default, giving you freedom over the look and feel.
//       </AccordionContent>
//     </AccordionPrimitives.Item>

//     <AccordionPrimitives.Item className="AccordionItem" value="item-3">
//       <AccordionTrigger>Can it be animated?</AccordionTrigger>
//       <AccordionPrimitives.Content className="AccordionContent">
//         <div className="AccordionContentText">
//           Yes! You can animate the Accordion with CSS or JavaScript.
//         </div>
//       </AccordionPrimitives.Content>
//     </AccordionPrimitives.Item>
//   </AccordionPrimitives.Root>
// );

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitives.Header className="accordion__header">
    <AccordionPrimitives.Trigger className={classnames("accordion__trigger", className)} {...props} ref={forwardedRef}>
      {children}
      <Icon icon="chevron-down" className="accordion-chevron" size="20px" />
    </AccordionPrimitives.Trigger>
  </AccordionPrimitives.Header>
));

AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitives.Content className={classnames("accordion__content", className)} {...props} ref={forwardedRef}>
    {children}
  </AccordionPrimitives.Content>
));

AccordionContent.displayName = "AccordionContent";

export { Accordion, AccordionItem, AccordionContent, AccordionTrigger };
