import _typography from "organic/theme/mixins/_typography";
import styled, { css } from "styled-components/macro";
import { borders, layout } from "styled-system";
import { Text } from "organic";
import StyledButton from "../button/styledButton";
// import { BaseButton } from "../button/button";

const Nav = styled.nav`
  ${props => css`
display: flex;
position: relative;
width: 100%;
flex-direction: ${props => (props.vertical === true ? "column" : "row")};
li {
  list-style: none;
}
& ${StyledButton} {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content space-between;
  border-radius: 6px;
  text-align: center;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  margin-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  ${Text} {
    color: ${props.theme.colors.gray700};
    ${_typography.mdMedium}
    margin-left: 14px;
  }
  svg {
    stroke: ${props.theme.colors.gray500};
  }

  &:hover {
    background-color: ${props.theme.colors.primary50};
    color: ${props.theme.colors.primary500};
    ${Text} {
      color: ${props.theme.colors.primary500};
    }
    svg {
      stroke: ${props.theme.colors.primary700};
    }
  }

}
`}
  ${layout}
  ${borders}
`;

Nav.defaultProps = {
  vertical: true,
};

export default Nav;
