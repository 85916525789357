import { Icon, Text } from "organic";
import React from "react";
import { formatDate } from "utils";

const MobilePreview = ({ children, title }) => {
  return (
    <div className="mobile-preview">
      <div className="phone-case">
        <div className="phone-speaker">
          <div className="speaker-bar"></div>
          <div className="camera"></div>
        </div>
        <div className="phone-screen">
          <div className="screen-time">{formatDate(new Date(), "HH: mm")}</div>
          <div className="screen-reception">
            <div className="screen-reception-bars">
              <Icon size={12} icon="bar-chart" svgProps={{ strokeWidth: "3px" }} />
            </div>
            <div className="screen-reception-wifi">
              <Icon size={12} icon="wifi" svgProps={{ strokeWidth: "3px" }} />
            </div>
            <div className="screen-reception-battery">
              <Icon size={12} icon="battery" svgProps={{ strokeWidth: "3px" }} />
            </div>
          </div>
          <div className="screen-title-bar">
            <Icon icon="chevron-left" size={20} /> <Text>{title}</Text> <div />
          </div>
          <div className="screen-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MobilePreview;
