import React, { useEffect } from "react";
// import season from "api/season";
import { Table } from "components/table";
import {
  getAllSeasons,
  //  getLoadingSeasons,
  getSeasonData,
  getSeasonOptions,
  transferFarmersToSeason,
} from "features/v1/season";
import {
  useDisclosure,
  // useDisclosure,
  useTable,
} from "hooks";
import { Badge, badgeConstants } from "organic";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils";
import CreateProjectForm from "views/forms/createProjectForm";
import { Modal, ModalContent } from "components/modal";
import EditProjectDetails from "./project/editProjectDetails";
import { toast } from "react-toastify";
import seasonApi from "api/season";
import { ProjectSelect } from "components/select";
import { handleActionError } from "services/shared/AOSErrorService";
import Card from "components/card/card";
import { selectOptionFromValue } from "utils/v1/selectUtils";

const TRANSFER_FARMERS_KEY = "transferFarmersTo";

const ProjectSettingsPage = () => {
  const dispatch = useDispatch();

  let seasonData = useSelector(getSeasonData);
  let seasonOptions = useSelector(getSeasonOptions);
  // let loading = useSelector(getLoadingSeasons);

  const createProjectDisclosure = useDisclosure();
  // const viewProjectDisclosure = useDisclosure()
  const editProjectDisclosure = useDisclosure();

  const { tableProps, selectedRow: selectedProject } = useTable({
    data: seasonData,
    columns: [
      {
        Header: "Project ID",
        accessor: "seasonId",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        id: "projectStartDate",
        Header: "Start Date",
        accessor: season => formatDate(new Date(season.duration.start_date)),
      },
      {
        id: "projectEndDate",
        Header: "End Date",
        accessor: season => formatDate(new Date(season.duration.end_date)),
      },
      {
        id: "projectStatus",
        Header: "Status",
        accessor: "isActive",
        Cell: ProjectStatusBadge,
      },
    ],
    title: "All Projects",
    noViewAction: true,
    canDelete: true,
  });

  const handleProjectDelete = projectToDelete => {
    // let canProceed = authLevelChecker();
    // FIXME: use useROle instead
    // if (canProceed) {
    seasonApi
      .delete(`/${projectToDelete._id}`, { params: { id: projectToDelete._id } })
      .then(response => {
        if (response.status === 200) {
          // setDeleteModalVisible(false);
          dispatch(getAllSeasons());
          toast.success("Season deleted successfully!");
        } else if (response.status === 401) {
          // setDeleteModalVisible(false);
          toast.error("You are not authorized to do that!");
        }
      })
      .catch(__err => {
        toast.error("an error occured");

        // setDeleteModalVisible(false);
      });
    // } else {
    //   toast.error("you don't have permission to delete seasons");
    // }
  };

  const handleTransferFarmerTo = ({ from, to }) => {
    try {
      const values = { newSeasonId: to };
      const seasonFrom = selectOptionFromValue(seasonOptions, from);
      const seasonTo = selectOptionFromValue(seasonOptions, to);
      dispatch(transferFarmersToSeason(values, { seasonTo, seasonFrom, from }));
    } catch (transferFarmersToError) {
      handleActionError(transferFarmersToError);
    }
  };

  const getExtraProjectActions = selectedProject => {
    return [
      {
        label: "Transfer Farmers to",
        actionKey: TRANSFER_FARMERS_KEY,
        icon: "corner-down-right",
        component: (
          <Card>
            <ProjectSelect
              ignoreGlobalState
              onChange={projectId => {
                handleTransferFarmerTo({ from: selectedProject._id, to: projectId });
              }}
            />
          </Card>
        ),
      },
    ];
  };

  useEffect(() => {
    dispatch(getAllSeasons());
  }, []);
  return (
    <div>
      <Table
        {...tableProps}
        createAction={{ label: "Add New Project" }}
        onCreateAction={() => {
          createProjectDisclosure.onOpen();
        }}
        onEditAction={() => {
          editProjectDisclosure.onOpen();
        }}
        onDeleteAction={selectedAction => {
          handleProjectDelete(selectedAction.selected);
        }}
        getExtraActions={getExtraProjectActions}
      />
      <Modal
        open={createProjectDisclosure.isOpen}
        onOpenChange={open => {
          //  example of on modal close
          if (open === false) {
            createProjectDisclosure.onClose();
          }
        }}
      >
        <ModalContent title="Add new Project">
          <CreateProjectForm modal={createProjectDisclosure.modal} />
        </ModalContent>
      </Modal>
      <Modal
        open={editProjectDisclosure.isOpen}
        onOpenChange={open => {
          if (open === false) {
            editProjectDisclosure.onClose();
          }
        }}
      >
        <ModalContent title="Edit Project Details">
          <EditProjectDetails selected={selectedProject} modal={editProjectDisclosure.modal} />
        </ModalContent>
      </Modal>
    </div>
  );
};

const ProjectStatusBadge = ({ value }) =>
  value === true ? (
    <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>Active</Badge>
  ) : (
    <Badge colorScheme={badgeConstants.colorSchemes.GRAY}>Inactive</Badge>
  );

export default ProjectSettingsPage;
