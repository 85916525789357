const AOS_DOMAIN = "aosafrica.com";
const FIDA_DOMAIN = "myfida.com";
const STAGING_DOMAIN = "aosafricatest.com";
/** Return first level subdomain */
const getFirstLevelSubdomain = () => {
  let hostArray = location.host.split(".");
  return hostArray[hostArray.length - 3];
};
/** Return domain name without extension(.com) */
const getHostName = () => {
  return location.hostname;
};

/** get value of customer useful for x-aos-customerid header */
export const getCustomerID = () => {
  const domain = getHostName();
  if (domain === "localhost" || domain === "127.0.01") return process.env.REACT_APP_LOCAL_CUSTOMERID || "sprout";
  const subdomain = getFirstLevelSubdomain();
  if (!subdomain) return "";
  if (subdomain === "staging" || subdomain === "staging-thrive-aos-web") return "sprout";
  if (subdomain === "app" || subdomain === "staging-thriveagric" || subdomain === "sandbox") return "thriveagric";
  return subdomain;
};

export const getCurrentAosVersion = () => {
  const version = process.env.REACT_APP_AOS_VERSION;
  if (version) {
    return version;
  }
  throw new Error("env var REACT_APP_AOS_VERSION is not set, check .env");
};

/** determine whether user is accessing the web from fida or aos domain */
export const getAOSFlavour = () => {
  const hostName = getHostName();

  if (hostName === "localhost" || hostName.includes(STAGING_DOMAIN)) {
    return "local";
  }
  if (hostName.includes(AOS_DOMAIN)) {
    return "aos";
  }

  if (hostName.includes(FIDA_DOMAIN)) {
    return "fida";
  }
  return "local";
};
