/* eslint-disable no-unused-vars */
import React from "react";
import { useGetAllExceptGroups } from "hooks/app/manageModule/useGetAllExceptGroups";
import Select2 from "./select2";

const ExceptGroup = ({ name, placeholder, selectedSeason, isMulti }) => {
  const { data: exceptGroups } = useGetAllExceptGroups({ params: { season: selectedSeason } });

  const except_groups = exceptGroups
    ? exceptGroups.map(group => ({
        value: JSON.stringify(group),
        label: group.agentName,
      }))
    : [];

  return (
    <Select2
      name={name}
      label="Except by Group *"
      placeholder={placeholder}
      isMulti={isMulti}
      options={except_groups}
    />
  );
};

export default ExceptGroup;
