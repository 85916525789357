export const defaultGridLayoutConfig = {
  // custom AOS Grid Layout Components props same as config passed to useGridLayout hook
  gridName: "sampleGridName",
  isEditable: false, // controls both the isResizeable and isDraggable props of RGL
  data: [],
  // RGL Props
  className: "",
  style: {},

  // If true, the container height swells and contracts to fit contents
  autoSize: true,
  // # of cols.
  cols: 12,

  // A selector that will not be draggable.
  draggableCancel: "",
  // A selector for the draggable handler
  draggableHandle: "",

  // Choose vertical or hotizontal compaction
  compactType: "vertical", // 'vertical' | 'horizontal'

  // layout is an array of object with the format:
  // {x: Number, y: Number, w: Number, h: Number, i: String}
  layout: [],

  //
  // Grid Dimensions
  //
  margin: [0, 0],
  // Padding inside the container [x, y] in px
  containerPadding: [0, 0],
  // Rows have a static height, but you can change this based on breakpoints if you like
  rowHeight: 150,
  // Default Infinity, but you can specify a max here if you like.
  // Note that this isn't fully fleshed out and won't error if you specify a layout that
  // extends beyond the row capacity. It will, however, not allow users to drag/resize
  // an item past the barrier. They can push items beyond the barrier, though.
  // Intentionally not documented for this reason.
  maxRows: Infinity,

  //
  // Flags
  //
  isBounded: false,
  isDraggable: true,
  isResizable: true,
  // transformScale: 1,
  // If true, grid can be placed one over the other.
  allowOverlap: false,
  // If true, grid items won't change position when being dragged over.
  preventCollision: false,
  // Use CSS transforms instead of top/left
  useCSSTransforms: true,
  // parent layout transform scale
  // transformScale: PropTypes.number,
  // If true, an external element can trigger onDrop callback with a specific grid position as a parameter
  isDroppable: false,

  // Resize handle options
  resizeHandles: ["se"],
  // resizeHandle: () => null,

  //
  // Callbacks
  //

  // Callback so you can save the layout. Calls after each drag & resize stops.
  onLayoutChange: () => {},

  // Calls when drag starts. Callback is of the signature (layout, oldItem, newItem, placeholder, e, ?node).
  // All callbacks below have the same signature. 'start' and 'stop' callbacks omit the 'placeholder'.
  onDragStart: () => {},
  // Calls on each drag movement.
  onDrag: () => {},
  // Calls when drag is complete.
  onDragStop: () => {},
  //Calls when resize starts.
  onResizeStart: () => {},
  // Calls when resize movement happens.
  onResize: () => {},
  // Calls when resize is complete.
  onResizeStop: () => {},
  // Calls when some element is dropped.
  onDrop: () => null,

  //
  // Other validations
  //
  // droppingItem: { i: "0", w: 1, h: 1 },
  // innerRef: null,
};
