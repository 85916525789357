import { organicTheme } from "organic";

/** create a mobile first media query string */
export const createMediaQuery = n => `@media screen and (min-width: ${n})`;

/** add a unit to to a numeric value
 * TODO: add validation of css units
 * TODO: add validation that number value is a number
 */
export const addUnit = (value, unit = "px") => `${value}${unit || ""}`;

/** check and return true if value is an actual object */
export function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function");
}

export function isPlainObject(x) {
  return (
    x !== null &&
    typeof x === "object" &&
    /* a check for empty prototype would be more typical, but that
         doesn't play well with objects created in different vm contexts */
    (!x.constructor || x.constructor.name === "Object") &&
    (x.toString ? x.toString() : Object.prototype.toString.call(x)) === "[object Object]" &&
    /* check for reasonable markers that the object isn't an element for react & preact/compat */
    !("props" in x && (x.$$typeof || x.constructor === undefined))
  );
}

export function isFunction(test) {
  return typeof test === "function";
}

// a utility function to get initials from a name
export const getInitials = name => {
  const names = name.split(" ");
  return names.length > 1 ? names[0][0]?.toUpperCase() + names[1][0]?.toUpperCase() : names[0][0]?.toUpperCase();
};

// a utility to generate a color that perfectly contrast a given color hex
export const getContrastColor = color => {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

// a utility to generate a random hex value
export const getRandomHex = ({ foreground = true }) => {
  const random = Math.floor(Math.random() * 16777215).toString(16);
  return foreground ? `#${random}` : `#${"0".repeat(6 - random.length)}${random}`;
};

export const generateColors = (colors = [Object.values(organicTheme.colors)]) => {
  const bgColor = colors[Math.floor(Math.random() * colors.length)];
  const color = getContrastColor(bgColor);
  return {
    bgColor,
    color,
  };
};

// a utility to parse a size string and determine if it a size in px, rem, em, or % or  one of the following: xs, sm, md, lg, xl, xxl and return the value for the width and height of the avatar in px by mapping the sizes: xs: 24px, sm: 32px, md: 40px, lg: 48px, xl: 56px, xxl: 64px
export const parseSize = size => {
  if (size.includes("px") || size.includes("rem") || size.includes("em") || size.includes("%")) {
    return size;
  } else if (size === "xs") {
    return "24px";
  } else if (size === "sm") {
    return "32px";
  } else if (size === "md") {
    return "40px";
  } else if (size === "lg") {
    return "48px";
  } else if (size === "xl") {
    return "56px";
  } else if (size === "xxl") {
    return "64px";
  } else {
    return "40px";
  }
};
