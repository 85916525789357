import { FormInput } from "components/inputs";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import React from "react";

const ActualTargetQuantityInput = () => {
  return (
    <FormInput
      name="target_quantity"
      type="number"
      min={0}
      max={MAX_NUM_VALUE}
      placeholder="Quantity Sourced"
      label="Quantity Sourced (KG)"
      required
    />
  );
};

export default ActualTargetQuantityInput;
