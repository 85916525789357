import { useDataFilter, useTable } from "hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Text, badgeConstants } from "organic";
import { useDispatch, useSelector } from "react-redux";
import { createCampaign, getAllCampaignData, getAllCampaigns } from "features/v1/bulkSms";
import { capitalize, formatDate } from "utils";
import { PageHeader, PageWrapper } from "components/layout";
import { Table } from "components/table";
import numeral from "numeral";
import { sanitizeMongoDoc } from "utils/v1/sanitize";

const campaignStatusBadges = {
  inactive: badgeConstants.colorSchemes.ORANGE,
  processing: badgeConstants.colorSchemes.BLUE_LIGHT,
  active: badgeConstants.colorSchemes.SUCCESS,
};

const VIEW_CAMPAIGN_KEY = "viewCampaign";
const REPEAT_CAMPAIGN_KEY = "repeatCampaign";

const SmsCampaign = () => {
  const dispatch = useDispatch();
  let campaignData = useSelector(getAllCampaignData);

  const navigate = useNavigate();

  const { filterParams } = useDataFilter();

  const { tableProps } = useTable({
    columns: [
      // { Header: "ID", accessor: "campaignId" },
      {
        Header: "Campaign Title",
        accessor: "campaign_title",
        Cell: ({ value }) => (
          <Text font="smMedium" color="primary600">
            {capitalize(value)}
          </Text>
        ),
      },
      { Header: "Sender ID", accessor: "sender_id" },
      { id: "charCount", Header: "Character Count", accessor: campaign => campaign?.recipient?.length || "N/A" },
      {
        id: "messageCount",
        Header: "Messages",
        accessor: campaign => numeral(numeral(campaign.content?.length).divide(160).value()).add(1).format("0"),
      },
      { Header: "Date/Time", accessor: camapign => formatDate(camapign.created_at) },
      {
        id: "campaignStatus",
        Header: "Status",
        accessor: "campaign_status",
        Cell: ({ value }) => <Badge colorScheme={campaignStatusBadges[`${value}`]}>{capitalize(value)}</Badge>,
      },
    ],
    data: campaignData,
    title: "All Campaigns",
    noViewAction: true,
    noEditAction: true,
  });
  useEffect(() => {
    dispatch(getAllCampaigns(filterParams));
  }, []);
  return (
    <PageWrapper>
      <PageHeader title="Messaging > SMS Campaign" subtitle="Manage all SMS campaign and activity." />
      {/* <SummaryGrid
        data={[
          { title: "SMS Sent", component: <Text font="displayXsBold">100,000</Text> },
          {
            title: "SMS Delivered",
            component: (
              <Text font="displayXsBold">
                95,000
                <Text color="primary500" font="displayXsBold" as="span">
                  /95%
                </Text>
              </Text>
            ),
          },
          {
            title: "SMS Not Delivered",
            component: (
              <Text font="displayXsBold">
                5,000
                <Text color="primary500" font="displayXsBold" as="span">
                  /5%
                </Text>
              </Text>
            ),
          },
        ]}
      /> */}
      <Table
        {...tableProps}
        createAction={{ label: "Create Campaign" }}
        onCreateAction={() => {
          navigate("/dashboard/messaging/create-campaign");
        }}
        getExtraActions={() => [
          // { actionKey: VIEW_CAMPAIGN_KEY, label: "View Campaign" },
          { actionKey: REPEAT_CAMPAIGN_KEY, label: "Repeat Campaign" },
        ]}
        onDataFilter={filterParams => {
          dispatch(getAllCampaigns(filterParams));
        }}
        onExtraAction={(selectedAction, { __filterParams }) => {
          if (selectedAction.actionKey === VIEW_CAMPAIGN_KEY) {
            navigate(`${selectedAction.selected._id}/view-report`);
          }

          if (selectedAction.actionKey === REPEAT_CAMPAIGN_KEY) {
            dispatch(
              createCampaign(
                sanitizeMongoDoc(selectedAction.selected, [
                  "sms_status",
                  "campaign_status",
                  "send_status",
                  "deleted_at",
                ]),
                {}
              )
            );
          }
        }}
      />
    </PageWrapper>
  );
};

export default SmsCampaign;
