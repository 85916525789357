import { useLoading } from "context";
import React from "react";
import styled, { keyframes } from "styled-components";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const neonShadow = keyframes`
  0%, 100% {
    box-shadow: 0 0 1px #ecffe5, 0 0 2px #ecffe5, 0 0 3px #ecffe5, 0 0 4px #ecffe5;
  }
  50% {
    box-shadow: 0 0 2px #ecffe5, 0 0 3px #ecffe5, 0 0 4px #ecffe5, 0 0 5px #ecffe5, 0 0 6px #ecffe5;
  }
`;

const fadeInOut = keyframes`
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const LoaderSpinner = styled.div`
  border: 2px solid #ecffe5;
  border-top: 2px solid #247809;
  border-radius: 50%;
  background-color: #ecffe5;
  width: 16px;
  height: 16px;
  animation: ${spin} 0.5s linear infinite, ${neonShadow} 1s ease-in-out infinite, ${fadeInOut} 5s linear;
  margin: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: calc(4rem + 5px);
  right: 20px;
  z-index: 9999;
`;

export const LoadingState = () => {
  const { isLoading } = useLoading();
  return isLoading ? <LoaderSpinner /> : null;
};
