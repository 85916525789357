const { createSlice } = require("@reduxjs/toolkit");

const initialAuthState = {};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {},
  exrtraReducers: __builder => {},
});

export default authSlice.reducer;
