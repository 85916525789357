// import PropTypes from 'prop-types'
import { Button, Flex } from "organic";
import React, { Component } from "react";

const handleGoHome = () => {
  window.location.pathname = "/dashboard/overview";
};

export default class Err404Page extends Component {
  //   static propTypes = {second: third}

  render() {
    return (
      <div
        style={{
          backgroundColor: "#ececec",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // borderRadius: 8,
          padding: 16,
          width: "100%",
          height: "100%",
        }}
      >
        <span className="display-1 text-primary font-weight-bold">OOPS!</span>
        <h2>Sorry, something went wrong</h2>
        <h4>an error has occured</h4>
        <h6>
          If you&apos;re expriencing this error for the first time, kindly click report error below
          <br />
          Try Refreshing the page, if this doesn&apos;t help Go back to the home page
        </h6>

        <Flex gap="1rem" my="1rem">
          <Button onClick={() => handleGoHome()}>Go Back to Home Page</Button>
          {/* </Link> */}
        </Flex>
      </div>
    );
  }
}
