import isFunction from "lodash/isFunction";

const selectValueFromLabel = (options, label) => {
  let result = options.find(option => option.label === label);
  if (result) {
    return result.value;
  }
  return result;
};
const selectLabelFromValue = (options, value) => {
  let result = options.find(option => option.value === value);
  if (result) {
    return result.label;
  }
  return result;
};
const selectOptionFromLabel = (options, label) => {
  return options.find(option => option.label === label);
};
const selectOptionFromValue = (options, value) => {
  return options.find(option => option.value === value);
};

const selectOptionFromExtraField = (options, extraFieldName, config = {}) => {
  if (isFunction(config?.resolver)) {
    return options.find(option => config.resolver(option) === extraFieldName);
  }
  return options.find(option => option[`${extraFieldName}`] === extraFieldName);
};

const createSelectOption = (label, value) => {
  return {
    label,
    value,
  };
};

export {
  selectLabelFromValue,
  selectValueFromLabel,
  selectOptionFromValue,
  selectOptionFromLabel,
  selectOptionFromExtraField,
  createSelectOption,
};
