import React, { useEffect, useState } from "react";
import { formatFileSize, useCSVDownloader, useCSVReader } from "react-papaparse";
import { toast } from "react-toastify";
import { getOrg } from "services/v1/authService";
import Icon from "../icon/icon";
import { Modal, ModalContent } from "components/modal";
import { useCallbackRef, useDisclosure } from "hooks";
import { Box, Button, CsvPreview, Flex, Text, buttonConstants } from "..";
import { flattenDeep, appGet, isFunction, isNotEmptyObject, snakeCase } from "utils";
import { organicTheme } from "organic";
import {
  CsvDropZone,
  CsvFile,
  CsvFileInfo,
  CsvFileName,
  CsvFileSelecedtIcon,
  CsvFileSelect,
  CsvFileSelectButton,
  CsvFileSelectIcon,
  CsvFileSelectSubtitle,
  CsvFileSelectTitle,
  CsvFileSize,
  CsvFileTemplateButton,
  CsvProgressBar,
  CsvRemove,
} from "./styled-components/styledCsvUpload";
// import { AOSClientError } from "services/AOSErrorService";

const orgId = getOrg();

const CsvUpload = ({
  cols = [],
  sample,
  loading,
  onChange,
  name = "",
  appendOrgId = true,
  orgIdKey = "organization",
  filterFn,
  preProcesssorFn,
  postProcessorFn,
  validationSchema,
}) => {
  const [errors, setErrors] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [__isDisabled, setIsDisabled] = useState(true);
  const [data, setData] = useState([]);

  const { CSVReader } = useCSVReader();
  const { CSVDownloader } = useCSVDownloader();
  const [zoneHover, setZoneHover] = useState(false);

  const previewDisclosure = useDisclosure();

  function reportError(
    error = {
      type: "", // A generalization of the error
      code: "", // Standardized error code
      message: "", // Human-readable details
      row: "", // Row index of parsed data where error is
    }
  ) {
    // set a new Error message based type and code of error
    const errorCode = error?.row ? `${error.code} on Row ${error.row}` : error.code;
    const errorMsg = `${errorCode}: ${error?.message}`;

    // const newError = new AOSClientError(errorMsg);
    const newError = {
      ...error,
      message: errorMsg,
    };
    setErrors(oldErrs => [...oldErrs, newError]);
  }

  const onChangeCallback = useCallbackRef(onChange, []);
  const uploadPreviewDisclosure = useDisclosure();
  const disableBtn = () => {
    setIsDisabled(true);
  };
  const enableBtn = () => {
    setIsDisabled(false);
  };
  const clearErrors = () => {
    setErrors([]);
  };
  const clearData = () => {
    setData([]);
  };

  const ignoredshowModal = () => {
    previewDisclosure.onOpen();
  };

  const confirmColsProp = ({ cols }) => {
    const errors = [];
    if (!cols) {
      const error = {
        code: "DataValidationError",
        type: "",
        message: "cols props should be an array of string, no cols prop defined",
        row: "all rows",
      };
      // toast.error(error.message);
      errors.push(error);
    }

    if (!cols?.length) {
      const error = {
        code: "DataValidationError",
        type: "",
        message: "cols props array cannot be empty, cols prop defined as empty array",
        row: "all rows",
      };
      // toast.error(error.message);
      errors.push(error);
    }

    return errors;
  };

  const handleVendorErrors = resultObject => {
    let issues = [];

    if (resultObject?.errors?.length > 0 && resultObject?.errors?.length < 10) {
      issues = flattenDeep(resultObject.errors);
      issues = issues.filter(issue => {
        if (issue.message === "Unable to auto-detect delimiting character; defaulted to ','") {
          return false;
        }
        return true;
      });
    }

    if (resultObject?.errors?.length > 10) {
      toast.error("this file contains too many error, kindly check it and try again");
      issues = flattenDeep(resultObject.errors.slice(0, 10));
    }

    return issues;
  };

  const filterData = resultObject => {
    return new Promise((resolve, reject) => {
      let data = [];
      let issues = [];
      try {
        setProcessing(true);

        issues = [...issues, ...confirmColsProp({ cols })];

        issues = [...issues, ...handleVendorErrors(resultObject)];

        if (resultObject.data.length > 0) {
          data = resultObject.data
            .map((datum, datumIndex) => {
              let _transformedDatum = datum;

              // preprocess data
              if (isFunction(preProcesssorFn)) {
                _transformedDatum = preProcesssorFn(datum);
              }

              // validation
              if (validationSchema && isNotEmptyObject(validationSchema)) {
                cols.forEach(col => {
                  if (appGet(validationSchema, col, false)) {
                    const error = validationSchema[`${col}`]?.(_transformedDatum);
                    if (error) {
                      // report error
                      const validationError = { row: datumIndex, code: "ValidationError", message: error, type: "" };
                      issues.push(validationError);
                      reportError(validationError);
                    }
                  }
                });
              }

              // postprocess data
              if (isFunction(postProcessorFn)) {
                _transformedDatum = postProcessorFn(datum);
              }

              // appendFields
              if (appendOrgId) {
                _transformedDatum[`${orgIdKey}`] = orgId;
              }

              return _transformedDatum;
            })
            .filter(unfilteredDatum => {
              // filtering
              if (!isFunction(filterFn)) {
                return true;
              }
              const isValid = filterFn(unfilteredDatum);
              return isValid;
            });
        }
        setProcessing(false);
        // console.log({ data, issues });
        resolve({ data, issues });
      } catch (error) {
        setProcessing(false);
        const bug = new Error(error.message);
        // console.log("from filterData catch block",{error})
        // bug.source = error;
        reject(bug);
      }
    });
  };

  // accepted file uploaded
  const _handleFileAccepted = async resultObj => {
    try {
      clearErrors();
      clearData();
      setFileUploaded(true);
      let { data, issues } = await filterData(resultObj);
      setProcessing(false);
      setData(data);
      setErrors(issues);
      enableBtn();
    } catch (error) {
      setProcessing(false);
      disableBtn();
      toast.error(error?.message || "Error occured after loading file, please try again");
    }
  };

  // eslint-disable-next-line no-unused-vars
  const _handleFileRejected = (err, __event) => {
    toast.error("File Rejected: " + err.message);
    setFileUploaded(false);
    clearData();
    clearErrors();
    reportError({
      type: "FileRejected",
      row: "N/A",
      code: err.code,
      message: err.message,
    });
  };

  const _handleOnRemoveFile = event => {
    disableBtn();
    clearData();
    clearErrors();
    setFileUploaded(false);
    event.stopPropagation();
  };

  useEffect(() => {
    onChangeCallback?.({ data, errors });
    // console.log({ data, errors });
    if (errors?.length) {
      toast.error("your csv upload contains some errors, kindly confirm and try again");
      uploadPreviewDisclosure.onOpen();
    }
  }, [data, errors]);

  return (
    <Flex flexDirection="column" gap="0.5rem">
      <CSVReader
        onUploadAccepted={results => {
          _handleFileAccepted(results);
        }}
        onUploadRejected={(err, event) => {
          _handleFileRejected(err, event);
        }}
        onDragOver={event => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={event => {
          event.preventDefault();
          setZoneHover(false);
        }}
        config={{
          header: true,
          worker: true,
          skipEmptyLines: "greedy",
        }}
        accept="text/csv, .csv"
        // progressBarColor="#22964b"
        onRemoveFile={_handleOnRemoveFile}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <Box>
            <CsvDropZone {...getRootProps()} zoneHover={zoneHover}>
              {acceptedFile && fileUploaded ? (
                <CsvFile>
                  <Flex>
                    <CsvFileSelecedtIcon>
                      <Icon icon="file" color={organicTheme.colors.primary600} />
                    </CsvFileSelecedtIcon>
                    <CsvFileInfo>
                      <CsvFileName>{acceptedFile.name}</CsvFileName>
                      <CsvFileSize>{formatFileSize(acceptedFile.size)}</CsvFileSize>
                    </CsvFileInfo>
                  </Flex>
                  <CsvProgressBar as={ProgressBar} />
                  <CsvRemove
                    {...getRemoveFileProps()}
                    onMouseOver={event => {
                      event.preventDefault();
                    }}
                    onMouseOut={event => {
                      event.preventDefault();
                    }}
                    onMouseDown={_handleOnRemoveFile}
                  >
                    <Icon icon="trash-2" size={20} color={organicTheme.colors.gray500} />
                    {/* <Remove color={removeHoverColor} /> */}
                  </CsvRemove>
                </CsvFile>
              ) : (
                <CsvFileSelect>
                  <CsvFileSelectIcon>
                    <Icon icon="upload-cloud" size={20} />
                  </CsvFileSelectIcon>
                  <Flex flexDirection="column">
                    <CsvFileSelectTitle>
                      <CsvFileSelectButton>
                        <Text as="span" font="smSemibold" color="primary700">
                          Click to upload
                        </Text>{" "}
                        or drag and drop file
                      </CsvFileSelectButton>
                    </CsvFileSelectTitle>
                    <CsvFileSelectSubtitle>Accepted file format: CSV, XLSX</CsvFileSelectSubtitle>
                  </Flex>
                </CsvFileSelect>
              )}
            </CsvDropZone>
          </Box>
        )}
      </CSVReader>
      <Flex alignItems="center" gap="12px">
        {/* <CsvFileSelectSubtitle>Accepted file format: CSV, XLSX</CsvFileSelectSubtitle> */}
        <CsvFileTemplateButton>
          {sample ? (
            <Flex alignItems="center" gap="1rem">
              <CSVDownloader
                bom={true}
                data={sample}
                filename={snakeCase(`sample AOS ${name} file`)}
                style={{ cursor: "pointer" }}
                type="link"
              >
                <Flex gap="0.5rem">
                  <Icon icon="download" color={organicTheme.colors.primary600} size={20} />
                  <Text color="primary600" font="smRegular">
                    Download Sample
                  </Text>
                </Flex>
              </CSVDownloader>
              <Button variant={buttonConstants.variants.UNSTYLED} onClick={() => uploadPreviewDisclosure.onOpen()}>
                <Flex gap="0.5rem">
                  <Icon icon="eye" color={organicTheme.colors.blue600} size={20} />
                  <Text color="blue600" font="smRegular">
                    Show Errors/Preview
                  </Text>
                </Flex>
              </Button>
            </Flex>
          ) : null}
        </CsvFileTemplateButton>
      </Flex>
      <Flex>{processing && "Processing..."}</Flex>
      <Modal {...uploadPreviewDisclosure.modalProps}>
        <ModalContent title="Csv Upload Preview">
          <CsvPreview loading={loading} errors={errors} data={data} cols={cols} />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export { CsvUpload };
