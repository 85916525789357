import harvest from "api/harvest";
import { ExportButton } from "components/export";
import { PageHeader } from "components/layout";
import { Table } from "components/table";
import { PageWrapper } from "components/layout";
import {
  getAllHarvestData,
  getAllHarvestDataWithParams,
  getAllHarvestMeta,
  // getLoadingHarvestData,
} from "features/v1/harvest";
import { useTable } from "hooks";
import { Flex } from "organic";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { legacyFormatDate } from "utils/v1/tableUtils";
import { formatDate } from "utils";

const HarvestPage = () => {
  const dispatch = useDispatch();

  let allHarvestData = useSelector(getAllHarvestData);
  // let loadingHarvestData = useSelector(getLoadingHarvestData);
  let allHarvestMeta = useSelector(getAllHarvestMeta);

  // const {modal: viewHarvestModal} = useDisclosure();

  const { tableProps } = useTable({
    data: allHarvestData,
    columns: [
      // {
      //   id: "harvestAgent",
      //   Header: "Agent",
      //   accessor: ({ agent }) => `${agent.first_name} ${agent.last_name} (${agent.phone_number})`,
      // },
      // {
      //   id: "harvestFarmer",
      //   Header: "Farmer",
      //   accessor: ({ farmer }) => `${farmer.first_name} ${farmer.last_name} (${farmer.role})`,
      // },
      // {
      //   Header: "Region",
      //   accessor: "agent.region"
      // },
      {
        Header: "Field ID",
        accessor: "field_id",
      },
      {
        Header: "Crop Type",
        accessor: "crop_type",
      },
      {
        id: "harvestDeliveryDate",
        Header: "Delivery Date",
        accessor: _harvest => formatDate(_harvest.delivery_date),
      },
      {
        Header: "Transported By",
        accessor: "transported_by",
      },
      {
        Header: "Bags Harvested",
        accessor: "quantity_harvested",
      },
      {
        Header: "Bags Threshed",
        accessor: "quantity_threshed",
      },
    ],
    title: "All Harvest",
    useServerPagination: true,
    serverPaginationMeta: allHarvestMeta,
  });

  // api calls
  const quickHarvestExport = async () => {
    const data = (
      await harvest.get("downloadHarvest", {
        params: tableProps.filterParams,
      })
    ).data;
    return data;
  };

  const getHarvestMetaKeys = async () => {
    return await harvest.get("downloadHarvest/getMetaKeys");
  };
  const advanceHarvestExport = async ({ filterParams, columnWhitelist }) => {
    let params = { columnWhitelist, ...filterParams };
    const res = await harvest.get("downloadHarvest/advance", { params });
    return res;
  };

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <PageHeader title="Harvest" />
        <ExportButton
          quickExportConfig={{
            apiCall: quickHarvestExport,
            fileName: `AOSHarvestQuickExport${legacyFormatDate(new Date())}.csv`,
          }}
          advancedExportConfig={{
            apiCall: advanceHarvestExport,
            getKeys: getHarvestMetaKeys,
          }}
        />
      </Flex>
      <Table
        {...tableProps}
        onDataFilter={params => {
          dispatch(getAllHarvestDataWithParams(params));
        }}
      />
      {/* {actionKey === "view" && <ViewHarvestDetails modal={viewHarvestModal} selected={selectedHarvest} />} */}
    </PageWrapper>
  );
};

export default HarvestPage;
