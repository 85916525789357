import React from "react";
import ErrorPage from "views/v1/404Page";
import LoginPage from "views/pages/auth/loginPage";
import GGPage from "views/pages/GGPage";
// import Redirect from "views/pages/Redirect";

export const AUTH_ROUTES_LIST = [
  {
    index: true,
    name: "Log In",
    element: <LoginPage />,
  },
  {
    path: "login",
    index: false,
    name: "Log In",
    element: <LoginPage />,
  },
  {
    path: "gg",
    name: "God Mode > Experimental",
    icon: "settings",
    invisible: true,
    showAtBottom: true,
    element: <GGPage />,
  },
  // {
  //     path: "/sign/forgotpassword",
  //     name: "Forgot Password",
  //     element: ForgotPassword
  // },
  // {
  //     path: "/sign/resetpassword",
  //     name: "Reset Password",
  //     element: ResetPassword
  // },
  // {
  //   path: "",
  //   element: <Redirect pathTo="login" />,
  // },
  {
    path: "*",
    element: <ErrorPage />,
  },
];
