import Card from "components/card/card";
import Chart from "components/chart/chart";
import { CHART_HEADER_HEIGHT, ChartHeader } from "components/chart/chartHeader";
import { getOverviewGroupSize, getOverviewGroupsChartData } from "features/v1/overview";
import { Box } from "organic";
import React from "react";
import { useSelector } from "react-redux";
import { uniqueId } from "utils";

const TotalGroups = ({ cardHeight, chart }) => {
  let data = useSelector(getOverviewGroupsChartData);
  let groupSize = useSelector(getOverviewGroupSize);

  const { type, title, subtitle, height, width } = chart;

  const yAxisLabel = "total groups";

  return (
    <Card height={cardHeight}>
      <Box height={CHART_HEADER_HEIGHT}>
        {[
          {
            title,
            subtitle,
            value: groupSize,
          },
        ].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Box>
      <Chart data={data} type={type} height={height} width={width} yAxisLabel={yAxisLabel} />
    </Card>
  );
};

export default TotalGroups;
