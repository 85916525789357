/* eslint-disable no-unused-vars */
import React from "react";
import { getCustomerID } from "services/customerService";
import Redirect from "views/pages/Redirect";
// import OverviewPage from "views/pages/overviewPage";
import AgentsPage from "views/pages/agentsPage";
import FarmIndexPage from "views/pages/farm";
import OnboardingPage from "views/pages/farm/onboardingPage";
import ClearancePage from "views/pages/farm/clearancePage";
import MonitoringPage from "views/pages/farm/monitoringPage";
import HarvestPage from "views/pages/harvestPage";
import WarehouseSummaryPage from "views/pages/warehouse/warehouseSummaryPage";
import InputDistributionPage from "views/pages/inputDistributionPage";
import MembersPage from "views/pages/membersPage";
import SurveysPage from "views/pages/surveysPage";
import SettingsPage from "views/pages/settings/settingsPage";
import Err404Page from "views/pages/errorPages/Err404Page";
import ProjectSettingsPage from "views/pages/settings/projectSettingsPage";
import RegionSettingsPage from "views/pages/settings/regionSettingsPage";
import CropSettingsPage from "views/pages/settings/cropSettingsPage";
import InputSettingsPage from "views/pages/settings/inputSettingsPage";
import SubscriptionSettingsPage from "views/pages/settings/subscriptionSettingsPage";
import TermsAndConditionsSettingsPage from "views/pages/settings/tncSettingsPage";
import QuestionnaireSettingsPage from "views/pages/settings/questionnaireSettingsPage";
import ContractorSettingsPage from "views/pages/settings/contractorSettingsPage";
import RolesAndPermissionSettingsPage from "views/pages/settings/rolesNPermissionSettingsPage";
import FarmMonitoringSettingsPage from "views/pages/settings/farmMonitoringSettingsPage";
import UnclearedFarmersPage from "views/pages/farm/clearance/unclearedFarmersPage";
import ClearedFarmersPage from "views/pages/farm/clearance/clearedFarmersPage";
import WarehousePage from "views/pages/warehousePage";
import StoresPage from "views/pages/warehouse/storesPage";
import FinanceIndexPage from "views/pages/finance";
import PaymentsPage from "views/pages/finance/paymentsPage";
import MessagingIndexPage from "views/pages/messaging";
import SmsCampaign from "views/pages/messaging/smsCampaignPage";
import Drafts from "views/pages/messaging/draftsPage";
import Templates from "views/pages/messaging/templatesPage";
import CreateCampaign from "views/pages/messaging/createCampaignPage";
import SmsCampaignReport from "views/pages/messaging/smsCampaignReportPage";
import MappingIndexPage from "views/pages/farm/mapping";
import MappingOverviewPage from "views/pages/farm/mapping/mappingOverviewPage";
import MappingInspectionPage from "views/pages/farm/mapping/mappingInspectionPage";
import RiskManagement from "views/pages/riskManagement";
import RiskSurvey from "views/pages/riskManagement/riskSurvey";
import RiskAgent from "views/pages/riskManagement/riskAgent";
import Farmers from "views/pages/riskManagement/farmers";
import RiskAgentFarmers from "views/pages/riskManagement/riskAgentFarmers";
import { Navigate } from "react-router-dom";
import MarketplaceIndexPage from "views/pages/marketplace";
import MarketplaceOverviewPage from "views/pages/marketplace/marketplaceOverviewPage";
import MarketplaceOverviewTrade from "views/pages/marketplace/marketplaceTradePage";
import MarketplaceTradeReconciliationPage from "views/pages/marketplace/marketplaceReconciliationPage";
import CreateTrade from "views/pages/marketplace/trade/createTrade";
import LogisticsDetails from "views/pages/marketplace/logistics/logisticsDetails";
import MarketplaceLogisticsPage from "views/pages/marketplace/marketplaceLogisticspage";
import TradeDetails from "views/pages/marketplace/trade/tradeDetails";
import ViewInvoice from "views/pages/marketplace/trade/viewInvoice";
import InsightAnalytics from "views/pages/insightAnalytics/insightAnalytics";
import FinanceReporting from "views/pages/finance/reporting/financeReporting";
import FinanceReportingDetails from "views/pages/finance/details/financeReportingDetails";
import OverviewPage from "views/pages/overviewPage";
import GGPage from "views/pages/GGPage";
import { DEMO_MODE_KEY } from "constants/demoConstants";
import Reporting from "views/pages/finance/reporting";
import TradeReconciliationDetails from "views/pages/marketplace/trade/tradeReconcilationDetails";
import EditActualTrade from "views/pages/marketplace/trade/editActualTrade";
import CreateActualTrade from "views/pages/marketplace/reconciliation/createActualTrade";
import ManageModulePage from "views/pages/settings/manageModule/manageModulePage";
import FarmerDetailsPage from "views/pages/farm/farmerDetailsPage";
import LegacyOnboardingPage from "views/pages/farm/onboardingPage_";

const isNotDevEnv = process.env.REACT_APP_ENV !== "development";

const customerid = getCustomerID();
const notThrive =
  customerid !== "thriveagric" && customerid !== "sprout" && customerid !== process.env.REACT_APP_STAGING_CUSTOMERID;
const isHeifer = customerid === "heifer";

export const financePage = {
  PAYMENT: "payment",
  MANAGER: "manager",
};

export const routeNames = {
  dashboardBaseUrl: "/dashboard",
  dahboardOverview: "overview",
  farmBaseUrl: "farm/*",
  farmOnboarding: "onboarding",
};

const isDemoMode = sessionStorage?.getItem?.(DEMO_MODE_KEY) ? true : false;

export const DASHBOARD_ROUTE_LIST = isDemoMode
  ? [
      {
        name: "Overview",
        icon: "grid",
        invisible: true,
        index: true,
        element: <Redirect pathTo="/dashboard/overview" />,
      },
      {
        path: "overview",
        name: "Overview",
        icon: "grid",
        element: <OverviewPage />,
      },
      {
        path: "*",
        invisible: true,
        element: <Err404Page />,
      },
    ]
  : [
      {
        name: "Overview",
        icon: "grid",
        invisible: true,
        index: true,
        element: <Redirect pathTo="/dashboard/overview" />,
      },
      isNotDevEnv
        ? {
            path: "overview",
            name: "Overview",
            icon: "grid",
            element: <OverviewPage />,
          }
        : {
            path: "overview",
            name: "Overview",
            icon: "grid",
            element: <OverviewPage />,
          },
      {
        path: "farm/*",
        name: "Farm",
        icon: "feather",
        element: <FarmIndexPage />,
        children: [
          {
            // path: "onboarding",
            name: "Onboarding",
            invisible: true,
            index: true,
            element: <Redirect pathTo="/dashboard/farm/onboarding" />,
          },
          {
            path: "onboarding",
            name: "Onboarding",
            element: <OnboardingPage />,
          },
          // {
          //   path: "onboarding",
          //   name: "Onboarding",
          //   element: <LegacyOnboardingPage />,
          // },
          {
            path: "onboarding/:id",
            name: "Farmer Details",
            invisible: true,
            element: <FarmerDetailsPage />,
          },
          {
            path: "onboarding/:id/edit",
            name: "Farmer Details",
            invisible: true,
            element: <FarmerDetailsPage edit />,
          },
          {
            path: "mapping/*",
            name: "Mapping",
            element: <MappingIndexPage />,
            children: [
              {
                name: "Mapping Overview",
                index: true,
                // element: <FarmIndexPage />,
                element: <Redirect pathTo="/dashboard/farm/mapping/mappingOverview" />,
              },
              {
                path: "mappingOverview", // FIXME: path as just 'overview' was clashing with dashbaord overview
                name: "Mapping Overview",
                element: <MappingOverviewPage />,
              },
              {
                path: "inspection",
                name: "Mapping Inspection",
                element: <MappingInspectionPage />,
              },
            ],
          },
          {
            path: "farmerClearance/*",
            name: "Clearance",
            invisible: isHeifer,
            element: <ClearancePage />,
            children: [
              {
                name: "Clearance",
                index: true,
                // element: <FarmIndexPage />,
                element: <Redirect pathTo="/dashboard/farm/farmerClearance/unclearedFarmers" />,
              },
              {
                path: "unclearedFarmers",
                name: "Uncleared Farmers",
                element: <UnclearedFarmersPage />,
              },
              {
                path: "clearedFarmers",
                name: "Cleared Farmers",
                element: <ClearedFarmersPage />,
              },
            ],
          },
          {
            path: "farmMonitoring",
            name: "Monitoring",
            invisible: isHeifer,
            element: <MonitoringPage />,
          },
        ],
      },
      {
        path: "harvest",
        name: "Harvest",
        invisible: isHeifer,
        icon: "codesandbox",
        element: <HarvestPage />,
      },
      {
        path: "agents",
        name: "Agents",
        icon: "user",
        element: <AgentsPage />,
      },

      {
        path: "warehouse/*",
        name: "Warehouse",
        invisible: isHeifer,
        icon: "layers",
        element: <WarehousePage />,
        children: [
          {
            name: "Warehouse",
            invisible: true,
            index: true,
            element: <Redirect pathTo="/dashboard/warehouse/stores" />,
            // element: <WarehouseIndexPage />,
          },
          {
            path: "warehouseSummary",
            name: "Warehouse Summary",
            invisible: true,
            element: <WarehouseSummaryPage />,
          },
          {
            path: "stores",
            name: "Warehouse Stores",
            invisible: true,
            element: <StoresPage />,
          },
        ],
      },

      {
        path: "inputDistribution",
        name: "Input Distribution",
        invisible: isHeifer,
        icon: "package",
        element: <InputDistributionPage />,
      },
      {
        path: "marketplace/*",
        name: "Marketplace",
        icon: "building02",
        element: <MarketplaceIndexPage />,
        children: [
          {
            name: "Market Index",
            index: true,
            // element: <FarmIndexPage />,
            element: <Redirect pathTo="/dashboard/marketplace/marketplaceOverview" />,
          },
          {
            path: "marketplaceOverview",
            name: "Overview",
            element: <MarketplaceOverviewPage />,
          },
          {
            path: "marketplaceTrade",
            name: "Trade",
            element: <MarketplaceOverviewTrade />,
          },
          {
            path: "marketplaceTrade/:tradeId",
            name: "Trade Details",
            invisible: true,
            element: <TradeDetails />,
          },
          {
            path: "marketplaceTrade/invoice/:tradeId",
            // path: "marketplaceTrade/invoice/",
            name: "Invoice",
            invisible: true,
            element: <ViewInvoice />,
          },
          {
            path: "marketplaceLogistics",
            name: "Logistics",
            element: <MarketplaceLogisticsPage />,
          },
          {
            path: "marketplaceLogistics/:tradeId",
            name: "Logistic Details",
            invisible: true,
            element: <LogisticsDetails />,
          },
          {
            path: "marketplaceReconciliation",
            name: "Trade Reconciliation",
            element: <MarketplaceTradeReconciliationPage />,
          },
          {
            path: "marketplaceReconciliation/reconcile/:tradeId",
            name: "Reconcile Trade",
            element: <CreateActualTrade />,
            invisible: true,
          },
          {
            path: "marketplaceReconciliation/:tradeId",
            name: "Trade Reconciliation Details",
            invisible: true,
            element: <TradeReconciliationDetails />,
          },
          {
            path: "marketplaceReconciliation/edit/:tradeId",
            name: "Trade Reconciliation Details",
            invisible: true,
            element: <EditActualTrade />,
          },
        ],
      },
      {
        path: "finance/*",
        name: "Finance",
        invisible: notThrive,
        icon: "credit-card",
        element: <FinanceIndexPage />,
        children: [
          {
            name: "Manager",
            invisible: true,
            index: true,
            element: <Redirect pathTo="/dashboard/finance/manager" />,
          },
          {
            path: "payments",
            name: "Manager",
            element: <PaymentsPage page={financePage.PAYMENT} />,
          },
          {
            path: "manager",
            name: "Payments",
            element: <PaymentsPage page={financePage.MANAGER} />,
          },
          // {
          //   path: "reporting",
          //   name: "Reporting",
          //   element: <FinanceReporting page={financePage.MANAGER} />,
          // },
          // {
          //   path: "details",
          //   name: "Details",
          //   element: <FinanceReportingDetails page={financePage.MANAGER} />,
          // },
          {
            path: "reporting/*",
            name: "Reporting",
            element: <Reporting />,
            children: [
              {
                name: "Reporting",
                index: true,
                element: <Navigate to="payment" replace />,
              },
              {
                path: "payment",
                name: "Reporting",
                element: <FinanceReporting page={financePage.MANAGER} />,
              },
              {
                path: "details/:tradeId/:cropType",
                name: "Details",
                element: <FinanceReportingDetails page={financePage.MANAGER} />,
              },
            ],
          },
        ],
      },
      {
        path: "members",
        name: "Members",
        invisible: isHeifer,
        icon: "users",
        element: <MembersPage />,
      },
      {
        path: "surveys",
        name: "Surveys",
        invisible: isHeifer,
        icon: "clipboard",
        element: <SurveysPage />,
      },
      {
        path: "messaging/*",
        name: "Messaging",
        icon: "mail",
        element: <MessagingIndexPage />,
        children: [
          {
            index: true,
            invisible: true,
            name: "SMS Campaign",
            element: <Redirect pathTo="/dashboard/messaging/sms-campaigns" />,
          },
          {
            path: "sms-campaigns",
            name: "SMS Campaign",
            element: <SmsCampaign />,
          },
          {
            element: <Drafts />,
            path: "drafts",
            name: "Draft",
          },
          {
            element: <Templates />,
            path: "templates",
            name: "Template",
          },
          {
            element: <CreateCampaign />,
            path: "create-campaign",
            name: "Create a Campaign",
            invisible: true,
          },
          {
            element: <SmsCampaignReport />,
            path: ":_id/view-report",
            name: "Create a Campaign",
            invisible: true,
          },
        ],
      },
      {
        path: "risk/*",
        name: "Risk Management",
        icon: "clock",
        element: <RiskManagement />,
        ignoreChildren: true,
        children: [
          {
            name: "Risk Management",
            index: true,
            element: <Redirect pathTo="/dashboard/risk/survey" />,
            // element: <SettingsIndexPage />,
          },
          {
            path: "survey",
            name: "Risk Survey",
            invisible: true,
            element: <RiskSurvey />,
          },
          {
            path: "survey/:id/*",
            name: "Risk Farmers and Agents",
            // invisible: true,
            element: <RiskAgentFarmers />,
            children: [
              {
                name: "Risk Agent",
                index: true,
                element: <Navigate to="agent" replace />,
              },
              {
                path: "agent",
                name: "Risk Agent",
                element: <RiskAgent />,
              },
              {
                path: "farmer",
                name: "Farmers",
                element: <Farmers />,
              },
            ],
          },
        ],
      },
      {
        path: "settings/*",
        name: "Settings",
        icon: "settings",
        showAtBottom: true,
        element: <SettingsPage />,
        children: [
          {
            name: "Settings",
            index: true,
            element: <Redirect pathTo="/dashboard/settings/projects" />,
            // element: <SettingsIndexPage />,
          },
          {
            path: "projects",
            name: "Projects",
            invisible: true,
            element: <ProjectSettingsPage />,
          },
          {
            path: "regions",
            name: "Regions",
            invisible: true,
            element: <RegionSettingsPage />,
          },
          {
            path: "crops",
            name: "Crops",
            invisible: true,
            element: <CropSettingsPage />,
          },
          {
            path: "inputs",
            name: "Inputs",
            invisible: true,
            element: <InputSettingsPage />,
          },
          {
            path: "subscriptionPlans",
            name: "Subscription Plans",
            invisible: true,
            element: <SubscriptionSettingsPage />,
          },
          {
            path: "terms",
            name: "Terms and Conditions",
            invisible: true,
            element: <TermsAndConditionsSettingsPage />,
          },
          {
            path: "questionnaire",
            name: "Questionnaire",
            invisible: true,
            element: <QuestionnaireSettingsPage />,
          },
          {
            path: "contractors",
            name: "Contractors",
            invisible: true,
            element: <ContractorSettingsPage />,
          },
          {
            path: "roles",
            name: "Roles and Permissions",
            invisible: true,
            element: <RolesAndPermissionSettingsPage />,
          },
          {
            path: "farmSupervision",
            name: "Farm Supervision / Monitoring",
            invisible: true,
            element: <FarmMonitoringSettingsPage />,
          },
          {
            path: "manageModule",
            name: "Manage Module",
            invisible: true,
            element: <ManageModulePage />,
          },
        ],
      },
      {
        path: "gg",
        name: "God Mode > Experimental",
        icon: "settings",
        invisible: true,
        showAtBottom: true,
        element: <GGPage />,
      },
      {
        path: "*",
        invisible: true,
        element: <Err404Page />,
      },
      //routes that have no tag on the sidebar should have a prop of invisible:true
    ];
