import { useMutation } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import ManageModule_API from "services/ManageModuleService";

export function useCreateModule() {
  return useMutation({
    mutationFn: values => {
      return ManageModule_API.createModule(values);
    },
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.modules.createModule);
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong creating a module");
      bug.report(error);
    },
  });
}
