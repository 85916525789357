import { useMutation } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import ManageModule_API from "services/ManageModuleService";

export function useDeleteModule(id) {
  return useMutation({
    mutationFn: () => {
      return ManageModule_API.deleteModule(id);
    },
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.modules.deleteModule);
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong deleting module");
      bug.report(error);
    },
  });
}
