import React from "react";
import * as Hovercardprimitives from "@radix-ui/react-hover-card";
import { classnames } from "utils";

const HoverCard = Hovercardprimitives.Root;
// HoverCard.defaultProps = {
//   className: "hover-card",
// };
const HoverCardTrigger = Hovercardprimitives.Trigger;
HoverCardTrigger.defaultProps = {
  className: "hover-card__trigger",
};

const HoverCardContent = React.forwardRef(
  ({ children, className, unstyled, ...restOfHoverCardContentProps }, forwardedRef) => {
    const hoverCardClassNames = classnames(className, { "hover-card__content": unstyled === true });
    return (
      <Hovercardprimitives.Portal>
        <Hovercardprimitives.Content
          className={hoverCardClassNames}
          {...restOfHoverCardContentProps}
          ref={forwardedRef}
        >
          {children}
        </Hovercardprimitives.Content>
      </Hovercardprimitives.Portal>
    );
  }
);

HoverCardContent.displayName = "HoverCardContent";

export { HoverCard, HoverCardContent, HoverCardTrigger };
