export const sessionStorageKeys = {
  TOKEN: "token",
  REFRESH: "refresh",
  SCOPE: "scope",
  ORG_NAME: "orgName",
  SENDER_ID: "senderId",
  ORG: "org",
};

export const localStorageKeys = {
  SESSION_EXPIRED_TOAST: "sessionExpiredMessage",
  LOGOUT: "logout",
};
