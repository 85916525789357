import React from "react";
import { PageWrapper } from "components/layout";
import { Flex } from "organic";
import { PageHeader } from "components/layout";
import { Outlet } from "react-router-dom";

function RiskManagement() {
  return (
    <PageWrapper>
      {/* <CustomFilter ignoreDateRange ignoreRegion noExport /> */}
      <Flex flexDirection="row" justifyContent="space-between">
        <PageHeader title="Risk Management" subtitle="Manage all risk management and activity." />
      </Flex>
      {/* <PageTabsNav tabs={tabs} /> */}
      <Outlet />
    </PageWrapper>
  );
}

export default RiskManagement;
