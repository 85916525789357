import { useState } from "react";

const useToggle = (defaultValue = false) => {
  const [isToggled, setIsToggled] = useState(defaultValue);

  const toggle = () => {
    setIsToggled(toggleValue => !toggleValue);
  };

  return { toggle, isToggled, setIsToggled, isOn: isToggled, setIsOn: setIsToggled };
};

export { useToggle };
