import React, { useEffect, useState } from "react";
import farmersApi from "api/farmers";
import { Box, Button, Flex, Input, Text } from "organic";
import { useDataFilter } from "hooks";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getAllClearedFarmersWithParams } from "features/v1/farmerClearance";

const UpdateGroupFarmSize = props => {
  const { selected, modal, paginationMeta } = props;

  const [groupTotalFarmSize, setGroupTotalFarmSize] = useState(selected?.farm_size || "N/A");
  const [curGroupFarmers, setCurGroupFarmers] = useState(selected?.farmers || []);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { filterParams } = useDataFilter();

  const handleFarmerFieldSizeChange = (value, index) => {
    try {
      if (Number.isNaN(value)) return;
      let newGroupFarmers = [...curGroupFarmers];
      newGroupFarmers[index].field_size = value;
      setCurGroupFarmers(newGroupFarmers);
    } catch (error) {
      // TODO: implement erroh handling
      console.log("an error occured");
    }
  };

  const handleAdjustFieldSize = async index => {
    try {
      let selectedFarmer = curGroupFarmers[index];
      if (!selectedFarmer?.field_id) {
        toast.error("MISSING FIELD_ID: cannot update, please report issue to tech team");
        return;
      }
      setLoading(true);
      let updateRes = await farmersApi.patch(
        "/updateFarmMapperInfo",
        { adjusted_farm_size: selectedFarmer.field_size },
        { params: { id: selectedFarmer.field_id } }
      );
      if (updateRes.status === 200) {
        toast.success(`${selectedFarmer.first_name}'s Field size updated to ${selectedFarmer.field_size}`);
      }

      setLoading(false);
    } catch (error) {
      toast.error(`Adjust Field Size Failed ${error?.response?.data?.message || error.message}`);
      setLoading(false);
    }
  };

  const handleUpdateGroupClearance = async () => {
    try {
      setLoading(true);
      let updateRes = await farmersApi.get("/clearFarmer/updateWithAcid", {
        params: { groupId: selected.groupId, season: filterParams.seasonId },
      });
      if (updateRes.status === 200) {
        toast.success("group " + selected.groupId + " successfully updated");
      }
      setLoading(false);
      modal.hideModal();
      return dispatch(getAllClearedFarmersWithParams({ ...filterParams, ...paginationMeta }));
    } catch (error) {
      toast.error(`Update Cleared Group Failed ${error?.response?.data?.message || error.message}`);

      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      if (Array.isArray(curGroupFarmers) && curGroupFarmers.length > 0) {
        setGroupTotalFarmSize(() =>
          curGroupFarmers.reduce((prev, cur) => {
            return parseFloat(prev) + parseFloat(cur.field_size);
          }, 0)
        );
      }
    } catch (error) {
      // TODO: implement erroh handling
      console.log("an error occured");
    }
  }, [curGroupFarmers]);
  return (
    <div>
      <Flex justifyContent="center">
        <Flex flexDirection="column" alignItems="center">
          <Text color="gray600">Group Total (ha)</Text>
          <Text font="displayXlBold" color="success600">
            {Number.isNaN(groupTotalFarmSize) ? 0 : parseFloat(groupTotalFarmSize).toFixed(2)}
          </Text>
          <Text color="error500">{Number.isNaN(groupTotalFarmSize) && "Fill in all Field Values"}</Text>
        </Flex>
        <Flex flexDirection="column" ml="2rem" alignItems="center">
          <Text color="gray600">Group Field Count</Text>
          <Text font="displayXlBold" color="success600">
            {selected.fieldsCount}
          </Text>
        </Flex>
      </Flex>
      <hr />
      <Flex justifyContent="space-evenly">
        <Text style={{ flex: 1 }} font="mdSemibold">
          Farmer
        </Text>
        <Text style={{ flex: 1 }} font="mdSemibold">
          Field
        </Text>
        <Text style={{ flex: 1 }} font="mdSemibold">
          Crop Type
        </Text>
        <Text style={{ flex: 1 }} font="mdSemibold">
          Farm Size
        </Text>
        <Text style={{ flex: 1 }} font="mdSemibold">
          Action
        </Text>
      </Flex>
      {selected?.farmers.length > 0 &&
        selected.farmers.map((farmer, farmerIndex) => {
          return (
            <Flex
              key={`farmer-${farmerIndex.toString()}`}
              justifyContent="space-evenly"
              borderBottom="1px gray300 solid"
              alignItems="center"
              mt="0.5rem"
            >
              <span>{!farmer?.field_id && "cannot update this group,\n please contact tech team"}</span>
              <Flex display="inline-flex" style={{ flex: 1 }} className="py-0 my-0">
                {farmer.first_name} {farmer.last_name}
              </Flex>
              <Flex display="inline-flex" style={{ flex: 1 }} className="py-0 my-0">
                {" "}
                {farmer.field_name}
              </Flex>
              <Flex display="inline-flex" style={{ flex: 1 }} className="py-0 my-0">
                {farmer.crop_type}
              </Flex>
              <Flex display="inline-flex" style={{ flex: 1 }} className="py-0 my-0">
                <Input
                  type="number"
                  onChange={e => handleFarmerFieldSizeChange(e.target.value, farmerIndex)}
                  defaultValue={farmer.adjusted_farm_size ? farmer.adjusted_farm_size : farmer.field_size}
                  min={0}
                />
              </Flex>
              <Box className="py-0 my-0">
                <Button
                  size="sm"
                  display="block"
                  colorScheme="secondaryGray"
                  onClick={() => handleAdjustFieldSize(farmerIndex)}
                  text="Adjust Field Size"
                />
              </Box>
            </Flex>
          );
        })}
      <Flex mt="2rem">
        <Button display="block" colorScheme="secondaryGray" disabled={loading} onClick={() => modal.hideModal()}>
          Cancel
        </Button>
        <Button display="block" ml="1rem" disabled={loading} onClick={handleUpdateGroupClearance}>
          Update Group
        </Button>
      </Flex>
    </div>
  );
};

export default UpdateGroupFarmSize;
