import { FormInput } from "components/inputs";
import React from "react";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import { useFormikContext } from "formik";
import { tradeStatuses } from "../marketplaceTradePage";

export const PriceToPayInput = () => {
  const { values } = useFormikContext();
  return (
    <FormInput
      name="price_to_pay"
      required
      type="number"
      min={0}
      max={MAX_NUM_VALUE}
      label="Price to Pay Farmers "
      placeholder="Price to Pay Farmers "
      step="0.01"
      isDisabled={values.status !== tradeStatuses.PENDING && values.status !== tradeStatuses.DECLINED}
    />
  );
};
