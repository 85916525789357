import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { singletonHook } from "react-singleton-hook";
import { getUserScope, initialRoleState, resolveRoleFlags } from "services/v1/rolesAndPermissionsService";
import { getUserRole } from "features/v1/auth";
import { getAllRolesData } from "features/v1/role";

const defaultUseRoleState = { ...initialRoleState, canWrite: [], canRead: [], canUpdate: [] };

/**
 * TODO: add a functionality to map role slug to role name
 * a custom hook to return user role state,
 * also includes boolean values for established roles, as well as sinple expression for scopes
 * of a user
 * @returns {Object} - an object if boolean flags where current role is set to true
 */
const useRoleImpl = () => {
  const [roleState, setRoleState] = useState(initialRoleState);
  const [canRead, setCanRead] = useState([]);
  const [canWrite, setCanWrite] = useState([]);
  const [canUpdate, setCanUpdate] = useState([]);

  let role = useSelector(getUserRole);
  let userScope = getUserScope();
  let allRoles = useSelector(getAllRolesData);

  // TODO: create a subscription to login and logout state

  useEffect(() => {
    // setRoleFlags
    if (role) {
      setRoleState(() => resolveRoleFlags(role));
    }
    if (!userScope) {
      return;
    }
    const _readableResources = [];
    const _writableResources = [];
    const _updatableResources = [];
    const scopeArray = userScope.split(",");

    scopeArray.forEach(scopeItem => {
      const [resource, permission] = scopeItem.split(".");
      if (permission === "read") {
        _readableResources.push(resource);
      }
      if (permission === "write") {
        _writableResources.push(resource);
      }
      if (permission === "update") {
        _updatableResources.push(resource);
      }
    });

    setCanRead(_readableResources);
    setCanWrite(_writableResources);
    setCanUpdate(_updatableResources);
  }, [role, userScope]);

  return { ...roleState, canWrite: canWrite, canUpdate: canUpdate, canRead: canRead, role, allRoles };
};

const useRole = singletonHook(defaultUseRoleState, useRoleImpl);

export { useRole as useLegacyRole };
