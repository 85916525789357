import React, { useMemo } from "react";
import { PageWrapper, VisibilityWrapper } from "components/layout";
import { Badge, Flex, Text, AvatarGroup, Avatar, badgeConstants, organicTheme } from "organic";
import { capitalize, uniqueId } from "utils";
import { PageHeader } from "components/layout";
import { Table } from "components/table";
import { useDataFilter, useDisclosure, useLegacyRole, useTable } from "hooks";
import { SummaryGrid } from "components/grid";
import { Modal, ModalContent } from "components/modal";
import { ConfirmDialog } from "components/confirmDialog";
import { useGetMarketplaceOverview, useGetOverviewTrades } from "hooks/app/marketplace";
import numeral from "numeral";
import EditTradeDetails from "./editModal/editTrade";
import { useNavigate } from "react-router-dom";
import { resources } from "services/v1/rolesAndPermissionsService";

export const resolveMarketplaceTradeStatusColor = status => {
  switch (`${status}`?.toLowerCase?.()) {
    case "pending":
      return badgeConstants.colorSchemes.ORANGE;
    case "approved":
      return badgeConstants.colorSchemes.SUCCESS;
    case "rejected":
      return badgeConstants.colorSchemes.ERROR;
    case "stocked":
      return badgeConstants.colorSchemes.PURPLE;
    case "delivered":
      return badgeConstants.colorSchemes.BLUE;
    case "finalised":
      return badgeConstants.colorSchemes.BLUE;
    case "closed":
      return badgeConstants.colorSchemes.SUCCESS;
    default:
      return badgeConstants.colorSchemes.GRAY;
  }
};

const MarketplaceOverviewPage = () => {
  const { filterParams } = useDataFilter();
  const { data: overviewData, isLoading: isOverviewLoading } = useGetMarketplaceOverview({ params: filterParams });
  const { data, isLoading } = useGetOverviewTrades({ params: { dateRange: filterParams.dateRange } });
  const { canUpdate, role, allRoles } = useLegacyRole();
  const navigate = useNavigate();

  const summaryCardsData = useMemo(() => {
    return [
      {
        title: "Total Target (KG)",
        component: (
          <OverviewValueWrapper
            data={overviewData}
            value={overviewData?.totalTargetQuantity}
            isLoading={isOverviewLoading}
          />
        ),
      },
      {
        title: "Total Target Achieved (KG)",
        component: (
          <OverviewValueWrapper
            data={overviewData}
            value={overviewData?.totalTargetAchieved}
            isLoading={isOverviewLoading}
          />
        ),
      },
      {
        title: "Total Target Remaining (KG)",
        component: (
          <OverviewValueWrapper
            data={overviewData}
            value={overviewData?.totalRemaining}
            isLoading={isOverviewLoading}
          />
        ),
      },
    ];
  }, [isOverviewLoading, overviewData]);

  const { tableProps, selectedRow: selectedTrade } = useTable({
    columns: [
      {
        id: "tradeId",
        Header: "ID",
        accessor: trade => trade.trade_id,
      },
      {
        idFunction: uniqueId(),
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Cost Price (KES)",
        // accessor: "cost_price",
        accessor: trade => numeral(trade.cost_price).format("0,0.00"),
      },
      {
        Header: "Selling Price (KES).",
        // accessor: "selling_price",
        accessor: trade => numeral(trade.selling_price).format("0,0.00"),
      },
      {
        Header: "Logistics Total (KES)",
        accessor: trade => numeral(trade.logistics_cost).format("0,0.00"),
      },
      {
        Header: "Target Qty (KG)",
        accessor: trade => numeral(trade.target_quantity).format("0,0.00"),
      },
      {
        id: "assignedAgents",
        Header: "Assigned Agents",
        accessor: "assigned_agents",
        Cell: ({ value }) => {
          return (
            <AvatarGroup>
              {value?.map?.(agent => (
                <Avatar size="xs" key={agent._id} name={`${agent.first_name} ${agent.last_name}`} />
              ))}
            </AvatarGroup>
          );
        },
      },
      {
        Header: "Total Investment (KES)",
        accessor: trade => numeral(trade.total_investment).format("0,0"),
        // accessor: "total_investment",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge colorScheme={resolveMarketplaceTradeStatusColor(value)}>{capitalize(value)}</Badge>,
      },
    ],
    data: data?.data,
    title: "All trades",
    useServerPagination: true,
    serverPaginationMeta: data?.meta || {},
    showCheckboxes: true,
    loading: isLoading,
    noEditAction: !canUpdate?.includes?.(resources.marketPlace) && role !== allRoles?.commercialSpecialist,
  });

  const tradeEditModal = useDisclosure();
  const confirmModal = useDisclosure();

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" align-Items="center">
        <PageHeader title="Marketplace > Overview" subtitle="Your trade summary and activity." />
      </Flex>
      <SummaryGrid data={summaryCardsData} />
      <Table
        {...tableProps}
        onEditAction={() => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=edit`);
        }}
        onViewAction={() => {
          const status = selectedTrade.status;
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=view&status=${status}`);
        }}
      />
      <Modal
        open={tradeEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            tradeEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit trade Details" size="md">
          <EditTradeDetails selected={selectedTrade} modal={tradeEditModal.modal} />
        </ModalContent>
      </Modal>
      <Modal
        open={confirmModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            confirmModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={550}>
          <ConfirmDialog
            heading="Do you want to continue?"
            subHeading="<p>
            Are you sure you want to confirm ? <br /> This action cannot be reversed
          </p>"
            confirmationText="Confirm"
            modal={confirmModal.modal}
          />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export const OverviewValueWrapper = ({ data, value, isLoading, isActive }) => {
  return (
    <VisibilityWrapper
      placeholder={<OverviewValuePlaceholder isLoading={isLoading} />}
      isVisible={data != null && typeof data !== "undefined"}
    >
      <Text font="displayXsBold" color={isActive ? organicTheme.colors.primary600 : organicTheme.colors.gray900}>
        {(value && numeral(value).format("0,0")) || "--"}
      </Text>
    </VisibilityWrapper>
  );
};

const OverviewValuePlaceholder = ({ isLoading = false }) => {
  if (isLoading) {
    return (
      <Text font="displayXsBold">
        --&nbsp;<Badge as="span">Loading...</Badge>
      </Text>
    );
  }
  // placeholder when not loading
  return <>--</>;
};

export default MarketplaceOverviewPage;
