import { generateChartData } from "mockData/overview";
import { organicTheme } from "organic";
import React from "react";
import { Area, CartesianGrid, AreaChart as ReAreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const CustomTooltip = ({ active, payload, label, yAxisLabel }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ border: `1px solid #c2c5ca`, background: "white", padding: "8px", borderRadius: "2px" }}>
        <p>{` ${label}`}</p>

        <p style={{ color: "#247809" }}>
          <span>{yAxisLabel}:</span> {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};

const AreaChart = ({ data, height, yAxisLabel }) => {
  return (
    <ResponsiveContainer height={height} width="100%">
      <ReAreaChart
        data={[...generateChartData({ value: 0, count: 0 }), ...data]}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorChart" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#48A928" stopOpacity={1} />
            <stop offset="100%" stopColor="rgba(72, 169, 40, 0.1)" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="x" />
        <YAxis dataKey="y" hide />
        <Tooltip content={<CustomTooltip yAxisLabel={yAxisLabel} />} />
        <Area
          type="linear"
          dataKey="y"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={organicTheme.colors.success500}
          strokeWidth="2px"
          fillOpacity={0.1}
          fill="url(#colorChart)"
        />
      </ReAreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChart;
