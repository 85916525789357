/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import * as yup from "yup";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { Box } from "organic";
import { FormInput } from "components/inputs";
import { GenderSelect } from "components/select";
import { appGet } from "utils";
import CountrySelect2 from "components/select/countrySelect2";
import StateSelect2 from "components/select/stateSelect2";
import LGASelect2 from "components/select/lgaSelect2";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.cols}, minmax(0, 1fr))`};
  gap: 1.5rem;
`;

const basicInformationValidationSchema = yup.object({
  // seasonId: yup.string().required("Required"),
  // regions: yup.array().min(1, "Required").of(yup.string().required("Required")),
  // region: yup.string().required("Required"),
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  middle_name: yup.string(),
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "Must be a number")
    .min(11, "Phone number must be at least 11 digits")
    .required("Required"),
  email: yup.string().email("Please enter a valid email address").required("Required"),
  gender: yup.string().required("Required"),
  age: yup
    .number()
    .typeError("Please enter a valid number")
    .required("This field is required")
    .min(1, "Number must be greater than 0"),
  country: yup.string().required("Required"),
  state: yup.string().required("Required"),
  lga: yup.string().required("Required"),
  village: yup.string(),
  district: yup.string().required("Required"),
  address: yup.string().required("Required"),
});

const EditBasicInformation = ({ basicInfo, setBasicInfo, setFormIsValid }) => {
  return (
    <Box>
      <Formik
        validationSchema={basicInformationValidationSchema}
        initialValues={basicInfo}
        onSubmit={() => {
          // handleSubmit ? handleSubmit() : null;
        }}
        validateOnMount
        enableReinitialize
      >
        {({ setFieldValue, values, isValid }) => {
          useEffect(() => {
            setBasicInfo(values);
          }, [values]);

          useEffect(() => {
            setFormIsValid(isValid);
          }, [isValid]);

          return (
            <>
              <Form>
                <Box mx={1} my={1}>
                  <GridContainer cols={2}>
                    <Box>
                      <FormInput label="First name" name="first_name" />
                    </Box>
                    <Box>
                      <FormInput label="Last name" name="last_name" />
                    </Box>
                    <Box>
                      <FormInput label="Middle name" name="middle_name" />
                    </Box>
                    <Box>
                      <FormInput label="Phone number" name="phone_number" />
                    </Box>
                    <Box>
                      <FormInput label="Email address" name="email" />
                    </Box>
                    <Box>
                      <GenderSelect
                        label="Gender"
                        onChange={value => {
                          setFieldValue("gender", value);
                        }}
                        value={appGet(values, "gender")}
                        dependencies={[values]}
                      />
                    </Box>
                    <Box>
                      <FormInput name="age" label="Age" type="number" />
                    </Box>
                    <Box>
                      <CountrySelect2
                        name="country"
                        stateFieldName="state"
                        lgaFieldName="lga"
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                    <Box>
                      <StateSelect2
                        name="state"
                        lgaFieldName="lga"
                        setFieldValue={setFieldValue}
                        selectedCountry={values.country}
                      />
                    </Box>
                    <Box>
                      <LGASelect2 name="lga" selectedCountry={values.country} selectedState={values.state} />
                    </Box>
                    {/* <Box>
                      <FormInput name="village" label="Village" />
                    </Box> */}
                    <Box>
                      <FormInput name="district" label="District" />
                    </Box>
                    <Box>
                      <FormInput name="address" label="Address" />
                    </Box>
                  </GridContainer>
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EditBasicInformation;
