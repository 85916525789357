import React, { useEffect, useMemo, useState } from "react";
import Pagination, { paginationConstants } from "./pagination";
import TableContainer from "./tableContainer";
import TableElement from "./tableElement";
import { EmptyState } from "components/appState";
import {
  useTable as useTanStackTable,
  usePagination,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { useCallbackRef, useDidMountEffect, useDisclosure, useDataFilter, useServerPagination } from "hooks";
import { debounce, hasMinLength, isEmptyObject, isFunction } from "utils";
import { tableConstants } from "./tableConstants";
import { IndeterminateCheckbox } from "components/inputs";
import { TableActions } from "./tableActions";
import { AlertDialog } from "components/modal";

const Table = ({
  // only available with custom useTable hook
  // onSelectAll,
  // checkboxChecked = () => null,
  // handleCheckboxChange = () => null,
  // NEW TABLE PROPS
  data,
  title,
  // pages,
  useServerPagination: DEPRECATED_useServerPagination,
  serverPaginationMeta,
  isServerPaginated,
  // totalItems,
  columns,
  showCheckboxes,
  noActions,
  canDelete,
  noEditAction,
  noViewAction,
  createAction,
  extraActions,
  getExtraActions,
  getMultiSelectActions,
  onSelect,
  onMultiSelect,
  onMultiSelectAction,
  onSearch,
  onSearchCleared,
  onDataFilter,
  onFilter,
  onViewAction,
  onEditAction,
  onDeleteAction,
  onExtraAction,
  onCreateAction,
  noDateRangeFilter,
  noLocalFilter,
  noSearch,
  useAlternatePagination,
  loading,
}) => {
  const [selectedAction, setSelectedAction] = useState(defaultSelectedAction);
  const [multiSelectAction, setMultiSelectAction] = useState(defaultSelectedAction);
  const [searchText, setSearchText] = useState("");
  const [intermediatiateSelection, setIntermediateSelection] = useState();

  const deleteDisclosure = useDisclosure();

  const { filterParams, isValidFilter } = useDataFilter();

  const handleSelect = options => {
    console.log({ options });
    if (options.actionKey === tableConstants.defaultActionKeys.DELETE_ACTION_KEY) {
      setIntermediateSelection(options);
      deleteDisclosure.onOpen();
      return;
    }
    // pass row obj to callback prop
    _onSelect(options.selected);
    // save Action obj internally
    setSelectedAction(options);
  };

  const handleConfirmDelete = () => {
    // pass row obj to callback prop
    _onSelect(intermediatiateSelection.selected);
    // save Action obj internally
    setSelectedAction(intermediatiateSelection);
  };

  const handleMultiSelect = multiSelectOptions => {
    console.log({ multiSelectOptions });
    _onMultiSelect(multiSelectOptions);
  };

  const handleResetSelect = () => {
    onSelect(null);
    setMultiSelectAction(defaultSelectedAction);
  };

  // default columns for column resize
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const { pages, totalDocCount } = useServerPagination({
    enabled: isServerPaginated,
    pagination: serverPaginationMeta,
  });

  const totalItems = useMemo(() => {
    if (isServerPaginated && totalDocCount) {
      return totalDocCount;
    }

    if (isServerPaginated && !totalDocCount) {
      return 0;
    }
    return data.length;
  }, [data.length, isServerPaginated, totalDocCount]);

  // react table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    headers,
    prepareRow,
    state: { pageIndex, pageSize },
    // pagination values
    page,
    pageCount,
    pageOptions,
    setPageSize,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    selectedFlatRows,
  } = useTanStackTable(
    {
      data,
      columns,
      defaultColumn,
      manualPagination: DEPRECATED_useServerPagination,
      pageCount: pages,
      initialState: paginationConstants.defaultState,
      autoResetPage: !isServerPaginated,
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    hooks => {
      if (showCheckboxes) {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: "selection",

            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            disableResizing: true,
            Header: TableCheckboxHeader,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: TableCheckboxCell,
            minWidth: 35,
            width: 35,
            maxWidth: 35,
          },
          ...columns,
        ]);
      }

      if (!noActions) {
        hooks.visibleColumns.push(columns => [
          ...columns,
          {
            Header: "",
            accessor: "_id",
            minWidth: 35,
            width: 68,
            disableResizing: true,
            Cell: ({ value, row }) => (
              <TableActions
                onSelect={handleSelect}
                row={row.original}
                id={value}
                extraActions={extraActions}
                getExtraActions={getExtraActions}
                noEditAction={noEditAction}
                noViewAction={noViewAction}
                canDelete={canDelete}
              />
            ),
          },
        ]);
        // hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        //   // fix the parent group of the selection button to not be resizable
        //   const selectionGroupHeader = headerGroups[0].headers[0];
        //   selectionGroupHeader.canResize = false;
        // });
      }
    }
  );

  const _onDataFilter = useCallbackRef(onDataFilter, [
    isValidFilter,
    filterParams.seasonId,
    filterParams.region,
    filterParams.dateRange,
    pageSize,
    pageIndex,
  ]);

  const _onSearch = useCallbackRef(onSearch, [
    isValidFilter,
    filterParams.seasonId,
    filterParams.region,
    filterParams.dateRange,
    pageIndex,
    pageSize,
    searchText,
  ]);

  const _onSearchCleared = useCallbackRef(onSearchCleared, [searchText]);

  const _onViewAction = useCallbackRef(onViewAction, []);
  const _onEditAction = useCallbackRef(onEditAction, []);
  const _onDeleteAction = useCallbackRef(onDeleteAction, []);
  const _onExtraAction = useCallbackRef(onExtraAction, []);
  const _onSelect = useCallbackRef(onSelect, []);
  const _onMultiSelect = useCallbackRef(onMultiSelect, []);
  const _onMultiSelectAction = useCallbackRef(onMultiSelectAction, []);

  const handleSearchChange = (term = "") => {
    // const debouncedRequest = debounce(() => {
    setSearchText(term);
    // }, 500);
    // debouncedRequest();
  };

  // handle data filter change including pagination
  useEffect(() => {
    if (isValidFilter && searchText.length === 0) {
      _onDataFilter({
        region: filterParams.region,
        seasonId: filterParams.seasonId,
        dateRange: filterParams.dateRange,
        // pageSize is called pageCount on the server 🤷‍♂️
        // pageIndex is called page on the server 🤷‍♂️
        nPerPage: pageSize,
        page: pageCount < pageIndex + 1 ? 1 : pageIndex + 1,
      });
    }
  }, [_onDataFilter, filterParams, isValidFilter, searchText.length, pageIndex, pageSize]);

  // handle search callback including pagination
  useEffect(() => {
    if (hasMinLength(searchText, 4) && isValidFilter) {
      let abortController = new AbortController();

      const debouncedRequest = debounce(() => {
        _onSearch(
          {
            // params
            searchText,
            region: filterParams.region,
            seasonId: filterParams.seasonId,
            dateRange: filterParams.dateRange,
            // pageSize is called pageCount on the server 🤷‍♂️
            // pageIndex is called page on the server 🤷‍♂️
            nPerPage: pageSize,
            page: pageCount < pageIndex + 1 ? 1 : pageIndex + 1,
          },
          { abortController }
        );
      }, 500);

      if (abortController) {
        abortController.abort();
        abortController = new AbortController();
      }
      debouncedRequest();
    }
    if (searchText?.length < 1) {
      _onSearchCleared();
    }
  }, [
    _onSearch,
    _onSearchCleared,
    isValidFilter,
    filterParams.dateRange,
    filterParams.region,
    filterParams.seasonId,
    searchText,
    pageIndex,
    pageSize,
  ]);

  useEffect(() => {
    if (isValidFilter) {
      onFilter({ page: pageIndex + 1, nPerPage: pageSize, searchText });
    }
  }, [isValidFilter, onFilter, pageIndex, pageSize, searchText]);

  useDidMountEffect(() => {
    if (!isEmptyObject(selectedAction) && !isEmptyObject(selectedAction?.selected) && selectedAction?.actionKey) {
      if (!Object.values(tableConstants.defaultActionKeys).includes(selectedAction.actionKey)) {
        isFunction(_onExtraAction) && _onExtraAction(selectedAction, { filterParams });
        return;
      }

      switch (selectedAction.actionKey) {
        case tableConstants.defaultActionKeys.VIEW_ACTION_KEY: {
          isFunction(_onViewAction) && _onViewAction(selectedAction, { filterParams });
          break;
        }
        case tableConstants.defaultActionKeys.EDIT_ACTION_KEY: {
          isFunction(_onEditAction) && _onEditAction(selectedAction, { filterParams });
          break;
        }
        case tableConstants.defaultActionKeys.DELETE_ACTION_KEY: {
          isFunction(_onDeleteAction) && _onDeleteAction(selectedAction, { filterParams });
          break;
        }
        default:
          break;
      }
    }
  }, [_onDeleteAction, _onEditAction, _onExtraAction, _onViewAction, selectedAction]);

  useDidMountEffect(() => {
    if (
      !isEmptyObject(multiSelectAction) &&
      Array.isArray(multiSelectAction?.selected) &&
      multiSelectAction?.actionKey
    ) {
      isFunction(_onMultiSelectAction) && _onMultiSelectAction(multiSelectAction, { filterParams });
    }
  }, [_onMultiSelectAction, multiSelectAction]);

  return (
    <>
      <TableContainer
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalItems={totalItems}
        title={title}
        onSearch={handleSearchChange}
        data={data}
        createAction={createAction}
        pages={pages}
        onCreate={onCreateAction}
        noDateRangeFilter={noDateRangeFilter}
        noLocalFilter={noLocalFilter}
        noSearch={noSearch}
        onMultiSelect={handleMultiSelect}
        multiSelectedRows={selectedFlatRows}
        showCheckboxes={showCheckboxes}
        laoding={loading}
        getMultiSelectActions={getMultiSelectActions}
      >
        {page?.length ? (
          <>
            <TableElement
              headers={headers}
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              prepareRow={prepareRow}
              data={data}
              resetSelect={handleResetSelect}
              showCheckboxes={showCheckboxes}
            />
            <Pagination
              pageIndex={pageIndex}
              pageSize={pageSize}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              gotoPage={gotoPage}
              pageCount={pageCount}
              setPageSize={setPageSize}
              pageOptions={pageOptions}
              useAlternatePagination={useAlternatePagination}
            />
          </>
        ) : (
          <EmptyState subtitle="No data for the current filter" />
        )}
      </TableContainer>
      <AlertDialog
        heading="Delete Item"
        subHeading="Warning: this action is not reversible, Are you sure you want to proceed"
        confirmationText="Proceed"
        disclosure={deleteDisclosure}
        onSuccess={handleConfirmDelete}
        variant="destructive"
      />
    </>
  );
};

const defaultSelectedAction = {
  selected: null,
  actionKey: "",
  id: null,
};

Table.defaultProps = {
  onViewAction(__selectedAction, __config = {}) {},
  onEditAction() {},
  onDeleteAction() {},
  onExtraAction() {},
  onSearch() {},
  onDataFilter: undefined,
  onFilter: undefined,
  showCheckboxes: false,
};

const TableCheckboxHeader = React.forwardRef(({ getToggleAllPageRowsSelectedProps }, innerRef) => (
  <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} ref={innerRef} />
));

TableCheckboxHeader.displayName = "TableCheckboxHeader";

const TableCheckboxCell = React.forwardRef(({ row }, innerRef) => (
  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} ref={innerRef} />
));

TableCheckboxCell.displayName = "TableCheckboxCell";

export { Table };
