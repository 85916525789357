import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader, PageWrapper } from "components/layout";
import { Box, Flex, Icon, organicTheme, Button, Text } from "organic";
import { useTable, useDataFilter } from "hooks";
import { Table } from "components/table";
import { format } from "date-fns";

import { uniqueId } from "utils";
import { useGetFinanceReportingOverview } from "hooks/app/financeReporting/useGetFinanceReportingOverview";
import { useGetInvoice } from "hooks/app/marketplace";

const FinanceReportingDetails = () => {
  const navigate = useNavigate();
  const { tradeId, cropType } = useParams();

  const { filterParams } = useDataFilter();

  // eslint-disable-next-line no-unused-vars
  const { data: financeReportingOverviewData, isLoading: isfinanceReportingOverviewLoading } =
    useGetFinanceReportingOverview({
      params: {
        dateRange: filterParams.dateRange,
      },
    });

  const total_cost_of_sales = financeReportingOverviewData?.totalCostofSales || 0;
  const total_revenue = financeReportingOverviewData?.totalRevenue || 0;
  const actual_gross_profit = financeReportingOverviewData?.totalGrossProfit || 0;
  const gross_profit_percentage = financeReportingOverviewData?.grossProfitPercentage?.toFixed(2) || 0;

  const cardData = [
    {
      id: 1,
      name: "Crop Type",
      value: `${cropType}`,
    },
    {
      id: 2,
      name: "Total Revenue",
      value: total_revenue.toLocaleString(),
    },
    {
      id: 3,
      name: "Gross profit percentage",
      value: `${gross_profit_percentage}%`,
    },
    {
      id: 4,
      name: "Cost of Sales (KES)",
      value: total_cost_of_sales.toLocaleString(),
    },
    {
      id: 5,
      name: "Actual Gross Profit (KES)",
      value: actual_gross_profit.toLocaleString(),
    },
  ];

  const handleClick = () => {
    navigate("/dashboard/finance/reporting");
  };

  // table data
  // eslint-disable-next-line no-unused-vars
  const { data: financeReportingTableData, isLoading: isfinanceReportingOTableLoading } = useGetInvoice({
    params: {
      dateRange: filterParams.dateRange,
    },
  });

  const {
    tableProps,
    // selectedRow: selectedTrade
  } = useTable({
    columns: [
      {
        id: "cropId",
        Header: (
          <Flex flexDirection="row" gap="4px" alignItems="center">
            <Text color="gray500" font="smSemiBold">
              Crop ID
            </Text>
            <Icon icon={"arrow-down"} size={16}></Icon>
          </Flex>
        ),
        accessor: "trade_id",
        Cell: ({ value }) => (
          <Text color="gray900" font="smSemiBold">
            {value}
          </Text>
        ),
      },
      {
        idFunction: uniqueId(),
        id: "dateCreated ",
        Header: "Date Created ",
        accessor: "date_issued",
        Cell: ({ value }) => {
          const formattedDate = format(new Date(value), "yyyy-MM-dd hh:mm a");

          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Quantity (KG)",
        accessor: "total_quantity",
      },
      {
        Header: "Pricing (KES)",
        accessor: "total_amount",
      },
    ],
    data: financeReportingTableData?.data?.data,
    title: "All Trades",
    useServerPagination: true,
    noEditAction: true,
    showCheckboxes: false,
  });

  return (
    <PageWrapper>
      <PageHeader title="Finance > Reporting" subtitle="Your trade summary and activity."></PageHeader>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        outline={`1px solid ${organicTheme.colors.gray200}`}
        p="18px"
      >
        <Flex gap="8px" alignItems="center" justifyContent="center">
          <Button colorScheme="" onClick={handleClick}>
            <Icon icon={"arrow-left"} style={{ cursor: "ponter" }} />
          </Button>
          <Text font="smMedium" color="gray900">
            Back to Reporting
          </Text>

          <Box backgroundColor="primary50" p="2px 8px" borderRadius="16px" color="primary700">
            <Text font="sm">{`ID ${tradeId}`}</Text>
          </Box>
        </Flex>
      </Flex>

      <Flex
        flexDirection="row"
        // justifyContent="space-between"
        flexWrap="wrap"
        gap="2px"
        marginTop="32px"
        maxWidth="1828px"
        minHeight="122px"
        outline={`1px solid ${organicTheme.colors.gray200}`}
        backgroundColor="gray100"
        pl="2px"
        pb="1px"
      >
        {/* CARDS */}
        {cardData.map(data => (
          <Flex
            key={data.id}
            width="200px"
            flex={1}
            flexDirection="column"
            justifyContent="center"
            borderRight={`1px solid ${organicTheme.colors.gray200}`}
            backgroundColor="white"
            height="122px"
          >
            <Text ml="20px" mb="8px" font="sm" color="gray500">
              {data.name}
            </Text>
            <Text font="displayXsBold" ml="20px">
              {data.value}
            </Text>
          </Flex>
        ))}
      </Flex>

      <Table {...tableProps} noActions={true} />
    </PageWrapper>
  );
};

export default FinanceReportingDetails;
