import { ExportButton } from "components/export";
import { Divider, PageHeader, PageWrapper, Panel } from "components/layout";
import { Table } from "components/table";
import { getAllCampaignData, getCampaignReport, getCampaignReportData } from "features/v1/bulkSms";
import { useTable } from "hooks";
import { Badge, Button, Flex, Text } from "organic";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, hyphenate } from "utils";

const filterMessagesByStatus = (messages = [], status) => {
  try {
    console.log({ messages });
    return messages?.filter(message => message?.status === status)?.length || 0;
  } catch (err) {
    console.log({ err });
  }
};

const messageStatusBadgeColors = {
  processing: "blueLight",
  sent: "success",
  delivered: "success",
  notSent: "orange",
};

const SmsCampaignReport = () => {
  const dispatch = useDispatch();

  let campaignReport = useSelector(getCampaignReportData);
  let campaignData = useSelector(getAllCampaignData);

  const { _id } = useParams();
  const navigate = useNavigate();

  const selected = React.useMemo(() => {
    if (_id) {
      return campaignData.find(campaign => campaign._id === _id);
    } else {
      return campaignData[0];
    }
  }, []);

  const reportFields = [
    {
      label: "In Progress",
      icon: <HourGlass />,
      color: "blueLight500",
      value: filterMessagesByStatus(campaignReport, "processing"),
    },
    {
      label: "Recipients",
      icon: <UsersRight />,
      color: "purple500",
      value: selected?.recipient?.length || 0,
    },
    {
      label: "Sent",
      icon: <CheckCircleBroken />,
      color: "primary500",
      value: filterMessagesByStatus(campaignReport, "sent"),
    },
    {
      label: "Failed to Send",
      icon: <XCircle />,
      color: "error500",
      value: filterMessagesByStatus(campaignReport, "notSent"),
    },
    {
      label: "Delivered",
      icon: <NavigationPointer01 />,
      color: "primary500",
      value: filterMessagesByStatus(campaignReport, "delivered"),
    },
  ];

  const { tableProps } = useTable({
    data: campaignReport,
    columns: [
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      {
        id: "messageDate",
        Header: "Date",
        accessor: message => formatDate(message.created_at),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge colorScheme={messageStatusBadgeColors[value]}>{value}</Badge>,
      },
    ],
    title: "Recent Messages",
    noActions: true,
  });

  useEffect(() => {
    dispatch(getCampaignReport(_id));
  }, []);

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <PageHeader title="Messaging > SMS Campaign > Report" subtitle="Manage all SMS campaign and activity." />
        <ExportButton />
      </Flex>
      <Panel
        headerElement={
          <Flex alignItems="center" justifyContent="flex-start">
            <Button
              leftIcon="arrow-left"
              variant="unstyled"
              onClick={() => {
                navigate("/dashboard/messaging");
              }}
            >
              <Text font="lgMedium">Back to Campaings</Text>
            </Button>
          </Flex>
        }
      >
        <Divider />
        <Flex justifyContent="space-between" height="4rem" alignItems="center">
          <Flex paddingLeft="1.5rem" width="100%">
            <Text font="mdMedium" color="gray900">
              Campaign:&nbsp; {selected.campaign_title}
            </Text>
          </Flex>
          <Divider orientation="vertical" />
          <Flex width="100%" pl="1.5rem">
            <Text font="mdMedium" color="gray900">
              Report
            </Text>
          </Flex>
        </Flex>
        <Divider />
        <Flex justifyContent="space-between">
          <Flex px="1.5rem" pt="20px" height="132px" width="100%">
            <Flex
              bg="gray100"
              borderRadius="0px 8px 8px 8px"
              height="68px"
              alignItems="center"
              py="10px"
              px="14px"
              maxWidth="505px"
              overflowY="auto"
            >
              <Text font="mdRegular" color="gray900">
                {selected.content}
              </Text>
            </Flex>
          </Flex>
          <Divider orientation="vertical" />
          <Flex flexDirection="column" gap="12px" py="12px" px="1.5rem" width="100%">
            {reportFields.map((reportField, reportFieldIndex) => {
              return (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  key={hyphenate(["campaign", selected._id, "report-val", reportFieldIndex])}
                >
                  <Flex gap="12px">
                    {reportField.icon}
                    <Text font="smMedium" color="gray700">
                      {reportField.label}
                    </Text>
                  </Flex>
                  <Text color={reportField.color}>{reportField?.value || 0}</Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Panel>
      <Table {...tableProps} />
    </PageWrapper>
  );
};

export default SmsCampaignReport;

const HourGlass = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M6.00065 12.3334H10.0007M4.40065 1.33337H11.6007C11.974 1.33337 12.1607 1.33337 12.3033 1.40604C12.4288 1.46995 12.5307 1.57194 12.5947 1.69738C12.6673 1.83999 12.6673 2.02667 12.6673 2.40004V3.78305C12.6673 4.10917 12.6673 4.27223 12.6305 4.42568C12.5978 4.56173 12.5439 4.69179 12.4708 4.81109C12.3884 4.94564 12.2731 5.06094 12.0425 5.29155L10.0882 7.24579C9.82422 7.50981 9.69222 7.64181 9.64276 7.79403C9.59925 7.92793 9.59925 8.07216 9.64276 8.20605C9.69222 8.35827 9.82422 8.49028 10.0882 8.75429L12.0425 10.7085C12.2731 10.9391 12.3884 11.0544 12.4708 11.189C12.5439 11.3083 12.5978 11.4384 12.6305 11.5744C12.6673 11.7278 12.6673 11.8909 12.6673 12.217V13.6C12.6673 13.9734 12.6673 14.1601 12.5947 14.3027C12.5307 14.4281 12.4288 14.5301 12.3033 14.594C12.1607 14.6667 11.974 14.6667 11.6007 14.6667H4.40065C4.02728 14.6667 3.8406 14.6667 3.69799 14.594C3.57255 14.5301 3.47056 14.4281 3.40665 14.3027C3.33398 14.1601 3.33398 13.9734 3.33398 13.6V12.217C3.33398 11.8909 3.33398 11.7278 3.37082 11.5744C3.40349 11.4384 3.45736 11.3083 3.53046 11.189C3.61292 11.0544 3.72822 10.9391 3.95882 10.7085L5.91307 8.75429C6.17708 8.49028 6.30909 8.35827 6.35855 8.20605C6.40205 8.07216 6.40205 7.92793 6.35855 7.79403C6.30909 7.64181 6.17708 7.5098 5.91307 7.24579L3.95882 5.29155C3.72822 5.06094 3.61292 4.94564 3.53046 4.81109C3.45736 4.69179 3.40349 4.56173 3.37082 4.42568C3.33398 4.27223 3.33398 4.10917 3.33398 3.78305V2.40004C3.33398 2.02667 3.33398 1.83999 3.40665 1.69738C3.47056 1.57194 3.57255 1.46995 3.69799 1.40604C3.8406 1.33337 4.02728 1.33337 4.40065 1.33337Z"
      stroke="#0BA5EC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UsersRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6673 14L14.6673 12M14.6673 12L12.6673 10M14.6673 12H10.6673M10.334 2.19384C11.3113 2.58943 12.0007 3.54754 12.0007 4.66667C12.0007 5.78579 11.3113 6.7439 10.334 7.13949M8.00065 10H5.33398C4.09147 10 3.47022 10 2.98016 10.203C2.32675 10.4736 1.80762 10.9928 1.53697 11.6462C1.33398 12.1362 1.33398 12.7575 1.33398 14M9.00065 4.66667C9.00065 6.13943 7.80674 7.33333 6.33398 7.33333C4.86123 7.33333 3.66732 6.13943 3.66732 4.66667C3.66732 3.19391 4.86123 2 6.33398 2C7.80674 2 9.00065 3.19391 9.00065 4.66667Z"
      stroke="#7A5AF8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckCircleBroken = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_5052_25222)">
      <path
        d="M14.6673 7.39052V8.00386C14.6665 9.44147 14.201 10.8403 13.3402 11.9917C12.4794 13.1432 11.2695 13.9855 9.89089 14.3931C8.51227 14.8007 7.03882 14.7518 5.6903 14.2536C4.34177 13.7554 3.19042 12.8346 2.40796 11.6286C1.6255 10.4225 1.25385 8.9959 1.34844 7.5614C1.44303 6.1269 1.99879 4.76141 2.93284 3.66857C3.86689 2.57574 5.12917 1.81411 6.53144 1.49729C7.93371 1.18046 9.40083 1.32541 10.714 1.91052M14.6673 2.66671L8.00065 9.34004L6.00065 7.34004"
        stroke="#48A928"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5052_25222">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const XCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_5052_25216)">
      <path
        d="M10.0007 6.00004L6.00065 10M6.00065 6.00004L10.0007 10M14.6673 8.00004C14.6673 11.6819 11.6826 14.6667 8.00065 14.6667C4.31875 14.6667 1.33398 11.6819 1.33398 8.00004C1.33398 4.31814 4.31875 1.33337 8.00065 1.33337C11.6826 1.33337 14.6673 4.31814 14.6673 8.00004Z"
        stroke="#F04438"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5052_25216">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const NavigationPointer01 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_5052_25220)">
      <path
        d="M2.27498 7.16299C1.87809 7.00864 1.67964 6.93146 1.6217 6.82026C1.57148 6.72386 1.57141 6.60903 1.62152 6.51257C1.67933 6.4013 1.87768 6.32389 2.2744 6.16908L13.5329 1.77552C13.891 1.63576 14.0701 1.56588 14.1845 1.60411C14.2839 1.6373 14.3618 1.71528 14.395 1.81465C14.4333 1.92907 14.3634 2.10813 14.2236 2.46625L9.83007 13.7247C9.67525 14.1215 9.59785 14.3198 9.48658 14.3776C9.39012 14.4277 9.27528 14.4277 9.17888 14.3774C9.06768 14.3195 8.99051 14.1211 8.83616 13.7242L7.08408 9.21883C7.05275 9.13826 7.03709 9.09798 7.01289 9.06406C6.99145 9.03399 6.96515 9.0077 6.93509 8.98625C6.90117 8.96206 6.86089 8.94639 6.78032 8.91506L2.27498 7.16299Z"
        stroke="#48A928"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5052_25220">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
