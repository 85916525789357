import { Flex, Text } from "organic";
import React from "react";

const Card = ({ children, stackHorizontal = false, title, width = "auto", height = "auto", ...otherCardProps }) => {
  return (
    <Flex
      flexDirection="column"
      px={["0.5rem", "1rem", "1rem", "1rem", "1rem", "1.5rem"]}
      py="1rem"
      width={width}
      height={height}
      justifyContent="space-between"
      backgroundColor="white"
      borderRadius="0.5rem"
      borderWidth="1px"
      borderColor="gray200"
      borderStyle="solid"
      {...otherCardProps}
    >
      <>
        {title && (
          <Text as="p" font="smMedium" color="gray500">
            {title}
          </Text>
        )}
        <Flex
          width={stackHorizontal ? "auto" : "100%"}
          height={"100%"}
          flexDirection={stackHorizontal ? "row" : "column"}
          justifyContent={"space-between"}
        >
          {children}
        </Flex>
      </>
    </Flex>
  );
};
export { Card };
export default Card;
