import React, { useMemo } from "react";
import { PageWrapper } from "components/layout";
import { Flex } from "organic";
import { PageHeader } from "components/layout";

import { useSearchParams } from "react-router-dom";
import { PageTabs } from "components/pageTabs";
import { appGet } from "utils";
import { useDataFilter } from "hooks";
import { useGetMarketplaceOverview } from "hooks/app/marketplace";
import ClosedTrades from "./trade/closedTrades";
import FinalisedTrades from "./trade/finalisedTrades";
import Invoicing from "./trade/invoicing";
import StockedTrades from "./trade/stockedTrades";
import ApprovedTrades from "./trade/approvedTrades";
import PendingTrades from "./trade/pendingTrades";
import { DeclinedTrades } from "./trade/declinedTrades";

const tabIds = {
  PENDING: "pending",
  APPROVED: "approved",
  DECLINED: "declined",
  STOCKED: "stocked",
  INVOICED: "invoiced",
  FINALIZED: "finalized",
  CLOSED: "closed",
};

export const tradeStatuses = {
  PENDING: "pending",
  DECLINED: "declined",
  APPROVED: "approved",
  STOCKED: "stocked",
  INVOICE: "stocked",
  FINALIZED: "finalized",
  CLOSED: "closed",
};

const MarketplacePlaceTradePage = () => {
  const [searchParams, __] = useSearchParams();
  const ignoredactiveTab = appGet(searchParams, "tab", "tab1");

  const { filterParams } = useDataFilter();
  const { data: overviewData } = useGetMarketplaceOverview({
    params: filterParams,
  });

  const tabs = useMemo(
    () => [
      {
        tabId: tabIds.PENDING,
        title: "Pending Approval",
        badge: appGet(overviewData, "pending", "--"),
        content: <PendingTrades />,
      },
      {
        tabId: tabIds.APPROVED,
        title: "Approved Trade",
        badge: appGet(overviewData, "approved", "--"),
        content: <ApprovedTrades />,
      },
      {
        tabId: tabIds.DECLINED,
        title: "Declined Trade",
        badge: appGet(overviewData, "declined", "--"),
        content: <DeclinedTrades />,
      },
      {
        tabId: tabIds.STOCKED,
        title: "Stocked",
        badge: appGet(overviewData, "stocked", "--"),
        content: <StockedTrades />,
      },
      // {
      //   tabId: tabIds.DELIVERED,
      //   title: "Delivered",
      //   badge: appGet(overviewData, "delivered", "--"),
      //   content: <DeliveredTrade />,
      // },
      {
        tabId: tabIds.INVOICED,
        title: "Invoicing",
        badge: appGet(overviewData, "stocked", "--"),
        content: <Invoicing />,
      },
      {
        tabId: tabIds.FINALIZED,
        title: "Finalised",
        badge: appGet(overviewData, "finalized", "--"),
        content: <FinalisedTrades />,
      },
      {
        tabId: tabIds.CLOSED,
        title: "Closed",
        badge: appGet(overviewData, "closed", "--"),
        content: <ClosedTrades />,
      },
    ],
    [overviewData]
  );

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" align-Items="center">
        <PageHeader title="Marketplace > Trade" subtitle="Your trade summary and activity." />
      </Flex>
      <PageTabs tabs={tabs} />
    </PageWrapper>
  );
};

export default MarketplacePlaceTradePage;
