import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllFarmMappingsWithParams,
  searchAllFarmMappingsWithParams,
  getFarmMappingData,
  // getLoadingFarmMapping,
  getFarmMappingMeta,
} from "features/v1/farmMapping";
import { Table } from "components/table";
import { useDisclosure, useTable } from "hooks";
import { legacyFormatDate } from "utils/v1/tableUtils";
import { Flex } from "organic";
import { Modal, ModalContent } from "components/modal";
import ViewMappingDetails from "./viewMappingDetails";
import EditMappingDetails from "./editMappingDetails";
import { convertObjectToLatLngObject, appGet } from "utils";
// import { useGoogleMaps } from "hooks/app";
import { LeafletMap, LeafletMarker, LeafletPolygon } from "components/map";
import { nanoid } from "nanoid";
import { FarmMappingSelect } from "components/select";
import { useLeafletMaps } from "hooks/app/maps/useLeafletMaps";

const MappingOverviewPage = () => {
  const dispatch = useDispatch();

  let farmMappingData = useSelector(getFarmMappingData);
  // let loading = useSelector(getLoadingFarmMapping);
  let farmMappingMeta = useSelector(getFarmMappingMeta);

  const farmMappingModal = useDisclosure();
  const farmMappingEditModal = useDisclosure();

  // const { isMapLoaded, drawPolygon, placeManyMarkers, drawManyPolygons } = useGoogleMaps({
  //   libraries: ["core", "maps", "marker", "geometry", "geocode"],
  // });

  const farmMaps = useMemo(() => {
    if (!farmMappingData?.length) {
      return [];
    }

    const transformedMappingData = farmMappingData
      ?.filter(mapping => mapping?.mappedFarm?.routeCoordinates)
      .map(mapping => ({
        key: mapping._id,
        positions: mapping?.mappedFarm?.routeCoordinates.map(coord =>
          convertObjectToLatLngObject(coord, {
            latKey: "latitude",
            lngKey: "longitude",
          })
        ),
      }));

    return transformedMappingData;
  }, [farmMappingData]);

  const { mapRef, isMapLoaded, flyTo } = useLeafletMaps(farmMappingData);

  const { tableProps, selectedRow: selectedFarmer } = useTable({
    data: farmMappingData,
    columns: [
      {
        Header: "ID",
        accessor: "ta_number",
      },
      {
        id: "mappingCrop",
        Header: "Crop Type",
        accessor: mapping => appGet(mapping, ["crop_type", "name"], "N/A"),
      },
      {
        id: "mappingDate",
        Header: "Date",
        accessor: mapperInfo => legacyFormatDate(mapperInfo?.createed_at),
      },
      // {
      //   id: "mappingAgent",
      //   Header: "Agent",
      //   accessor: mapperInfo => `${mapperInfo?.agent?.first_name || "N/A"} ${mapperInfo?.agent?.last_name || "N/A"}`,
      // },
      {
        id: "mappingFarmer",
        Header: "Farmer",
        accessor: mapperInfo => `${mapperInfo?.farmer?.first_name || "N/A"} ${mapperInfo?.farmer?.last_name || "N/A"}`,
      },
      {
        Header: "Farm Size (ha)",
        accessor: "farm_size",
      },
      {
        id: "mappingAccuracy",
        Header: "Accuracy",
        accessor: mapperInfo => mapperInfo?.coordinate?.accuracy || "N/A",
      },
      {
        Header: "Adjusted Farm Size (ha)",
        accessor: "adjusted_farm_size",
      },
    ],
    title: "All Mappings",
    useServerPagination: true,
    serverPaginationMeta: farmMappingMeta,
    noViewAction: true,
  });

  // const renderMap = useCallbackRef(() => {
  //   if (isMapLoaded && farmMappingData?.length > 0) {
  //     const markersData = farmMappingData.map(mapping => mapping?.coordinate || {});
  //     const mappingData = farmMappingData.map(mapping => mapping?.mappedFarm?.routeCoordinates || []);

  //     console.log({ mappingData, markersData });

  //     // place markers
  //     placeManyMarkers(markersData, {
  //       filterFn(markerDatum) {
  //         return markerDatum?.latitude && markerDatum?.longtitude;
  //       },
  //       transformFn(datum) {
  //         return {
  //           lat: toNumber(datum.latitude),
  //           lng: toNumber(datum.longtitude),
  //         };
  //       },
  //       onSuccess() {
  //         console.log("markers drawn from hook");
  //       },
  //     });

  //     drawPolygon(mappingData[0], {
  //       transformFn(point) {
  //         return {
  //           lat: point.latitude,
  //           lng: point.longitude,
  //         };
  //       },
  //     });

  //     // draw polygons
  //     drawManyPolygons(mappingData, {
  //       filterFn(polygonDatum) {
  //         return polygonDatum?.length > 0;
  //       },
  //       transformFn(polygonDatum) {
  //         console.log({ polygonDatum });

  //         return polygonDatum.map(polygonPoint => {
  //           return {
  //             lat: polygonPoint.latitude,
  //             lng: polygonPoint.longitude,
  //           };
  //         });
  //       },
  //     });
  //   }
  // }, [isMapLoaded, farmMappingData, placeManyMarkers, drawManyPolygons]);

  // useDidMountEffect(() => {
  //   if (isMapLoaded) {
  //     renderMap();
  //   }
  // }, [isMapLoaded, renderMap]);

  return (
    <>
      <Flex flexDirection="column" position="relative" my="2rem">
        {/* Map */}
        <LeafletMap ref={mapRef}>
          {farmMappingData?.length
            ? farmMappingData
                .filter(mapping => mapping?.coordinate)
                .map(mapping => {
                  return (
                    <LeafletMarker
                      key={nanoid()}
                      position={convertObjectToLatLngObject(mapping.coordinate, {
                        latKey: "latitude",
                        lngKey: "longtitude",
                      })}
                    />
                  );
                })
            : null}
          {farmMaps?.length
            ? farmMaps.map(transformedMapping => {
                return <LeafletPolygon key={transformedMapping.key} positions={transformedMapping.positions} />;
              })
            : null}
        </LeafletMap>

        <Flex position="absolute" zIndex={999999} right="1rem" top="1rem" minWidth="360px">
          <FarmMappingSelect
            onChange={farm => {
              console.log({ farm, isMapLoaded });
              // if (isMapLoaded) {
              flyTo(
                convertObjectToLatLngObject(farm.coordinate, {
                  latKey: "latitude",
                  lngKey: "longtitude",
                })
              );
              // }
            }}
          />
        </Flex>
      </Flex>
      {/* </VisibilityWrapper> */}
      {/* <VisibilityWrapper isVisible={!farmMappingData?.length}>
        <EmptyState title="Map View" subtitle="No data to show  on map" />
      </VisibilityWrapper> */}

      {/* Table */}
      <Table
        {...tableProps}
        onViewAction={() => {
          farmMappingModal.onOpen();
        }}
        onDataFilter={filterParams => {
          dispatch(getAllFarmMappingsWithParams({ ...filterParams }));
        }}
        onSearch={(params, { signal }) => {
          console.log("search callback", { text: params.searchText });
          dispatch(searchAllFarmMappingsWithParams(params, { signal }));
        }}
        onEditAction={() => {
          farmMappingEditModal.onOpen();
        }}
      />
      {/* <ViewFarmMappingDetails modal={farmMappingModal} selected={selectedFarmMapping} />
      <EditFarmMappingDetails
        updateFarmMappingInfo={handleUpdateFarmMappingInfo}
        modal={farmMappingModal}
        selected={selectedFarmMapping}
      /> */}
      {/* view farmer */}
      <Modal
        size
        open={farmMappingModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmMappingModal.onClose();
          }
        }}
      >
        <ModalContent title="View Farmer  Details">
          <ViewMappingDetails />
        </ModalContent>
      </Modal>
      {/* edit farmer */}
      <Modal
        open={farmMappingEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmMappingEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Farm Mapping Details">
          <EditMappingDetails selected={selectedFarmer} modal={farmMappingEditModal.modal} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default MappingOverviewPage;
