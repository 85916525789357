import { faker } from "@faker-js/faker";
import { mockOverviewConstants } from "./constants";

export function mockSingelAgent() {
  return {
    address: "Kiyawa",
    avatar_url: null,
    country: "Nigeria",
    created_at: "2023-11-18T06:18:46.875Z",
    email: "nasirumustapha116@gmail.com",
    farmer_accounts: [],
    farmers: [],
    farmers_groups: [],
    first_name: "Nasiru",
    gender: "male",
    idx: 4811,
    last_name: "Mustapha",
    lga: "Kiyawa",
    organization: "62017d4a048dba00f7185f16",
    phone_number: "08131823127",
    region: "r5",
    role: "agent",
    siloId: "@Nasiry_01",
    siloType: "b",
    state: null,
    _id: "65585746000dd40014d7dbf0",
  };
}

export function mockAgentOverviewData() {
  return {
    data: {
      data: faker.helpers.multiple(mockSingelAgent, { count: 1000 }),
      meta: { totalDocCount: mockOverviewConstants.AGENT_COUNT },
    },
  };
}
