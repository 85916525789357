const SCOPE_KEY = "scope";

export const resourceTypes = {
  all: "all",
  agent: "agent",
  clearFarmer: "clearFarmer",
  crop: "crop",
  farmer: "farmer",
  farmerAccount: "farmerAccount",
  farmerCollectedInput: "farmerCollectedInput",
  farmerPayment: "farmerPayment",
  farmerSeason: "farmerSeason",
  farmMapper: "farmMapper",
  farmMonitoring: "farmMonitoring",
  farmSubActivity: "farmSubActivity",
  farmSubPayment: "farmSubPayment",
  farmSupervision: "farmSupervision",
  farmSupervisionCategory: "farmSupervisionCategory",
  harvest: "harvest",
  inputType: "inputType",
  language: "language",
  questionnaire: "questionnaire",
  season: "season",
  subscription: "subscription",
  survey: "survey",
  marketPlace: "marketPlace",
};

const resourceNames = {
  all: "Organisation",
  agent: "Agent",
  clearFarmer: "Farmer Clearance",
  crop: "Crop",
  farmer: "Farmer",
  farmerAccount: "Farmer Account",
  farmerCollectedInput: "Input Distribution",
  farmerPayment: "Farmer Payments",
  farmerSeason: "Farmer Season",
  farmMapper: "Farm Mapper",
  farmMonitoring: "Farm Monitoring",
  farmSubActivity: "Farm Subscription Activity",
  farmSubPayment: "Farm Subscription Payment",
  farmSupervision: "Farm Supervision",
  farmSupervisionCategory: "Farm Supervision Category",
  harvest: "Harvest",
  inputType: "Input Type",
  language: "Language",
  questionnaire: "Questionnaire",
  season: "Season",
  subscription: "Subscription",
  survey: "Survey",
  marketPlace: "Market Place",
};

export const roleConstants = {
  basicRoles: {
    SUB_ADMIN: "subAdmin",
    REGIONAL_MANAGER: "regionalManager",
    ZONAL_MANAGER: "zonalManager",
    OPERATIONAL_MANAGER: "operationalManager",
    FINANCE: "finance",
    STORE_MANAGER: "storeManager",
    AGENT: "agent",
  },

  resources: resourceTypes,
  resourceTypes,
  storageKeys: {
    scope: SCOPE_KEY,
  },
  resourceOptions: [
    { label: resourceNames.agent, value: resourceTypes.agent },
    { label: resourceNames.clearFarmer, value: resourceTypes.clearFarmer },
    { label: resourceNames.crop, value: resourceTypes.crop },
    { label: resourceNames.farmer, value: resourceTypes.farmer },
    { label: resourceNames.farmerAccount, value: resourceTypes.farmerAccount },
    { label: resourceNames.farmerCollectedInput, value: resourceTypes.farmerCollectedInput },
    { label: resourceNames.farmerPayment, value: resourceTypes.farmerPayment },
    { label: resourceNames.farmerSeason, value: resourceTypes.farmerSeason },
    { label: resourceNames.farmMapper, value: resourceTypes.farmMapper },
    { label: resourceNames.farmMonitoring, value: resourceTypes.farmMonitoring },
    { label: resourceNames.farmSubActivity, value: resourceTypes.farmSubActivity },
    { label: resourceNames.farmSubPayment, value: resourceTypes.farmSubPayment },
    { label: resourceNames.farmSupervision, value: resourceTypes.farmSupervision },
    { label: resourceNames.farmSupervisionCategory, value: resourceTypes.farmSupervisionCategory },
    { label: resourceNames.harvest, value: resourceTypes.harvest },
    { label: resourceNames.inputType, value: resourceTypes.inputType },
    { label: resourceNames.language, value: resourceTypes.language },
    { label: resourceNames.questionnaire, value: resourceTypes.questionnaire },
    { label: resourceNames.season, value: resourceTypes.season },
    { label: resourceNames.subscription, value: resourceTypes.subscription },
    { label: resourceNames.survey, value: resourceTypes.survey },
    { label: resourceNames.marketPlace, value: resourceTypes.marketPlace },
  ],
  resourceNames,
};
