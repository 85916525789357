import React from "react";

import DataGridRow from "components/v1/DataGrid/DataGridRow";
import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
import { legacyFormatDate, DEPRECATED_formatRole } from "utils/v1/tableUtils";
import { Flex } from "organic";

const ViewMappingDetails = ({ selected }) => {
  return (
    <Flex flexDirection="column" justifyContent="space-around">
      <DataGridRow
        fields={[
          {
            label: "Adjusted Farm Size (ha)",
            value: selected?.adjusted_farm_size || selected?.farm_size || "N/A",
          },
          {
            label: "Status",
            value: selected?.status || "active",
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Crop Type",
            value: selected?.crop_type,
          },
          {
            label: "Farm Size (ha)",
            value: selected?.farm_size,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Created on",
            value: legacyFormatDate(selected?.onboarded_at),
          },
          {
            label: "Field ID (TA)",
            value: selected?.ta_number,
          },
        ]}
      />

      <DataGridTitle title="Farmer Details" />
      <DataGridRow
        fields={[
          {
            label: "Phone Number",
            value: selected?.farmer?.phone_number,
          },
          {
            label: "Role",
            value: DEPRECATED_formatRole(selected?.farmer?.role),
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "First Name",
            value: selected?.farmer?.first_name,
          },
          {
            label: "Last Name",
            value: selected?.farmer?.last_name,
          },
        ]}
      />
      <DataGridTitle title="Agent Detials" />
      <DataGridRow
        fields={[
          {
            label: "First Name",
            value: selected?.agent?.first_name || "N/A",
          },
          {
            label: "Last Name",
            value: selected?.agent?.last_name,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Phone Number",
            value: selected?.agent?.phone_number,
          },
        ]}
      />
    </Flex>
  );
};

export default ViewMappingDetails;
