import numeral from "numeral";
import { useMemo } from "react";

const calcServerSidePageCount = ({ totalDocCount = 1, nPerPage = 1 }) => {
  const _totalDocCount = numeral(totalDocCount).value();
  const _nPerPage = numeral(nPerPage).value();

  console.log({ _totalDocCount, _nPerPage, totalDocCount, nPerPage });
  return Math.ceil(
    numeral(_totalDocCount)
      .divide(_nPerPage || 1)
      .value()
  );
};

export function useServerPagination({ enabled = false, pagination = {} }) {
  const _enabled = useMemo(() => {
    return enabled;
  }, [enabled]);

  const _totalDocCount = useMemo(() => {
    return numeral(pagination?.totalDocCount || 1).value();
  }, [pagination?.totalDocCount]);

  const _nPerPage = useMemo(() => {
    return numeral(pagination?.nPerPage || 1).value();
  }, [pagination?.nPerPage]);

  const _noOfRows = useMemo(() => {
    return numeral(pagination?.pageCount || 0).value();
  }, [pagination?.pageCount]);

  const _pages = useMemo(() => {
    if (!_enabled) {
      return undefined;
    }

    if (_enabled) {
      return calcServerSidePageCount({
        totalDocCount: _totalDocCount,
        nPerPage: _nPerPage,
      });
    }
  }, [_enabled, _totalDocCount, _nPerPage]);

  return {
    totalDocCount: _totalDocCount,
    nPerPage: _nPerPage,
    pages: _pages,
    noOfRows: _noOfRows,
  };
}
