// a styled component that serves as wrapper for Avatar components and Lays them out in a horizontal cascade
import styled, { css } from "styled-components/macro";

export const StyledAvatarGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  ${props => css`
    width: calc(${props.size * props.count});
    height: calc(${props.size});
  `}
`;
