import colors from "organic/theme/colors";
import styled from "styled-components";

export const Label = styled.label`
  display: block;
  font-weight: 500;
  font-size: 14px;
`;

export const StyledTextBox = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  height: 44px;
  align-items: center;

  background-color: ${colors.gray50};
  outline: 1px solid ${colors.gray300};
  border-radius: 0px;
  border: none;
  font-size: 1rem;
  padding: 0px 14px;
  cursor: not-allowed;
  color: ${colors.gray500};

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &:hover {
    outline-color: ${colors.gray500};
  }
  &:focus {
    outline-color: ${colors.gray500};
  }
`;
