import crops from "api/crops";
import inputs from "api/inputs";
import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";
import { getOrg } from "services/v1/authService";
import { getSubmissionConfigOptions } from "utils/v1/apiUtils";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
import { printObj } from "utils/v1/textUtils";

// Action Types
const GET_SEASON_CROPS = "GET_SEASON_CROPS";
const LOAD_CROPS = "LOAD_CROPS";
const GET_SEASON_INPUTS = "GET_SEASON_INPUTS";
const LOAD_INPUTS = "LOAD_INPUTS";

const organizationId = getOrg();

// Action Creators

/** create crop for season
 * @param {string} values.name - name of crop
 * @param {string} values.season - season of crop
 */
export const createCropForSeason = (values, config) => async dispatch => {
  const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);
  try {
    dispatch({ type: LOAD_CROPS, payload: true });
    let createCropRes = await crops.post("add", values);
    toast.success("Crop Created Successfully");
    printObj(createCropRes, "createCrop response");
    if (hasModal) config.modal.hideModal();
    if (hasFormOptions) config.formOptions.setSubmitting(false);
    dispatch({ type: LOAD_CROPS, payload: false });
    return dispatch(getAllCrops());
  } catch (err) {
    if (hasFormOptions) config.formOptions.setSubmitting(false);
    handleActionError(err, "createCropError");
    dispatch({ type: LOAD_CROPS, payload: false });
  }
};

/** Get all crops for season */
export const getAllCrops =
  (params = {}, config = {}) =>
  async dispatch => {
    try {
      dispatch({ type: LOAD_CROPS, payload: true });
      const getAllCropsRes = await crops.get("", { params });
      let cropOptions = [];
      cropOptions = getAllCropsRes.data.data.map(crop => {
        return {
          label: `${crop.name}`,
          value: crop._id,
          ...crop,
        };
      });
      dispatch({
        type: GET_SEASON_CROPS,
        payload: {
          cropsData: getAllCropsRes.data.data,
          cropOptions,
          cropsMeta: getAllCropsRes.data.meta || {},
        },
      });
      dispatch({ type: LOAD_CROPS, payload: false });
      if (config?.onSuccess) {
        config.onSuccess();
      }
      return true;
    } catch (err) {
      dispatch({ type: LOAD_CROPS, payload: false });
      handleActionError(err);
      return false;
    }
  };

/** update crop */
export const updateCrop =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions, hasModal } = getSubmissionConfigOptions(config);
    const body = sanitizeMongoDoc(values);

    dispatch({ type: LOAD_CROPS, payload: true });
    try {
      let addInputTypesRes = await crops.put(`update/${organizationId}/${config.cropId}`, body, { ignoreOrgId: true });
      if (addInputTypesRes.status === 200) {
        toast.success("crop details updated sucessfully");
        if (hasModal) config.modal.hideModal();
        dispatch({ type: LOAD_CROPS, payload: false });
        return dispatch(getAllCrops());
      }
    } catch (err) {
      dispatch({ type: LOAD_CROPS, payload: false });
      if (hasFormOptions) config.formOptions.setSubmitting(false);
      handleActionError(err);
    }
  };

/** Update Crop Variety */
export const updateCropVariety = (values, config) => async dispatch => {
  try {
    dispatch({ type: LOAD_CROPS, payload: true });
    const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);
    await crops.put("addVariety", values, { params: { organizationId, crop: config.cropId } });
    toast.success("Crop Variety updated Successfully");
    if (hasFormOptions) config.formOptions.setSubmitting(false);
    if (hasModal) config.modal.hideModal();
    dispatch({ type: LOAD_CROPS, payload: false });
    return dispatch(getAllCrops({}));
  } catch (createInputTypeError) {
    dispatch({ type: LOAD_CROPS, payload: false });
    handleActionError(createInputTypeError);
  }
};

/** delete input for crop type */
export const deleteInputsForCrop =
  (cropId, config = {}) =>
  async dispatch => {
    const { hasFormOptions, hasModal } = getSubmissionConfigOptions(config);
    try {
      dispatch({ type: LOAD_CROPS, payload: true });

      let deleteInputTypesRes = await crops.put(`deleteInputs/${organizationId}/${cropId}`);
      if (deleteInputTypesRes.status === 200) {
        toast.success("input deleted sucessfully");
        if (hasModal) config.modal.hideModal();
        dispatch({ type: LOAD_CROPS, payload: false });
        return dispatch(getCurSeasonInputs({ cropId }));
      }
    } catch (err) {
      dispatch({ type: LOAD_CROPS, payload: false });
      handleActionError(err);
      if (hasFormOptions) config.formOptions.setSubmitting(false);
    }
  };

/** Get all input for a season */
export const getCurSeasonInputs =
  ({ seasonId, cropId }) =>
  async dispatch => {
    try {
      const params = {
        seasonId,
        cropId,
      };
      dispatch({ type: LOAD_INPUTS, payload: true });
      let getAllInputRes = await inputs.get("", { params });

      dispatch({
        type: GET_SEASON_INPUTS,
        payload: {
          inputsData: getAllInputRes.data.data,
          inputsMeta: getAllInputRes.data.meta || {},
        },
      });
      dispatch({ type: LOAD_INPUTS, payload: false });
    } catch (err) {
      dispatch({ type: LOAD_INPUTS, payload: false });
      handleActionError(err);
    }
  };

/** Create Input Type */
export const createInputType = (values, config) => async dispatch => {
  try {
    dispatch({ type: LOAD_INPUTS, payload: true });
    // const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);
    await inputs.post("create", { organization: organizationId, ...values });
    toast.success("Input Created Successfully");
    config?.closeModal();
    // if (hasFormOptions) config.formOptions.setSubmitting(false);
    // if (hasModal) config.modal.hideModal();
    dispatch({ type: LOAD_INPUTS, payload: false });
    return dispatch(getCurSeasonInputs({ seasonId: values.seasonId, cropId: values.cropId }));
  } catch (createInputTypeError) {
    dispatch({ type: LOAD_INPUTS, payload: false });
    handleActionError(createInputTypeError);
  }
};

/** Set Quantity of Inputs per hectare size */
export const setInputQtyPerHectareSize =
  (values, config = {}) =>
  async dispatch => {
    try {
      const quantity_per_hectares = values?.quantity_per_hectares.map(val => ({
        ...val,
        hectare_size: Number(val.hectare_size).toFixed(1),
      }));

      const {
        //  hasFormOptions,
        hasModal,
      } = getSubmissionConfigOptions(config);
      const body = sanitizeMongoDoc({ quantity_per_hectares });
      const params = { inputId: config.inputId };
      dispatch({ type: LOAD_INPUTS, payload: true });
      const setInputQtyPerHectaresRes = await inputs.put("setInputQuantityPerHectares", body, { params });

      if (setInputQtyPerHectaresRes.status === 200) {
        toast.success("Input Quantity per Hectare size set sucessfully");
        if (hasModal) config.modal.hideModal();
        dispatch({ type: LOAD_INPUTS, payload: false });
        return dispatch(getCurSeasonInputs({ cropId: config.cropId, seasonId: config.seasonId }));
      }
    } catch (setInputQtyPerHectareSizeError) {
      dispatch({ type: LOAD_INPUTS, payload: false });
      handleActionError(setInputQtyPerHectareSizeError);
    }
  };

const initialState = {
  cropsData: [],
  cropOptions: [],
  cropsMeta: {},
  loadingCrops: false,
  loadingInputs: false,
  inputsData: [],
  inputsMeta: {},
};

// crops reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SEASON_CROPS:
      return { ...state, ...payload };
    case LOAD_CROPS:
      return { ...state, loadingCrops: payload };
    case GET_SEASON_INPUTS:
      return { ...state, ...payload };
    case LOAD_INPUTS:
      return { ...state, loadingInputs: payload };
    default:
      return state;
  }
};

// selecter funciton
export const getCurSeasonCropsData = state => state.legacyCrops.cropsData;
export const getCropOptions = state => state.legacyCrops.cropOptions;
export const getCurSeasonCropsMeta = state => state.legacyCrops.cropsMeta;
export const getLoadingCurSeasonCrops = state => state.legacyCrops.loadingCrops;
export const getCurSeasonInputsData = state => state.legacyCrops.inputsData;
export const getCurSeasonInputsMeta = state => state.legacyCrops.inputsMeta;
export const getLoadingCurSeasonInputs = state => state.legacyCrops.loadingInputs;
