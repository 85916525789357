import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/accordion";
import { Divider } from "components/layout";
import { PageTabs } from "components/pageTabs";
import { Switch } from "components/switch";
import DataGridRow from "components/v1/DataGrid/DataGridRow";
import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
import { setAllIDClearance } from "features/v1/inputDistribution";
import { getRegionOptions } from "features/v1/regions";
import { useDataFilter } from "hooks";
import { Box, Flex, Text } from "organic";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { toast } from "react-toastify";
import { formatDate, getTAFromFieldId, hyphenate } from "utils";
import { selectLabelFromValue } from "utils/v1/selectUtils";

const ViewInputDistributionDetails = ({ selected, modal }) => {
  const clearanceRef = useRef(
    selected?.id_stages || {
      clearedId1: true,
      clearedId2: false,
      clearedId3: false,
    }
  );
  const dispatch = useDispatch();
  let regionOption = useSelector(getRegionOptions);
  const loadingClearance = useSelector(state => state.legacyInputDistribution.loadingClearance);

  const { filterParams } = useDataFilter();
  async function onClearedChange(checked, key) {
    clearanceRef.current = { ...clearanceRef.current, [key]: checked };
    dispatch(
      setAllIDClearance(
        { ...clearanceRef.current },
        {
          params: { id: selected._id },
          filter: filterParams,
          onError: () => {
            toast.error("Something Went wrong. kindly contact admin");
          },
          onSuccess: () => {
            modal.hideModal();
          },
        }
      )
    );
  }

  const tabs = [
    {
      title: "Summary",
      content: <IDSumary selected={selected} />,
    },
    {
      title: "Farmers",
      content: <IDFarmers selected={selected} />,
    },
    {
      title: "Allocated Inputs",
      content: <IDAllocatedInput selected={selected} />,
    },
  ];

  return (
    <div>
      <DataGridTitle center title="Store" />
      <DataGridRow
        fields={[
          {
            label: "Store Name",
            value: selected?.warehouse.store.name,
          },
          {
            label: "Received By Group",
            value: selected?.groupID,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Distributed by",
            value: selected?.distributedBy,
          },
          {
            label: "Created on",
            value: formatDate(selected?.onboarded_at),
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Region",
            value: selectLabelFromValue(regionOption, selected?.region),
          },
          {
            label: "State",
            value: selected?.warehouse.store.state,
          },
        ]}
      />
      <Box mt="2rem">
        <DataGridTitle center title="Clearance" />
        <Flex justifyContent="space-around">
          {Object.keys(clearanceRef.current).map((clearedStageKey, index) => {
            return (
              <div key={index.toString()}>
                <Flex alignItems="center" flexDirection="column">
                  <Text as="label">{clearedStageKey.slice(-3)}</Text>
                  <Box mt="10px">
                    <Switch
                      checked={selected.id_stages[clearedStageKey]}
                      onCheckedChange={checked => onClearedChange(checked, clearedStageKey)}
                    />
                  </Box>
                  {loadingClearance && <PulseLoader size={8} loading={"loading"} color={"#21c570"} />}
                </Flex>
              </div>
            );
          })}
        </Flex>
      </Box>
      <Box mt="2rem">
        <DataGridTitle title="Other Details" />
        <PageTabs tabs={tabs} />
      </Box>
    </div>
  );
};

export default ViewInputDistributionDetails;

const IDSumary = ({ selected }) => (
  <>
    <DataGridRow
      fields={[
        {
          label: "Farm Size (ha)",
          value: selected?.farm_size,
        },
        {
          label: "Crop Type",
          value: selected?.crop_type,
        },
      ]}
    />
    <DataGridRow
      fields={[
        {
          label: "Approximate Field Size",
          value: `~ ${selected?.roundUpFieldSize} ha`,
        },
        {
          label: "Number of Farmers",
          value: `${selected?.farmers?.length || "No"} Farmers`,
        },
      ]}
    />
  </>
);

const IDFarmers = ({ selected }) => {
  return (
    <>
      {selected?.farmers?.length > 0 ? (
        <div>
          {selected.farmers.map((farmer, farmerIndex) => (
            <div key={farmerIndex.toString()}>
              <DataGridRow
                fields={[
                  {
                    label: "TA Number",
                    value: getTAFromFieldId(farmer.fieldId),
                  },
                  { label: "Field Size (ha)", value: farmer.field_size },
                ]}
              />
            </div>
          ))}
        </div>
      ) : (
        "No Farmers Data"
      )}
    </>
  );
};

const IDAllocatedInput = ({ selected }) => (
  <Accordion>
    {selected?.input_alloted?.length > 0
      ? selected?.input_alloted.map((inputRound, __inputRoundIndex) => (
          <AccordionItem key={inputRound?.id_stage.toString()} value={inputRound?.id_stage.toString()}>
            <AccordionTrigger>{`Input Distribution Stage ${inputRound.id_stage}`}</AccordionTrigger>
            {inputRound.collected_resource.map((resource, resourceIndex) => (
              <AccordionContent
                key={hyphenate(["input-round", inputRound?.id_stage, "item", resourceIndex.toString()])}
              >
                <Box mt="1rem">
                  <DataGridRow
                    fields={[
                      {
                        label: resource.name,
                        value: resource.inputQuantity,
                      },
                    ]}
                  />
                </Box>
                <Divider />
              </AccordionContent>
            ))}
          </AccordionItem>
        ))
      : "No Input allocation Data"}
  </Accordion>
);
