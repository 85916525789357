import Select2 from "./select2";
import React, { useEffect, useState } from "react";
import { getStatesOptions } from "./options/stateSelectOptions";

const StateSelect2 = ({ selectedCountry, lgaFieldName, setFieldValue, ...props }) => {
  const [statesOptions, setStatesOptions] = useState([]);

  useEffect(() => {
    if (selectedCountry) {
      setStatesOptions(getStatesOptions({ countryName: selectedCountry }));
    }
  }, [selectedCountry]);

  return (
    <Select2
      extraActionOnChange={() => {
        setFieldValue(lgaFieldName, "");
      }}
      label="State"
      placeholder="Select State"
      options={statesOptions}
      isDisabled={!selectedCountry}
      {...props}
    />
  );
};

export default StateSelect2;
