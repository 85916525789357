import { default as lodashFlattenDeep } from "lodash/flattenDeep";
import { default as lodashGet } from "lodash/get";
import mapValues from "lodash/mapValues";
import mapKeys from "lodash/mapKeys";
import snakeCase from "lodash/snakeCase";
import isNull from "lodash/isNull";
import { default as lodashIsEmpty } from "lodash/isEmpty";

export function flattenDeep(...args) {
  const result = lodashFlattenDeep(...args);
  return result;
}

// Convert a string to an object
export function queryStringToObject(queryString) {
  const params = new URLSearchParams(queryString);
  const obj = {};

  params.forEach((value, key) => {
    obj[key] = JSON.parse(JSON.stringify(decodeURIComponent(value)));
  });

  return obj;
}

// Convert an object to a string
export function objectToStringifiedQueryString(obj) {
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(obj[key]))}`)
    .join("&");
}
/* a check for empty prototype would be more typical, but that
           doesn't play well with objects created in different vm contexts */
export function isPlainObject(x) {
  return (
    x !== null &&
    typeof x === "object" &&
    (!x.constructor || x.constructor.name === "Object") &&
    (x.toString ? x.toString() : Object.prototype.toString.call(x)) === "[object Object]" &&
    /* check for reasonable markers that the object isn't an element for react & preact/compat */
    !("props" in x && (x.$$typeof || x.constructor === undefined))
  );
}

export function isObject(arg) {
  return isPlainObject(arg);
}
export function isEmptyObject(arg) {
  return lodashIsEmpty(arg);
}

/**
 * This function checks if the argument passed is not an empty javascript object
 * return true if the argument `obj` is an object and `obj` has enumerable values
 *
 * returns false if `obj` is not and object or if  `obj` is an empty object
 *
 * @param  {Object} obj
 * @returns {boolean}
 */
export function isNotEmptyObject(obj) {
  if (isPlainObject(obj) && !isEmptyObject(obj)) {
    return true;
  }

  if (!isPlainObject(obj) || isEmptyObject(obj)) {
    return false;
  }
}

export function formatObjectNullFields(obj, replace = "N/A") {
  return mapValues(obj, function (value, __key) {
    if (isNull(value) || value === "null") {
      return replace;
    }
    return value;
  });
}

export function appGet(object, path, defaultValue = null) {
  return lodashGet(object, path, defaultValue);
}

export function flattenObj(obj) {
  const flattenedKeys = [];

  const newObj = mapValues(obj, function (value, key) {
    if (isObject(value) && !isEmptyObject(value)) {
      flattenedKeys.push(key);
      return mapKeys(value, function (__nestedValue, nestedKey) {
        return snakeCase(`${key} ${nestedKey}`);
      });
    }
    if (Array.isArray(value)) {
      return value.join(",");
    }
    return value;
  });

  flattenedKeys.forEach(flattenedKey => {
    let flattenedItem = newObj[`${flattenedKey}`];
    Object.assign(newObj, flattenedItem);
    delete newObj[`${flattenedKey}`];
  });

  // second pass incase of nested object and arrays
  // TODO: implement a recursive strategy for this
  const flattenedObj = mapValues(newObj, function (value, key) {
    if (isObject(value) && !isEmptyObject(value)) {
      flattenedKeys.push(key);
      return mapKeys(value, function (__nestedValue, nestedKey) {
        return snakeCase(`${key} ${nestedKey}`);
      });
    }
    if (Array.isArray(value)) {
      // test if array might be array of strings
      if (!isObject(value[0]) && !Array.isArray(value[0])) {
        // return simple arrays as strings
        return value.join(",");
      }
      return JSON.stringify(value);
    }
    return value;
  });

  return flattenedObj;
}

export const deleteObjProps = (obj, props = []) => {
  if (props.length > 0) {
    props.forEach(prop => {
      if (obj[`${prop}`]) delete obj[`${prop}`];
    });
  }
  return obj;
};
