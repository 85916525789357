import { getApiUrl } from "utils";
import { axiosService } from "./fetchService";
import { apiConstants } from "constants/apiConstants";

export default class Marketplace_API {
  static fetchMarketplaceOverview = async params => {
    const url = getApiUrl({
      endpointName: apiConstants.endpoints.marketplace,
      path: `trade/getTradeOverview`,
      params: { dateRange: params?.dateRange },
    });
    const response = await axiosService({ url, method: "GET" });
    return { data: response.data };
  };

  /**
   * 
   * @param {Object} params 
   * @returns 

  */

  // static fetchOverviewTrades = async params => {
  //   const url = getApiUrl({
  //     endpointName: apiConstants.endpoints.marketplace,
  //     path: `trade/getAll`,
  //     params: { dateRange: params?.dateRange },
  //   });
  //   const response = await axiosService({ url, method: "GET" });
  //   return { data: response.data.data };
  // };

  static fetchAllLogistics = async () => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "logistics/getAll" });
    const response = await axiosService({ url, method: "GET" });
    return response.data.data;
  };

  static fetchAllTradeReconciliations = async __params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/logistics" });

    const { data } = await axiosService({ url, method: "GET" });
    return data;
  };

  static fetchAllTrades = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/getAll" });
    const { data } = await axiosService({ url, method: "GET", params });
    return data;
  };

  static fetchSingleTrade = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: `trade/getOne` });
    const response = await axiosService({ url, method: "GET", params });
    return response?.data?.data[0] || null;
  };
  static fetchSingleTradeByTradeId = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: `trade/getByTradeId` });
    const response = await axiosService({ url, method: "GET", params });
    return response?.data?.data[0] || null;
  };

  static createLogistics = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/logistics/create" });

    return axiosService({ method: "POST", url, data: values });
  };

  static createTrade = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/create" });
    return axiosService({ method: "POST", url, data: values });
  };

  static updateLogistics = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/logistics" });
    const { data } = await axiosService({ method: "POST", url, data: values });
    return data;
  };

  static updateTrade = async values => {
    if (!values?.id) throw new Error("Trade id is required");
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/update" });
    const { data } = await axiosService({ method: "PUT", url, data: values?.data || {}, params: { id: values.id } });
    return data;
  };
  static toggleStatus = async values => {
    if (!values?.params?.tradeId) throw new Error("Trade id is required");
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/toggleTradeStatus" });
    const { data } = await axiosService({ method: "PUT", url, data: values?.data || {}, params: values?.params });
    return data;
  };

  static updateTradeReconciliation = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/logistics" });
    const { data } = await axiosService({ method: "POST", url, data: values });
    return data;
  };

  static fetchAllInvoice = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/invoice/getAll" });
    return await axiosService({ url, method: "GET", params });
  };

  static fetchOneInvoice = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/invoice/getOne" });
    return await axiosService({ url, method: "GET", params });
  };

  static fetchSingleInvoiceById = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/invoice/getByTradeId" });
    const response = await axiosService({ url, method: "GET", params });
    return response.data;
  };
  static fetchFarmerPayment = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.farmerPayment, path: "" });
    const response = await axiosService({ url, method: "GET", params });
    return response.data.farmerPayment;
  };
  static generateInvoice = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "invoice/create " });
    return axiosService({ method: "POST", url, data: values });
  };

  static fetchTradesReconOverview = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/getActualTradeOverview" });
    const { data } = await axiosService({ url, method: "GET", params });
    console.log("overview-data", data);
    return data;
  };
  static fetchTradesReconById = async params => {
    console.log({ params });
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/getActualTradeById" });
    const { data } = await axiosService({ url, method: "GET", params });
    console.log("id data", data);
    return data.data;
  };

  static fetchTradesReconByTradeId = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/getActualTradeByTradeId" });
    const { data } = await axiosService({ url, method: "GET", params });
    console.log("trade-id data", data);
    return data?.data?.[0] ? data.data[0] : null;
  };

  static fetchTradesRecon = async params => {
    console.log({ params });
    const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "/trade/getAllActualTrades" });
    const { data } = await axiosService({ url, method: "GET", params });
    console.log("data", data);
    return data;
  };

  static fetchFinanceReportingOverview = async params => {
    console.log({ params });
    const url = getApiUrl({
      endpointName: apiConstants.endpoints.marketplace,
      path: "/trade/getAllFinancialReporting",
    });
    const { data } = await axiosService({ url, method: "GET", params });
    console.log("data", data);
    return data;
  };
}
