// Ducks pattern
// actions
const ADD_BUTTON_CLICKED = "ADD_BUTTON_CLICKED";
const MODAL_STATE = "MODAL_STATE";
const RESET_MODAL_STATE = "RESET_MODAL_STATE";
const FROM_LOCATION = "FROM_LOCATION";
const SEARCH_BAR_LOADING = "SEARCH_BAR_LOADING";

// action creators
export const toggleSearchLoading = predicate => dispatch => {
  dispatch({
    type: SEARCH_BAR_LOADING,
    payload: predicate,
  });
};

export const handle_modal_state = () => dispatch => {
  dispatch({
    type: MODAL_STATE,
  });
};

export const add_button_toggle = clickState => dispatch => {
  dispatch({
    type: ADD_BUTTON_CLICKED,
    payload: clickState,
  });
  dispatch(handle_modal_state());
};

export const reset_modal_state = () => dispatch => {
  dispatch({
    type: RESET_MODAL_STATE,
  });
};

export const from_location = location => dispatch => {
  dispatch({
    type: FROM_LOCATION,
    payload: location,
  });
};

//reducer

const initialState = {
  clickState: false,
  rootModalClicked: false,
  rootModalIsOpen: false,
  modalOpen: false,
  location: "",
  searchLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BUTTON_CLICKED:
      return { ...state, clickState: action.payload };
    case MODAL_STATE:
      return { ...state, modalOpen: !state.modalOpen };
    case FROM_LOCATION:
      return { ...state, location: action.payload };
    case RESET_MODAL_STATE:
      return { ...state, clickState: false, modalOpen: false };
    case SEARCH_BAR_LOADING:
      return { ...state, searchLoading: action.payload };
    default:
      return state;
  }
};

// selector functions

export const getClickState = state => state.legacyCommon.clickState;
export const getModalOpen = state => state.legacyCommon.modalOpen;
