import dayjs from "dayjs";
import { useState } from "react";
import { toast } from "react-toastify";

const useDateRangeDownload = (domainBaseUrl, url, params, setLoader) => {
  const [data, setData] = useState([]);

  const handleDataDownload = async (start_date, end_date) => {
    if (!start_date || !end_date) {
      return;
    }
    setLoader(true);
    let startDate = dayjs(start_date).format("YYYY-MM-DD").toString();
    let endDate = dayjs(end_date).format("YYYY-MM-DD").toString();

    try {
      let res = await domainBaseUrl.get(url, {
        params: {
          startDate,
          endDate,
          ...params,
        },
      });
      setData(res.data);
      setLoader(false);
      toast.success("Success! Proceed to Download the records.");
    } catch (error) {
      toast.error("An error occured!");
      setLoader(false);
    }
  };

  return [data, handleDataDownload];
};

export { useDateRangeDownload };
