import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import ManageModule_API from "services/ManageModuleService";

export function useGetManageModule() {
  const { data, error, isLoading } = useQuery({
    queryKey: [rqKeys.modules.fetchAllManageModules],
    queryFn: async () => {
      const result = await ManageModule_API.fetchAllManageModules();
      return result;
    },
  });

  return { data, error, isLoading };
}
