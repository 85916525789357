import * as yup from "yup";
export const createWarehouseValidationSchema = yup.object().shape({
  store: yup.object().shape({
    name: yup.string().required("Store Name is required"),
    address: yup.string(),
    state: yup.string().required("Store State is required"),
    ta_number: yup.string().required("Store TA is required"),
    lga: yup.string().required("Store LGA is required"),
  }),
  user: yup.array(),
});
