import * as yup from "yup";

export const createSMSCampaignValidationSchema = yup.object().shape({
  campaign_title: yup.string().min(4, "enter at least four characters").required("campaign title is required"),
  sender_id: yup.string(),
  content: yup.string(),
  size: yup.string(),
  recipient: yup.array().required("recipient list cannot be empty"),
  status: yup.string().oneOf(["processing", "failed", "successful"]),
});
