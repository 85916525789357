import { Checkbox } from "components/inputs";
import { VisibilityWrapper } from "components/layout";
// import { countriesByCountryCode } from "components/select/options/countrySelectOptions";
import { createContact } from "features/v1/bulkSms";
import { Badge, Box, Button, CsvUpload, Flex, Input, Text, badgeConstants } from "organic";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { PhoneValidator } from "services/PhoneNumberService";

const ImportContacts = ({ onImport, onError, countryCode }) => {
  const [showContactBookForm, sertShowContactBookForm] = useState(false);
  const [title, setTitle] = useState("");
  const [numbers, setNumbers] = useState();

  const dispatch = useDispatch();

  const handleSaveRecipients = () => {
    dispatch(createContact({ address_book_title: title, content: numbers, country_code: countryCode }));
  };
  return (
    <Flex gap="12px" flexDirection="column" width="100%" height="100%" mt="20px">
      <Flex flexDirection="column" gap="8px">
        <Text font="smRegular" color="gray500">
          Your file only requires mobile numbers, you can upload your files below. International dial code must be
          precise for each recipient number and must be in line with selected country.
        </Text>
        <Text font="smRegular" color="gray500">
          <Badge colorScheme={badgeConstants.colorSchemes.ORANGE}> Important:</Badge> Kindly ensure the format the phone
          column as a number
        </Text>
        <Flex width="100%">
          <CsvUpload
            cols={["phone"]}
            appendOrgId={false}
            onChange={({ data, errors }) => {
              // let data = [];
              // if (Array.isArray(val) && val?.length) {
              //   val.forEach(number => data.push(number.phone));
              // }
              // console.log("data from inside import contacts onchange", { data });
              setNumbers(data);
              onImport?.(data);
              onError?.(errors);
            }}
            sample={[{ phone: "2349136916077" }, { phone: "2349134516547" }]}
            validationSchema={{
              phone(data) {
                // console.log("data from validation schema", { data, countryCode });
                // const phoneNumber = new PhoneValidator(data.phone, countryCode);
                // if (!phoneNumber.isValid()) {
                //   return `Not a Valid ${countriesByCountryCode[countryCode]} phone number`;
                // }

                if (data && typeof data === "string" && data?.length > 0) {
                  return "Column phone cannot be empty";
                }

                if (data?.phone && data.phone.indexOf("+") === 0) {
                  return "Do not add the leading '+' in the phone number";
                }

                // if (phoneNumber.validate()) {
                //   const msg = phoneNumber.validate();
                //   console.log({ msg });
                //   return msg;
                // }

                return;
              },
            }}
            // preProcesssorFn={datum => {
            //   if (datum.phone[0] === "0") {
            //     return { phone: datum.phone.slice(1) };
            //   }
            //   if (datum.phone.indexOf("+") === 0) {
            //     return datum;
            //   }
            //   return { phone: `+${datum.phone}` };
            // }}
            postProcessorFn={datum => {
              return datum.phone;
            }}
          />
        </Flex>
      </Flex>
      <Flex position="relative" mt="12px">
        <Checkbox label="Save Recipients to Contact Book" onCheckedChange={sertShowContactBookForm} />
      </Flex>
      <VisibilityWrapper isVisible={showContactBookForm}>
        <Box width="100%" mt="1rem">
          <Flex flexDirection="column" gap="1.5rem">
            <Box width="100%">
              <Input
                value={title}
                onChange={e => setTitle(e.target.value)}
                label="Contact Group Name"
                placeholder="Enter a name for this group of contacts"
              />
            </Box>
            <Box>
              <Button type="button" leftIcon="save" onClick={handleSaveRecipients}>
                Save Recipeitns
              </Button>
            </Box>
          </Flex>
        </Box>
      </VisibilityWrapper>
    </Flex>
  );
};

export default ImportContacts;
