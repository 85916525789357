/* eslint-disable no-unused-vars */
// import { EmptyState } from "components/appState";
import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";

import { addScope, getAllRoles, removeScope, updateRole } from "features/v1/role";
import { useDisclosure, useTable } from "hooks";
import { Button } from "organic";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { resourceNames } from "services/v1/rolesAndPermissionsService";
import CreateRoleForm from "views/forms/createRoleForm";

const Permission = ({ selectedRole = [] }) => {
  const dispatch = useDispatch();
  //   const [selectedRowId, setSelectedRowId] = useState(null);
  const ignoredtoggleScope = (canDo, config = {}) => {
    if (canDo === true) {
      dispatch(addScope({ scope: [`${config.resource}.${config.scope}`] }, { params: { slug: config.slug } }));
      return;
    }
    dispatch(removeScope({ scope: [`${config.resource}.${config.scope}`] }, { params: { slug: config.slug } }));
  };

  const [existingScope, setExistingScope] = useState([]);

  //const [__selectedScopes, setSelectedScopes] = useState([]);

  const handleScopeChange = (e, { permission }) => {
    let checked = e.target.checked;
    console.log("checked", checked);

    if (checked) {
      setExistingScope(existingScopes => {
        const result = [...existingScopes, permission];
        const newSet = new Set(result);
        console.log("result:", newSet);
        return Array.from(newSet);
      });
    } else {
      setExistingScope(existingScopes => {
        const newSet = new Set(existingScope);
        const deleted = newSet.delete(permission);
        console.log("isDeleted", deleted);
        return Array.from(newSet);
      });
    }
  };
  console.log("existing", existingScope);

  const scopeData = useMemo(() => {
    if (selectedRole) {
      return Object.keys(resourceNames).map(resource => {
        return {
          name: resourceNames[resource],
          readScope: `${resource}.read`,
          writeScope: `${resource}.write`,
          updateScope: `${resource}.update`,
        };
      });
    } else {
      return [];
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectedRole) {
      setExistingScope(selectedRole.scope);
    }
    console.log("role", selectedRole);
  }, [selectedRole]);

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const handleSubmit = () => {
    const updateScope = existingScope => {
      dispatch(updateRole({ ...selectedRole, scope: existingScope }, { params: { id: selectedRole._id } }));
    };

    updateScope(existingScope);
  };

  const { tableProps } = useTable({
    data: scopeData,
    columns: [
      {
        Header: "Resources Type",
        accessor: "name",
      },
      {
        Header: "Read Access",
        accessor: "readScope",
        Cell: ({ value }) => {
          console.log("existing2", existingScope);
          return (
            <input
              type="checkbox"
              onChange={e => {
                handleScopeChange(e, { permission: value });

                console.log("value", value);
              }}
              checked={existingScope.includes(value) || existingScope.includes("all.read")}
            />
          );
        },
      },
      {
        Header: "Write Access",
        accessor: "writeScope",
        Cell: ({ value }) => {
          return (
            <input
              type="checkbox"
              onChange={e => {
                handleScopeChange(e, { permission: value });

                console.log("value", value);
              }}
              checked={existingScope.includes(value) || existingScope.includes("all.write")}
            />
          );
        },
      },
      {
        Header: "Update Access",
        accessor: "updateScope",
        Cell: ({ value }) => {
          return (
            <input
              type="checkbox"
              onChange={e => {
                handleScopeChange(e, { permission: value });

                console.log("value", value);
              }}
              checked={existingScope.includes(value) || existingScope.includes("all.write")}
            />
          );
        },
      },
    ],
    title: "Role manager & permission",
    noViewAction: true,
    noSearch: true,
    noLocalFilter: true,
    showCheckboxes: false,
    noActions: true,
    useAlternatePagination: true,
  });
  const createRoleDisclosure = useDisclosure();

  return (
    <>
      <Table
        // data={scopeData}
        {...tableProps}
        createAction={{ label: "Add New Role" }}
        onCreateAction={() => {
          createRoleDisclosure.onOpen();
        }}
      />
      <Button display="block" onClick={handleSubmit}>
        Submit Scope
      </Button>

      <Modal
        open={createRoleDisclosure.isOpen}
        onOpenChange={open => {
          //  example of on modal close
          if (open === false) {
            createRoleDisclosure.onClose();
          }
        }}
      >
        <ModalContent title="Add New Role">
          <CreateRoleForm disclosure={createRoleDisclosure} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default Permission;
