import { useState } from "react";

/**
 ***custom hook for `<FeedbackModal />`**
 *
 * Usage:
 *
 * ```
 * import useFeedbackModal from "hooks/useFeedbackModal"
 *
 *
 * const someFeedbackModal = useFeedbackModal()
 *
 *
 * const handleSomeOperation = () => {
 *      someFeedbackModal.toggleFeedback()
 * }
 *
 * return (
 *      <FeedbackModal modal={someModal} >
 *        <SomeModalContent />
 *     </FeedbackModal>
 * )
 *```
 *
 */

function useFeedbackModal() {
  const [feedbackModal, setFeedbackModal] = useState(false);

  const toggleFeedbackModal = () => setFeedbackModal(oldVal => !oldVal);

  return { feedbackModal, toggleFeedbackModal };
}

export { useFeedbackModal };
