import _ from "lodash";
// useful function for reading and manipulating TA NUmbers

/**
 * Extract groupId from a farmer TA NUmber
 * @param  {String} taNumber
 */
export const getGroupIdFromTA = taNumber => {
  let groupID = "";
  try {
    if (_.isString(taNumber)) {
      groupID = taNumber.slice(0, 16);
    }
    return groupID;
  } catch (error) {
    console.log({ error });
  }
};

/**
 * Extract TA Number from a fieldId / field TA NUmber
 * @param  {String} fieldId
 */
export const DEPRECATED_getTAFromFieldId = fieldId => {
  let taNumber = "";
  try {
    if (_.isString(fieldId)) {
      taNumber = fieldId.slice(0, 19);
    }
    return taNumber;
  } catch (error) {
    console.log({ error });
  }
};

/**
 * Extract groupID from a fieldId / field TA NUmber
 * @param  {String} fieldId
 */
export const DEPRECATED_getGroupIDFromFieldId = fieldId => {
  let groupID = "";
  try {
    if (_.isString(fieldId)) {
      groupID = fieldId.slice(0, 16);
    }
    return groupID;
  } catch (error) {
    console.log({ error });
  }
};

/**
 * Retrieve the lead farmer from a list of Farmer Objects
 * using a role field
 * @param {Array} farmers - an array of farmer objects that include a role field
 */
export const DEPRECATED_getLeadFarmerFromList = farmers => {
  let lead = {};
  if (farmers?.length > 0) {
    farmers.forEach(farmer => {
      if (farmer.role === "lead_farmer") lead = farmer;
    });
  }
  return lead;
};

/**
 * Return true if farmer is a lead farmer
 * @param {Object} farmer - Object containing farmer data including Farmer ID (TA) or role
 */
export const DEPRECATED_isLeadFarmer = farmer => {
  return farmer.role === "lead_farmer" || farmer.ta_number.slice(-2) === "01";
};
