import { Panel } from "components/layout";
import { VisibilityWrapper } from "components/layout/visibilityWrapper";
import { Form, Formik } from "formik";
import { Badge, Box, Button, Flex, Icon, Image, Text, buttonConstants } from "organic";
import React, { useRef, useState } from "react";
import {
  SURVEY_QUESTIONNAIRE,
  isBvnSurvey,
  questionInputTypes,
  questionTypeIcons,
  v2HandleInitializeQuestionnaireEdit,
} from "utils/questionnaire-utils/questionnaire-utils";
import { selectLabelFromValue } from "utils/v1/selectUtils";

import { Switch } from "components/switch";
import { v2QuestionnaireValidationSchema } from "validators/questionnaireValidationSchemas";
import { Loader } from "feather-icons-react/build/IconComponents";
import MobilePreview from "./mobilePreview";
import QuestionForm from "./questionForm";
import QuestionList from "./questionList";
import { EmptyState } from "components/appState";
import { appGet, isEmptyObject } from "utils";
import { useDispatch } from "react-redux";
import { updateQuestionnaireQuestions } from "features/v1/questionnaire";
import { Divider } from "components/layout";
import QuestionnaireDetailsForm from "./questionnaireDetailsForm";
import { toast } from "react-toastify";
import { removeObjProps } from "utils/v1/sanitize";

// import { getCustomerID } from "services/customerService";

// const CUSTOMER_ID = getCustomerID();

const QUESTIONS_TAB_ID = "questions";
const QUESTIONNAIRE_DETAILS_TAB_ID = "questionnaireDetails";
// const EXTRA_ONBOARDING_QUESTIONS_LIMIT = CUSTOMER_ID === "mercycorps" ? 19 : 15;

const EditQuestionnaireDetails = ({ onBack, selected }) => {
  const [currentTab, setCurrentTab] = useState(QUESTIONS_TAB_ID);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionKey, setCurrentQuestionKey] = useState("exisitingQuestions");
  const [__loading, __setLoading] = useState(false);
  const [questionnaireState, __setQuestionnaireState] = useState(selected);

  const defaultBlockListRef = useRef(null);
  const existingBlockListRef = useRef(null);
  const newBlockListRef = useRef(null);

  const dispatch = useDispatch();

  const initValues = React.useMemo(() => v2HandleInitializeQuestionnaireEdit(questionnaireState), []);

  const handleInnerTabChange = (e, tabId) => {
    if (currentTab === tabId) {
      return;
    }
    setCurrentTab(tabId);
  };

  const getSelectedBlockListQuestion = ({ values }) => {
    return `${appGet(values, [currentQuestionKey, currentQuestionIndex, "question_title"], "Untitled")}`;
  };

  const resolveCurrentQuestionTypeIcon = ({ values }) => {
    return questionTypeIcons[appGet(values, [currentQuestionKey, currentQuestionIndex, "question_type"])];
  };

  const handleSubmitQuestionnaire = (values, formOptions) => {
    // console.log("onsubmit", { values, touched: formOptions.touched, status: formOptions.status });

    // update the rest of the questionnaire (including exisiting and default questions)
    const allExistingQuestions = [...values.defaultQuestions, ...values.existingQuestions].map(
      (question, questionIndex) => {
        // FIXME: dirty dirty hack to fix onChnage bug
        removeObjProps(question, ["_id", "1000", "", "default", "object Object"]);
        return { ...question, key: questionIndex };
      }
    );

    // create and submit new questions payload if any
    const newQuestionsIndexOffset = values.existingQuestions.length + values.defaultQuestions.length;

    const _transformedNewQuestions = appGet(values, "newQuestions", []).map((newQuestion, newQuestionIndex) => {
      return { ...newQuestion, key: newQuestionIndex + newQuestionsIndexOffset };
    });

    // delete selected.psychometrics_status;
    // delete selected.meta;
    const completeQuestionnaire = {
      ...values.questionnaireDetails,
      questions: [...allExistingQuestions, ..._transformedNewQuestions],
    };
    dispatch(
      updateQuestionnaireQuestions(completeQuestionnaire, {
        formOptions,
        id: selected._id,
        onSuccess: () => {
          onBack();
        },
        onError: () => {
          toast.warn("Questionnaire failed to update, kindly try again, if the issue persists please contact admin");
        },
      })
    );
  };

  React.useEffect(() => {
    console.log({ selected, questionnaireState });
  }, [questionnaireState]);

  return (
    <Panel headerElement={<EditQuestionnaireHeader onClickAction={onBack} name={appGet(selected, "name")} />}>
      {selected ? (
        <Formik
          initialValues={initValues}
          validationSchema={v2QuestionnaireValidationSchema}
          onSubmit={handleSubmitQuestionnaire}
        >
          {({ values, setFieldValue, errors, dirty, isSubmitting, submitForm }) => {
            let isSurvey = values.model === SURVEY_QUESTIONNAIRE;
            console.log({ errors, values });
            return (
              <Form>
                <div className="inner-tabs">
                  <div className="inner-tabs__header">
                    <Button
                      variant="unstyled"
                      className="inner-tabs__trigger"
                      data-status={currentTab === QUESTIONS_TAB_ID ? "active" : "inactive"}
                      onClick={e => handleInnerTabChange(e, QUESTIONS_TAB_ID)}
                    >
                      <Text font="smMedium">Questions</Text>
                      <Badge ml="0.5rem" colorScheme="gray">
                        {appGet(values, "defaultQuestions.length", 0) +
                          appGet(values, "existingQuestions.length", 0) +
                          appGet(values, "newQuestions.length", 0)}
                        {isSubmitting ? "Updating...." : ""}
                      </Badge>
                    </Button>
                    <Button
                      className="inner-tabs__trigger"
                      variant="unstyled"
                      data-status={currentTab === QUESTIONNAIRE_DETAILS_TAB_ID ? "active" : "inactive"}
                      onClick={e => handleInnerTabChange(e, QUESTIONNAIRE_DETAILS_TAB_ID)}
                    >
                      <Text font="smMedium">Questionnaire Details</Text>
                    </Button>
                  </div>
                </div>
                {/* tabs content */}
                <div className="inner-tabs__body">
                  {/* questions tab */}
                  <VisibilityWrapper isVisible={currentTab === QUESTIONS_TAB_ID}>
                    {/* blocks */}
                    <Flex
                      flexDirection="column"
                      borderRight="1px solid #EAECF0"
                      borderTop="1px solid #EAECF0"
                      paddingLeft="24px"
                      paddingRight="1rem"
                      py="0.5rem"
                      height="564px"
                      maxWidth="277px"
                      overflowY="auto"
                      flexGrow="1"
                      ref={newBlockListRef}
                    >
                      {/* defaultQuestions if any */}
                      {values?.defaultQuestions?.length ? (
                        <QuestionList
                          readOnly
                          setCurrentQuestionIndex={setCurrentQuestionIndex}
                          setCurrentQuestionKey={setCurrentQuestionKey}
                          currentQuestionIndex={currentQuestionIndex}
                          currentQuestionKey={currentQuestionKey}
                          questionKey="defaultQuestions"
                          questionnaire={values}
                          ref={defaultBlockListRef}
                          handleScroll={() => {
                            if (defaultBlockListRef.current)
                              defaultBlockListRef.current.scrollTop = defaultBlockListRef.current.scrollHeight;
                          }}
                        />
                      ) : null}
                      {/* existingQuestions if any */}
                      <QuestionList
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        setCurrentQuestionKey={setCurrentQuestionKey}
                        currentQuestionIndex={currentQuestionIndex}
                        currentQuestionKey={currentQuestionKey}
                        questionKey="existingQuestions"
                        questionnaire={values}
                        readOnly={isSurvey}
                        ref={existingBlockListRef}
                        handleScroll={() => {
                          if (existingBlockListRef.current)
                            existingBlockListRef.current.scrollTop = existingBlockListRef.current.scrollHeight;
                        }}
                        editOnly
                      />
                      <QuestionList
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        setCurrentQuestionKey={setCurrentQuestionKey}
                        currentQuestionIndex={currentQuestionIndex}
                        currentQuestionKey={currentQuestionKey}
                        questionKey="newQuestions"
                        questionnaire={values}
                        badge="New"
                        readOnly={isSurvey}
                        handleScroll={() => {
                          if (newBlockListRef.current)
                            newBlockListRef.current.scrollTop = newBlockListRef.current.scrollHeight;
                        }}
                      />
                    </Flex>

                    {/* form */}
                    {isBvnSurvey(selected) ? (
                      <Flex flexDirection="column" maxWidth="460px" flexGrow="1">
                        <EmptyState
                          title="BVN Survey"
                          subtitle="Should NOT be edited"
                          message="this questionnaire is for collecting farmer BVN via Survey and should not be edited"
                        />
                      </Flex>
                    ) : (
                      <Flex flexDirection="column" maxWidth="651px" flexGrow="2">
                        {appGet(values, [currentQuestionKey, currentQuestionIndex], null) ? (
                          <>
                            <Flex
                              alignItems="center"
                              px="24px"
                              py="18px"
                              borderBottom="1px solid #EAECF0"
                              borderTop="1px solid #EAECF0"
                              bg="#FFF"
                              justifyContent="space-between"
                            >
                              <Flex>
                                <Image
                                  src={resolveCurrentQuestionTypeIcon({ values })}
                                  borderRadius="4px"
                                  backgroundColor="gray100"
                                  size={24}
                                />
                                <Text font="lgMedium" ml="1rem">
                                  {selectLabelFromValue(
                                    questionInputTypes,
                                    appGet(values, [currentQuestionKey, currentQuestionIndex, "question_type"])
                                  )}
                                </Text>
                              </Flex>
                              <Flex>
                                <Switch
                                  checked={appGet(
                                    values,
                                    [currentQuestionKey, currentQuestionIndex, "isCompulsory"],
                                    false
                                  )}
                                  onCheckedChange={val =>
                                    setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.isCompulsory`, val)
                                  }
                                />
                                <Text ml="12px">Required</Text>
                              </Flex>
                            </Flex>
                            <QuestionForm
                              values={values}
                              selected={selected}
                              currentQuestionIndex={currentQuestionIndex}
                              currentQuestionKey={currentQuestionKey}
                              setFieldValue={setFieldValue}
                              isReadOnly={currentQuestionKey === "defaultQuestions"}
                            />
                          </>
                        ) : (
                          <EmptyState
                            title="No question selected"
                            subtitle="select or create a new question to edit it here"
                          />
                        )}
                      </Flex>
                    )}

                    {/* Preview  */}
                    <Flex
                      flexDirection="column"
                      borderLeft="1px solid #EAECF0"
                      bg="white"
                      overflow="hidden"
                      flexGrow="1"
                      maxHeight="564px"
                      maxWidth="327px"
                    >
                      <Flex
                        flexDirection="row"
                        maxHeight="67px"
                        alignItems="center"
                        px="24px"
                        py="18px"
                        borderBottom="1px solid #EAECF0"
                        borderTop="1px solid #EAECF0"
                        bg="#FFF"
                        justifyContent="space-between"
                      >
                        <Text font="lgMedium">Preview</Text>
                      </Flex>
                      <Box bg="gray100" overflowY="auto" overflowX="hidden">
                        <MobilePreview>
                          <p style={{ color: "#101828", fontSize: "16.037px", fontWeight: 500, lineHeight: "22.91px" }}>
                            {getSelectedBlockListQuestion({ values })}
                          </p>

                          <EmptyState title="Coming Soon" />
                        </MobilePreview>
                      </Box>
                    </Flex>
                  </VisibilityWrapper>
                  {/* questionnaire details tab */}
                  <VisibilityWrapper isVisible={currentTab === QUESTIONNAIRE_DETAILS_TAB_ID} width="100%">
                    <QuestionnaireDetailsForm detailsKey="questionnaireDetails" />
                  </VisibilityWrapper>
                </div>
                <Flex padding="1rem">
                  <Divider />
                </Flex>
                <Flex padding="1rem" justifyContent="flex-end">
                  <Button
                    tabIndex="-1"
                    onClick={onBack}
                    colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}
                    type="submit"
                  >
                    <Text font="smSemibold">Cancel</Text>
                  </Button>
                  <Button
                    ml="12px"
                    type="button"
                    onClick={() => {
                      if (!isEmptyObject(errors)) {
                        toast.warn(
                          "this questionnaire contains some errors, kindly check questions highlighted in red",
                          {
                            toastId: "questionnareError",
                          }
                        );
                        return;
                      }
                      if (!dirty) {
                        toast("You haven't made any chnages");
                        return;
                      }
                      submitForm();
                    }}
                    isDisabled={isSubmitting}
                  >
                    <Text font="smSemibold">Save</Text>
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Loader />
      )}
    </Panel>
  );
};

export default EditQuestionnaireDetails;

const EditQuestionnaireHeader = ({ onClickAction, name }) => {
  return (
    <Flex alignItems="center">
      <Button variant="unstyled" onClick={onClickAction}>
        <Icon icon="arrow-left" color="gray700" size={20} />
      </Button>
      <Text as="span" font="lgMedium" color="gray900">{`Edit ${name || ""}`}</Text>
    </Flex>
  );
};
