import React from "react";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Input, Text } from "organic";
import { FormInput } from "components/inputs";
import { LogisticsTypeSelect, selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import numeral from "numeral";
import { useCreateLogistics } from "hooks/app/marketplace";
import { handleFormSubmission } from "services/formSubmissionService";

const CreateLogistics = ({ modal }) => {
  const { mutateAsync } = useCreateLogistics();

  const initialValues = {
    name: "",
    unit: "",
    price_per_unit: "",
    currency: "",
    amount: "",
  };
  const onSubmit = async (values, formOptions) => {
    await handleFormSubmission({
      submitFn: mutateAsync,
      values,
      config: { modal, formOptions },
      successMsg: "Logistics Type Created Successfully",
      errorMsg: "Error Creating Logistics Type",
    });
  };

  const calcLogisticsAmount = ({ values, setFieldValue }) => {
    const selling_price = numeral(values?.price_per_unit || 1)
      .multiply(values.unit || 1)
      .format("0");
    setFieldValue("amount", selling_price);
  };

  return (
    <Box>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ setFieldValue, isSubmitting, errors, values }) => (
          <Form>
            <Box>
              <Text>{Object.values(errors)}</Text>
              <Flex flexDirection="column" width="100%" gap="1rem">
                <LogisticsTypeSelect
                  label="Logistics Type"
                  placeholder="Select Logistics Type"
                  onChange={val => setFieldValue("name", val)}
                  required
                  name="name"
                />
                <Input
                  name="unit"
                  label="Unit (KG, L, M, etc)"
                  type="number"
                  required
                  value={values.unit}
                  onChange={e => {
                    setFieldValue("unit", numeral(e.target.value).format("0"));
                  }}
                  onBlur={() => {
                    calcLogisticsAmount({ values, setFieldValue });
                  }}
                />

                <Input
                  name="price_per_unit"
                  label="Price"
                  type="number"
                  value={values.price_per_unit}
                  onChange={e => {
                    setFieldValue("price_per_unit", numeral(e.target.value).format("0"));
                  }}
                  required
                  onBlur={() => {
                    calcLogisticsAmount({ values, setFieldValue });
                  }}
                />

                <FormInput
                  name="amount"
                  label="Amount"
                  isDisabled
                  disabled
                  type="number"
                  required
                  rightAddon={
                    <CurrencySelect
                      onChange={currencyValue => {
                        setFieldValue("currency", currencyValue);
                      }}
                      colorScheme={selectConstants.colorSchemes.UNSTYLED}
                      required
                    />
                  }
                />
              </Flex>

              <Flex gap="20px" mt="2rem">
                <Button
                  size="lg"
                  display="block"
                  type="button"
                  colorScheme="secondaryGray"
                  disabled={isSubmitting}
                  onClick={() => {
                    modal.hideModal();
                  }}
                >
                  Cancel
                </Button>
                <Button size="lg" display="block" type="submit" disabled={isSubmitting}>
                  Create Logistics Type
                </Button>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateLogistics;
