import { LeafletMap, leafletMapConstants } from "components/map";
import { useLeafletMaps } from "hooks/app/maps/useLeafletMaps";
import { Badge, Flex } from "organic";
import farmersApi from "api/farmers";

import React, { useEffect, useMemo, useState } from "react";
import { CountrySelect, StateSelect } from "components/select";
import { toast } from "react-toastify";
import { useDataFilter, useDidMountEffect } from "hooks";
import { useTurf } from "hooks/app";
import { convertObjectToLatLngArray } from "utils";
import { AOSClientError } from "services/shared/AOSErrorService";
// import { convertObjectToLatLngObject } from "utils";

const toastId = "mappingInspection";

const MappingInspectionPage = () => {
  const [currentCountry, setCurrentCountry] = useState();
  const [currentState, setCurrentState] = useState();
  const [validMappingData, setValidMappingData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { mapRef, ignoredmarkers, isMapLoaded, clearPolygons, setPolygons, polygons } = useLeafletMaps();

  const { findOverlappingPolygons } = useTurf();

  const { filterParams, isValidProjectId } = useDataFilter();

  // Find overlapping polygons using Turf.js and spatial index
  const overlappingPolygons = useMemo(
    () => findOverlappingPolygons(validMappingData),
    [findOverlappingPolygons, validMappingData]
  );

  //   fetch data
  useEffect(() => {
    const fetchMappingData = async (params, __config = {}) => {
      try {
        setLoading(true);
        const res = await farmersApi.get("farmMapper/getAllPolygons", { params, ignoreOrgId: true });
        const data = res.data.data;
        setAllData(data);
        setValidMappingData(() =>
          data
            .filter(datum => datum?.routeCoordinates?.length)
            .map(datum => {
              const polygonCoordinates = datum.routeCoordinates.map(point =>
                convertObjectToLatLngArray(point, { latKey: "latitude", lngKey: "longitude" })
              );
              return [...polygonCoordinates, polygonCoordinates[0]];
            })
        );
        setLoading(false);
      } catch (fetchMappingDataError) {
        console.log({ fetchMappingDataError });
        const bug = new AOSClientError(`fetchMappingDataError: ${fetchMappingDataError.message}`);
        bug.report();
        setLoading(false);
      }
    };

    if (!currentState) {
      toast.info("kindly select a country and state to continue", { toastId });
      return;
    }
    if (!isValidProjectId) {
      toast.info("kindly select a project to continue", { toastId });
      return;
    }
    if (currentState && isValidProjectId) {
      fetchMappingData({ state: currentState, seasonId: filterParams.seasonId });
    }
  }, [currentCountry, currentState, filterParams.seasonId, isValidProjectId]);

  useDidMountEffect(() => {
    clearPolygons();
    const colorCodedPolygons = validMappingData.map((datum, index) => {
      console.log({ datum });
      return overlappingPolygons.includes(index)
        ? { positions: [...datum], pathOptions: leafletMapConstants.defaults.OVERLAPED_POLYGON_STYLE }
        : { positions: datum };
    });
    if (isMapLoaded) {
      setPolygons(colorCodedPolygons);
    }
  }, [overlappingPolygons, isMapLoaded, validMappingData]);

  return (
    <Flex flexDirection="column" gap="2rem" mt="1rem">
      <Flex alignItems="center" gap="1rem" py="1rem" zIndex="99999">
        <CountrySelect onChange={setCurrentCountry} />
        <StateSelect
          label="State"
          countryName={currentCountry}
          onChange={state => setCurrentState(state.replace("State", "").trim())}
        />
      </Flex>
      <Flex flexDirection="column" position="relative">
        <LeafletMap ref={mapRef} height="70vh" polygons={polygons}></LeafletMap>
      </Flex>
      <Flex>
        <Badge>Total Records: {loading ? "Loading..." : allData?.length}</Badge>
      </Flex>
    </Flex>
  );
};

export default MappingInspectionPage;
