import React from "react";
import farmersApi from "api/farmers";
import { PageWrapper } from "components/layout";
import { useDataFilter } from "hooks";
import { PageTabsNav } from "components/pageTabs";
import { Box, Flex } from "organic";
import { ExportButton } from "components/export";
import { useSelector } from "react-redux";
import { getCurrentPageTabId } from "features/v1/dashboard";
import { PageHeader } from "components/layout";

const CLEARED_FARMERS_TAB_ID = "clearedFarmers";
const UNCLEARED_FARMERS_TAB_ID = "unclearedFarmers";

const pageTabNavItems = [
  {
    tabId: UNCLEARED_FARMERS_TAB_ID,
    title: "Uncleared Farmers",
    path: "unclearedFarmers",
  },
  {
    tabId: CLEARED_FARMERS_TAB_ID,
    title: "Cleared Farmers",
    path: "clearedFarmers",
  },
];

const ClearancePage = () => {
  let currentPageTabId = useSelector(getCurrentPageTabId);

  const { filterParams } = useDataFilter();

  const quickClearFarmersExport = async () => {
    const data = (await farmersApi.get("clearFarmer/download", { params: filterParams })).data;
    return data;
  };

  const getClearFarmersMetaKeys = async () => {
    return await farmersApi.get("clearFarmer/download/getMetaKeys");
  };
  const advanceClearFarmersExport = async ({ filterParams, columnWhitelist }) => {
    let params = { columnWhitelist, ...filterParams };
    const res = await farmersApi.get("clearFarmer/download/advance", {
      params,
    });
    return res;
  };

  return (
    <PageWrapper>
      <Flex justifyContent="space-between">
        <PageHeader title="Farm > Clearance" subtitle="your farmers summary and activities" />
        <Box display={currentPageTabId === CLEARED_FARMERS_TAB_ID ? "block" : "none"}>
          <ExportButton
            quickExportConfig={{
              apiCalls: {
                [CLEARED_FARMERS_TAB_ID]: quickClearFarmersExport,
              },
              activeTab: currentPageTabId,
            }}
            advancedExportConfig={{
              apiCalls: {
                [CLEARED_FARMERS_TAB_ID]: advanceClearFarmersExport,
              },
              getTabKeys: {
                [CLEARED_FARMERS_TAB_ID]: getClearFarmersMetaKeys,
              },
              activeTab: currentPageTabId,
            }}
          />
        </Box>
      </Flex>
      <PageTabsNav tabs={pageTabNavItems} />
    </PageWrapper>
  );
};

export default ClearancePage;
