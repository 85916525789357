import React, { useMemo } from "react";
import PropTypes from "prop-types";
// import { Col } from "reactstrap";
import { Text } from "organic";

const FormGridTitlePropTypes = {
  title: PropTypes.string,
  center: PropTypes.bool,
};

const FormGridTitle = props => {
  const { title } = props;
  return useMemo(
    () => (
      <Text font="displayXsMedium" pt={20} pb={5} color="primary">
        {title}
      </Text>
    ),
    [title]
  );
};

FormGridTitle.propTypes = FormGridTitlePropTypes;

export default FormGridTitle;
