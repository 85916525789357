import React, { useMemo } from "react";
import { Select } from ".";
import { useOrgData } from "hooks";
import { getCurrencyCodeByCountryName } from "utils";

const CurrencySelect = props => {
  const { orgCountries } = useOrgData();

  let currencyOptions = useMemo(() => {
    return orgCountries?.map?.(country => {
      const currency = getCurrencyCodeByCountryName(country);
      return {
        value: currency,
        label: `${currency}`,
      };
    });
  }, [orgCountries]);

  return (
    <div>
      <Select
        placeholder="Select Currency"
        {...props}
        name="currency"
        options={currencyOptions}
        defautlValue={currencyOptions[0] ?? undefined}
      />
    </div>
  );
};

export default CurrencySelect;
