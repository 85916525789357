import { EmptyState } from "components/appState";
import { PageTabs } from "components/pageTabs";
import React, { useMemo } from "react";
import { hyphenate } from "utils";
import Flex from "../layout/flexbox";
// import Text from "../primary/text";
import { Badge, badgeConstants } from "..";
import styled from "styled-components/macro";

const ErrorList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding: 0px;
  margin: 0px;
`;
const ErrorListItem = styled.li`
  margin: 4px 0px;
`;

const CsvPreview = ({ data, __cols, errors }) => {
  // let previewData = useMemo(() => (data?.length > 100 ? data.slice(0, 50) : data), []);
  let previewTabs = useMemo(() => {
    return [
      { title: "Errors", content: <PreviewErrors errors={errors} /> },
      { title: "Preview", content: <PreviewData /> },
    ];
  }, []);

  return (
    <>
      {data.length > 0 || errors.length > 0 ? (
        <>
          <Flex className="mt-5 ml-3 d-flex flex-direction-column">
            <PageTabs tabs={previewTabs} />
          </Flex>
        </>
      ) : (
        <EmptyState title="Select a File to Preview it here" message="nothing to preview" />
      )}
    </>
  );
};

const PreviewErrors = ({ errors }) => {
  return (
    <ErrorList>
      {errors?.length > 0 ? (
        errors?.map((singleError, errorIndex) => (
          <ErrorListItem key={hyphenate(["err", singleError, errorIndex])}>
            {errorIndex + 1}&nbsp;
            <Badge size={badgeConstants.sizes.MD} colorScheme={badgeConstants.colorSchemes.ERROR}>
              {singleError.message}
              {/* {singleError?.row ? `Row ${singleError.row}` : singleError.code}: {singleError?.message || "N/A"} */}
            </Badge>
          </ErrorListItem>
        ))
      ) : (
        <ErrorListItem>No errors</ErrorListItem>
      )}
    </ErrorList>
  );
};
const PreviewData = ({ data, cols }) => {
  return (
    <div>
      <p className="">showing the first {data.length} rows</p>
      <table>
        <thead>
          {cols.length > 0 && (
            <tr className="header-text">
              <th>S/N</th>
              {cols?.map((c, i) => (
                <th key={hyphenate(["preview", "column", i, "header"])}>{c}</th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {data?.map(
            (previewItem, previewItemIndex) =>
              (
                <tr className="m-0 ca-font-regular" key={hyphenate(["preview-row", previewItemIndex])}>
                  <td>{previewItemIndex + 1}</td>
                  {cols.map((col, colIndex) => (
                    <td
                      // className={`header-text ${
                      //   val === "" || typeof val === "undefined" ? "border border-danger" : ""
                      // }`}
                      className="header-text"
                      key={hyphenate(["preview-col", previewItemIndex, colIndex])}
                    >
                      {previewItem[`${col}`]}
                    </td>
                  ))}
                </tr>
              ) || (
                <tr key={hyphenate(["preview-row", previewItemIndex])} className="m-0 ca-font-regular">
                  <td>No data to Preview</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export { CsvPreview };
