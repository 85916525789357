import React from "react";
import { DropdownItem } from "components/dropdown";
import { hyphenate, isFunction } from "utils";
import { Flex, Icon, Text } from "organic";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover";
import { useCallbackRef } from "hooks";

const ExtraTableActions = ({ actions, row, id, onSelect, getter }) => {
  // if extra actions is a function pass the current data item to it
  // extraAction should return an array of extra actions
  const _getter = useCallbackRef(getter, []);

  const memoizedExtraActions = React.useMemo(() => {
    if (isFunction(_getter)) {
      return _getter(row);
    }
    if (Array.isArray(actions) && actions?.length) {
      return actions;
    }

    return [];
  }, [_getter, actions, row]);
  //   console.log({ actions, row, id, onSelect, getter, memoizedExtraActions });

  const _handleAction = selectedAction => {
    if (isFunction(onSelect)) {
      onSelect(selectedAction);
    }
  };

  return (
    <>
      {memoizedExtraActions?.length ? (
        memoizedExtraActions.map(action => {
          if (!action?.actionKey) return null;

          if (action?.component) {
            return (
              <Popover key={hyphenate([action.actionKey, id])}>
                <PopoverTrigger className="hover-card__table-trigger">
                  <div>
                    {action.icon && <Icon icon={action.icon || "square"} size={16} />}
                    <Text ml={`${action.icon ? "0.75rem" : "0rem"}`} as="span" font="smMedium" color="gray700">
                      {action.label}
                    </Text>
                  </div>
                </PopoverTrigger>
                <PopoverContent alignOffset={32} sideOffset={0} side="top" className="default-dropdown__content">
                  <Flex flexDirection="column" backgroundColor="white" borderRadius="md">
                    {action.component}
                  </Flex>
                </PopoverContent>
              </Popover>
            );
          }

          return (
            <DropdownItem
              onSelect={() => _handleAction({ selected: row, id, actionKey: action.actionKey })}
              key={hyphenate([action.actionKey, id])}
            >
              {action.icon && <Icon icon={action.icon || "square"} size={16} />}
              <Text ml={`${action.icon ? "0.75rem" : "0rem"}`} as="span" font="smMedium" color="gray700">
                {action.label}
              </Text>
            </DropdownItem>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

ExtraTableActions.defaultProps = {
  actions: [],
  row: {},
  id: "",
};

export { ExtraTableActions };
