import region from "api/region";
import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";
import React from "react";

// actions
const GET_ALL_REGIONS = "GET_ALL_REGIONS";
const SET_ALL_REGIONS = "SET_ALL_REGIONS";
const LOAD_GET_ALL_REGIONS = "LOAD_GET_ALL_REGIONS";
const SET_SELECTED_REGION = "SET_SELECTED_REGION";

// action creators

/** Create Region */
export const createRegion =
  (values, config = {}) =>
  async dispatch => {
    const { setSubmitting } = config.formOptions;
    const createRegionToast = "createRegionToast";
    toast(`creating Region ${values.value}....`, { toastId: createRegionToast, type: "info" });
    try {
      await region.post("/create", values);
      toast.update(createRegionToast, {
        type: "success",
        render: "Region created successfully!",
        autoClose: 1000,
        closeButton: true,
      });
      setSubmitting(false);
      if (config?.modal) {
        config.modal.hideModal();
      }
      return dispatch(getAllRegions());
    } catch (createRegionError) {
      const message = createRegionError?.response?.data?.message || "Region Create failed";
      if (message.includes("duplicate key error")) {
        // if region id already exists incremetn the id and try again
        const newRegionIDValue = `r${Number(values.value.slice(1)) + 1}`;
        config.formOptions.setValues({ ...values, value: newRegionIDValue });
        config.formOptions.submitForm();
        toast.update(createRegionToast, {
          render: (
            <p className="ca-font-regular">
              Region ID <span className="text-danger">{values.value}</span> Taken. Retrying with{" "}
              <span className="text-info">{newRegionIDValue}</span>
            </p>
          ),
          type: "info",
        });
        return;
      }
      setSubmitting(false);
      handleActionError(createRegionError, "Create Region Error", message);
    }
  };

/** Get All Region */
export const getAllRegions = () => async dispatch => {
  try {
    dispatch({ type: LOAD_GET_ALL_REGIONS, payload: true });
    const regionData = await region.get();

    if (regionData.status === 200) {
      dispatch({ type: GET_ALL_REGIONS, payload: regionData.data.data });
    }
    dispatch({ type: LOAD_GET_ALL_REGIONS, payload: false });
  } catch (getAllRegionsError) {
    dispatch({ type: LOAD_GET_ALL_REGIONS, payload: false });
    handleActionError(getAllRegionsError);
  }
};

/** Set region options from external source */
export const setAllRegion = data => dispatch => {
  dispatch({
    type: SET_ALL_REGIONS,
    payload: data,
  });
};

/** set region loading state from external source */
export const loadRegions = isLoading => dispatch => {
  dispatch({
    type: LOAD_GET_ALL_REGIONS,
    payload: isLoading,
  });
};

/** Update Region */
export const updateRegion =
  (values, config = {}) =>
  async dispatch => {
    try {
      if (!config?.id) {
        throw new Error("[AOS_WEB_ERROR]: Region ID is not being provided");
      }
      dispatch({ type: LOAD_GET_ALL_REGIONS, payload: true });
      await region.put(`update/${config.id}`, values);
      toast.success("region deleted successfully");
      dispatch({ type: LOAD_GET_ALL_REGIONS, payload: false });
      config.modal.hideModal();
      return dispatch(getAllRegions());
    } catch (updateRegionError) {
      dispatch({ type: LOAD_GET_ALL_REGIONS, payload: false });
      handleActionError(updateRegionError);
    }
  };

/** Add Zones to a Region */
export const addZonesToRegion =
  (values, __config = {}) =>
  __dispatch => {};

/** Delete a Region */
export const deleteRegion =
  (values, __config = {}) =>
  async dispatch => {
    try {
      if (!values?._id) {
        throw new Error("[AOS_WEB_ERROR]: Region ID is not being provided");
      }
      dispatch({ type: LOAD_GET_ALL_REGIONS, payload: true });
      await region.delete(`${values._id}`);
      toast.success("region deleted successfully");
      dispatch({ type: LOAD_GET_ALL_REGIONS, payload: false });
      return dispatch(getAllRegions());
    } catch (getAllRegionsError) {
      dispatch({ type: LOAD_GET_ALL_REGIONS, payload: false });
      handleActionError(getAllRegionsError);
    }
  };

// initial state
const initialState = {
  regionData: [],
  loadingRegions: false,
  regionOptions: [],
  selectedRegion: null,
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REGIONS:
      return middlewareGetAllRegions(state, action);
    case LOAD_GET_ALL_REGIONS:
      return {
        ...state,
        loadingRegions: action.payload,
      };
    case SET_SELECTED_REGION:
    default:
      return state;
  }
};

const middlewareGetAllRegions = (state, action) => {
  {
    // let _regionData = action.payload;
    // let commonRegion;
    // let zonesFromOldRegion;

    // let commonRegionsData = oldRegionOptions.map(
    //   (oldRegion, oldRegionIndex) => {
    //     commonRegion = _regionData.find(
    //       (_region) => _region.value === oldRegion.value
    //     );
    //

    //     zonesFromOldRegion = getZoneOptionsFromRegionValue(
    //       oldRegionOptions,
    //       oldRegion.value
    //     );

    //     return {
    //       ...oldRegion,
    //       zones: [...zonesFromOldRegion, ...commonRegion.zones],
    //     };
    //   }
    // );

    // let filteredRegions = _regionData.filter((_region) => {
    //   if (
    //     oldRegionOptions.find((oldRegion) => oldRegion.value === _region.value)
    //   )
    //     return false;
    //   return true;
    // });

    return {
      ...state,
      regionData: action.payload,
      // regionOptions: [...commonRegionsData, ...filteredRegions],
      regionOptions: action.payload,
    };
  }
};

// selector functions
export const getRegionData = state => state.legacyRegion.regionData;
export const getRegionOptions = state => state.legacyRegion.regionOptions;
export const getSelectedRegion = state => state.legacyRegion.selectedRegion;
export const getLoadingRegions = state => state.legacyRegion.loadingRegions;
