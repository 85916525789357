import React, { useMemo } from "react";
import farmerImage from "assets/icons/farmer.svg";
import _ from "lodash";
const FarmerAvatar = ({ avatar_url, alt, size }) => {
  return useMemo(() => {
    if (avatar_url === "null" || avatar_url == null || _.isNull(avatar_url)) {
      return (
        <img
          src={farmerImage}
          alt={alt || "AOS User Image"}
          width={size || 70}
          height={size || 70}
          className="farmer-profile-img"
        />
      );
    }
    return (
      <img
        src={avatar_url ? avatar_url : farmerImage}
        alt="agent"
        width={100}
        height={100}
        className="farmer-profile-img"
      />
    );
  }, [avatar_url]);
};

export default FarmerAvatar;
