import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { getAllSubscriptionPlans, getSubscriptionPlans } from "features/v1/subscription";
import { useDisclosure, useTable } from "hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency, formatDate } from "utils";
import CreateSubscriptionForm from "views/forms/createSubscriptionForm";

const SubscriptionSettingsPage = () => {
  let subscriptionPlanData = useSelector(getSubscriptionPlans);

  const dispatch = useDispatch();

  const { tableProps } = useTable({
    data: subscriptionPlanData,
    columns: [
      {
        Header: "Plan Name",
        accessor: "subscription_plan_name",
      },
      {
        id: "subscrptionPlanCreatedAt",
        Header: "Date",
        accessor: subscriptionPlan => formatDate(new Date(subscriptionPlan.created_at)),
      },
      {
        Header: "Duration (Months)",
        accessor: "duration",
      },
      {
        id: "subscriptionPlanContributionAmount",
        Header: "Equity Contribution Amount",
        accessor: subscriptionPlan => formatCurrency(subscriptionPlan.equity_contribution_amount),
      },
      {
        id: "subscriptionPlanLoanAmount",
        Header: "Total loan Amount",
        accessor: subscriptionPlan => formatCurrency(subscriptionPlan.total_input_loan_amount),
      },
      {
        id: "subscriptionPlanOutputName",
        Header: "Output Name",
        accessor: subscriptionPlan => subscriptionPlan?.subscription_plan_outputs?.output_name || "N/A",
      },
    ],
    title: "Subscription Plans",
  });

  useEffect(() => {
    dispatch(getAllSubscriptionPlans());
  }, []);
  const createSubscriptionDisclosure = useDisclosure();
  return (
    <div>
      <Table
        {...tableProps}
        createAction={{ label: "Add New Subscription" }}
        onCreateAction={() => {
          createSubscriptionDisclosure.onOpen();
        }}
      />
      <Modal
        open={createSubscriptionDisclosure.isOpen}
        onOpenChange={open => {
          //  example of on modal close
          if (open === false) {
            createSubscriptionDisclosure.onClose();
          }
        }}
      >
        <ModalContent title="Add New Subscription">
          <CreateSubscriptionForm />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SubscriptionSettingsPage;
