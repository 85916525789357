import React, { useMemo } from "react";
import { EmptyState } from "components/appState";
import { PageHeader, PageWrapper, Panel, VisibilityWrapper, FormGridHeader } from "components/layout";
import { CropSelect, selectConstants } from "components/select";
import { useGetSingleTrade, useUpdateTrade } from "hooks/app/marketplace";
import { Badge, Button, Flex, Icon, Text, Box, Input, TextBox } from "organic";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { appGet, formatDate, queryStringToObject } from "utils";
import { Formik, Form } from "formik";
import CurrencySelect from "components/select/currencySelect";
import { handleFormSubmission } from "services/formSubmissionService";
import { getCurrentUser } from "services/v1/authService";
import { DateSelect } from "components/date";

const PAGE_VIEW_MODE = "view";
const PAGE_EDIT_MODE = "edit";

const currentUser = getCurrentUser();

const getEdiLogisticsInitialValues = trade => {
  return {
    initiated_by: appGet(trade, "initiated_by", ""),
    crop_type: appGet(trade, "crop_type", ""),
    anticipated_profit: appGet(trade, "anticipated_profit", ""),
    logistics_type: appGet(trade, "logistics_type", []),
    logistics_cost: appGet(trade, "logistics_cost", "0"),
    target_quantity: appGet(trade, "target_quantity", "0"),
    assigned_agents: appGet(trade, "assigned_agents", []),
    created_at: appGet(trade, "created_at", ""),
    updated_at: appGet(trade, "updated_at", ""),
  };
};

const LogisticsDetails = () => {
  const { tradeId } = useParams();
  const [searchParams, __] = useSearchParams();
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetSingleTrade({ params: { id: tradeId } });
  console.log({ data });
  const { mutateAsync } = useUpdateTrade({});

  const mode = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(options, "mode", "view");
  }, [searchParams]);

  const isEditMode = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(options, "mode", "view") === PAGE_EDIT_MODE;
  }, [searchParams]);

  const createOption = item => {
    return item
      ? {
          value: item,
          label: item,
        }
      : null;
  };

  const initialValues = useMemo(() => getEdiLogisticsInitialValues(data), [data]);

  const handleUpdateTrade = async (values, __formOptions) => {
    console.log("values", values, "user", currentUser);

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: { data: { ...values, approved_by: currentUser.userId }, id: tradeId },
      successMsg: "trade updated succesfully",
      errorMsg: "Something went wrong while updating trade, please try again",
      onSuccess: () => {
        navigate(-1);
      },
    });
  };

  // useEffect(() => {
  //   if (data && !createdBy?.current) {
  //     const user = getSingleAdmin(appGet(data, "initiated_by", ""));
  //     console.log({ user });
  //     createdBy.current = user;
  //   }
  // }, [data, getSingleAdmin]);

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > Trade" subtitle="Your trade summary and activity." />
      </Flex>
      <Panel
        bodyBg="transparent"
        headerElement={
          <LogisticsDetailsHeader
            onClickAction={() => {
              navigate(-1);
            }}
            name={appGet(data, "trade_id", "--")}
            isLoading={isLoading}
            mode={mode}
          />
        }
      >
        <Flex flexDirection="column" gap="1rem" bg="white" p="24px">
          {error || isLoading || !data ? (
            <EmptyState title="Something went wrong" subtitle="Unable to fetch trade details, Trying again..." />
          ) : (
            <Box>
              <Formik initialValues={initialValues} onSubmit={handleUpdateTrade}>
                {({
                  values,
                  //  setFieldValue, handleSubmit
                }) => (
                  <>
                    <Form>
                      <Box>
                        <Flex flexDirection="column" width="100%" gap="1rem">
                          {/* Croptype and cost price */}
                          <Flex gap="1rem">
                            <VisibilityWrapper
                              width="100%"
                              isVisible={isEditMode}
                              placeholder={<TextBox width="100%" label="Crop Type" value={values.crop_type} />}
                            >
                              <CropSelect label="Crop Type" name="crop_type" required />
                            </VisibilityWrapper>
                            <VisibilityWrapper
                              width="100%"
                              display="block"
                              isVisible={isEditMode}
                              placeholder={
                                <TextBox width="100%" label="Created" value={formatDate(values.created_at)} />
                              }
                            >
                              <DateSelect
                                label="Created"
                                name="Created"
                                // onChange={date => {
                                //   // const formattedDate = formatDate(date);
                                // }}
                              />
                            </VisibilityWrapper>
                          </Flex>

                          <FormGridHeader>Logistics Type</FormGridHeader>
                          {/* Logistics Type and Logistics Cost */}
                          {values.logistics_type?.length ? (
                            <>
                              {values.logistics_type.map((logistics, index) => (
                                <Flex width="100%" gap="1rem" my={1} key={index}>
                                  {console.log({ logistics })}

                                  <>
                                    <VisibilityWrapper
                                      width="100%"
                                      display="block"
                                      isVisible={isEditMode}
                                      placeholder={
                                        <TextBox
                                          width="100%"
                                          label={index === 0 ? "Logistics Type" : ""}
                                          value={logistics.name}
                                        />
                                      }
                                    >
                                      <Box width="100%" gap="1rem">
                                        <Input label="Logistics Type" required value={logistics.type} type="text" />
                                      </Box>
                                    </VisibilityWrapper>
                                    <VisibilityWrapper
                                      width="100%"
                                      display="block"
                                      isVisible={isEditMode}
                                      placeholder={
                                        <TextBox
                                          width="100%"
                                          label={index === 0 ? "Unit" : ""}
                                          value={logistics.unit}
                                        />
                                      }
                                    >
                                      <Box width="100%" gap="1rem">
                                        <Input label="Unit" required value={logistics.type} type="text" />
                                      </Box>
                                    </VisibilityWrapper>
                                    <VisibilityWrapper
                                      width="100%"
                                      display="block"
                                      isVisible={isEditMode}
                                      placeholder={
                                        <TextBox
                                          width="100%"
                                          label={index === 0 ? "Price" : ""}
                                          value={logistics.price_per_unit}
                                        />
                                      }
                                    >
                                      <Box width="100%" gap="1rem">
                                        <Input label="Price" required value={logistics.type} type="text" />
                                      </Box>
                                    </VisibilityWrapper>
                                    <VisibilityWrapper
                                      width="100%"
                                      display="block"
                                      isVisible={isEditMode}
                                      placeholder={
                                        <TextBox
                                          width="100%"
                                          label={index === 0 ? "Amount" : ""}
                                          value={logistics.amount}
                                        />
                                      }
                                    >
                                      <Box width="100%">
                                        <Input
                                          label="Amount"
                                          required
                                          value={logistics.cost}
                                          type="number"
                                          rightAddon={
                                            <CurrencySelect
                                              placeholder="Currency"
                                              colorScheme={selectConstants.colorSchemes.UNSTYLED}
                                              value={createOption(logistics.currency)}
                                            />
                                          }
                                        />
                                      </Box>
                                    </VisibilityWrapper>
                                  </>
                                </Flex>
                              ))}
                            </>
                          ) : (
                            <>
                              <Box mx={4}>
                                <Text font="smRegular" color="gray500">
                                  No Logistics Type
                                </Text>
                              </Box>
                            </>
                          )}

                          {/* Target quantity and assigned agent */}
                          <Flex width="100%" gap="1rem">
                            <VisibilityWrapper
                              width="100%"
                              display="block"
                              isVisible={isEditMode}
                              placeholder={
                                <TextBox width="50%" label="Last Modified" value={formatDate(values.updated_at)} />
                              }
                            >
                              <DateSelect
                                label="Last Modified"
                                name="lastModified"
                                // onChange={date => {
                                //   const formattedDate = formatDate(date);
                                //   // setDateIssued(formattedDate);
                                //   // setFieldValue("lastModified", formattedDate);
                                // }}
                              />
                            </VisibilityWrapper>
                            {/* COMMENTED IT BECAUSE IT WAS EMPTY */}

                            {/* <VisibilityWrapper
                              width="100%"
                              display="block"
                              isVisible={isEditMode}
                              placeholder={<TextBox width="100%" label="Created" value={values.cost_price} />}
                            ></VisibilityWrapper> */}
                          </Flex>
                        </Flex>
                        <VisibilityWrapper
                          width="100%"
                          isVisible={isEditMode}
                          gap="1rem"
                          my="2rem"
                          mx="1rem"
                          justifyContent="flex-end"
                        >
                          <Box>
                            <Button
                              type="button"
                              colorScheme="secondaryGray"
                              onClick={() => {
                                navigate(-1);
                              }}
                            >
                              <Text color="gray700">Cancel</Text>
                            </Button>
                          </Box>
                          <Box alignItems="start">
                            <Button type="submit">
                              <Text color="white" textAlign="center">
                                Update Logistics
                              </Text>
                            </Button>
                          </Box>
                        </VisibilityWrapper>
                      </Box>
                    </Form>
                  </>
                )}
              </Formik>
            </Box>
          )}
        </Flex>
      </Panel>
    </PageWrapper>
  );
};

const LogisticsDetailsHeader = ({ onClickAction, name, isLoading, mode }) => {
  return (
    <Flex alignItems="center" gap="1rem">
      <Button type="button" variant="unstyled" onClick={onClickAction}>
        <Icon icon="arrow-left" color="gray700" size={20} />
      </Button>
      <Text as="span" font="lgMedium" color="gray900">
        Back to Logistics
      </Text>
      <Badge as="span" colorScheme={mode === PAGE_VIEW_MODE ? "blue" : "success"}>
        {mode === PAGE_EDIT_MODE ? "Editing" : "Viewing"} {isLoading ? "Loading Trade Details..." : `ID ${name}`}
      </Badge>
    </Flex>
  );
};

export default LogisticsDetails;
