import { FormInput } from "components/inputs";
import { CountrySelect, GenderSelect, LGASelect, Select, StateSelect } from "components/select";
// import FarmerAvatar from "components/v1/common/FarmerAvatar";
// import CustomFormField from "components/v1/Forms/CustomFormField";
import FormGridTitle from "components/v1/Forms/FormGridTitle";
// import OverlayLoader from "components/v1/Loader/OverlayLoader";
import { banks } from "components/v1/options/banks";
// import AgentSelect from "components/v1/Select/AgentSelect";
// import { statusOptions } from "components/v1/options/status";
import {
  editFarmerDetails,
  // getLoadingFarmers
} from "features/v1/farmers";
import { ErrorMessage, Form, Formik } from "formik";
import { useDataFilter } from "hooks";
import { Box, Button, Flex } from "organic";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Label } from "reactstrap";
// import { formatRole } from "utils";
import { customStyles } from "utils/v1/dropdownIndicatorStyle";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import { getGroupIdFromTA } from "utils/v1/TAUtils";
import { initiateEditFarmerDetails } from "views/v1/Forms/Farmers/farmerInitialValues";
import { editFarmerDetailsValidationSchema } from "views/v1/Forms/Farmers/farmerValidationSchemas";

const EditFarmerDetails = props => {
  const { selected, modal } = props;

  const [selectedCountry, setSelectedCountry] = useState();

  const { filterParams } = useDataFilter();

  const dispatch = useDispatch();

  const handleEditFarmerDetails = (values, formOptions) => {
    dispatch(editFarmerDetails(values, { formOptions, modal, filterParams, selected }));
  };
  return (
    <Box>
      <Formik
        initialValues={initiateEditFarmerDetails(selected)}
        onSubmit={handleEditFarmerDetails}
        validationSchema={editFarmerDetailsValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex flexDirection="column" gap="1.5rem">
              <FormGridTitle title="User Details" />
              {/* <Flex >
                {/* <StatusSelect
                  label="Status"
                  name="status"
                  defaultValue={"active"}
                  onChange={value => setFieldValue("status", value)}
                /> */}
              <Box>
                <FormInput
                  name="group_id"
                  label="Group ID"
                  disabled
                  defaultValue={getGroupIdFromTA(selected.ta_number)}
                />
              </Box>
              <Box>
                <FormInput name="ta_number" label="User ID" disabled defaultValue={selected.ta_number} />
              </Box>
              <Box>
                <FormInput required name="first_name" label="First Name" defaultValue={values.first_name} />{" "}
              </Box>
              <Box>
                <FormInput required name="last_name" label="Last Name" defaultValue={values.last_name} />
              </Box>
              <Box>
                <FormInput name="middle_name" label="Middle Name" defaultValue={values.middle_name} />{" "}
              </Box>
              <Box>
                <FormInput name="phone_number" label="Phone Number" defaultValue={values.phone_number} />
              </Box>
              <Box>
                <Label htmlFor="phone_number_owner">Phone Number Owner</Label>
                <Select
                  options={[
                    { value: "owner", label: "Owner" },
                    { value: "relative", label: "Relative" },
                  ]}
                  defaultValue={values.phone_number_owner}
                  onChange={value => setFieldValue("phone_number_owner", value)}
                />
              </Box>
              <Box></Box>
              <Label htmlFor="gender">Gender</Label>
              <GenderSelect defaultValue={values.gender} onChange={val => setFieldValue("gender", val)} name="gender" />

              <Box>
                <FormGridTitle title="Demographic Details" />
              </Box>
              <Box>
                <FormInput name="age" label="Age" type="number" defaultValue={values.age} />
              </Box>
              <Box>
                <CountrySelect onChange={setSelectedCountry} />
              </Box>
              <Box>
                <StateSelect
                  name="state"
                  styles={{
                    container: customStyles.container,
                    control: customStyles.control,
                    dropdownIndicator: customStyles.dropdownIndicatorStyles,
                  }}
                  defaultValue={values.state}
                  countryName={selectedCountry}
                  onChange={value => setFieldValue("state", value)}
                />
                <ErrorMessage component="span" name="state" className="text-danger" />
              </Box>
              <Box>
                <Label htmlFor="lga">LGA</Label>
                <LGASelect
                  state={values.state}
                  countryName={selectedCountry}
                  defaultValue={values.lga}
                  onChange={val => setFieldValue("lga", val)}
                  name="lga"
                />
              </Box>
              <Box>
                <FormInput name="village" label="Village" defaultValue={selected.village} />
              </Box>
              <Box>
                <FormInput name="district" label="District" defaultValue={selected.district} />
              </Box>
              <Box>
                <FormGridTitle title="Bank Details" />

                <Box>
                  <Label htmlFor="bank_name">Bank Name</Label>
                  <Select
                    options={banks}
                    defaultValue={values.bank_code}
                    onChange={val => {
                      const bankOption = selectOptionFromValue(banks, val);
                      setFieldValue("bank_code", bankOption.bankCode);
                    }}
                  />
                </Box>
                <Box></Box>
                <FormInput name="bank_account_name" label="Account Name" defaultValue={selected.bank_account_name} />
              </Box>
              <Box>
                <FormInput
                  name="bank_account_number"
                  label="Account Number"
                  defaultValue={selected.bank_account_number}
                />
              </Box>
              <Box>
                <FormInput name="bvn" label="BVN" defaultValue={selected.bvn} />
              </Box>
              <Flex style={{ gap: "20px" }}>
                <Button type="button" colorScheme="secondaryGray" onClick={modal.hideModal}>
                  Cancel
                </Button>
                <Button type="submit">Save Farmer Details</Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditFarmerDetails;
