import { useEffect, useRef, useState } from "react";
import { getUserScope } from "services/v1/rolesAndPermissionsService";

export function useScope() {
  const _userScopeRef = useRef(getUserScope());

  const [userScope, _setUserScope] = useState([]);

  useEffect(() => {
    if (_userScopeRef?.current && !userScope?.length) {
      _setUserScope(_userScopeRef.current.split(","));
    }
  }, []);

  return { userScope };
}
