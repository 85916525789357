import React from "react";
import Card from "components/card/card";
import Chart from "components/chart/chart";
import { CHART_HEADER_HEIGHT, ChartHeader } from "components/chart/chartHeader";
import { Box, organicTheme } from "organic";
import { uniqueId } from "utils";
import { useSelector } from "react-redux";
import { getOverviewAgentInvolvedCount, getOverviewOverAllAgentCount } from "features/v1/overview";

const ActiveAgents = ({ cardHeight, chart, cardWidth = "auto" }) => {
  let overAllAgentCount = useSelector(getOverviewOverAllAgentCount);
  let activeAgents = useSelector(getOverviewAgentInvolvedCount);

  let _inactiveAgent = Math.ceil(overAllAgentCount - activeAgents);
  const inactiveAgentsCount = isNaN(_inactiveAgent) || _inactiveAgent == Infinity ? 0 : _inactiveAgent;

  const { type, title, subtitle, height, width } = chart;

  return (
    <Card height={cardHeight} width={cardWidth} stackHorizontal title={title}>
      <Box height={CHART_HEADER_HEIGHT}>
        {[
          {
            subtitle,
            value: activeAgents,
          },
        ].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Box>
      <Chart
        data={[
          { name: "Active", value: activeAgents, fill: organicTheme.colors.success500 },
          { name: "Inactive", value: inactiveAgentsCount, fill: organicTheme.colors.success100 },
        ]}
        type={type}
        height={height}
        width={width}
      />
    </Card>
  );
};

export default ActiveAgents;
