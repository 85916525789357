import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import InputDistribution_API from "services/inputDistibutionService";
import { useDataFilter } from "../filter";

export function useGetInputDistribution({ params }) {
  const { isValidFilter: enabled } = useDataFilter();

  const fetcher = async ({ signal }) => {
    const { data } = await InputDistribution_API.fetchInputDistributionData(params, { signal });
    return data;
  };

  return useQuery({
    queryKey: [
      rqKeys.inputDistribution.fetchInputDistributionData,
      params?.nPerPage,
      params?.page,
      params?.dateRange?.[0],
      params?.dateRange?.[1],
      params?.searchText,
      params?.seasonId,
      params?.region,
    ],
    queryFn: fetcher,
    enabled,
  });
}
