import React from "react";

import { Form, Formik } from "formik";

import { Box, Button, Flex } from "organic";
import { FormInput } from "components/inputs";
import { StatusSelect } from "components/select";
import { useDispatch } from "react-redux";
import { updateFarmMappingInfo } from "features/v1/farmMapping";
import DataStructure from "components/modal/dataStructure";

const EditMappingDetails = props => {
  const { modal, selected } = props;

  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{ ...selected, adjusted_farm_size: selected?.adjusted_farm_size || selected?.farm_size }}
      onSubmit={(values, formikOptions) => {
        dispatch(updateFarmMappingInfo(values, { formikOptions, modal, selected }));
      }}
    >
      {({ setFieldValue, handleChange, isSubmitting }) => (
        <Box mt={5}>
          <Form>
            <DataStructure
              fields={[
                {
                  label: "Field ID",
                  value: selected?.ta_number || "N/A",
                },
                {
                  label: "Current Farm Size (ha)",
                  value: selected?.farm_size || "N/A",
                },
              ]}
            />
            <Box my={5}>
              <FormInput
                type="number"
                // value={values?.adjusted_farm_size}
                required
                onChange={handleChange}
                name="adjusted_farm_size"
                label="Adjusted Farm Size"
              />

              <Box my={3}>
                <StatusSelect
                  required
                  defaultValue={selected.status}
                  label="Status"
                  onChange={val => setFieldValue("status", val)}
                  name="status"
                />
              </Box>
            </Box>
            <Flex style={{ gap: "20px" }}>
              <Button type="button" colorScheme="secondaryGray" onClick={modal.hideModal} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                Save Mapping Details
              </Button>
            </Flex>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default EditMappingDetails;
