import { useIsFetching } from "@tanstack/react-query";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const numberOfQueriesFetching = useIsFetching();

  const value = useMemo(() => {
    return {
      isLoading,
      numberOfQueriesFetching,
      setIsLoading,
    };
  }, [isLoading, numberOfQueriesFetching]);

  useEffect(() => {
    if (numberOfQueriesFetching === 0) {
      setIsLoading(false);
    }

    if (numberOfQueriesFetching > 0) {
      setIsLoading(true);
    }
  }, [numberOfQueriesFetching]);

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => useContext(LoadingContext);
