import { apiConstants } from "constants/apiConstants";
import { AOSClientError } from "services/shared/AOSErrorService";
import { appGet } from "./object-utils";

const endpointNameOptions = Object.values(apiConstants.endpoints);

const resolveBackendServer = endpointPath => {
  let _backendServerName = "";
  Object.keys(apiConstants.endpointsByBackendServer).forEach(backendServer => {
    if (apiConstants.endpointsByBackendServer[backendServer].includes(endpointPath)) {
      _backendServerName = backendServer;
    }
  });
  return _backendServerName;
};

function getApiBaseUrl(backendServer) {
  let baseURL;

  // select baseURL based on bacekendServer arg
  if (backendServer === apiConstants.apiNames.ADMIN_API) {
    baseURL = resolveAdminApiBaseUrl();
  }

  if (backendServer === apiConstants.apiNames.MAIN_API) {
    baseURL = resolveMainApiBaseUrl();
  }

  return baseURL;
}

/**
 * function takes an object as input and recursively generates a URL query string.
 * It properly handles nested objects by appending square brackets to the keys.
 * Arrays are also supported, and the resulting query string is properly URL-encoded.
 * @param {object} obj
 * @param {*} parentKey
 * @returns
 */
export function objectToQueryString(obj, parentKey = "") {
  const queryString = [];

  for (const key in obj) {
    if (obj?.prototype?.hasOwnProperty?.call?.(key)) {
      const value = obj[key];
      const encodedKey = parentKey ? `${parentKey}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

      if (Array.isArray(value)) {
        // Handle array values by iterating through each element
        value.forEach((element, index) => {
          const arrayKey = `${encodedKey}[${index}]`;
          queryString.push(`${arrayKey}=${encodeURIComponent(element)}`);
        });
      } else if (value && typeof value === "object") {
        // Recursively handle nested objects
        queryString.push(objectToQueryString(value, encodedKey));
      } else if (value !== undefined && value !== null) {
        // Encode key and value and add to the queryString array
        queryString.push(`${encodedKey}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryString.join("&");
}

/**
 * function uses a regular expression (keyValuePattern)
 * to check whether the provided string follows the basic format
 * of key-value pairs in a query string
 * @param {*} queryString
 * @returns {boolean} return true if the `queryString` is a valid URLSearchParams literal
 */
export function isValidQueryString(queryString) {
  // Regular expression for validating key-value pairs in a query string
  const keyValuePattern = /^[\w%]+=[\w%]*(&[\w%]+=[\w%]*)*$/;

  return keyValuePattern.test(queryString);
}

/** switch admin api endpoint based on NODE_ENV
 * if the different NODE_ENV based API env vars are avaliable
 */

export const resolveAdminApiBaseUrl = () => {
  let ADMIN_API;

  switch (apiConstants.currentPrefferedBackendServer) {
    case "production":
      ADMIN_API = appGet(apiConstants.baseUrls.production, apiConstants.apiNames.ADMIN_API, "");
      break;
    case "staging":
      ADMIN_API = appGet(apiConstants.baseUrls.staging, apiConstants.apiNames.ADMIN_API, "");
      break;
    case "development":
      ADMIN_API = appGet(apiConstants.baseUrls.local, apiConstants.apiNames.ADMIN_API, "");
      break;
    default:
      ADMIN_API = apiConstants.defaults.ADMIN_API;
  }

  return ADMIN_API;
};

/** switch users api endpoint based on NODE_ENV
 * if the different NODE_ENV based API env vars are avaliable
 */
export const resolveMainApiBaseUrl = () => {
  let MAIN_API;
  switch (apiConstants.currentPrefferedBackendServer) {
    case "production": {
      MAIN_API = appGet(apiConstants.baseUrls.production, apiConstants.apiNames.MAIN_API, "");
      break;
    }
    case "staging": {
      MAIN_API = appGet(apiConstants.baseUrls.staging, apiConstants.apiNames.MAIN_API, "");
      break;
    }
    case "development": {
      MAIN_API = appGet(apiConstants.baseUrls.local, apiConstants.apiNames.MAIN_API, "");
      break;
    }
    default:
      MAIN_API = apiConstants.defaults.MAIN_API;
  }
  return MAIN_API;
};

export function getApiUrl({ endpointName = "", path = "", version = 1, queryParams = {} }) {
  if (endpointName && !endpointNameOptions.includes(endpointName)) {
    throw new AOSClientError(
      `endpointName passed to getApiUrl is not a known endpointName, ${endpointName} does not exist in known endpoint names ${endpointNameOptions}`
    );
  }
  const backendServer = resolveBackendServer(endpointName);

  const baseURL = getApiBaseUrl(backendServer);
  // Ensure that the base URL ends with a slash and the endpoint doesn't start with one
  const base = baseURL.endsWith("/") ? baseURL : baseURL + "/";

  // Combine the base URL and endpoint to form the final URL
  let finalURL = `${base}`;

  if (version) {
    finalURL = finalURL.concat(apiConstants.versionNames[version]);
  }
  if (endpointName) {
    finalURL = finalURL.concat(apiConstants.endpoints[endpointName]);
  }

  const urlPath = path.startsWith("/") ? path : `/${path}`;

  if (urlPath) {
    finalURL = finalURL.concat(urlPath);
  }

  const query = Object.keys(queryParams)?.length ? objectToQueryString(queryParams) : null;
  if (query) {
    finalURL = finalURL.concat(`?${query}`);
  }

  return finalURL;
}
