import React, { useState } from "react";
// import PropTypes from "prop-types";
import { useCustomModal, useTable, useDataFilter } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  getFarmersForClearanceData,
  getFarmersForClearanceMeta,
  // getLoadingFarmersForClearance,
  // getLoadingSearchFarmersForClearance,
  searchAllFarmersForClearanceWithParams,
} from "features/v1/farmerClearance";
import farmersApi from "api/farmers";
import { toast } from "react-toastify";
import ViewFarmerForClearance from "views/v1/DataTables/FarmerClearance/ViewFarmerForClearance";
import { handleActionError } from "services/shared/AOSErrorService";
import { Table } from "components/table";
import { Button } from "organic";
import { formatDate } from "utils";

const UnclearedFarmersPage = () => {
  const [selectedFarmer, setSelectedFarmer] = useState();
  const [actionType, setActionType] = useState();
  const [loadingClearFarmer, setLoadingClearFarmer] = useState(false);

  const dispatch = useDispatch();

  let farmersForClearanceData = useSelector(getFarmersForClearanceData);
  let farmersForClearanceMeta = useSelector(getFarmersForClearanceMeta);
  // let loadingFarmersForClearance = useSelector(getLoadingFarmersForClearance);
  // let loadingSearchFarmersForClearance = useSelector(getLoadingSearchFarmersForClearance);

  let { filterParams } = useDataFilter();
  const unclearedFarmerModal = useCustomModal();

  const { tableProps } = useTable({
    data: farmersForClearanceData,
    columns: [
      {
        Header: "Group ID",
        accessor: "group_id",
      },
      { id: "farmerName", Header: "Name", accessor: farmer => `${farmer?.first_name} ${farmer?.last_name}` },
      {
        Header: "Phone",
        accessor: "phone_number",
      },
      {
        id: "farmerCreatedAt",
        Header: "Date",
        accessor: fee => formatDate(fee?.created_at),
      },
      {
        Header: "TA Number",
        accessor: "ta_number",
      },
      {
        Header: "Expected Group Total",
        accessor: "expectedGroupSubscriptionTotal",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "LGA",
        accessor: "lga",
      },
      {
        id: "farmerDetails",
        Header: "Clear Farmer",
        accessor: farmer => farmer,
        Cell: ({ value }) => (
          <Button
            leftIcon="check"
            disabled={loadingClearFarmer}
            size="sm"
            colorScheme="secondaryGray"
            onClick={() => handleClearFarmer(value)}
          >
            Clear {value?.first_name}&apos;s Group
          </Button>
        ),
      },
    ],
    title: "Farmer For Clearance",
    useServerPagination: true,
    serverPaginationMeta: farmersForClearanceMeta,
    noActions: true,
  });

  const ignoredhandleSelect = options => {
    setActionType(options.actionKey);
    setSelectedFarmer(options.selected);
    unclearedFarmerModal.showModal();
  };

  const handleClearFarmer = async farmer => {
    try {
      setLoadingClearFarmer(true);
      const res = await farmersApi.get("clearFarmer/initiateWithAcid", {
        params: { groupId: farmer.group_id, season: filterParams.seasonId },
      });
      if (res.data.status === "success") {
        toast.success(`${farmer.first_name}'s group cleared successfully`);
        // dispatch(getFarmersForClearanceWithParams(filterParams));
        return;
      }
    } catch (error) {
      setLoadingClearFarmer(false);
      handleActionError(error);
    } finally {
      setLoadingClearFarmer(false);
    }
  };

  return (
    <>
      <Table
        {...tableProps}
        onSearch={(params, { abortController }) => {
          /** const {
           * searchTerm: searchTerm,
           * region: filterParams.region,
           * seasonId: filterParams.seasonId,
           * dateRange: filterParams.dateRange,
           * // pageSize is called pageCount on the server 🤷‍♂️
           * // pageIndex is called page on the server 🤷‍♂️
           * nPerPage: state.pageSize,
           * page: 1,
           * } = params
           *
           */
          dispatch(searchAllFarmersForClearanceWithParams(params, { abortController }));
        }}
      />
      {actionType === "view" && <ViewFarmerForClearance selected={selectedFarmer} modal={unclearedFarmerModal} />}
    </>
  );
};

export default UnclearedFarmersPage;
