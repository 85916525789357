import { FormInput } from "components/inputs";
import { selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import { MAX_NUM_VALUE, MIN_NUM_VALUE } from "constants/sharedConstants";
import { useFormikContext } from "formik";
import { useDidMountEffect } from "hooks";
import numeral from "numeral";
import React from "react";
import { createSelectOption } from "utils/v1/selectUtils";

const calcTotalTradePrice = ({ sellingPrice, targetQuantity }) => {
  return numeral(sellingPrice || 0)
    .multiply(targetQuantity || 1)
    .value()
    .toFixed(2);
};

export const TradeRevenueField = () => {
  const { values, setFieldValue } = useFormikContext();
  useDidMountEffect(() => {
    setFieldValue(
      "trade_finance_fee",
      calcTotalTradePrice({ sellingPrice: values.selling_price, targetQuantity: values.target_quantity }) || "0"
    );
  }, [values.selling_price, values.target_quantity]);
  return (
    <FormInput
      label="Trade Revenue "
      name="trade_finance_fee"
      required
      hint="selling price × Target quantity"
      type="number"
      min={MIN_NUM_VALUE}
      max={MAX_NUM_VALUE}
      isDisabled
      readOnly
      rightAddon={
        <CurrencySelect
          onChange={currencyValue => {
            setFieldValue("currency", currencyValue);
          }}
          value={createSelectOption(values.currency)}
          colorScheme={selectConstants.colorSchemes.UNSTYLED}
          placeholder="Currency"
        />
      }
    />
  );
};
