import numeral from "numeral";
import { Badge } from "organic";
import React from "react";

const RowRange = ({ pageIndex, pageSize, totalItems }) => {
  const firstRow = pageIndex * pageSize + 1;
  const lastRow = Math.min((pageIndex + 1) * pageSize, totalItems);

  return (
    <Badge>
      Showing {firstRow || 0}-{lastRow || 0} of {numeral(totalItems).format("0,0") || "unknown"}
    </Badge>
  );
};

export default RowRange;
