import breakpoints, { mediaQueries } from "./breakpoints";
import colors from "./colors";
import { fonts, fontSizes, fontWeights, lineHeights, letterSpacings } from "./fonts";
import opacity from "./opacity";
import space from "./spaces";
import sizes from "./sizes";

const defaultTheme = {
  // from styled system
  disableStyledSystemCache: false, // enable style cache by default
  space,
  colors,
  fonts,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacings,
  breakpoints,
  mediaQueries,
  opacity,
  sizes,
  borderWidths: {
    0: "0px",
    1: "1px",
    2: "2px",
    4: "4px",
    8: "8px",
    DEFAULT: "1px",
  },
  borderStyles: {
    solid: "solid",
  },
  radii: {
    none: "0px",
    xs: "0.125rem",
    sm: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
  shadows: {},
  zIndices: {
    auto: "auto",
    0: "0",
    10: "10",
    20: "20",
    30: "30",
    40: "40",
    50: "50",
    60: "60",
    70: "70",
    80: "80",
    90: "90",
  },
  transitions: {},
  bgPositions: {
    bottom: "bottom",
    center: "center",
    left: "left",
    leftBottom: "left bottom",
    leftTop: "left top",
    right: "right",
    rightBottom: "right bottom",
    rightTop: "right top",
    top: "top",
  },
  //   WIP
  container: ["40rem", "48rem", "64rem", "80rem", "96rem"],
  contrast: {
    0: "0",
    50: ".5",
    75: ".75",
    100: "1",
    125: "1.25",
    150: "1.5",
    200: "2",
  },
  cursor: {
    auto: "auto",
    default: "default",
    pointer: "pointer",
    wait: "wait",
    text: "text",
    move: "move",
    help: "help",
    notAllowed: "not-allowed",
    none: "none",
    contextMenu: "context-menu",
    progress: "progress",
    cell: "cell",
    crosshair: "crosshair",
    verticalText: "vertical-text",
    alias: "alias",
    copy: "copy",
    noDrop: "no-drop",
    grab: "grab",
    grabbing: "grabbing",
    allScroll: "all-scroll",
    colResize: "col-resize",
    rowResize: "row-resize",
    nResize: "n-resize",
    eResize: "e-resize",
    sResize: "s-resize",
    wResize: "w-resize",
    neResize: "ne-resize",
    nwResize: "nw-resize",
    seResize: "se-resize",
    swResize: "sw-resize",
    ewResize: "ew-resize",
    nsResize: "ns-resize",
    neswResize: "nesw-resize",
    nwseResize: "nwse-resize",
    zoomIn: "zoom-in",
    zoomOut: "zoom-out",
  },
  dropShadow: {
    // WIP
  },
  // flex box theme values
  flex: {
    1: "1 1 0%",
    auto: "1 1 auto",
    initial: "0 1 auto",
    none: "none",
  },
  flexBasis: {
    auto: "auto",
    ...space,
    "1/2": "50%",
    "1/3": "33.333333%",
    "2/3": "66.666667%",
    "1/4": "25%",
    "2/4": "50%",
    "3/4": "75%",
    "1/5": "20%",
    "2/5": "40%",
    "3/5": "60%",
    "4/5": "80%",
    "1/6": "16.666667%",
    "2/6": "33.333333%",
    "3/6": "50%",
    "4/6": "66.666667%",
    "5/6": "83.333333%",
    "1/12": "8.333333%",
    "2/12": "16.666667%",
    "3/12": "25%",
    "4/12": "33.333333%",
    "5/12": "41.666667%",
    "6/12": "50%",
    "7/12": "58.333333%",
    "8/12": "66.666667%",
    "9/12": "75%",
    "10/12": "83.333333%",
    "11/12": "91.666667%",
    full: "100%",
  },
  flexGrow: {
    0: "0",
    DEFAULT: "1",
  },
  flexShrink: {
    0: "0",
    DEFAULT: "1",
  },
  gap: { ...space },
  listStyleType: {
    none: "none",
    disc: "disc",
    decimal: "decimal",
  },
  objectPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  order: {
    first: "-9999",
    last: "9999",
    none: "0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
  },
  icons: {
    iconXs: "24px",
    iconSm: "32px",
    iconMd: "40px",
    iconLg: "48px",
    iconXl: "56px",
  },
  //   transition
  transitionDelay: ["0s", "75ms", "100ms", "150ms", "200ms", "300ms", "500ms", "700ms", "1000ms"],
  transitionDuration: ["0s", "75ms", "100ms", "150ms", "200ms", "300ms", "500ms", "700ms", "1000ms"],
};

export default defaultTheme;
