import { FormInput } from "components/inputs";
import { MAX_NUM_VALUE, MIN_NUM_VALUE } from "constants/sharedConstants";
import React from "react";

export const ActualQuantitySourcedField = () => {
  return (
    <FormInput
      placeholder="Actual Quantity Accepted (KG)"
      type="number"
      min={MIN_NUM_VALUE}
      max={MAX_NUM_VALUE}
      name="actual_quantity_sourced"
      required
      label="Actual Quantity Accepted (KG)"
    />
  );
};
