import React, { useEffect } from "react";
import { getAllAdmins, getLoadingAdmins, getSubAdmins } from "features/v1/auth";
import { useDispatch, useSelector } from "react-redux";
import { PageWrapper } from "components/layout";
import { Table } from "components/table";
import { useDisclosure, useTable } from "hooks";
import { PageHeader } from "components/layout";
import { Modal } from "reactstrap";
import { ModalContent } from "components/modal";
import EditMemberDetails from "./members/editMemberDetails";

const MembersPage = () => {
  const subAdmins = useSelector(getSubAdmins);
  const loadingAdmins = useSelector(getLoadingAdmins);

  const memberModal = useDisclosure();
  const dispatch = useDispatch();

  const { tableProps, selectedRow: selectedMember } = useTable({
    data: subAdmins,
    columns: [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
      },
    ],
    title: "All Members",
    noViewAction: true,
  });

  useEffect(() => {
    dispatch(getAllAdmins());
  }, []);

  return (
    <PageWrapper>
      <PageHeader title="Members" subtitle="All members and store managers" />
      <Table
        {...tableProps}
        loading={loadingAdmins}
        onEditAction={() => {
          memberModal.onOpen();
        }}
      />
      <Modal {...memberModal.modalProps}>
        <ModalContent title="Edit Member Details">
          <EditMemberDetails selected={selectedMember} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export default MembersPage;
