import numeral from "numeral";
import { AOSClientError, AOSTypeError } from "services/shared/AOSErrorService";
import { isPlainObject, appGet, isString, isNotEmptyObject, isNumber } from "utils";

const defaultParseLatLngCoordinateConfig = {
  latKey: "lat",
  lngKey: "lng",
};

const defaultIsValidLatLngCoordinatesConfig = defaultParseLatLngCoordinateConfig;

export function convertStrCoordinateToLatLngObject(data) {
  const latLngArray = data.split(",");
  if (latLngArray.length !== 2) {
    return null;
  } else if (latLngArray.length === 2) {
    return { lat: latLngArray[0], lng: latLngArray[1] };
  }
}

export function convertArrayCoordinateToLatLngObject(data) {
  return { lat: data[0], lng: data[1] };
}
export function convertObjectToLatLngArray(data, config = { latKey: "lat", lngKey: "lng" }) {
  return [data[config.latKey], data[config.lngKey]];
}

export function convertObjectToLatLngObject(data, config) {
  if (isPlainObject(data) && appGet(data, config?.latKey, false) && appGet(data, config?.lngKey, false)) {
    return { lat: data[config.latKey], lng: data[config.lngKey] };
  }

  return data;
}

export function isValidStringCoord(data) {
  //  parameter is a string in format "3.34565,9.34565"
  return isString(data) && data.contains(",");
}
export function isValidArrayCoord(data) {
  // parameter is a an array in the format [lat,lng]
  return Array.isArray(data) && data?.length === 2 && isValidLatitude(data[0]) && isValidLongitude(data[1]);
}
export function isValidObjectCoord(data, config) {
  // if parameter is an already formed valid position object
  return isNotEmptyObject(data) && !isValidLatLngCoordinates(data, config);
}

export function castValidLatLngObject(arg) {
  // make sure we're using numerical values and not string
  return { lat: numeral(arg.lat).value(), lng: numeral(arg.lng).value() };
}

export function isValidLatLngCoordinates(data, config = defaultIsValidLatLngCoordinatesConfig) {
  let latValue, lngValue;
  latValue = appGet(data, config.latKey, false);
  lngValue = appGet(data, config.lngKey, false);

  if (lngValue && latValue) {
    return isValidLatitude(latValue) && isValidLongitude(lngValue);
  }

  return false;
}

export function isValidLongitude(longitude) {
  if (!isNumber(longitude)) {
    throw new AOSClientError(`longitude should be a number,  got ${typeof longitude} instead`);
  }

  if (-180 <= longitude <= 180 && -180 <= longitude) {
    return true;
  }
  throw new AOSClientError("MapData Error", "Longitiude is not a valid longitude value");
}

export function isValidLatitude(latitude) {
  if (!isNumber(latitude)) {
    throw new AOSTypeError(`latitude should be a number,  got ${typeof latitude} instead`);
  }
  if (latitude <= 90 && -90 <= latitude) {
    return true;
  }
  throw new AOSClientError("MapData Error: Longitiude is not a valid longitude value");
}
export function getCountryFromAddressCompo(addressComponents) {
  let country;

  if (!Array.isArray(addressComponents)) {
    country = "";
    return country;
  }

  addressComponents.forEach(component => {
    const types = component.types;
    if (types.includes("country")) {
      country = component.long_name;
      console.log("Country: " + country);
    }
  });

  return country;
}

export function getStateFromAddressComponent(addressComponents) {
  let state;

  if (!Array.isArray(addressComponents)) {
    state = "";
    return state;
  }

  addressComponents.forEach(component => {
    const types = component.types;
    if (types.includes("administrative_area_level_1")) {
      state = component.long_name;
      console.log("State: " + state);
    }
  });

  return state;
}

export function handleGeocodeStatus(status) {
  switch (status) {
    case "OK":
      // Handle successful geocoding results
      break;
    case "ZERO_RESULTS":
      // Handle case where no results were found
      break;
    case "OVER_QUERY_LIMIT":
      // Handle rate limiting or quota exceeded
      break;
    case "REQUEST_DENIED":
      // Handle authorization issues
      break;
    case "INVALID_REQUEST":
      // Handle invalid request parameters
      break;
    default:
    // Handle other or unknown errors
  }
}
