import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUserAvatar } from "features/v1/auth";
import { Box, Icon, Image } from "organic";

const AvatarUrl = ({ avatarUrl, size, borderRadius, view }) => {
  const [image, setImage] = useState(avatarUrl);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setImage(avatarUrl);
  }, [avatarUrl]);

  const handleFileUpload = async file => {
    try {
      setLoading(true);
      await dispatch(updateUserAvatar(file));
    } catch (error) {
      console.error("Error uploading avatar:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    if (!view) {
      inputRef.current.click();
    }
  };

  return (
    <Box>
      <label>
        {image ? (
          <Image
            src={image}
            onClick={handleClick}
            borderRadius={borderRadius}
            size={size}
            alt={view ? "Image" : "Profile image"}
          />
        ) : (
          <div onClick={handleClick}>
            <Icon icon="user" size={size} />
          </div>
        )}
      </label>
      <input
        onChange={e => handleFileUpload(e.target.files[0])}
        disabled={loading}
        style={{
          paddingLeft: "250px",
          paddingBottom: "20px",
          margin: "0px",
          display: "none",
        }}
        id="file"
        type="file"
        label="Upload Profile Picture"
        name="avatar_url"
        accept=".png, .jpg, .jpeg"
        hidden={true}
        ref={inputRef}
      />
    </Box>
  );
};

export default AvatarUrl;
