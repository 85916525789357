import React, { useState } from "react";
import { Button, Flex, Label, buttonConstants, organicTheme } from "organic";
import { Calendar } from "react-date-range";
import { isFunction } from "utils";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover";
import { useDisclosure } from "hooks";
// import { formatDate } from "utils";

const DateSelect = ({ onChange, label, triggerElement }) => {
  const [triggerLabel, setTriggerLabel] = useState(new Date().toLocaleDateString());

  const dateSelectPopover = useDisclosure();

  const handleDateSelect = date => {
    let label = generateDateLabel(date.toLocaleDateString());
    setTriggerLabel(label);
    if (isFunction(onChange)) {
      onChange(date);
    }
    dateSelectPopover.onClose();
  };

  return (
    <Popover {...dateSelectPopover.modalProps}>
      <PopoverTrigger>
        {triggerElement ?? (
          <Flex flexDirection="column">
            <Label>{label}</Label>
            <Button
              type="button"
              {...dateSelectPopover.getButtonProps()}
              colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}
              leftIcon="calendar"
            >
              {triggerLabel}
            </Button>
          </Flex>
        )}
      </PopoverTrigger>
      <PopoverContent>
        <Calendar onChange={handleDateSelect} color={organicTheme.colors.primary500}>
          DateSelect
        </Calendar>
      </PopoverContent>
    </Popover>
  );
};

const generateDateLabel = _date => {
  return `${_date}`;
};

export { DateSelect };
