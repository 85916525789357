import React from "react";
import { PageWrapper } from "components/layout";
import { PageTabsNav } from "components/pageTabs";
import { Flex, Text } from "organic";
import { CropSelect } from "components/select";
import { appGet } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData, setData } from "features/v1/dashboard";
import { VisibilityWrapper } from "components/layout/visibilityWrapper";
import { PageHeader } from "components/layout";
import { useDashboardLayout } from "context";
// import { useDashboardLayout } from "context";

const SEASON_TAB_ID = "season";
const REGIONS_TAB_ID = "regions";
const CROPS_TAB_ID = "crops";
const INPUTS_TAB_ID = "inputs";
const LANGUAGE_TAB_ID = "language";
const SUBSCRIPTION_PLANS_TAB_ID = "subscriptionPlans";
const QUESTIONNAIRE = "questionnaire";
const CONTRACTORS_TAB_ID = "contractors";
const ROLES_AND_PERMISSIONS_TAB_ID = "rolesAndPermissions";
const FARM_SUPERVISION_TAB_ID = "farmSupervision";
const MANAGE_MODULE_TAB_ID = "manageModule";

// const DEFAULT_TAB_ID = SEASON_TAB_ID;

const tabs = [
  { tabId: SEASON_TAB_ID, title: "Projects", path: "projects" },
  { tabId: REGIONS_TAB_ID, title: "Regions", path: "regions" },
  { tabId: CROPS_TAB_ID, title: "Crops", path: "crops" },
  { tabId: INPUTS_TAB_ID, title: "Inputs", path: "inputs" },
  { tabId: LANGUAGE_TAB_ID, title: "Subscription Plans", path: "subscriptionPlans" },
  { tabId: SUBSCRIPTION_PLANS_TAB_ID, title: "Terms and Conditions", path: "terms" },
  { tabId: QUESTIONNAIRE, title: "Questionnaire", path: "questionnaire" },
  { tabId: CONTRACTORS_TAB_ID, title: "Contractors", path: "contractors" },
  { tabId: ROLES_AND_PERMISSIONS_TAB_ID, title: "Roles and Permissions", path: "roles" },
  { tabId: FARM_SUPERVISION_TAB_ID, title: "Farm Supervision / Monitoring", path: "farmSupervision" },
  { tabId: MANAGE_MODULE_TAB_ID, title: "Manage Module", path: "manageModule" },
];

function SettingsPage() {
  const dispatch = useDispatch();

  let dashboardData = useSelector(getDashboardData);
  const { dashboardState } = useDashboardLayout();
  // const {currentPageTabId}= dashboardState

  const selectedCrop = appGet(dashboardData, "selectedCrop");
  const currentPageTabId = appGet(dashboardState, "currentPageTabId");

  return (
    <PageWrapper>
      {/* <CustomFilter ignoreDateRange ignoreRegion noExport /> */}
      <Flex flexDirection="row" justifyContent="space-between">
        <PageHeader title="Settings" subtitle="Manage all users activity" />
        <VisibilityWrapper isVisible={currentPageTabId === INPUTS_TAB_ID}>
          <Flex flexDirection="column">
            <CropSelect
              onChange={value => {
                dispatch(setData({ selectedCrop: value }));
              }}
            />
            <Text font="smMedium" color="error500">
              {!selectedCrop ? "Kindly select a crop" : ""}
            </Text>
          </Flex>
        </VisibilityWrapper>
      </Flex>
      <PageTabsNav tabs={tabs} />
    </PageWrapper>
  );
}

export default SettingsPage;
