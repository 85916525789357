import React, { useEffect, useState } from "react";
import { Button, Flex, Icon, organicTheme } from "organic";
import { buttonConstants } from "organic/components/button/button";
import { DateRangePicker } from "react-date-range";
import { formatDate, isFunction } from "utils";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover";
import { useDisclosure } from "hooks";

const SINGLE_DATE_RANGE_KEY = "dateRange";

const DateRangeSelect = ({ onChange, dateRange, staticRanges = [] }) => {
  const [dateRangePickerState, setDateRangePickerState] = useState(defaultDateRangeSelectRanges);
  const [triggerLabel, setTriggerLabel] = useState();

  const { isOpen, onClose, getButtonProps } = useDisclosure();

  const handleDateRangePickerState = async ranges => {
    const selectedRange = await ranges[SINGLE_DATE_RANGE_KEY];
    setDateRangePickerState([selectedRange]);
    let label = generateDateRangeLabel([selectedRange]);
    setTriggerLabel(label);
  };

  const handleSubmit = () => {
    const selectedRange = dateRangePickerState[0];
    if (isFunction(onChange)) {
      onChange([selectedRange.startDate, selectedRange.endDate]);
    }
    onClose();
  };

  useEffect(() => {
    if (dateRange) {
      const dateRangeSelectFormat = formatFilterDateRangeValueForDateRangeSelect(dateRange);
      let label = generateDateRangeLabel(dateRangeSelectFormat);
      setDateRangePickerState(dateRangeSelectFormat);
      setTriggerLabel(label);
    }
  }, []);

  return (
    <Popover
      open={isOpen}
      onOpenChange={open => {
        if (!open) {
          onClose();
        }
      }}
      modal
    >
      <PopoverTrigger>
        <Button {...getButtonProps()} colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY} leftIcon="calendar">
          {triggerLabel}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Flex flexDirection="column" backgroundColor="white" padding="0.5rem" borderRadius="lg">
          <Flex flexDirection="row" justifyContent="flex-end">
            <Button
              variant={buttonConstants.variants.DESTRUCTIVE}
              colorScheme={buttonConstants.colorSchemes.TERTIARY_COLOR}
              size="sm"
              onClick={() => onClose()}
            >
              <Icon icon="x" size={16} />
            </Button>
          </Flex>
          <DateRangePicker
            ranges={dateRangePickerState}
            onChange={handleDateRangePickerState}
            staticRanges={staticRanges}
            rangeColors={[organicTheme.colors.primary600]}
            inputRanges={[]}
          />
          <Flex justifyContent="flex-end">
            <Button onClick={handleSubmit} colorScheme={buttonConstants.colorSchemes.TERTIARY_COLOR}>
              Submit
            </Button>
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  );
};

const defaultDateRangeSelectRanges = [{ key: SINGLE_DATE_RANGE_KEY, startDate: new Date(), endDate: new Date() }];

const generateDateRangeLabel = _dateRange => {
  return `${formatDate(_dateRange[0].startDate)} - ${formatDate(_dateRange[0].endDate)}`;
};

const formatFilterDateRangeValueForDateRangeSelect = _dateRange => {
  if (_dateRange) {
    const [startDate, endDate] = _dateRange;
    const dateRangeSelectFormat = { key: SINGLE_DATE_RANGE_KEY, startDate, endDate };
    return [dateRangeSelectFormat];
  }
};

export { DateRangeSelect };
