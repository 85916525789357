import React from "react";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Text } from "organic";
import { FormInput } from "components/inputs";
import { useGenerateInvoice } from "hooks/app/marketplace";
import { handleFormSubmission } from "services/formSubmissionService";
import { DateSelect } from "components/date";
import { getCurrentUser } from "services/v1/authService";
import { addDays, startOfDay } from "date-fns";
import { generateInvoiceValidationSchema } from "validators/marketplaceValidatorSchemas";
import { FormErrorMessage } from "components/form";
import { InvoiceItemField } from "../tradeForm/invoiveItemField";

const GenerateInvoice = ({ modal, trade }) => {
  const { mutateAsync, isLoading } = useGenerateInvoice();

  const initialValues = {
    initiated_by: getCurrentUser()?.userId || "",
    recipient_email: "",
    to: "",
    date_issued: "",
    date_due: "",
    invoice_item: [],
    trade_id: trade.trade_id,
    crop_type: trade.crop_type,
    total_amount: 0,
    total_quantity: 0,
  };

  // const onSubmit = async (values, formOptions) => {
  //   console.log(values);
  //   let _values = {
  //     ...values,
  //     invoice_item: values.invoice_item.map(item => ({
  //       ...item,
  //       rates: parseFloat(item.rates),
  //       quantity: parseFloat(item.quantity),
  //       amount: parseFloat(item.amount),
  //     })),
  //     total_amount: parseFloat(values.total_amount),
  //     total_quantity: parseFloat(values.total_quantity),
  //     date_issued: new Date(values.date_issued).toISOString(),
  //     date_due: new Date(values.date_due).toISOString(),
  //   };

  const onSubmit = async (values, formOptions) => {
    console.log("Original values:", values);

    let _values = {
      ...values,
      invoice_item: values.invoice_item.map(item => ({
        ...item,
        rates: parseFloat(item.rates),
        quantity: parseFloat(item.quantity),
        amount: parseFloat(item.amount),
      })),
      total_amount: parseFloat(values.total_amount),
      total_quantity: parseFloat(values.total_quantity),
      date_issued: new Date(values.date_issued).toISOString(),
      date_due: new Date(values.date_due).toISOString(),
    };

    console.log("Converted values:", _values);

    console.log("Final payload:", _values);

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: _values,
      config: { modal, formOptions },
      successMsg: "Invoice generated Successfully",
      errorProps: {
        message: "Error generating invoice",
        msg400: "Error generating invoice",
        msg401: "Unauthorized",
      },
    });
  };

  return (
    <Box>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={generateInvoiceValidationSchema}>
        {({ setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Box>
              <Text font="smMedium" color="error500">
                {errors.initiated_by}
              </Text>
              <Flex flexDirection="column" width="100%" gap="1rem">
                <Flex gap="1rem">
                  <Box width="50%">
                    <FormInput label="Recipient Email" name="recipient_email" required />
                  </Box>
                  <Box width="50%">
                    <FormInput label="Bill To" name="to" required />
                  </Box>
                </Flex>
                <Flex gap="1rem">
                  <Box width="50%">
                    <DateSelect
                      label="Date of Issue"
                      onChange={date => {
                        console.log({ date });
                        setFieldValue("date_issued", startOfDay(addDays(date, 1)));
                      }}
                    />
                    <FormErrorMessage name="date_issued" />
                  </Box>
                  <Box width="50%">
                    <DateSelect
                      label="Due Date"
                      onChange={date => {
                        console.log({ date });
                        setFieldValue("date_due", startOfDay(addDays(date, 1)));
                      }}
                    />
                    <FormErrorMessage name="date_due" />
                  </Box>
                </Flex>
              </Flex>
              <InvoiceItemField />
              <Flex gap="20px" mt="2rem">
                <Button
                  size="lg"
                  display="block"
                  type="button"
                  colorScheme="secondaryGray"
                  disabled={isSubmitting}
                  onClick={() => {
                    modal.hideModal();
                  }}
                >
                  Cancel
                </Button>
                <Button size="lg" display="block" type="submit" isDisabled={isSubmitting || isLoading}>
                  Send Invoice
                </Button>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default GenerateInvoice;
