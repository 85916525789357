import React, { useEffect, useRef, useState } from "react";
import { Image, Text } from "../primary";
import Icon from "../icon/icon";
import { Button, buttonConstants } from "..";
import { StyledAvatar } from "./styledAvatar";
import { generateColors, getInitials, parseSize } from "organic/utils/shared-utils";
import { Tooltip } from "components/modal";

const AVATAR_COLORS = [
  "#F9FAFB",
  "#ECFFE5",
  "#FEF3F2",
  "#FEF0C7",
  "#ECFDF3",
  "#F8F9FC",
  "#E0F2FE",
  "#EFF8FF",
  "#EEF4FF",
  "#F4F3FF",
  "#FDF2FA",
  "#FFF1F3",
  "#FFF6ED",
];

/**
 * an Avatar component that display the image if src prop is a valid image url, otherwise display the initials of the name prop
 *
 */
export const BaseAvatar = ({
  src,
  name,
  size = "sm",
  borderRadius = "50%",
  iconColor = "#fff",
  focusColor = "#000",
  className,
  onClick,
  onHover,
  tooltipContent,
  style,
}) => {
  const [initials, setInitials] = useState("");
  const avatarRef = useRef(null);
  const colors = generateColors(AVATAR_COLORS);

  useEffect(() => {
    if (!src) {
      setInitials(getInitials(name));
    }
  }, [src, name]);

  return (
    <Tooltip content={tooltipContent} openOnHover>
      <StyledAvatar
        ref={avatarRef}
        size={parseSize(size)}
        borderRadius={borderRadius}
        backgroundColor={colors.bgColor}
        iconColor={iconColor}
        focusColor={focusColor}
        className={className}
        style={style}
        onClick={onClick}
        onHover={onHover}
      >
        {src ? (
          <Image src={src} alt={name} />
        ) : (
          (!src && name && !onClick && (
            //  if there's name prop and no src prop, display the initials of the name prop
            <Text color={colors.color} font={`${size}Medium`}>
              {initials}
            </Text>
          )) ||
          null
        )}
        {(!src && onClick && (
          <Button
            size={buttonConstants.sizes.xs}
            colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}
            onClick={onClick}
          >
            <Icon icon="edit" color={colors.color} />
          </Button>
        )) ||
          null}
      </StyledAvatar>
    </Tooltip>
  );
};

const Avatar = React.memo(BaseAvatar);
Avatar.displayName = "Avatar";
export { Avatar };
