import { authChecker, getOrg } from "../../services/v1/authService";
import season, { seasonWithoutOrg } from "api/season";
import { toast } from "react-toastify";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import { handleActionError } from "services/shared/AOSErrorService";
import { isFunction } from "utils";
import farmerSeason from "api/farmerseason";

const organizationId = getOrg();

// actions
const GET_ALL_SEASONS = "GET_ALL_SEASONS";
const LOAD_GET_ALL_SEASONS = "LOAD_GET_ALL_SEASONS";
const LOAD_UPDATE_SEASON = "LOAD_UPDATE_SEASON";
const SET_SELECTED_SEASON = "SET_SELECTED_SEASON";
const LOAD_TRANSFER_FARMERS = "LOAD_TRANSFER_FARMERS";
// const UPDATE_SEASON = "UPDATE_SEASON";

export const getAllSeasons = () => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_GET_ALL_SEASONS,
      payload: true,
    });
    const seasonData = await season.get("", { ignoreOrgId: true });

    if (seasonData.status === 200) {
      dispatch({
        type: GET_ALL_SEASONS,
        payload: seasonData.data.data,
      });
      dispatch({
        type: LOAD_GET_ALL_SEASONS,
        payload: false,
      });
      return seasonData.data.data;
    }
  } catch (getAllSeasonsError) {
    dispatch({
      type: LOAD_GET_ALL_SEASONS,
      payload: false,
    });
    handleActionError(getAllSeasonsError);
  }
};

// const ignoredGetSeasonRegionsAndZones = seasonId => async dispatch => {
//  const __regionsAndZonesData  = await season
//     .get("getRegionsAndZones", { params: { season: `${seasonId}` } })

//       // dispatch({
//       //   type: GET_ALL_SEASONS,
//       //   payload: seasonData.data.data,
//       // });
//       // dispatch({
//       //   type: LOAD_GET_ALL_SEASONS,
//       //   payload: false,
//       // });

//     catch (getAllSeasonsError) {
//       handleActionError(getAllSeasonsError);
//     }
// };

export const setSelectedSeason = selectedSeason => async dispatch => {
  //console.log("getSelectedSeason: ",selectedSeason)
  dispatch({
    type: SET_SELECTED_SEASON,
    payload: selectedSeason,
  });
  // return dispatch(getSeasonRegionsAndZones(selectedSeason));
};

export const createSeason =
  (values, { setSubmitting, modal }) =>
  async dispatch => {
    try {
      const response = await season.post("/create", values);

      if (response.status === 200) {
        toast.success("Season Created created successfully!");
        dispatch(getAllSeasons());
      } else if (response.status === 401) {
        toast.error("You are not authorized to do that!");
      }
      setSubmitting(false);
      if (modal) {
        modal?.hideModal();
      }
    } catch (createSeasonError) {
      handleActionError(createSeasonError);
      setSubmitting(false);
    }
  };

/** Add list of Regions to a season */
export const addRegionsToSeason =
  (data, options = {}) =>
  async dispatch => {
    try {
      let hasModal = options?.modal;
      // let hasFormOptions = options?.formOptions;

      const id = options.id;
      dispatch({
        type: LOAD_UPDATE_SEASON,
        payload: true,
      });
      const res = await seasonWithoutOrg.put(`addRegion/${organizationId}/${id}`, data);

      if (res.status === 200) {
        toast.success("regions sucessfully added");
        if (hasModal) options.modal.hideModal();
        dispatch({
          type: LOAD_UPDATE_SEASON,
          payload: false,
        });
        return dispatch(getAllSeasons());
      }
    } catch (addRegionsToSeasonError) {
      dispatch({
        type: LOAD_UPDATE_SEASON,
        payload: false,
      });
      handleActionError(addRegionsToSeasonError);
      // setSubmitting(false);
    }
  };

export const transferFarmersToSeason = (values, config) => async dispatch => {
  try {
    const params = {
      seasonId: config.from,
    };

    const { seasonFrom, seasonTo } = config;
    dispatch({ type: LOAD_TRANSFER_FARMERS, payload: true });
    await farmerSeason.post("transferFarmerSeason", values, { params });
    toast.success(`Farmers from ${seasonFrom.label} transfered to ${seasonTo.label} Succesfully`);
    dispatch({ type: LOAD_TRANSFER_FARMERS, payload: false });
    if (isFunction(config?.onSuccess)) {
      config.onSuccess();
    }
    // opt out of fetching the list
    return dispatch(getAllSeasons(config.filter));
  } catch (transferFarmerToAgentErr) {
    dispatch({ type: LOAD_TRANSFER_FARMERS, payload: false });
    handleActionError(transferFarmerToAgentErr);
  }
};

// reducer
const initialState = {
  loadingSeasons: false,
  loadingUpdateSeason: false,
  seasonData: [],
  seasonOptions: [],
  selectedSeason: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SEASONS: {
      return middlewareHandleGetAllSeasons(state, action);
    }
    case LOAD_GET_ALL_SEASONS:
      return {
        ...state,
        loadingSeasons: action.payload,
      };
    case LOAD_UPDATE_SEASON:
      return {
        ...state,
        loadingUpdateSeason: action.payload,
      };
    case SET_SELECTED_SEASON: {
      return middlewareHandleSetSelectedSeason(state, action);
    }
    default:
      return state;
  }
};

// middleware
const middlewareHandleGetAllSeasons = (state, action) => {
  const selectOptions = action.payload
    .filter(seasonOption => {
      return seasonOption.isActive ? true : false;
    })
    .map(season => {
      return {
        value: season._id,
        label: season.name,
        target: season.target,
        acheived: season.acheived,
      };
    });

  return {
    ...state,
    seasonData: action.payload,
    seasonOptions: selectOptions,
  };
};

const middlewareHandleSetSelectedSeason = (state, action) => {
  let selectedSeasonOption = selectOptionFromValue(state.seasonOptions, action.payload);
  return {
    ...state,
    selectedSeason: selectedSeasonOption,
  };
};

// selector functions
export const getSeasonData = state => state.legacySeason.seasonData;
export const getSeasonOptions = state => state.legacySeason.seasonOptions;
export const getSelectedSeason = state => state.legacySeason.selectedSeason;
// export const getSelectedSeasonId = (state) => state.season.selectedSeason.value;
export const getLoadingSeasons = state => state.legacySeason.loadingSeasons;
