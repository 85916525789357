import { FormInput } from "components/inputs";
import DataStructure from "components/modal/dataStructure";
import CustomBadge from "components/v1/badge/CustomBadge";
// import DataGridRow from "components/v1/DataGrid/DataGridRow";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import React from "react";

const VerifyBvn = ({ selected }) => {
  return (
    <>
      <Box my={4}>
        <CustomBadge color="light">Unverified BVN</CustomBadge>&nbsp;Just to let you know this user BVN is not yet
        verified
      </Box>
      <div className="mx-auto my-4 text-center">
        <DataStructure
          fields={[
            {
              label: "Full Name",
              value: `${selected?.first_name || ""} ${selected?.last_name || ""}`,
            },
            {
              label: "Phone Number",
              value: selected?.phone_number,
            },
            {
              label: "State",
              value: selected?.state,
            },
          ]}
        />
      </div>
      <div className="mx-auto my-4" style={{ maxWidth: "370px" }}>
        <Formik initialValues={{ bvn: "" }}>
          {() => (
            <Form>
              <Flex>
                <Box width="100%">
                  <FormInput label="Your BVN" placeholder="221344567741" required name="bvn" type="number" />
                </Box>
              </Flex>
              <Flex>
                <Box mt="1rem">
                  <Button type="submit">Verify BVN</Button>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default VerifyBvn;
