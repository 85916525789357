import { ErrorMessage } from "formik";
import React from "react";
import { default as CreatableSelectPrmitive } from "react-select/creatable";
import { selectConstants } from ".";
import { classnames } from "utils";
import { Box } from "organic";
import { StyledLabel } from "organic/components/input/styledInput";

const CreatableSelect = props => {
  const { label, name, colorScheme, ...restProps } = props;

  const selectRootClassnames = classnames({
    select: colorScheme === selectConstants.colorSchemes.DEFAULT,
    "blue-select": colorScheme === selectConstants.colorSchemes.BLUE,
    "black-select": colorScheme === selectConstants.colorSchemes.BLACK,
  });
  const selectContainerClassnames = classnames({
    "select-container": colorScheme === selectConstants.colorSchemes.DEFAULT,
    "blue-select-container": colorScheme === selectConstants.colorSchemes.BLUE,
    "black-select-container": colorScheme === selectConstants.colorSchemes.BLACK,
  });
  return (
    <>
      {label && (
        <Box mb={2}>
          <StyledLabel>
            {
              label
              //  && <Text>{label}</Text>
            }
          </StyledLabel>
        </Box>
      )}
      <CreatableSelectPrmitive
        {...restProps}
        unstyled
        className={selectContainerClassnames}
        classNamePrefix={selectRootClassnames}
        classNames={{
          clearIndicator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.clearIndicator),
          control: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.control),
          dropdownIndicator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.dropdownIndicator),
          group: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.group),
          groupHeading: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.groupHeading),
          indicatorsContainer: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.indicatorsContainer),
          indicatorSeparator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.indicatorSeparator),
          input: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.input),
          loadingIndicator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.loadingIndicator),
          loadingMessage: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.loadingMessage),
          menu: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menu),
          menuList: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menuList),
          menuPortal: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menuPortal),
          multiValue: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValue),
          multiValueLabel: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValueLabel),
          multiValueRemove: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValueRemove),
          noOptionsMessage: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.noOptionsMessage),
          option: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.option),
          placeholder: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.placeholder),
          singleValue: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.singleValue),
          valueContainer: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.valueContainer),
        }}
      />
      {name && <ErrorMessage name={name} component="span" className="text-danger ca-font-regular" />}
    </>
  );
};

CreatableSelect.defaultProps = {
  colorScheme: "default",
};

export { CreatableSelect };

const mapSelectStateToClassName = (state, className) => {
  if (state.isFocused) {
    return `${className}--focused`;
  }
  if (state.isSelected) {
    return `${className}--selected`;
  }
  if (state.isDisabled) {
    return `${className}--disabled`;
  }

  return className;
};

const reactSelectInnerComponentsClassNames = {
  clearIndicator: "clear-indicator",
  container: "container",
  control: "control",
  dropdownIndicator: "dropdown-indicator",
  group: "group",
  groupHeading: "group-heading",
  indicatorsContainer: "indicators-container",
  indicatorSeparator: "indicator-separator",
  input: "input",
  loadingIndicator: "loading-indicator",
  loadingMessage: "loading-message",
  menu: "menu",
  menuList: "menu-list",
  menuPortal: "menu-portal",
  multiValue: "multi-value",
  multiValueLabel: "multi-value-label",
  multiValueRemove: "multi-value-remove",
  noOptionsMessage: "no-options-message",
  option: "option",
  placeholder: "placeholder",
  singleValue: "single-value",
  valueContainer: "value-container",
};
