import React from "react";
import PropTypes from "prop-types";
import AOSFormButton from "../Button/AOSFormButton";
import EmptyIcon from "./EmptyIcon.svg";
import OverlayLoader from "components/v1/Loader/OverlayLoader";

const emptyStatePropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.string,
  onClickAction: PropTypes.func,
  actionLabel: PropTypes.string,
  status: PropTypes.oneOf(["error", "danger", "info", "warning", "default"]),
};

const LegacyEmptyState = props => {
  const { title, subtitle, message, onClickAction, actionLabel, loading } = props;
  return (
    <OverlayLoader active={loading || false}>
      <div
        style={{
          backgroundColor: "#f4f4f4",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "64px 15px",
          borderRadius: "8px",
        }}
      >
        <h4>{title ? title : "Nothing Here"}</h4>
        <img src={EmptyIcon} alt="No Data" />
        {subtitle ? <h5>{subtitle}</h5> : undefined}
        {message ? <p>{message}</p> : undefined}
        {onClickAction && actionLabel ? <AOSFormButton text={actionLabel} onClick={onClickAction} /> : undefined}
      </div>
    </OverlayLoader>
  );
};

LegacyEmptyState.propTypes = emptyStatePropTypes;

export default LegacyEmptyState;
