import React from "react";
import { OverviewValueWrapper, resolveMarketplaceTradeStatusColor } from "../marketplaceOverviewPage";
import { Table } from "components/table";
import { SummaryGrid } from "components/grid";
import { Avatar, AvatarGroup, Badge, Button, Flex, Text, badgeConstants, buttonConstants } from "organic";
import { useDataFilter, useLegacyRole, useTable } from "hooks";
import { useGetMarketplaceOverview, useGetTrades } from "hooks/app/marketplace";
import { tradeStatuses } from "../marketplaceTradePage";
import numeral from "numeral";
import { appGet, capitalize } from "utils";
import { useNavigate } from "react-router-dom";
import { resources } from "services/v1/rolesAndPermissionsService";
import { useAppRoles } from "hooks/app";

const FinalisedTrades = () => {
  const { filterParams } = useDataFilter();

  const navigate = useNavigate();

  const { canUpdate } = useLegacyRole();

  const appRoles = useAppRoles();

  const handleResolveTrade = trade => {
    console.log("resolve trade");
    navigate(`/dashboard/marketplace/marketplaceReconciliation/reconcile/${trade.trade_id}`);
  };

  const { data: overviewData, isLoading: isOverviewLoading } = useGetMarketplaceOverview({
    params: filterParams.dateRange,
  });
  const { data: tradeData } = useGetTrades({
    params: { status: tradeStatuses.FINALIZED },
  });

  const { tableProps } = useTable({
    data: tradeData?.data || [],
    columns: [
      {
        Header: "ID",
        accessor: "trade_id",
      },
      {
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Cost Price",
        accessor: trade => numeral(trade.cost_price).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Farmers under trade",
        accessor: "trade_farmers",
      },
      {
        Header: "Target Qty (KG)",
        accessor: trade => numeral(trade?.target_quantity || 0).format("0,0.00"),
      },
      {
        Header: "Sourced Qty (KG)",
        accessor: trade => numeral(trade.target_achieved).format("0"),
      },

      {
        Header: "Total Logistics",
        accessor: trade => numeral(trade.logistics_cost).format("0,0.00"),
      },
      {
        Header: "No. of Sent Invoice",
        accessor: "total_invoice",
      },
      {
        Header: "Assigned Agents",
        accessor: "assigned_agents",
        Cell: ({ value }) => {
          return (
            <AvatarGroup>
              {value?.map?.(agent => (
                <Avatar size="xs" key={agent._id} name={`${agent.first_name} ${agent.last_name}`} />
              ))}
            </AvatarGroup>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge colorScheme={resolveMarketplaceTradeStatusColor(value)}>{capitalize(value)}</Badge>,
      },
      {
        Header: "Trade Reconcilation",
        accessor: "trade_reconcilation",
        Cell: ({ row }) => {
          return (
            <>
              {(appRoles.isRole("commercialSpecialist") && (
                <Button
                  type="button"
                  colorScheme={buttonConstants.colorSchemes.PRIMARY}
                  onClick={() => handleResolveTrade(row.original)}
                  disabled={!canUpdate?.includes?.(resources.marketPlace)}
                >
                  Resolve Trade
                </Button>
              )) || (
                <Badge size={badgeConstants.sizes.MD} colorScheme={badgeConstants.colorSchemes.BLUE_GRAY}>
                  Restricted
                </Badge>
              )}
            </>
          );
        },
      },
    ],
    title: "All finalised trades",
    useServerPagination: true,
    serverPaginationMeta: {},
    noEditAction: true,
  });
  return (
    <Flex flexDirection="column">
      <SummaryGrid
        data={[
          {
            title: "Total Trade Created",
            component: (
              <OverviewValueWrapper
                data={overviewData}
                value={numeral(overviewData?.pending || 0)
                  .add(overviewData?.approved || 0)
                  .add(overviewData?.declined || 0)
                  .add(overviewData?.stocked || 0)
                  // .add(overviewData?.delivered || 0)
                  .add(overviewData?.invoiceStatusCount || 0)
                  .add(overviewData?.finalized || 0)
                  .add(overviewData?.closed || 0)
                  .format("0")}
                isLoading={isOverviewLoading}
              />
            ),
          },
          {
            title: "Total Approved",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.approved} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Declined",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.declined} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Stocked",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.stocked} isLoading={isOverviewLoading} />
            ),
          },
          // {
          //   title: "Total Delivered",
          //   component: (
          //     <OverviewValueWrapper data={overviewData} value={overviewData?.delivered} isLoading={isOverviewLoading} />
          //   ),
          // },
          {
            title: "Total Finalised",
            component: (
              <OverviewValueWrapper
                isActive
                data={overviewData}
                value={overviewData?.finalized}
                isLoading={isOverviewLoading}
              />
            ),
          },
          {
            title: "Total Closed",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.closed} isLoading={isOverviewLoading} />
            ),
          },
        ]}
      />
      <Table
        {...tableProps}
        onEditAction={selectedAction => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedAction.selected._id}?mode=edit`);
        }}
        onViewAction={selectedAction => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedAction.selected._id}?mode=view`);
        }}
        onExtraAction={(selectedActions, __) => {
          if (selectedActions.actionKey === "viewInvoice") {
            console.log("selected actions ", selectedActions.selected);
            navigate(`invoice/${selectedActions.selected._id}`);
          }
        }}
        getExtraActions={__selected => {
          const actions = [];

          actions.push({ label: "View Invoice", actionKey: "viewInvoice" });

          return actions;
        }}
      />
    </Flex>
  );
};

export default FinalisedTrades;
