import React, { useEffect } from "react";
import { PageHeader } from "components/layout";
import { Table } from "components/table";
import { PageWrapper } from "components/layout";
import { useDisclosure, useTable } from "hooks";
import { Modal, ModalContent } from "components/modal";
import CreateTemplate from "./createTemplate";
import { useDispatch, useSelector } from "react-redux";
import { getAllTemplates, getAllTemplateData, deleteTemplate } from "features/v1/bulkSms";
import { formatDate, objectToStringifiedQueryString } from "utils";
import EditTemplate from "./editTemplate";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import { Badge, badgeConstants } from "organic";

const START_CAMPAIGN_KEY = "startCampaign";

const Templates = () => {
  const dispatch = useDispatch();

  let templatesData = useSelector(getAllTemplateData);

  const navigate = useNavigate();

  const createTemplateDisclosure = useDisclosure();
  const editTemplateDisclosure = useDisclosure();

  const { tableProps, selectedRow: selectedTemplate } = useTable({
    columns: [
      {
        Header: "Template Name",
        accessor: "template_name",
      },
      {
        id: "smsCharacters",
        Header: "Characters Count",
        accessor: template => template.content.length,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge
            colorScheme={value === "active" ? badgeConstants.colorSchemes.SUCCESS : badgeConstants.colorSchemes.ERROR}
          >
            {value}
          </Badge>
        ),
      },
      {
        id: "smsMessages",
        Header: "Messages",
        accessor: template => {
          return numeral(numeral(template.content.length).divide(160).value()).add(1).format("0");
        },
      },
      {
        id: "templateDate",
        Header: "Template Name",
        accessor: template => formatDate(template.created_at),
      },
    ],
    data: templatesData,
    title: "All Templates",
    canDelete: true,
    noViewAction: true,
  });

  const getExtraTableActions = () => {
    return [
      {
        label: "Start Campaign",
        actionKey: START_CAMPAIGN_KEY,
      },
    ];
  };

  useEffect(() => {
    dispatch(getAllTemplates());
  }, []);

  return (
    <PageWrapper>
      <PageHeader title="Messaging > Templates" subtitle="Manage all SMS campaign and activity." />
      <Table
        {...tableProps}
        createAction={{ label: "New Template" }}
        onCreateAction={() => {
          createTemplateDisclosure.onOpen();
        }}
        onEditAction={(__selectedAction, __config) => {
          editTemplateDisclosure.onOpen();
        }}
        onDeleteAction={(selectedAction, __config) => {
          dispatch(deleteTemplate(selectedAction.selected._id));
        }}
        getExtraActions={getExtraTableActions}
        onExtraAction={(selectedAction, __conifg) => {
          if (selectedAction.actionKey === START_CAMPAIGN_KEY) {
            const payload = objectToStringifiedQueryString({
              template: selectedAction.selected,
            });
            navigate(`/dashboard/messaging/create-campaign?${payload}`);
          }
        }}
      />
      <Modal {...createTemplateDisclosure.modalProps}>
        <ModalContent title="Create New Template">
          <CreateTemplate modal={createTemplateDisclosure.modal} />
        </ModalContent>
      </Modal>
      <Modal {...editTemplateDisclosure.modalProps}>
        <ModalContent title="Edit Template">
          <EditTemplate modal={editTemplateDisclosure.modal} selected={selectedTemplate} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export default Templates;
