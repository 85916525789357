import { Flex } from "organic";
import React, { useState } from "react";
import Permission from "./rolesAndPermission/permission";
import Roles from "./rolesAndPermission/roles";
// import { EmptyState } from "components/appState";

const RolesAndPermissionSettingsPage = () => {
  const [selectedRole, setSelectedRole] = useState(null);

  return (
    <Flex justifyContent="space-between" width="100%" gap="2rem">
      {/* <EmptyState title="Comming Soon" subtitle="Fine Grained Acess Control" /> */}
      <Flex flexGrow="1" width="40%">
        <Roles onSelectRole={setSelectedRole} />
      </Flex>
      <Flex flexDirection="column" flexGrow="2" width="60%">
        <Permission selectedRole={selectedRole} />
      </Flex>
    </Flex>
  );
};

export default RolesAndPermissionSettingsPage;
