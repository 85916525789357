/* eslint-disable no-unused-vars */
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Box, Flex, Text } from "organic";
import React from "react";
import { FormInput } from "components/inputs";

const loginDetailsValidationSchema = yup.object().shape({
  phone_number: yup.string().required("Phone number is required"),
  password: yup.string().required("Password is required"),
});

const EditLoginDetails = ({ loginDetails }) => {
  return (
    <>
      {loginDetails.phone_number ? (
        <Box>
          <Formik
            validationSchema={loginDetailsValidationSchema}
            initialValues={loginDetails}
            onSubmit={(values, formikOptions) => {}}
            enableReinitialize
          >
            {({ values, setFieldValue }) => {
              return (
                <>
                  {/* <pre>
                    <code>{JSON.stringify(values, null, 2)}</code>
                  </pre> */}
                  <Form>
                    <Flex gap="1rem" mx={1} my={1}>
                      <Box width="50%">
                        <FormInput
                          label="Phone Number"
                          name="phone_number"
                          placeholder="07063058990"
                          autoComplete="off"
                          required
                        />
                      </Box>
                      <Box width="50%" mb={5}>
                        <FormInput
                          name="password"
                          label="Password"
                          placeholder="Password"
                          type="password"
                          required
                          autoComplete="off"
                          showCopy
                        />
                      </Box>
                    </Flex>
                  </Form>
                </>
              );
            }}
          </Formik>
          <Box onClick={() => {}} background="#ECFFE5" borderRadius="1px" py="10px" cursor="pointer" width="156px">
            <Text textAlign="center" font="smSemibold" color="primary700">
              Reset Password
            </Text>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default EditLoginDetails;
