import React from "react";
import { Form, Formik } from "formik";
import { Badge, Box, Button, Flex, Icon, Text } from "organic";
import { FormInput } from "components/inputs";
import { PageHeader, PageWrapper, Panel } from "components/layout";
import { useNavigate, useParams } from "react-router-dom";
import { EmptyState } from "components/appState";
import { appGet } from "utils";
import {
  useCreateTradeReconciliation,
  useGetActualTradeByTradeId,
  useGetSingleTradeByTradeId,
} from "hooks/app/marketplace";
import { CostPriceField } from "../tradeForm/costPriceField";
import { SellingPriceInput } from "../tradeForm/sellingPriceInput";
import { AnticipatedProfitField } from "../tradeForm/anticipatedProfitField";
import { ProfitMarginField } from "../tradeForm/profitMarginField";
import { PriceToPayInput } from "../tradeForm/priceToPayInput";
import { CommissionFeeInput } from "../tradeForm/commissionFeeInput";
import { handleFormSubmission } from "services/formSubmissionService";
import { ActualLogisticsTypeField } from "../tradeForm/actualLogisticsTypeField";
import numeral from "numeral";
import { createActualTradeValidationSchema } from "validators/marketplaceValidatorSchemas";
import { ActualQuantitySourcedField } from "../tradeForm/actualQuantitySourced";
import { ActualQuantityRejectedField } from "../tradeForm/actualQuantityRejected";
import { ActualTradeRevenueField } from "../tradeForm/actualTradeRevenueField";
import ActualTargetQuantityInput from "../tradeForm/actualTargetQuantity";
import { ActualTotalInvestmentField } from "../tradeForm/actualTotalInvestment";

const getInitialActualTradeValues = trade => {
  if (!trade) return null;
  // Initial values for the logisticsTypes array
  return {
    trade_id: appGet(trade, "trade_id", ""),
    initiated_by: appGet(trade, "initiated_by", ""),
    approved_by: appGet(trade, "approved_by", ""),
    crop_type: appGet(trade, "crop_type", ""),
    cost_price: numeral(appGet(trade, "cost_price", "")).format("0.00"),
    selling_price: numeral(appGet(trade, "selling_price", "")).format("0.00"),
    anticipated_profit: numeral(appGet(trade, "anticipated_profit", "")).format("0.00"),
    profit_margin: numeral(appGet(trade, "profit_margin", "")).format("0.00"),
    logistics_type: appGet(trade, "logistics_type", []).map(type => ({
      name: appGet(type, "name", ""),
      unit: numeral(appGet(type, "unit", "")).format("0.00"),
      price_per_unit: numeral(appGet(type, "price_per_unit", "")).format("0.00"),
      amount: numeral(appGet(type, "amount", "")).format("0.00"),
      logistics_date: appGet(type, "logistics_date", new Date().toISOString()),
    })),
    logistics_cost: numeral(appGet(trade, "logistics_cost", "")).format("0.00"),
    target_quantity: numeral(appGet(trade, "target_quantity", "")).format("0.00"),
    actual_quantity_sourced: appGet(trade, "actual_quantity_sourced", ""),
    actual_quantity_rejected: appGet(trade, "actual_quantity_rejected", ""),
    price_to_pay: numeral(appGet(trade, "price_to_pay", "")).format("0.00"),
    commission_fee: numeral(appGet(trade, "commission_fee", "")).format("0.00"),
    total_investment: numeral(appGet(trade, "total_investment", "")).format("0.00"),
    // trade_finance_fee: appGet(trade, "trade_finance_fee", ""),
    total_trade_price: numeral(appGet(trade, "trade_finance_fee", "")).format("0.00"),
    currency: appGet(trade, "currency", ""),
    status: appGet(trade, "status", "finalized"),
  };
};

const CreateActualTrade = () => {
  const navigate = useNavigate();

  const { tradeId } = useParams();

  const { data: trade, isLoading } = useGetSingleTradeByTradeId({
    params: {
      tradeId,
    },
  });

  const { data: actualTrade } = useGetActualTradeByTradeId({ params: { id: tradeId } });

  const { mutateAsync } = useCreateTradeReconciliation();

  const initialValues = getInitialActualTradeValues(trade);

  const onSubmit = async (values, __formOptions) => {
    const _values = {
      trade_id: values.trade_id,
      initiated_by: values.initiated_by,
      approved_by: values.approved_by,
      crop_type: values.crop_type,
      cost_price: numeral(values.cost_price).value().toFixed(2),
      selling_price: numeral(values.selling_price).value().toFixed(2),
      anticipated_profit: numeral(values.anticipated_profit).value().toFixed(2),
      profit_margin: numeral(values.profit_margin).value().toFixed(2),
      logistics_type: values.logistics_type,
      logistics_cost: numeral(values.logistics_cost).value().toFixed(2),
      target_quantity: numeral(values.target_quantity).value().toFixed(2),
      actual_quantity_sourced: numeral(values.actual_quantity_sourced).value().toFixed(2),
      actual_quantity_rejected: numeral(values.actual_quantity_rejected).value().toFixed(2),
      price_to_pay: numeral(values.price_to_pay).value().toFixed(2),
      commission_fee: numeral(values.commission_fee).value().toFixed(2),
      total_investment: numeral(values.total_investment).value().toFixed(2),
      currency: values.currency,
    };
    await handleFormSubmission({
      submitFn: mutateAsync,
      values: _values,
      errorProps: {},
      errorMsg: "failed to reconcile trade, kindly check form and try again",
      successMsg: "trade reconcilled successfully",
      onSuccess() {
        navigate("/dashboard/marketplace/marketplaceReconciliation");
      },
    });
  };

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > TradeReconcilliation" subtitle="Your trade summary and activity." />
      </Flex>
      <Panel
        headerElement={
          <Flex alignItems="center" gap="1rem">
            <Button
              type="button"
              variant="unstyled"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Icon icon="arrow-left" color="gray700" size={20} />
            </Button>
            <Text as="span" font="lgMedium" color="gray900">
              Create Actual Trade
            </Text>
            <Badge as="span" colorScheme="blue">
              Reconciling {isLoading ? "Loading Trade Details..." : `ID ${tradeId}`}
            </Badge>
          </Flex>
        }
      >
        <Box px="2rem">
          {initialValues ? (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={createActualTradeValidationSchema}
            >
              {({ isSubmitting, values, errors }) => {
                console.log({ values, errors });
                return (
                  <Box>
                    <Form>
                      <Box>
                        <Flex flexDirection="column" width="100%" gap="1rem">
                          <Box width="100%">
                            <FormInput name="trade_id" label="Trade Ref ID " disabled />
                            <Text>{actualTrade?.trade_id ? "The Trade has already been reconciled" : ""}</Text>
                          </Box>

                          <Flex width="100%" gap="1rem">
                            <Box width="50%">
                              <FormInput name="crop_type" required label="Crop Type " disabled />
                            </Box>
                            <Box width="50%">
                              <CostPriceField />
                            </Box>
                          </Flex>
                          <Flex width="100%" gap="1rem">
                            <Box width="50%">
                              <AnticipatedProfitField />
                            </Box>
                            <Box width="50%">
                              <SellingPriceInput />
                            </Box>
                          </Flex>
                          <Flex>
                            <Box width="50%">
                              <ProfitMarginField />
                            </Box>
                          </Flex>
                          {/* logistics type */}
                          <ActualLogisticsTypeField />

                          <Flex width="100%" gap="1rem">
                            <Box width="50%">
                              <ActualQuantitySourcedField />
                            </Box>

                            <Box width="50%">
                              <ActualQuantityRejectedField />
                            </Box>
                          </Flex>
                          <Flex width="100%" gap="1rem">
                            <Box width="50%">
                              <ActualTargetQuantityInput />
                            </Box>
                            <Box width="50%">
                              <CommissionFeeInput />
                            </Box>
                          </Flex>
                          <Flex width="100%" gap="1rem">
                            <Box width="50%">
                              <PriceToPayInput />
                            </Box>
                            <Box width="50%">
                              <ActualTradeRevenueField />
                            </Box>
                          </Flex>

                          <Box width="100%">
                            <ActualTotalInvestmentField />
                          </Box>
                        </Flex>

                        <Flex gap="20px" mt="2rem">
                          <Button
                            size="lg"
                            display="block"
                            type="button"
                            colorScheme="secondaryGray"
                            onClick={() => navigate(-1)}
                            disabled={isSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button size="lg" display="block" type="submit" disabled={isSubmitting}>
                            Create Actual Trade
                          </Button>
                        </Flex>
                      </Box>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          ) : (
            <EmptyState title="Loading... trade details" subtitle="kindly wait, this might take a few seconds" />
          )}
        </Box>
      </Panel>
    </PageWrapper>
  );
};

export default CreateActualTrade;
