import React, { useEffect, useMemo, useState } from "react";
import { PageWrapper } from "components/layout";
import { Badge, Flex, Text, badgeConstants } from "organic";
import { PageHeader } from "components/layout";
import { Table } from "components/table";
import { useDisclosure, useTable } from "hooks";
import { SummaryGrid } from "components/grid";
// import { ExportButton } from "components/export";
import { useGetTrades } from "hooks/app/marketplace";
import { appGet, calculateAverage, formatDate, sum } from "utils";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import { Modal, ModalContent } from "components/modal";
import CreateLogistics from "./logistics/createLogistics";
import { CropSelect } from "components/select";
// import logistics from "api/logistics";

const MarketplaceLogisticsPage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const ignoredgenerateInitials = name => {
    const names = name.split(" ");
    const initials = names.map(n => n.charAt(0).toUpperCase()).join("");
    return initials;
  };

  const navigate = useNavigate();
  // const { filterParams } = useDataFilter();

  // const { data, isLoading } = useGetLogistics({ params: filterParams });
  const { data, isLoading } = useGetTrades({ params: {} });
  console.log({ data });
  const [selectedCrop, setSelectedCrop] = useState(null);

  useEffect(() => {
    if (!data?.data) {
      setFilteredData([]);
      return;
    }

    if (!selectedCrop) {
      setFilteredData([]);
      return;
    }

    const filteredData = data.data.filter(trade => `${trade.crop_type}` === `${selectedCrop}`);

    setFilteredData(filteredData);
  }, [selectedCrop, data]);

  const totalUnit = useMemo(() => {
    let _totalUnit = numeral(0);

    if (data?.data && Array.isArray(data.data)) {
      data.data.forEach(item => {
        if (Array.isArray(item.logistics_type)) {
          item.logistics_type.forEach(logisticsType => {
            _totalUnit.add(parseInt(logisticsType.unit, 10) || 0);
          });
        }
      });
    }

    return _totalUnit.format("0,0");
  }, [data]);

  const averageCost = useMemo(() => {
    let allCosts = [];

    if (data?.data && Array.isArray(data.data)) {
      data.data.forEach(item => {
        if (Array.isArray(item.logistics_type)) {
          item.logistics_type.forEach(logisticsType => {
            allCosts.push(parseFloat(logisticsType.amount) || 0);
          });
        }
      });
    }

    const average = calculateAverage(allCosts);
    return numeral(average || 0).format("0,0");
  }, [data]);

  const totalCost = useMemo(() => {
    const _totalCost = numeral(
      (data?.data || []).reduce((total, item) => {
        if (Array.isArray(item.logistics_type)) {
          item.logistics_type.forEach(logisticsType => {
            total += parseFloat(logisticsType.amount) || 0;
          });
        }
        return total;
      }, 0)
    );

    return _totalCost.format("0,0");
  }, [data]);

  const { tableProps } = useTable({
    data: selectedCrop ? filteredData : data?.data || [],
    columns: [
      {
        Header: "ID",
        accessor: "trade_id",
      },
      {
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Logistics Type.",

        accessor: value => {
          const logisticsTypes = appGet(value, "logistics_type", []);
          const names = logisticsTypes?.map(item => item?.name);
          return names || [];
        },
        Cell: ({ value }) => (
          <>
            {value.map((name, index) => (
              <Badge key={index} colorScheme={badgeConstants.colorSchemes.GRAY}>
                {name}
              </Badge>
            ))}
          </>
        ),
      },
      {
        Header: "Unit",
        accessor: value => {
          const logisticsTypes = appGet(value, "logistics_type", []);
          const units = logisticsTypes?.map(item => item?.unit);
          return units || [];
        },
        Cell: ({ value }) => (
          <>
            {value.map((unit, index) => (
              <Badge key={index} colorScheme={badgeConstants.colorSchemes.GRAY}>
                {unit}
              </Badge>
            ))}
          </>
        ),
      },
      {
        Header: "Price/Unit",
        accessor: value => {
          const logisticsTypes = appGet(value, "logistics_type", []);
          const prices = logisticsTypes?.map(item => item?.price_per_unit);
          return prices || [];
        },
        Cell: ({ value }) => (
          <>
            {value.map((price, index) => (
              <Badge key={index} colorScheme={badgeConstants.colorSchemes.GRAY}>
                {price}
              </Badge>
            ))}
          </>
        ),
      },
      {
        Header: "Total (KES)",
        accessor: value => (
          <Text as="span" font="mdMedium" color="gray700">
            {numeral(sum(value?.logistics_type?.map?.(type => numeral(type?.amount || 0).value()))).format("0,0.00")}
          </Text>
        ),
      },
      {
        id: "created",
        Header: "Created",
        accessor: logistic => formatDate(logistic.created_at),
      },
      {
        id: "lastModified",
        Header: "Last Modified",
        accessor: logistic => formatDate(logistic.updated_at),
      },
    ],

    loading: isLoading,
    title: "All logistics",
    useServerPagination: true,
    noEditAction: true,
  });
  console.log("Table Props:", tableProps);
  const createLogisticsModal = useDisclosure();

  // const quickExportFunction = async () => {
  //   // api to be inserted here
  //   const data = (await logistics.get("", { params: tableProps.filterParams })).data;
  //   return data;
  // };
  // const advancedExportFunction = async ({ filterParams, columnWhitelist }) => {
  //   let params = { ...filterParams, columnWhitelist };
  //   // api to be inserted here
  //   const res = await logistics.get("", { params });
  //   return res;
  // };
  // // api to be inserted here
  // const getExportKeys = async () => {
  //   return await logistics.get("");
  // };

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > Logistics" subtitle="Your logistics summary and activity." />
        <Flex gap="1rem" alignItems="center">
          <CropSelect
            getOptionValue={option => option?.name}
            onChange={option => {
              console.log(option);
              setSelectedCrop(option);
            }}
          />
          {/* <ExportButton
          quickExportConfig={{
            apiCall: quickExportFunction,
          }}
          noAdvancedExport={true}
          /> */}
        </Flex>
      </Flex>

      <SummaryGrid
        data={[
          { title: "Total Unit (KG)", component: <Text font="displayXsBold">{totalUnit}</Text> },
          {
            title: "Average Logistic Cost",
            component: <Text font="displayXsBold">{averageCost}</Text>,
          },
          {
            title: "Total Logistic Cost",
            component: <Text font="displayXsBold">{totalCost}</Text>,
          },
        ]}
      />
      <Table
        {...tableProps}
        noSearch
        onViewAction={selectedAction => {
          navigate({ pathname: `${selectedAction.selected._id}`, search: "?mode=view" });
        }}
      />
      <Modal {...createLogisticsModal.modalProps}>
        <ModalContent title="Create New Logistics Type">
          <CreateLogistics modal={createLogisticsModal.modal} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export default MarketplaceLogisticsPage;
