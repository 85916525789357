import { useMutation } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import Onboarding_API from "services/onboardingApiService";

export function useChangeMemberToContractual(params) {
  return useMutation({
    mutationFn: values => {
      return Onboarding_API.changeMemberToContractual(values, params);
    },
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.onboarding.changeMemberToContractual);
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong coverting member farmer to contractual");
      bug.report(error);
    },
  });
}
