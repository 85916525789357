/* eslint-disable no-unused-vars */
import {
  getAllFarmerPaymentsWithParams,
  getFarmerPaymentDateRange,
  getFarmerPaymentsData,
  getFarmerPaymentsMeta,
  searchAllFarmerPaymentsWithParams,
} from "features/v1/farmerPayment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import useRiskSurveyTable from "../riskSurvey/useRiskSurveyTable";
import { Badge, Box, Flex, Icon, Text, badgeConstants } from "organic";
import { Table } from "components/table";
import { getRiskSurveyByIdMeta, getSurveyById } from "features/v1/riskManagement";

const RiskAgentFarmers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [activeTab, setActiveTab] = useState("agent");
  const farmerPaymentsData = useSelector(getFarmerPaymentsData);
  const farmerPaymentsMeta = useSelector(getFarmerPaymentsMeta);
  const riskSurveyByIdMeta = useSelector(getRiskSurveyByIdMeta);

  const { tableProps, selectedRow: selectedPayment } = useRiskSurveyTable(farmerPaymentsData, farmerPaymentsMeta);

  useEffect(() => {
    dispatch(getSurveyById(params.id));
  }, []);

  const getExtraActions = payment => {
    const paymentExtraActions = [{ label: "View", actionKey: "viewAgents" }];

    return paymentExtraActions;
  };

  const data = [
    {
      title: "Survey Name",
      value: riskSurveyByIdMeta?.surveyName || "-",
    },
    {
      title: "Responses",
      value: riskSurveyByIdMeta?.totalResponse || 0,
    },
    {
      title: "Validated Farmers",
      value: riskSurveyByIdMeta?.totalValidatedFarmers || 0,
    },
    {
      title: "Flagged Farmers",
      value: riskSurveyByIdMeta?.totalFlaggedFarmers || 0,
    },
  ];

  return (
    <Flex flexDirection="column">
      <Flex
        px="24px"
        py="28px"
        mb="2rem"
        alignItems="center"
        gap="0.8rem"
        backgroundColor="white"
        borderRadius="0.5rem"
        borderWidth="1px"
        borderColor="gray200"
        borderStyle="solid"
      >
        <Link to="/dashboard/risk">
          <Flex gap="0.8rem" alignItems="center">
            <Icon icon="arrow-left" color="#344054" size="20px" />
            <Text color="gray900" font="lgMedium">
              Back to Risk Survey
            </Text>
          </Flex>
        </Link>
        {/* <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>ID {params?.id}</Badge> */}
      </Flex>
      <div className="payments-summary">
        <div className="payments-summary__grid">
          {data.map((d, index) => {
            return (
              <div className="payments-summary__gridItem" key={index}>
                <div className="payments-summary__gridItem__Title">{d?.title}</div>
                <div className="payments-summary__gridItem__Value">{d?.value}</div>
              </div>
            );
          })}
        </div>
      </div>
      <Flex
        p="4px"
        mt="1.8rem"
        alignItems="center"
        gap="8px"
        backgroundColor="gray50"
        borderRadius="0.5rem"
        borderWidth="1px"
        borderColor="gray100"
        borderStyle="solid"
      >
        <Flex
          flex="1"
          bg={`${activeTab === "agent" ? "white" : "transparent"}`}
          borderRadius="6px"
          textAlign="center"
          boxShadow="xs"
        >
          <NavLink style={{ width: "100%", padding: "8px 12px" }} to={`/dashboard/risk/survey/${params.id}/agent`}>
            {({ isActive }) => {
              if (isActive) setActiveTab("agent");
              return (
                <Text color={`${activeTab === "agent" ? "gray700" : "gray500"}`} font="smMedium">
                  All Risk Agents
                </Text>
              );
            }}
          </NavLink>
        </Flex>
        <Flex
          flex="1"
          bg={`${activeTab === "farmer" ? "white" : "transparent"}`}
          borderRadius="6px"
          textAlign="center"
          boxShadow="xs"
        >
          <NavLink style={{ width: "100%", padding: "8px 12px" }} to={`/dashboard/risk/survey/${params.id}/farmer`}>
            {({ isActive }) => {
              if (isActive) setActiveTab("farmer");
              return (
                <Text color={`${activeTab === "farmer" ? "gray700" : "gray500"}`} font="smMedium">
                  All farmers
                </Text>
              );
            }}
          </NavLink>
        </Flex>
      </Flex>
      <Outlet />
    </Flex>
  );
};

export default RiskAgentFarmers;
