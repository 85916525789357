import styled from "styled-components/macro";
import { background, color } from "styled-system";
import shouldForwardProp from "@styled-system/should-forward-prop";

/**default gutter or grid gap values */

const Container = styled.div.withConfig({
  shouldForwardProp,
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  height: auto;
  ${props => `
  padding-right: ${props.gutters.right || "0px"};
  padding-left: ${props.gutters.left || "0px"};
  padding-top: ${props.gutters.top || "0px"};
  padding-bottom: ${props.gutters.bottom || "0px"};
 
  @media (min-width: ${props.theme.breakpoints.sm}) {
    max-width: ${props.theme.container.sm};
  }
  
  @media (min-width: ${props.theme.breakpoints.md}) {
    max-width: ${props.theme.container.md};
  }
  
  @media (min-width: ${props.theme.breakpoints.lg}) {
    max-width: ${props.theme.container.lg};
  }
  
  @media (min-width: ${props.theme.breakpoints.xl}) {
    max-width: ${props.theme.container.xl};
  }
  `}

  ${background}
  ${color}
`;

const defaultContainerProps = {
  gutters: {
    left: "1rem",
    right: "1rem",
    top: "0px",
    bottom: "0px",
  },
};

Container.defaultProps = defaultContainerProps;

export { Container };
export default Container;
