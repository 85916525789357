import React from "react";
import { tableConstants } from "./tableConstants";
import { DropdownItem } from "components/dropdown";
import { Button, Icon, Text } from "organic";
import { isFunction } from "utils";

const defaultActions = [
  {
    label: "View",
    icon: "external-link",
    actionKey: tableConstants.defaultActionKeys.VIEW_ACTION_KEY,
  },
  {
    label: "Edit",
    icon: "edit-3",
    actionKey: tableConstants.defaultActionKeys.EDIT_ACTION_KEY,
  },
  {
    label: "Delete",
    icon: "trash-2",
    actionKey: tableConstants.defaultActionKeys.DELETE_ACTION_KEY,
  },
];

const DefaultTableActions = ({ id, row, onSelect, canDelete, noView, noEdit }) => {
  const handleDefaultActionSelect = action => {
    onSelect({ selected: row, id, actionKey: action.actionKey });
  };

  const memoizedOrderedDefaultActions = React.useMemo(
    () =>
      defaultActions.filter(_action => {
        if (_action.actionKey === tableConstants.defaultActionKeys.DELETE_ACTION_KEY) {
          return canDelete === true;
        }
        if (_action.actionKey === tableConstants.defaultActionKeys.VIEW_ACTION_KEY) {
          return noView !== true;
        }
        if (_action.actionKey === tableConstants.defaultActionKeys.EDIT_ACTION_KEY) {
          return noEdit !== true;
        }
        return true;
      }),

    [canDelete, noEdit, noView]
  );

  return (
    <>
      {memoizedOrderedDefaultActions.map(action => (
        <DropdownItem key={`${action.actionKey}-${id}`} onSelect={() => handleDefaultActionSelect(action)}>
          {action.icon && <Icon icon={action.icon || "square"} size={16} />}
          <Text ml={`${action.icon ? "0.75rem" : "0rem"}`} as="span" font="smMedium" color="gray700">
            {action.label}
          </Text>
        </DropdownItem>
      ))}
    </>
  );
};

const defaultCreateAction = {
  label: "Create",
  icon: "plus",
  actionKey: tableConstants.actionKeys.CREATE_ACTION_KEY,
};

const CreateTableAction = ({ action = defaultCreateAction, onClick }) => {
  const memoizedCreateAction = React.useMemo(() => ({ ...defaultCreateAction, ...action }), [action]);

  const _handleAction = e => {
    e.preventDefault();
    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <Button type="button" onClick={_handleAction}>
      <Icon icon={memoizedCreateAction.icon} /> {memoizedCreateAction.label}
    </Button>
  );
};

export { DefaultTableActions, CreateTableAction };
