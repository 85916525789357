import {
  FARMER_ONBOARDING_QUESTIONNAIRE,
  FARM_MAPPING_QUESTIONNAIRE,
  FARM_MONITORING_QUESTIONNAIRE,
  HARVEST_QUESTIONNAIRE,
  PSYCHOMETRIC_TEST_QUESTIONNAIRE,
  SURVEY_QUESTIONNAIRE,
} from "utils/questionnaire-utils/questionnaire-utils";
import * as yup from "yup";

export const createQuestionnaireValidationSchema = yup.object().shape({
  name: yup.string().required("questionnaire name is required"),
  organization: yup.string(),
  psychometrics_status: yup.boolean(),
  season: yup.string().required("season is required"),
  farm_operation_category: yup.array().required("Farm Operation Categories are required"),
  category_type: yup.array().required("Farm type(s) is required"),
  model: yup
    .string()
    .required("questionnaire type is required")
    .oneOf([
      PSYCHOMETRIC_TEST_QUESTIONNAIRE,
      FARMER_ONBOARDING_QUESTIONNAIRE,
      SURVEY_QUESTIONNAIRE,
      FARM_MAPPING_QUESTIONNAIRE,
      HARVEST_QUESTIONNAIRE,
      FARM_MONITORING_QUESTIONNAIRE,
    ]),
  questionnaire_type: yup.string(),
  score_per_question: yup.number(),
  cut_off_mark: yup.number(),
  category_crop_type: yup.string(),
  status: yup.string().required().oneOf(["active", "inactive"]),
  questions: yup.array().of(yup.object().shape({})),
  // prices: yup.object(),
});

export const questionsValidationSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question_title: yup.string().required("question title is required"),
      answer_options: yup.array().of(yup.string()),
      correct_answer: yup.array().of(yup.string()),
      question_type: yup.string().required("question type is required"),
      isCompulsory: yup.boolean().required("kindly select a value for compulsory"),
      maxLength: yup.number("max length must should be a numeric value").required("max length is required"),
      minLength: yup.number("minimum length of characters must should be a numeric value"),
      keyboardType: yup.string(),
    })
  ),
});

const singleQuestionValidationSchema = yup.object().shape({
  question_title: yup.string().required("question title is required"),
  answer_options: yup.array().of(yup.string()),
  correct_answer: yup.array().of(yup.string()),
  question_type: yup.string().required("question type is required"),
  isCompulsory: yup.boolean().required("kindly select a value for compulsory"),
  maxLength: yup.number("max length must should be a numeric value").required("max length is required"),
  minLength: yup.number("minimum length of characters must should be a numeric value"),
  keyboardType: yup.string(),
});

export const v2QuestionnaireValidationSchema = yup.object().shape({
  questionnaireDetails: yup.object().shape({
    name: yup.string().required("questionnaire name is required"),
    organization: yup.string(),
    psychometrics_status: yup.boolean(),
    season: yup.string().required("season is required"),
    farm_operation_category: yup.array().required("Farm Operation Categories are required"),
    category_type: yup.array().required("Farm type(s) is required"),
    model: yup
      .string()
      .required("questionnaire type is required")
      .oneOf([
        PSYCHOMETRIC_TEST_QUESTIONNAIRE,
        FARMER_ONBOARDING_QUESTIONNAIRE,
        SURVEY_QUESTIONNAIRE,
        FARM_MAPPING_QUESTIONNAIRE,
        HARVEST_QUESTIONNAIRE,
      ]),
    score_per_question: yup.number(),
    cut_off_mark: yup.number(),
    category_crop_type: yup.string(),
    status: yup.string().required().oneOf(["active", "inactive"]),
  }),
  defaultQuestions: yup.array().of(singleQuestionValidationSchema),
  existingQuestions: yup.array().of(singleQuestionValidationSchema),
  newQuestions: yup.array().of(singleQuestionValidationSchema),
  // questions: yup.array().of(
  //   yup.object().shape({
  //     question_title: yup.string().required("question title is required"),
  //     answer_options: yup.array().of(yup.string()),
  //     correct_answer: yup.array().of(yup.string()),
  //     question_type: yup.string().required("question type is required"),
  //     isCompulsory: yup.boolean().required("kindly select a value for compulsory"),
  //     maxLength: yup.number("max length must should be a numeric value").required("max length is required"),
  //     keyboardType: yup.string(),
  //   })
  // ),
});
