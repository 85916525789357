import { default as lodashIsFunction } from "lodash/isFunction";
import { default as _uniqueId } from "lodash/uniqueId";

const countryCurrencyMap = require("country-currency-map");

import { default as chunkArray } from "lodash/chunk";

export function isFunction(arg) {
  return lodashIsFunction(arg);
}

export function uniqueId() {
  return _uniqueId();
}

export function chunk(item, chunkSize) {
  return chunkArray(item, chunkSize);
}

export function runIfFn(fn, ...args) {
  if (isFunction(fn)) {
    fn(...args);
  }
}

export function noop() {}

// Function to get currency code by country name
export function getCurrencyCodeByCountryName(countryName) {
  const countryInfo = countryCurrencyMap.getCurrencyAbbreviation(countryName);
  if (countryInfo) {
    return countryInfo;
  } else {
    return null; // Country not found
  }
}

// Function to get currency code by country ISO code
export function getCurrencyCodeByCountryISOCode(countryISOCode) {
  const countryInfo = countryCurrencyMap.getCountryByAbbreviation(countryISOCode);
  if (countryInfo) {
    return countryInfo;
  } else {
    return null; // Country not found
  }
}

/** a HOFunction to conditionally execute the first arg
 * if the condition in the secondn argument is === true */
export function runIf(condition, fn) {
  return (...args) => {
    if (condition === true) {
      return fn(...args);
    }
  };
}
