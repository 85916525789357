import React, { useEffect, useRef } from "react";

import { useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import { DASHBOARD_ROUTE_LIST } from "routes/dashboardRoutes";
import { Icon, organicTheme } from "organic";
import { classnames } from "utils";
import { ScopedView, SidebarNav } from ".";
import { dashboardLayoutConstants, getSidebarExpanded } from "features/v1/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { Logo } from "components/common";

let ps;
export const SIDEBAR_EXPANDED_INNER_WIDTH = "263px";
export const SIDEBAR_COLLAPSED_INNER_WIDTH = "48px";

let SIDEBAR_COLLAPSED_ICON = "chevrons-right";
let SIDEBAR_EXPANDED_ICON = "chevrons-left";

const Sidebar = () => {
  const sidebarRef = useRef();

  let location = useLocation();
  let expanded = useSelector(getSidebarExpanded);
  const dispatch = useDispatch();

  let sidebarClassnames = classnames("sidebar", {
    "sidebar--expanded": expanded,
    "sidebar--collapsed": !expanded,
  });
  let sidebarTopClassnames = classnames("sidebar-top", {
    "sidebar-top--expanded": expanded,
    "sidebar-top--collapsed": !expanded,
  });
  let sidebarBottomClassnames = classnames("sidebar-bottom", {
    "sidebar-bottom--expanded": expanded,
    "sidebar-bottom--collapsed": !expanded,
  });

  let sidebarToggleIcon = expanded ? SIDEBAR_EXPANDED_ICON : SIDEBAR_COLLAPSED_ICON;

  const toggleExpanded = () => {
    dispatch({ type: dashboardLayoutConstants.actions.TOGGLE_SIDEBAR });
  };

  useEffect(() => {
    console.log({ location });
  }, [location]);

  useEffect(() => {
    ps = new PerfectScrollbar(sidebarRef.current, {
      suppressScrollX: true,
      suppressScrollY: false,
    });
    return ps.destroy();
  }, []);

  return (
    <div className={sidebarClassnames}>
      {/* sidebar brand */}
      <div className="sidebar-brand">
        <Logo variant={expanded ? "fullLogo" : "iconOnly"} />
        {/* <Image {...logoProps} alt="aosafrica logo" /> */}
        <button type="button" className="sidebar-brand__toggle" onClick={toggleExpanded}>
          <Icon icon={sidebarToggleIcon} iconProps={{ size: "24px", stroke: organicTheme.colors.gray900 }} />
        </button>
      </div>
      {/* sidebar top */}
      <div className={sidebarTopClassnames}>
        <div>
          <SidebarNav
            ref={sidebarRef}
            routes={DASHBOARD_ROUTE_LIST}
            filterRoutes={route => !route?.showAtBottom && !route?.ignoreChildren}
          />
          <SidebarNav
            ignoreChildren
            ref={sidebarRef}
            routes={DASHBOARD_ROUTE_LIST}
            filterRoutes={route => !route?.showAtBottom && route?.ignoreChildren}
          />
        </div>
      </div>
      {/* sidebar bottom */}
      <div className={sidebarBottomClassnames}>
        <ScopedView resource="all">
          <SidebarNav ignoreChildren filterRoutes={route => route?.showAtBottom} routes={DASHBOARD_ROUTE_LIST} />
        </ScopedView>
      </div>
    </div>
  );
};

export { Sidebar };
