import React from "react";
import { PulseLoader } from "react-spinners";
import { Button } from "reactstrap";

const CustomButton = ({
  className,
  smallRounded, // FIXME: temporary, until we have a standardised component library
  children,
  onClick = () => null,
  text,
  disabled,
  loading = false,
  ...props
}) => {
  const customButtonClassNames = `${
    smallRounded && "m-0 p-2  text-bold font-bold rounded-pill bg-success text-light"
  } ${className}`;

  const resolveBtnContent = btnText => {
    if (loading === false) {
      if (children) {
        return children;
      }
      return btnText || "Submit";
    }

    return <PulseLoader size={8} color={"#fff"} />;
  };

  return (
    <Button {...props} onClick={onClick} disabled={disabled} className={customButtonClassNames}>
      {resolveBtnContent(text)}
    </Button>
  );
};

export default CustomButton;
