const hectareSizeOptions = [
  { label: "0.1 ha", value: 0.1 },
  { label: "0.2 ha", value: 0.2 },
  { label: "0.3 ha", value: 0.3 },
  { label: "0.4 ha", value: 0.4 },
  { label: "0.5 ha", value: 0.5 },
  { label: "0.6 ha", value: 0.6 },
  { label: "0.7 ha", value: 0.7 },
  { label: "0.8 ha", value: 0.8 },
  { label: "0.9 ha", value: 0.9 },
  { label: "1 ha", value: 1 },
  { label: "1.1 ha", value: 1.1 },
  { label: "1.2 ha", value: 1.2 },
  { label: "1.3 ha", value: 1.3 },
  { label: "1.4 ha", value: 1.4 },
  { label: "1.5 ha", value: 1.5 },
  { label: "1.6 ha", value: 1.6 },
  { label: "1.7 ha", value: 1.7 },
  { label: "1.8 ha", value: 1.8 },
  { label: "1.9 ha", value: 1.9 },
  { label: "2 ha", value: 2 },
  { label: "2.1 ha", value: 2.1 },
  { label: "2.2 ha", value: 2.2 },
  { label: "2.3 ha", value: 2.3 },
  { label: "2.4 ha", value: 2.4 },
  { label: "2.5 ha", value: 2.5 },
  { label: "2.6 ha", value: 2.6 },
  { label: "2.7 ha", value: 2.7 },
  { label: "2.8 ha", value: 2.8 },
  { label: "2.9 ha", value: 2.9 },
  { label: "3 ha", value: 3 },
  { label: "3.1 ha", value: 3.1 },
  { label: "3.2 ha", value: 3.2 },
  { label: "3.3 ha", value: 3.3 },
  { label: "3.4 ha", value: 3.4 },
  { label: "3.5 ha", value: 3.5 },
  { label: "3.6 ha", value: 3.6 },
  { label: "3.7 ha", value: 3.7 },
  { label: "3.8 ha", value: 3.8 },
  { label: "3.9 ha", value: 3.9 },
  { label: "4 ha", value: 4 },
  { label: "4.1 ha", value: 4.1 },
  { label: "4.2 ha", value: 4.2 },
  { label: "4.3 ha", value: 4.3 },
  { label: "4.4 ha", value: 4.4 },
  { label: "4.5 ha", value: 4.5 },
  { label: "4.6 ha", value: 4.6 },
  { label: "4.7 ha", value: 4.7 },
  { label: "4.8 ha", value: 4.8 },
  { label: "4.9 ha", value: 4.9 },
  { label: "5 ha", value: 5 },
  { label: "5.1 ha", value: 5.1 },
  { label: "5.2 ha", value: 5.2 },
  { label: "5.3 ha", value: 5.3 },
  { label: "5.4 ha", value: 5.4 },
  { label: "5.5 ha", value: 5.5 },
  { label: "5.6 ha", value: 5.6 },
  { label: "5.7 ha", value: 5.7 },
  { label: "5.8 ha", value: 5.8 },
  { label: "5.9 ha", value: 5.9 },
  { label: "6 ha", value: 6 },
  { label: "6.1 ha", value: 6.1 },
  { label: "6.2 ha", value: 6.2 },
  { label: "6.3 ha", value: 6.3 },
  { label: "6.4 ha", value: 6.4 },
  { label: "6.5 ha", value: 6.5 },
  { label: "6.6 ha", value: 6.6 },
  { label: "6.7 ha", value: 6.7 },
  { label: "6.8 ha", value: 6.8 },
  { label: "6.9 ha", value: 6.9 },
  { label: "7 ha", value: 7 },
  { label: "7.1 ha", value: 7.1 },
  { label: "7.2 ha", value: 7.2 },
  { label: "7.3 ha", value: 7.3 },
  { label: "7.4 ha", value: 7.4 },
  { label: "7.5 ha", value: 7.5 },
  { label: "7.6 ha", value: 7.6 },
  { label: "7.7 ha", value: 7.7 },
  { label: "7.8 ha", value: 7.8 },
  { label: "7.9 ha", value: 7.9 },
  { label: "8 ha", value: 8 },
  { label: "8.1 ha", value: 8.1 },
  { label: "8.2 ha", value: 8.2 },
  { label: "8.3 ha", value: 8.3 },
  { label: "8.4 ha", value: 8.4 },
  { label: "8.5 ha", value: 8.5 },
  { label: "8.6 ha", value: 8.6 },
  { label: "8.7 ha", value: 8.7 },
  { label: "8.8 ha", value: 8.8 },
  { label: "8.9 ha", value: 8.9 },
  { label: "9 ha", value: 9 },
  { label: "9.1 ha", value: 9.1 },
  { label: "9.2 ha", value: 9.2 },
  { label: "9.3 ha", value: 9.3 },
  { label: "9.4 ha", value: 9.4 },
  { label: "9.5 ha", value: 9.5 },
  { label: "9.6 ha", value: 9.6 },
  { label: "9.7 ha", value: 9.7 },
  { label: "9.8 ha", value: 9.8 },
  { label: "9.9 ha", value: 9.9 },
  { label: "10 ha", value: 10 },
  { label: "10.1 ha", value: 10.1 },
  { label: "10.2 ha", value: 10.2 },
  { label: "10.3 ha", value: 10.3 },
  { label: "10.4 ha", value: 10.4 },
  { label: "10.5 ha", value: 10.5 },
  { label: "10.6 ha", value: 10.6 },
  { label: "10.7 ha", value: 10.7 },
  { label: "10.8 ha", value: 10.8 },
  { label: "10.9 ha", value: 10.9 },
  { label: "11 ha", value: 11 },
  { label: "11.1 ha", value: 11.1 },
  { label: "11.2 ha", value: 11.2 },
  { label: "11.3 ha", value: 11.3 },
  { label: "11.4 ha", value: 11.4 },
  { label: "11.5 ha", value: 11.5 },
  { label: "11.6 ha", value: 11.6 },
  { label: "11.7 ha", value: 11.7 },
  { label: "11.8 ha", value: 11.8 },
  { label: "11.9 ha", value: 11.9 },
  { label: "12 ha", value: 12 },
  { label: "12.1 ha", value: 12.1 },
  { label: "12.2 ha", value: 12.2 },
  { label: "12.3 ha", value: 12.3 },
  { label: "12.4 ha", value: 12.4 },
  { label: "12.5 ha", value: 12.5 },
  { label: "12.6 ha", value: 12.6 },
  { label: "12.7 ha", value: 12.7 },
  { label: "12.8 ha", value: 12.8 },
  { label: "12.9 ha", value: 12.9 },
  { label: "13 ha", value: 13 },
  { label: "13.1 ha", value: 13.1 },
  { label: "13.2 ha", value: 13.2 },
  { label: "13.3 ha", value: 13.3 },
  { label: "13.4 ha", value: 13.4 },
  { label: "13.5 ha", value: 13.5 },
  { label: "13.6 ha", value: 13.6 },
  { label: "13.7 ha", value: 13.7 },
  { label: "13.8 ha", value: 13.8 },
  { label: "13.9 ha", value: 13.9 },
  { label: "14 ha", value: 14 },
  { label: "14.1 ha", value: 14.1 },
  { label: "14.2 ha", value: 14.2 },
  { label: "14.3 ha", value: 14.3 },
  { label: "14.4 ha", value: 14.4 },
  { label: "14.5 ha", value: 14.5 },
  { label: "14.6 ha", value: 14.6 },
  { label: "14.7 ha", value: 14.7 },
  { label: "14.8 ha", value: 14.8 },
  { label: "14.9 ha", value: 14.9 },
  { label: "15 ha", value: 15 },
  { label: "15.1 ha", value: 15.1 },
  { label: "15.2 ha", value: 15.2 },
  { label: "15.3 ha", value: 15.3 },
  { label: "15.4 ha", value: 15.4 },
  { label: "15.5 ha", value: 15.5 },
  { label: "15.6 ha", value: 15.6 },
  { label: "15.7 ha", value: 15.7 },
  { label: "15.8 ha", value: 15.8 },
  { label: "15.9 ha", value: 15.9 },
  { label: "16 ha", value: 16 },
  { label: "16.1 ha", value: 16.1 },
  { label: "16.2 ha", value: 16.2 },
  { label: "16.3 ha", value: 16.3 },
  { label: "16.4 ha", value: 16.4 },
  { label: "16.5 ha", value: 16.5 },
  { label: "16.6 ha", value: 16.6 },
  { label: "16.7 ha", value: 16.7 },
  { label: "16.8 ha", value: 16.8 },
  { label: "16.9 ha", value: 16.9 },
  { label: "17 ha", value: 17 },
  { label: "17.1 ha", value: 17.1 },
  { label: "17.2 ha", value: 17.2 },
  { label: "17.3 ha", value: 17.3 },
  { label: "17.4 ha", value: 17.4 },
  { label: "17.5 ha", value: 17.5 },
  { label: "17.6 ha", value: 17.6 },
  { label: "17.7 ha", value: 17.7 },
  { label: "17.8 ha", value: 17.8 },
  { label: "17.9 ha", value: 17.9 },
  { label: "18 ha", value: 18 },
  { label: "18.1 ha", value: 18.1 },
  { label: "18.2 ha", value: 18.2 },
  { label: "18.3 ha", value: 18.3 },
  { label: "18.4 ha", value: 18.4 },
  { label: "18.5 ha", value: 18.5 },
  { label: "18.6 ha", value: 18.6 },
  { label: "18.7 ha", value: 18.7 },
  { label: "18.8 ha", value: 18.8 },
  { label: "18.9 ha", value: 18.9 },
  { label: "19 ha", value: 19 },
  { label: "19.1 ha", value: 19.1 },
  { label: "19.2 ha", value: 19.2 },
  { label: "19.3 ha", value: 19.3 },
  { label: "19.4 ha", value: 19.4 },
  { label: "19.5 ha", value: 19.5 },
  { label: "19.6 ha", value: 19.6 },
  { label: "19.7 ha", value: 19.7 },
  { label: "19.8 ha", value: 19.8 },
  { label: "19.9 ha", value: 19.9 },
  { label: "20 ha", value: 20 },
  { label: "20.1 ha", value: 20.1 },
  { label: "20.2 ha", value: 20.2 },
  { label: "20.3 ha", value: 20.3 },
  { label: "20.4 ha", value: 20.4 },
  { label: "20.5 ha", value: 20.5 },
  { label: "20.6 ha", value: 20.6 },
  { label: "20.7 ha", value: 20.7 },
  { label: "20.8 ha", value: 20.8 },
  { label: "20.9 ha", value: 20.9 },
  { label: "21 ha", value: 21 },
  { label: "21.1 ha", value: 21.1 },
  { label: "21.2 ha", value: 21.2 },
  { label: "21.3 ha", value: 21.3 },
  { label: "21.4 ha", value: 21.4 },
  { label: "21.5 ha", value: 21.5 },
  { label: "21.6 ha", value: 21.6 },
  { label: "21.7 ha", value: 21.7 },
  { label: "21.8 ha", value: 21.8 },
  { label: "21.9 ha", value: 21.9 },
  { label: "22 ha", value: 22 },
  { label: "22.1 ha", value: 22.1 },
  { label: "22.2 ha", value: 22.2 },
  { label: "22.3 ha", value: 22.3 },
  { label: "22.4 ha", value: 22.4 },
  { label: "22.5 ha", value: 22.5 },
  { label: "22.6 ha", value: 22.6 },
  { label: "22.7 ha", value: 22.7 },
  { label: "22.8 ha", value: 22.8 },
  { label: "22.9 ha", value: 22.9 },
  { label: "23 ha", value: 23 },
  { label: "23.1 ha", value: 23.1 },
  { label: "23.2 ha", value: 23.2 },
  { label: "23.3 ha", value: 23.3 },
  { label: "23.4 ha", value: 23.4 },
  { label: "23.5 ha", value: 23.5 },
  { label: "23.6 ha", value: 23.6 },
  { label: "23.7 ha", value: 23.7 },
  { label: "23.8 ha", value: 23.8 },
  { label: "23.9 ha", value: 23.9 },
  { label: "24 ha", value: 24 },
  { label: "24.1 ha", value: 24.1 },
  { label: "24.2 ha", value: 24.2 },
  { label: "24.3 ha", value: 24.3 },
  { label: "24.4 ha", value: 24.4 },
  { label: "24.5 ha", value: 24.5 },
  { label: "24.6 ha", value: 24.6 },
  { label: "24.7 ha", value: 24.7 },
  { label: "24.8 ha", value: 24.8 },
  { label: "24.9 ha", value: 24.9 },
  { label: "25 ha", value: 25 },
  { label: "25.1 ha", value: 25.1 },
  { label: "25.2 ha", value: 25.2 },
  { label: "25.3 ha", value: 25.3 },
  { label: "25.4 ha", value: 25.4 },
  { label: "25.5 ha", value: 25.5 },
  { label: "25.6 ha", value: 25.6 },
  { label: "25.7 ha", value: 25.7 },
  { label: "25.8 ha", value: 25.8 },
  { label: "25.9 ha", value: 25.9 },
  { label: "26 ha", value: 26 },
  { label: "26.1 ha", value: 26.1 },
  { label: "26.2 ha", value: 26.2 },
  { label: "26.3 ha", value: 26.3 },
  { label: "26.4 ha", value: 26.4 },
  { label: "26.5 ha", value: 26.5 },
  { label: "26.6 ha", value: 26.6 },
  { label: "26.7 ha", value: 26.7 },
  { label: "26.8 ha", value: 26.8 },
  { label: "26.9 ha", value: 26.9 },
  { label: "27 ha", value: 27 },
  { label: "27.1 ha", value: 27.1 },
  { label: "27.2 ha", value: 27.2 },
  { label: "27.3 ha", value: 27.3 },
  { label: "27.4 ha", value: 27.4 },
  { label: "27.5 ha", value: 27.5 },
  { label: "27.6 ha", value: 27.6 },
  { label: "27.7 ha", value: 27.7 },
  { label: "27.8 ha", value: 27.8 },
  { label: "27.9 ha", value: 27.9 },
  { label: "28 ha", value: 28 },
  { label: "28.1 ha", value: 28.1 },
  { label: "28.2 ha", value: 28.2 },
  { label: "28.3 ha", value: 28.3 },
  { label: "28.4 ha", value: 28.4 },
  { label: "28.5 ha", value: 28.5 },
  { label: "28.6 ha", value: 28.6 },
  { label: "28.7 ha", value: 28.7 },
  { label: "28.8 ha", value: 28.8 },
  { label: "28.9 ha", value: 28.9 },
  { label: "29 ha", value: 29 },
  { label: "29.1 ha", value: 29.1 },
  { label: "29.2 ha", value: 29.2 },
  { label: "29.3 ha", value: 29.3 },
  { label: "29.4 ha", value: 29.4 },
  { label: "29.5 ha", value: 29.5 },
  { label: "29.6 ha", value: 29.6 },
  { label: "29.7 ha", value: 29.7 },
  { label: "29.8 ha", value: 29.8 },
  { label: "29.9 ha", value: 29.9 },
  { label: "30 ha", value: 30 },
  { label: "30.1 ha", value: 30.1 },
  { label: "30.2 ha", value: 30.2 },
  { label: "30.3 ha", value: 30.3 },
  { label: "30.4 ha", value: 30.4 },
  { label: "30.5 ha", value: 30.5 },
  { label: "30.6 ha", value: 30.6 },
  { label: "30.7 ha", value: 30.7 },
  { label: "30.8 ha", value: 30.8 },
  { label: "30.9 ha", value: 30.9 },
  { label: "31 ha", value: 31 },
  { label: "31.1 ha", value: 31.1 },
  { label: "31.2 ha", value: 31.2 },
  { label: "31.3 ha", value: 31.3 },
  { label: "31.4 ha", value: 31.4 },
  { label: "31.5 ha", value: 31.5 },
  { label: "31.6 ha", value: 31.6 },
  { label: "31.7 ha", value: 31.7 },
  { label: "31.8 ha", value: 31.8 },
  { label: "31.9 ha", value: 31.9 },
  { label: "32 ha", value: 32 },
  { label: "32.1 ha", value: 32.1 },
  { label: "32.2 ha", value: 32.2 },
  { label: "32.3 ha", value: 32.3 },
  { label: "32.4 ha", value: 32.4 },
  { label: "32.5 ha", value: 32.5 },
  { label: "32.6 ha", value: 32.6 },
  { label: "32.7 ha", value: 32.7 },
  { label: "32.8 ha", value: 32.8 },
  { label: "32.9 ha", value: 32.9 },
  { label: "33 ha", value: 33 },
  { label: "33.1 ha", value: 33.1 },
  { label: "33.2 ha", value: 33.2 },
  { label: "33.3 ha", value: 33.3 },
  { label: "33.4 ha", value: 33.4 },
  { label: "33.5 ha", value: 33.5 },
  { label: "33.6 ha", value: 33.6 },
  { label: "33.7 ha", value: 33.7 },
  { label: "33.8 ha", value: 33.8 },
  { label: "33.9 ha", value: 33.9 },
  { label: "34 ha", value: 34 },
  { label: "34.1 ha", value: 34.1 },
  { label: "34.2 ha", value: 34.2 },
  { label: "34.3 ha", value: 34.3 },
  { label: "34.4 ha", value: 34.4 },
  { label: "34.5 ha", value: 34.5 },
  { label: "34.6 ha", value: 34.6 },
  { label: "34.7 ha", value: 34.7 },
  { label: "34.8 ha", value: 34.8 },
  { label: "34.9 ha", value: 34.9 },
  { label: "35 ha", value: 35 },
  { label: "35.1 ha", value: 35.1 },
  { label: "35.2 ha", value: 35.2 },
  { label: "35.3 ha", value: 35.3 },
  { label: "35.4 ha", value: 35.4 },
  { label: "35.5 ha", value: 35.5 },
  { label: "35.6 ha", value: 35.6 },
  { label: "35.7 ha", value: 35.7 },
  { label: "35.8 ha", value: 35.8 },
  { label: "35.9 ha", value: 35.9 },
  { label: "36 ha", value: 36 },
  { label: "36.1 ha", value: 36.1 },
  { label: "36.2 ha", value: 36.2 },
  { label: "36.3 ha", value: 36.3 },
  { label: "36.4 ha", value: 36.4 },
  { label: "36.5 ha", value: 36.5 },
  { label: "36.6 ha", value: 36.6 },
  { label: "36.7 ha", value: 36.7 },
  { label: "36.8 ha", value: 36.8 },
  { label: "36.9 ha", value: 36.9 },
  { label: "37 ha", value: 37 },
  { label: "37.1 ha", value: 37.1 },
  { label: "37.2 ha", value: 37.2 },
  { label: "37.3 ha", value: 37.3 },
  { label: "37.4 ha", value: 37.4 },
  { label: "37.5 ha", value: 37.5 },
  { label: "37.6 ha", value: 37.6 },
  { label: "37.7 ha", value: 37.7 },
  { label: "37.8 ha", value: 37.8 },
  { label: "37.9 ha", value: 37.9 },
  { label: "38 ha", value: 38 },
  { label: "38.1 ha", value: 38.1 },
  { label: "38.2 ha", value: 38.2 },
  { label: "38.3 ha", value: 38.3 },
  { label: "38.4 ha", value: 38.4 },
  { label: "38.5 ha", value: 38.5 },
  { label: "38.6 ha", value: 38.6 },
  { label: "38.7 ha", value: 38.7 },
  { label: "38.8 ha", value: 38.8 },
  { label: "38.9 ha", value: 38.9 },
  { label: "39 ha", value: 39 },
  { label: "39.1 ha", value: 39.1 },
  { label: "39.2 ha", value: 39.2 },
  { label: "39.3 ha", value: 39.3 },
  { label: "39.4 ha", value: 39.4 },
  { label: "39.5 ha", value: 39.5 },
  { label: "39.6 ha", value: 39.6 },
  { label: "39.7 ha", value: 39.7 },
  { label: "39.8 ha", value: 39.8 },
  { label: "39.9 ha", value: 39.9 },
  { label: "40 ha", value: 40 },
  { label: "40.1 ha", value: 40.1 },
  { label: "40.2 ha", value: 40.2 },
  { label: "40.3 ha", value: 40.3 },
  { label: "40.4 ha", value: 40.4 },
  { label: "40.5 ha", value: 40.5 },
  { label: "40.6 ha", value: 40.6 },
  { label: "40.7 ha", value: 40.7 },
  { label: "40.8 ha", value: 40.8 },
  { label: "40.9 ha", value: 40.9 },
  { label: "41 ha", value: 41 },
  { label: "41.1 ha", value: 41.1 },
  { label: "41.2 ha", value: 41.2 },
  { label: "41.3 ha", value: 41.3 },
  { label: "41.4 ha", value: 41.4 },
  { label: "41.5 ha", value: 41.5 },
  { label: "41.6 ha", value: 41.6 },
  { label: "41.7 ha", value: 41.7 },
  { label: "41.8 ha", value: 41.8 },
  { label: "41.9 ha", value: 41.9 },
  { label: "42 ha", value: 42 },
  { label: "42.1 ha", value: 42.1 },
  { label: "42.2 ha", value: 42.2 },
  { label: "42.3 ha", value: 42.3 },
  { label: "42.4 ha", value: 42.4 },
  { label: "42.5 ha", value: 42.5 },
  { label: "42.6 ha", value: 42.6 },
  { label: "42.7 ha", value: 42.7 },
  { label: "42.8 ha", value: 42.8 },
  { label: "42.9 ha", value: 42.9 },
  { label: "43 ha", value: 43 },
  { label: "43.1 ha", value: 43.1 },
  { label: "43.2 ha", value: 43.2 },
  { label: "43.3 ha", value: 43.3 },
  { label: "43.4 ha", value: 43.4 },
  { label: "43.5 ha", value: 43.5 },
  { label: "43.6 ha", value: 43.6 },
  { label: "43.7 ha", value: 43.7 },
  { label: "43.8 ha", value: 43.8 },
  { label: "43.9 ha", value: 43.9 },
  { label: "44 ha", value: 44 },
  { label: "44.1 ha", value: 44.1 },
  { label: "44.2 ha", value: 44.2 },
  { label: "44.3 ha", value: 44.3 },
  { label: "44.4 ha", value: 44.4 },
  { label: "44.5 ha", value: 44.5 },
  { label: "44.6 ha", value: 44.6 },
  { label: "44.7 ha", value: 44.7 },
  { label: "44.8 ha", value: 44.8 },
  { label: "44.9 ha", value: 44.9 },
  { label: "45 ha", value: 45 },
  { label: "45.1 ha", value: 45.1 },
  { label: "45.2 ha", value: 45.2 },
  { label: "45.3 ha", value: 45.3 },
  { label: "45.4 ha", value: 45.4 },
  { label: "45.5 ha", value: 45.5 },
  { label: "45.6 ha", value: 45.6 },
  { label: "45.7 ha", value: 45.7 },
  { label: "45.8 ha", value: 45.8 },
  { label: "45.9 ha", value: 45.9 },
  { label: "46 ha", value: 46 },
  { label: "46.1 ha", value: 46.1 },
  { label: "46.2 ha", value: 46.2 },
  { label: "46.3 ha", value: 46.3 },
  { label: "46.4 ha", value: 46.4 },
  { label: "46.5 ha", value: 46.5 },
  { label: "46.6 ha", value: 46.6 },
  { label: "46.7 ha", value: 46.7 },
  { label: "46.8 ha", value: 46.8 },
  { label: "46.9 ha", value: 46.9 },
  { label: "47 ha", value: 47 },
  { label: "47.1 ha", value: 47.1 },
  { label: "47.2 ha", value: 47.2 },
  { label: "47.3 ha", value: 47.3 },
  { label: "47.4 ha", value: 47.4 },
  { label: "47.5 ha", value: 47.5 },
  { label: "47.6 ha", value: 47.6 },
  { label: "47.7 ha", value: 47.7 },
  { label: "47.8 ha", value: 47.8 },
  { label: "47.9 ha", value: 47.9 },
  { label: "48 ha", value: 48 },
  { label: "48.1 ha", value: 48.1 },
  { label: "48.2 ha", value: 48.2 },
  { label: "48.3 ha", value: 48.3 },
  { label: "48.4 ha", value: 48.4 },
  { label: "48.5 ha", value: 48.5 },
  { label: "48.6 ha", value: 48.6 },
  { label: "48.7 ha", value: 48.7 },
  { label: "48.8 ha", value: 48.8 },
  { label: "48.9 ha", value: 48.9 },
  { label: "49 ha", value: 49 },
  { label: "49.1 ha", value: 49.1 },
  { label: "49.2 ha", value: 49.2 },
  { label: "49.3 ha", value: 49.3 },
  { label: "49.4 ha", value: 49.4 },
  { label: "49.5 ha", value: 49.5 },
  { label: "49.6 ha", value: 49.6 },
  { label: "49.7 ha", value: 49.7 },
  { label: "49.8 ha", value: 49.8 },
  { label: "49.9 ha", value: 49.9 },
  { label: "50 ha", value: 50 },
];

export default hectareSizeOptions;
