import {
  FARMER_ONBOARDING_QUESTIONNAIRE,
  FARM_MAPPING_QUESTIONNAIRE,
  HARVEST_QUESTIONNAIRE,
  PSYCHOMETRIC_TEST_QUESTIONNAIRE,
  SURVEY_QUESTIONNAIRE,
} from "utils/questionnaire-utils/questionnaire-utils";
import * as yup from "yup";

export const subscriptionPlanValidationSchema = yup.object().shape({
  user: yup.string(),
  // can be "Maize farm in Ijora Bunue for Dry season"
  subscription_plan_name: yup.string().required(),
  duration: yup.string(),
  // equity contribution amount per unit
  equity_contribution_amount: yup.string(),
  // the total worth in cash for stipulated(outgoing) inputs, which the farmer is expected to return after farming/plan cycle
  total_input_loan_amount: yup.string().isType(yup.number),
  subscription_plan_inputs: yup.array().of(
    yup.object().shape({
      // the brand name on the input/ vendor of the input
      input_name: yup.string().required(),
      // e.g seed, fertilizer, drone service, water service, tractors, labour service e.t.c
      input_type: yup.string().required(),
      // the quantity that was given out to farmer as loan
      input_quantity: yup.string().required(),
      // the unit of measurement
      input_unit_measurement: yup.string().required(),
      // amount per unit
      unit_amount: yup.string().required(),
    })
  ),
  subscription_plan_ouputs: yup.object().shape({
    // the brand name on the input/ vendor of the input
    output_name: yup.string(),
    // e.g seed, fertilizer, drone service, water service, tractors, labour service e.t.c
    output_type: yup.string(),
    // the quantity that was given out to farmer as loan
    output_quantity: yup.string(),
    // the unit of measurement
    input_unit_measurement: yup.string(),
    // amount per unit
    expected_repayment: yup.string(),
    // the field for further instructions
    comment: yup.string(),
  }),
});

export const legacyCreateCropValidationSchema = yup.object().shape({
  name: yup.string().required("kindly enter crop name"),
  crop_category: yup.string().required("crop category is required").oneOf(["crop", "livestock"]),
  season: yup.string().required("kindly select a season"),
});

export const DEPRECATED_updateCropValidationSchema = yup.object().shape({
  name: yup.string().required("kindly enter crop name"),
  organization: yup.string().required(),
  crop_category: yup.string().required("crop category is required").oneOf(["crop", "livestock"]),
  season: yup.string(),
});
export const addInputDistToCropValidationSchema = yup.object().shape({
  inputTypes: yup.array(),
});

export const createInputValidationSchema = yup.object().shape({
  name: yup.string().required(),
  organizationId: yup.string().required(),
  seasonId: yup.string().required(),
  cropId: yup.string().required(),
  input_distribution: yup.string().required(),
});

export const legacySetInputQtyPerHectareSizeValidationSchema = yup.object().shape({
  quantity_per_hectares: yup.array().of(
    yup.object().shape({
      hectare_size: yup.number("hectare size should be a number"),
      hectare_value: yup.string(),
    })
  ),
});

export const legagyCreateQuestionnaireValidationSchema = yup.object().shape({
  name: yup.string().required("questionnaire name is required"),
  organization: yup.string(),
  psychometrics_status: yup.boolean(),
  season: yup.string().required("season is required"),
  farm_operation_category: yup.array().required("Farm Operation Categories are required"),
  category_type: yup.array().required("Farm type(s) is required"),
  model: yup
    .string()
    .required("questionnaire type is required")
    .oneOf([
      PSYCHOMETRIC_TEST_QUESTIONNAIRE,
      FARMER_ONBOARDING_QUESTIONNAIRE,
      SURVEY_QUESTIONNAIRE,
      FARM_MAPPING_QUESTIONNAIRE,
      HARVEST_QUESTIONNAIRE,
    ]),
  score_per_question: yup.number(),
  cut_off_mark: yup.number(),
  category_crop_type: yup.string(),
  status: yup.string().required().oneOf(["active", "inactive"]),
  questions: yup.array().of(yup.object().shape({})),
});

export const legacyQuestionsValidationSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question_title: yup.string().required("question title is required"),
      answer_options: yup.array().of(yup.string()),
      correct_answer: yup.array().of(yup.string()),
      question_type: yup.string().required("question type is required"),
      isCompulsory: yup.boolean().required("kindly select a value for compulsory"),
      maxLength: yup.number("max length must should be a numeric value").required("max length is required"),
      keyboardType: yup.string(),
    })
  ),
});

export const createRoleValidationSchema = yup.object().shape({
  name: yup.string().required("Role Name is required"),
  slug: yup.string(),
  scope: yup.array(),
});

export const legacyFarmSupervisionCategoryValidatioSchema = yup.object().shape({
  category: yup.string().required("kindly fill category name"),
  season: yup.string().required("kindly select a season"),
  sub_categories: yup.array(),
  organization: yup.string().required("missing org id"),
});

export const updateRegionValidationSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required(),
});
