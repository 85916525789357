import * as yup from "yup";

export const createFieldValidationSchema = yup.object().shape({
  agent: yup.string().required(), // the Agent id (that logs this visit)
  farmer: yup.string().required(), // the farmers id (owner of the supervised farmland)
  season: yup.string().required(), // the season id of the season the farmer was onboarded
  groupLead: yup.string().required(), //
  ta_number: yup.string().required(), //TA-000000001-01-01
  fieldId: yup.string().required(),
  crop_type: yup.string().required(),
  field_fertile: yup.string(),
  added_manure: yup.string(),
  tree_per_hectare: yup.string(),
  no_of_tree: yup.number().positive().nullable(),
  stump_per_hectare: yup.string(),
  no_of_stump: yup.number().positive().nullable(),
  stony_per_hectare: yup.string(),
  erosion_per_hectare: yup.string(),
  farm_waterlogging_flooding: yup.string(),
  farm_close_river: yup.string(),
  non_cereal_crop: yup.string(),
  farm_size: yup.number().positive().required(),
  adjusted_farm_size: yup.number().positive().nullable(),
  mappedFarm: yup.object(), // data from google map
  farm_termite: yup.string(),
  farm_cricket: yup.string(),
  farm_bigRat: yup.string(),
  presence_of_striga: yup.string(),
  coordinate: yup.object().shape({
    latitude: yup.string(), // The latitude for farmer
    longtitude: yup.string(), // The longtitude for farmer
    accuracy: yup.number(),
  }),
  status: yup.string().oneOf(["active", "inactive"]).default("active"),
  onboarded_at: yup.date().default(() => new Date().toISOString()),
  created_by: yup.string(),
});

export const editFarmerDetailsValidationSchema = yup.object().shape({
  last_name: yup.string().min(3).max(30).required(),
  first_name: yup.string().min(3).max(30).required(),
  middle_name: yup.string().required(),
  age: yup.string().required(),
  gender: yup.string().required(),
  status: yup.string().oneOf(["active", "inactive"]),
  state: yup.string().required(),
  lga: yup.string().required(),
  village: yup.string(),
  district: yup.string(),
  phone_number: yup.string(),
  phone_number_owner: yup.string().oneOf(["owner", "relative"]),
  role: yup.string().oneOf(["lead_farmer", "farmer", "secretary"]),
});
