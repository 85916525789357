import { Table } from "components/table";
import { PageWrapper } from "components/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSurveys,
  // getLoadingSurveyData,
  getSurveyData,
} from "features/v1/survey";
import { legacyFormatDate } from "utils/v1/tableUtils";
import { useTable, useDataFilter, useDisclosure } from "hooks";
import surveyApi from "api/survey";
import { Flex } from "organic";
import { ExportButton } from "components/export";
import { Modal, ModalContent } from "components/modal";
import { PageHeader } from "components/layout";
import ViewSurveyDetails from "./survey/viewSurveyDetails";

const SurveysPage = () => {
  const dispatch = useDispatch();
  const surveysData = useSelector(getSurveyData);
  // const loadingSurveys = useSelector(getLoadingSurveyData);

  const { filterParams } = useDataFilter();

  const viewSurveyModal = useDisclosure();

  const { tableProps, selectedRow: selectedSurvey } = useTable({
    data: surveysData,
    columns: [
      {
        Header: "TA Number",
        accessor: "ta_number",
      },
      {
        Header: "Survey type",
        accessor: "survey_type",
      },
      {
        Header: "Farmer Serial ID",
        accessor: "farmer_id",
      },
      // {
      //   id: "surveyQuestion",
      //   Header: "Question",
      //   accessor: survey => {return get(survey, ["feedback", 0, "question"], "N/A")}
      // },
      // {
      //   id: "surveyResponse",
      //   Header: "Response",
      //   accessor: survey => {return get(survey, ["feedback", 0, "answer"], "N/A")}
      // },

      {
        id: "surveryCreatedDate",
        Header: "Created on",
        accessor: survey => legacyFormatDate(survey.created_at),
      },
    ],
    title: "All Surveys",
    noEditAction: true,
  });
  // api calls

  const advanceSurveyExport = async ({ filterParams, columnWhitelist }) => {
    let params = { columnWhitelist, ...filterParams };
    const res = await surveyApi.get("advance", { params });
    return res;
  };

  const quickSurveysExport = async () => {
    const data = (await surveyApi.get("downloadSurveys", { params: filterParams })).data;
    return data;
  };

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" align-Items="center">
        <PageHeader title="Surveys" />
        <ExportButton
          quickExportConfig={{
            apiCall: quickSurveysExport,
            fileName: `AOSSurveysQuickExport${legacyFormatDate(new Date())}.csv`,
          }}
          advancedExportConfig={{
            apiCall: advanceSurveyExport,
          }}
        />
      </Flex>
      <Table
        {...tableProps}
        onDataFilter={({ seasonId, dateRange }) => {
          dispatch(getAllSurveys({ seasonId, dateRange }));
        }}
        onViewAction={() => {
          viewSurveyModal.onOpen();
        }}
      />
      <Modal {...viewSurveyModal.modalProps}>
        <ModalContent title="Survey Details">
          <ViewSurveyDetails modal={viewSurveyModal} selected={selectedSurvey} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export default SurveysPage;
