import role from "api/role";
import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";
import { basicRoles } from "services/v1/rolesAndPermissionsService";

const LOADING_ROLES = "LOADING_ROLES";
const SET_ALL_ROLES = "SET_ALL_ROLES";
const SET_BASIC_ROLES = "SET_BASIC_ROLES";

/**
api/v1/user/role/create
api/v1/user/role/getAll
api/v1/user/role/removeScope
 */

export const getAllRoles = () => async dispatch => {
  try {
    dispatch({ type: LOADING_ROLES, payload: true });
    const result = await role.get("getAll");

    dispatch({ type: SET_ALL_ROLES, payload: result.data.data });
    dispatch({ type: LOADING_ROLES, payload: false });
  } catch (getAllRolesError) {
    dispatch({ type: LOADING_ROLES, payload: false });
    handleActionError(getAllRolesError, "getAllRolesError");
  }
};
export const getAllBasicRoles = () => async dispatch => {
  try {
    dispatch({ type: LOADING_ROLES, payload: true });
    const [
      subAdminResult,
      // regionalManagerResult,
      // zonalManagerResult,
      // operationalManagerResult,
      financeResult,
      // storeManagerResult,
      agentResult,
    ] = Promise.all([
      await role.get("getOne", { params: { slug: basicRoles.SUB_ADMIN } }),
      // await role.get("getOne", { params: { slug: basicRoles.REGIONAL_MANAGER } }),
      // await role.get("getOne", { params: { slug: basicRoles.ZONAL_MANAGER } }),
      // await role.get("getOne", { params: { slug: basicRoles.OPERATIONAL_MANAGER } }),
      await role.get("getOne", { params: { slug: basicRoles.FINANCE } }),
      // await role.get("getOne", { params: { slug: basicRoles.STORE_MANAGER } }),
      await role.get("getOne", { params: { slug: basicRoles.AGENT } }),
    ]);

    dispatch({
      type: SET_BASIC_ROLES,
      payload: {
        subAdmin: subAdminResult.data.scope,
        // regionalManager: regionalManagerResult.data.scope,
        // zonalManager: zonalManagerResult.data.scope,
        // operationalManager: operationalManagerResult.data.scope,
        finance: financeResult.data.scope,
        // storeManager: storeManagerResult.data.scope,
        agent: agentResult.data.scope,
      },
    });
    dispatch({ type: LOADING_ROLES, payload: false });
  } catch (getAllRolesError) {
    dispatch({ type: LOADING_ROLES, payload: false });
    handleActionError(getAllRolesError, "getAllRolesError");
  }
};
export const getRole =
  (params = {}) =>
  async dispatch => {
    try {
      dispatch({ type: LOADING_ROLES, payload: true });
      const result = await role.get("getOne", { params });
      dispatch({ type: LOADING_ROLES, payload: false });
      if (result.status === 200) {
        return result.data.data;
      }
      return null;
    } catch (getAllRolesError) {
      dispatch({ type: LOADING_ROLES, payload: false });
      handleActionError(getAllRolesError, "getAllRolesError");
    }
  };
export const createRole =
  (data, __config = {}) =>
  async dispatch => {
    try {
      dispatch({ type: LOADING_ROLES, payload: true });
      await role.post("create", data);
      dispatch({ type: LOADING_ROLES, payload: false });
      toast.success("user role created successfully");
      return dispatch(getAllRoles());
    } catch (createRoleError) {
      dispatch({ type: LOADING_ROLES, payload: false });
      handleActionError(createRoleError, "createRoleError");
    }
  };
export const removeScope =
  (data, config = {}) =>
  async dispatch => {
    try {
      const params = {
        ...config.params,
        organizationId: undefined,
      };
      dispatch({ type: LOADING_ROLES, payload: true });
      await role.put("removeScope", data, { params });
      dispatch({ type: LOADING_ROLES, payload: false });
      toast.success(`permission removed successfuly`);
      return dispatch(getAllRoles());
    } catch (removeScopeError) {
      dispatch({ type: LOADING_ROLES, payload: false });
      handleActionError(removeScopeError, "removeScopeError");
    }
  };
export const addScope =
  (data, config = {}) =>
  async dispatch => {
    try {
      const params = {
        ...config.params,
        organizationId: undefined,
      };
      dispatch({ type: LOADING_ROLES, payload: true });
      await role.put("addScope", data, { params });
      dispatch({ type: LOADING_ROLES, payload: false });
      toast.success(`permission added successfuly`);
      return dispatch(getAllRoles());
    } catch (addScopeError) {
      dispatch({ type: LOADING_ROLES, payload: false });
      handleActionError(addScopeError, "addScopeError");
    }
  };

export const updateRole =
  (data, config = {}) =>
  async dispatch => {
    try {
      const params = {
        ...config.params,
      };
      dispatch({ type: LOADING_ROLES, payload: true });
      await role.put("update", data, { params, ignoreOrgId: true });
      dispatch({ type: LOADING_ROLES, payload: false });
      toast.success(`role updated successfuly`);
      return dispatch(getAllRoles());
    } catch (addScopeError) {
      dispatch({ type: LOADING_ROLES, payload: false });
      handleActionError(addScopeError, "addScopeError");
    }
  };

// initial state
const initialState = {
  allRolesData: [],
  loadingRoles: false,
  roleOptions: [],
  basicScopes: {
    subAdmin: [],
    regionalManager: [],
    zonalManager: [],
    operationalManager: [],
    finance: [],
    storeManager: [],
    agent: [],
  },
};

// reducer
const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ROLES:
      return { ...state, loadingRoles: action.payload };
    case SET_ALL_ROLES: {
      const _roleOptions = action.payload.map(role => {
        return { label: role.name, value: role.name, id: role._id, slug: role.slug, scope: role.scope };
      });
      return { ...state, allRolesData: action.payload, roleOptions: _roleOptions };
    }
    case SET_BASIC_ROLES:
      return { ...state, basicRolesData: { ...state.basicRolesData, ...action.payload } };
    default:
      return state;
  }
};

// selectetor fucntions
export const getLoadingRoles = state => state.legacyRole.loadingRoles;
export const getAllRolesData = state => state.legacyRole.allRolesData;
export const getRoleOptions = state => state.legacyRole.roleOptions;
export const getBasicRolesData = state => state.legacyRole.basicRolesData;

export default roleReducer;
