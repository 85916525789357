import { getAllAdmins, getAllSubAdmins, getSubAdmins } from "features/v1/auth";
import { useAppSelector } from "../redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useCallbackRef } from "hooks/ui/useCallbackRef";

// const dummmyAdmin = { first_name: "", last_name: "", avatar_url: "", created_at: "" };

export function useAllAdminData() {
  const admins = useAppSelector(getSubAdmins);
  const subAdmins = useAppSelector(getAllSubAdmins);

  const dispatch = useDispatch();

  const getSingleAdmin = useCallbackRef(
    id => {
      const _selectedAdmin =
        admins?.find?.(_admin => {
          return `${_admin._id}` === `${id}`;
        }) || null;

      console.log({ _selectedAdmin });
      if (!_selectedAdmin) {
        return null;
      }

      return _selectedAdmin;
    },
    [admins]
  );

  useEffect(() => {
    if (!admins?.length) {
      dispatch(getAllAdmins());
      dispatch(getAllSubAdmins());
    }
  }, []);

  return { admins, subAdmins, getSingleAdmin };
}
