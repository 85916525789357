import { SummaryGrid } from "components/grid";
import { Table } from "components/table";
import { useDataFilter, useTable } from "hooks";
import { Avatar, AvatarGroup, Badge, Flex, Text } from "organic";
import React from "react";
import { OverviewValueWrapper, resolveMarketplaceTradeStatusColor } from "../marketplaceOverviewPage";
import { useGetMarketplaceOverview, useGetTrades, useToggleStatus } from "hooks/app/marketplace";
import numeral from "numeral";
import { tradeStatuses } from "../marketplaceTradePage";
import { appGet, capitalize } from "utils";
import { useNavigate } from "react-router-dom";
import { useAppRoles } from "hooks/app";
import { handleFormSubmission } from "services/formSubmissionService";
import { toast } from "react-toastify";

const ApprovedTrades = () => {
  const navigate = useNavigate();
  const { filterParams } = useDataFilter();

  const { data: overviewData, isLoading: isOverviewLoading } = useGetMarketplaceOverview({
    params: { status: tradeStatuses.APPROVED, dateRange: filterParams.dateRange },
  });

  const appRoles = useAppRoles();

  const roles = useAppRoles();
  const { mutateAsync } = useToggleStatus();

  const handleUpdateTrade = async values => {
    console.log("triggered", values);
    await handleFormSubmission({
      submitFn: mutateAsync,
      values: {
        params: { tradeId: values.trade_id },
        data: { target_achieved: values.target_achieved },
      },
      successMsg: "Trade moved to stocked succesfully",
      errorMsg: "Error moving trade to stocked",
    });
  };

  const { data, isLoading } = useGetTrades({
    params: { status: tradeStatuses.APPROVED },
  });

  const { tableProps, selectedRow: selectedTrade } = useTable({
    data: data?.data || [],
    columns: [
      {
        Header: "ID",
        accessor: "trade_id",
      },
      {
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Cost Price",
        accessor: trade => numeral(trade?.cost_price || 0).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Farmers under trade",
        accessor: trade => numeral(trade?.trade_farmers || 0).format("0,0.00"),
      },

      {
        Header: "Target Qty (KG)",
        accessor: trade => numeral(trade?.target_quantity || 0).format("0,0.00"),
      },
      {
        Header: "Sourced Qty (KG)",
        accessor: trade => numeral(trade?.target_achieved || 0).format("0,0.00"),
      },
      {
        Header: "Assigned Agents",
        accessor: "assigned_agents",
        Cell: ({ value }) => {
          return (
            <AvatarGroup>
              {value?.map?.(agent => (
                <Avatar size="xs" key={agent._id} name={`${agent.first_name} ${agent.last_name}`} />
              ))}
            </AvatarGroup>
          );
        },
      },
      {
        Header: "Profit Margin (%)",
        accessor: trade => numeral(trade.profit_margin || 0).format("0.00"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge colorScheme={resolveMarketplaceTradeStatusColor(value)}>{capitalize(value)}</Badge>,
      },
    ],
    title: "All approved trades",
    useServerPagination: true,
    serverPaginationMeta: data?.meta || {},
    noEditAction: !appRoles.isRole("regionalManager"),
  });

  return (
    <Flex flexDirection="column">
      <SummaryGrid
        data={[
          {
            title: "Total Trade Created",
            component: (
              <OverviewValueWrapper
                data={overviewData}
                value={numeral(overviewData?.pending || 0)
                  .add(overviewData?.approved || 0)
                  .add(overviewData?.declined || 0)
                  .add(overviewData?.stocked || 0)
                  // .add(overviewData?.delivered || 0)
                  .add(overviewData?.invoiceStatusCount || 0)
                  .add(overviewData?.finalized || 0)
                  .add(overviewData?.closed || 0)
                  .format("0")}
                isLoading={isOverviewLoading}
              />
            ),
          },
          {
            title: "Total Approved",
            component: (
              <OverviewValueWrapper
                isActive
                data={overviewData}
                value={overviewData?.approved}
                isLoading={isOverviewLoading}
              />
            ),
          },
          {
            title: "Total Declined",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.declined} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Stocked",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.stocked} isLoading={isOverviewLoading} />
            ),
          },
          // {
          //   title: "Total Delivered",
          //   component: (
          //     <OverviewValueWrapper data={overviewData} value={overviewData?.delivered} isLoading={isOverviewLoading} />
          //   ),
          // },
          {
            title: "Total Finalised",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.finalized} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Closed",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.closed} isLoading={isOverviewLoading} />
            ),
          },
        ]}
      />
      <Table
        {...tableProps}
        loading={isLoading}
        onViewAction={() => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=view`);
        }}
        onExtraAction={(selectedActions, __) => {
          if (selectedActions.actionKey === "moveToStocked") {
            console.log("selected actions ", selectedActions.selected);
            handleUpdateTrade(selectedActions.selected);
          }
        }}
        getExtraActions={__selected => {
          const actions = [];
          if (roles.isRole("regionalManager")) {
            actions.push({ label: "Move to stocked", actionKey: "moveToStocked" });
          }

          return actions;
        }}
        onEditAction={() => {
          if (appRoles.isRole("regionalManager")) {
            navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=edit`);
          } else {
            toast.info("Confirming you have permission to edit approved trades... kindly try again shortly");
          }
        }}
      />
    </Flex>
  );
};

export default ApprovedTrades;
