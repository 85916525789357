import React from "react";
import { FaRegWindowClose } from "react-icons/fa";

/**
 *
 *
 * @export
 * @param {Function} { toggle }
 * @returns
 */
export default function closeBtn({ toggle, size }) {
  return <FaRegWindowClose size={size || 32} className="text-danger mt-2 close-btn" onClick={toggle} />;
}
