import { FormInput } from "components/inputs";
import { selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import { useFormikContext } from "formik";
import React from "react";
import { createSelectOption } from "utils/v1/selectUtils";

export const SellingPriceInput = () => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <FormInput
      label="Selling Price"
      placeholder="Selling Price"
      required
      name="selling_price"
      type="number"
      max={MAX_NUM_VALUE}
      rightAddon={
        <CurrencySelect
          onChange={currencyValue => {
            setFieldValue("currency", currencyValue);
          }}
          value={createSelectOption(values.currency)}
          colorScheme={selectConstants.colorSchemes.UNSTYLED}
          placeholder="Currency"
        />
      }
    />
  );
};
