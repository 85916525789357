import numeral, { isNumeral } from "numeral";
import { default as isFinite } from "lodash/isFinite";
import { default as lodashSum } from "lodash/sum";
import { all, create } from "mathjs";

export function formatCurrency(number, config = { currency: "NGN" }) {
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: config.currency,
  });
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
/** returns true is the parameter is a valid numerical value eg "69" */
export function isNumericalValue(data) {
  return isNumeral(data);
}
/** returns true is the parameter is an actual number value eg 69 or 345.345 */
export function isNumber(arg) {
  return isFinite(arg);
}

export function toNumber(arg) {
  return numeral(arg).value();
}

export function calculateAverage(numbers) {
  if (numbers.length === 0) {
    return 0; // To avoid division by zero if the array is empty
  }

  const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const average = sum / numbers.length;
  return average;
}

export function sum(numbers = []) {
  if (!numbers.every(isNumber)) {
    return "--";
  }
  return lodashSum(numbers);
}

export function divideUsingMultiplication(dividend, divisor) {
  try {
    // Check if the divisor is zero to avoid division by zero
    if (!divisor || divisor === 0) {
      return dividend; // divide by 1
    }

    if (isNaN(dividend) || isNaN(divisor)) {
      return 1; // zero is not a good default for division (chaining error)
    }

    // Calculate the result using multiplication by the reciprocal
    const result = dividend * (1 / divisor);

    // Check if the result is Infinity or -Infinity
    if (!isFinite(result)) {
      return "0.000";
    }
    console.log({ result });
    return result;
  } catch (error) {
    console.log({ error });
    return dividend;
  }
}

const defaultMathConfig = {
  number: "BigNumber",
  precision: 64,
  epsilon: "1e-60",
};
const mathUtils = create(all);
mathUtils.config(defaultMathConfig);

export { mathUtils };
