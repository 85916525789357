/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Icon, Text } from "organic";
import { SearchInput, FormTextArea } from "components/inputs";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FormInput } from "components/inputs";
import { format } from "date-fns";
import {
  createTermsAndConditions,
  getAllTermsAndConditions,
  getTermsAndConditions,
  getTermsAndConditionsLoading,
} from "features/v1/language";
import { useDispatch, useSelector } from "react-redux";

export const initialCreateTermValues = {
  term: "",
  description: "",
};

const createTermValidator = Yup.object().shape({
  term: Yup.string().required("Required"),
  description: Yup.string(),
});

const TermsAndConditionsSettingsPage = () => {
  const termsAndConditions = useSelector(getTermsAndConditions);
  const termsAndConditionsLoading = useSelector(getTermsAndConditionsLoading);
  const dispatch = useDispatch();
  // FIXME: move this to redux slice file `features/language`
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page // Example list of items

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the item list based on the current page
  const displayedItems = termsAndConditions.slice(startIndex, endIndex);

  // Event handler for page change
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const handleCreateNewTerm = (values, submitProps) => {
    dispatch(createTermsAndConditions({ termsAndConditions: [...termsAndConditions, values] }, submitProps));
  };

  const deleteTerm = id => {
    const updatedTerms = termsAndConditions.filter(t => t._id !== id);
    dispatch(createTermsAndConditions({ termsAndConditions: updatedTerms }, null, true));
  };

  useEffect(() => {
    dispatch(getAllTermsAndConditions());
  }, []);

  return (
    <div className="terms-conditions">
      <Flex>
        <SearchInput onChange={() => {}} />
      </Flex>
      <div className="content">
        <div className="create">
          <div className="heading">Create New Term & Condition</div>
          <div className="create__body">
            <Formik
              initialValues={initialCreateTermValues}
              validationSchema={createTermValidator}
              onSubmit={handleCreateNewTerm}
            >
              {({ errors }) => (
                <Box>
                  <Form>
                    <Text mx={2} color="red" textAlign="center">
                      {errors.message}
                    </Text>

                    <Box>
                      <Box mb={4}>
                        <FormInput required name="term" label="Title Name" placeholder="Enter title name" mb={4} />{" "}
                      </Box>
                    </Box>

                    <Box>
                      <Box mb={4}>
                        <FormTextArea
                          required
                          name="description"
                          label="Description"
                          placeholder="Enter terms and condition..."
                          mb={4}
                        />{" "}
                      </Box>
                    </Box>

                    <Button colorScheme="primary" display="flex" type="submit" className="form-btn-1 btn-lg" disabled>
                      Create New Term & Condition
                    </Button>
                  </Form>
                </Box>
              )}
            </Formik>
          </div>
        </div>
        <div className="existing">
          <div className="heading">Existing Terms & Conditions</div>
          <div className="existing__body">
            {displayedItems.map(term => {
              return (
                <div key={term._id} className="term">
                  <div>
                    <div className="term__heading">
                      <span className="title">{term?.title || "Terms for Niger Farmer"}</span>
                      <span className="date">
                        {term.created_at ? format(new Date(term?.created_at), "dd MMM yyyy, h:mm a") : "--"}
                      </span>
                    </div>
                    <div className="term__content">
                      <p>{term?.term}</p>
                    </div>
                  </div>
                  <div onClick={() => deleteTerm(term._id)} className="delete-icon">
                    <Icon icon="trash-2" color="#667085" size="20px" />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="horizontal-rule" />
          <div className="terms-conditions__pagination">
            <AlternatePagination
              itemsPerPage={itemsPerPage}
              totalItems={termsAndConditions.length}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsSettingsPage;

export const AlternatePagination = ({ itemsPerPage, totalItems, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Event handler for previous button click
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  // Event handler for next button click
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="alternate-pagination">
      <button className="prev" onClick={handlePreviousClick} disabled={currentPage === 1}>
        <span className="arrow-icon">
          <Icon icon="arrow-left" color="#344054" size="20px" />
        </span>
      </button>
      <span className="current-page">
        Page <span className="current-page__bold">{currentPage}</span> of{" "}
        <span className="current-page__bold">{totalPages}</span>
      </span>
      <button className="next" onClick={handleNextClick} disabled={currentPage === totalPages}>
        <span className="arrow-icon">
          <Icon icon="arrow-right" color="#344054" size="20px" />
        </span>
      </button>
    </div>
  );
};
