import { Flex, Text, organicTheme } from "organic";
import React from "react";
import { isNumber } from "utils";

const ProgressChart = ({
  size,
  height,
  width,
  data,
  // target,
  // acheived,
  trackWidth,
  trackColor,
  indicatorWidth,
  indicatorColor,
  indicatorCap,
  labelNameColor,
  labelValueColor,
  spinnerSpeed,
}) => {
  const { progress = 0, name, value = 0 } = data;

  const setSize = () => {
    // use width or height if provided,  but always prefer size
    let hasWidthNumber = width && isNumber(width);
    if (hasWidthNumber) {
      return size ? size : width;
    }
    let hasHeightNumber = height && isNumber(height);
    if (hasHeightNumber) {
      return size ? size : height;
    }
    // if neither height nor width is provided as a number then size will be used
    return size; //if size is not provided default value 120 is used
  };

  const _size = setSize();
  const center = _size / 2;
  const radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
  const dashArray = 2 * Math.PI * radius;
  const dashOffset = dashArray * ((100 - progress) / 100);

  const hideLabel = _size < 100 ? true : false;

  return (
    <>
      <Flex position="relative" width={size} mx="auto">
        <svg
          className="progress-chart"
          viewBox={`0 0 ${_size} ${_size}`}
          style={{ width: _size, height: _size }}
          preserveAspectRatio="xMidYMid meet"
        >
          <circle
            className="progress-chart__track"
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className="progress-chart__indicator"
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>
        {!hideLabel && (
          <div className="progress-chart-label">
            <Text as="p" font="xsMedium" color={labelNameColor}>
              {name}
            </Text>
            <Text as="p" font="smBold" color={labelValueColor}>
              {value ? value : `${progress}%`}
            </Text>
          </div>
        )}
      </Flex>
    </>
  );
};

ProgressChart.defaultProps = {
  size: 120,
  progress: 0,
  trackWidth: 10,
  data: {
    name: "Loading...",
    value: 0,
    progress: 0,
  },
  trackColor: organicTheme.colors.gray100,
  indicatorWidth: 10,
  indicatorColor: organicTheme.colors.success500,
  indicatorCap: `round`,
  labelNameColor: organicTheme.colors.gray500,
  labelValueColor: organicTheme.colors.gray900,
  spinnerMode: false,
  spinnerSpeed: 1,
};

export default ProgressChart;
