import React, { useMemo } from "react";
import { Select } from ".";
import { useGetLogistics } from "hooks/app/marketplace";

export const LogisticsSelect = ({ onChange, ...restProps }) => {
  const { data } = useGetLogistics();

  const logisticsOptions = useMemo(() => {
    return data?.map?.(logistic => {
      return {
        label: `${logistic.name}`,
        value: logistic,
      };
    });
  }, [data]);

  return (
    <Select
      onChange={onChange}
      label="Logistics Type"
      options={logisticsOptions}
      {...restProps}
      placeholder={"Select Logistics Type..."}
    />
  );
};
