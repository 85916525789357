export default [
  {
    state: {
      name: "Abim",
      id: 1,
      locals: [{ name: "Labwor" }],
    },
  },
  {
    state: {
      name: "Adjumani",
      id: 2,
      locals: [{ name: "East Moyo" }],
    },
  },
  {
    state: {
      name: "Agago",
      id: 3,
      locals: [{ name: "Agago" }],
    },
  },
  {
    state: {
      name: "Alebtong",
      id: 4,
      locals: [{ name: "Ajuri" }, { name: "Moroto" }],
    },
  },
  {
    state: {
      name: "Amolatar",
      id: 5,
      locals: [{ name: "Kioga" }],
    },
  },
  {
    state: {
      name: "Amudat",
      id: 6,
      locals: [{ name: "Upe" }],
    },
  },
  {
    state: {
      name: "Amuria",
      id: 7,
      locals: [{ name: "Amuria" }, { name: "Kapelebyong" }],
    },
  },
  {
    state: {
      name: "Amuru",
      id: 8,
      locals: [{ name: "Kilak" }],
    },
  },
  {
    state: {
      name: "Apac",
      id: 9,
      locals: [{ name: "Kwania" }, { name: "Maruzi" }],
    },
  },
  {
    state: {
      name: "Arua",
      id: 10,
      locals: [{ name: "Arua Municipal Council" }, { name: "Ayivu" }, { name: "Madi-Okollo" }, { name: "Terego" }],
    },
  },
  {
    state: {
      name: "Budaka",
      id: 11,
      locals: [{ name: "Budaka" }, { name: "Iki-Iki" }],
    },
  },
  {
    state: {
      name: "Bududa",
      id: 12,
      locals: [{ name: "Manjiya" }],
    },
  },
  {
    state: {
      name: "Bugiri",
      id: 13,
      locals: [{ name: "Bukooli" }],
    },
  },
  {
    state: {
      name: "Buhweju",
      id: 14,
      locals: [{ name: "Buhweju" }],
    },
  },
  {
    state: {
      name: "Buikwe",
      id: 15,
      locals: [{ name: "Buikwe" }],
    },
  },
  {
    state: {
      name: "Bukedea",
      id: 16,
      locals: [{ name: "Bukedea" }],
    },
  },
  {
    state: {
      name: "Bukomansimbi",
      id: 17,
      locals: [{ name: "Bukomansimbi" }],
    },
  },
  {
    state: {
      name: "Bukwa",
      id: 18,
      locals: [{ name: "Kongasis" }],
    },
  },
  {
    state: {
      name: "Bulambuli",
      id: 19,
      locals: [{ name: "Bulambuli" }],
    },
  },
  {
    state: {
      name: "Buliisa",
      id: 20,
      locals: [{ name: "Buliisa" }],
    },
  },
  {
    state: {
      name: "Bundibugyo",
      id: 21,
      locals: [{ name: "Bughendera" }, { name: "Bwamba" }],
    },
  },
  {
    state: {
      name: "Bushenyi",
      id: 22,
      locals: [{ name: "Bushenyi-Ishaka" }, { name: "Igara" }],
    },
  },
  {
    state: {
      name: "Busia",
      id: 23,
      locals: [{ name: "Busia Municipal Council" }, { name: "Samia-Bugwe" }],
    },
  },
  {
    state: {
      name: "Butalega",
      id: 24,
      locals: [{ name: "Bunyole" }],
    },
  },
  {
    state: {
      name: "Butambala",
      id: 25,
      locals: [{ name: "Butambala" }],
    },
  },
  {
    state: {
      name: "Buvuma",
      id: 26,
      locals: [{ name: "Buvuma" }],
    },
  },
  {
    state: {
      name: "Buyenda",
      id: 27,
      locals: [{ name: "Budiope East" }, { name: "Budiope West" }],
    },
  },
  {
    state: {
      name: "Dokolo",
      id: 28,
      locals: [{ name: "Dokolo" }],
    },
  },
  {
    state: {
      name: "Gomba",
      id: 29,
      locals: [{ name: "Gomba" }],
    },
  },
  {
    state: {
      name: "Gulu",
      id: 30,
      locals: [{ name: "Aswa" }, { name: "Gulu Municipal Council" }, { name: "Omoro" }],
    },
  },
  {
    state: {
      name: "Hoima",
      id: 31,
      locals: [{ name: "Bugahya" }, { name: "Buhaguzi" }, { name: "Hoima Municipal Council" }],
    },
  },
  {
    state: {
      name: "Ibanda",
      id: 32,
      locals: [{ name: "Ibanda" }],
    },
  },
  {
    state: {
      name: "Iganga",
      id: 33,
      locals: [{ name: "Bugweri" }, { name: "Iganga Municipal Council" }, { name: "Kigulu" }],
    },
  },
  {
    state: {
      name: "Isingiro",
      id: 34,
      locals: [{ name: "Bukanga" }, { name: "Isingiro" }],
    },
  },
  {
    state: {
      name: "Jinja",
      id: 35,
      locals: [{ name: "Butembe" }, { name: "Jinja Municipal Council" }, { name: "Kagoma" }],
    },
  },
  {
    state: {
      name: "Kaabong",
      id: 36,
      locals: [{ name: "Dodoth East" }, { name: "Dodoth West" }, { name: "Ik" }],
    },
  },
  {
    state: {
      name: "Kabale",
      id: 37,
      locals: [{ name: "Kabale Municipal Council" }, { name: "Ndorwa" }, { name: "Rubanda" }, { name: "Rukiga" }],
    },
  },
  {
    state: {
      name: "Kabarole",
      id: 38,
      locals: [
        { name: "Bunyangabu" },
        { name: "Burahya" },
        { name: "Fort Portal Municipal Council" },
        { name: "Nakasongola" },
      ],
    },
  },
  {
    state: {
      name: "Kaberamaido",
      id: 39,
      locals: [{ name: "Kaberamaido" }, { name: "Kalaki" }],
    },
  },
  {
    state: {
      name: "Kalangala",
      id: 40,
      locals: [{ name: "Bujumba" }, { name: "Kyamuswa" }],
    },
  },
  {
    state: {
      name: "Kaliro",
      id: 41,
      locals: [{ name: "Kaliro" }],
    },
  },
  {
    state: {
      name: "kalungu",
      id: 42,
      locals: [{ name: "kalungu" }],
    },
  },
  {
    state: {
      name: "Kampala",
      id: 43,
      locals: [{ name: "Kampala" }],
    },
  },
  {
    state: {
      name: "Kamuli",
      id: 44,
      locals: [{ name: "Bugabula" }, { name: "Buzaaya" }],
    },
  },
  {
    state: {
      name: "Kamwenge",
      id: 45,
      locals: [{ name: "Kibale" }, { name: "Kitagwenda" }],
    },
  },
  {
    state: {
      name: "Kanungu",
      id: 46,
      locals: [{ name: "Kinkiizi" }],
    },
  },
  {
    state: {
      name: "Kapchorwa",
      id: 47,
      locals: [{ name: "Tingey" }],
    },
  },
  {
    state: {
      name: "Kasese",
      id: 48,
      locals: [{ name: "Bukonzo" }, { name: "Busongora" }, { name: "Kasese Municipal Council" }],
    },
  },
  {
    state: {
      name: "Katakwi",
      id: 49,
      locals: [{ name: "Toroma" }, { name: "Usuk" }],
    },
  },
  {
    state: {
      name: "Kayunga",
      id: 50,
      locals: [{ name: "Bbaale" }, { name: "Ntenjeru" }],
    },
  },
  {
    state: {
      name: "Kibaale",
      id: 51,
      locals: [
        { name: "Bugangaizi" },
        { name: "Bugangaizi East" },
        { name: "Buyaga" },
        { name: "Buyaga West" },
        { name: "Buyanja" },
      ],
    },
  },
  {
    state: {
      name: "Kiboga",
      id: 52,
      locals: [{ name: "Kiboga" }],
    },
  },
  {
    state: {
      name: "Kibuku",
      id: 53,
      locals: [{ name: "Kibuku" }],
    },
  },
  {
    state: {
      name: "Kiruhura",
      id: 54,
      locals: [{ name: "Kazo" }, { name: "Nyabushozi" }],
    },
  },
  {
    state: {
      name: "Kiryandongo",
      id: 55,
      locals: [{ name: "Kibanda" }],
    },
  },
  {
    state: {
      name: "Kisoro",
      id: 56,
      locals: [{ name: "Bufumbira" }],
    },
  },
  {
    state: {
      name: "Kitgum",
      id: 57,
      locals: [{ name: "Chua" }],
    },
  },
  {
    state: {
      name: "Koboko",
      id: 58,
      locals: [{ name: "Koboko" }],
    },
  },
  {
    state: {
      name: "Kole",
      id: 59,
      locals: [{ name: "kole" }],
    },
  },
  {
    state: {
      name: "Kotido",
      id: 60,
      locals: [{ name: "Jie" }],
    },
  },
  {
    state: {
      name: "Kumi",
      id: 61,
      locals: [{ name: "kumi" }],
    },
  },
  {
    state: {
      name: "Kwen",
      id: 62,
      locals: [{ name: "Kween" }],
    },
  },
  {
    state: {
      name: "Kyankwanzi",
      id: 63,
      locals: [{ name: "Kiboga" }],
    },
  },
  {
    state: {
      name: "Kyegegwa",
      id: 64,
      locals: [{ name: "Kyaka" }],
    },
  },
  {
    state: {
      name: "Kyejonjo",
      id: 65,
      locals: [{ name: "Mwenge" }],
    },
  },
  {
    state: {
      name: "Lamwo",
      id: 66,
      locals: [{ name: "Lamwo" }],
    },
  },
  {
    state: {
      name: "Lira",
      id: 67,
      locals: [{ name: "Erute" }, { name: "Lira Municipal Council" }],
    },
  },
  {
    state: {
      name: "Luuka",
      id: 68,
      locals: [{ name: "Luuka" }],
    },
  },
  {
    state: {
      name: "Luwero",
      id: 69,
      locals: [{ name: "Bamunanika" }, { name: "Katikamu" }],
    },
  },
  {
    state: {
      name: "Lwengo",
      id: 70,
      locals: [{ name: "Bukoto" }],
    },
  },
  {
    state: {
      name: "Lyantonde",
      id: 71,
      locals: [{ name: "Kabula" }],
    },
  },
  {
    state: {
      name: "Manafwa",
      id: 72,
      locals: [{ name: "Bubulo" }],
    },
  },
  {
    state: {
      name: "Maracha",
      id: 73,
      locals: [{ name: "Maracha" }],
    },
  },
  {
    state: {
      name: "Masaka",
      id: 74,
      locals: [{ name: "Bukoto Central" }, { name: "Bukoto East" }, { name: "Masaka Municipal Council" }],
    },
  },
  {
    state: {
      name: "Masindi",
      id: 75,
      locals: [{ name: "Bujenje" }, { name: "Buruuli" }, { name: "Masindi Municipal Council" }],
    },
  },
  {
    state: {
      name: "Mayuge",
      id: 76,
      locals: [{ name: "Bunya" }],
    },
  },
  {
    state: {
      name: "Mbale",
      id: 77,
      locals: [{ name: "Bungokho" }, { name: "Mbale Municipal Council" }],
    },
  },
  {
    state: {
      name: "Mbarara",
      id: 78,
      locals: [{ name: "Kashari" }, { name: "Mbarara Municipal Council" }, { name: "Rwampara" }],
    },
  },
  {
    state: {
      name: "Mitooma",
      id: 79,
      locals: [{ name: "Ruhinda" }],
    },
  },
  {
    state: {
      name: "Mityana",
      id: 80,
      locals: [{ name: "Busujju" }, { name: "Mityana" }],
    },
  },
  {
    state: {
      name: "Moroto",
      id: 81,
      locals: [{ name: "Matheniko" }, { name: "Moroto Municipal Council" }],
    },
  },
  {
    state: {
      name: "Moyo",
      id: 82,
      locals: [{ name: "Obongi" }, { name: "West Moyo" }],
    },
  },
  {
    state: {
      name: "Mpigi",
      id: 83,
      locals: [{ name: "Mawokota" }],
    },
  },
  {
    state: {
      name: "Mubende",
      id: 84,
      locals: [{ name: "Buwekula" }, { name: "Kasambya" }, { name: "Kassanda" }],
    },
  },
  {
    state: {
      name: "Mukono",
      id: 85,
      locals: [{ name: "Mukono" }, { name: "Mukono Municipal Council" }, { name: "Nakifuma" }],
    },
  },
  {
    state: {
      name: "Nakapiripiriti",
      id: 86,
      locals: [{ name: "Chekwii" }, { name: "Pian" }],
    },
  },
  {
    state: {
      name: "Nakaseke",
      id: 87,
      locals: [{ name: "Nakaseke North" }, { name: "Nakaseke South" }],
    },
  },
  {
    state: {
      name: "Nakasongola",
      id: 88,
      locals: [{ name: "Bukooli Island" }, { name: "Bukooli South" }],
    },
  },
  {
    state: {
      name: "Namutumba",
      id: 89,
      locals: [{ name: "Busiki" }],
    },
  },
  {
    state: {
      name: "Napak",
      id: 90,
      locals: [{ name: "Bokora" }],
    },
  },
  {
    state: {
      name: "Nebbi",
      id: 91,
      locals: [{ name: "Jonam" }, { name: "Padyere" }],
    },
  },
  {
    state: {
      name: "Ngora",
      id: 92,
      locals: [{ name: "Ngora" }],
    },
  },
  {
    state: {
      name: "Ntoroko",
      id: 93,
      locals: [{ name: "Ntoroko" }],
    },
  },
  {
    state: {
      name: "Ntungamo",
      id: 94,
      locals: [{ name: "Kajara" }, { name: "Ntungamo Municipal Council" }, { name: "Ruhaama" }, { name: "Rushenyi" }],
    },
  },
  {
    state: {
      name: "Nwoya",
      id: 95,
      locals: [{ name: "Nwoya" }],
    },
  },
  {
    state: {
      name: "Otuke",
      id: 96,
      locals: [{ name: "Otuke" }],
    },
  },
  {
    state: {
      name: "Oyam",
      id: 97,
      locals: [{ name: "Oyam" }],
    },
  },
  {
    state: {
      name: "Pader",
      id: 98,
      locals: [{ name: "Pader" }],
    },
  },
  {
    state: {
      name: "Pallisa",
      id: 99,
      locals: [{ name: "Agule" }, { name: "Butebo" }, { name: "Pallisa" }],
    },
  },
  {
    state: {
      name: "Rakai",
      id: 100,
      locals: [{ name: "Kakuuto" }, { name: "Kooki" }, { name: "Kyotera" }],
    },
  },
  {
    state: {
      name: "Rubirizi",
      id: 101,
      locals: [{ name: "Bunyaruguru" }, { name: "Katerera" }],
    },
  },
  {
    state: {
      name: "Rukungiri",
      id: 102,
      locals: [{ name: "Rubabo" }, { name: "Rujumbura" }, { name: "Rukungiri Municipal Council" }],
    },
  },
  {
    state: {
      name: "Sembabule",
      id: 103,
      locals: [{ name: "Lwemiyaga" }, { name: "Mawogola" }],
    },
  },
  {
    state: {
      name: "Serere",
      id: 104,
      locals: [{ name: "Kasilo" }, { name: "Serere" }],
    },
  },
  {
    state: {
      name: "Sheema",
      id: 105,
      locals: [{ name: "Sheema" }],
    },
  },
  {
    state: {
      name: "Sironko",
      id: 106,
      locals: [{ name: "Budadiri" }],
    },
  },
  {
    state: {
      name: "Soroti",
      id: 107,
      locals: [{ name: "Soroti" }, { name: "Soroti Municipal Council" }],
    },
  },
  {
    state: {
      name: "Tororo",
      id: 108,
      locals: [{ name: "Tororo" }, { name: "Tororo Municipal Council" }, { name: "West Budama" }],
    },
  },
  {
    state: {
      name: "Wakiso",
      id: 109,
      locals: [{ name: "Busiro" }, { name: "Entebbe Municipal Council" }, { name: "Kyadondo" }],
    },
  },
  {
    state: {
      name: "Yumbe",
      id: 110,
      locals: [{ name: "Aringa" }],
    },
  },
  {
    state: {
      name: "Zombo",
      id: 111,
      locals: [{ name: "Okoro" }],
    },
  },
];
