import { useMutation } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import ManageModule_API from "services/ManageModuleService";

export function useEditModule() {
  return useMutation(ManageModule_API.editModule, {
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.modules.editModule);
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong editing module");
      bug.report(error);
    },
  });
}
