import admin from "api/admin";
import { stateLocations } from "utils/v1/stateLocations";
import { toast } from "react-toastify";
import farmers from "api/farmers";

//types
const GET_WEATHER_UPDATE = "GET_WEATHER_UPDATE";
const WEATHER_FETCH_ERROR = "WEATHER_FETCH_ERROR";
const TOGGLE_LOADER = "TOGGLE_LOADER";
const DAILY_FORECAST_SENT = "DAILY_FORECAST_SENT";
const WEEKLY_FORECAST_SENT = "WEEKLY_FORECAST_SENT";

//actions
export const sendDailyForecast = (forecast, state) => async (dispatch, getState) => {
  try {
    const { states } = getState().weather;
    const st = states.find(s => s.name === state);

    if (st.dailyForecastSent) {
      return toast.error("Daily Forecast already sent");
    }

    const response = await farmers.post("/test/notify/dailyForecast", {
      forecast,
      state,
    });
    toast.success(response.data.message);

    dispatch({
      type: DAILY_FORECAST_SENT,
      payload: true,
      stateId: st.id,
    });
    return;
  } catch (error) {
    toast.error(error.response.data.message || error.message);
  }
};

export const sendWeeklyForecast = (forecast, state) => async (dispatch, getState) => {
  try {
    const { states } = getState().weather;
    const st = states.find(s => s.name === state);

    if (st.weeklyForecastSent) {
      return toast.error("Weekly Forecast already sent");
    }
    const response = await farmers.post("/test/notify/weeklyForecast", {
      forecast,
      state,
    });

    toast.success(response.data.message);

    dispatch({
      type: WEEKLY_FORECAST_SENT,
      payload: true,
      stateId: st.id,
    });
  } catch (error) {
    toast.error(error.response.data.message || error.message);
  }
};
export const getForecast = id => async (dispatch, getState) => {
  try {
    dispatch({
      type: TOGGLE_LOADER,
      payload: true,
    });

    const { states } = getState().weather;

    const state = states.find(s => s.id === id);
    const { lat, long, id: stateId } = state;

    const params = {
      lat,
      lon: long,
      // stateId,
    };
    const result = await admin.get("/warehouse/getWeather", {
      params,
    });

    dispatch({
      type: GET_WEATHER_UPDATE,
      payload: result.data.data,
      stateId,
    });
    dispatch({
      type: TOGGLE_LOADER,
      payload: false,
    });
  } catch (error) {
    const message = error.response ? error.response.data.message : undefined;
    if (!message) {
      dispatch({
        type: WEATHER_FETCH_ERROR,
        payload: error.message,
      });
      dispatch({
        type: TOGGLE_LOADER,
        payload: false,
      });
    } else {
      dispatch({
        type: WEATHER_FETCH_ERROR,
        payload: message,
      });
      dispatch({
        type: TOGGLE_LOADER,
        payload: false,
      });
    }
  }
};

//reducer
const initialState = {
  states: stateLocations,
  weather: {},
  errors: {},
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WEATHER_UPDATE: {
      const stateIndex = action.stateId - 1;
      const st = state.states.map((item, index) => (index === stateIndex ? { ...item, isAvailable: true } : item));
      return {
        ...state,
        states: st,
        weather: { ...state.weather, [action.stateId]: action.payload },
      };
    }
    case DAILY_FORECAST_SENT: {
      const stateIdx = action.stateId - 1;
      const newState = state.states.map((item, index) =>
        index === stateIdx ? { ...item, dailyForecastSent: true } : item
      );
      return {
        ...state,
        states: newState,
      };
    }
    case WEEKLY_FORECAST_SENT: {
      const stIdx = action.stateId - 1;
      const newSt = state.states.map((item, index) => (index === stIdx ? { ...item, weeklyForecastSent: true } : item));
      return {
        ...state,
        states: newSt,
      };
    }
    case TOGGLE_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case WEATHER_FETCH_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};
