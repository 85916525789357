import farmerSeason from "api/farmerseason";
import { handleActionError } from "services/shared/AOSErrorService";

// actions
export const LOAD_FARMER_SEASON_DATA = "LOAD_FARMER_SEASON_DATA";
export const GET_FARMER_SEASON_DATA = "GET_FARMER_SEASON_DATA";

// action creators
export const getFarmersBySeasonWithParams = params => async dispatch => {
  try {
    dispatch({ type: LOAD_FARMER_SEASON_DATA, payload: true });

    const farmerData = await farmerSeason.get("getFarmerBySeasonWithParams", { params, ignoreOrgId: true });

    dispatch({
      type: GET_FARMER_SEASON_DATA,
      payload: farmerData.data.data,
      farmersMeta: farmerData?.data?.meta || {},
    });

    dispatch({ type: LOAD_FARMER_SEASON_DATA, payload: false });
  } catch (getFarmersBySeasonWithParamsError) {
    dispatch({ type: LOAD_FARMER_SEASON_DATA, payload: false });
    handleActionError(getFarmersBySeasonWithParamsError);
  }
};
export const searchFarmersBySeasonWithParams = (params, cancelToken) => async dispatch => {
  try {
    dispatch({ type: LOAD_FARMER_SEASON_DATA, payload: true });
    const farmerSearchData = await farmerSeason.get("search", { params, cancelToken });

    dispatch({
      type: GET_FARMER_SEASON_DATA,
      payload: farmerSearchData.data.data,
      farmersMeta: farmerSearchData?.data?.meta || {},
    });

    dispatch({ type: LOAD_FARMER_SEASON_DATA, payload: false });
  } catch (searchAllFarmersWithParamsError) {
    dispatch({ type: LOAD_FARMER_SEASON_DATA, payload: false });
    handleActionError(searchAllFarmersWithParamsError);
  }
};

//   state
const initialState = {
  loading: false,
  farmerData: [],
  farmersMeta: {},
  loadingSearch: false,
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FARMER_SEASON_DATA:
      return {
        ...state,
        farmerData: action.payload, //action.payload,
        farmersMeta: action.farmersMeta,
        loading: false,
      };
    case LOAD_FARMER_SEASON_DATA:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export const getFarmerSeasonData = state => state.legacyFarmerSeason.farmerData;
export const getLoadingFarmerSeason = state => state.legacyFarmerSeason.loading;
export const getFarmerSeasonMeta = state => state.legacyFarmerSeason.farmersMeta;
