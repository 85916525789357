import { DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger } from "components/dropdown";
import { Modal, ModalContent } from "components/modal";
import { useAdvancedExport, useDataFilter, useDidMountEffect, useDisclosure, useQuickExport } from "hooks";
import { Button, Flex, Icon, Text } from "organic";
import React, { useState } from "react";
import { AdvancedExportMetasList } from ".";
import { toast } from "react-toastify";

const ExportButton = ({ quickExportConfig, advancedExportConfig, noQuickExport, noAdvancedExport }) => {
  const [columnWhitelist, setColumnWhitelsit] = useState([]);

  const advancedExportModal = useDisclosure();
  const { filterParams, isValidFilter } = useDataFilter();

  const { csvDownload, loadingDownload } = useQuickExport({
    ...quickExportConfig,
  });

  const { exportKeys, exportStatus, requestExport, loadingExport } = useAdvancedExport({
    ...advancedExportConfig,
    columnWhitelist,
    filterParams,
  });

  const handleAdvancedExport = () => {
    requestExport();
  };
  const handleQuickExport = () => {
    csvDownload();
  };
  useDidMountEffect(() => {
    if (exportStatus === "failure") {
      toast.error("Advanced Export Failed");
    }
  }, [exportStatus]);

  return (
    <>
      <DropdownMenu>
        <DropdownTrigger>
          <Flex bg="primary600" px="1rem" height="44px" alignItems="center">
            <Icon icon="download" color="white" size={20} />{" "}
            <Text as="span" font="smSemibold" color="white" ml="0.5rem">
              Export
            </Text>
          </Flex>
        </DropdownTrigger>
        <DropdownContent unstyled>
          {!noQuickExport ? (
            <DropdownItem onSelect={handleQuickExport} disabled={loadingDownload || !isValidFilter}>
              <Icon icon="download-cloud" size={16} />
              <Text as="span" ml="0.5rem">
                Quick Export
              </Text>
            </DropdownItem>
          ) : null}
          {!noAdvancedExport ? (
            <DropdownItem onSelect={() => advancedExportModal.onOpen()}>
              <Icon icon="send" size={16} />
              <Text as="span" ml="0.5rem">
                Advance Export
              </Text>
            </DropdownItem>
          ) : null}
        </DropdownContent>
      </DropdownMenu>
      <Modal
        open={advancedExportModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            advancedExportModal.onClose();
          }
        }}
      >
        <ModalContent title="Export Data to Your Email">
          <Flex flexDirection="column" alignItems="center" mt="2rem">
            <AdvancedExportMetasList exportKeys={exportKeys} onChange={setColumnWhitelsit} />
            <Flex mt="1rem">
              <Button onClick={handleAdvancedExport} isDisabled={loadingExport || !isValidFilter}>
                Export Data
              </Button>
            </Flex>
            <Text color="error500">
              {!isValidFilter ? "please set a valid filter, select at least a project and region" : ""}
            </Text>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export { ExportButton };
