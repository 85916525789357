/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import farmersApi from "api/farmers";
import { PageWrapper } from "components/layout";
import { useFeedbackModal, useCustomModal, useTable } from "hooks";
import { legacyFormatDate } from "utils/v1/tableUtils";
import { searchAllFarmersWithParams } from "features/v1/farmers";
import { Badge, Box, Button, Flex, Icon, Text, badgeConstants } from "organic";
import { Table } from "components/table";
import { capitalize, validateUUID } from "utils";
import { ExportButton } from "components/export";
import farmerSeason from "api/farmerseason";
import { PageHeader } from "components/layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetAllContractualMembers } from "hooks/app/farmer";
import { format } from "date-fns";
import EditBasicInformation from "./onboarding/editBasicInformation";
import EditLoginDetails from "./onboarding/editLoginDetails";
import { handleFormSubmission } from "services/formSubmissionService";
import { toast } from "react-toastify";
import { useUpdateFarmer } from "hooks/app/farmer/useUpdateFarmer";
import { useGetContractualFarmerLogin } from "hooks/app/farmer";

const tabType = {
  LOGIN_DETAILS: "Approve",
  BASIC_INFO: "reject",
};

const tabList = [
  {
    name: tabType.LOGIN_DETAILS,
    text: "Login Details",
  },
  {
    name: tabType.BASIC_INFO,
    text: "Basic Information",
  },
];

const IconWrapper = styled.div`
  transform: translateY(2px);
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.cols}, minmax(0, 1fr))`};
  gap: 1.5rem;
`;

const FarmerDetailsPage = ({ edit }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { data: contractualFarmersResponse, isLoading } = useGetAllContractualMembers({
    params: { agentId: params.id },
  });

  const { data: loginDto, isLoading: isFetchingPassword } = useGetContractualFarmerLogin({
    params: { id: params.id },
  });
  const [leadFarmer, setLeadFarmer] = useState(null);
  const [activeTab, setActiveTab] = useState(edit ? tabType.BASIC_INFO : tabType.LOGIN_DETAILS);

  const dispatch = useDispatch();

  const farmerExportModal = useCustomModal();
  const farmerExportFeedbackModal = useFeedbackModal();

  const { tableProps, selectedRow: selectedFarmer } = useTable({
    data: contractualFarmersResponse?.data || [],
    columns: [
      // {
      //   Header: "ID",
      //   accessor: "ta_number",
      // },
      {
        id: "farmerSerialId",
        Header: "ID",
        accessor(farmer) {
          return validateUUID(farmer?.farmer_id) ? "Not Yet Assigned" : farmer.farmer_id;
        },
      },
      {
        id: "farmerFullName",
        Header: "Name",
        accessor: farmer => `${farmer?.first_name || "N/A"} ${farmer?.last_name || "N/A"}`,
      },
      {
        id: "farmerSyncedDate",
        Header: "Date",
        accessor: farmer => format(new Date(farmer?.created_at), "yyyy-MM-dd"),
      },
      {
        Header: "Phone",
        accessor: "phone_number",
      },

      {
        Header: "Gender",
        accessor: farmer => (farmer.gender ? capitalize(farmer.gender) : "--"),
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "LGA",
        accessor: farmer => (farmer.lga ? farmer.lga : "--"),
      },
      {
        Header: "District",
        accessor: "district",
      },
      {
        Header: "Role",
        accessor: "isContractual",
        Cell: FarmerRoleBadge,
      },
    ],
    title: "All Members",
    noActions: true,
  });

  // api calls
  const quickFarmersExport = async () => {
    const data = (await farmerSeason.get("downloadFarmers", { params: tableProps.filterParams })).data;
    return data;
  };

  const getFarmersMetaKeys = async () => {
    return await farmersApi.get("downloadFarmers/getMetaKeys");
  };
  const advanceFarmersExport = async ({ columnWhitelist, filterParams }) => {
    let params = { columnWhitelist, ...filterParams };
    const res = await farmersApi.get("downloadFarmers/advance", { params });
    return res;
  };

  const [formIsValid, setFormIsValid] = useState(true);

  const [loginDetails, setLoginDetails] = useState({
    phone_number: "",
    password: "",
  });

  const [basicInfo, setBasicInfo] = useState({
    // seasonId: "",
    // region: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    gender: "",
    phone_number: "",
    age: 0,
    country: "",
    state: "",
    lga: "",
    village: "",
    district: "",
    address: "",
  });

  useEffect(() => {
    if (!isFetchingPassword) {
      setLoginDetails({
        phone_number: loginDto.phone_number,
        password: loginDto.id,
      });
    }
  }, [isFetchingPassword]);

  useEffect(() => {
    if (!isLoading) {
      const leadFarmer = contractualFarmersResponse?.data?.find(cf => cf.isContractual);
      setLeadFarmer(leadFarmer);
      setBasicInfo({
        // seasonId: leadFarmer.season.seasonId,
        // region: "",
        first_name: leadFarmer.first_name,
        last_name: leadFarmer.last_name,
        middle_name: leadFarmer.middle_name,
        email: leadFarmer.email,
        gender: leadFarmer.gender,
        phone_number: leadFarmer.phone_number,
        age: leadFarmer.age,
        country: leadFarmer.country,
        state: leadFarmer.state,
        lga: leadFarmer.lga,
        village: leadFarmer.village ? leadFarmer.village : "",
        district: leadFarmer.district,
        address: leadFarmer.address ? leadFarmer.address : "",
      });
    }
  }, [isLoading]);

  const { mutateAsync: updateFarmerAsync, isLoading: updateFarmerLoading } = useUpdateFarmer({
    agentId: leadFarmer?.agent?.agent_id,
    id: leadFarmer?.farmer,
  });

  const updateFarmer = async values => {
    const payload = values;

    await handleFormSubmission({
      submitFn: updateFarmerAsync,
      values: payload,
      config: {},
      errorMsg: "Failed to update contractual farmer",
      successMsg: "",
      onSuccess() {
        toast.success("Contractual farmer updated successfully");
      },
      onError(error) {
        console.log({ error });
      },
    });
  };

  return (
    <>
      <PageWrapper>
        {/* pageHeader Section */}
        <Flex flexDirection="row" justifyContent="space-between" align-Items="center">
          <PageHeader title="Farm > Onboarding" subtitle="Your farmers summary and activity." />

          <Box display="none">
            <ExportButton
              quickExportConfig={{
                apiCall: quickFarmersExport,
                fileName: `AOSFarmersQuickExport${legacyFormatDate(new Date())}.csv`,
              }}
              advancedExportConfig={{
                apiCall: advanceFarmersExport,
                getKeys: getFarmersMetaKeys,
                modal: farmerExportModal,
                feedbackModal: farmerExportFeedbackModal,
              }}
            />
          </Box>
        </Flex>
        <Flex
          px="24px"
          py="20px"
          alignItems="center"
          gap="0.8rem"
          backgroundColor="white"
          borderTopRightRadius="8px"
          borderTopLeftRadius="8px"
          borderWidth="1px"
          borderColor="gray200"
          borderStyle="solid"
        >
          <Link to="/dashboard/farm/onboarding">
            <Flex gap="0.8rem" alignItems="center">
              <IconWrapper>
                <Icon icon="arrow-left" color="#344054" size="20px" />
              </IconWrapper>
              <Text color="gray900" font="lgMedium">
                Back to Onboarding
              </Text>
            </Flex>
          </Link>
          <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>ID {leadFarmer?.farmer_id}</Badge>
        </Flex>
        <Box p="24px" borderWidth="1px" borderColor="gray200" borderStyle="solid">
          {/* Tabs */}
          <Flex
            p="4px"
            alignItems="center"
            gap="8px"
            backgroundColor="gray50"
            borderRadius="0.5rem"
            borderWidth="1px"
            borderColor="gray100"
            borderStyle="solid"
          >
            {tabList.map((tab, index) => (
              <Flex
                key={index}
                onClick={() => setActiveTab(tab.name)}
                flex="1"
                bg={`${activeTab === tab.name ? "white" : "transparent"}`}
                borderRadius="6px"
                justifyContent="center"
                boxShadow="xs"
                p="8px 12px"
                cursor="pointer"
              >
                <Text color={`${activeTab === tab.name ? "gray700" : "gray500"}`} font="smMedium">
                  {tab.text}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Box height="1px" backgroundColor="gray200" my="16px"></Box>
          {/* Tabs content */}
          {activeTab === tabType.LOGIN_DETAILS && <EditLoginDetails loginDetails={loginDetails} />}
          {activeTab === tabType.BASIC_INFO && edit && (
            <EditBasicInformation basicInfo={basicInfo} setBasicInfo={setBasicInfo} setFormIsValid={setFormIsValid} />
          )}
          {activeTab === tabType.BASIC_INFO && !edit && (
            <GridContainer cols={3}>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  First Name
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.first_name}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  Last Name
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.last_name}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  Middle Name
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.middle_name || "--"}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  Phone
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.phone_number}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  Email
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.agent?.email}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  Gender
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.gender ? capitalize(leadFarmer.gender) : "--"}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  Age
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.age}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  Country
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.country}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  State
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.first_name}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  LGA
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.state}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text font="smSemibold" color="#A6A6A6">
                  District
                </Text>
                <Text font="mdSemibold" color="#2B2B2B">
                  {leadFarmer?.district}
                </Text>
              </Flex>
            </GridContainer>
          )}
        </Box>
        <Box>
          {/* table */}
          <Table
            {...tableProps}
            onSearch={(params, { abortController }) => {
              dispatch(
                searchAllFarmersWithParams(
                  params,
                  { abortController } // cancel token
                )
              );
            }}
            onDataFilter={({ seasonId, region, dateRange, nPerPage, page }) => {}}
          />
        </Box>
        {edit && (
          <Flex gap="20px" justifyContent="flex-end">
            <Button width="fit-content" type="button" colorScheme="secondaryGray" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateFarmer(basicInfo);
              }}
              width="fit-content"
              type="submit"
              isDisabled={!formIsValid || updateFarmerLoading}
            >
              {updateFarmerLoading ? "Saving..." : "Save Changes"}
            </Button>
          </Flex>
        )}
      </PageWrapper>
    </>
  );
};

const FarmerRoleBadge = ({ value }) => (
  <Badge colorScheme={value ? badgeConstants.colorSchemes.PRIMARY : badgeConstants.colorSchemes.GRAY}>
    {value ? "Contractual Farmer" : "Member"}
  </Badge>
);

// const FarmerBvnStatusBadge = () => {
//   return <Badge colorScheme={badgeConstants.colorSchemes.GRAY}>N/A</Badge>;
// };

export default FarmerDetailsPage;
