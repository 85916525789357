import React, { useMemo } from "react";
import { Text } from "organic";
import PropTypes from "prop-types";

const DataGridTitlePropTypes = {
  title: PropTypes.string,
  center: PropTypes.bool,
};

const DataGridTitle = props => {
  const { title, center = false } = props;
  const MemoizedTitle = useMemo(
    () => (
      <Text font="lgBold" mt={4} mb={3} textAlign={(center && "text-center") || "left"}>
        {title}
      </Text>
    ),
    [center, title]
  );
  return MemoizedTitle;
};

DataGridTitle.propTypes = DataGridTitlePropTypes;

export default DataGridTitle;
