import { CreatableSelect } from "components/select";
import { updateFarmSupervisionCategory } from "features/v1/farmMonitoring";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import React from "react";
import { useDispatch } from "react-redux";

const AddSubcategories = ({ selected, modal }) => {
  const dispatch = useDispatch();
  const handleSubcategoryChange = (newValue, { actionMeta, setFieldValue }) => {
    switch (actionMeta.action) {
      case "clear": {
        // answer options is cleared
        setFieldValue("sub_category", []);
        return;
      }
      case "create-option": {
        // new answer options is manually entered
        setFieldValue(
          "sub_category",
          newValue.map(val => val?.value || val)
        );
        return;
      }
      case "select-option": {
        // an answer option that was previously removed was reselected
        setFieldValue(
          "sub_cateogry",
          newValue.map(val => val?.value || val)
        );
        return;
      }
      case "remove-value": {
        if (!newValue) {
          setFieldValue("sub_category", []);
          return;
        }
        setFieldValue(
          "sub_category",
          newValue.map(val => val?.value || val)
        );
        // an answer option that was created is removed from list (still available to reselect)
        return;
      }
      default: {
        return;
      }
    }
  };

  const handleUpdateFarmSupervisionSubCategory = (values, formOptions) => {
    dispatch(
      updateFarmSupervisionCategory(
        { sub_category: values.sub_category },
        { formOptions, modal, id: selected._id, season: selected.season }
      )
    );
  };
  return (
    <div>
      {" "}
      <Formik
        initialValues={{
          category: selected.category,
          sub_category: selected.sub_category,
          season: selected.season,
          organization: selected.organization,
        }}
        onSubmit={handleUpdateFarmSupervisionSubCategory}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex>
              <Box width="100%">
                <CreatableSelect
                  isMulti
                  isClearable
                  name="sub_category"
                  components={{ DropdownIndicator: null }}
                  label="Subcategories"
                  defaultValue={selected.sub_category}
                  getOptionLabel={option => option?.label || option}
                  getOptionValue={option => option?.value || option}
                  placeholder="type a sub category and Hit Enter"
                  onChange={(newValue, actionMeta) => {
                    handleSubcategoryChange(newValue, { actionMeta, setFieldValue, formValues: values });
                  }}
                />
              </Box>
            </Flex>

            <Box mt="2rem">
              <Button display="block" type="submit" text="Update" />
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSubcategories;
