import { Select } from "components/select";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import hectareSizeOptions from "./hectareSizeSelectOptions";

const HectareSizeSelect = ({ onChange, defaultValue }) => {
  const [selectedHectareSize, setSelectedHectareSize] = useState();

  const handleSelectHectareSize = value => {
    let selectedHectareSizeOption = selectOptionFromValue(hectareSizeOptions, value);
    if (selectedHectareSizeOption) setSelectedHectareSize(selectedHectareSizeOption);
    if (_.isFunction(onChange)) onChange(value);
  };

  useEffect(() => {
    if (defaultValue) {
      let defaultHectareSizeOption = selectOptionFromValue(hectareSizeOptions, defaultValue);
      if (defaultHectareSizeOption) setSelectedHectareSize(defaultHectareSizeOption);
    }
  }, []);

  return (
    <Select
      options={hectareSizeOptions}
      value={selectedHectareSize}
      placeholder={"select an approximated hectare size"}
      onChange={handleSelectHectareSize}
    />
  );
};

export default HectareSizeSelect;
