import styled, { css } from "styled-components/macro";
import { GREY_LIGHT } from "../constants";

export const CsvDropZone = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 24px;
    width: 100%;
    border: 1px dashed ${theme.colors.gray200};
    border-radius: 8px;
    background: ${theme.colors.white};
    cursor: pointer;
    position: relative;
    align-self: stretch;
    max-width: 100vw;
  `}

  ${({ zoneHover, theme }) => {
    if (zoneHover === true) {
      return css`
        border: 2px dashed ${theme.colors.gray500};
        background: #ddd;
      `;
    }
  }}
`;

export const CsvFile = styled.div`
background: #EEE:
border-radius: 20px;
display: flex;
width: 100%;
zIndex: 10;
flex-direction: column;
justify-content: center;
`;

export const CsvFileInfo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CsvFileSize = styled.div`
  background-color: ${GREY_LIGHT};
  border-radius: 3rem;
  margin-bottom: 0.5em;
  justify-content: center;
  display: flex;

  color: var(--gray-500, #667085);

  /* Text sm/Regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const CsvFileName = styled.div`
  max-width: 300px;
  overflow: hidden;

  color: var(--gray-700, #344054);

  /* Text sm/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const CsvProgressBar = styled.div`
  ${props => css`
    bottom: 14px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    background: ${props.theme.colors.primary600} !important;
  `}
`;

export const CsvRemove = styled.div`
  height: 20px;
  right: 10px;
  top: 6px;
  width: 20px;
  position: absolute;
`;

export const CsvFileSelect = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CsvFileSelectIcon = styled.div`
  ${({ theme }) => css`
    border-radius: 28px;
    border: 6px solid ${theme.colors.gray50};
    background: ${theme.colors.gray100};
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
  `}
`;
export const CsvFileSelecedtIcon = styled.div`
  ${({ theme }) => css`
    border-radius: 28px;
    border: 6px solid ${theme.colors.primary50};
    background: ${theme.colors.primary100};
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
  `}
`;

export const CsvFileSelectTitle = styled.div`
  color: var(--gray-700, #344054);

  /* Text sm/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const CsvFileSelectButton = styled.div``;

export const CsvFileSelectSubtitle = styled.div`
  color: var(--gray-500, #667085);

  /* Text xs/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

export const CsvFileTemplateButton = styled.div`
  z-index: 70;
`;
