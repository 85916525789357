import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { deleteQuestionnaire, getAllQuestionnaire, getQuestionnaireData } from "features/v1/questionnaire";
import { useDisclosure, useTable } from "hooks";
import { Badge, badgeConstants } from "organic";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateQuestionnaireForm from "views/forms/createQuestionnaireForm";
import EditQuestionnaireDetails from "./questionnaire/editQuestionnaireDetails";
import {
  checkForQuestionnaireType,
  getQuestionnaireModelBadge,
  surveyTypes,
} from "utils/questionnaire-utils/questionnaire-utils";
import UpdateMarketplaceSurveyPrices from "./questionnaire/updateMarketplaceSurveyPrices";

const UPDATE_MARKETPLACE_KEY = "updateMaketplace";

const QuestionnaireSettingsPage = () => {
  const allQuestionnaire = useSelector(getQuestionnaireData);
  const [currentView, setCurrentView] = useState("tableView");

  const dispatch = useDispatch();
  const createQuestionnaireDisclosure = useDisclosure();
  const updateMarkplacePricesDisclosure = useDisclosure();

  const { tableProps, selectedRow: selectedQuestionnaire } = useTable({
    data: allQuestionnaire,
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        id: "questionnaireCategory",
        Header: "Farm Category",
        accessor: questionnaire =>
          questionnaire?.farm_operation_category?.length && questionnaire?.farm_operation_category.toString(),
      },

      {
        id: "questionnaireFarmType",
        Header: "Farm Type",
        accessor: questionnaire => questionnaire?.category_type?.length && questionnaire?.category_type.toString(),
      },
      {
        id: "questionsCount",
        Header: "No. of Questions",
        accessor: _questionnaire => _questionnaire.questions.length || 0,
      },
      {
        id: "questionnaireModel",
        Header: "Questionnaire Type",
        accessor: "model",
        Cell: QuestionnaireModelBadge,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: QuestionnaireStatusBadge,
      },
    ],
    title: "All Questionnaires",
    noDateRangeFilter: true,
    noSearch: true,
    canDelete: true,
    showCheckboxes: false,
    useAlternatePagination: false,
    noViewAction: true,
  });

  const handleGetExtractions = _questionnaire => {
    if (checkForQuestionnaireType({ questionnaire: _questionnaire, type: surveyTypes.MARKETPLACE_SURVEY })) {
      return [{ icon: "hash", label: "Set Crop Prices", actionKey: UPDATE_MARKETPLACE_KEY }];
    }
  };

  useEffect(() => {
    dispatch(getAllQuestionnaire());
  }, []);

  return (
    <div>
      {currentView === "tableView" ? (
        <Table
          {...tableProps}
          createAction={{ label: "Add New Questionnaire" }}
          onCreateAction={() => {
            createQuestionnaireDisclosure.onOpen();
          }}
          onEditAction={() => {
            setCurrentView("questionnaireEditView");
          }}
          onDeleteAction={() => {
            dispatch(deleteQuestionnaire({ id: selectedQuestionnaire._id }));
          }}
          getExtraActions={handleGetExtractions}
          onExtraAction={selectedAction => {
            if (selectedAction.actionKey === UPDATE_MARKETPLACE_KEY) {
              updateMarkplacePricesDisclosure.onOpen();
            }
          }}
          noViewAction
        />
      ) : (
        <EditQuestionnaireDetails selected={selectedQuestionnaire} onBack={() => setCurrentView("tableView")} />
      )}
      <Modal {...createQuestionnaireDisclosure.modalProps}>
        <ModalContent title="Add New Questionnaire">
          <CreateQuestionnaireForm modal={createQuestionnaireDisclosure.modal} />
        </ModalContent>
      </Modal>
      <Modal {...updateMarkplacePricesDisclosure.modalProps}>
        <ModalContent title="Set Crop Prices">
          <UpdateMarketplaceSurveyPrices
            selected={selectedQuestionnaire}
            modal={updateMarkplacePricesDisclosure.modal}
          />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default QuestionnaireSettingsPage;

const QuestionnaireStatusBadge = ({ value }) => (
  <Badge colorScheme={value === "active" ? badgeConstants.colorSchemes.SUCCESS : badgeConstants.colorSchemes.ERROR}>
    {value || "N/A"}
  </Badge>
);
const QuestionnaireModelBadge = ({ value }) => {
  const colorScheme = getQuestionnaireModelBadge(value);
  return <Badge colorScheme={colorScheme}>{value || "N/A"}</Badge>;
};
