import React, { useEffect, useState } from "react";
import { Select } from ".";
import { useDispatch, useSelector } from "react-redux";
import { getAllCrops, getCropOptions, getLoadingCurSeasonCrops } from "features/v1/cropsAndInput";
import { isFunction } from "utils";
import { selectOptionFromExtraField, selectOptionFromValue } from "utils/v1/selectUtils";
import { useDidMountEffect } from "hooks";

export const CROP_CATEGORY_ENUM = ["crop", "livestock"];

const CropSelect = ({ isMulti, getOptionValue, onChange, defaultValue, categories = [], ...restOfCropSelectProps }) => {
  const [selectedCrop, setSelectedCrop] = useState();
  const [_cropOptions, _setCropOptions] = useState([]);

  const dispatch = useDispatch();

  let cropOptions = useSelector(getCropOptions);
  let loadingCrops = useSelector(getLoadingCurSeasonCrops);

  const filterOptionsByCategory = (_categories = []) => {
    const testValidCategory = value => CROP_CATEGORY_ENUM.includes(value);
    // make sure we're filtering with only the accepted enum of crop categories
    if (!_categories.every(cat => testValidCategory(cat))) return [];

    return cropOptions.filter(cropOpt => {
      return _categories.includes(cropOpt.crop_category);
    });
  };

  const handleCropChange = val => {
    if (!val) {
      setSelectedCrop(null);
      return;
    }
    let selected;
    let onChangeValue;

    if (isMulti) {
      selected = val;
      if (Array.isArray(selected)) {
        setSelectedCrop(selected);
      }
    }

    if (!isMulti) {
      selected = selectOptionFromValue(cropOptions, val);
      if (selected) {
        setSelectedCrop(selected);
      }
    }
    if (isFunction(getOptionValue) && isMulti) {
      onChangeValue = selected.map(item => getOptionValue(item));
    }
    if (isFunction(getOptionValue) && !isMulti) {
      onChangeValue = getOptionValue(selected);
    }

    if (!getOptionValue) {
      onChangeValue = selected.value;
    }

    if (isFunction(onChange)) onChange(onChangeValue);
  };

  const initialiseCropSelect = React.useCallback(async () => {
    dispatch(
      getAllCrops(
        {},
        {
          onSuccess: () => {
            let _defaultSelected;
            const resolveDefaultValueOption = isFunction(getOptionValue)
              ? selectOptionFromExtraField
              : selectOptionFromValue;
            if (defaultValue && isMulti) {
              _defaultSelected = defaultValue.map(_val =>
                resolveDefaultValueOption(cropOptions, _val, { resolver: getOptionValue })
              );
              if (_defaultSelected) {
                setSelectedCrop(_defaultSelected);
              } else {
                setSelectedCrop([]);
              }
            }

            if (defaultValue && !isMulti) {
              _defaultSelected = resolveDefaultValueOption(cropOptions, defaultValue, { resolver: getOptionValue });
              if (_defaultSelected) {
                setSelectedCrop(_defaultSelected);
              } else {
                setSelectedCrop(null);
              }
            }
          },
        }
      )
    );
  }, []);

  useEffect(() => {
    initialiseCropSelect();
  }, []);

  useDidMountEffect(() => {
    if (!categories?.length) {
      _setCropOptions(cropOptions);
    }
    if (categories?.length) {
      const filteredOptions = filterOptionsByCategory(categories);
      _setCropOptions(filteredOptions);
    }
  }, [categories, cropOptions]);
  return (
    <Select
      {...restOfCropSelectProps}
      onChange={handleCropChange}
      options={_cropOptions}
      value={selectedCrop}
      disabled={loadingCrops || restOfCropSelectProps.disabled}
      placeholder={loadingCrops ? "Loading Crops..." : "Select a Crop"}
      isMulti={isMulti}
    />
  );
};

export { CropSelect };
