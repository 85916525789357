import {
  farmerApprovedPaymentRequestStatus,
  // farmerPaymentDateRange,
  farmerPaymentRequestStatus,
  getFarmerPaymentsDataNonPaginated,
} from "features/v1/farmerPayment";
import { useDataFilter } from "hooks";
import { Text } from "organic";
import React from "react";
import { useSelector } from "react-redux";
import { financePage } from "routes/dashboardRoutes";

const PaymentsSummaryTiles = ({ page }) => {
  const { filterParams } = useDataFilter();
  let farmerPaymentsDataNonPaginated = useSelector(getFarmerPaymentsDataNonPaginated);

  // Filter records by date
  const fromDate = new Date(filterParams.dateRange[0]);
  const toDate = new Date(filterParams.dateRange[1]);

  farmerPaymentsDataNonPaginated = farmerPaymentsDataNonPaginated.filter(p => {
    const createdAt = new Date(p.created_at);
    return createdAt >= fromDate && createdAt <= toDate;
  });

  const paymentsData = [
    {
      title: "Total Payments",
      value: farmerPaymentsDataNonPaginated?.length,
      amount: farmerPaymentsDataNonPaginated.reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated.reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Total Approved",
      value: farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED).length,
      amount: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED)
        .reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED)
        .reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Total Rejected",
      value: farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.REJECTED).length,
      amount: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.REJECTED)
        .reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.REJECTED)
        .reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Total Pending",
      value: farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.PENDING).length,
      amount: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.PENDING)
        .reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.PENDING)
        .reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Average price per kg",
      value: farmerPaymentsDataNonPaginated.length
        ? (
            farmerPaymentsDataNonPaginated.reduce((acc, cum) => acc + cum.amount, 0) /
            farmerPaymentsDataNonPaginated.reduce((acc, cum) => acc + cum.unit, 0)
          ).toFixed(2)
        : 0,
      showValue: true,
    },
  ];

  const managersData = [
    {
      title: "Total",
      value: farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED).length,
      amount: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED)
        .reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated
        ?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED)
        .reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Payout",
      value: farmerPaymentsDataNonPaginated?.filter(
        p =>
          p.status === farmerPaymentRequestStatus.APPROVED &&
          p.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED
      ).length,
      amount: farmerPaymentsDataNonPaginated
        ?.filter(
          p =>
            p.status === farmerPaymentRequestStatus.APPROVED &&
            p.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED
        )
        .reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated
        ?.filter(
          p =>
            p.status === farmerPaymentRequestStatus.APPROVED &&
            p.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED
        )
        .reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Total Declined",
      value: farmerPaymentsDataNonPaginated?.filter(
        p =>
          p.status === farmerPaymentRequestStatus.APPROVED &&
          p.payment_status === farmerApprovedPaymentRequestStatus.DECLINED
      ).length,
      amount: farmerPaymentsDataNonPaginated
        ?.filter(
          p =>
            p.status === farmerPaymentRequestStatus.APPROVED &&
            p.payment_status === farmerApprovedPaymentRequestStatus.DECLINED
        )
        .reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated
        ?.filter(
          p =>
            p.status === farmerPaymentRequestStatus.APPROVED &&
            p.payment_status === farmerApprovedPaymentRequestStatus.DECLINED
        )
        .reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Pending",
      value: farmerPaymentsDataNonPaginated?.filter(
        p =>
          p.status === farmerPaymentRequestStatus.APPROVED &&
          p.payment_status === farmerApprovedPaymentRequestStatus.PENDING
      ).length,
      amount: farmerPaymentsDataNonPaginated
        ?.filter(
          p =>
            p.status === farmerPaymentRequestStatus.APPROVED &&
            p.payment_status === farmerApprovedPaymentRequestStatus.PENDING
        )
        .reduce((acc, cum) => acc + cum.amount, 0),
      unit: farmerPaymentsDataNonPaginated
        ?.filter(
          p =>
            p.status === farmerPaymentRequestStatus.APPROVED &&
            p.payment_status === farmerApprovedPaymentRequestStatus.PENDING
        )
        .reduce((acc, cum) => acc + cum.unit, 0),
    },
    {
      title: "Average price per kg",
      value: farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED).length
        ? (
            farmerPaymentsDataNonPaginated
              ?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED)
              .reduce((acc, cum) => acc + cum.amount, 0) /
            farmerPaymentsDataNonPaginated
              ?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED)
              .reduce((acc, cum) => acc + cum.unit, 0)
          ).toFixed(2)
        : 0,
      showValue: true,
    },
  ];

  const data = page === financePage.PAYMENT ? paymentsData : managersData;

  return (
    <div className="payments-summary">
      <div className="payments-summary__grid">
        {data.map((d, index) => {
          return (
            <div className="payments-summary__gridItem" key={index}>
              <div className="payments-summary__gridItem__Title">{d.title}</div>
              <div className="payments-summary__gridItem__Value">
                {d.showValue && <span>{d?.value?.toLocaleString("en-US") || "--"}&nbsp;</span>}
                {d["amount"] !== undefined ? <span>{d?.amount?.toLocaleString("en-US")}</span> : null}
              </div>
              {d["unit"] !== undefined ? (
                <div className="payments-summary__gridItem__Title">
                  <Text as="span" font="smBold" color="gray500">
                    Units:
                  </Text>{" "}
                  {d.unit.toLocaleString("en-US")}kg
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentsSummaryTiles;
