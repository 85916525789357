import { FormInput } from "components/inputs";
import { ProjectSelect, Select } from "components/select";

// import LegacyProjectSelect from "components/v1/Select/ProjectSelect";

import { createCropForSeason } from "features/v1/cropsAndInput";
import { Form, Formik } from "formik";
import { Box, Button } from "organic";
import React from "react";
import { useDispatch } from "react-redux";
import { createCropValidationSchema } from "validators/cropsValidationSchemas";
import { initialCreateCropValues } from "views/v1/Forms/Settings/settingsInitialValues";

function CreateCropForm({ modal }) {
  const dispatch = useDispatch();

  const handleCreateSeasonCrop = (values, formOptions) => dispatch(createCropForSeason(values, { formOptions, modal }));
  return (
    <div>
      <Formik
        initialValues={initialCreateCropValues}
        validationSchema={createCropValidationSchema}
        onSubmit={handleCreateSeasonCrop}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <Box my={40}>
              <Box mb={4}>
                <FormInput name="name" placeholder="Name of Crop" label="Crop Name" required />
              </Box>
              <Box mb={4}>
                <Select
                  options={[
                    { label: "Crop", value: "crop" },
                    { label: "Livestock", value: "livestock" },
                  ]}
                  name="crop_category"
                  label="Crop or Livesctock"
                  required
                  onChange={val => setFieldValue("crop_category", val)}
                />
              </Box>

              <Box mb={4}>
                <ProjectSelect
                  name="season"
                  label="Select a Season"
                  ignoreGlobalState
                  required
                  onChange={val => setFieldValue("season", val)}
                />
              </Box>

              <Button colorScheme="primary" display="block" disabled={isSubmitting} type="submit">
                Add Crop To Season
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateCropForm;
