import React from "react";
import { CropSelect } from "components/select";
import { useFormikContext } from "formik";

export const CropTypeField = () => {
  const { setFieldValue, initialValues } = useFormikContext();
  return (
    <CropSelect
      label="Crop Type"
      name="crop_type"
      required
      onChange={val => {
        setFieldValue("crop_type", val);
      }}
      getOptionValue={selected => selected.name}
      defaultValue={initialValues?.crop_type || undefined}
    />
  );
};
