import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getStoreManagers, getLoadingAdmins, getAllAdmins } from "features/v1/auth";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { getLoadingWarehouse } from "features/v1/warehouse";
import { createSelectOption, selectOptionFromValue } from "utils/v1/selectUtils";
import { Select } from ".";

const createStoreManagerOptions = managers => {
  return managers.map((manager, __managerIndex) => {
    return createSelectOption(`${manager?.first_name} ${manager?.last_name} (${manager?.phone_number})`, manager._id);
  });
};

const StoreManagerSelect = props => {
  const { onChange, label } = props;

  const dispatch = useDispatch();

  const [selectedStoreManager, setSelectedStoreManager] = useState();
  const [storeManagerOptions, setStoreManagerOptions] = useState();

  const storeManagers = useSelector(getStoreManagers);
  const loadingWarehouse = useSelector(getLoadingWarehouse);
  const loadingAdmins = useSelector(getLoadingAdmins);

  const _handleChange = selectedValue => {
    setSelectedStoreManager(() => selectOptionFromValue(storeManagerOptions, selectedValue));
    if (_.isFunction(onChange)) onChange(selectedValue);
  };

  useEffect(() => {
    dispatch(getAllAdmins());
  }, []);

  useEffect(() => {
    if (storeManagers.length > 0) setStoreManagerOptions(() => createStoreManagerOptions(storeManagers));
  }, [storeManagers]);

  return (
    <>
      {loadingWarehouse || loadingAdmins ? (
        <PulseLoader color="#3BC552" size={20} />
      ) : (
        <Select
          value={selectedStoreManager}
          options={storeManagerOptions}
          onChange={_handleChange}
          placeholder="Select a Store Manager"
          label={label}
        />
      )}
    </>
  );
};

export { StoreManagerSelect };
