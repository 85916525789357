import React, { useState } from "react";
import { Box } from "organic";
import { Table } from "components/table";
import { Modal, ModalContent } from "components/modal";
import { useDisclosure } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  farmerApprovedPaymentRequestStatus,
  farmerPaymentRequestStatus,
  getAllApprovedPaymentRequestsWithParams,
  getAllFarmerPaymentsWithParams,
  getFarmerPaymentDateRange,
  getLoadingFarmerPayment,
  searchAllFarmerPaymentsWithParams,
  updateFarmerPayment,
} from "features/v1/farmerPayment";
import EditPaymentsInfo from "./editPaymentsInfo";
import { financePage } from "routes/dashboardRoutes";
import { ConfirmDialog, RejectWithCommentDialog } from "components/confirmDialog";
import { useNavigate } from "react-router-dom";

const PaymentsTable = ({ tableProps, selectedPayment, selectedCropType, activeTab, page }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(getLoadingFarmerPayment);
  const isPaymentsPage = page === financePage.PAYMENT;
  const [rejectPaymentRequestComment, setRejectPaymentRequestComment] = useState("");
  const [declinePaymentComment, setDeclinePaymentComment] = useState("");

  const farmerEditModal = useDisclosure();
  const rejectionCommentModal = useDisclosure();
  const confirmPayoutModal = useDisclosure();
  const declinePaymentModal = useDisclosure();

  let paymentAdditionalParams = {};
  let managerAdditionalParams = {};

  switch (activeTab) {
    case "tab1":
      isPaymentsPage ? (paymentAdditionalParams = {}) : (managerAdditionalParams = {});
      break;
    case "tab2":
      isPaymentsPage
        ? (paymentAdditionalParams = { status: farmerPaymentRequestStatus.APPROVED })
        : (managerAdditionalParams = { payment_status: farmerApprovedPaymentRequestStatus.PROCESSED });
      break;
    case "tab3":
      isPaymentsPage
        ? (paymentAdditionalParams = { status: farmerPaymentRequestStatus.REJECTED })
        : (managerAdditionalParams = { payment_status: farmerApprovedPaymentRequestStatus.DECLINED });
      break;
    case "tab4":
      isPaymentsPage
        ? (paymentAdditionalParams = { status: farmerPaymentRequestStatus.PENDING })
        : (managerAdditionalParams = { payment_status: farmerApprovedPaymentRequestStatus.PENDING });
      break;
    default:
      isPaymentsPage ? (paymentAdditionalParams = {}) : (managerAdditionalParams = {});
  }

  const navigateToPage = (route, tab) => {
    navigate({
      pathname: route,
      search: `?tab=${tab}`,
    });
  };

  /** determine what extra action are avalable */
  const getExtraActions = payment => {
    let paymentExtraActions = [
      // { label: "Edit details", actionKey: "editDetails" },
      { label: "Approve", actionKey: "approve" },
      { label: "Reject", actionKey: "reject" },
    ];

    let managerExtraActions = [
      { label: "Payout", actionKey: "payout" },
      { label: "Decline", actionKey: "decline" },
    ];

    paymentExtraActions =
      payment.status === farmerPaymentRequestStatus.APPROVED
        ? paymentExtraActions.filter(action => action.actionKey !== "approve")
        : payment.status === farmerPaymentRequestStatus.REJECTED
        ? paymentExtraActions.filter(action => action.actionKey !== "reject")
        : paymentExtraActions;

    managerExtraActions =
      payment.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED
        ? []
        : payment.payment_status === farmerApprovedPaymentRequestStatus.DECLINED
        ? managerExtraActions.filter(action => action.actionKey !== "decline")
        : managerExtraActions;

    return isPaymentsPage ? paymentExtraActions : managerExtraActions;
  };

  const approveFarmerPayment = (id, type, modal) => {
    dispatch(
      updateFarmerPayment(
        { status: `${type === "approve" ? farmerPaymentRequestStatus.APPROVED : farmerPaymentRequestStatus.REJECTED}` },
        {
          type,
          farmerPaymentId: selectedPayment._id,
          tradeId: selectedPayment.trade_id,
          unit: selectedPayment.unit,
          modal,
          setActiveTab: () => navigateToPage("", "tab2"),
        }
      )
    );
  };

  const processPayout = (id, type) => {
    dispatch(
      updateFarmerPayment(
        { payment_status: farmerApprovedPaymentRequestStatus.PROCESSED },
        {
          type,
          farmerPaymentId: selectedPayment._id,
          tradeId: selectedPayment.trade_id,
          unit: selectedPayment.unit,
          setActiveTab: () => navigateToPage("", "tab2"),
        }
      )
    );
  };

  return (
    <>
      <Box>
        <Table
          {...tableProps}
          onSearch={(params, { abortController }) => {
            isPaymentsPage
              ? dispatch(
                  searchAllFarmerPaymentsWithParams(
                    { ...params, ...paymentAdditionalParams },
                    { abortController } // cancel token
                  )
                )
              : dispatch(
                  searchAllFarmerPaymentsWithParams(
                    { ...params, status: farmerPaymentRequestStatus.APPROVED, ...managerAdditionalParams },
                    { abortController } // cancel token
                  )
                );
          }}
          // eslint-disable-next-line no-unused-vars
          onDataFilter={({ seasonId, region, dateRange, nPerPage, page }) => {
            dispatch(getFarmerPaymentDateRange(dateRange.map(d => new Date(d).toISOString())));
            isPaymentsPage
              ? dispatch(
                  getAllFarmerPaymentsWithParams({
                    nPerPage,
                    page,
                    dateRange,
                    crop_type: selectedCropType?.value,
                    ...paymentAdditionalParams,
                  })
                )
              : dispatch(
                  getAllApprovedPaymentRequestsWithParams({
                    nPerPage,
                    page,
                    dateRange,
                    crop_type: selectedCropType?.value,
                    status: farmerPaymentRequestStatus.APPROVED,
                    ...managerAdditionalParams,
                  })
                );
          }}
          onExtraAction={({ actionKey }) => {
            if (actionKey === "editDetails") {
              farmerEditModal.onOpen();
            }
            if (actionKey === "approve") {
              approveFarmerPayment(selectedPayment._id, "approve");
            }
            if (actionKey === "reject") {
              rejectionCommentModal.onOpen();
            }
            if (actionKey === "payout") {
              confirmPayoutModal.onOpen();
            }
            if (actionKey === "decline") {
              declinePaymentModal.onOpen();
            }
          }}
          getExtraActions={getExtraActions}
        />
      </Box>
      <Modal
        open={farmerEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmerEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Payments  Information">
          <EditPaymentsInfo selected={selectedPayment} modal={farmerEditModal.modal} />
        </ModalContent>
      </Modal>
      <Modal
        open={rejectionCommentModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            rejectionCommentModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={650}>
          <RejectWithCommentDialog
            loading={loading}
            comment={rejectPaymentRequestComment}
            setComment={setRejectPaymentRequestComment}
            selected={selectedPayment}
            modal={rejectionCommentModal.modal}
            handleSubmit={() => {
              dispatch(
                updateFarmerPayment(
                  { status: farmerPaymentRequestStatus.REJECTED, comment: rejectPaymentRequestComment },
                  {
                    type: "reject",
                    farmerPaymentId: selectedPayment._id,
                    tradeId: selectedPayment.trade_id,
                    unit: selectedPayment.unit,
                    modal: rejectionCommentModal.modal,
                    resetForm: () => setRejectPaymentRequestComment(""),
                    setActiveTab: () => navigateToPage("", "tab3"),
                  }
                )
              );
            }}
          />
        </ModalContent>
      </Modal>
      <Modal
        open={confirmPayoutModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            confirmPayoutModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={550}>
          <ConfirmDialog
            heading="Do you want to continue?"
            subHeading="<p>
            Are you sure you want to confirm payout? <br /> This action cannot be reversed
          </p>"
            confirmationText="Confirm"
            modal={confirmPayoutModal.modal}
            onConfirm={() => {
              processPayout(selectedPayment._id, "payout");
              confirmPayoutModal.modal.hideModal();
            }}
          />
        </ModalContent>
      </Modal>
      <Modal
        open={declinePaymentModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            declinePaymentModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={650}>
          <RejectWithCommentDialog
            loading={loading}
            comment={declinePaymentComment}
            setComment={setDeclinePaymentComment}
            selected={selectedPayment}
            modal={declinePaymentModal.modal}
            handleSubmit={() => {
              dispatch(
                updateFarmerPayment(
                  { payment_status: farmerApprovedPaymentRequestStatus.DECLINED, comment: declinePaymentComment },
                  {
                    type: "Declined",
                    farmerPaymentId: selectedPayment._id,
                    tradeId: selectedPayment.trade_id,
                    unit: selectedPayment.unit,
                    modal: declinePaymentModal.modal,
                    resetForm: () => setDeclinePaymentComment(""),
                    setActiveTab: () => navigateToPage("", "tab3"),
                  }
                )
              );
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentsTable;
