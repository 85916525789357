import React from "react";
import { Select } from ".";
import { useCallbackRef, useOrgData } from "hooks";
import { countryOptions, getCountryCodeFromCountryName } from "./options/countrySelectOptions";

const CountrySelect = ({ onChange, useCountryCode = false, menuPlacement = "bottom", ...props }) => {
  const { loading } = useOrgData();

  const onChangeCallback = useCallbackRef(val => {
    if (useCountryCode) {
      onChange?.(getCountryCodeFromCountryName(val));
      return;
    }
    onChange?.(val);
  }, []);

  return (
    <Select
      label="Country"
      placeholder="Select a Country"
      isLoading={loading}
      {...props}
      options={countryOptions}
      onChange={onChangeCallback}
      menuPlacement={menuPlacement}
    />
  );
};

export { CountrySelect };
