import React from "react";
import { FieldArray } from "formik";

const CustomFormFieldArray = props => {
  // const { name, values, render, } = props
  // TODO flesh out reusable features for this wrapper
  return (
    <div>
      <FieldArray {...props} />
    </div>
  );
};

CustomFormFieldArray.propTypes = {};

export default CustomFormFieldArray;
