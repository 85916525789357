import React from "react";
import { Box, Button, Flex, Icon, Text, organicTheme } from "organic";
import { capitalize } from "utils";

const SuccessDialog = ({ heading, subHeading, confirmationText, onConfirm }) => {
  return (
    <Flex px="1.5rem" py="1.5rem" alignItems="center" flexDirection="column" justifyContent="center" textAlign="center">
      <Box mb="40px" mt="10px">
        <Icon icon="check-circle" size="45px" color={organicTheme.colors.primary600} />
      </Box>
      <Text font="xlMedium" mt={2}>
        {heading}
      </Text>
      <Box mt={2}>
        <Text font="smRegular" color="#667085" dangerouslySetInnerHTML={{ __html: subHeading }}></Text>
      </Box>
      <Flex mt={40} gap="20px" width="90%">
        <Button onClick={onConfirm} display="block" type="button">
          {capitalize(confirmationText)}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SuccessDialog;
