import { FormInput } from "components/inputs";
import { selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import { useFormikContext } from "formik";
import { useDidMountEffect } from "hooks";
import numeral from "numeral";
import React from "react";
import { createSelectOption } from "utils/v1/selectUtils";

const calcTradeCostPrice = ({ totalInvestment, targetQuantity }) => {
  const total_investment = numeral(totalInvestment || 0).value();
  const target_quantity = numeral(targetQuantity || 1).value();
  const byDivision = numeral(total_investment).divide(target_quantity).value().toFixed(2);
  return byDivision;
};

export const CostPriceField = () => {
  const { values, setFieldValue } = useFormikContext();

  useDidMountEffect(() => {
    const costPrice = calcTradeCostPrice({
      totalInvestment: values.total_investment,
      targetQuantity: values.target_quantity,
    });
    setFieldValue("cost_price", costPrice);
  }, [values.total_investment, values.target_quantity]);

  return (
    <FormInput
      label="Cost Price"
      name="cost_price"
      required
      hint="Total investment ÷ Target quantity"
      isDisabled
      type="number"
      min={0}
      step="any"
      max={MAX_NUM_VALUE}
      rightAddon={
        <CurrencySelect
          onChange={currencyValue => {
            setFieldValue("currency", currencyValue);
          }}
          value={createSelectOption(values.currency)}
          colorScheme={selectConstants.colorSchemes.UNSTYLED}
          placeholder="Currency"
        />
      }
    />
  );
};
