import React, { useRef, useState } from "react";
import { Input } from "organic";
import { debounce, hasMinLength, isFunction } from "utils";

const SearchInput = React.forwardRef(
  ({ placeholder, onChange, __onCleared, minLength, debounceDelay = 500 }, forwardedRef) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [message, setMessage] = useState("");

    const handleOnChange = e => {
      let value = e.target.value;
      setSearchTerm(value);
      handleDebouncedSearch(value);
      if (hasMinLength(value, minLength)) {
        setMessage("");
      } else {
        setMessage(`enter at least ${minLength} characters..`);
      }
    };

    const handleDebouncedSearch = useRef(
      debounce(term => {
        if (isFunction(onChange)) {
          console.count("calling onchange");
          return onChange(term);
        }
      }, debounceDelay)
    ).current;

    // useDidMountEffect(() => {
    //   try {
    //     const { token } = axios.CancelToken.source();
    //     const { abort, signal } = new AbortController();
    //     if (hasMinLength(searchTerm, minLength)) {
    //       handleDebouncedSearch(searchTerm, { token, signal });
    //       return () => abort() || handleDebouncedSearch.cancel();
    //     } else {
    //       handleDebouncedSearch("", { token, signal });
    //       return handleDebouncedSearch.cancel();
    //     }
    //   } catch (searchEffectError) {
    //     console.log({searchEffectError})
    //     const customException = new AOSClientError("SearchError", searchEffectError.message);
    //     customException.report(searchEffectError);
    //   }
    // }, [searchTerm, handleDebouncedSearch]);

    return (
      <div>
        <Input
          // {...restProps}
          ref={forwardedRef}
          value={searchTerm}
          errorText={message}
          placeholder={placeholder}
          minLength={4}
          leftIcon="search"
          onChange={handleOnChange}
        />
      </div>
    );
  }
);

SearchInput.displayName = "SearchInput";
SearchInput.defaultProps = {
  placeholder: "Search",
  debounceDelay: 1500,
  minLength: 4,
};

export { SearchInput };
