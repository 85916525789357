import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { useLegacyDidMountEffect } from "hooks";
/**
 *
 * @param {Object} config an object with config options
 * @param {Function} config.apiCall an async call to the advanced export endpoint
 * @param {Function} config.getKeys an async call to the advanced export getMetaKeys endpoint
 * @param {Object} config.apiCalls an object where keys are tab ids
 *                                  and the values are Function that call the advanced export endpoint
 * @param {Object} config.getTabKeys an object where keys are tab ids
 *                                  and the values are Function that call the advanced export getMetaKeys endpoint
 * @param {String} config.activeTab value of activeTab from useTabs hook
 * @param {Object} config.modal instance of useCustomModal this is used to show feedback and close modal after export
 * @param {Object} config.feedbackModal instance of useCustomModal this is used to show feedback and close modal after export
 * @param {Object} config.filterParams object containing filter params {seasonId, region, dateRange}
 * @param {Array} config.columnWhitelist value of activeTab from useTabs hook
 *
 * * Custom hook to handle andvaced export
 *
 * * Usage
 *    Either provide provide a single function that returns csv data
 *    Or provide config options for multiple exports on the same page
 * * Example
 *      const RandomAdvancedExport = useAdvancedExport({
          apiCall: async () => { return (await get("csvEndpoint")).data}
 *      })
 * * Options
 *  - activeTabs: (String): value of current tabId as provided by useCustomTabs custom hooks
 *  - apiCalls: (Object):
 */

function useAdvancedExport(config) {
  const hasFeedbackModal = _.isObject(config?.feedbackModal) && !_.isEmpty(config?.feedbackModal);
  const hasModal = _.isObject(config?.modal) && !_.isEmpty(config?.modal);

  const [loadingExport, setLoadingExport] = useState(false);
  const [tabKeys, setTabKeys] = useState({});
  const [exportKeys, setExportKeys] = useState([]);
  const [status, setStatus] = useState("");

  const setSuccessFul = () => setStatus("success");
  const setFailed = () => setStatus("failure");

  const handleError = AdvanceExportError => {
    // log error - temporarily for testing
    console.log({ error: AdvanceExportError });

    setLoadingExport(false); // disable loading
    if (hasModal) {
      config.modal.hideModal();
    }

    if (AdvanceExportError?.response?.status && AdvanceExportError?.response?.status === 404) {
      // toast.info("Advanced Export is not yet Availbale for this tab")

      return;
    }
    if (AdvanceExportError?.response) {
      // a server error from failed api request (old format)
      toast.error(
        AdvanceExportError?.response?.data?.message || AdvanceExportError?.response?.message || "Advanced Export Failed"
      );
      return;
    }
    if (AdvanceExportError?.data) {
      // a server error from failed api request
      toast.error(AdvanceExportError?.data?.message || "Unfortunately Advanced Export Failed");
      return;
    }

    if (AdvanceExportError?.message) {
      toast.error(AdvanceExportError.message);
    }
  };

  const _getMetaKeys = async () => {
    try {
      // config.getKeys is a function (no tabs)
      if (_.isFunction(config?.getKeys)) {
        setLoadingExport(true);
        const res = await config.getKeys();
        if (res?.status === 200) {
          setExportKeys(res?.data?.data || []);
        }
        setLoadingExport(false);
        return;
      }

      // config.getTabKeys is an object (tabbed page)
      if (_.isObject(config.getTabKeys) && config?.activeTab && config?.getTabKeys[config?.activeTab]) {
        setLoadingExport(true);
        const res = await config.getTabKeys[config.activeTab]();

        if (res?.status === 200) {
          setTabKeys(oldTabKeys => ({
            ...oldTabKeys,
            [config?.activeTab]: res?.data?.data || [],
          }));
        }
        setLoadingExport(false);
        return;
      }
    } catch (error) {
      handleError(error);
    }
  };

  const requestData = async () => {
    try {
      console.log("advanced export", { config });

      // // config is a callback or config.apiCall
      // if (_.isFunction(config)) {
      //   let res = await config();
      //   return res;
      // }
      // config is an object and config.apiCall is a callBack
      if (_.isFunction(config.apiCall)) {
        // TODO: fix to throw error if filter  params and at least export keys aren't provided
        return await config.apiCall({
          filterParams: config?.filterParams || {},
          columnWhitelist: config?.columnWhitelist || exportKeys,
        });
        // return res;
      }

      // config is a config object use activeTab to select callback
      if (_.isObject(config) && !_.isEmpty(config)) {
        if (
          config?.activeTab &&
          _.isObject(config.apiCalls) &&
          !_.isEmpty(config.apiCalls) &&
          config?.apiCalls[config?.activeTab]
        ) {
          let res = await config.apiCalls[config.activeTab]({
            filterParams: config?.filterParams || {},
            columnWhitelist: config?.columnWhitelist || exportKeys,
          });
          return res;
        }

        if (config?.activeTab && !config?.apiCalls[config?.activeTab]) {
          return;
        }
      } else {
        // no config options
        // toast.info(`Advanced export not yet available for this page`);

        return;
      }
    } catch (error) {
      setFailed();
      if (hasFeedbackModal) {
        config.feedbackModal.toggleFeedbackModal();
      }
      handleError(error);
    }
  };

  const requestExport = async () => {
    try {
      setLoadingExport(true);
      if (config?.modal) {
        config.modal.hideModal();
      }
      let exportRes = await requestData();
      if (exportRes?.status === 200) {
        setSuccessFul();
        if (hasFeedbackModal) {
          config.feedbackModal.toggleFeedbackModal();
        }
        toast.success(exportRes?.data?.message || "Data sent Successfully");
      }
      setLoadingExport(false);
    } catch (error) {
      setFailed();
      if (config?.modal) {
        config.modal.hideModal();
      }
      if (hasFeedbackModal) {
        config.feedbackModal.toggleFeedbackModal();
      }
      handleError(error);
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    _getMetaKeys();
  }, []);

  useLegacyDidMountEffect(() => {
    _getMetaKeys();
  }, [config?.activeTab]);

  useLegacyDidMountEffect(() => {
    if (config?.activeTab) {
      setExportKeys(tabKeys[config.activeTab]);
    }
  }, [tabKeys]);

  return {
    requestExport,
    exportStatus: status,
    exportKeys,
    loadingExport,
  };
}

export { useAdvancedExport };
