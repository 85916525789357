import { leafletMapConstants } from "components/map";
import { useCallbackRef } from "hooks/ui/useCallbackRef";
import L from "leaflet";
import { useEffect, useMemo, useReducer, useRef } from "react";

const defaultUseLeafletMapsConfig = {};

export function useLeafletMaps(__data = [], __config = defaultUseLeafletMapsConfig) {
  const map = useRef(null);

  const [state, dispatch] = useReducer(function (state, update) {
    return { ...state, ...update };
  }, leafletMapConstants.defaults.REDUCER_STATE);

  const _markers = useMemo(() => {
    return state?.markers || [];
  }, [state?.markers]);

  const _polygons = useMemo(() => {
    return state?.polygons || [];
  }, [state?.polygons]);

  const initialiseMap = useCallbackRef(() => {
    dispatch({ isLoaded: true, lmap: map.current, isInitialised: true });
  }, []);

  const reInitialiseMap = useCallbackRef(() => {
    dispatch({ lmap: map.current });
  }, []);

  const panTo = useCallbackRef(point => {
    if (state.isLoaded) {
      state.lmap.panTo(point);
    }
  }, []);

  const flyTo = useCallbackRef(point => {
    if (state.isLoaded) {
      state.lmap.panTo(point);
    }
  }, []);

  const flyToBounds = useCallbackRef(
    bounds => {
      console.log({ bounds });
      state.lmap.flyToBounds(bounds, { duration: 1.5 });
    },
    [state?.polygons, state?.markers]
  );

  const calculatePolygonBounds = useCallbackRef(
    polygons => {
      // Flatten the array of coordinates
      const coordinates = polygons.reduce((acc, polygon) => acc.concat(polygon), []);

      console.log({ coordinates });

      // Calculate the bounds
      const bounds = L.latLngBounds(coordinates);
      console.log({ bounds });

      return bounds;
    },
    [state?.polygons]
  );

  const setMarkers = useCallbackRef(markers => {
    dispatch({ markers: [...state.markers, ...markers] });
  }, []);

  const setPolygons = useCallbackRef(polygons => {
    dispatch({ polygons: [...state.polygons, ...polygons] });
  }, []);

  const cleanupState = useCallbackRef(() => {
    dispatch(leafletMapConstants.defaults.REDUCER_STATE);
  }, []);

  const clearPolygons = useCallbackRef(() => {
    dispatch({ ...state, polygons: [] });
  }, []);

  const clearMarkers = useCallbackRef(() => {
    dispatch({ ...state, markers: [] });
  }, []);

  const removeMarker = useCallbackRef(index => {
    if (index > state.markers?.length || index > 0) return;
    const oldMarkers = [...state.markers];
    dispatch({ markers: oldMarkers.splice(index, 1) });
  }, []);

  const removePolygon = useCallbackRef(index => {
    const oldPolygons = [...state.polygons];
    dispatch({ polygons: oldPolygons.splice(index, 1) });
  }, []);

  // initialise map
  useEffect(() => {
    if (map?.current?._loaded && !state.isInitialised) {
      initialiseMap();
    }
  }, [initialiseMap, state.isInitialised]);

  // reinitialise map
  useEffect(() => {
    if (map?.current?._loaded && state.isInitialised && !state?.lmap) {
      reInitialiseMap();
    }
  }, [reInitialiseMap, state.isInitialised, state?.lmap]);

  // jump to newly added polygons
  useEffect(() => {
    if (!state.isLoaded || state.polygons.length === 0) return;

    // Find the bounds that encompass all polygons
    const bounds = calculatePolygonBounds(state.polygons);
    console.log("tryna flytobounds", { polygons: state.polygons, bounds, map: state.lmap });

    // Fly to the calculated bounds with a smooth animation
    flyToBounds(bounds);
  }, [calculatePolygonBounds, flyToBounds, state.isLoaded, state.lmap, state.polygons]);

  useEffect(() => {
    console.log({ state });
  }, [state]);

  return {
    L,
    mapRef: map,
    isMapLoaded: map?.current?._loaded,
    panTo,
    flyTo,
    cleanupState,
    markers: _markers,
    polygons: _polygons,
    setMarkers,
    setPolygons,
    clearMarkers,
    clearPolygons,
    removeMarker,
    removePolygon,
  };
}
