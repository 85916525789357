export default [
  {
    state: {
      name: "Bombali",
      id: 1,
      locals: [
        { name: "Bombali Sebora" },
        { name: "Gbanti" },
        { name: "Paki Masabong" },
        { name: "Safroko Limba" },
        { name: "Biriwa Limba" },
        { name: "Gbendembu" },
        { name: "Ngowahun" },
        { name: "Magbaiamba-Ndowahun" },
        { name: "Kamaranka" },
        { name: "Mara" },
      ],
    },
  },
  {
    state: {
      name: "Falaba",
      id: 2,
      locals: [
        { name: "Neya" },
        { name: "Nyedu" },
        { name: "Kulor-Seradu" },
        { name: "Mongo" },
        { name: "Seradu" },
        { name: "Morifindugu" },
        { name: "Delemandugu" },
        { name: "Sulima" },
        { name: "Kabelia" },
        { name: "Folasaba-Kamba" },
        { name: "Dembelia Musaia" },
        { name: "Dembelia Sikunia" },
        { name: "Kamadugu-Yiraia" },
      ],
    },
  },
  {
    state: {
      name: "Koinadugu II",
      id: 3,
      locals: [
        { name: "Wara-Wara Bafodia" },
        { name: "Kamukeh" },
        { name: "Sengbe" },
        { name: "Wara-Wara Yagalla" },
        { name: "Diang" },
        { name: "Kasunko-Kakellay" },
        { name: "Gbongobor-Kayaka" },
        { name: "Thamiso" },
        { name: "Neini" },
        { name: "Kallian" },
        { name: "Barawa-Wolley" },
      ],
    },
  },
  {
    state: {
      name: "Tonkolili",
      id: 4,
      locals: [
        { name: "Dansogoia" },
        { name: "Kalantuba" },
        { name: "Kafe" },
        { name: "Simiria" },
        { name: "Kholifa Rowala" },
        { name: "Tane" },
        { name: "Kunike Gbarnga" },
        { name: "Kholifa Mayosso-Mamuntha" },
        { name: "Kunike Sanda" },
        { name: "Kunike Folawusu" },
        { name: "Yele" },
        { name: "Mayeppoh" },
        { name: "Poli" },
        { name: "Masakong" },
        { name: "Yoni Mabanta" },
        { name: "Yoni Mamailla" },
        { name: "Kholifa Mabang" },
        { name: "Malal" },
        { name: "Sambaia" },
      ],
    },
  },
  {
    state: {
      name: "Kambia",
      id: 5,
      locals: [
        { name: "Magbema" },
        { name: "Mambolo" },
        { name: "Samu" },
        { name: "Gbinle" },
        { name: "Dixon" },
        { name: "Tonko Limba" },
        { name: "Bramaia" },
        { name: "Khonimakha" },
        { name: "Masungbala" },
        { name: "Tala-Munu" },
      ],
    },
  },
  {
    state: {
      name: "Port Loko",
      id: 6,
      locals: [
        { name: "Bureh" },
        { name: "Kasseh" },
        { name: "Maconteh" },
        { name: "Bake-Loko" },
        { name: "Maforki" },
        { name: "Lokomassama" },
        { name: "Kamasondo" },
        { name: "Kaffu Bullom" },
        { name: "Marampa" },
        { name: "Koya" },
        { name: "Masimera" },
        { name: "Thainkatopa" },
        { name: "Makama" },
      ],
    },
  },
  {
    state: {
      name: "Karene",
      id: 7,
      locals: [
        { name: "Tambakha Simibungie" },
        { name: "Tambakha Yobangie" },
        { name: "Gbanti" },
        { name: "Libeisaygahun-Gombagu" },
        { name: "Mafonda" },
        { name: "Makerembay" },
        { name: "Sanda Tendaren" },
        { name: "Sanda Magbolontor" },
        { name: "Sella Limba" },
        { name: "Sanda Loko" },
        { name: "Buya" },
        { name: "Romende" },
        { name: "Safroko" },
        { name: "Dibia" },
      ],
    },
  },
  {
    state: {
      name: "Bo",
      id: 8,
      locals: [
        { name: "Badja" },
        { name: "Bagbe" },
        { name: "Bagbo" },
        { name: "Baoma" },
        { name: "Bumpe" },
        { name: "Gbo" },
        { name: "Jaiama" },
        { name: "Bongor" },
        { name: "Kakua" },
        { name: "Komboya" },
        { name: "Lugbu" },
        { name: "Niawa-Lenga" },
        { name: "Selenga" },
        { name: "Tikonko" },
        { name: "Valunia" },
        { name: "Wonde" },
      ],
    },
  },
  {
    state: {
      name: "Bonthe",
      id: 9,
      locals: [
        { name: "Bendu Cha" },
        { name: "Bum" },
        { name: "Dema" },
        { name: "Imperi" },
        { name: "Jong" },
        { name: "Kpanda-Kemo" },
        { name: "Kwamebai Krim" },
        { name: "Nongoba Bullom" },
        { name: "Sittia" },
        { name: "Sogbini" },
        { name: "Yawbeko" },
      ],
    },
  },
  {
    state: {
      name: "Moyamba",
      id: 10,
      locals: [
        { name: "Bagruwa" },
        { name: "Banta" },
        { name: "Banta-Mokele" },
        { name: "Bumpe" },
        { name: "Dasse" },
        { name: "Fakunya" },
        { name: "Kaiyamba" },
        { name: "Kagboro" },
        { name: "Kamajei" },
        { name: "Kongboro" },
        { name: "Kori" },
        { name: "Kowa" },
        { name: "Ribbi" },
        { name: "Timdel" },
      ],
    },
  },
  {
    state: {
      name: "Pujehun",
      id: 11,
      locals: [
        { name: "Barri" },
        { name: "Gallines" },
        { name: "Perri" },
        { name: "Kpaka" },
        { name: "Makpele" },
        { name: "Malen" },
        { name: "Mano Sakrim" },
        { name: "Kpanga" },
        { name: "Kabonde" },
        { name: "Kpanga-Krim" },
        { name: "Peje" },
        { name: "Soro Gbema" },
        { name: "Sowa" },
        { name: "Yekemo-Kpukumu-Krim" },
      ],
    },
  },
  {
    state: {
      name: "Kailahun",
      id: 12,
      locals: [
        { name: "Dea" },
        { name: "Njaluahun" },
        { name: "Kissi Kama" },
        { name: "Kissi Teng" },
        { name: "Kissi Tongi" },
        { name: "Jawei" },
        { name: "Luawa" },
        { name: "Malema" },
        { name: "Mandu" },
        { name: "Peje West" },
        { name: "Penguia" },
        { name: "Upper Bambara" },
        { name: "Kpeje Bongre" },
        { name: "Yawei" },
      ],
    },
  },
  {
    state: {
      name: "Kenema",
      id: 13,
      locals: [
        { name: "Dama" },
        { name: "Dodo" },
        { name: "Gaura" },
        { name: "Gorama Mende" },
        { name: "Kandu Leppiama" },
        { name: "Koya" },
        { name: "Langrama" },
        { name: "Lower Bambara" },
        { name: "Malegohun" },
        { name: "Niawa" },
        { name: "Nomo" },
        { name: "Nongowa" },
        { name: "Simbaru" },
        { name: "Small Bo" },
        { name: "Tunkia" },
        { name: "Wando" },
      ],
    },
  },
  {
    state: {
      name: "Kono",
      id: 14,
      locals: [
        { name: "Tankoro" },
        { name: "Gbense" },
        { name: "Kama'a" },
        { name: "Nimikoro" },
        { name: "Nimiyama" },
        { name: "Sandor" },
        { name: "Toli" },
        { name: "Gbane" },
        { name: "Lei" },
        { name: "Soa" },
        { name: "Fiama" },
        { name: "Gorama Kono" },
        { name: "Mafindor" },
        { name: "Gbane Kandor" },
      ],
    },
  },
];
