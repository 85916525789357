import React from "react";
import { DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger } from "components/dropdown";
import { hyphenate, isFunction } from "utils";
import { Flex, Icon, Text, organicTheme } from "organic";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover";
import { useCallbackRef } from "hooks";
import { DefaultTableActions } from "./defaultTableActions";
import { ExtraTableActions } from "./extraTableActions";

const TableMultiSelectActions = ({ actions, getter, rows, onSelect }) => {
  const _getter = useCallbackRef(getter, []);

  const _rows = React.useMemo(() => rows.map(row => row.original), []);

  const memoizedMultiActions = React.useMemo(() => {
    if (_getter) {
      return _getter(_rows);
    }
    if (Array.isArray(actions) && actions?.length) {
      return actions;
    }

    return [];
  }, [_getter, actions, _rows]);

  const _handleAction = selectedOptions => {
    if (isFunction(onSelect)) {
      onSelect(selectedOptions);
    }
  };

  // render enpty fragment if actions list is not an array
  if (!Array.isArray(memoizedMultiActions)) {
    return <></>;
  }

  return (
    <>
      {memoizedMultiActions?.length ? (
        memoizedMultiActions.map((action, actionIndex) => {
          if (!action.actionKey) return null;

          if (action?.component) {
            return (
              <Popover key={hyphenate([action.actionKey, actionIndex])}>
                <PopoverTrigger className="hover-card__table-trigger">
                  <div>
                    <Icon icon={action.icon || "square"} size={16} />
                    <Text ml="0.75rem" as="span" font="smMedium" color="gray700">
                      {action.label}
                    </Text>
                  </div>
                </PopoverTrigger>
                <PopoverContent alignOffset={32} sideOffset={0} side="top" className="default-dropdown__content">
                  <Flex flexDirection="column" backgroundColor="white" borderRadius="md">
                    {action.component}
                  </Flex>
                </PopoverContent>
              </Popover>
            );
          }

          return (
            <DropdownItem
              onSelect={() => _handleAction({ selected: _rows, actionKey: action.actionKey })}
              key={hyphenate(["multi-select", action.actionKey, actionIndex.toString()])}
            >
              <Icon icon={action.icon || "square"} /> {action.label}
            </DropdownItem>
          );
        })
      ) : (
        <DropdownItem>No Actions Provided</DropdownItem>
      )}
    </>
  );
};

TableMultiSelectActions.defaultProps = {
  actions: [],
};

const UnMemoisedTableActions = ({
  onSelect,
  id,
  row,
  extraActions,
  getExtraActions,
  noEditAction,
  noViewAction,
  canDelete,
}) => {
  return (
    <DropdownMenu>
      <DropdownTrigger>
        <Icon icon="more-vertical" color={organicTheme.colors.gray900} size={20} />
      </DropdownTrigger>
      <DropdownContent unstyled alignOffset={-5} align="end">
        <DefaultTableActions
          onSelect={onSelect}
          id={id}
          row={row}
          noEdit={noEditAction}
          noView={noViewAction}
          canDelete={canDelete}
        />
        <ExtraTableActions actions={extraActions} getter={getExtraActions} row={row} id={id} onSelect={onSelect} />
      </DropdownContent>
    </DropdownMenu>
  );
};

// const TableActions = React.memo(UnMemoisedTableActions);
const TableActions = UnMemoisedTableActions;

TableActions.displayName = "TableActions";

export { TableActions, TableMultiSelectActions };
