import React, { useMemo } from "react";
import aosLogoSvg from "assets/images/aos-logo.svg";
import aosLogoSvgSmall from "assets/images/aos-logo-small.svg";
import fidaLogoSvg from "assets/images/fida-logo.svg";
import fidaLogoSvgSmall from "assets/images/fida-logo-small.svg";
import { Image } from "organic";
import { getAOSFlavour } from "services/customerService";

const logos = {
  fida: {
    iconOnly: {
      src: fidaLogoSvgSmall,
      width: "30px",
      height: "30px",
      alt: "fida logo",
    },
    fullLogo: {
      src: fidaLogoSvg,
      width: "85px",
      height: "42px",
      alt: "fida logo",
    },
  },
  aos: {
    iconOnly: {
      src: aosLogoSvgSmall,
      width: "30px",
      height: "30px",
      alt: "aosafrica logo",
    },
    fullLogo: {
      src: aosLogoSvg,
      width: "85px",
      height: "42px",
      alt: "aosafrica logo",
    },
  },
  local: {
    iconOnly: {
      src: aosLogoSvgSmall,
      width: "30px",
      height: "30px",
      alt: "aosafrica logo",
    },
    fullLogo: {
      src: aosLogoSvg,
      width: "85px",
      height: "42px",
      alt: "aosafrica logo",
    },
  },
};

const getLogoProps = ({ variant, flavour }) => {
  if (logos[flavour][variant]) {
    return logos[flavour][variant];
  }
  return {
    src: aosLogoSvg,
    width: "85px",
    height: "42px",
    alt: "aosafrica logo",
  };
};

export const logoConstants = {
  variants: {
    FULL: "fullLogo",
    ICON: "iconOnly",
  },
};

const Logo = ({ variant = "fullLogo" }) => {
  let logoProps = useMemo(() => {
    const aosFlavour = getAOSFlavour();
    return getLogoProps({ variant, flavour: aosFlavour });
  }, [variant]);

  return <Image {...logoProps} />;
};

export { Logo };
