import React from "react";
import * as PopoverPrimitives from "@radix-ui/react-popover";

const Popover = PopoverPrimitives.Root;
Popover.defaultProps = {
  defaultOpen: false,
};
const PopoverTrigger = PopoverPrimitives.Trigger;
PopoverTrigger.defaultProps = {
  asChild: true,
};

const PopoverContent = React.forwardRef(function (
  { children, sideOffset, ...restOfPopoverContetnProps },
  forwardedRef
) {
  return (
    <PopoverPrimitives.Portal>
      <PopoverPrimitives.Content sideOffset={sideOffset} {...restOfPopoverContetnProps} ref={forwardedRef}>
        {children}
      </PopoverPrimitives.Content>
    </PopoverPrimitives.Portal>
  );
});

PopoverContent.displayName = "PopoverContent";
PopoverContent.defaultProps = {
  sideOffset: 5,
  className: "default-popover",
};

const PopoverClose = PopoverPrimitives.Close;

PopoverClose.defaultProps = {
  className: "default-popover-close",
};

export { Popover, PopoverTrigger, PopoverContent, PopoverClose };
