import React from "react";
import StyledBadge from "./styledbadge";

const badgeConstants = {
  colorSchemes: {
    BLUE: "blue",
    BLUE_GRAY: "blueGray",
    BLUE_LIGHT: "blueLight",
    ERROR: "error",
    GRAY: "gray",
    INDIGO: "indigo",
    ORANGE: "orange",
    PINK: "pink",
    PRIMARY: "primary",
    PURPLE: "purple",
    ROSE: "rose",
    SUCCESS: "success",
    WARNING: "warning",
  },
  sizes: {
    SM: "sm",
    MD: "md",
    LG: "lg",
  },
};

const Badge = ({ children, colorScheme, size, ...restOfProps }) => {
  return (
    <StyledBadge
      {...restOfProps}
      sm={size === badgeConstants.sizes.SM}
      md={size === badgeConstants.sizes.MD}
      lg={size === badgeConstants.sizes.LG}
      blue={colorScheme === badgeConstants.colorSchemes.BLUE}
      blueGray={colorScheme === badgeConstants.colorSchemes.BLUE_GRAY}
      blueLight={colorScheme === badgeConstants.colorSchemes.BLUE_LIGHT}
      error={colorScheme === badgeConstants.colorSchemes.ERROR}
      gray={colorScheme === badgeConstants.colorSchemes.GRAY}
      indigo={colorScheme === badgeConstants.colorSchemes.INDIGO}
      orange={colorScheme === badgeConstants.colorSchemes.ORANGE}
      pink={colorScheme === badgeConstants.colorSchemes.PINK}
      primary={colorScheme === badgeConstants.colorSchemes.PRIMARY}
      purple={colorScheme === badgeConstants.colorSchemes.PURPLE}
      rose={colorScheme === badgeConstants.colorSchemes.ROSE}
      success={colorScheme === badgeConstants.colorSchemes.SUCCESS}
      warning={colorScheme === badgeConstants.colorSchemes.WARNING}
    >
      {children}
    </StyledBadge>
  );
};

Badge.defaultProps = {
  size: badgeConstants.sizes.SM,
  colorScheme: badgeConstants.colorSchemes.PRIMARY,
};

export { Badge, badgeConstants };
