import React from "react";
import Select2 from "./select2";
import { useOrgData } from "hooks";
import { countryOptions } from "./options/countrySelectOptions";

const CountrySelect2 = ({
  // useCountryCode = false,
  stateFieldName,
  lgaFieldName,
  setFieldValue,
  ...props
}) => {
  const { loading } = useOrgData();

  return (
    <Select2
      extraActionOnChange={() => {
        setFieldValue(stateFieldName, "");
        setFieldValue(lgaFieldName, "");
      }}
      label="Country"
      placeholder="Select a Country"
      isLoading={loading}
      options={countryOptions}
      {...props}
    />
  );
};

export default CountrySelect2;
