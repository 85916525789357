import { FormInput } from "components/inputs";
import { VisibilityWrapper } from "components/layout/visibilityWrapper";
import { CropCategorySelect, CropSelect, ProjectSelect, StatusSelect } from "components/select";
import { useFormikContext } from "formik";
import { Box, Flex } from "organic";
import React from "react";
import { appGet } from "utils";
import {
  PSYCHOMETRIC_TEST_QUESTIONNAIRE,
  handleEditCategoryType,
  handleEditFarmOperationCategory,
} from "utils/questionnaire-utils/questionnaire-utils";

const QuestionnaireDetailsForm = ({ detailsKey }) => {
  const { setFieldValue, values, setValues } = useFormikContext();
  const currentModel = appGet(values, [detailsKey, "model"]);
  return (
    <>
      <Flex flexDirection="column" width="100%" padding="24px" style={{ gap: "20px" }}>
        <Flex width="100%" style={{ gap: "24px" }}>
          {/* name */}
          <Box width="100%">
            <FormInput
              placeholder="name"
              label="Name"
              name={`${detailsKey}.name`}
              required
              defaultValue={appGet(values, [detailsKey, "name"])}
            />
          </Box>
          {/* season */}
          <Box width="100%">
            <ProjectSelect
              label="Season"
              name={`${detailsKey}.season`}
              onChange={val => setFieldValue(`${detailsKey}.season`, val)}
              ignoreGlobalState
              defaultValue={appGet(values, [detailsKey, "season"])}
              colorScheme="default"
            />
          </Box>
        </Flex>
        <Flex style={{ gap: "24px" }}>
          {/* farm categories */}
          <Box width="100%">
            <CropCategorySelect
              label="Farm Categories"
              placeholder="select crop category"
              name={`${detailsKey}.farm_operation_category`}
              isDisabled={Array.isArray(values.farm_operation_category) && values.farm_operation_category?.length === 1}
              getOptionValue={option => option.name}
              onChange={value => handleEditFarmOperationCategory(value, { setValues, values, setFieldValue })}
              defaultValue={appGet(values, [detailsKey, "farm_operation_category"])}
            />
          </Box>
          {/* farm type */}
          <Box width="100%">
            <CropSelect
              label="Farm Type(s)"
              name={`${detailsKey}.category_type`}
              placeholder="Select Farm Type"
              categories={appGet(values, [detailsKey, "farm_operation_category"])}
              getOptionValue={option => option.name}
              isDisabled={Array.isArray(values.farm_operation_category) && values.farm_operation_category?.length === 1}
              onChange={value => handleEditCategoryType(value, { setFieldValue, values, setValues })}
              defaultValue={appGet(values, [detailsKey, "category_type"], "")}
            />
          </Box>
        </Flex>
        <VisibilityWrapper style={{ gap: "24px" }} isVisible={currentModel === PSYCHOMETRIC_TEST_QUESTIONNAIRE}>
          {/* cut off marks */}
          <Box width="100%">
            <FormInput
              placeholder="Cut Off Mark"
              label="Cut off mark"
              name={`${detailsKey}.cut_off_mark`}
              type="number"
              defaultValue={appGet(values, [detailsKey, "cut_off_mark"])}
            />
          </Box>
          {/* score per question */}
          <Box width="100%">
            <FormInput
              placeholder="Score per Question"
              label="Score per question"
              name={`${detailsKey}.score_per_question`}
              type="number"
              min="0"
              defaultValue={appGet(values, [detailsKey, "score_per_question"])}
            />
          </Box>
        </VisibilityWrapper>
        <Flex style={{ gap: "24px" }}>
          {/* status */}
          <Box width="100%">
            <StatusSelect
              label="Status"
              required
              name={`${detailsKey}.status`}
              defaultValue={appGet(values, [detailsKey, "status"])}
              onChange={_val => setFieldValue(`${detailsKey}.status`, _val)}
            />
          </Box>
          <Box width="100%"></Box>
        </Flex>
      </Flex>
    </>
  );
};

export default QuestionnaireDetailsForm;
