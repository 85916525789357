import { roleConstants } from "constants/roleConstants";
import _ from "lodash";
import { signOut } from "services/v1/authService";
import { appGet } from "utils/shared-utils/object-utils";
import { capitalize } from "utils/shared-utils/text-utils";

export const initialRoleState = {
  isSuperAdmin: false,
  isSubAdmin: false,
  isOperationalManager: false,
  isFinance: false,
  isStoreManager: false,
  isRegionalManager: false,
  isZonalManager: false,
};

export const resolveRoleFlags = role => {
  if (role) {
    switch (role) {
      case "zonalManager":
        return { ...initialRoleState, isZonalManager: true };
      case "regionalManager":
        return { ...initialRoleState, isRegionalManager: true };
      case "storeManager":
        return { ...initialRoleState, isStoreManager: true };
      case "finance":
        return { ...initialRoleState, isFinance: true };
      case "operationalManager":
        return { ...initialRoleState, isOperationalManager: true };
      case "subAdmin":
        return { ...initialRoleState, isSubAdmin: true };
      case "superAdmin":
        return { ...initialRoleState, isSuperAdmin: true };
      default:
        return { ...initialRoleState };
    }
  }
  return initialRoleState;
};

export const setUserScope = scope => {
  if (!scope) {
    return;
  }
  if (_.isString(scope)) {
    sessionStorage.setItem(roleConstants.storageKeys.scope, scope);
    return;
  }

  if (_.isArray(scope)) {
    const scopeString = scope.join(",");
    sessionStorage.setItem(roleConstants.storageKeys.scope, scopeString);
  }
};

export const getUserScope = () => {
  const userScope = sessionStorage.getItem(roleConstants.storageKeys.scope);
  if (userScope) {
    return userScope;
  }
  signOut();
  return null;
};

export const removeUserScope = () => {
  sessionStorage.removeItem(roleConstants.storageKeys.scope);
};

export const getScopeDescriptor = userScope => {
  const [resource, permission] = userScope.split(".");
  const resourceDescriptor = appGet(roleConstants.resources, resource, "Unknown Resource");
  const permissionDescriptor = capitalize(permission);

  return `${permissionDescriptor} all ${resourceDescriptor} Data`;
};

export const geScopeOptions = () => {
  const readScopeOptions = Object.entries(roleConstants.resourceNames).map(resource => ({
    label: `View All ${resource[1]}(s)`,
    value: `${resource[0]}.read`,
  }));
  const writeScopeOptions = Object.entries(roleConstants.resourceNames).map(resource => ({
    label: `Create/Update ${resource[1]}(s)`,
    value: `${resource[0]}.write`,
  }));

  return [...readScopeOptions, ...writeScopeOptions];
};
