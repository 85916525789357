import { authChecker } from "../../services/v1/authService";
import subscription from "api/subscription";
import admin from "api/admin";
import { toast } from "react-toastify";
import generateXYData from "utils/v1/generateXYData";
// import Axios from "axios";
import { handleActionError } from "services/shared/AOSErrorService";

// Ducks pattern
// actions
const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION";
const GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY = "GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY";
const GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT = "GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT";

const GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY_OVERVIEW = "GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY_OVERVIEW";
const GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT_OVERVIEW = "GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT_OVERVIEW";
const LOAD_SUBSCRIPTION = "LOAD_SUBSCRIPTION";
const LOAD_SEARCH = "LOAD_SEARCH";
// const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
// const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
// const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
// const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
// const CLEAR_SUBSCRIPTION_FORM = "CLEAR_SUBSCRIPTION_FORM";
// const ADD_INPUT_SUBSCRIPTION = "ADD_INPUT_SUBSCRIPTION";
// const ADD_OUTPUT_SUBSCRIPTION = "ADD_OUTPUT_SUBSCRIPTION";
// const DELETE_INPUT_SUBSCRIPTION = "DELETE_INPUT_SUBSCRIPTION";
const SUBSCRIPTION_PLAN_DETAILS = "SUBSCRIPTION_PLAN_DETAILS";
const GET_ALL_SUBSCRIPTION_PLANS = "GET_ALL_SUBSCRIPTION_PLANS";
const LOAD_ALL_SUSBSCRIPTION_PLANS = "LOAD_ALL_SUBSCRIPTION_PLANS";

// action creators
export const getAllSubActivityWithParams = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_SUBSCRIPTION, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("getAllFarmSubActivityWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
  } catch (getAllSubActivityWithParamsError) {
    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
    handleActionError({ getAllSubActivityWithParamsError });
  }
};
export const searchAllSubActivityWithParams = (params, __cancelToken) => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_SEARCH, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("search", { params });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SEARCH, payload: false });
  } catch (searchAllSubActivityWithParamsError) {
    dispatch({ type: LOAD_SEARCH, payload: false });
    handleActionError({ searchAllSubActivityWithParamsError });
  }
};

export const getAllSubPaymentWithParams = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_SUBSCRIPTION, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("getAllFarmSubPaymentsWithParams", { params });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
  } catch (getAllSubPaymentWithParamsError) {
    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
    handleActionError({ getAllSubPaymentWithParamsError });
  }
};
export const searchAllSubPaymentWithParams = (params, cancelToken) => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_SEARCH, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("search", { params, cancelToken });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SEARCH, payload: false });
  } catch (searchAllSubPaymentWithParamsError) {
    dispatch({ type: LOAD_SEARCH, payload: false });
    handleActionError({ searchAllSubPaymentWithParamsError });
  }
};

//OVERVIEW
export const getAllSubActivityWithParamsOverview = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_SUBSCRIPTION, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("getAllFarmSubActivityWithParamsOverview", { params });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY_OVERVIEW,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
  } catch (getAllSubActivityWithParamsOverviewError) {
    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
    handleActionError({ getAllSubActivityWithParamsOverviewError });
  }
};

export const getAllFarmSubPaymentWithParamsOverview = params => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_SUBSCRIPTION, payload: true });
    // console.log("params: ",params)
    const resData = await subscription.get("getAllFarmSubPaymentsWithParamsOverview", { params });

    dispatch({
      type: GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT_OVERVIEW,
      payload: resData.data.data,
      pages: resData.data.pages,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
  } catch (getAllFarmSubPaymentWithParamsOverviewError) {
    dispatch({ type: LOAD_SUBSCRIPTION, payload: false });
    handleActionError({ getAllFarmSubPaymentWithParamsOverviewError });
  }
};

export const getAllSubscriptions = () => async dispatch => {
  try {
    authChecker();

    const subscriptionData = await subscription.get("/allSubscriptionHistory");

    dispatch({
      type: GET_ALL_SUBSCRIPTION,
      payload: subscriptionData.data.data,
      pages: subscriptionData.data.pages,
      total: subscriptionData.data.total,
    });
  } catch (getAllSubscriptionsError) {
    handleActionError({ getAllSubscriptionsError });
  }
};
export const getAllSubscriptionPlans = () => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_ALL_SUSBSCRIPTION_PLANS, payload: true });
    const allSubscriptionData = await admin.get("warehouse/subscription/getAll");

    dispatch({
      type: GET_ALL_SUBSCRIPTION_PLANS,
      payload: allSubscriptionData.data.data,
      subscriptionPlansMeta: allSubscriptionData?.data?.meta || {},
    });
    dispatch({ type: LOAD_ALL_SUSBSCRIPTION_PLANS, payload: false });
  } catch (getAllSubscriptionPlansError) {
    handleActionError({ getAllSubscriptionPlansError });
  }
};

export const handlePaginatedPageChange = (page_number, per_page) => async dispatch => {
  try {
    authChecker();
    const params = {
      page_number,
      per_page,
    };

    const subscriptionData = await subscription.get("/allSubscriptionHistory", { params });

    dispatch({
      type: GET_ALL_SUBSCRIPTION,
      payload: subscriptionData.data.data,
      pages: subscriptionData.data.pages,
      total: subscriptionData.data.total,
    });
  } catch (handlePaginatedPageChangeError) {
    handleActionError({ handlePaginatedPageChangeError });
  }
};

export const submitSubscription = (values, config) => async dispatch => {
  try {
    authChecker();
    dispatch({ type: LOAD_ALL_SUSBSCRIPTION_PLANS, payload: false });

    const res = await admin.post("/warehouse/createSubscriptionPlan", values);

    if (res.status === 200) {
      toast.success(res.data.message);
    }
    dispatch({ type: LOAD_ALL_SUSBSCRIPTION_PLANS, payload: false });
  } catch (submitSubscriptionError) {
    handleActionError({ submitSubscriptionError });
    config.formOptions.setSubmitting(false);
  }

  return dispatch(getAllSubscriptionPlans());
};

//reducer
const initialState = {
  subscriptionData: [],
  subscriptionPaymentData: [],
  subscriptionPaymentChartData: [],
  subscriptionActivityData: [],
  subscriptionActivityDataCount: 0,
  subPaymentPlanFeePerFieldMapped: 8000,
  subPaymentTarget: 400000000,
  subPaymentTotalAmountPaid: 0,
  loading: false,
  subscriptionPlans: [],
  subscriptionPlansMeta: {},
  loadingSubscriptionPlans: false,
  pages: 1,
  pageSize: { label: "10", value: 10 },
  total: 0,
  // pageSize: 10,
  pageNumber: 1,
  offset: 0,
  info: [],
};

function middlewareFilterData(state, action) {
  try {
    let _data = action.payload;
    let totalAmountPaid = 0;
    //totalAmountPaid = _data.length >= 1 ? Math.ceil(state.subPaymentPlanPerFieldMapped * _data.length) : 0;
    //let totalFieldSize = 0
    //commitmentFeesTotalAmountPaid = _data.length >= 1 ? Math.ceil(state.commitmentFeePerFieldMapped * _data.length) : 0;
    //console.time("imperative for");
    for (var i = 0; i < _data.length; i++) {
      totalAmountPaid += parseFloat(_data[i].subscription.farmer_paid); //? _data[i].farm_size : 0
    }
    let subscriptionPaymentChartData = generateXYData(_data);
    //console.timeEnd("imperative for");
    //totalAmountPaid.toFixed(2)
    return {
      ...state,
      subscriptionPaymentData: _data,
      subscriptionPaymentChartData,
      subPaymentTotalAmountPaid: totalAmountPaid,
      pages: action.pages,
      total: action.total,
    };
  } catch (error) {
    console.log({ error });
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.payload,
        pages: action.pages,
        total: action.total,
      };
    case GET_ALL_SUBSCRIPTION_PLANS:
      return {
        ...state,
        subscriptionPlans: action.payload,
        subscriptionPlansMeta: action.subscriptionPlansMeta,
      };
    case GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY:
      return {
        ...state,
        subscriptionData: action.payload,
        subscriptionActivityDataCount: action.payload.length,
        pages: action.pages,
        total: action.total,
      };
    case GET_ALL_FILTERED_SUBSCRIPTION_ACTIVITY_OVERVIEW:
      return {
        ...state,
        total: action.total,
      };
    case GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT_OVERVIEW:
      return {
        ...state,
        total: action.total,
      };
    case GET_ALL_FILTERED_SUBSCRIPTION_PAYMENT:
      return middlewareFilterData(state, action);
    case LOAD_SUBSCRIPTION:
      return {
        ...state,
        loading: action.payload,
      };
    case SUBSCRIPTION_PLAN_DETAILS:
      return {
        ...state,
        subscription_plan_details: action.payload,
      };
    case LOAD_ALL_SUSBSCRIPTION_PLANS:
      return {
        ...state,
        loadingSubscriptionPlans: action.payload,
      };
    default:
      return state;
  }
};

// selector functions
export const getSubscriptionData = state => state.legacySubscription.subscriptionData;
export const getSubscriptionPaymentData = state => state.legacySubscription.subscriptionPaymentData;
export const getSubscriptionPaymentChartData = state => state.legacySubscription.subscriptionPaymentChartData;
export const getSubscriptionActivityData = state => state.legacySubscription.subscriptionActivityData;
export const getSubscriptionActivityDataCount = state => state.legacySubscription.subscriptionActivityDataCount;
export const getSubPaymentPlanFeePerFieldMapped = state => state.legacySubscription.subPaymentPlanFeePerFieldMapped;
export const getSubPaymentTarget = state => state.legacySubscription.subPaymentTarget;
export const getSubPaymentTotalAmountPaid = state => state.legacySubscription.subPaymentTotalAmountPaid;
export const getSubLoading = state => state.legacySubscription.loading;
export const getSubscriptionPlans = state => state.legacySubscription.subscriptionPlans;
export const getSubscriptionPlansMeta = state => state.legacySubscription.subscriptionPlansMeta;
export const getLoadingSubscriptionPlans = state => state.legacySubscription.loadingSubscriptionPlans;
