import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextArea } from "organic";

const FormTextAreaPropTypes = {
  hint: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.node,
  errorText: PropTypes.string,
  inputProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  palceholder: PropTypes.any,
  rows: PropTypes.number,
  cols: PropTypes.number,
};

const FormTextArea = props => {
  // const {
  //   hint,
  //   cols,
  //   rows,
  //   description,
  //   errorText,
  //   label,
  //   isInvalid,
  //   inputProps,
  //   name,
  //   value,
  //   onChange,
  //   isDisabled,
  //   isRequired,
  //   placeholder,
  //   defaultValue,
  // }
  const {
    placeholder,
    required,
    name,
    label,
    rows,
    cols,
    hint,
    disabled = false,
    defaultValue, // for prefilled fields
    description,
  } = props;

  const [field, meta, helper] = useField({ name });

  useEffect(() => {
    if (defaultValue) {
      helper.setValue(defaultValue);
    }
  }, []);

  return (
    <TextArea
      {...field}
      hint={hint}
      cols={cols}
      rows={rows}
      description={description}
      errorText={meta.error}
      label={label}
      isDisabled={disabled}
      isRequired={required}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  );
};

FormTextArea.defaultProps = {
  isDisabled: false,
  rows: 5,
};

FormTextArea.propTypes = FormTextAreaPropTypes;

export { FormTextArea };
