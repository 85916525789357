import { toast } from "react-toastify";
import authService, { authChecker } from "../../services/v1/authService";
import questionnaire from "api/questionnaire";
import { getSubmissionConfigOptions } from "utils/v1/apiUtils";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
import { handleActionError } from "services/shared/AOSErrorService";

const SET_ALL_QUESTIONNAIRE = "SET_ALL_QUESTIONNAIRE";
const LOAD_ALL_QUESTIONNAIRE = "LOAD_ALL_ALL_QUESTIONNAIRE";

export const getAllQuestionnaire = () => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_ALL_QUESTIONNAIRE,
      payload: true,
    });

    const res = await questionnaire.get("getAllByOrg");

    let _questionnaireOptions = res.data.data.map(_questionnaire => {
      return { label: `${_questionnaire.name} (${_questionnaire.questions.length} questions)`, value: _questionnaire };
    });
    // .filter(_questionnaireOption => _questionnaireOption.value.model === "survey");
    dispatch({
      type: SET_ALL_QUESTIONNAIRE,
      payload: res?.data?.data || [],
      questionnaireOptions: _questionnaireOptions,
    });
  } catch (error) {
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong fetching Questionnaire, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(
        error?.response?.data?.message ?? "Something went wrong fetching Questionnaire, try refreshing the page"
      );
    }
    toast.error("Something went wrong fetching Questionnaire, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_ALL_QUESTIONNAIRE,
      payload: false,
    });
  }
};

export const addNewQuestionnaire =
  (values, config = {}) =>
  async dispatch => {
    try {
      const params = { season: values.season, name: values.model, crop: values.category_crop_type };
      values.organization = authService.getOrg();
      dispatch({
        type: LOAD_ALL_QUESTIONNAIRE,
        payload: true,
      });
      await questionnaire.post("/create", values, { params });
      toast.success("successfully created new Questionnaire");
      config.modal.hideModal();
      dispatch(getAllQuestionnaire());
    } catch (error) {
      handleActionError(error);
    } finally {
      dispatch({
        type: LOAD_ALL_QUESTIONNAIRE,
        payload: false,
      });
    }
  };
export const addManyQuestions =
  (values, config = {}) =>
  async dispatch => {
    try {
      const { hasModal } = getSubmissionConfigOptions(config);
      const params = { id: config.id };
      // values.organization = authService.getOrg();
      dispatch({
        type: LOAD_ALL_QUESTIONNAIRE,
        payload: true,
      });
      await questionnaire.put("/addManyQuestion", values, { params });
      toast.success("New Questions added successfully");
      if (hasModal) {
        config.modal.hideModal();
      }
      return dispatch(getAllQuestionnaire());
    } catch (error) {
      handleActionError(error);
    } finally {
      dispatch({
        type: LOAD_ALL_QUESTIONNAIRE,
        payload: false,
      });
    }
  };

export const deleteQuestionnaire =
  (config = {}) =>
  async dispatch => {
    try {
      dispatch({
        type: LOAD_ALL_QUESTIONNAIRE,
        payload: true,
      });
      const params = {
        id: config?.id,
      };
      await questionnaire.delete("deleteQuestionnaire", { params });
      toast.success("successfully deleted Questionnaire");
      dispatch(getAllQuestionnaire());
    } catch (error) {
      handleActionError(error);
    } finally {
      dispatch({
        type: LOAD_ALL_QUESTIONNAIRE,
        payload: false,
      });
    }
  };

export const updateQuestionnaireQuestions = (values, config) => async dispatch => {
  const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);
  const { modal, formOptions, id } = config;
  const sanitizedQuestions = sanitizeMongoDoc(values, ["psychometrics_status"]);

  if (!id) {
    return;
  }
  try {
    const params = { id };
    dispatch({
      type: LOAD_ALL_QUESTIONNAIRE,
      payload: true,
    });
    await questionnaire.put("/update", sanitizedQuestions, { params });
    toast.success("successfully updated Questions");
    if (hasModal) {
      modal.hideModal();
    }
    config?.onSuccess();
    return dispatch(getAllQuestionnaire());
  } catch (error) {
    if (hasFormOptions) {
      formOptions.setSubmitting(false);
    }
    handleActionError(error);
    config?.onError();
  } finally {
    dispatch({
      type: LOAD_ALL_QUESTIONNAIRE,
      payload: false,
    });
  }
};

export const updateQuestionnaire = (values, modal, params) => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_ALL_QUESTIONNAIRE,
      payload: true,
    });
    await questionnaire.put("/update", values, { params: { params } });
    toast.success("successfully updated Questions");
    modal.hideModal();
    dispatch(getAllQuestionnaire());
  } catch (error) {
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong updating questions, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(
        error?.response?.data?.message ?? "Something went wrong  updating questions, try refreshing the page"
      );
    }
    toast.error("Something went wrong  updating questions, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_ALL_QUESTIONNAIRE,
      payload: false,
    });
  }
};

const initialState = {
  questionnaireData: [],
  questionnaireOptions: [],
  loadingData: false,
};

let questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_QUESTIONNAIRE:
      return {
        ...state,
        questionnaireData: action.payload,
        questionnaireOptions: action.questionnaireOptions,
      };
    case LOAD_ALL_QUESTIONNAIRE:
      return { ...state, loadingData: action.payload };
    default:
      return state;
  }
};

export default questionnaireReducer;

// selector functions
export const getLoadingQuestionnaire = state => state.legacyQuestionnaire.loadingData;
export const getQuestionnaireData = state => state.legacyQuestionnaire.questionnaireData;
export const getQuestionnaireOptions = state => state.legacyQuestionnaire.questionnaireOptions;
export const getQuestionnaireMeta = state => state.legacyQuestionnaire.inputDistMeta;
