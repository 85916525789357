import ActiveAgents from "views/charts/activeAgents";
import AverageFarmerPerAgent from "views/charts/averageFarmerPerAgent";
import ReturningVsNewFarmers from "views/charts/returningVsNewFarmers";
import TotalCommitmentFee from "views/charts/totalCommitmentFee";
import TotalEquityContribution from "views/charts/totalEquityContribution";

export const ACTIVE_AGENTS_KEY = "activeAgents";
export const RETURNING_VS_NEWFARMERS_KEY = "returningVsNewFarmers";
export const TOTAL_COMMITMENTFEE_KEY = "totalCommitmentFee";
export const TOTAL_EQUITY_CONTRIBUTION_KEY = "totalEquityContribution";
export const AVERAGE_FARMER_PERAGENT_KEY = "averageFarmerPerAgent";

export const rightGridSchema = [
  {
    key: ACTIVE_AGENTS_KEY,
    title: "Active Agent",
    subtitle: "Active",
    unit: "",
    type: "pie",
    height: 123,
    width: 132,
    component: ActiveAgents,
  },
  {
    key: RETURNING_VS_NEWFARMERS_KEY,
    title: "Returning & New Farmers",
    subtitle: "New Farmers",
    unit: "",
    type: "pie",
    height: 123,
    width: 132,
    component: ReturningVsNewFarmers,
  },
  {
    key: TOTAL_COMMITMENTFEE_KEY,
    title: "Total CommitmentFee",
    subtitle: "Paid",
    unit: "NGN",
    type: "progress",
    height: 140,
    width: 123,
    component: TotalCommitmentFee,
  },
  {
    key: TOTAL_EQUITY_CONTRIBUTION_KEY,
    title: "Total Equity Contribution",
    subtitle: "Paid",
    unit: "NGN",
    type: "progress",
    height: 123,
    width: "100%",
    component: TotalEquityContribution,
  },
  {
    key: AVERAGE_FARMER_PERAGENT_KEY,
    title: "Average No. of Farmers /Agent",
    subtitle: "Farmer / Agent",
    unit: "",
    type: "progress",
    height: 123,
    width: "100%",
    component: AverageFarmerPerAgent,
  },
];
