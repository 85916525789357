import React from "react";
import Card from "components/card/card";
import Chart from "components/chart/chart";
import { CHART_HEADER_HEIGHT, ChartHeader } from "components/chart/chartHeader";
import { Box } from "organic";
import { uniqueId } from "utils";
import { useSelector } from "react-redux";
import { getOverviewSubscriptionPaymentTotal } from "features/v1/overview";

const TotalCommitmentFee = ({ cardHeight, chart, cardWidth = "auto" }) => {
  let totalCommitmentFee = useSelector(getOverviewSubscriptionPaymentTotal);

  // const totalCommitmentFee =
  //       isNaN(state.totalFarmersMappedCount * UNIT_COMMITMENT_FEE) ||
  //       state.totalFarmersMappedCount * UNIT_COMMITMENT_FEE == Infinity
  //         ? 0
  //         : Math.abs(state.totalFarmersMappedCount * UNIT_COMMITMENT_FEE);

  const { type, title, subtitle, unit, height, width } = chart;
  return (
    <Card height={cardHeight} width={cardWidth} stackHorizontal title={title}>
      <Box height={CHART_HEADER_HEIGHT}>
        {[{ subtitle, unit, value: totalCommitmentFee }].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Box>
      <Chart
        data={{ name: "Target", value: totalCommitmentFee, progress: 30 }}
        type={type}
        height={height}
        width={width}
      />
    </Card>
  );
};

export default TotalCommitmentFee;
