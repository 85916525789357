import React from "react";
import { Table } from "components/table";
import { Flex, Text } from "organic";
import { useDataFilter, useTable } from "hooks";
import { useGetInvoice } from "hooks/app/marketplace";
import numeral from "numeral";
import { appGet } from "utils";

const Invoicing = () => {
  const { filterParams } = useDataFilter();

  const { data } = useGetInvoice({ params: { dateRange: filterParams.dateRange } });

  const { tableProps } = useTable({
    data: data?.data.data || [],
    columns: [
      {
        Header: "Invoice ID",
        accessor: "invoice_id",
      },
      {
        Header: "Recipient Email",
        accessor: "recipient_email",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Bill To",
        accessor: "to",
      },
      {
        Header: "Date of Issue",
        accessor: data => {
          const dateIssued = appGet(data, "date_issued", "");
          if (dateIssued) {
            const parsedDate = new Date(dateIssued);
            return parsedDate.toLocaleDateString();
          }
          return "";
        },
      },
      {
        Header: "Issued On",
        accessor: data => {
          const dateDue = appGet(data, "date_due", "");
          if (dateDue) {
            const parsedDate = new Date(dateDue);
            return parsedDate.toLocaleDateString();
          }
          return "";
        },
      },
      {
        Header: " Quantity (KG)",
        accessor: trade => numeral(trade.total_quantity).format("0,0.00"),
      },
      {
        Header: "Amount",
        accessor: trade => numeral(trade.total_amount).format("0,0.00"),
      },
    ],
    useServerPagination: true,
    serverPaginationMeta: data?.meta || {},
    title: "All invoice history",
    noEditAction: true,
    noViewAction: true,
    noAction: false,
  });

  return (
    <Flex flexDirection="column">
      <Table {...tableProps} />
    </Flex>
  );
};

export default Invoicing;
