import { DateSelect } from "components/date";
import { FormInput } from "components/inputs";
import { createSeason } from "features/v1/season";
import { ErrorMessage, Form, Formik } from "formik";
import { Box, Button, Flex, Text } from "organic";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { hyphenate } from "utils";
// import { get, hyphenate } from "utils";
import { createProjectValidator } from "validators/projectValidator";

/** NEW PROJECT VALUES */
export const inialCreateProjectValues = {
  name: "",
  seasonId: "",
  target: 1,
  achieved: 1,
  duration: {
    start_date: new Date(),
    end_date: new Date(),
  },
  isActive: true,
  cropName: "N/A",
  // regions: []
};

const CreateProjectForm = ({ modal }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const [newSeason, setNewSeason] = useState(null);
  const [isTestSeason, setIsTestSeason] = useState(false);

  const dispatch = useDispatch();

  const handleCreateNewPeroject = (values, { setSubmitting, setErrors }) => {
    try {
      // generate season id
      const submissionTime = Date.now();
      values.seasonId = isTestSeason
        ? hyphenate(["test", new Date(values.duration.start_date).getFullYear(), submissionTime])
        : hyphenate([new Date(values.duration.start_date).getFullYear(), submissionTime]);
      dispatch(
        createSeason(values, {
          setSubmitting,
          setErrors,
          modal,
        })
      );
    } catch (error) {
      setSubmitting(false);
      toast.error("an error occured");
    }
  };
  return (
    <Formik
      initialValues={inialCreateProjectValues}
      validationSchema={createProjectValidator}
      onSubmit={handleCreateNewPeroject}
    >
      {({ isSubmitting, setFieldValue, errors }) => (
        <Box>
          <Form>
            <Text mx={2} color="red" textAlign="center">
              {errors.message}
            </Text>

            <Box my={40}>
              <Box mb={4}>
                <FormInput required name="name" label="Project Name" placeholder="Enter project name" mb={4} />{" "}
              </Box>
              {/* <Box mb={4}> */}
              {/* <FormInput required name="cropName" label="Crop Name" placeholder="Name of Crop eg(maize)" />{" "} */}
              {/* </Box> */}
              <Box mb={4}>
                <FormInput
                  name="target"
                  label="Project's Farmer Onboarding Target"
                  required
                  type="number"
                  placeholder="Enter total farmers target"
                />
              </Box>
              {/* <Box mb={4}>
                <FormInput
                  name="achieved"
                  label="Number of already Farmers Onboarded"
                  type="number"
                  placeholder="Enter farmers onboarded"
                />{" "}
              </Box> */}

              {/* <Box mb={4}>
                <Select
                  label="Season"
                  required
                  options={[
                    {
                      value: "ws",
                      label: "Wet Season",
                    },
                    {
                      value: "ds",
                      label: "Dry Season",
                    },
                  ]}
                  defaultValue={{
                    value: "ws",
                    label: "Wet Season",
                  }}
                  onChange={value => setNewSeason(value)}
                  placeholder="Select season"
                />
                <ErrorMessage name="season" component="p" className="text-danger" />
              </Box> */}
            </Box>

            <Box mb={4}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={isTestSeason}
                  onChange={() => setIsTestSeason(prevState => !prevState)}
                />{" "}
                Test Project
              </Label>
            </Box>
            <Flex mb={4} justifyContent="space-between">
              <Box>
                <div>
                  <Label>Start Date*</Label>
                </div>
                <DateSelect
                  date={startDate}
                  onChange={date => {
                    if (date !== null) {
                      setStartDate(date);
                      setFieldValue("duration.start_date", date.toISOString());
                    }
                  }}
                />

                <ErrorMessage name="duration.start_date" component="p" className="text-danger" />
              </Box>
              <Box>
                <div>
                  <Label>End Date*</Label>
                </div>
                <DateSelect
                  date={endDate}
                  onChange={date => {
                    if (date !== null) {
                      setEndDate(date);
                      setFieldValue("duration.end_date", date.toISOString());
                    }
                  }}
                />
                <ErrorMessage name="duration.end_date" component="p" className="text-danger" />
              </Box>
            </Flex>

            <Button
              mt="2rem"
              colorScheme="primary"
              display="block"
              type="submit"
              className="form-btn-1 btn-lg"
              disabled={isSubmitting || Object.keys(errors).length > 1}
            >
              Create new Project
            </Button>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default CreateProjectForm;
