import React from "react";
import styled, { css } from "styled-components/macro";
import shouldForwardProps from "@styled-system/should-forward-prop";
import { space, layout } from "styled-system";
import { SVG, customIcons } from "./customSvg";
import { appGet } from "utils";
import FeatherIcon from "./featherIcon";

const IconWrapper = styled.span.withConfig({
  shouldForwardProps,
})`
  ${props => css`
    ${space}
    ${layout}

  ${props?.cursor
      ? css`
          cursor: ${props.cursor};
        `
      : css``}
  `}
`;

const Icon = ({ icon, size, color, svgProps, ...restProps }) => {
  const isCustomIcon = appGet(customIcons, `${icon}`, false);

  if (isCustomIcon) {
    return <SVG icon={icon} {...restProps} />;
  }

  return (
    <IconWrapper {...restProps}>
      <FeatherIcon icon={icon} size={size} color={color} {...svgProps} />
    </IconWrapper>
  );
};

Icon.defaultProps = {
  svgProps: {}, // feathericons default
  icon: "square",
};

export default Icon;
