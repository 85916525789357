import { toast } from "react-toastify";
import { authChecker, getOrg } from "../../services/v1/authService";
import farmMonitoring from "api/farmMonitoring";
import farmSupervision from "api/farmSupervision";
import { handleActionError } from "services/shared/AOSErrorService";
import farmSupervisionCategory from "api/farmSupervisionCategory";

export const GET_ALL_FARM_SUPERVISION = "GET_ALL_FARM_SUPERVISION";
export const GET_ALL_FARM_MONITORING = "GET_ALL_FARM_MONITORING";
export const GET_ALL_FARM_SUPERVISION_CATEGORIES = "GET_ALL_FARM_SUPERVISION_CATEGORIES";
export const LOAD_ALL_FARM_SUPERVISION = "LOAD_ALL_FARM_SUPERVISION";
export const LOAD_ALL_FARM_SUPERVISION_CATEGORY = "LOAD_ALL_FARM_SUPERVISION_CATEGORY";
export const LOAD_ALL_FARM_MONITORING = "LOAD_ALL_FARM_MONITORING";

const organizationId = getOrg();

export const DEPRECATED_getAllFarmMonitoring = params => async dispatch => {
  try {
    authChecker();
    // console.log(params)
    dispatch({ type: LOAD_ALL_FARM_MONITORING, payload: true });
    const farmMonitoringData = await farmMonitoring.get("getAll", { params });

    dispatch({
      type: GET_ALL_FARM_MONITORING,
      payload: farmMonitoringData.data.data,
      totalFMDocCount: farmMonitoringData.data.meta?.totalDocCount,
      fmPageCount: farmMonitoringData.data?.meta?.pageCount || 0,
      fmMeta: farmMonitoringData.data?.meta || {},
    });

    dispatch({ type: LOAD_ALL_FARM_MONITORING, payload: false });
  } catch (getAllFarmMonitoringError) {
    dispatch({ type: LOAD_ALL_FARM_MONITORING, payload: false });
    console.log({ getAllFarmMonitoringError });
  }
};

export const getAllFarmSupervision = params => async dispatch => {
  try {
    authChecker();
    // console.log(params)
    dispatch({ type: LOAD_ALL_FARM_SUPERVISION, payload: true });
    const farmSupervisionData = await farmSupervision.get("getAllFarmVisitsWithParams", { params });

    let sortedFarmSupervisionData = farmSupervisionData.data.data.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    dispatch({
      type: GET_ALL_FARM_SUPERVISION,
      payload: sortedFarmSupervisionData,
      totalFSDocCount: farmSupervisionData.data.meta.totalDocCount,
      pageCount: farmSupervisionData.data?.meta?.pageCount || 0,
      fsMeta: farmSupervisionData.data?.meta || {},
    });

    dispatch({ type: LOAD_ALL_FARM_SUPERVISION, payload: false });
  } catch (getAllFarmSupervisionError) {
    dispatch({ type: LOAD_ALL_FARM_SUPERVISION, payload: false });
    console.log({ getAllFarmSupervisionError });
  }
};

/** DEPRECATED: this model and mos tlike this endpoint no longer functions */
export const bulkUploadFarmersClearedForMonitoring = (data, handleModalClose, fetchData) => dispatch => {
  // if (errorCheck(data, fileType)) {
  dispatch({ type: LOAD_ALL_FARM_MONITORING, payload: true });
  farmMonitoring
    .post("", data)
    .then(() => {
      toast.success("Successfully Uploaded. Contact Admin.");
      dispatch({ type: LOAD_ALL_FARM_MONITORING, payload: false });
      fetchData();
      handleModalClose();
    })
    .catch(err => {
      if (err?.response?.data?.error) {
        const { name, message } = err.response.data.error;
        toast.error(`${name}: ${message}`);
      } else if (err?.response?.data?.message) {
        const message = err?.response?.data?.message;
        toast.error(` ${message}`);
      } else {
        toast.error(`${err?.message}`);
      }
      dispatch({ type: LOAD_ALL_FARM_MONITORING, payload: false });
    });
};

export const updateFarmSupervisionCategory =
  (values, config = {}) =>
  async dispatch => {
    const { formOptions, modal } = config;
    try {
      dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: true });
      if (values.category && values?.sub_category) {
        await Promise.all([
          farmSupervisionCategory.put("update/category", values),
          farmSupervisionCategory.put(
            "update/subcategory",
            { sub_category: values.sub_category },
            { params: { categoryId: config.id, seasonId: config.season } }
          ),
        ]);
        toast.success("Subcategories updated successfully");
        toast.success("Category updated successfully");
      }
      if (!values.category && values?.sub_category) {
        await farmSupervisionCategory.put(
          `update/subcategory`,
          { sub_category: values.sub_category },
          { params: { categoryId: config.id, seasonId: config.season } }
        );
        toast.success("Subcategories updated successfully");
      }
      if (values.category && !values?.sub_category) {
        await farmSupervisionCategory.put(`update/category`, values, {
          params: { categoryId: config.id, seasonId: config.season },
        });
        toast.success("Category updated successfully");
      }
      dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: false });
      modal.hideModal();
      return dispatch(getAllSupervisionCategories({ seasonId: config.season }));
    } catch (updateSupervisionCategoryError) {
      formOptions.setSubmitting(false);
      dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: false });
      handleActionError(updateSupervisionCategoryError);
    }
  };

export const getAllSupervisionCategories = params => async dispatch => {
  try {
    dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: true });
    const res = await farmSupervisionCategory.get("", { params: { seasonId: params.seasonId, organizationId } });
    dispatch({ type: GET_ALL_FARM_SUPERVISION_CATEGORIES, payload: res?.data?.data || [] });
    dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: false });
  } catch (getAllSupervisionCategoriesError) {
    dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: false });
    handleActionError(getAllSupervisionCategoriesError);
  }
};

export const createSupervisionCategory =
  (values, config = {}) =>
  async dispatch => {
    const { formOptions } = config;
    try {
      dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: true });
      const res = await farmSupervisionCategory.post("set", values);
      if (res.status === 200) {
        toast.success("Category created successfully");
      }
      dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: false });
      formOptions.setSubmitting(false);
      config.modal.hideModal();
      return dispatch(getAllSupervisionCategories(config.filterParams));
    } catch (createSupervisionCategoryError) {
      formOptions.setSubmitting(false);
      dispatch({ type: LOAD_ALL_FARM_SUPERVISION_CATEGORY, payload: false });
      handleActionError(createSupervisionCategoryError);
    }
  };

const initialState = {
  farmMonitoringData: [],
  totalFSDocCount: 0,
  totalFMDocCount: 0,
  fsPageCount: 0,
  fmPageCount: 0,
  fmMeta: {},
  fsMeta: {},
  farmSupervisionData: [],
  farmSupervisionCategoriesData: [],
  loadingFarmMonitoringData: false,
  loadingFarmSupervisionData: false,
  loadingFarmSupervisionCategories: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FARM_MONITORING:
      return {
        ...state,
        farmMonitoringData: action.payload,
        totalFMDocCount: action.totalFMDocCount,
        fmPageCount: action.fmPageCount,
        fmMeta: action.fmMeta,
      };
    case LOAD_ALL_FARM_MONITORING:
      return { ...state, loadingFarmMonitoringData: action.payload };
    case GET_ALL_FARM_SUPERVISION:
      return {
        ...state,
        farmSupervisionData: action.payload,
        totalFSDocCount: action.totalFSDocCount,
        fsPageCount: action.pageCount,
        fsMeta: action.fsMeta,
      };
    case GET_ALL_FARM_SUPERVISION_CATEGORIES:
      return {
        ...state,
        farmSupervisionCategoriesData: action.payload,
      };
    case LOAD_ALL_FARM_SUPERVISION_CATEGORY:
      return {
        ...state,
        loadingFarmSupervisionCategories: action.payload,
      };
    case LOAD_ALL_FARM_SUPERVISION:
      return { ...state, loadingFarmSupervisionData: action.payload };
    default:
      return state;
  }
};

export const getFarmMonitoringData = state => state.legacyFarmMonitoring.farmMonitoringData;
export const getTotalFSDocCount = state => state.legacyFarmMonitoring.totalFSDocCount;
export const getTotalFMDocCount = state => state.legacyFarmMonitoring.totalFMDocCount;
export const getFsPageCount = state => state.legacyFarmMonitoring.fsPageCount;
export const getFmPageCount = state => state.legacyFarmMonitoring.fmPageCount;
export const getFmMeta = state => state.legacyFarmMonitoring.fmMeta;
export const getFsMeta = state => state.legacyFarmMonitoring.fsMeta;
export const getFarmSupervisionData = state => state.legacyFarmMonitoring.farmSupervisionData;
export const getFarmSupervisionCategoriesData = state => state.legacyFarmMonitoring.farmSupervisionCategoriesData;
export const getLoadingFarmMonitoringData = state => state.legacyFarmMonitoring.loadingFarmMonitoringData;
export const getLoadingFarmSupervisionData = state => state.legacyFarmMonitoring.loadingFarmSupervisionData;
export const getloadingFarmSupervisionCategories = state => state.legacyFarmMonitoring.loadingFarmSupervisionCategories;
