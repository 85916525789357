import React from "react";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { PulseLoader } from "react-spinners";

export default function OverlayLoader({ active, children }) {
  return (
    <LoadingOverlay
      active={active}
      spinner={<PulseLoader />}
      // text='Chart Loading...'
      styles={{
        overlay: base => ({
          ...base,
          background: "rgba(0, 0, 0, 0.1)",
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
}
