//redux state, actions and reducer for custom filter component

import { durationOption } from "utils/v1/filterOption";
import { selectOptionFromValue } from "utils/v1/selectUtils";

// actions types
const SET_DURATION = "SET_DURATION";
const SET_DATE_RANGE = "SET_DATE_RANGE";
const SET_SEASON_ID = "SET_SEASON_ID";
const SET_REGION = "SET_REGION";
const SET_ZONE = "SET_ZONE";

// action creators
export const setDuration = selectedDuration => dispatch => {
  // no need to set dateRange here in case of custom duration
  if (selectedDuration.value === "custom") {
    dispatch({
      type: SET_DURATION,
      payload: selectedDuration.value,
    });
    return;
  }

  // set duration and dateRange value
  let { range, value } = selectedDuration;

  dispatch({
    type: SET_DURATION,
    payload: value,
  });

  return dispatch({
    type: SET_DATE_RANGE,
    payload: [range.startDate, range.endDate],
  });
};

export const setDateRange = selectedDateRange => dispatch => {
  // set dateRange value
  dispatch({ type: SET_DATE_RANGE, payload: selectedDateRange });
  // setDuration to custom
  let duration = selectOptionFromValue(durationOption, "custom");
  return dispatch(setDuration(duration));
};

export const setSeasonId = selectedSeason => dispatch => {
  return dispatch({
    type: SET_SEASON_ID,
    payload: selectedSeason,
  });
};

export const setRegion = selectedRegion => dispatch => {
  return dispatch({
    type: SET_REGION,
    payload: selectedRegion,
  });
};

export const setZone = selectedZone => dispatch => {
  return dispatch({
    type: SET_ZONE,
    payload: selectedZone,
  });
};

// export const getAllRegions = (func) => (dispatch) => {
//   return region.get().then((res) => )
// };

// initial state

// get userRole from sessionStorage

let _durationOption = durationOption.filter(item => item.value !== "custom");
let { startDate, endDate } = _durationOption[0].range;

const initialState = {
  duration: _durationOption[0].value,
  seasonId: null,
  selectedSeasonOption: null,
  dateRange: [startDate, endDate],
  region: null,
  selectedRegionOption: null,
  zone: null,
  selectedZoneValue: null,
};

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DURATION:
      return middlewareSetDuration(state, action);
    case SET_DATE_RANGE:
      return middlewareSetDateRange(state, action);
    case SET_SEASON_ID:
      return middlewareSetSeasonId(state, action);
    case SET_REGION:
      return middlewareSetRegion(state, action);
    case SET_ZONE:
      return middlewareSetZone(state, action);
    default:
      return state;
  }
};

// middleware reducer functions
const middlewareSetSeasonId = (state, action) => {
  return {
    ...state,
    seasonId: action.payload,
  };
};
const middlewareSetDateRange = (state, action) => {
  return {
    ...state,
    dateRange: action.payload,
  };
};
const middlewareSetDuration = (state, action) => {
  return {
    ...state,
    duration: action.payload,
  };
};

const middlewareSetRegion = (state, action) => {
  return {
    ...state,
    region: action.payload.value,
    // zone: action.payload.zones,
    // selectedZoneValue: "all",
  };
};

const middlewareSetZone = (state, action) => {
  return {
    ...state,
    zone: action.payload,
  };
};

// selector functions
export const getFilterDuration = state => state.legacyFilter.duration;
export const getFilterDateRange = state => state.legacyFilter.dateRange;
export const getFilterSeasonId = state => state.legacyFilter.seasonId;
export const getFilterRegion = state => state.legacyFilter.region;
export const getFilterZone = state => state.legacyFilter.zone;
export const getFilterZoneValue = state => state.legacyFilter.selectedZoneValue;
