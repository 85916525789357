import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Box, Button, Flex, Text, buttonConstants } from "organic";
import { FormInput } from "components/inputs";
import { CropSelect, selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import { LogisticsTypeSelect } from "components/select";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/accordion";
import { appGet } from "utils";
import { VisibilityWrapper } from "components/layout";

const getEditTradeInitialValues = trade => {
  return {
    initiated_by: appGet(trade, "initiated_by", ""),
    crop_type: appGet(trade, "crop_type", ""),
    cost_price: appGet(trade, "cost_price", ""),
    selling_price: appGet(trade, "selling_price", ""),
    anticipated_profit: appGet(trade, "anticipated_profit", ""),
    logistics_type: [],
    logistics_cost: appGet(trade, "logistics_cost", ""),
    target_quantity: appGet(trade, "target_quantity", ""),
    assigned_agents: appGet(trade, "assigned_agents", []),
    price_to_pay: appGet(trade, "price_to_pay", ""),
    max_farmer_payments: appGet(trade, "max_farmer_payments", ""),
    commission_fee: appGet(trade, "commission_fee", ""),
    contingency_fee: appGet(trade, "contingency_fee", ""),
    trade_finance_fee: appGet(trade, "trade_finance_fee", ""),
    total_investment: appGet(trade, "total_investment", ""),
    status: appGet(trade, "status", ""),
  };
};

// a function to reduce an array of object to an array of strings from the array[inde]._id
const getLogisticsTypeIds = logistics_types => {
  return logistics_types.map(logistics_type => logistics_type._id);
};

const EditTradeDetails = props => {
  const { selected, modal } = props;
  // handle all logitics types state here and submit the ids only
  const [logistics_types, setLogisticsTypes] = useState([{ type: "", unit: "", price: "", amount: "" }]);
  const [showLogisticsSelect, setShowLogisticsSelect] = useState(false);

  // const onSubmit = (value) => {
  // if (authLevelChecker()) {
  // dispatch(editTradeDetails(values, { formOptions, modal }));
  // } else {
  // formOptions.setSubmitting(false)
  // }
  // };
  const onSubmit = (__values, __formOptions) => {
    const ignoredlogisticsIds = getLogisticsTypeIds(logistics_types);

    //   // if (authLevelChecker()) {
    //   // dispatch(editTradeDetails(values, { formOptions, modal }));
    //   // } else {
    //   // formOptions.setSubmitting(false)
    //   // }
  };

  const initialValues = getEditTradeInitialValues(selected);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, values, errors, setFieldValue }) => (
        <Box>
          <Form>
            <Box>
              <Text mx={2} my={1}>
                {errors?.message}
              </Text>

              <Flex flexDirection="column" width="100%" gap="1rem">
                <Flex gap="1rem">
                  <Flex width="50%" gap="1rem">
                    <CropSelect label="Crop Type" name="cropType" onChange={val => setFieldValue("cropType", val)} />
                  </Flex>
                  <Box width="50%">
                    <FormInput
                      label="Cost Price"
                      name="cost_price"
                      defaultValue={selected.first_name}
                      required
                      // ref={senderIdRef}

                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          value={values.currency}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                </Flex>
                <Flex width="100%" gap="1rem">
                  <Box width="50%">
                    <FormInput
                      label="Selling Price"
                      name="selling_price"
                      defaultValue={selected.first_name}
                      required
                      // ref={senderIdRef}

                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          value={values.currency}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                  <Box width="50%">
                    <FormInput
                      label="Anticipated Profit "
                      name="anticipated_profit"
                      defaultValue={selected.first_name}
                      required
                      // ref={senderIdRef}

                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          value={values.currency}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                </Flex>
                <Accordion multiple defaultValue="default">
                  <AccordionItem value="default">
                    <AccordionTrigger>Logistics Type</AccordionTrigger>
                    <AccordionContent>
                      {values.logistics_type.length > 0 ? (
                        values.logistics_type.map((logisticType, logisticsTypeIndex) => (
                          <Flex key={logisticType.id} width="100%" gap="1rem">
                            <Box width="50%">
                              <FormInput
                                name="logistics_types"
                                label="Type"
                                defaultValue={values.logistics_types[0].type}
                                disabled
                              />
                            </Box>
                            <Box width="50%">
                              <FormInput name={`logistics_types[${logisticsTypeIndex}]`} />
                            </Box>
                          </Flex>
                        ))
                      ) : (
                        <></>
                      )}
                      {logistics_types.map((logisticsType, index) => (
                        <Flex key={index} width="100%" gap="1rem">
                          <Box width="50%">
                            <FormInput
                              name={`logistics_types[${index}].name`}
                              label={index === 0 ? "Type" : ""}
                              defaultValue={logisticsType.name}
                              disabled
                            />
                          </Box>
                          <Box width="50%">
                            <FormInput
                              name={`logistics_types[${index}].cost_per_unit`}
                              label={index === 0 ? "Unit" : ""}
                              required={index === 0}
                              defaultValue={logisticsType.unit}
                              disabled
                            />
                          </Box>
                          <Box width="50%">
                            <FormInput
                              name={`logistics_types[${index}].price`}
                              label={index === 0 ? "Price/Unit" : ""}
                              defaultValue={logisticsType.price}
                              disabled
                            />
                          </Box>
                          <Box width="50%">
                            <FormInput
                              name={`logistics_types[${index}].amount`}
                              label={index === 0 ? "Amount" : ""}
                              defaultValue={logisticsType.amount}
                              isDisabled
                              rightAddon={
                                <CurrencySelect
                                  onChange={currencyValue => {
                                    setFieldValue(`logistics_types[${index}].currency`, currencyValue);
                                  }}
                                  value={values.currency}
                                  colorScheme={selectConstants.colorSchemes.UNSTYLED}
                                />
                              }
                            />
                          </Box>
                        </Flex>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
                <Flex justifyContent="flex-end" width="100%" gap="1rem" alignItems="center">
                  <Text font="smMedium" color="gray500">
                    Total Amount
                  </Text>
                  <Text font="mdMedium" color="gray700">
                    {values.logistics_cost}
                    {values.currency}
                  </Text>
                </Flex>
                <Flex alignItems="center" width="100%" gap="1rem" pb="1rem" height="50px">
                  <Button
                    leftIcon="plus-circle"
                    colorScheme={buttonConstants.colorSchemes.TERTIARY_COLOR}
                    type="button"
                    onClick={() => {
                      setShowLogisticsSelect(() => !showLogisticsSelect);
                    }}
                  >
                    <Text> Add Type</Text>
                  </Button>
                  <VisibilityWrapper isVisible={showLogisticsSelect}>
                    <LogisticsTypeSelect
                      label=""
                      onChange={value => {
                        setLogisticsTypes([...logistics_types, value]);
                        setShowLogisticsSelect(false);
                      }}
                    />
                  </VisibilityWrapper>
                </Flex>

                <Flex width="100%" gap="1rem">
                  <Box width="50%">
                    <FormInput name="target_qty" required label="Target Qty (KG) " defaultValue={selected.first_name} />
                  </Box>
                  <Box width="50%">
                    <FormInput
                      leftIcon="user"
                      name="assigned_agent"
                      label="Assigned Agents "
                      defaultValue={values.last_name}
                    />
                  </Box>
                </Flex>
                <Flex width="100%" gap="1rem">
                  <Box width="50%">
                    <FormInput
                      label="Price to Pay Farmers"
                      name="farmers_price"
                      required
                      defaultValue={selected.first_name}
                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                  <Box width="50%">
                    <FormInput
                      name="max_price"
                      label="Maximum Farmer Payment for Breakeven "
                      required
                      defaultValue={values.phone_number}
                    />
                  </Box>
                </Flex>
                <Flex width="100%" gap="1rem">
                  <Box width="50%">
                    <FormInput
                      label="Agency Commission "
                      name="agency_Commission"
                      defaultValue={selected.first_name}
                      required
                      // ref={senderIdRef}

                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                  <Box width="50%">
                    <FormInput
                      label="Contingency Fee  "
                      name="contingency_fee"
                      defaultValue={selected.first_name}
                      required
                      // ref={senderIdRef}

                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                </Flex>
                <Flex width="100%" gap="1rem">
                  <Box width="50%">
                    <FormInput
                      label="Trade Finance Fee "
                      name="trade_finance_fee"
                      defaultValue={selected.first_name}
                      required
                      // ref={senderIdRef}

                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                  <Box width="50%">
                    <FormInput
                      label="Total Investment "
                      name="total_investment"
                      defaultValue={selected.first_name}
                      required
                      // ref={senderIdRef}

                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue("currency", currencyValue);
                          }}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                        />
                      }
                    />
                  </Box>
                </Flex>
              </Flex>

              <Flex gap="20px" mt="2rem">
                <Button
                  size="lg"
                  display="block"
                  type="button"
                  colorScheme="secondaryGray"
                  onClick={modal.hideModal}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button size="lg" display="block" type="submit" disabled={isSubmitting}>
                  Edit Trade
                </Button>
              </Flex>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default EditTradeDetails;
