export const mapSeasonIdsToLabels = seasonId => {
  switch (seasonId) {
    case "64adb3bb530a4b0014357e6f":
      return "Test season";
    case "65c187d8116f4cbc98822e56":
      return "Dry season 2024";
    case "65535aa16f3c2d00149d7e72":
      return "Wet season 2024";
    default:
      return "---";
  }
};

export const mapModuleIdsToLabels = moduleId => {
  switch (moduleId) {
    case "farm_mapping":
      return "Farm Mapping";
    case "farm_monitoring":
      return "Farm Monitoring";
    case "harvest":
      return "Harvest";
    case "clearance":
      return "Clearance";
    // Add more cases as needed
    default:
      return "---";
  }
};

export const mapRegionIdsToLabels = regionId => {
  switch (regionId) {
    case "64ad457958a3411bd1befe88":
      return "R1";
    case "2":
      return "Jalingo";
    case "3":
      return "Zaria";
    // Add more cases as needed
    default:
      return "---";
  }
};

export const mapGroupIdsToLabels = groupId => {
  switch (groupId) {
    case "group1":
      return "Group 1";
    case "group2":
      return "Group 2";
    case "group3":
      return "Group 3";
    // Add more cases as needed
    default:
      return "---";
  }
};
