import { authChecker } from "../../services/v1/authService";
// import { toast } from "react-toastify";
import admin2 from "api/admin";

// actions
// const CREATE_BOUND_SERVICE = "CREATE_BOUND_SERVICE";
const TOGGLE_BOUND_STATE = "TOGGLE_BOUND_STATE";
const GET_ALL_BOUND_SERVICE = "GET_ALL_BOUND_SERVICE";
// const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
// const CLEAR_BOUND_FORM = "CLEAR_BOUND_FORM";
// const ADD_BOUND_SERVICE = "ADD_BOUND_SERVICE";

// action creators
export const toggleInboundState = () => dispatch => {
  dispatch({
    type: TOGGLE_BOUND_STATE,
  });
};

export const getAllBoundServices = params => async dispatch => {
  try {
    authChecker();

    const boundData = await admin2.get("warehouse/bound/getAllWithParams", { params });

    dispatch({
      type: GET_ALL_BOUND_SERVICE,
      payload: boundData.data.data,
      boundMeta: boundData?.data?.meta || {},
    });
  } catch (getAllBoundServicesError) {
    console.log({ getAllBoundServicesError });
  }
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;

  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};
export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  dispatch({
    type: HANDLE_PAGE_CHANGE,
    payload: pageNumber,
  });
  dispatch(handleOffset(pageNumber, pageSize));
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY,
    payload: arrayOfInfo,
  });
};

//reducer
const initialState = {
  boundServiceActive: false,
  boundData: [],
  boundMeta: {},
  pageSize: 10,
  pageNumber: 1,
  offset: 0,
  info: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BOUND_STATE: {
      const { boundServiceActive } = state;
      return { ...state, boundServiceActive: !boundServiceActive };
    }
    case GET_ALL_BOUND_SERVICE:
      return {
        ...state,
        boundData: action.payload,
        boundMeta: action.boundMeta,
      };
    case HANDLE_PAGE_CHANGE:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY: {
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize);
      return { ...state, info: infoToDisplay };
    }
    default:
      return state;
  }
};

// selector functions
export const getBoundServiceActive = state => state.legacyInboundService.boundServiceActive;
export const getBoundData = state => state.legacyInboundService.boundData;
export const getBoundMeta = state => state.legacyInboundService.boundMeta;
export const getPageSize = state => state.legacyInboundService.pageSize;
export const getPageNumber = state => state.legacyInboundService.pageNumber;
export const getOffset = state => state.legacyInboundService.offset;
export const getInfo = state => state.legacyInboundService.info;
