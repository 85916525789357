import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { getAllCrops, getCurSeasonCropsData } from "features/v1/cropsAndInput";
import { useDisclosure, useTable } from "hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils";
import CreateCropForm from "views/forms/createCropForm";
import EditCropDetails from "./crop/editCropDetails";
import AddVarietyToCrop from "./crop/addVarietyToCrop";

const EDIT_CROP_VARIETY_KEY = "editCropVariety";

const CropSettingsPage = () => {
  let cropsData = useSelector(getCurSeasonCropsData);

  const dispatch = useDispatch();

  const createCropDisclosure = useDisclosure();
  const editCropDisclosure = useDisclosure();
  const editCropVarietyDisclosure = useDisclosure();

  const { tableProps, selectedRow: selectedCrop } = useTable({
    data: cropsData,
    columns: [
      {
        id: "cropCreatedAt",
        Header: "Created on",
        accessor: crop => formatDate(new Date(crop.created_at)),
      },
      {
        Header: "Crop Name",
        accessor: "name",
      },
      {
        Header: "Crop Category",
        accessor: "crop_category",
      },
      {
        id: "cropVariaties",
        Header: "Crop Varieties",
        accessor: crop => {
          return (crop?.crop_variety && crop.crop_variety.join(" , ")) || "None Yet";
        },
      },
    ],
    title: "All Crops",
    noViewAction: true,
  });

  useEffect(() => {
    dispatch(getAllCrops());
  }, []);
  return (
    <div>
      <Table
        {...tableProps}
        createAction={{ label: "Add New Crop" }}
        onCreateAction={() => {
          createCropDisclosure.onOpen();
        }}
        onEditAction={() => {
          editCropDisclosure.onOpen();
        }}
        getExtraActions={() => [
          {
            label: "Edit Crop Variety",
            actionKey: EDIT_CROP_VARIETY_KEY,
            icon: "git-branch",
          },
        ]}
        onExtraAction={(selectedAction, __config) => {
          if (selectedAction.actionKey === EDIT_CROP_VARIETY_KEY) {
            editCropVarietyDisclosure.onOpen();
          }
        }}
      />

      <Modal {...createCropDisclosure.modalProps}>
        <ModalContent title="Add New Crop">
          <CreateCropForm />
        </ModalContent>
      </Modal>

      <Modal {...editCropDisclosure.modalProps}>
        <ModalContent title="Update Crop Details">
          <EditCropDetails selected={selectedCrop} modal={editCropDisclosure.modal} />
        </ModalContent>
      </Modal>

      <Modal {...editCropVarietyDisclosure.modalProps}>
        <ModalContent title="Update Crop Variety">
          <AddVarietyToCrop selected={selectedCrop} modal={editCropVarietyDisclosure.modal} />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CropSettingsPage;
