import React from "react";
import { Flex, Text } from "organic";
import numeral from "numeral";

export const CHART_HEADER_HEIGHT = 57;

export const ChartHeader = ({ title, value, subtitle, unit }) => {
  return (
    <Flex flexDirection="column" mr="2rem">
      {title && (
        <Text as="p" font="smMedium" color="gray500">
          {title}
        </Text>
      )}
      <Text as="p" font="displayXsBold" color="gray900" marginTop="0.5rem">
        {numeral(value).format("0,0.0") || "--"}
        <Text as="span" font="xsRegular" color="gray900">
          {unit ? unit : ""}
        </Text>
      </Text>
      {subtitle && (
        <Text as="p" font="smMedium" color="gray500">
          {subtitle || "N/A"}
        </Text>
      )}
    </Flex>
  );
};
