import * as Yup from "yup";

export const createProjectValidator = Yup.object().shape({
  duration: Yup.object().shape({
    start_date: Yup.string().required("Reuired"),
    end_date: Yup.string().required("Required"),
  }),
  name: Yup.string().required("Required"),
  seasonId: Yup.string(),
  isActive: Yup.bool(),
});
