import _ from "lodash";
import { separateCamelCase } from "utils/shared-utils/text-utils";
// useful function for reading and manipulating TA NUmbers

/**
 * Extract groupId from a farmer TA NUmber
 * @param  {String} taNumber
 */
const getGroupIdFromTA = taNumber => {
  let groupID = "";
  try {
    if (_.isString(taNumber)) {
      groupID = taNumber.slice(0, 16);
    }
    return groupID;
  } catch (error) {
    console.log({ error });
  }
};

/**
 * Extract TA Number from a fieldId / field TA NUmber
 * @param  {String} fieldId
 */
const getTAFromFieldId = fieldId => {
  let taNumber = "";
  try {
    if (_.isString(fieldId)) {
      taNumber = fieldId.slice(0, 19);
    }
    return taNumber;
  } catch (error) {
    console.log({ error });
  }
};

/**
 * Extract groupID from a fieldId / field TA NUmber
 * @param  {String} fieldId
 */
const getGroupIDFromFieldId = fieldId => {
  let groupID = "";
  try {
    if (_.isString(fieldId)) {
      groupID = fieldId.slice(0, 16);
    }
    return groupID;
  } catch (error) {
    console.log({ error });
  }
};

/**
 * Retrieve the lead farmer from a list of Farmer Objects
 * using a role field
 * @param {Array} farmers - an array of farmer objects that include a role field
 */
const getLeadFarmerFromList = farmers => {
  let lead = {};
  if (farmers?.length > 0) {
    farmers.forEach(farmer => {
      if (farmer.role === "lead_farmer") lead = farmer;
    });
  }
  return lead;
};

/**
 * Return true if farmer is a lead farmer
 * @param {Object} farmer - Object containing farmer data including Farmer ID (TA) or role
 */
const isLeadFarmer = farmer => {
  return farmer.role === "lead_farmer" || farmer.ta_number.slice(-2) === "01";
};

const formatRole = function (roleValue) {
  if (_.isString(roleValue) && !_.isEmpty(roleValue)) {
    switch (roleValue) {
      case "lead_farmer":
        return "Lead Farmer";
      case "secretary":
        return "Secretary";
      case "farmer":
        return "Farmer";
      case "agent":
        return "Agent";
      case "zonalManager":
        return "Zonal Manager";
      case "regionalManager":
        return "Regional Manager";
      case "finance":
        return "Finance";
      case "storeManager":
        return "Store Manager";
      case "operationalManager":
        return "Operational Manager";
      case "subAdmin":
        return "Sub Admin";
      case "admin":
        return "Admin";
      case "superAdmin":
        return "Super User";
      default:
        return separateCamelCase(roleValue);
    }
  }

  return "N/A";
};

const formatGender = genderValue => {
  if (genderValue === "male" || genderValue === "Male") return "Male";
  if (genderValue === "female" || genderValue === "Female") return "Female";
  return "";
};

export {
  isLeadFarmer,
  getLeadFarmerFromList,
  getGroupIDFromFieldId,
  getTAFromFieldId,
  getGroupIdFromTA,
  formatRole,
  formatGender,
};
