import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Flex, Image } from "organic";
import loginBg from "assets/images/login.png";
import { Logo } from "components/common";

export default function AuthLayout() {
  return (
    <Flex flexDirection="column" height="100vh" maxHeight="100vh" padding="0px" margin="0px">
      <Flex flexDirection="row" width="100%" height="100vh" justifyContent="center">
        <Box p={["0.5rem", "1rem", "4rem"]} width={[1 / 2, 7 / 10, 1 / 2]} maxWidth="560px">
          <Box width="123px">
            <Logo />
          </Box>
          <Box marginTop={["149px"]}>
            <Outlet />
          </Box>
        </Box>
        <Box maxHeight="100vh" height={[0, 0, 0, "100%"]} width={[0, 0, 0, "762px"]} overflow="hidden">
          <Image src={loginBg} maxWidth="762px" width="100%" height="auto" overflow="hidden" alt="login page image" />
        </Box>
      </Flex>
    </Flex>
  );
}
