import { useLegacyRole } from "hooks";
import React, { useEffect, useState } from "react";

const ScopedAction = ({ resource, children }) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const { canWrite } = useLegacyRole();

  useEffect(() => {
    const _granted = canWrite.includes(resource) || canWrite.includes("all");
    setPermissionGranted(_granted);
  }, [canWrite]);

  if (!permissionGranted) {
    return <></>;
  }
  return <>{children}</>;
};

export { ScopedAction };
