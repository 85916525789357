import React from "react";
import _ from "lodash";
import { Field } from "formik";
import { Box, Flex, Text } from "organic";
import ReactSelect from "react-select";
import { classnames } from "utils";
import {
  SelectErrorComponent,
  mapSelectStateToClassName,
  reactSelectInnerComponentsClassNames,
  selectConstants,
  selectDefaultProps,
  selectPropTypes,
} from ".";

const Select2 = props => {
  const { label, name, options, isMulti, isLoading, colorScheme, extraActionOnChange, placeholder, ...rest } = props;

  const selectRootClassnames = classnames({
    select: colorScheme === selectConstants.colorSchemes.DEFAULT,
    "blue-select": colorScheme === selectConstants.colorSchemes.BLUE,
    "black-select": colorScheme === selectConstants.colorSchemes.BLACK,
    "unstyled-select": colorScheme === selectConstants.colorSchemes.UNSTYLED,
  });
  const selectContainerClassnames = classnames({
    "select-container": colorScheme === selectConstants.colorSchemes.DEFAULT,
    "blue-select-container": colorScheme === selectConstants.colorSchemes.BLUE,
    "black-select-container": colorScheme === selectConstants.colorSchemes.BLACK,
    "unstyled-select-container": colorScheme === selectConstants.colorSchemes.UNSTYLED,
  });

  const onChange = (form, field, option) => {
    form.setFieldValue(field.name, isMulti ? option.map(item => item.value) : option.value);
    // Call for additional action onChange
    if (_.isFunction(extraActionOnChange)) extraActionOnChange();
  };

  const getValue = field => {
    if (options) {
      if (isMulti) {
        const selectedOption = options.filter(option => field.value.indexOf(option.value) >= 0);
        return selectedOption.length ? selectedOption : [];
      } else {
        const selectedOption = options.find(option => option.value === field.value);
        return selectedOption ? selectedOption : "";
      }
    } else {
      return isMulti ? [] : "";
    }
  };

  return (
    <Flex flexDirection="column" width="100%">
      {label && (
        <Box mb={2}>
          <Text as="label" font="smMedium" color="grey700">
            {label}
            {rest?.required === true ? (
              <Text as="span" color="primary600">
                *
              </Text>
            ) : (
              ""
            )}
          </Text>
        </Box>
      )}
      <Field name={name}>
        {({ form, field, meta }) => {
          const { onBlur } = field;
          return (
            <Flex flexDirection="column" width="100%">
              <ReactSelect
                {...rest}
                placeholder={placeholder}
                isLoading={isLoading}
                unstyled
                id={name}
                value={getValue(field)}
                onChange={val => {
                  onChange(form, field, val);
                }}
                styles={{
                  placeholder: provided => ({
                    ...provided,
                    color: "#D0D5DD",
                  }),
                }}
                onBlur={onBlur}
                options={options}
                isMulti={isMulti}
                className={selectContainerClassnames}
                classNamePrefix={selectRootClassnames}
                classNames={{
                  clearIndicator: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.clearIndicator),
                  control: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.control),
                  dropdownIndicator: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.dropdownIndicator),
                  group: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.group),
                  groupHeading: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.groupHeading),
                  indicatorsContainer: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.indicatorsContainer),
                  indicatorSeparator: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.indicatorSeparator),
                  input: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.input),
                  loadingIndicator: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.loadingIndicator),
                  loadingMessage: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.loadingMessage),
                  menu: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menu),
                  menuList: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menuList),
                  menuPortal: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menuPortal),
                  multiValue: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValue),
                  multiValueLabel: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValueLabel),
                  multiValueRemove: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValueRemove),
                  noOptionsMessage: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.noOptionsMessage),
                  option: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.option),
                  placeholder: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.placeholder),
                  singleValue: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.singleValue),
                  valueContainer: state =>
                    mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.valueContainer),
                }}
              />
              {meta.error && <SelectErrorComponent>{meta.error}</SelectErrorComponent>}
            </Flex>
          );
        }}
      </Field>
    </Flex>
  );
};

Select2.propTypes = selectPropTypes;
Select2.defaultProps = selectDefaultProps;

export default Select2;
