import React from "react";
import PropTypes from "prop-types";
import EmptyStateIcon from "assets/icons/empty-state-icon.svg";
import { Button, Flex, Text } from "organic";

const emptyStatePropTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.string,
  onClickAction: PropTypes.func,
  actionLabel: PropTypes.string,
  status: PropTypes.oneOf(["error", "danger", "info", "warning", "default"]),
};

const EmptyState = ({ title, subtitle, message, onClickAction, actionLabel }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      py="2rem"
      mx="auto"
      minHeight="340px"
    >
      <img src={EmptyStateIcon} alt="No Data" />
      <Text font="mdSemibold" color="gray900" textAlign="center">
        {title ? title : "Nothing Here"}
      </Text>
      {subtitle ? (
        <Text font="smRegular" color="gray500" textAlign="center">
          {subtitle}
        </Text>
      ) : undefined}
      {message ? <Text textAlign="center">{message}</Text> : undefined}
      {onClickAction && actionLabel ? (
        <Button colorScheme="secondaryGray" text={actionLabel} onClick={onClickAction} />
      ) : undefined}
    </Flex>
  );
};

EmptyState.propTypes = emptyStatePropTypes;
export { EmptyState };
