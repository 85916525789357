import { Divider } from "components/layout";
import { PageTabs } from "components/pageTabs";
import DataGridRow from "components/v1/DataGrid/DataGridRow";
import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
import FarmerAvatar from "components/v1/common/FarmerAvatar";
import { getRegionOptions } from "features/v1/regions";
import { Box } from "organic";
import React from "react";
import { useSelector } from "react-redux";
import { formatCurrency, formatDate, formatRole } from "utils";
import { selectLabelFromValue } from "utils/v1/selectUtils";

const ViewClearanceDetails = ({ selected }) => {
  let regionOption = useSelector(getRegionOptions);

  const ClearanceDetails = () => (
    <>
      <DataGridRow
        fields={[
          {
            label: "No. of Fields",
            value: selected?.fieldsCount,
          },
          {
            label: "Farm Size",
            value: selected?.farm_size,
          },
          {
            label: "Group ID",
            value: selected?.groupId,
          },
          {
            label: "Crop",
            value: selected?.crop,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Equity Contribution",
            value: selected?.paidEquityContribution ? "Paid" : "Not Paid",
          },
          {
            label: "Equity Amount",
            value: formatCurrency(selected?.equityContributionAmount),
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Commitment Fee",
            value: selected?.paidEquityContribution ? "Paid" : "Not Paid",
          },
          {
            label: "Commitment Fee Amount",
            value: formatCurrency(selected?.commitmentFeeAmount),
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Cleared For Input Distribution",
            value: selected?.clearedForInputDistribution ? "Cleared" : "Not Cleared",
          },
          {
            label: "Cleared on",
            value: formatDate(selected?.created_at),
          },
        ]}
      />
    </>
  );

  const FarmerDetails = () => (
    <>
      {selected?.farmers?.length > 0 &&
        selected?.farmers.map((farmer, farmerIndex) => (
          <Box my="0.5rem" key={farmerIndex.toString()}>
            <DataGridRow
              fields={[
                {
                  label: "",
                  component: <FarmerAvatar size={64} avatar_url={farmer.avatar_url} />,
                },
                {
                  label: "Name",
                  value: `${farmer.first_name} ${farmer.last_name}`,
                },
                {
                  label: "Role",
                  value: formatRole(farmer.role),
                },
                {
                  label: "Crop",
                  value: farmer.crop_type,
                },
                {
                  label: "Field Size (ha)",
                  value: farmer.field_size,
                },
                {
                  label: "Field TA",
                  value: farmer.field_name,
                },
              ]}
            />
            <Divider />
          </Box>
        ))}
    </>
  );
  return (
    <div>
      {" "}
      <DataGridTitle title="Onboarded by" />
      <DataGridRow
        fields={[
          {
            label: "Agent",
            value: `${selected?.agent?.first_name} ${selected?.agent?.last_name}`,
          },
          {
            label: "Phone",
            value: selected?.agent?.phone_number,
          },
          {
            label: "Region",
            value: selectLabelFromValue(regionOption, selected?.agent?.region),
          },
          {
            label: "Zone",
            value: selected?.agent?.zone,
          },
        ]}
      />
      <Box mt="2rem">
        <PageTabs
          tabs={[
            {
              title: "Clearance Details",
              content: <ClearanceDetails />,
            },
            {
              title: "Farmers Details",
              content: <FarmerDetails />,
            },
          ]}
        />
      </Box>
    </div>
  );
};

export default ViewClearanceDetails;
