import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import agents from "api/agents";
import farmerImage from "assets/icons/farmer.svg";
import FormGridTitle from "components/v1/Forms/FormGridTitle";
import { editAgentDetails } from "features/v1/agents";
import { Box, Button, Flex, Text } from "organic";
import { FormInput } from "components/inputs";
import { CountrySelect, GenderSelect, LGASelect, RegionSelect, StateSelect } from "components/select";
import { ScopedAction } from "components/layout/scopedAction";
import AvatarUrl from "utils/ta-utils/avatar-url";

const EditAgentDetails = props => {
  const { selected, modal } = props;

  const [siloId, setSiloId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();

  const dispatch = useDispatch();

  const handlePasswordRequest = async (agentId, setFieldValue) => {
    if (!agentId) {
      return;
    }

    // let canProceed = authLevelChecker();
    try {
      // FIXME: use useRole isntead
      // if (canProceed) {
      setLoading(true);
      let result = await agents.get("/getSiloId", {
        params: { id: agentId },
      });

      const { siloId } = result.data.data;

      setLoading(false);
      setSiloId(siloId);
      setFieldValue("siloId", siloId);
      // } else {
      //   setLoading(false);
      //   return;
      // }
    } catch (error) {
      setLoading(false);
    }
  };

  const onSubmit = (values, formOptions) => {
    // if (authLevelChecker()) {
    dispatch(editAgentDetails(values, { formOptions, modal }));
    // } else {
    // formOptions.setSubmitting(false)
    // }
  };
  return (
    <Formik initialValues={{ ...selected, siloId: "passwordisrestricted" }} onSubmit={onSubmit}>
      {({ isSubmitting, values, errors, setFieldValue }) => (
        <Box>
          <Form>
            <Box>
              <Text mx={2} my={1}>
                {errors?.message}
              </Text>

              <Box mx="auto" textAlign="center">
                <AvatarUrl
                  avatarUrl={selected?.avatar_url ? selected.avatar_url : farmerImage}
                  size="150px"
                  borderRadius="50%"
                  view
                />
              </Box>

              <FormGridTitle title="Agent Details" />
              <Flex flexDirection="column" gap="1rem">
                <Box>
                  <FormInput name="first_name" label="First Name" defaultValue={selected.first_name} />
                </Box>
                <Box>
                  <FormInput name="last_name" label="Last Name" defaultValue={values.last_name} />
                </Box>

                <Box>
                  <FormInput name="phone_number" label="Phone Number" defaultValue={values.phone_number} />
                </Box>
                <Box>
                  <FormInput
                    name="phone_number_2"
                    label="Secondary Phone Number"
                    defaultValue={values.secondary_phone_number}
                  />
                </Box>

                <Box>
                  <FormInput name="email" label="Email" defaultValue={values.email} />
                </Box>
                <Box>
                  <GenderSelect label="Gender" value={values.gender} onChange={val => setFieldValue("gender", val)} />
                </Box>
              </Flex>

              <FormGridTitle title="Demographic Details" />
              <Flex flexDirection="column" gap="1rem">
                <Box>
                  <CountrySelect onChange={setSelectedCountry} />
                </Box>
                <Box>
                  <StateSelect
                    label="State"
                    countryName={selectedCountry}
                    value={values.state}
                    onChange={val => setFieldValue("state", val)}
                  />
                </Box>
                <Box>
                  <LGASelect
                    label="LGA"
                    selectedCountry={selectedCountry}
                    state={values.state}
                    value={values.lga}
                    onChange={val => setFieldValue("lga", val)}
                  />
                </Box>

                <Box>
                  <RegionSelect
                    colorScheme="default"
                    label="Region"
                    value={values.region}
                    onChange={val => setFieldValue("region", val)}
                  />
                </Box>
              </Flex>
              <FormGridTitle title="Other Details" />
              <Flex flexDirection="column" gap="1rem">
                <Box>
                  <FormInput
                    name="referee_phone_number"
                    label="Referee Phone Number"
                    defaultValue={values.referee_phone_number}
                  />
                </Box>

                <Box>
                  <FormInput
                    type="password"
                    id="password_hash"
                    name="siloId"
                    label="Password"
                    disabled={siloId.length < 1}
                    rightAddon={
                      <ScopedAction resource="agent">
                        <Button
                          // size="sm"
                          type="button"
                          block
                          disabled={loading}
                          onClick={() => handlePasswordRequest(selected._id, setFieldValue)}
                        >
                          Request
                        </Button>
                      </ScopedAction>
                    }
                  />
                </Box>
              </Flex>
              <Flex gap="20px" mt="2rem">
                <Button type="button" colorScheme="secondaryGray" onClick={modal.hideModal} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  Save Agent Details
                </Button>
              </Flex>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default EditAgentDetails;
