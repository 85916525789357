/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/accordion";
import { Box, Button, Flex, Text } from "organic";
import { useDispatch, useSelector } from "react-redux";
import { getRiskSurveyByTANumber, getRiskSurveyByTaNumber } from "features/v1/riskManagement";
import { format } from "date-fns";

function ViewFarmerSurvey({ selected, closeModal }) {
  const dispatch = useDispatch();
  const riskSurvey = useSelector(getRiskSurveyByTANumber);

  useEffect(() => {
    dispatch(getRiskSurveyByTaNumber(selected.ta_number));
  }, []);

  return (
    <>
      <Accordion>
        <AccordionItem value={1}>
          <AccordionTrigger>{`Survey Details`}</AccordionTrigger>
          <AccordionContent>
            <Flex flexDirection="column">
              <Box mb={5}>
                <Text font="smSemibold">TA Number</Text>
                <Text color="gray500">{riskSurvey?.ta_number || "--"}</Text>
              </Box>
              <Box mb={5}>
                <Text font="smSemibold">Date Created</Text>
                <Text color="gray500">
                  {riskSurvey?.created_at ? format(new Date(riskSurvey?.created_at), "yyyy-MM-dd hh:mm a") : "--"}
                </Text>
              </Box>
            </Flex>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value={2}>
          <AccordionTrigger>{`Survey Feedback`}</AccordionTrigger>
          <AccordionContent>
            <Flex flexDirection="column">
              {riskSurvey?.feedback ? (
                Object.entries(riskSurvey?.feedback)
                  ?.map(rs => ({ question: rs[0], answer: rs[1][0] }))
                  .map((feedback, index) => {
                    return (
                      <Box key={index} mb={5}>
                        <Text font="smSemibold">{feedback.question}</Text>
                        <Text color="gray500">{feedback.answer}</Text>
                      </Box>
                    );
                  })
              ) : (
                <Box mb={5}>
                  <Text color="gray500">No data</Text>
                </Box>
              )}
            </Flex>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Button type="button" onClick={closeModal} isDisabled={false} colorScheme="secondaryGray" display="block">
        Cancel
      </Button>
    </>
  );
}

export default ViewFarmerSurvey;
