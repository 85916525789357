import React, { useState, useMemo } from "react";
import { ConfirmDialog, RejectWithCommentDialog } from "components/confirmDialog";
import { PageHeader, PageWrapper, Panel, FormGridHeader } from "components/layout";
import { Modal, ModalContent } from "components/modal";
import { selectConstants } from "components/select";
import { useDisclosure } from "hooks";
import { useGetActualTradeById, useUpdateTrade } from "hooks/app/marketplace";
import { Badge, Button, Flex, Icon, Text, Box, Input, TextBox } from "organic";
import { useNavigate, useParams } from "react-router-dom";
import { appGet, formatDate } from "utils";
import { Formik, Form } from "formik";
import CurrencySelect from "components/select/currencySelect";
import { handleFormSubmission } from "services/formSubmissionService";
import { EmptyState } from "components/appState";
import { CropTypeField } from "./cropTypeField";
import { CostPriceField } from "../tradeForm/costPriceField";
import { AnticipatedProfitField } from "../tradeForm/anticipatedProfitField";
import { SellingPriceInput } from "../tradeForm/sellingPriceInput";
import { ProfitMarginField } from "../tradeForm/profitMarginField";
import TargetQuantityInput from "../tradeForm/targetQuantityInput";
import { CommissionFeeInput } from "../tradeForm/commissionFeeInput";
import { PriceToPayInput } from "../tradeForm/priceToPayInput";
import { TradeRevenueField } from "../tradeForm/tradeRevenueField";
import { TotalInvestmentField } from "../tradeForm/totalInvestmentField";

const ACTUAL_TRADE = "actualTrade";

const getEditTradeInitialValues = trade => {
  return {
    initiated_by: appGet(trade, "initiated_by", ""),
    crop_type: appGet(trade, "crop_type", ""),
    cost_price: appGet(trade, "cost_price", ""),
    selling_price: appGet(trade, "selling_price", ""),
    anticipated_profit: appGet(trade, "anticipated_profit", ""),
    logistics_type: appGet(trade, "logistics_type", [])?.map?.(type => {
      return {
        name: appGet(type, "name", ""),
        amount: appGet(type, "amount", ""),
        unit: appGet(type, "unit", ""),
        price_per_unit: appGet(type, "price_per_unit", ""),
        id: appGet(type, "_id", ""),
      };
    }),
    logistics_cost: appGet(trade, "logistics_cost", "0"),
    target_quantity: appGet(trade, "target_quantity", "0"),
    // assigned_agents: appGet(trade, "assigned_agents", []),
    price_to_pay: appGet(trade, "price_to_pay", "0"),
    max_farmer_payments: appGet(trade, "max_farmer_payments", "0"),
    commission_fee: appGet(trade, "commission_fee", "0"),
    // contingency_fee: appGet(trade, "contingency_fee", "0"),
    total_investment: appGet(trade, "total_investment", "0"),
    status: appGet(trade, "status", ""),
    profit_margin: appGet(trade, "profit_margin", "0"),
    commodity_average: appGet(trade, "commodity_average", 0),
    declined_comment: appGet(trade, "declined_comment", ""),
    trade_id: appGet(trade, "trade_id", ""),
    target_achieved: appGet(trade, "target_achieved", 0),
    trade_farmers: appGet(trade, "trade_farmers", 0),
    percentage_profit: appGet(trade, "percentage_profit", "0"),
    total_trade_price: appGet(trade, "total_trade_price", "0"),
    approved_by: appGet(trade, "approved_by", ""),
  };
};

const EditActualTrade = () => {
  const { tradeId } = useParams();

  const { data, isLoading, error } = useGetActualTradeById({ params: { id: tradeId } });

  const [activeTopTab, setAcitveTopTab] = useState(ACTUAL_TRADE);

  const navigate = useNavigate();

  const { mutateAsync } = useUpdateTrade();

  const confirmApproveModal = useDisclosure();
  const confirmRejectModal = useDisclosure();

  const isActiveTopTab = tabId => {
    if (activeTopTab === tabId) {
      return true;
    } else {
      return false;
    }
  };

  const createOption = item => {
    return item
      ? {
          value: item,
          label: item,
        }
      : null;
  };

  const initialValues = useMemo(() => getEditTradeInitialValues(data), [data]);

  const handleTopTabChange = (e, tabId) => {
    e.preventDefault();
    if (activeTopTab === tabId) {
      return;
    }
    setAcitveTopTab(tabId);
  };

  const handleUpdateTrade = async (values, __formOptions) => {
    //   if (!values?.decline_comment?.length) {
    //     values.declined_comment = "No Comment";
    //   }

    //   values.commodity_average = numeral(values?.total_trade_price || 0)
    //     .divide(values?.trade_farmers || 1)
    //     .value();

    //   if (values.status === "declined") {
    //     await handleFormSubmission({
    //       submitFn: mutateAsync,
    //       values: { data: { ...values, approved_by: currentUser.userId }, id: tradeId },
    //       successMsg: "trade declined succesfully",
    //       errorMsg: "Something went wrong while declining trade, please try again",
    //       onSuccess() {
    //         confirmRejectModal.onClose();
    //         navigate(-1);
    //       },
    //     });

    //     return;
    //   }

    //   if (values.status === "approved") {
    //     await handleFormSubmission({
    //       submitFn: mutateAsync,
    //       values: { data: { ...values, approved_by: currentUser.userId }, id: tradeId },
    //       config: {},
    //       successMsg: "trade approved succesfully",
    //       errorMsg: "Something went wrong while approving trade, please try again",
    //       onSuccess() {
    //         confirmApproveModal.onClose();
    //         navigate(-1);
    //       },
    //     });
    //     return;
    //   }

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: { data: { ...values }, id: tradeId },
      successMsg: "trade updated succesfully",
      errorMsg: "Something went wrong while updating trade, please try again",
      onSuccess() {
        navigate(-1);
      },
    });
  };

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > Trade" subtitle="Your trade summary and activity." />
      </Flex>
      <Panel
        bodyBg="transparent"
        headerElement={
          <TradeDetailsHeader
            onClickAction={() => {
              navigate(-1);
            }}
            name={appGet(data, "trade_id", "--")}
            isLoading={isLoading}
          />
        }
      >
        <Flex width="100%" gap="1rem" p="24px">
          <TextBox width="100%" label="Crop Type" value={data?.crop_type} />
          <TextBox width="100%" label="Last Updated" value={formatDate(data?.updated_at)} />
        </Flex>

        <div className="inner-tabs">
          <div className="inner-tabs__header">
            <Button
              variant="unstyled"
              className="inner-tabs__trigger"
              data-status={isActiveTopTab(ACTUAL_TRADE) ? "active" : "inactive"}
              onClick={e => handleTopTabChange(e, ACTUAL_TRADE)}
            >
              <Text font="smMedium" color={activeTopTab === ACTUAL_TRADE ? "gray700" : "gray500"}>
                Actual Trade
              </Text>
            </Button>
          </div>
        </div>

        <div className="inner-tabs__body">
          <Flex flexDirection="column" gap="1rem" bg="white" p="24px">
            {error || isLoading || !data ? (
              <EmptyState title="Something went wrong" subtitle="Unable to fetch trade details, Trying again..." />
            ) : (
              <Box>
                <Formik initialValues={initialValues} onSubmit={handleUpdateTrade}>
                  {({ values, setFieldValue, handleSubmit }) => {
                    // const getFieldValue = fieldName => appGet(values, fieldName);
                    return (
                      <>
                        <Form>
                          <Box>
                            <Flex flexDirection="column" width="100%" gap="1rem">
                              {/* Croptype and cost price */}
                              <Flex gap="1rem">
                                <Box width="50%">
                                  <CropTypeField />
                                </Box>
                                <Box width="50%">
                                  <CostPriceField />
                                </Box>
                              </Flex>
                              <Flex width="100%" gap="1rem">
                                <Box width="50%">
                                  <AnticipatedProfitField />
                                </Box>
                                <Box width="50%">
                                  <SellingPriceInput />
                                </Box>
                              </Flex>
                              <Flex>
                                <Box width="50%">
                                  <ProfitMarginField />
                                </Box>
                              </Flex>
                              <FormGridHeader>Logistics Type</FormGridHeader>
                              {/* Logistics Type and Logistics Cost */}
                              {values.logistics_type?.length ? (
                                <>
                                  {values.logistics_type.map((logistics, index) => (
                                    <Flex width="100%" gap="1rem" my={1} key={index}>
                                      {console.log({ logistics })}

                                      <Box width="100%" gap="1rem">
                                        <Input
                                          label={index === 0 ? "Logistics Type" : ""}
                                          required={index === 0}
                                          value={logistics.name}
                                          type="text"
                                        />
                                      </Box>
                                      <Box width="100%" gap="1rem" marginLeft={4}>
                                        <Input
                                          label={index === 0 ? "Unit" : ""}
                                          required={index === 0}
                                          value={logistics.unit}
                                          type="text"
                                        />
                                      </Box>
                                      <Box width="100%" gap="1rem">
                                        <Input
                                          label={index === 0 ? "Price" : ""}
                                          required={index === 0}
                                          value={logistics.price_per_unit}
                                          type="text"
                                        />{" "}
                                      </Box>

                                      <Box width="100%">
                                        <Input
                                          label={index === 0 ? "Amount" : ""}
                                          required={index === 0}
                                          value={logistics.amount}
                                          type="number"
                                          rightAddon={
                                            <CurrencySelect
                                              placeholder="Currency"
                                              colorScheme={selectConstants.colorSchemes.UNSTYLED}
                                              value={createOption(logistics.currency)}
                                            />
                                          }
                                        />
                                      </Box>
                                    </Flex>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <Box mx={4}>
                                    <Text font="smRegular" color="gray500">
                                      No Logistics Type
                                    </Text>
                                  </Box>
                                </>
                              )}
                              <Flex width="100%" gap="1rem">
                                <Box width="100%">
                                  <Input
                                    label="Actual Quantity Sourced (KG)  "
                                    required
                                    value={values.actual_quantity_sourced || 0}
                                    type="number"
                                  />
                                </Box>
                                <Box width="100%">
                                  <Input
                                    label="Actual Quantity Rejected (KG) "
                                    required
                                    value={values.actual_quantity_rejected || 0}
                                    type="number"
                                  />
                                </Box>
                              </Flex>
                              {/* Target quantity and assigned agent */}
                              <Flex width="100%" gap="1rem">
                                <Box width="50%">
                                  <TargetQuantityInput />
                                </Box>
                                <Box width="50%">
                                  <CommissionFeeInput />
                                </Box>
                              </Flex>
                              <Flex width="100%" gap="1rem">
                                <Box width="50%">
                                  <PriceToPayInput />
                                </Box>
                                <Box width="50%">
                                  <TradeRevenueField />
                                </Box>
                              </Flex>

                              <Box width="100%">
                                <TotalInvestmentField />
                              </Box>

                              <Flex gap="1rem" my="2rem" mx="1rem" justifyContent="flex-end">
                                <Box>
                                  <Button
                                    type="button"
                                    colorScheme="secondaryGray"
                                    onClick={() => {
                                      navigate("/marketplace/marketplaceTrade");
                                    }}
                                  >
                                    <Text color="gray700">Cancel</Text>
                                  </Button>
                                </Box>
                                <Box alignItems="start">
                                  <Button type="submit">
                                    <Text color="white" textAlign="center">
                                      Edit Trade
                                    </Text>
                                  </Button>
                                </Box>
                              </Flex>
                            </Flex>
                          </Box>
                        </Form>
                        <Modal {...confirmApproveModal.modalProps}>
                          <ModalContent variant="center" width={550}>
                            <ConfirmDialog
                              heading="Do you want to continue?"
                              subHeading={`<p>
                              Are you sure you want to confirm payout? <br />This action cannot be reversed.<br />
                             <br /><em>comment: ${
                               appGet(values, "decline_comment", false) ? values.decline_comment : "No Comment"
                             }</em>
                           </p>`}
                              confirmationText={`Confirm`}
                              modal={confirmApproveModal.modal}
                              onConfirm={() => {
                                handleSubmit();
                              }}
                            />
                          </ModalContent>
                        </Modal>
                        <Modal {...confirmRejectModal.modalProps}>
                          <ModalContent variant="center" width={550}>
                            <RejectWithCommentDialog
                              comment={appGet(values, "decline_comment", "No Comment")}
                              setComment={comment => {
                                setFieldValue("decline_comment", comment);
                              }}
                              handleSubmit={handleSubmit}
                              loading={isLoading}
                              modal={confirmRejectModal.modal}
                            />
                          </ModalContent>
                        </Modal>
                      </>
                    );
                  }}
                </Formik>
              </Box>
            )}
          </Flex>
        </div>
      </Panel>
    </PageWrapper>
  );
};

const TradeDetailsHeader = ({ onClickAction, name, isLoading }) => {
  return (
    <Flex alignItems="center" gap="1rem">
      <Button type="button" variant="unstyled" onClick={onClickAction}>
        <Icon icon="arrow-left" color="gray700" size={20} />
      </Button>
      <Text as="span" font="lgMedium" color="gray900">
        Back to Trade Reconciliation
      </Text>
      <Badge as="span" colorScheme="success">
        Editing {isLoading ? "Loading Trade Details..." : `ID ${name}`}
      </Badge>
    </Flex>
  );
};

export default EditActualTrade;
