import React from "react";
import Card from "components/card/card";
import Chart from "components/chart/chart";
import { CHART_HEADER_HEIGHT, ChartHeader } from "components/chart/chartHeader";
import { Box } from "organic";
import { uniqueId } from "utils";
import { getOverviewAverageGroupSize, getOverviewGroupsChartData } from "features/v1/overview";
import { useSelector } from "react-redux";

const AverageGroupSize = ({ cardHeight, chart, __cardWidth = "auto" }) => {
  let data = useSelector(getOverviewGroupsChartData);
  let averageGroupSize = useSelector(getOverviewAverageGroupSize);

  const { type, title, subtitle, height, width } = chart;

  const yAxisLabel = "average group size";

  return (
    <Card height={cardHeight}>
      <Box height={CHART_HEADER_HEIGHT}>
        {[
          {
            title,
            subtitle,
            value: averageGroupSize,
          },
        ].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Box>
      <Chart data={data} type={type} chart={chart} height={height} width={width} yAxisLabel={yAxisLabel} />
    </Card>
  );
};

export default AverageGroupSize;
