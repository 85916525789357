import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { Agent_API } from "services/api/agentApiService";

export function useSearchAgents({ searchText, __signal }) {
  const fetcher = async () => {
    if (!searchText?.length) {
      return [];
    }
    const data = await Agent_API.searchAgents({ params: { searchText } });
    return data;
  };

  return useQuery({
    queryKey: [rqKeys.agent.searchAgents, searchText],
    queryFn: fetcher,
  });
}
