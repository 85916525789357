import React from "react";
import { OverviewValueWrapper, resolveMarketplaceTradeStatusColor } from "../marketplaceOverviewPage";
import { Table } from "components/table";
import { SummaryGrid } from "components/grid";
import { Avatar, AvatarGroup, Badge, Flex, Text } from "organic";
import { useDataFilter, useTable } from "hooks";
import { useGetMarketplaceOverview, useGetTrades } from "hooks/app/marketplace";
import { tradeStatuses } from "../marketplaceTradePage";
import numeral from "numeral";
import { capitalize } from "utils";
import { useNavigate } from "react-router-dom";

const ClosedTrades = () => {
  const { filterParams } = useDataFilter();
  const navigate = useNavigate();

  const { data: overviewData, isLoading: isOverviewLoading } = useGetMarketplaceOverview({
    params: { dateRange: filterParams.dateRange },
  });
  const { data: tradeData } = useGetTrades({
    params: { status: tradeStatuses.CLOSED },
  });
  console.log({ tradeData });
  const { tableProps, selectedRow: selectedTrade } = useTable({
    data: tradeData?.data || [],
    columns: [
      {
        Header: "ID",
        accessor: "trade_id",
      },
      {
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Cost Price",
        accessor: trade => numeral(trade.cost_price).format("0,0.00") + trade.currency,
      },
      {
        Header: "Farmers under trade",
        accessor: "trade_farmers",
      },

      {
        Header: "Sourced Qty (KG)",
        accessor: trade => numeral(trade.target_achieved).format("0"),
      },
      {
        Header: "Profit Margin (%)",
        accessor: trade => numeral(trade.logistics_cost).format("0,0.00"),
      },
      {
        Header: "Assigned Agents",
        accessor: "assigned_agents",
        Cell: ({ value }) => {
          return (
            <AvatarGroup>
              {value?.map?.(agent => (
                <Avatar size="xs" key={agent._id} name={`${agent.first_name} ${agent.last_name}`} />
              ))}
            </AvatarGroup>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge colorScheme={resolveMarketplaceTradeStatusColor(value)}>{capitalize(value)}</Badge>,
      },
    ],
    title: "All closed trades",
    useServerPagination: true,
    serverPaginationMeta: {},
    noActions: true,
  });
  return (
    <Flex flexDirection="column">
      <SummaryGrid
        data={[
          {
            title: "Total Trade Created",
            component: (
              <OverviewValueWrapper
                data={overviewData}
                value={numeral(overviewData?.pending || 0)
                  .add(overviewData?.approved || 0)
                  .add(overviewData?.declined || 0)
                  .add(overviewData?.stocked || 0)
                  // .add(overviewData?.delivered || 0)
                  .add(overviewData?.invoiceStatusCount || 0)
                  .add(overviewData?.finalized || 0)
                  .add(overviewData?.closed || 0)
                  .format("0")}
                isLoading={isOverviewLoading}
              />
            ),
          },
          {
            title: "Total Approved",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.approved} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Declined",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.declined} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Stocked",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.stocked} isLoading={isOverviewLoading} />
            ),
          },
          // {
          //   title: "Total Delivered",
          //   component: (
          //     <OverviewValueWrapper data={overviewData} value={overviewData?.delivered} isLoading={isOverviewLoading} />
          //   ),
          // },
          {
            title: "Total Finalised",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.finalized} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Closed",
            component: (
              <OverviewValueWrapper
                isActive
                data={overviewData}
                value={overviewData?.closed}
                isLoading={isOverviewLoading}
              />
            ),
          },
        ]}
      />
      <Table
        {...tableProps}
        onEditAction={() => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=edit`);
        }}
        onViewAction={() => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=view`);
        }}
      />
    </Flex>
  );
};

export default ClosedTrades;
