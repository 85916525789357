/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import * as yup from "yup";
import { FormInput } from "components/inputs";
import { Form, Formik } from "formik";
import { Box, Flex, Text } from "organic";
import { generatePassword } from "utils/export-utils/export-utils";
import axios from "axios";
import { getApiUrl } from "utils";
import { apiConstants } from "constants/apiConstants";
import { axiosService } from "services/fetchService";

// Define a function to check if the phone number exists in the API
export const checkPhoneNumberExists = async phoneNumber => {
  try {
    const url = getApiUrl({ endpointName: apiConstants.endpoints.agent, path: "/searchContractual" });
    const { data } = await axiosService({ url, method: "GET", params: { phone_number: phoneNumber } });

    return data?.message === "user exist"; // Assuming the API returns a boolean indicating if the phone number exists
  } catch (error) {
    // Handle errors if necessary
    console.error("Error checking phone number:", error);
    throw error;
  }
};

const LoginDetailsForm = ({ setIsSectionValid, loginDetails, setLoginDetails }) => {
  const loginDetailsValidationSchema = yup.object().shape({
    phone_number: yup
      .string()
      .matches(/^[0-9]+$/, "Must be a number")
      .min(11, "Phone number must be at least 11 digits")
      // .test("checkPhoneNumber", "Phone number already exists", async function (value) {
      //   try {
      //     // Use Axios to call the API
      //     const exists = await checkPhoneNumberExists(value);
      //     return !exists; // Return true if phone number doesn't exist
      //   } catch (error) {
      //     // Handle errors if necessary
      //     console.error("Error checking phone number:", error);
      //     return false; // Consider the validation failed if an error occurs
      //   }
      // })
      .required("Phone number is required"),
    password: yup.string().required("Password is required"),
  });

  // useEffect(() => {
  //   setLoginDetails({
  //     phone_number: "08063058990",
  //     password: "A123456789",
  //   });
  // }, []);

  return (
    <Formik
      initialValues={loginDetails}
      validationSchema={loginDetailsValidationSchema}
      onSubmit={(values, formikOptions) => {}}
      validateOnMount
      enableReinitialize
    >
      {({ values, setFieldValue, isSubmitting, isValid, dirty, handleChange }) => {
        useEffect(() => {
          setIsSectionValid(isValid);
        }, [isValid]);

        useEffect(() => {
          setLoginDetails(values);
        }, [values]);

        return (
          <>
            <Form autoComplete="off">
              <Flex flexDirection="column" gap="1rem" mx={1} my={1}>
                <Box>
                  <FormInput
                    label="Phone Number"
                    name="phone_number"
                    placeholder="07063058990"
                    // value={values.phone_number}
                    // onChange={handleChange}
                    required
                  />
                </Box>
                <Flex flexDirection="column">
                  <Box mb={5}>
                    <FormInput
                      name="password"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      // value={values.password}
                      required
                      // onChange={handleChange}
                      showCopy
                    />
                  </Box>
                  <Box
                    onClick={() => {
                      setFieldValue("password", generatePassword());
                    }}
                    background="#ECFFE5"
                    borderRadius="1px"
                    py="10px"
                    cursor="pointer"
                    width="156px"
                  >
                    <Text textAlign="center" font="smSemibold" color="primary700">
                      Generate Password
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default LoginDetailsForm;
