import { Select } from "components/select";
import { getLGAOptionsFromStateAndCountryName } from "components/select/options/stateSelectOptions";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { selectOptionFromValue } from "utils/v1/selectUtils";

const LGASelect = ({ onChange, state, countryName, defaultValue, name, label, required }) => {
  const [selectedStateLGAs, setSelectedStateLGAs] = useState();
  const [selectedLGA, setSelectedLGA] = useState();

  const _handleChange = selectedValue => {
    setSelectedLGA(selectOptionFromValue(selectedStateLGAs, selectedValue));
    if (_.isFunction(onChange)) onChange(selectedValue);
  };

  useEffect(() => {
    if (defaultValue) setSelectedLGA({ label: defaultValue, value: defaultValue });
  }, []);

  useEffect(() => {
    if (state) {
      let newOptions = getLGAOptionsFromStateAndCountryName(state, countryName);
      if (newOptions) setSelectedStateLGAs(newOptions);
    }
  }, [countryName, state]);

  return (
    <Select
      name={name}
      options={selectedStateLGAs || []}
      placeholder="Select LGA"
      value={selectedLGA}
      onChange={_handleChange}
      label={label}
      required={required}
    />
  );
};

export { LGASelect };
