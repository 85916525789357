import { FormInput } from "components/inputs";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import { useFormikContext } from "formik";
import { useDidMountEffect } from "hooks";
import numeral from "numeral";
import React from "react";

const calcTradeProfitMargin = ({ anticipatedProfit, sellingPrice, targetQuantity }) => {
  try {
    const anticipated_profit = numeral(anticipatedProfit || 0).value();
    const trade_revenue = numeral(sellingPrice || 0)
      .multiply(targetQuantity || 0)
      .value();

    const profit_margin = numeral(anticipated_profit || 0)
      .divide(trade_revenue || 1)
      .multiply(100);
    if (Number.isNaN(profit_margin)) {
      return 0.0;
    }
    return profit_margin.value().toFixed(2);
  } catch (error) {
    console.log({ error });
    return 0.0;
  }
};

export const ProfitMarginField = () => {
  const { values, setFieldValue } = useFormikContext();

  useDidMountEffect(() => {
    setFieldValue(
      "profit_margin",
      calcTradeProfitMargin({
        anticipatedProfit: values.anticipated_profit,
        sellingPrice: values.selling_price,
        targetQuantity: values.target_quantity,
      })
    );
  }, [values.anticipated_profit, values.selling_price, values.target_quantity]);
  return (
    <FormInput
      label="Profit Margin (%)"
      required
      hint="Anticipated profit ÷ trade revenue × 100"
      min={0}
      max={MAX_NUM_VALUE}
      step="0.01"
      name="profit_margin"
      type="number"
      disabled
      readOnly
    />
  );
};
