import * as Sentry from "@sentry/react";
import admin from "api/admin";
import authService, { authChecker } from "../../services/v1/authService";
import history from "../../history";
import { toast } from "react-toastify";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
import { setUserScope } from "services/v1/rolesAndPermissionsService";
import { getCurrentOrganization } from "./organization";

// actions
const FETCH_USER_DATA = "FETCH_USER_DATA";
const LOAD_USER = "LOAD_USER";
const GET_ALL_ADMINS = "GET_ALL_ADMINS";
const GET_SUB_ADMINS = "GET_SUB_ADMINS";
const LOAD_ADMINS_DATA = "LOAD_ADMINS_DATA";
const SIGN_OUT = "SIGN_OUT";
// const CREATE_ADMIN = "CREATE_ADMIN";
const LOADING_AUTH = "LOADING_AUTH";

//action creators
export const login =
  (data, { setErrors, setSubmitting }) =>
  async dispatch => {
    try {
      const res = await admin.post("login", data);
      const _loginData = res.data.data || res.data;
      if (_loginData?.sessionAuth) {
        const { accessToken, refreshToken, scope } = res.data.data.sessionAuth;
        authService.setToken(accessToken);
        authService.setRefresh(refreshToken);
        setUserScope(scope);
      }
      if (_loginData?.organization) {
        authService.setOrg(_loginData.organization);
      }

      if (_loginData?.token) {
        authService.setToken(_loginData.token);
      }

      dispatch({
        type: FETCH_USER_DATA,
        payload: _loginData,
      });
      toast.success(res?.data?.message || res?.data?.data?.message);
      history.push("/dashboard/");
      return {
        setSubmitting,
        setErrors,
      };
    } catch (loginError) {
      Sentry.captureException(loginError);
      console.log({ loginError });
      setErrors({
        message:
          loginError?.response?.data?.message ||
          loginError?.response?.data?.error ||
          loginError?.response?.data ||
          loginError.message ||
          "Could not login, kindly try again",
      });
      setSubmitting(false);
    }
  };

export const fetchUserData = () => async dispatch => {
  try {
    dispatch({ type: LOAD_USER, payload: true });
    if (!authChecker()) {
      authService.signOut();
    }
    let res = await admin.get("/find_one");
    // authService.setOrg(res.data.data.organization);
    dispatch({ type: FETCH_USER_DATA, payload: res.data.data });
    dispatch({ type: LOAD_USER, payload: false });
    return dispatch(getCurrentOrganization());
  } catch (fetchUserDataErr) {
    Sentry.captureException(fetchUserDataErr);
    return dispatch({ type: LOAD_USER, payload: false });
  }
};

export const getAllAdmins = () => dispatch => {
  try {
    if (authChecker()) {
      dispatch({ type: LOAD_ADMINS_DATA, payload: true });
      // console.log("params: ",params)
      admin
        .get("all")
        .then(adminsData => {
          dispatch({
            type: GET_ALL_ADMINS,
            payload: adminsData.data.data,
          });

          dispatch({ type: LOAD_ADMINS_DATA, payload: false });
        })
        .catch(err => {
          if (err.response === undefined) {
            return toast.error(err.message || "Something went wrong fetching data, try refreshing the page");
          }
          if (err.response.status === 401) {
            return toast.error(err.response.data.message);
          }
          toast.error("Something went wrong fetching Members, try refreshing the page");
          dispatch({ type: LOAD_ADMINS_DATA, payload: false });
        });
    }
  } catch (getAllAdminsError) {
    dispatch({ type: LOAD_ADMINS_DATA, payload: false });
    Sentry.captureException(getAllAdminsError);
  }
};

export const getAllSubAdmins = () => dispatch => {
  try {
    if (authChecker()) {
      dispatch({ type: LOAD_ADMINS_DATA, payload: true });
      // console.log("params: ",params)
      admin
        .get("all")
        .then(adminsData => {
          dispatch({
            type: GET_SUB_ADMINS,
            payload: adminsData.data.data,
          });

          dispatch({ type: LOAD_ADMINS_DATA, payload: false });
        })
        .catch(err => {
          if (err.response === undefined) {
            return toast.error(err.message || "Something went wrong fetching data, try refreshing the page");
          }
          if (err.response.status === 401) {
            return toast.error(err.response.data.message);
          }
          toast.error("Something went wrong fetching Members, try refreshing the page");
          dispatch({ type: LOAD_ADMINS_DATA, payload: false });
        });
    }
  } catch (getAllSubAdminsError) {
    dispatch({ type: LOAD_ADMINS_DATA, payload: false });
    console.log({ getAllSubAdminsError });
  }
};

export const editAdminDetails = (values, options) => async dispatch => {
  let hasFormikOptions = options?.formikOptions;
  let hasModal = options?.modal;
  let hasRefresh = options?.refreshData;
  try {
    let params = {
      id: values._id,
    };

    let sanitizedValues = sanitizeMongoDoc(values, [
      "password_hash",
      "siloId",
      "avatar_url",
      "area",
      "areas",
      "email",
      "password",
      "season",
      "zones",
    ]);

    let res = await admin.put("updateOtherAdmin", sanitizedValues, { params });
    if (res.status === 200) {
      toast.success("Admin Details updated successfully!");
      if (hasModal) options.modal.hideModal();
      if (hasRefresh) dispatch(options.refreshData());
    }
    if (res.status === 401) {
      toast.error("You are not authorized to do that!");
    }
  } catch (err) {
    if (err?.isAxiosError) {
      toast.error(err.response.data.message);
      if (hasFormikOptions)
        options.formikOptions.setErrors({
          message: err.response.data.message,
        });
    } else {
      toast.error(err?.message || "admin update failed, please contact support, or try again");
      if (hasFormikOptions)
        options.formikOptions.setErrors({
          message: err?.message,
        });
    }
    if (hasFormikOptions) options.formikOptions.setSubmitting(false);
  }
};

export const logout = () => dispatch => {
  authService.signOut();
  dispatch({ type: SIGN_OUT });
};

export const submitAdmincredentials =
  (values, { setSubmitting, setErrors }) =>
  async dispatch => {
    let user = authService.getCurrentUser();
    // FIXME: use useRole instead
    // let authorized = authLevelChecker();
    // if (authorized) {
    try {
      let res = await admin.post("/createSubadmin", values, user);
      if (res.status === 200) {
        toast.success(res.data.message);
      }

      if (res.status === 400) {
        toast.error(res?.data?.message || "There is an error in creating subadmin, please contact support");
      }
      if (res.status === 500) {
        toast.error(res?.data?.message || "There is an error in creating subadmin, please contact support");
      }
      setSubmitting(false);
      return dispatch(getAllAdmins());
    } catch (err) {
      if (err?.isAxiosError) {
        toast.error(err.response.data.message);
        setErrors({
          message: err.response.data.message,
        });
      } else {
        toast.error(err?.message || "Create Admin Failed, please contact support, or try again");
      }
      setSubmitting(false);
      return {
        setSubmitting,
        setErrors,
      };
    }
  };

export const updateUser =
  (values, config = {}) =>
  async dispatch => {
    try {
      dispatch({ type: LOADING_AUTH, payload: true }); //admin/update
      const response = await admin.put("update", values);
      config.onSuccess();
      console.log(response);
      dispatch({ type: LOADING_AUTH, payload: false });
      return dispatch(fetchUserData());
    } catch (updateUserError) {
      dispatch({ type: LOADING_AUTH, payload: false });
      console.log({ updateUserError });
    }
  };
export const updateUserAvatar =
  (file, __config = {}) =>
  async dispatch => {
    try {
      dispatch({ type: LOADING_AUTH, payload: true });
      // create form data
      const data = new FormData();
      // you can just set the file directly
      data.append("images", file);
      await admin.post("/uploadFarmerProfileImage", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Successfully Uploaded.");
      dispatch({ type: LOADING_AUTH, payload: false });
      return dispatch(fetchUserData());
    } catch (updateUserError) {
      dispatch({ type: LOADING_AUTH, payload: false });
      console.log({ updateUserError });
    }
  };
// };

//reducer

const initialState = {
  userData: {},
  loadingUser: false,
  errorMessage: "",
  adminsData: [],
  loadingAdmins: false,
  subAdminsData: [],
  storeManagers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADMINS: {
      const subAdminsData = action.payload.filter(e => e.role != "superAdmin");
      const _storeManagers = action.payload.filter(e => e.role === "storemanager" || e.role === "storeManager");
      return {
        ...state,
        adminsData: action.payload,
        subAdminsData: subAdminsData,
        storeManagers: _storeManagers,
      };
    }
    case LOAD_ADMINS_DATA:
      return { ...state, loadingAdmins: action.payload };
    case LOAD_USER:
      return { ...state, loadingUser: action.payload };
    case GET_SUB_ADMINS:
      return { ...state, subAdminsData: action.payload };
    case FETCH_USER_DATA: {
      return { ...state, userData: { ...action.payload } };
    }
    case SIGN_OUT:
      return { ...state, userData: { ...state.userData, token: null } };
    default:
      return state;
  }
};

// selector functions

export const getUserData = state => state?.legacyAuth?.userData;
export const getLoadingUser = state => state?.legacyAuth?.loadingUser;
export const getLoadingAdmins = state => state?.legacyAuth?.loadingAdmins;
export const getUserRole = state => state?.legacyAuth?.userData?.role;
export const getUserRegion = state => state?.legacyAuth?.userData?.region;
export const getUserZone = state => state?.legacyAuth?.userData?.zone;
export const getStoreManagers = state => state?.legacyAuth?.storeManagers;
export const getSubAdmins = state => state?.legacyAuth?.subAdminsData;

// utils
