import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Slide, Bounce, ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
// import { SingletonHooksContainer } from "react-singleton-hook";
import { ThemeProvider } from "styled-components/macro";

import GlobalStyleUtils from "organic/global";
import { organicTheme } from "organic";

import store from "./store";
// import toolkitStore from "./toolkitStore";
import IndexRoutes from "./routes/index";
import CustomRouter from "components/v1/customRouter/CustomRouter";
import history from "./history";
import ProgressLoader from "components/v1/Loader/ProgressLoader";

// CSS and SASS
// import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import "react-date-range/dist/styles.css"; // main daterange style file
import "react-date-range/dist/theme/default.css"; // daterange theme css file
import "assets/scss/thrive.scss";
import "assets/css/normalize.css";
import "assets/css/fonts.css";
import "assets/css/index.css";
import "assets/css/components/card/barMetricCard.css";
import "assets/css/components/card/lineMetricCard.css";
import "assets/css/components/card/longBarMetricCard.css";
import "assets/css/components/card/metricCard.css";
import "assets/css/components/card/pieMetricCard.css";
import "assets/css/views/pages/insightAnalytics/insightAnalyticsCard.css";
import "assets/css/views/pages/insightAnalytics/customizeDashboard.css";
import { SingletonHooksContainer } from "react-singleton-hook";
import { ErrorState } from "components/appState";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LoadingProvider } from "context";

export const ONLINE_OFFLINE_TOAST_CONTAINER = "onlineOfflineToastContainer";
export const INFO_TOAST_CONTAINER = "infoToastContainer";
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
// const SENTRY_PORT = process.env.REACT_APP_SENTRY_PORT;
const SENTRY_TRACE_SAMPLE_RATE = process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE;
const APP_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENV || "";
// const domainName = location.hostname;
// const protocol = location?.protocol || "https"
// const SENTRY_PROTOCOL = process.env.REACT_APP_SENTRY_PROTOCOL === "secure" ? "https" : "http";

export const appQueryClient = new QueryClient();

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/http:\/\/localhost:$/, /\.aosafricatest\.com$/, /\.aosafrica\.com$/],
    }),
    new Sentry.Replay(),
  ],
  normalizeDepth: 10, // for reduxt context
  // tunnel: `${SENTRY_PROTOCOL}://${domainName}:${SENTRY_PORT}/tunnel`,
  // Performance Monitoring
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <QueryClientProvider client={appQueryClient}>
    <Provider store={store}>
      {/* <Provider store={toolkitStore}> */}
      <ErrorState>
        <ThemeProvider theme={organicTheme}>
          <LoadingProvider>
            <GlobalStyleUtils />

            <CustomRouter history={history}>
              <IndexRoutes />
            </CustomRouter>
          </LoadingProvider>
        </ThemeProvider>
        <SingletonHooksContainer />
      </ErrorState>
      <ToastContainer
        position="top-center"
        hideProgressBar
        closeOnClick
        draggable
        enableMultiContainer
        pauseOnHover
        transition={Slide}
      />
      <ToastContainer
        position="bottom-left"
        closeOnClick
        hideProgressBar
        draggable
        pauseOnHover
        enableMultiContainer
        containerId={ONLINE_OFFLINE_TOAST_CONTAINER}
        transition={Bounce}
      />
      <ToastContainer
        position="bottom-left"
        closeOnClick
        hideProgressBar
        draggable
        pauseOnHover
        theme="colored"
        enableMultiContainer
        containerId={INFO_TOAST_CONTAINER}
        transition={Bounce}
      />
      <ProgressLoader />
      {/* </Provider>, */}
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
);
