import { Table } from "components/table";
import { Badge } from "organic";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import farmersApi from "api/farmers";
import { handleActionError } from "services/shared/AOSErrorService";
import { validateUUID } from "utils";
import { transferFarmerToAgent } from "features/v1/farmers";
import SearchAgents from "../farm/onboarding/searchAgents";
import { useTable } from "hooks";

const ViewAgentsFarmers = ({ selected }) => {
  const [farmersOnboarded, setFarmersOnboarded] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { tableProps } = useTable({
    data: farmersOnboarded,
    columns: [
      {
        Header: "Farmer ID",
        accessor: "ta_number",
      },
      {
        id: "farmerName",
        Header: "Name",
        accessor: farmer => `${farmer?.first_name || "N/A"} ${farmer?.last_name || "N/A"}`,
      },
      {
        Header: "Group ID",
        accessor: "group_id",
      },

      {
        id: "farmerSerialId",
        Header: "Serial ID",
        accessor(farmer) {
          return validateUUID(farmer?.farmer_id) ? "Not Yet Assigned" : farmer?.farmer_id ? farmer.farmer_id : "No ID";
        },
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value }) => (
          <Badge pill colorScheme={value === "lead_farmer" ? "primary" : "gray"}>
            {value || "N/A"}
          </Badge>
        ),
      },
      {
        Header: "Age",
        accessor: "age",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "LGA",
        accessor: "lga",
      },
    ],
    title: loading ? "Loading..." : `Farmers Onborded by ${selected.first_name} ${selected.last_name}`,
    noDateRangeFilter: true,
    noLocalFilter: true,
    noSearch: true,
    showCheckboxes: true,
    noActions: true,
  });

  const ignoredhandleTransferSingleFarmerToAgent = (farmer, agent) => {
    dispatch(
      transferFarmerToAgent([farmer.group_id], {
        agentTo: agent._id,
        agent,
        agentFrom: farmer.agent,
        onSuccess: getFarmersByAgentBySeason,
      })
    );
  };

  const handleTransferMultipleFarmerToAgent = (farmers, agent) => {
    const farmersGroupIds = [...new Set(farmers.map(farmer => farmer.group_id))];

    console.log({ farmers, agent, farmersGroupIds });
    dispatch(
      transferFarmerToAgent(farmersGroupIds, {
        agentTo: agent._id,
        agent,
        agentFrom: selected._id,
        onSuccess: getFarmersByAgentBySeason,
      })
    );
  };

  const getExtraActions = farmer => {
    console.log({ farmer });
    return [
      {
        label: "Move To",
        icon: "corner-down-right",
        actionKey: "moveFarmer",
        component: <SearchAgents onChange={agent => handleTransferMultipleFarmerToAgent(farmer, agent)} />,
      },
    ];
  };

  const getFarmersByAgentBySeason = useCallback(async () => {
    try {
      setLoading(true);
      const response = await farmersApi.get("allFarmersByAgentAndSeason", {
        params: { seasonId: tableProps.filterParams.seasonId, agentId: selected._id },
        ignoreOrgId: true,
      });
      setFarmersOnboarded(response.data.data);
      setLoading(false);
    } catch (getFarmersByAgentBySeasonErr) {
      setLoading(false);
      handleActionError(getFarmersByAgentBySeasonErr);
    }
  }, []);

  useEffect(() => {
    setFarmersOnboarded([]);
    getFarmersByAgentBySeason();
  }, []);

  return (
    <div>
      <Table {...tableProps} getMultiSelectActions={getExtraActions} />
    </div>
  );
};

export default ViewAgentsFarmers;
