import React from "react";
import { StyledTextBox, Label } from "./styledTextBox";
import Flex from "../layout/flexbox";
// import Icon from "../icon/icon";

// Functional component for the text box with label
export const TextBox = ({ label, value, ...restProps }) => {
  return (
    <Flex gap="11px" flexDirection="column" {...restProps}>
      <Label>{label}</Label>
      <StyledTextBox>
        {value}
        {/* <Icon icon={textBoxIcon} /> */}
      </StyledTextBox>
    </Flex>
  );
};
