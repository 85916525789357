// import { yupToFormErrors } from "formik";
import { getCurrentUser } from "services/v1/authService";

// Commented descriptions are from api mongo schema
export const initiateCreateFieldValues = selectedFarmer => {
  return {
    // the Agent id (that logs this visit)
    agent: selectedFarmer?.agent?._id || "",
    // the farmers id (owner of the supervised farmland)
    farmer: selectedFarmer?._id,
    // the farmers id (owner of the supervised farmland)
    season: "",
    // the lead farmers id
    //TA-000000001-01-01
    groupLead: selectedFarmer?.groupLead || "",
    // measurement in hectares max: 3
    farm_size: null,
    ta_number: selectedFarmer?.ta_number || "",
    fieldId: "",
    // Suggests the crop type that will be planted on the field
    crop_type: "",
    // Suggests if the land is fertile
    field_fertile: "",
    // Suggest the last time that farmer added manure the land
    added_manure: null,
    // Suggest if there are tree on the farm land
    tree_per_hectare: "",
    // Suggest the number of trees on the farm land
    no_of_tree: null,
    // Suggest the number of Stump on the farm land
    stump_per_hectare: "",
    // Suggest the number of stump on a farm land
    no_of_stump: null,
    // suggest the number of stones on the farm land
    stony_per_hectare: "",
    //  suggest if land is prone to erosion
    erosion_per_hectare: "",
    // Land prone to water logging if it is a maize farm or flooding if it is a rice farm
    farm_waterlogging_flooding: "",
    //  farm close to a big river
    farm_close_river: "",
    //  HAs the farm been rotated with cereal crop in the last three years
    non_cereal_crop: "",
    //default value should be farm_size value
    adjusted_farm_size: null,
    // data from google map
    mappedFarm: {},
    // suggest if termite can be found on either the maize or soybean farm
    farm_termite: "",
    // Suggest if there are cricket on the farm land
    farm_cricket: "",
    // suggest that Rice farm is prone to big rat,
    farm_bigRat: "",
    presence_of_striga: "",
    coordinate: {
      // The latitude for farmer
      latitude: "",
      // The longtitude for farmer
      longtitude: "",
      accuracy: null,
    },
    status: "active",
    //the date the data was onboarded(on or offline)
    onboarded_at: new Date().toISOString(),
    created_by: getCurrentUser().userId,
  };
};

export const initiateEditFarmerDetails = farmer => {
  return {
    last_name: farmer?.last_name || "",
    first_name: farmer?.first_name || "",
    middle_name: farmer?.middle_name || "N/A",
    age: farmer?.age,
    gender: farmer?.gender || "",
    status: farmer?.status || "active",
    state: farmer?.state || "",
    lga: farmer?.lga || "",
    village: farmer?.village || "",
    district: farmer?.district || "",
    phone_number: farmer?.phone_number || "",
    phone_number_owner: farmer?.phone_number_owner || "",
    role: farmer?.role || "",
  };
};
