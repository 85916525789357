import React from "react";
import PaymentsTable from "./paymentsTable";

const PaymentsContent = ({ tableProps, selectedPayment, selectedCropType, activeTab, page }) => {
  return (
    <PaymentsTable
      tableProps={tableProps}
      selectedPayment={selectedPayment}
      selectedCropType={selectedCropType}
      activeTab={activeTab}
      page={page}
    />
  );
};

export default PaymentsContent;
