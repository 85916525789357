import React from "react";
import { Col, Row } from "reactstrap";
import FarmerAvatar from "components/v1/common/FarmerAvatar";
import DataGridRow from "components/v1/DataGrid/DataGridRow";
import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
import CustomModal from "components/v1/Modal/CustomModal";
import { getGroupIdFromTA } from "utils/v1/TAUtils";

const ViewFarmerForClearance = props => {
  const { modal, selected } = props;
  return (
    <CustomModal modal={modal}>
      <Row className="mb-4">
        <Col className="d-flex justify-content-center">
          <FarmerAvatar avatar_url={selected?.avatar_url} />
        </Col>
      </Row>
      <Col className="mt-4 mb-2 ml-3 form-header-color tab-font-size ca-font-medium">User Details</Col>
      <DataGridRow
        fields={[
          {
            label: "Group ID",
            value: getGroupIdFromTA(selected?.ta_number),
          },
          {
            label: "User ID",
            value: selected?.ta_number,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "First Name",
            value: selected?.first_name,
          },
          {
            label: "Last Name",
            value: selected?.last_name,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Middle Name",
            value: selected?.middle_name,
          },
          {
            label: "Phone Number",
            value: selected?.phone_number,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Phone Number Owner",
            value: selected?.phone_number_owner,
          },
          {
            label: "Gender",
            value: selected?.gender,
          },
        ]}
      />

      <DataGridTitle title="Demographic Details" />
      <DataGridRow
        fields={[
          {
            label: "Age",
            value: selected?.age,
          },
          {
            label: "State",
            value: selected?.state,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "LGA",
            value: selected?.lga,
          },
          {
            label: "Village",
            value: selected?.village,
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "District",
            value: selected?.district,
          },
          {},
        ]}
      />
      <DataGridTitle title="Bank Detials" />
      <DataGridRow
        fields={[
          {
            label: "Bank Name",
            value: selected?.bank_name ? selected.bank_name : "Not Updated",
          },
          {
            label: "Bank Name",
            value: selected?.bank_account_name ? selected.bank_account_name : "Not Updated",
          },
        ]}
      />
      <DataGridRow
        fields={[
          {
            label: "Account Number",
            value: selected?.bank_account_number ? selected?.bank_account_number : "Not Updated",
          },
          {
            label: "BVN",
            value: selected?.bvn ? selected.bvn : "Not Updated",
          },
        ]}
      />
    </CustomModal>
  );
};

export default ViewFarmerForClearance;
