import React from "react";
import Card from "components/card/card";
import Chart from "components/chart/chart";
import { CHART_HEADER_HEIGHT, ChartHeader } from "components/chart/chartHeader";
import { Box } from "organic";
import { uniqueId } from "utils";
import { getMappingSizeChartData } from "features/v1/farmMapping";
import { useSelector } from "react-redux";
import { getOverviewAverageFieldSize } from "features/v1/overview";

const AverageFieldSize = ({ cardHeight, chart, __cardWidth = "auto" }) => {
  let data = useSelector(getMappingSizeChartData);
  let averageFiledSize = useSelector(getOverviewAverageFieldSize);

  const { type, title, subtitle, height, width } = chart;

  const yAxisLabel = "average field size";

  return (
    <Card height={cardHeight}>
      <Box height={CHART_HEADER_HEIGHT}>
        {[
          {
            title,
            subtitle,
            value: averageFiledSize,
          },
        ].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Box>
      <Chart data={data} type={type} height={height} width={width} yAxisLabel={yAxisLabel} />
    </Card>
  );
};

export default AverageFieldSize;
