import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Badge, Box, Button, Flex, Icon, Text, badgeConstants, organicTheme } from "organic";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover";
import {
  getActiveRiskAgent,
  getAgentFarmers,
  getLoadingAgentFarmers,
  getLoadingSurvey,
  getSelectedAgentFarmers,
  resetAgentFarmersList,
  resetSelectedAgentFarmers,
  searchForAgentFarmers,
  setActiveRow,
  setSelectedAgentFarmers,
  updateRiskAgentData,
} from "features/v1/riskManagement";
import { useDispatch, useSelector } from "react-redux";
import { truncateText } from "utils";

function getInitials(fullName) {
  // Split the full name into an array of first and last names
  const names = fullName.split(" ");

  // Get the first character of each name and convert to uppercase
  const firstInitial = names[0] ? names[0].charAt(0).toUpperCase() : "";
  const lastInitial = names[1] ? names[1].charAt(0).toUpperCase() : "";

  // Concatenate the initials
  const initials = firstInitial + lastInitial;

  return initials;
}

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 16px 16px 16px 42px;
  font-size: 16px;
  flex: 1;
`;

const SearchButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const AssignAgentsPopover = ({ id }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  const activeRiskAgent = useSelector(getActiveRiskAgent);
  const agentFarmers = useSelector(getAgentFarmers);
  const selected = useSelector(getSelectedAgentFarmers);
  const loading = useSelector(getLoadingSurvey);
  const loadingAgentFarmers = useSelector(getLoadingAgentFarmers);
  const [agentFarmersState, setAgentFarmersState] = useState([]);

  useEffect(() => {
    setAgentFarmersState(
      agentFarmers.map(ag => {
        if (selected.find(s => `${s.id}` === `${ag.id}`)) {
          return {
            agentFarmer: ag,
            checked: true,
          };
        }
        return {
          agentFarmer: ag,
          checked: false,
        };
      })
    );
  }, [agentFarmers, selected]);

  useEffect(() => {
    if (activeRiskAgent === id) {
      setOpenPopover(true);
    } else {
      setOpenPopover(false);
    }
  }, [activeRiskAgent]);

  const handleSearchAgents = () => {
    dispatch(setActiveRow(id));
    if (searchText.length < 4) return;
    dispatch(searchForAgentFarmers(searchText)).then(() => setSearchText(""));
  };

  const handleChange = id => {
    const prevState = [...agentFarmersState];
    const current = prevState.find(agF => agF.agentFarmer.id == id);
    current.checked = !current.checked;
    setAgentFarmersState(prevState);
    let currentSelection = [...selected];
    if (currentSelection.find(s => s.id === current.agentFarmer.id)) {
      currentSelection = currentSelection.filter(s => s.id !== current.agentFarmer.id);
    } else {
      currentSelection.push(current.agentFarmer);
    }
    dispatch(setSelectedAgentFarmers(currentSelection));
  };

  const handleAssignFarmers = () => {
    dispatch(
      updateRiskAgentData(
        id,
        selected.map(s => s.id)
      )
    ).then(() => {
      dispatch(resetSelectedAgentFarmers());
      dispatch(resetAgentFarmersList());
      dispatch(setActiveRow(null));
    });
  };

  return (
    <Popover
      key={id}
      open={openPopover}
      onOpenChange={open => {
        setOpenPopover(open);
        if (!open) {
          dispatch(resetSelectedAgentFarmers());
          dispatch(resetAgentFarmersList());
        }
      }}
    >
      <PopoverTrigger>
        <Button variant="unstyled">
          <Icon icon="more-vertical" color={organicTheme.colors.gray900} size={20} />
        </Button>
      </PopoverTrigger>
      <PopoverContent alignOffset={10} align="end">
        <Box width="377px" backgroundColor="white">
          <InputContainer>
            <SearchIconContainer>
              <Icon icon="search" color={organicTheme.colors.gray500} size={20} />
            </SearchIconContainer>
            <Input value={searchText} placeholder="Search" onChange={e => setSearchText(e.target.value)} />

            <SearchButtonContainer>
              <Button
                onClick={handleSearchAgents}
                display="block"
                type="button"
                isDisabled={searchText.length < 4 || loadingAgentFarmers}
              >
                {loadingAgentFarmers ? "Searching..." : "Search"}
              </Button>
            </SearchButtonContainer>
          </InputContainer>
          {searchText.length < 4 && (
            <Text ml="16px" as="i" color="red" font="xsMedium" textAlign="center">
              Enter at least 4 characters to search
            </Text>
          )}
          <Box p="16px" backgroundColor="white">
            <Text color="gray600" mb="20px" font="smMedium">
              Agents
            </Text>
            {agentFarmersState?.length ? (
              agentFarmersState.map((agent, i) => {
                return (
                  <Flex key={i} gap="8px" flexDirection="column">
                    <Flex as="label" gap="20px" alignItems="center">
                      <Flex gap="20px" alignItems="center">
                        <Box backgroundColor="gray100" borderRadius="100%" width="24px" height="24px">
                          <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                            <Text font="smMedium">
                              {agent.agentFarmer?.name ? getInitials(agent.agentFarmer?.name) : "--"}
                            </Text>
                          </Flex>
                        </Box>
                        <Text font="smMedium" whiteSpace="no-wrap">
                          {agent.agentFarmer?.name ? truncateText(agent.agentFarmer?.name, 15) : "--"}
                        </Text>
                        <Text font="smMedium">{agent.agentFarmer?.ta_number || "no TA number"}</Text>
                        <Box backgroundColor="gray100" borderRadius="4px" px="4px" py="2px">
                          <Text color="gray600" font="smRegular">
                            {agent.agentFarmer?.noOfFarmer || "--"}
                          </Text>
                        </Box>
                      </Flex>
                      <input
                        type="checkbox"
                        checked={agent.checked}
                        onChange={() => handleChange(agent.agentFarmer?.id)}
                      />
                    </Flex>
                  </Flex>
                );
              })
            ) : (
              <Text as="i" color="gray600" font="smMedium" textAlign="center">
                No agents match your search
              </Text>
            )}

            {selected.length ? (
              <>
                <Box mt="20px">
                  <Text color="gray600" font="smMedium">
                    Selected
                  </Text>
                  <Flex mt="15px" gap="8px" flexWrap="wrap">
                    {selected.map((s, index) => {
                      return (
                        <Badge key={index} colorScheme={badgeConstants.colorSchemes.SUCCESS}>
                          {s.name}
                        </Badge>
                      );
                    })}
                  </Flex>
                </Box>
                <Box mt="20px">
                  <Button onClick={handleAssignFarmers} display="block" type="button" isDisabled={loading}>
                    {loading ? "Assigning..." : "Assign"}
                  </Button>
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default AssignAgentsPopover;
