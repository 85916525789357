import { ErrorMessage } from "formik";
// import { Box, Text } from "organic";
import React from "react";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import { classnames, isFunction } from "utils";
import { Box, Flex, Text } from "organic";

const selectPropTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  colorScheme: PropTypes.oneOf(["default", "black", "blue", "unstyled"]),
};

const selectDefaultProps = {
  onChange: () => null,
  options: [],
  loading: false,
  disabled: false,
  placeholder: "Select..",
  isMulti: false,
  colorScheme: "default",
};

const Select = ({
  label,
  name,
  placeholder,
  onChange,
  isDisabled,
  options,
  isLoading,
  isMulti,
  colorScheme,
  ...otherSelectProps
}) => {
  const selectRootClassnames = classnames({
    select: colorScheme === selectConstants.colorSchemes.DEFAULT,
    "blue-select": colorScheme === selectConstants.colorSchemes.BLUE,
    "black-select": colorScheme === selectConstants.colorSchemes.BLACK,
    "unstyled-select": colorScheme === selectConstants.colorSchemes.UNSTYLED,
  });
  const selectContainerClassnames = classnames({
    "select-container": colorScheme === selectConstants.colorSchemes.DEFAULT,
    "blue-select-container": colorScheme === selectConstants.colorSchemes.BLUE,
    "black-select-container": colorScheme === selectConstants.colorSchemes.BLACK,
    "unstyled-select-container": colorScheme === selectConstants.colorSchemes.UNSTYLED,
  });

  const _handleChange = selectedOption => {
    if (isFunction(onChange)) {
      if (isMulti) {
        onChange(selectedOption); // return array 'as is' if isMulti
        return selectedOption;
      }
      onChange(selectedOption.value);
      return selectedOption.value;
    }
  };

  return (
    <Flex flexDirection="column" width="100%">
      {label && (
        <Box mb={2}>
          <Text as="label" font="smMedium" color="grey700">
            {label}
            {otherSelectProps?.required === true ? (
              <Text as="span" color="primary600">
                *
              </Text>
            ) : (
              ""
            )}
          </Text>
        </Box>
      )}
      <ReactSelect
        {...otherSelectProps}
        placeholder={placeholder}
        isDisabled={isDisabled}
        options={options}
        isLoading={isLoading}
        name={name || ""}
        onChange={_handleChange}
        isMulti={isMulti}
        unstyled
        className={selectContainerClassnames}
        classNamePrefix={selectRootClassnames}
        classNames={{
          clearIndicator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.clearIndicator),
          control: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.control),
          dropdownIndicator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.dropdownIndicator),
          group: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.group),
          groupHeading: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.groupHeading),
          indicatorsContainer: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.indicatorsContainer),
          indicatorSeparator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.indicatorSeparator),
          input: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.input),
          loadingIndicator: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.loadingIndicator),
          loadingMessage: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.loadingMessage),
          menu: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menu),
          menuList: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menuList),
          menuPortal: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.menuPortal),
          multiValue: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValue),
          multiValueLabel: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValueLabel),
          multiValueRemove: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.multiValueRemove),
          noOptionsMessage: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.noOptionsMessage),
          option: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.option),
          placeholder: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.placeholder),
          singleValue: state => mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.singleValue),
          valueContainer: state =>
            mapSelectStateToClassName(state, reactSelectInnerComponentsClassNames.valueContainer),
        }}
      />
      {/* {name && <ErrorMessage name={name} component="span" className="text-danger" />} */}
      {name && <ErrorMessage name={name} component={SelectErrorComponent} />}
    </Flex>
  );
};
const mapSelectStateToClassName = (state, className) => {
  if (state.isFocused) {
    return `${className}--focused`;
  }
  if (state.isSelected) {
    return `${className}--selected`;
  }
  if (state.isDisabled) {
    return `${className}--disabled`;
  }

  return className;
};

const selectConstants = {
  colorSchemes: {
    DEFAULT: "default",
    BLUE: "blue",
    BLACK: "black",
    UNSTYLED: "unstyled",
  },
};
const reactSelectInnerComponentsClassNames = {
  clearIndicator: "clear-indicator",
  container: "container",
  control: "control",
  dropdownIndicator: "dropdown-indicator",
  group: "group",
  groupHeading: "group-heading",
  indicatorsContainer: "indicators-container",
  indicatorSeparator: "indicator-separator",
  input: "input",
  loadingIndicator: "loading-indicator",
  loadingMessage: "loading-message",
  menu: "menu",
  menuList: "menu-list",
  menuPortal: "menu-portal",
  multiValue: "multi-value",
  multiValueLabel: "multi-value-label",
  multiValueRemove: "multi-value-remove",
  noOptionsMessage: "no-options-message",
  option: "option",
  placeholder: "placeholder",
  singleValue: "single-value",
  valueContainer: "value-container",
};

Select.propTypes = selectPropTypes;
Select.defaultProps = selectDefaultProps;

const SelectErrorComponent = ({ children }) => {
  return (
    <Text as="span" font="smRegular" color="error700">
      {children}
    </Text>
  );
};

export {
  Select,
  selectConstants,
  mapSelectStateToClassName,
  reactSelectInnerComponentsClassNames,
  selectPropTypes,
  selectDefaultProps,
  SelectErrorComponent,
};
