import { CreatableSelect } from "components/select";
import { updateCropVariety } from "features/v1/cropsAndInput";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import React from "react";
import { useDispatch } from "react-redux";

const AddVarietyToCrop = ({ selected, modal }) => {
  const dispatch = useDispatch();

  const handleUpdateCropVariety = (values, formOptions) => {
    dispatch(updateCropVariety(values, { formOptions, modal, cropId: selected._id }));
  };

  const handleCropVarietyChange = (newValues, { setFieldValue, actionMeta }) => {
    switch (actionMeta.action) {
      case "clear": {
        // crop variety is cleared
        setFieldValue("crop_variety", []);
        return;
      }
      case "create-option": {
        // new crop variety is manually entered
        setFieldValue(
          "crop_variety",
          newValues.map(val => val?.value || val)
        );
        return;
      }
      case "select-option": {
        // an crop variety that was previously removed was reselected
        setFieldValue(
          "crop_variety",
          newValues.map(val => val?.value || val)
        );
        return;
      }
      case "remove-value": {
        if (!newValues) {
          setFieldValue("crop_variety", []);
          return;
        }
        setFieldValue(
          "crop_variety",
          newValues.map(val => val?.value || val)
        );
        // an crop variety that was created is removed from list (still available to reselect)
        return;
      }
      default: {
        return;
      }
    }
  };
  return (
    <Formik initialValues={{ crop_variety: selected?.crop_variety || [] }} onSubmit={handleUpdateCropVariety}>
      {({ setFieldValue, values }) => (
        <Form>
          <Flex flexDirection="column" gap="1rem">
            <Box>
              <CreatableSelect
                isMulti
                isClearable
                label="Crop Varieties"
                placeholder="Enter a Crop Variety and hit enter"
                name="crop_variety"
                formatCreateLabel={inputVal => `Hit Enter to Add "${inputVal}"`}
                components={{ DropdownIndicator: null }}
                defaultValue={selected?.crop_variety || []}
                getOptionLabel={option => option?.label || option}
                getOptionValue={option => option?.value || option}
                onChange={(newValue, actionMeta) =>
                  handleCropVarietyChange(newValue, {
                    actionMeta,
                    setFieldValue,
                    values,
                  })
                }
              />
            </Box>
            <Box>
              <Button display="block">Save Crop Variety</Button>
            </Box>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default AddVarietyToCrop;
