import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import farmersApi from "api/farmers";
import { PageWrapper, PageHeader } from "components/layout";
import { useFeedbackModal, useCustomModal, useTable, useDisclosure } from "hooks";
import { legacyFormatDate } from "utils/v1/tableUtils";
import {
  getGroupsChartData,
  getGroupSize,
  getLoadingFarmers,
  getTotalFarmersCount,
  transferFarmerToAgent,
} from "features/v1/farmers";
import Card from "components/card/card";
import { Badge, Box, Flex, badgeConstants } from "organic";
import Chart from "components/chart/chart";
import ChartHeadersList from "components/chart/chartHeadersList";
import { Table } from "components/table";
import { capitalize, isLeadFarmer, validateUUID } from "utils";
import { Modal, ModalContent } from "components/modal";
import ViewFarmerDetails from "./onboarding/viewFarmerDetails";
import EditFarmerDetails from "./onboarding/editFarmerDetails";
import CreateFieldForFarmer from "./onboarding/createFieldForFarmer";
import { ExportButton } from "components/export";
import farmerSeason from "api/farmerseason";
import VerifyBvn from "./onboarding/verifyBvn";
import CreateContractualFarmer, { basicInfoInitialValues } from "./onboarding/createContractualFarmer";
import { ConfirmDialog } from "components/confirmDialog";
import { useNavigate } from "react-router-dom";
import { useGetAllContractualMembers } from "hooks/app/farmer";
import { format } from "date-fns";
import { toast } from "react-toastify";
import AssignContractualFarmer from "./onboarding/assignContractualFarmer";
import { useAppRoles } from "hooks/app";
import BasicInformationForm from "./onboarding/basicInformationForm";
import { handleFormSubmission } from "services/formSubmissionService";
import { useUpdateFarmer } from "hooks/app/farmer/useUpdateFarmer";
import SearchAgents from "./onboarding/searchAgents";

const OnboardingPage = () => {
  const [fetchParams, setFetchParams] = useState({});

  const dispatch = useDispatch();

  const appRoles = useAppRoles();

  const { data: contractualFarmersResponse } = useGetAllContractualMembers({
    params: fetchParams,
  });

  const navigate = useNavigate();
  const [basicInfo, setBasicInfo] = useState(basicInfoInitialValues);

  let loading = useSelector(getLoadingFarmers);
  let groupsChartData = useSelector(getGroupsChartData);

  let groupSize = useSelector(getGroupSize);
  let totalFarmers = useSelector(getTotalFarmersCount);

  // custom hooks
  const farmerViewModal = useDisclosure();

  const farmerEditModal = useDisclosure();

  const createFieldModal = useDisclosure();

  const verifyBvnModal = useDisclosure();
  const createContractualFarmer = useDisclosure();
  const changeRoleModal = useDisclosure();
  const deleteFarmerModal = useDisclosure();
  const demoteFarmer = useDisclosure();
  const promoteFarmer = useDisclosure();
  const editMember = useDisclosure();

  const farmerExportModal = useCustomModal();
  const farmerExportFeedbackModal = useFeedbackModal();

  const { tableProps, selectedRow: selectedFarmer } = useTable({
    data: contractualFarmersResponse?.data || [],
    columns: [
      // {
      //   Header: "ID",
      //   accessor: "ta_number",
      // },
      {
        id: "farmerSerialId",
        Header: "ID",
        accessor(farmer) {
          return validateUUID(farmer?.farmer_id) ? "Not Yet Assigned" : farmer.farmer_id;
        },
      },
      {
        id: "farmerFullName",
        Header: "Name",
        accessor: farmer => `${farmer?.first_name || "N/A"} ${farmer?.last_name || "N/A"}`,
      },
      {
        id: "farmerSyncedDate",
        Header: "Date",
        accessor: farmer => format(new Date(farmer?.created_at), "yyyy-MM-dd"),
      },
      {
        Header: "Phone",
        accessor: "phone_number",
      },

      {
        Header: "Gender",
        accessor: farmer => (farmer.gender ? capitalize(farmer.gender) : "--"),
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "LGA",
        accessor: farmer => (farmer.lga ? farmer.lga : "--"),
      },
      {
        Header: "District",
        accessor: "district",
      },
      {
        Header: "Role",
        accessor: "isContractual",
        Cell: FarmerRoleBadge,
      },
    ],
    title: "All Farmers",
    useServerPagination: true,
    serverPaginationMeta: contractualFarmersResponse?.meta,
    noViewAction: true,
    noEditAction: true,
    // callbacks
    onFilter: params => {
      setFetchParams({ ...params });
    },
  });

  // api calls
  const quickFarmersExport = async () => {
    const data = (await farmerSeason.get("downloadFarmers", { params: tableProps.filterParams })).data;
    return data;
  };

  const getFarmersMetaKeys = async () => {
    return await farmersApi.get("downloadFarmers/getMetaKeys");
  };
  const advanceFarmersExport = async ({ columnWhitelist, filterParams }) => {
    let params = { columnWhitelist, ...filterParams };
    const res = await farmersApi.get("downloadFarmers/advance", { params });
    return res;
  };

  // const handleTransferMultipleFarmerToAgent = (farmers, agent) => {
  //   console.log({ farmers, agent });
  // };
  const handleTransferSingleFarmerToAgent = (farmer, agent) => {
    dispatch(
      transferFarmerToAgent([farmer.group_id], {
        agentTo: agent._id,
        agent,
        agentFrom: farmer.agent.agent_id,
        filter: tableProps.filterParams,
      })
    );
  };
  /** determine what extra action are avalable */
  const getExtraActions = farmer => {
    let actions = [
      { label: "Change Role", actionKey: "changeRole" },
      { label: "View Details", actionKey: "viewDetails" },
      { label: "Edit Details", actionKey: "editDetails" },
      { label: "Delete", actionKey: "deleteFarmer" },
      { label: "Create Field For Farmer", icon: "map", actionKey: "createField" },
    ];
    if (isLeadFarmer(farmer)) {
      actions.push({
        label: "Move To",
        actionKey: "moveFarmer",
        icon: "corner-down-right",
        component: <SearchAgents onChange={agent => handleTransferSingleFarmerToAgent(farmer, agent)} />,
      });
    }

    return !farmer.isContractual ? actions.filter(action => action.actionKey !== "viewDetails") : actions;
  };

  const { mutateAsync: updateFarmerAsync, isLoading: updateFarmerLoading } = useUpdateFarmer({
    agentId: selectedFarmer?.agent?.agent_id,
    id: selectedFarmer?.farmer,
  });

  const updateFarmer = async values => {
    const payload = values;

    await handleFormSubmission({
      submitFn: updateFarmerAsync,
      values: payload,
      config: {},
      errorMsg: "Failed to update member farmer",
      successMsg: "",
      onSuccess() {
        toast.success("Member farmer updated successfully");
        editMember.onClose();
      },
      onError(error) {
        console.log({ error });
      },
    });
  };

  return (
    <PageWrapper>
      {/* pageHeader Section */}
      <Flex flexDirection="row" justifyContent="space-between" align-Items="center">
        <PageHeader title="Farm > Onboarding" subtitle="Your farmers summary and activity." />
        <ExportButton
          quickExportConfig={{
            apiCall: quickFarmersExport,
            fileName: `AOSFarmersQuickExport${legacyFormatDate(new Date())}.csv`,
          }}
          advancedExportConfig={{
            apiCall: advanceFarmersExport,
            getKeys: getFarmersMetaKeys,
            modal: farmerExportModal,
            feedbackModal: farmerExportFeedbackModal,
          }}
        />
      </Flex>
      {/* pageContent Section */}
      <Box width="100%" mt="2rem">
        {/* chart */}
        <Card>
          <ChartHeadersList
            headers={[
              {
                title: "Total Groups",
                value: groupSize || 0,
              },
              { title: "Total Farmers", value: totalFarmers || 0 },
            ]}
          />
          <Chart
            name="Farmer(s)"
            height={242}
            width="100%"
            loading={loading}
            type="area"
            data={groupsChartData}
            yAxisLabel={"farmers"}
          />
        </Card>
      </Box>
      <Box mt="2.25rem">
        {/* table */}
        <Table
          {...tableProps}
          // onSearch={(params, { abortController }) => {
          //   setFetchParams({ params, abortController });
          // }}
          createAction={
            appRoles.role === "superAdmin" || appRoles.role === "supervisor"
              ? { label: "Create Contractual Farmer" }
              : null
          }
          onCreateAction={() => {
            appRoles.role === "superAdmin" || appRoles.role === "supervisor" ? createContractualFarmer.onOpen() : null;
          }}
          onExtraAction={({ actionKey }) => {
            if (actionKey === "changeRole") {
              if (!selectedFarmer.agent.agent_id) {
                toast.error("Farmer has no agent id");
                return;
              }
              changeRoleModal.onOpen();
            }
            if (actionKey === "viewDetails") {
              if (!selectedFarmer.agent.agent_id) {
                toast.error("Farmer has no agent id");
                return;
              }
              navigate(`/dashboard/farm/onboarding/${selectedFarmer.agent.agent_id}`);
            }
            if (actionKey === "editDetails") {
              if (!selectedFarmer.agent.agent_id) {
                toast.error("Farmer has no agent id");
                return;
              }
              if (selectedFarmer.isContractual) {
                navigate(`/dashboard/farm/onboarding/${selectedFarmer.agent.agent_id}/edit`);
              } else {
                editMember.onOpen();
              }
            }
            if (actionKey === "deleteFarmer") {
              deleteFarmerModal.onOpen();
            }

            if (actionKey === "createField") {
              createFieldModal.onOpen();
            }
          }}
          getExtraActions={getExtraActions}
        />
      </Box>
      {/* Create contractual farmer */}
      <Modal {...createContractualFarmer.modalProps}>
        <ModalContent title="Create Contractual Farmer">
          <CreateContractualFarmer modal={createContractualFarmer} />
        </ModalContent>
      </Modal>

      {/* Promote member to contractual farmer */}
      <Modal {...promoteFarmer.modalProps}>
        <ModalContent title="Create Contractual Farmer">
          <CreateContractualFarmer modal={promoteFarmer} selectedFarmer={selectedFarmer} />
        </ModalContent>
      </Modal>

      {/* Edit member */}
      <Modal {...editMember.modalProps}>
        <ModalContent title="Edit Member">
          <BasicInformationForm
            basicInfo={basicInfo}
            setBasicInfo={setBasicInfo}
            isMemberFarmer
            selectedFarmer={selectedFarmer}
            hasSubmitButton
            submitButtonText={`${updateFarmerLoading ? "Submitting..." : "Submit"}`}
            handleSubmit={values => {
              updateFarmer(values);
            }}
            isSubmitting={updateFarmerLoading}
          />
        </ModalContent>
      </Modal>

      {/* view farmer */}
      <Modal
        size
        open={farmerViewModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmerViewModal.onClose();
          }
        }}
      >
        <ModalContent title="View Farmer  Details">
          <ViewFarmerDetails />
        </ModalContent>
      </Modal>

      {/* edit farmer */}
      <Modal
        open={farmerEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmerEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Farmer  Details">
          <EditFarmerDetails selected={selectedFarmer} modal={farmerEditModal.modal} />
        </ModalContent>
      </Modal>

      {/* create field for farmer */}
      <Modal
        open={createFieldModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            createFieldModal.onClose();
          }
        }}
      >
        <ModalContent title="Create Field for Farmer">
          <CreateFieldForFarmer selected={selectedFarmer} modal={createFieldModal.modal} />
        </ModalContent>
      </Modal>
      {/* verify BVN */}
      <Modal
        open={verifyBvnModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            verifyBvnModal.onClose();
          }
        }}
      >
        <ModalContent title="BVN Verification">
          <VerifyBvn selected={selectedFarmer} />
        </ModalContent>
      </Modal>
      {/* Change Role */}
      <Modal
        open={changeRoleModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            changeRoleModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={450}>
          <ConfirmDialog
            heading="Do you want to continue?"
            subHeading="<p>
            Are you sure you want to change role? <br /> Existing login credentials will be lost
          </p>"
            confirmationText="Continue"
            modal={changeRoleModal.modal}
            onConfirm={() => {
              changeRoleModal.modal.hideModal();
              if (!selectedFarmer.isContractual) {
                promoteFarmer.onOpen();
              } else {
                demoteFarmer.onOpen();
              }
            }}
          />
        </ModalContent>
      </Modal>
      {/* Delete Farmer */}
      <Modal
        open={deleteFarmerModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            deleteFarmerModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={450}>
          <ConfirmDialog
            heading="Do you want to continue?"
            subHeading="<p>
            Are you sure you want to delete?
          </p>"
            confirmationText="Continue"
            modal={deleteFarmerModal.modal}
            onConfirm={() => {
              deleteFarmerModal.modal.hideModal();
            }}
          />
        </ModalContent>
      </Modal>
      {/* Demote farmer */}
      <Modal {...demoteFarmer.modalProps}>
        <ModalContent title="Assign Contractual Farmer">
          {/* <CreateContractualFarmer modal={createNewMemberFarmer.modal} /> */}
          <AssignContractualFarmer modal={demoteFarmer.modal} handleSubmit={() => {}} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

const FarmerRoleBadge = ({ value }) => (
  <Badge colorScheme={value ? badgeConstants.colorSchemes.PRIMARY : badgeConstants.colorSchemes.GRAY}>
    {value ? "Contractual Farmer" : "Member"}
  </Badge>
);

// const FarmerBvnStatusBadge = () => {
//   return <Badge colorScheme={badgeConstants.colorSchemes.GRAY}>N/A</Badge>;
// };

export default OnboardingPage;
