import { FormInput, FormTextArea } from "components/inputs";
import { CountrySelect, LGASelect, StateSelect } from "components/select";
import { createWarehouse } from "features/v1/warehouse";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createWarehouseValidationSchema } from "validators/warehouseValidationSchemas";

const CreateStore = ({ modal }) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const dispatch = useDispatch();

  const createWarehouseInitialValues = {
    store: {
      name: "",
      address: "",
      state: "",
      ta_number: "",
      lga: "",
    },
    user: [],
  };

  return (
    <Formik
      initialValues={createWarehouseInitialValues}
      validationSchema={createWarehouseValidationSchema}
      onSubmit={(values, formikOptions) => {
        dispatch(
          createWarehouse(values, {
            formikOptions,
            modal,
          })
        );
      }}
    >
      {({ values, setFieldValue, isSubmitting, handleChange }) => (
        <Form>
          <Flex flexDirection="column" gap="1rem">
            <Box>
              <FormInput
                label="Store Name"
                name="store.name"
                placeholder="Enter Store Name"
                value={values.store.name}
                onChange={handleChange}
                required
              />
            </Box>
            <Box>
              <FormInput
                name="store.ta_number"
                label="Store TAS Number"
                placeholder="Enter Store TAS Number eg. (TAS-0001)"
                value={values.store.ta_number}
                required
                onChange={handleChange}
              />
            </Box>
            <Box>
              <FormTextArea
                label="Store Address"
                name="store.address"
                placeholder="Enter Store Address"
                // value={values.store.address}
                // onChange={handleChange}
              />
            </Box>
            <Box>
              <CountrySelect required onChange={setSelectedCountry} />
            </Box>
            <Box>
              <StateSelect
                name="store.state"
                label="Store State"
                countryName={selectedCountry}
                required
                onChange={val => setFieldValue("store.state", val)}
              />
            </Box>
            <Box>
              <LGASelect
                name="store.lga"
                required
                label="Store LGA"
                countryName={selectedCountry}
                state={values.store.state}
                onChange={val => setFieldValue("store.lga", val)}
              />
            </Box>
          </Flex>
          <Button mt="2rem" display="block" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default CreateStore;
