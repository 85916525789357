import React from "react";
import { StyledLabel, StyledTextArea, StyledTextAreaGroup } from "./styledInput";
import { Text } from "../primary";
import Box from "../layout/box";
import Icon from "../icon/icon";

const TextArea = React.forwardRef(
  (
    {
      hint,
      cols,
      rows,
      description,
      errorText,
      label,
      isInvalid,
      name,
      value,
      onChange,
      isDisabled,
      isRequired,
      placeholder,
      defaultValue,
    },
    ref
  ) => {
    return (
      <Box position="relative">
        <Box mb={2}>
          <StyledLabel fontSize={14} pb={2}>
            {label}
            {isRequired === true ? (
              <Text as="span" color="primary600">
                *
              </Text>
            ) : (
              ""
            )}
          </StyledLabel>
        </Box>
        <StyledTextAreaGroup>
          <StyledTextArea
            ref={ref}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
            required={isRequired}
            placeholder={placeholder}
            defaultValue={defaultValue}
            rows={rows}
            cols={cols}
          />
          {description ? <Icon icon="help-circle" /> : null}
          {isInvalid ? <Icon icon="alert-circle" /> : null}
        </StyledTextAreaGroup>

        <Text>{hint}</Text>
        <Text as="span" font="smRegular" color="error700">
          {errorText ? errorText : ""}
        </Text>
      </Box>
    );
  }
);

TextArea.displayName = "TextArea";

export { TextArea };
