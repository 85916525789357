import { sessionStorageKeys } from "constants/storageConstants";
import { toast } from "react-toastify";

export const authUtils = {
  setToken(val) {
    try {
      sessionStorage.setItem(sessionStorageKeys.TOKEN, val);
    } catch (error) {
      toast.error(error.message);
      console.log({ error });
    }
  },
  getToken() {
    try {
      const token = sessionStorage.getItem(sessionStorageKeys.TOKEN);
      if (token) {
        return token;
      } else {
        return null;
      }
    } catch (error) {
      toast.error(error.message);
      console.log({ error });
      return null;
    }
  },
  removeToken() {
    try {
      sessionStorage.removeItem(sessionStorageKeys.TOKEN);
    } catch (error) {
      toast.error(error.message);
      console.log({ error });
    }
  },
  setRefresh(val) {
    try {
      sessionStorage.setItem(sessionStorageKeys.TOKEN, val);
    } catch (error) {
      toast.error(error.message);
      console.log({ error });
    }
  },
  getRefresh() {
    try {
      return sessionStorage.getItem(sessionStorageKeys.TOKEN);
    } catch (error) {
      toast.error(error.message);
      console.log({ error });
      return null;
    }
  },
  removeRefresh() {
    try {
      sessionStorage.removeItem(sessionStorageKeys.TOKEN);
    } catch (error) {
      toast.error(error.message);
      console.log({ error });
    }
  },
};
