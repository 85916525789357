import { faker } from "@faker-js/faker";
import { mockOverviewConstants } from "./constants";

export function mockSingelFarmerGraphData() {
  return {
    created_at: mockOverviewConstants.DATE_RANGE_SAMPLE[faker.number.int(9)],
    role: "lead_farmer",
    _id: faker.string.uuid(),
  };
}

export function mockFarmerOverview() {
  const result = {
    groupSize: mockOverviewConstants.GROUP_COUNT,
    newFarmers: mockOverviewConstants.FARMER_COUNT,
    oldFarmers: 0,
    totalFarmers: mockOverviewConstants.FARMER_COUNT,
    agentInvolvedCount: mockOverviewConstants.ACTIVE_AGENTS,
    averageFarmersPerAgent: mockOverviewConstants.AVG_FARMER_PER_AGENT,
    averageGroupSize: mockOverviewConstants.AVG_GROUP_SIZE,
    graphData: [...faker.helpers.multiple(mockSingelFarmerGraphData, { count: mockOverviewConstants.SAMPLE_SIZE })],
  };

  console.log({ result });
  return {
    data: {
      data: result,
    },
  };
}

export function generateChartData({ value, count }) {
  return faker.helpers.multiple(
    () => ({
      x: mockOverviewConstants.DATE_RANGE_SAMPLE[faker.number.int(30)],
      y: faker.number.int(value),
    }),
    count
  );
}
