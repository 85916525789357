import { ErrorMessage } from "formik";
import { Text } from "organic";
import React from "react";

export const FormErrorMessage = ({ name }) => {
  if (!name) {
    return <></>;
  }
  return (
    <ErrorMessage name={name}>
      {msg => (
        <Text as="p" font="smRegular" color="error700">
          {msg}
        </Text>
      )}
    </ErrorMessage>
  );
};
