import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Flex, Text } from "organic";
import { Tooltip } from "components/modal";
import * as Sentry from "@sentry/react";

export class ErrorState extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    console.log({ componentError: error, componentErrorDetails: errorInfo });
  }

  handleUnhandledRejections = event => {
    Sentry.captureException(event.reason);
  };

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handleUnhandledRejections);
  }

  componentWillUnmount() {
    removeEventListener("unhandledrejection", this.handleUnhandledRejections);
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    const { children, useTooltip } = this.props;
    if (hasError || error) {
      return (
        <ErrorStateWrapper useTooltip={useTooltip} message={error.message}>
          <ErrorStateComponent error={error} useTooltip={useTooltip} errorInfo={errorInfo} />
        </ErrorStateWrapper>
      );
    }
    return children;
  }
}

const ErrorStateWrapper = ({ useTooltip, message, children }) => {
  if (useTooltip === true) {
    return (
      <Tooltip
        content={
          <Flex flexDirection="column" maxWidth="360px">
            <Text font="mdSemibold">An Error Occured</Text>

            <Text font="smRegular">
              Reason:&nbsp;
              <Text as="span" color="error800">
                {message}
              </Text>
            </Text>
          </Flex>
        }
        openOnHover
      >
        {children}
      </Tooltip>
    );
  }

  return <>{children}</>;
};

const ErrorStateComponent = ({ useTooltip, error, errorInfo }) => {
  const handleRefresh = () => {
    window.location.reload(false);
  };

  const handleReportError = () => {
    toast.success("Support has been notified about this via email and you will be contacted soon");
  };

  const handleGoHome = () => {
    window.location.pathname = "/dashboard/overview";
  };

  if (useTooltip === true) {
    return (
      <Flex
        backgroundColor="gray300"
        flexDirection="column"
        justifyContent="center"
        borderRadius="8px"
        alignItems="center"
        p="1rem"
      >
        <Text font="lgSemibold">An Error Occured</Text>
        <Text as="code">{error && error.toString()}</Text>
      </Flex>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#ececec",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // borderRadius: 8,
        padding: 16,
        width: "100%",
        height: "100%",
      }}
    >
      <span className="display-1 text-primary font-weight-bold">OOPS!</span>
      <h2>Sorry, something went wrong</h2>
      <h4>an error has occured</h4>
      <h6>
        If you&apos;re expriencing this error for the first time, kindly click report error below
        <br />
        Try Refreshing the page, if this doesn&apos;t help Go back to the home page
      </h6>

      <Flex gap="1rem" my="1rem">
        <Button colorScheme="secondaryGray" variant="destructive" onClick={() => handleReportError(error, errorInfo)}>
          Report Error
        </Button>
        {/* <Link to="/dashboard/"> */}
        <Button colorScheme="secondaryGray" onClick={handleRefresh}>
          Refresh Page
        </Button>
        <Button onClick={() => handleGoHome(error, errorInfo)}>Go Back to Home Page</Button>
        {/* </Link> */}
      </Flex>
      <details style={{ whiteSpace: "pre-wrap" }}>
        {error && error.toString()}
        <br />
        {errorInfo?.componentStack}
      </details>
    </div>
  );
};
