import React from "react";
import * as DropdownPrimitives from "@radix-ui/react-dropdown-menu";
import { classnames } from "utils";

const DropdownSubMenu = DropdownPrimitives.Sub;

const DropdownSubMenuTrigger = DropdownPrimitives.SubTrigger;
DropdownSubMenuTrigger.defaultProps = {
  className: "default-dropdown-submenu__trigger",
};

const DropdownSubMenuContent = React.forwardRef(function (
  { children, className, unstyled, ...restOfDropdownSubMenuContentProps },
  forwardedRef
) {
  const dropdownSubMenuClassnames = classnames(className, { "default-dropdown-submenu__content": unstyled === true });
  return (
    <DropdownPrimitives.Portal>
      <DropdownPrimitives.SubContent
        className={dropdownSubMenuClassnames}
        {...restOfDropdownSubMenuContentProps}
        ref={forwardedRef}
      >
        {children}
      </DropdownPrimitives.SubContent>
    </DropdownPrimitives.Portal>
  );
});
DropdownSubMenuContent.displayName = "DropdownSubMenuContent";

export { DropdownSubMenu, DropdownSubMenuTrigger, DropdownSubMenuContent };
