/* eslint-disable no-unused-vars */
import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { getCropOptions, getCurSeasonInputs, getCurSeasonInputsData } from "features/v1/cropsAndInput";
import { getDashboardData } from "features/v1/dashboard";
import { getSeasonOptions } from "features/v1/season";
import { useDataFilter, useDisclosure, useTable } from "hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "utils";
import { selectLabelFromValue } from "utils/v1/selectUtils";
import CreateInputForm from "views/forms/createInputForm";
import SetQuantityPerHectare from "./inputs/setQuantityPerHectare";

const InputSettingsPage = () => {
  let dashboardData = useSelector(getDashboardData);

  const { filterParams, isValidFilter } = useDataFilter();
  let inputsData = useSelector(getCurSeasonInputsData);
  let cropOptions = useSelector(getCropOptions);
  let projectOptions = useSelector(getSeasonOptions);

  const dispatch = useDispatch();

  const { tableProps, selectedRow: selectedInput } = useTable({
    data: inputsData,
    columns: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        id: "inputCrop",
        Header: "Crop",
        accessor: input => selectLabelFromValue(cropOptions, input.crop),
      },
      {
        id: "inputCreatedAt",
        Header: "Created",
        accessor: input => formatDate(new Date(input.created_at)),
      },
      {
        id: "inputProject",
        Header: "Project",
        accessor: input => selectLabelFromValue(projectOptions, input.season),
      },
      {
        Header: "Input Distribution Round",
        accessor: "input_distribution",
      },
    ],
    title: "All Inputs",
    noViewAction: true,
  });

  const createInputDisclosure = useDisclosure();
  const editInputDisclosure = useDisclosure();
  useEffect(() => {
    if (dashboardData?.selectedCrop && isValidFilter) {
      dispatch(getCurSeasonInputs({ seasonId: filterParams.seasonId, cropdId: dashboardData.selectedCrop }));
    }
  }, [dashboardData?.selectedCrop, dispatch, filterParams, isValidFilter]);

  return (
    <div>
      <Table
        {...tableProps}
        createAction={{ label: "Add New Input" }}
        onCreateAction={() => {
          createInputDisclosure.onOpen();
        }}
        onEditAction={() => {
          editInputDisclosure.onOpen();
        }}
      />
      {/* <Inputs /> */}
      <Modal {...createInputDisclosure.modalProps}>
        <ModalContent title="Add New Inputs">
          <CreateInputForm closeModal={() => createInputDisclosure.onClose()} />
        </ModalContent>
      </Modal>

      <Modal {...editInputDisclosure.modalProps}>
        {/* Quantity of Input Per hectare Size */}
        <ModalContent title="Set Input Quantity Per Hectare">
          <SetQuantityPerHectare selected={selectedInput} closeModal={() => createInputDisclosure.onClose()} />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default InputSettingsPage;
