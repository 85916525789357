import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getFilterDateRange, setDateRange } from "features/v1/filter";
import { Flex, Text } from "organic";

import { createStaticRanges, dateConstants } from "utils";
import { DateRangeSelect } from "components/date";

const DateRangeFilter = ({ ...otherDateRangeFilterProps }) => {
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();

  const dateRange = useSelector(getFilterDateRange);

  const _handleFilterSelectCustomRange = async dateArray => {
    try {
      dispatch(setDateRange(dateArray));
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const staticRanges = createStaticRanges(defaultStaticRanges);

  return (
    <Flex flexDirection="column" width="auto" {...otherDateRangeFilterProps}>
      <Flex flexDirection="row" justifyContent="end" width="auto">
        <DateRangeSelect dateRange={dateRange} staticRanges={staticRanges} onChange={_handleFilterSelectCustomRange} />
      </Flex>
      <Text as="p" font="xsMedium" color="error500">
        {" "}
        {errorMsg}
      </Text>
    </Flex>
  );
};

/**
 *
 */
const defaultStaticRanges = [
  // today
  {
    label: "Today",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_TODAY,
      endDate: dateConstants.namedDates.END_OF_TODAY,
    }),
  },
  // this week
  {
    label: "This Week",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_WEEK,
      endDate: dateConstants.namedDates.END_OF_WEEK,
    }),
  },
  // last week
  {
    label: "Last Week",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_LAST_WEEK,
      endDate: dateConstants.namedDates.END_OF_LAST_WEEK,
    }),
  },
  // this month
  {
    label: "This Month",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_MONTH,
      endDate: dateConstants.namedDates.END_OF_MONTH,
    }),
  },
  // last month
  {
    label: "Last Month",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_LAST_MONTH,
      endDate: dateConstants.namedDates.END_OF_LAST_MONTH,
    }),
  },
  // last quarter
  {
    label: "Last Quarter",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_LAST_QUARTER,
      endDate: dateConstants.namedDates.END_OF_LAST_QUARTER,
    }),
  },
  // month to date
  // {
  //   label: "Month to Date",
  //   range: () => ({
  //     startDate: dateConstants.namedDates.START_OF_MONTH_TO_DATE,
  //     endDate: dateConstants.namedDates.END_OF_MONTH_TO_DATE,
  //   }),
  // },
  // quarter to date
  {
    label: "Quarter to Date",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_QUARTER_TO_DATE,
      endDate: dateConstants.namedDates.END_OF_QUARTER_TO_DATE,
    }),
  },
  // year to date
  {
    label: "Year to Date",
    range: () => ({
      startDate: dateConstants.namedDates.START_OF_YEAR_TO_DATE,
      endDate: dateConstants.namedDates.END_OF_YEAR_TO_DATE,
    }),
  },
];

DateRangeFilter.propTypes = {
  page: PropTypes.string,
  location: PropTypes.string,
};

DateRangeFilter.defaultPropTypes = {
  handleFilterWrapper: () => {},
  handleExportFilter: () => {},
  page: "",
  location: "",
};

export { DateRangeFilter };
