import React, { useEffect, useState } from "react";
import { OverviewValueWrapper, resolveMarketplaceTradeStatusColor } from "../marketplaceOverviewPage";
import { Table } from "components/table";
import { SummaryGrid } from "components/grid";
import { Avatar, AvatarGroup, Badge, Button, Flex, Text } from "organic";
import { useDataFilter, useDisclosure, useTable } from "hooks";
import { useGetMarketplaceOverview, useGetTrades } from "hooks/app/marketplace";
import { tradeStatuses } from "../marketplaceTradePage";
import numeral from "numeral";
import { appGet, capitalize } from "utils";
import { useNavigate } from "react-router-dom";
import GenerateInvoice from "./generateInvoice";
import { Modal, ModalContent } from "components/modal";

const StockedTrades = () => {
  const { filterParams } = useDataFilter();

  const navigate = useNavigate();
  const generateInvoiceModal = useDisclosure();

  const [selectedRow, setSelectedRow] = useState(null);
  const handleGenerateInvoice = row => {
    setSelectedRow(row);
  };
  useEffect(() => {
    if (selectedRow && !generateInvoiceModal.isOpen) {
      generateInvoiceModal.onOpen();
    }
  }, [selectedRow]);
  const { data: overviewData, isLoading: isOverviewLoading } = useGetMarketplaceOverview({
    params: filterParams.dateRange,
  });
  const { data: tradeData } = useGetTrades({ params: { status: tradeStatuses.STOCKED } });

  console.log("Farmer", tradeData?.data);
  const { tableProps, selectedRow: selectedTrade } = useTable({
    data: tradeData?.data || [],
    columns: [
      {
        Header: "ID",
        accessor: "trade_id",
      },
      {
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Cost Price",
        accessor: trade => numeral(trade.cost_price).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Farmers under trade",
        accessor: "trade_farmers",
      },
      {
        Header: "No. of Sent Invoice",
        accessor: "total_invoice",
      },
      {
        Header: "Target Qty (KG)",
        accessor: trade => numeral(trade?.target_quantity || 0).format("0,0.00"),
      },
      {
        Header: "Sourced Qty (KG)",
        accessor: trade => numeral(trade.target_achieved).format("0,0.00"),
      },
      {
        Header: "Sold Qty (KG)",
        accessor: "total_sold",
      },
      {
        Header: "Balance Qty (KG)",
        accessor: "balance_quantity",
      },
      {
        Header: "Total Invesment",
        accessor: trade => numeral(trade.total_investment).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Total Logistics",
        accessor: trade => numeral(trade.logistics_cost).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Assigned Agents",
        accessor: "assigned_agents",
        Cell: ({ value }) => {
          return (
            <AvatarGroup>
              {value?.map?.(agent => (
                <Avatar size="xs" key={agent._id} name={`${agent.first_name} ${agent.last_name}`} />
              ))}
            </AvatarGroup>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge colorScheme={resolveMarketplaceTradeStatusColor(value)}>{capitalize(value)}</Badge>,
      },
      {
        Header: "",
        accessor: "generate_Invoice",
        Cell: ({ row }) => {
          return <Button onClick={() => handleGenerateInvoice(row.original)}>Generate Invoice</Button>;
        },
      },
    ],
    title: "All stocked trades",
    useServerPagination: true,
    serverPaginationMeta: {},
    noEditAction: true,
  });

  return (
    <Flex flexDirection="column">
      <SummaryGrid
        data={[
          {
            title: "Total Trade Created",
            component: (
              <OverviewValueWrapper
                data={overviewData}
                value={numeral(overviewData?.pending || 0)
                  .add(overviewData?.approved || 0)
                  .add(overviewData?.declined || 0)
                  .add(overviewData?.stocked || 0)
                  // .add(overviewData?.delivered || 0)
                  .add(overviewData?.invoiceStatusCount || 0)
                  .add(overviewData?.finalized || 0)
                  .add(overviewData?.closed || 0)
                  .format("0")}
                isLoading={isOverviewLoading}
              />
            ),
          },
          {
            title: "Total Approved",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.approved} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Declined",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.declined} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Stocked",
            component: (
              <OverviewValueWrapper
                isActive
                data={overviewData}
                value={overviewData?.stocked}
                isLoading={isOverviewLoading}
              />
            ),
          },
          // {
          //   title: "Total Delivered",
          //   component: (
          //     <OverviewValueWrapper data={overviewData} value={overviewData?.delivered} isLoading={isOverviewLoading} />
          //   ),
          // },
          {
            title: "Total Finalised",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.finalized} isLoading={isOverviewLoading} />
            ),
          },
          {
            title: "Total Closed",
            component: (
              <OverviewValueWrapper data={overviewData} value={overviewData?.closed} isLoading={isOverviewLoading} />
            ),
          },
        ]}
      />
      <Table
        {...tableProps}
        onEditAction={() => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=edit`);
        }}
        onViewAction={() => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=view`);
        }}
      />

      <Modal
        open={generateInvoiceModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            generateInvoiceModal.onClose();
          }
        }}
      >
        <ModalContent size="md" title="Generate Invoice ">
          <GenerateInvoice trade={selectedRow} modal={generateInvoiceModal.modal} />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default StockedTrades;
