import React from "react";
import * as TooltipPrimitives from "@radix-ui/react-tooltip";
import { useDisclosure } from "hooks";
import { Box } from "organic";
import { classnames } from "utils";

const tooltipValues = {
  colors: {
    default: "white",
    green: "#429A24",
  },
};
export const tooltipConstants = {
  colorSchemes: {
    DEFAULT: "default",
    GREEN: "green",
  },
};
const Tooltip = ({
  children,
  colorScheme,
  openOnHover,
  content,
  open,
  side,
  align,
  defaultOpen,
  onOpenChange,
  ...props
}) => {
  const tooltipDisclosure = useDisclosure();

  return (
    <>
      {openOnHover === true ? (
        <TooltipPrimitives.Provider>
          <TooltipPrimitives.Root open={tooltipDisclosure.isOpen} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
            <TooltipPrimitives.Trigger asChild>
              <div onMouseEnter={() => tooltipDisclosure.onOpen()} onMouseLeave={() => tooltipDisclosure.onClose()}>
                {children}
              </div>
            </TooltipPrimitives.Trigger>
            <TooltipPrimitives.Content
              side={side}
              align={align}
              {...props}
              className={classnames(props.className, "tooltip-content")}
            >
              <Box
                bg={tooltipValues.colors[`${colorScheme}`]}
                zIndex={999999}
                borderRadius="0.5rem"
                py="0.5rem"
                px="12px"
              >
                {content}
              </Box>
              <TooltipPrimitives.Arrow fill={tooltipValues.colors[`${colorScheme}`]} width={11} height={5} />
            </TooltipPrimitives.Content>
          </TooltipPrimitives.Root>
        </TooltipPrimitives.Provider>
      ) : (
        <TooltipPrimitives.Provider>
          <TooltipPrimitives.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
            <TooltipPrimitives.Trigger asChild>{children}</TooltipPrimitives.Trigger>
            <TooltipPrimitives.Content side={side} align={align} {...props}>
              {content}
              <TooltipPrimitives.Arrow width={11} height={5} />
            </TooltipPrimitives.Content>
          </TooltipPrimitives.Root>
        </TooltipPrimitives.Provider>
      )}
    </>
  );
};

Tooltip.defaultProps = {
  side: "top",
  align: "center",
  openOnHover: false,
  colorScheme: "default",
  isVisible: true,
};

export { Tooltip };
