/** return each string in an array as a hypenated string */
export const DEPRECATED_hyphenate = strArray => `${strArray.join("-")}`;

/** pretty print JS Object to console  */
export const printObj = (obj = {}, title = "Unanmed Object") => {
  console.log(
    "===================\n" + "[PRINTING OBJ]: " + title + "\n===================\n" + JSON.stringify(obj, null, 2)
  );
};

/**
 * generate an array of specified length
 * of random integers of specified range
 * NOTE: incomplete  */
export const generateArrayOfInts = (len = 0, __range = [0, len]) => {
  let arrayOfInts = new Array(len);
  return arrayOfInts;
};

/**
 * a regex function to test if a string contains only digits
 * (useful for yup validations)
 * @param {String} value - a string to test
 * @returns {Boolean}  true | false based on the result of the test
 */
export const digitsOnly = value => /^\d+$/.test(value);
