import { useState } from "react";
import { toast } from "react-toastify";
import { legacyFormatDate } from "utils/v1/tableUtils";
import _ from "lodash";
import { jsonToCSV } from "react-papaparse";
import { flattenObj, formatDate, formatObjectNullFields, isEmptyObject, isFunction, isObject, isString } from "utils";
import { removeObjProps } from "utils/v1/sanitize";
/**
 *
 * @param {Object} config an object with config options
 * @param {Function} config.apiCall an async function that should return csv data
 * @param {String} config.fileName an object where keys are tab ids
 *                                 and values are fileNames for the csv files
 * @param {Object} config.apiCalls an object where keys are tab ids
 *                                  and the values are Function that return csv data
 * @param {Object} config.fileNames an object where keys are tab ids
 *                                 and values are fileNames for the csv files
 * @param {boolean} config.activeTab value of activeTab from useTabs hook
 *
 * * Custom hook to handle quick export
 *
 * * Usage
 *    Either provide provide a single function that returns JSON data
 *    Or provide config options for multiple exports on the same page
 * * Example
 *      const RandomQuickExport = useQuickExport({
          apiCall: async () => { return (await get("csvEndpoint")).data}
 *      })
 * * Options
 *  - activeTabs: (String): value of current tabId as provided by useCustomTabs custom hooks
 *  - apiCalls: (Object):
 */

const defaultFileName = val =>
  `AOS${_.capitalize(val?.toString() || "")}QuickExport_${legacyFormatDate(new Date())}.csv`;

function useQuickExport(config) {
  const [downloadData, setDownloadData] = useState("");
  const [loadingDownload, setLoadingDownload] = useState(false);

  const handleError = quickExportError => {
    // log error - temporarily for testing
    console.log({ error: quickExportError });

    if (quickExportError?.response) {
      toast.error(
        quickExportError?.response?.data?.message ||
          quickExportError?.response?.message ||
          "Quick Export Failed, Please Try Advanced Export"
      );
      return;
    }

    if (quickExportError?.message) {
      toast.error(quickExportError.message);
      return;
    }

    toast.error("Unfortunately Quick Export Failed, Please Try Advanced Export");
    return;
  };
  const downloadCsvFile = (csv = "") => {
    if (csv && csv.length > 0) {
      // export successfull
      setDownloadData(csv);
      let hiddenElement = document.createElement("a");
      let blob = new Blob(["\ufeff", csv]);
      let url = URL.createObjectURL(blob);
      hiddenElement.href = url;
      // hiddenElement.target = "_blank";
      hiddenElement.download = config?.activeTab
        ? defaultFileName(config?.activeTab)
        : config?.fileName || defaultFileName();
      hiddenElement.click();
      hiddenElement.remove(); // experimental, hopefully prevent creating too many nodes
      toast.success("Quick Export Successful");
    } else {
      toast.warn("No data for the current filter, try a different filter");
    }
  };

  const downloadCsvData = async config => {
    try {
      let csv = "";
      // config is a callback or config.apiCall
      if (isFunction(config)) {
        csv = await config();
        return csv;
      }
      // config is an object and config.apiCall is a callBack
      if (isObject(config) && isFunction(config?.apiCall)) {
        csv = await config.apiCall();
        return csv;
      }

      // config is a config object use activeTab to select callback
      if (isObject(config) && !isEmptyObject(config)) {
        if (
          config?.activeTab &&
          isObject(config.apiCalls) &&
          !isEmptyObject(config.apiCalls) &&
          config?.apiCalls[config?.activeTab]
        ) {
          csv = await config.apiCalls[config.activeTab]();
          return csv;
        }
        if (config?.activeTab && !config?.apiCalls[config?.activeTab]) {
          toast.info(`Quick export not yet available for ${config?.activeTab || "this"} tab`);
          return csv;
        }
      } else {
        // no config options
        toast.info("Quick export not yet available for this page");
        return csv;
      }
    } catch (error) {
      handleError(error);
    }
  };

  const csvDownload = async () => {
    let csvData = "";
    try {
      console.log("quick export", { config });
      setLoadingDownload(true);
      let jsonData = await downloadCsvData(config);
      console.log({ jsonData });
      if (!jsonData) {
        downloadCsvFile(csvData);
        return;
      }
      if (isString(jsonData)) {
        downloadCsvFile(jsonData);
        return;
      }
      if (Array.isArray(jsonData?.data)) {
        jsonData = jsonData.data;
      }
      if (Array.isArray(jsonData)) {
        console.log({ jsonData });
        let blacklistedFields = ["_id", "_v", "updated_at", "password_hash", "verifyToken"];
        jsonData = jsonData.map(datum => {
          let flatJsonData = flattenObj(datum);
          let sanitizedJsonData = removeObjProps(flatJsonData, blacklistedFields);
          let formattedJsonData = formatObjectNullFields(sanitizedJsonData);
          formattedJsonData.created_at = formatDate(formattedJsonData.created_at);
          if (formattedJsonData.onboarded_at) {
            formattedJsonData.onboarded_at = formatDate(formattedJsonData.onboarded_at);
          }
          console.log("before", { formattedJsonData });
          return formattedJsonData;
        });
        const csvReadyData = JSON.stringify(jsonData);
        console.log("AFTER", { csvReadyData });
        csvData = jsonToCSV(csvReadyData);
        console.log({ csvData });
      }
      downloadCsvFile(csvData);
      setLoadingDownload(false);
    } catch (error) {
      handleError(error);
      setLoadingDownload(false);
    }
  };
  return { downloadData, csvDownload, loadingDownload };
}

export { useQuickExport };
