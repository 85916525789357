import axios from "axios";
import { getCurrentAosVersion, getCustomerID } from "services/customerService";
import authService from "../services/v1/authService";

import { resolveAdminApiBaseUrl } from "utils";

const baseApi = resolveAdminApiBaseUrl();

let API = baseApi + "/api/v1/admin/contractors";

const customerid = getCustomerID();
const aosVersion = getCurrentAosVersion();

const contractors = axios.create({
  baseURL: API,
  headers: { "x-aos-customerid": customerid, "x-aos-version": aosVersion },
});

contractors.defaults.headers.post["Content-Type"] = "application/json";
contractors.defaults.headers["Access-Control-Allow-Origin"] = "*";

contractors.interceptors.request.use(
  config => {
    const token = authService.getToken();
    const organization = authService.getOrg();
    config.params = config?.params || {};

    if (organization && !config?.ignoreOrgId) {
      config.params["organizationId"] = organization;
    }
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    authService.tokenExpired(err);
    return Promise.reject(err);
  }
);

contractors.interceptors.response.use(
  config => config,
  err => {
    authService.tokenExpired(err);
    return Promise.reject(err);
  }
);

export default contractors;
