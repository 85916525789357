import { FormInput } from "components/inputs";
import { VisibilityWrapper } from "components/layout/visibilityWrapper";
import { CreatableSelect, Select } from "components/select";
import { useFormikContext } from "formik";
import { Box, Flex } from "organic";
import React from "react";
import { appGet } from "utils";
import {
  PSYCHOMETRIC_TEST_QUESTIONNAIRE,
  handleAnswerOptionsChange,
  keyBoardTypes,
} from "utils/questionnaire-utils/questionnaire-utils";
import { selectOptionFromValue } from "utils/v1/selectUtils";

const QuestionForm = ({ selected, currentQuestionIndex, currentQuestionKey, isReadOnly }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <>
      {/* Question Title */}
      <Flex flexDirection="column" px="24px">
        <Box mt="1.25rem">
          <FormInput
            label="Question Title"
            placeholder="Question"
            name={`${currentQuestionKey}.${currentQuestionIndex}.question_title`}
            defaultValue={appGet(values, [currentQuestionKey, currentQuestionIndex, "question_title"])}
            required
            disabled={isReadOnly === true}
          />
        </Box>

        {/* Answer Options */}
        <Box mt="1.25rem">
          <CreatableSelect
            isMulti
            isClearable
            label="Add Answer Option"
            placeholder={
              appGet(values, [currentQuestionKey, currentQuestionIndex, "answer_options"], []).length === 0
                ? "Start Typing an Answer Option"
                : "Select Answer Options"
            }
            // name={`${currentQuestionKey}.${currentQuestionIndex}.answer_options`}
            formatCreateLabel={inputVal => `Hit Enter to Add Option "${inputVal}"`}
            components={{ DropdownIndicator: null }}
            // options={values.questions[currentQuestionIndex].answer_options}
            // isValidNewOption={() => true}
            value={appGet(values, [currentQuestionKey, currentQuestionIndex, "answer_options"])}
            getOptionLabel={option => option.text || option.label}
            getOptionValue={option => option.key || option.value}
            onChange={(newValue, actionMeta) =>
              handleAnswerOptionsChange(newValue, {
                actionMeta,
                setFieldValue,
                currentQuestionIndex,
                currentQuestionKey,
                values,
              })
            }
            isDisabled={isReadOnly === true}
          />
        </Box>
        <>
          {/* Correct Answers */}
          <VisibilityWrapper isVisible={values.model === PSYCHOMETRIC_TEST_QUESTIONNAIRE}>
            <Box mt="1.25rem">
              <Select
                isMulti
                label="Correct Answer(s)"
                placeholder="Select Correct Answers(s)"
                // name={`${currentQuestionKey}.${currentQuestionIndex}.correct_answer`}
                options={appGet(values, [currentQuestionKey, currentQuestionIndex, "answer_options"])}
                getOptionLabel={option => option.text}
                getOptionValue={option => option.key}
                value={appGet(values, [currentQuestionKey, currentQuestionIndex, "correct_answer"])}
                required={selected?.model === PSYCHOMETRIC_TEST_QUESTIONNAIRE}
                onChange={val => {
                  if (!val) {
                    setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.correct_answer`, []);
                    return;
                  }
                  setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.correct_answer`, val);
                }}
              />
            </Box>
          </VisibilityWrapper>
        </>
        {/* keyboard Type */}
        <Box mt="1.25rem">
          <Select
            required
            defaultValue={
              appGet(values, [currentQuestionKey, currentQuestionIndex, "keyboardType"])
                ? selectOptionFromValue(
                    keyBoardTypes,
                    appGet(values, [currentQuestionKey, currentQuestionIndex, "keyboardType"])
                  )
                : keyBoardTypes[0]
            }
            label="Keyboard Type"
            // name={`${currentQuestionKey}.${currentQuestionIndex}.keyboardType`}
            options={keyBoardTypes}
            value={selectOptionFromValue(
              keyBoardTypes,
              appGet(values, [currentQuestionKey, currentQuestionIndex, "keyboardType"])
            )}
            onChange={val => setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.keyboardType`, val)}
            isDisabled={isReadOnly === true}
          />
        </Box>

        {/* Max Length */}
        <Box mt="1.25rem">
          <FormInput
            required
            label="Max Length"
            type="number"
            min="0"
            step="1"
            placeholder="Enter maximum length for text answer"
            name={`${currentQuestionKey}.${currentQuestionIndex}.maxLength`}
            defaultValue={appGet(values, [currentQuestionKey, currentQuestionIndex, "maxLength"])}
            disabled={isReadOnly === true}
          />
        </Box>
        {/* Min Length */}
        <Box mt="1.25rem">
          <FormInput
            required
            label="Min Length"
            type="number"
            min="1"
            step="1"
            placeholder="Enter maximum length for text answer"
            name={`${currentQuestionKey}.${currentQuestionIndex}.minLength`}
            defaultValue={appGet(values, [currentQuestionKey, currentQuestionIndex, "minLength"])}
            disabled={isReadOnly === true}
          />
        </Box>
      </Flex>
    </>
  );
};

export default QuestionForm;
