import { toast } from "react-toastify";

export async function handleFormSubmission({
  submitFn,
  values,
  config = {},
  onSuccess,
  onError,
  errorMsg,
  errorProps = {},
  successMsg,
}) {
  try {
    const response = await submitFn?.(values);
    if (successMsg) {
      toast.success(successMsg);
    }
    onSuccess?.(response);
    config?.modal?.hideModal?.();
  } catch (error) {
    const msg = errorProps?.message || errorMsg;

    if (error.response && error.response.status) {
      const statusCode = error.response.status;

      switch (statusCode) {
        case 400:
          toast.error(`${errorProps?.msg400 || "Bad Request"} `);
          break;
        case 401:
          toast.error(`${errorProps?.msg401 || "Unauthorized"}`);
          break;
        // Add more cases for different error codes as needed
        default:
          toast.error(`${msg}`);
      }
    } else {
      toast.error(`${msg} `);
    }

    onError?.(error);
  }
}
