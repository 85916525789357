import React from "react";
import Card from "components/card/card";
import Chart from "components/chart/chart";
import { CHART_HEADER_HEIGHT, ChartHeader } from "components/chart/chartHeader";
import { Box, organicTheme } from "organic";
import { uniqueId } from "utils";
import { useSelector } from "react-redux";
import { getOverviewNewFarmersCount, getOverviewOldFarmersCount } from "features/v1/overview";

const ReturningVsNewFarmers = ({ cardHeight, chart, cardWidth = "auto" }) => {
  let newFarmers = useSelector(getOverviewNewFarmersCount);
  let oldFarmers = useSelector(getOverviewOldFarmersCount);

  const { type, title, subtitle, height, width } = chart;
  return (
    <Card height={cardHeight} width={cardWidth} stackHorizontal title={title}>
      <Box height={CHART_HEADER_HEIGHT}>
        {[
          {
            subtitle,
            value: newFarmers,
          },
        ].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Box>
      <Chart
        data={[
          { name: "Returning", value: oldFarmers, fill: organicTheme.colors.success500 },
          { name: "New", value: newFarmers, fill: organicTheme.colors.success100 },
        ]}
        type={type}
        height={height}
        width={width}
      />
    </Card>
  );
};

export default ReturningVsNewFarmers;
