import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterDateRange,
  getFilterRegion,
  getFilterSeasonId,
  getFilterZone,
  setRegion,
  setSeasonId,
} from "features/v1/filter";
import { useLegacyDidMountEffect } from "hooks";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import { getRegionOptions } from "features/v1/regions";
// import { toast } from "react-toastify";
// import { INFO_TOAST_CONTAINER } from "index";
import { Flex } from "organic";
import { ProjectSelect } from "components/select";
import { RegionSelect, selectConstants } from "components/select";
import { DEMO_MODE_KEY } from "constants/demoConstants";

const ProjectFilter = props => {
  const { ignoreRegion = false, ignoreSeason, noRegion = false, noSeason = false, handleFilterWrapper } = props;

  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();

  const regionOptions = useSelector(getRegionOptions);
  const seasonId = useSelector(getFilterSeasonId);
  const dateRange = useSelector(getFilterDateRange);
  const region = useSelector(getFilterRegion);
  const zone = useSelector(getFilterZone);

  useLegacyDidMountEffect(() => {
    // if (!dateRange && !ignoreDateRange) return setErrorMsg("Kindly Select a Date Range");
    // if (!seasonId && !ignoreSeason) return setErrorMsg("Kindly Select a Season");
    // if (!region && !ignoreRegion) return setErrorMsg("Kindly Select a Region");
    // if (!zone) return setErrorMsg("Kindly Select a Zone");
    setErrorMsg("");
    //TODO: soon to be deprecated in favour of useCustomFilter hook
    if (handleFilterWrapper) {
      handleFilterWrapper({
        dateRange,
        seasonId,
        // zone,
        region,
      });
    }
  }, [dateRange, seasonId, zone, region]);

  // const generateUniqueLoanIds = useCallback(async () => {
  //   try {
  //     const idsGenerated = await farmersApi.patch("assignUniqueFarmerId", null, { params: { seasonId } });
  //     if (idsGenerated?.data?.message?.includes("No Unassigned Farmer available")) {
  //       return;
  //     }
  //     // const successMessage = idsGenerated.data.message || idsGenerated.data || "Farmers Updated Successfully";
  //     // toast(successMessage, { position: "bottom-left", containerId: INFO_TOAST_CONTAINER });
  //   } catch (generateUniqueLoanIdsError) {
  //     handleActionError(generateUniqueLoanIdsError);
  //   }
  // }, [seasonId]);

  // useLegacyDidMountEffect(() => {
  //   if (seasonId && !ignoreSeason) {
  //     generateUniqueLoanIds();
  //   }
  // }, [seasonId]);

  const handleOverviewSeasonChange = async _seasonId => {
    try {
      if (!ignoreSeason) {
        dispatch(setSeasonId(_seasonId));
      }
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const isDemoMode = sessionStorage?.getItem(DEMO_MODE_KEY);

  const handleRegionChange = async regionVal => {
    if (regionVal?.value === "all" && !ignoreRegion) {
      dispatch(setRegion(regionOptions?.[0]));
      return;
    }
    let selectedRegionOption = selectOptionFromValue(regionOptions, regionVal);
    try {
      if (!ignoreRegion) {
        dispatch(setRegion(selectedRegionOption));
      }
    } catch (error) {
      setErrorMsg(error);
    }
  };

  return (
    <Flex>
      <Flex className="row">
        <Flex className="col-lg-12">
          <p className="text-center ca-font-regular">{errorMsg}</p>
        </Flex>
        <Flex className="col-lg-10">
          <Flex flexDirection="row" className="filter-wrapper">
            {!noSeason ? (
              <Flex marginLeft="4px">
                <ProjectSelect
                  useAll={isDemoMode}
                  colorScheme={selectConstants.colorSchemes.BLUE}
                  onChange={handleOverviewSeasonChange}
                />
              </Flex>
            ) : null}

            {!noRegion ? (
              <Flex marginLeft="0.5rem">
                <RegionSelect useAll={isDemoMode} value={region} onChange={handleRegionChange} />
              </Flex>
            ) : null}
          </Flex>
        </Flex>
        <Flex className="col-lg-2"></Flex>
      </Flex>
    </Flex>
  );
};

ProjectFilter.propTypes = {
  handleFilterWrapper: PropTypes.func,
  page: PropTypes.string,
  location: PropTypes.string,
};

ProjectFilter.defaultPropTypes = {
  handleFilterWrapper: () => {},
  page: "",
  location: "",
};

export { ProjectFilter };
