import { parsePhoneNumber, validatePhoneNumberLength as validateLength } from "libphonenumber-js";

export function isValidIntlPhoneNumber(phoneNumber) {
  // Define a regular expression for a valid international phone number
  const phoneRegex = /^\+\d{1,4}\s?\d{1,14}$/;

  // Test the provided phone number against the regular expression
  return phoneRegex.test(phoneNumber);
}

export function validatePhonNumberLength(...args) {
  return validateLength(...args);
}

export function isValidPhoneNumberForCountry(phoneNumberString, country) {
  const phoneNumber = parsePhoneNumber(phoneNumberString, {
    defaultCountry: country,
    // Demand that the entire input string must be a phone number.
    // Otherwise, it would "extract" a phone number from an input string.
    extract: false,
  });
  if (!phoneNumber) {
    return false;
  }
  if (phoneNumber.country !== country) {
    return false;
  }
  return phoneNumber.isValid();
}
