import React from "react";
import Card from "components/card/card";
import Chart from "components/chart/chart";
import { ChartHeader } from "components/chart/chartHeader";
import { Box, Flex } from "organic";
import { uniqueId } from "utils";
import { useSelector } from "react-redux";
import { getOverviewAgentChartData, getOverviewOverAllAgentCount } from "features/v1/overview";

const TotalAgents = ({ cardHeight, chart, __cardWidth = "auto" }) => {
  let data = useSelector(getOverviewAgentChartData);
  let totalAgents = useSelector(getOverviewOverAllAgentCount);

  const { type, title, subtitle, height, width } = chart;

  const yAxisLabel = "total agents";

  return (
    <Card height={cardHeight}>
      <Flex flexDirection="row">
        {[
          {
            title,
            subtitle,
            value: totalAgents,
          },
        ].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Flex>
      <Box position="relative" width={width}>
        <Chart data={data} type={type} height={height} width={width} yAxisLabel={yAxisLabel} />
      </Box>
    </Card>
  );
};

export default TotalAgents;
