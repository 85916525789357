import React, { forwardRef, useEffect, useRef } from "react";
import * as CheckboxPrimtives from "@radix-ui/react-checkbox";
import { Icon, organicTheme } from "organic";
import { classnames } from "utils";

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);
  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

const Checkbox = ({ label, id, defaultChecked, size, checked, onCheckedChange, disabled, required, name, value }) => {
  let chcekboxClassnames = classnames("checkbox", { "checkbox--sm": size === "sm", "checkbox--md": size === "md" });
  return (
    <div className="checkbox-wrapper">
      <CheckboxPrimtives.Root
        className={chcekboxClassnames}
        defaultChecked={defaultChecked}
        id={id}
        checked={checked}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
        required={required}
        name={name}
        value={value}
      >
        <CheckboxPrimtives.Indicator className="checkbox__indicator">
          <Icon
            icon="check"
            svgProps={{ strokeWidth: 2 }}
            size={size === "md" ? 14 : 12}
            color={organicTheme.colors.primary600}
          />
        </CheckboxPrimtives.Indicator>
      </CheckboxPrimtives.Root>
      <label className="checkbox__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Checkbox.displayName = "Checkbox";

Checkbox.defaultProps = {
  size: "sm",
};

export { IndeterminateCheckbox, Checkbox };
