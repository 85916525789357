import { ExportButton } from "components/export";
import { PageHeader, PageWrapper } from "components/layout";
import { PageTabsNav } from "components/pageTabs";
import { useDataFilter } from "hooks";
import farmersApi from "api/farmers";
import { Flex } from "organic";
import React from "react";
import { generateCsvFilename } from "utils/export-utils/export-utils";

const OVERVIEW_TAB_ID = "mappingOverview";
const INSPECTION_TAB_ID = "mappingInspection";

const mappingPageTabs = [
  { title: "Overview", path: "mappingOverview", tabId: OVERVIEW_TAB_ID },
  { title: "Inspection", path: "inspection", tabId: INSPECTION_TAB_ID },
];

const MappingIndexPage = () => {
  const { filterParams } = useDataFilter();

  const quickFarmMappingExport = async () => {
    const data = (await farmersApi.get("downloadFarmMapper", { params: filterParams })).data;
    return data?.data || data;
  };

  const advancedFarmMappingExport = async ({ columnWhitelist, filterParams }) => {
    let params = { columnWhitelist: columnWhitelist, ...filterParams };
    const res = await farmersApi.get("downloadFarmMapper/advance", { params });
    return res;
  };

  const getFarmMappingExportKeys = async () => {
    return await farmersApi.get("downloadFarmMapper/getMetaKeys");
  };
  return (
    <PageWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <PageHeader title="Farm > Mapping" subtitle="your farmers summary and activity" />
        <ExportButton
          quickExportConfig={{
            apiCall: quickFarmMappingExport,
            fileName: generateCsvFilename("farmMapping"),
          }}
          advancedExportConfig={{
            apiCall: advancedFarmMappingExport,
            getKeys: getFarmMappingExportKeys,
          }}
        />
      </Flex>
      <PageTabsNav tabs={mappingPageTabs} />
    </PageWrapper>
  );
};

export default MappingIndexPage;
