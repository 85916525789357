import { FormErrorMessage } from "components/form";
import { Checkbox, SearchInput } from "components/inputs";
import { TruncatedList } from "components/list";
import { Popover, PopoverContent, PopoverTrigger } from "components/popover";
import { FieldArray, useFormikContext } from "formik";
import { useSearchAgents } from "hooks/app";

import { Avatar, AvatarGroup, Badge, Box, Button, Flex, Icon, Text, organicTheme } from "organic";
import React, { useState } from "react";

export const AssignAgentsField = () => {
  const [searchText, setSearchText] = useState("");
  const [assignedAgentPhoneNumbers, setAssignedAgentPhoneNumbers] = useState([]);

  const { values, setFieldValue } = useFormikContext();
  const { data: agentsData, isLoading: isLoadingAgents } = useSearchAgents({ searchText });

  const updateSearch = val => {
    if (val?.length) {
      setSearchText(val);
    }
  };

  // function to find agent in array
  const findAgent = agent => {
    // spread array to make a copy
    const _agents = [...values.assigned_agents] || [];
    const foundAgent = _agents.find(_agent => `${_agent.phone_number}` === `${agent.phone_number}`);
    return foundAgent ? foundAgent : null;
  };

  // function to remove agent from array
  const removeAgent = agent => {
    // spread array to make a copy
    let _agents = [...values.assigned_agents] || [];
    _agents = _agents.filter(_agent => `${_agent.phone_number}` !== `${agent.phone_number}`);
    setAssignedAgentPhoneNumbers(() => _agents.map(_agent => `${_agent.phone_number}`));
    setFieldValue("assigned_agents", _agents);
  };

  // function to add agent to array
  const addAgent = agent => {
    const _agents = [...values.assigned_agents] || [];
    _agents.push({
      id: agent._id,
      first_name: agent.first_name,
      last_name: agent.last_name,
      phone_number: agent.phone_number,
    });
    setAssignedAgentPhoneNumbers([...assignedAgentPhoneNumbers, agent.phone_number]);
    setFieldValue("assigned_agents", _agents);
  };

  // function to toggle selected agent
  const handleAgentSelection = ({ agent }) => {
    if (findAgent(agent)) {
      removeAgent(agent);
      return;
    } else {
      addAgent(agent);
    }
  };

  return (
    <div>
      <Text color="gray700" font="smMedium" textAlign="left" mb="8px">
        Assigned Agents
        <Text as="sup" color="primary500" font="smRegular">
          *
        </Text>
      </Text>
      <FieldArray
        name="assigned_agents"
        render={() => {
          return (
            <Popover className="agent-search">
              <PopoverTrigger asChild={false} className="agent-search__trigger">
                <SelectedAgents />
              </PopoverTrigger>
              <FormErrorMessage name="assigned_agents" />
              <PopoverContent asChild>
                <Box
                  width="var(--radix-popper-anchor-width)"
                  height="296px"
                  boxShadow="0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)"
                  bg="white"
                  overflowY="auto"
                >
                  <SearchInput onChange={updateSearch} className="agent-search__input" />
                  <Flex px="18px" pt="1rem" gap="1rem" flexDirection="column">
                    <Text color="gray600" font="smMedium">
                      {searchText?.length
                        ? `Searching for ${searchText} found ${agentsData?.meta?.totalDocCount || 0} agent(s)`
                        : "All Agents"}
                    </Text>
                    {isLoadingAgents ? <Badge>loading...</Badge> : null}
                    <Flex flexDirection="column" gap="1rem" overflowY="auto">
                      {agentsData?.data?.map?.(agent => {
                        return (
                          <Button
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              e.nativeEvent.stopImmediatePropagation();
                              e.nativeEvent.stopPropagation();
                              e.nativeEvent.stopImmediatePropagation();
                              e.nativeEvent.stopPropagation();
                              handleAgentSelection({ agent });
                            }}
                            isDisabled={isLoadingAgents}
                            key={agent.phone_number}
                            mt="4px"
                            variant="unstyled"
                            type="button"
                          >
                            <Checkbox
                              isDisabled
                              checked={assignedAgentPhoneNumbers.includes(`${agent.phone_number}`)}
                            />
                            <Flex alignItems="center" gap="0.5rem">
                              <Avatar name={`${agent?.first_name ?? "-"} ${agent?.last_name ?? "-"}`} />
                              <Text as="span" color="gray900" font="smMedium">
                                {agent.first_name} {agent.last_name}
                              </Text>
                              <Text as="span" color="gray600" font="smRegular">
                                {agent.phone_number}
                              </Text>
                            </Flex>
                          </Button>
                        );
                      })}
                    </Flex>
                  </Flex>
                </Box>
              </PopoverContent>
            </Popover>
          );
        }}
      />
    </div>
  );
};

const SelectedAgents = () => {
  const { values } = useFormikContext();

  return (
    <Flex gap="0.5rem" zIndex={9999} height="44px" alignItems="center" px="14px">
      <TruncatedList
        limit={4}
        items={values?.assigned_agents || []}
        renderItem={(agent, __) => (
          <AvatarGroup>
            <Avatar
              key={agent.phone_number}
              size="xs"
              name={`${agent.first_name?.trim?.()} ${agent.last_name?.trim?.()}`}
              tooltipContent={
                <Flex bg="white" p="1rem">
                  <Text font="smRegular">
                    {agent.first_name?.trim?.()} {agent.last_name?.trim?.()} ({agent.phone_number})
                  </Text>
                </Flex>
              }
            />
          </AvatarGroup>
        )}
      />
      {!values?.assigned_agents?.length && (
        <Flex flexDirection="column" gap="6px" justifyContent="flex-start">
          <Flex gap="0.5rem" height="44px" alignItems="center" px="14px">
            <Icon icon="user" color={organicTheme.colors.gray500} /> <Text color="gray500">Select Agents</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
