const { assignAliasToScale } = require("organic/utils/aliases");

const scale = [
  "0rem", // "0px"
  "0.25rem", // "4px"
  "0.5rem", // "8px"
  "0.75rem", // "12px"
  "1rem", // "16px"
  "1.25rem", // "20px"
  "1.5rem", // "24px"
  "2rem", // "32px"
  "2.5rem", // "40px"
  "3rem", // "48px"
  "4rem", // "64px"
  "5rem", // "80px"
  "6rem", // "96px"
  "8rem", // "128px"
  "10rem", // "160px"
  "12rem", // "192px"
  "14rem", // "224px"
  "16rem", // "256px"
];
const aliases = [
  "0rem",
  "0.25rem",
  "0.5rem",
  "0.75rem",
  "1rem",
  "1.25rem",
  "1.5rem",
  "2rem",
  "2.5rem",
  "3rem",
  "4rem",
  "5rem",
  "6rem",
  "8rem",
  "10rem",
  "12rem",
  "14rem",
  "16rem",
];
export { scale as spacingScale, aliases as spacingAliases };
const spacing = assignAliasToScale(scale, aliases);
export default spacing;
