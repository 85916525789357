import React from "react";
import Card from "components/card/card";
import Chart from "components/chart/chart";
import { ChartHeader } from "components/chart/chartHeader";
import { Box } from "organic";
import { getPercentageOf, uniqueId } from "utils";
import { useSelector } from "react-redux";
import { getOverviewAverageFarmersPerAgent, getOverviewAvergageFarmerPerAgentTarget } from "features/v1/overview";

const AverageFarmerPerAgent = ({ cardHeight, chart, cardWidth = "auto" }) => {
  let averageFarmersPerAgent = useSelector(getOverviewAverageFarmersPerAgent);
  let averageFarmerPerAgentTarget = useSelector(getOverviewAvergageFarmerPerAgentTarget);

  const averageFarmerPerAgentTargetPercent = getPercentageOf({
    achieved: averageFarmersPerAgent,
    target: averageFarmerPerAgentTarget,
  });

  const { type, title, subtitle, height, width } = chart;
  return (
    <Card height={cardHeight} width={cardWidth} stackHorizontal title={title}>
      <Box>
        {[{ subtitle, value: averageFarmersPerAgent }].map(headerProps => {
          return <ChartHeader key={uniqueId()} {...headerProps} />;
        })}
      </Box>
      <Chart
        data={{
          name: "Target",
          value: averageFarmerPerAgentTarget,
          progress: averageFarmerPerAgentTargetPercent,
        }}
        type={type}
        height={height}
        width={width}
      />
    </Card>
  );
};

export default AverageFarmerPerAgent;
