import dayjs from "dayjs";
import _ from "lodash";

/**
 * A function to display a date in human readable format
 * @param  {Date} date Date object or ISOstring format of js Date object
 * @returns {String} readbale string format of date param
 */
export const legacyFormatDate = (date, format = "ddd, MMM D, YYYY h:mm A") => {
  return dayjs(date).format(format);
};

/**
 * A function to display a date in human readable format
 * @param  {string} amount Date object or ISOstring format of js Date object
 * @returns {string} readbale string format of date param
 */

export const formatAmount = function (n, dp) {
  var e = "",
    s = e + n,
    l = s.length,
    b = n < 0 ? 1 : 0,
    i = s.lastIndexOf("."),
    j = i == -1 ? l : i,
    r = e,
    d = s.substr(j + 1, dp);
  while ((j -= 3) > b) {
    r = "," + s.substr(j, 3) + r;
  }
  return s.substr(0, j + 3) + r + (dp ? "." + d + (d.length < dp ? "00000".substr(0, dp - d.length) : e) : e);
};

/**
 * a function to display a user friendly format for AOS roles
 * @param  {string} roleValue - a string value of role as recorded in DB
 * @returns  {string} roleFormat - a string value of role in a
 *                                User-friendly format or "N/A"
 *
 */

export const DEPRECATED_formatRole = function (roleValue) {
  if (_.isString(roleValue) && !_.isEmpty(roleValue)) {
    switch (roleValue) {
      case "lead_farmer":
        return "Lead Farmer";
      case "secretary":
        return "Secretary";
      case "farmer":
        return "Farmer";
      case "agent":
        return "Agent";
      case "zonalManager":
        return "Zonal Manager";
      case "regionalManager":
        return "Regional Manager";
      case "finance":
        return "Finance";
      case "storeManager":
        return "Store Manager";
      case "operationalManager":
        return "Operational Manager";
      case "subAdmin":
        return "Sub Admin";
      case "admin":
        return "Admin";
      case "superAdmin":
        return "Super User";
      default:
        return "";
    }
  }

  return "N/A";
};

/** Format Gender value */
export const DEPRECATED_formatGender = genderValue => {
  if (genderValue === "male" || genderValue === "Male") return "Male";
  if (genderValue === "female" || genderValue === "Female") return "Female";
  return "";
};
