import React, { useState } from "react";
import styled from "styled-components";
import { Avatar, Badge, Box, Button, Flex, Icon, Text, badgeConstants, organicTheme } from "organic";
import { truncateText } from "utils";

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eaecf0;
`;

const SearchIconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 16px 16px 16px 42px;
  font-size: 16px;
  flex: 1;
`;

const SearchButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const AddExistingFarmersModal = ({ handleSubmit }) => {
  const [searchText, setSearchText] = useState("");
  const [existingFarmers, setExistingFarmers] = useState([
    {
      id: 1,
      avatar: "",
      name: "Phoenix Baker",
      ta_number: "TA488488493",
      checked: false,
    },
    {
      id: 2,
      avatar: "",
      name: "Olivia Rhye",
      ta_number: "TA13944384",
      checked: false,
    },
    {
      id: 3,
      avatar: "",
      name: "Lana Steiner",
      ta_number: "TA488488493",
      checked: false,
    },
  ]);

  const selected = existingFarmers?.filter(farmer => farmer.checked);

  const handleSearchMembers = () => {};

  const handleChange = id => {
    setExistingFarmers(
      existingFarmers.map(farmer => {
        if (farmer.id === id) {
          return { ...farmer, checked: !farmer.checked };
        }
        return farmer;
      })
    );
  };

  return (
    <Box>
      <InputContainer>
        <SearchIconContainer>
          <Icon icon="search" color={organicTheme.colors.gray500} size={20} />
        </SearchIconContainer>
        <Input value={searchText} placeholder="Search" onChange={e => setSearchText(e.target.value)} />

        <SearchButtonContainer>
          <Button onClick={handleSearchMembers} display="block" type="button" isDisabled={searchText.length < 4}>
            Search
          </Button>
        </SearchButtonContainer>
      </InputContainer>
      {searchText.length < 4 && (
        <Text ml="16px" as="i" color="red" font="xsMedium" textAlign="center">
          Enter at least 4 characters to search
        </Text>
      )}
      <Box p="16px" backgroundColor="white">
        <Text color="gray600" mb="20px" font="smMedium">
          Members
        </Text>
        {existingFarmers?.length ? (
          <Flex flexDirection="column" gap="10px">
            {existingFarmers.map((farmer, i) => {
              return (
                <Flex key={i} as="label" gap="20px" alignItems="center" justifyContent="space-between">
                  <Flex gap="20px" alignItems="center">
                    <Avatar name={farmer.name ? farmer.name : "--"} size="xs" />
                    <Text font="smMedium" color="primary900" whiteSpace="no-wrap">
                      {farmer.name ? truncateText(farmer.name, 15) : "--"}
                    </Text>
                    <Text font="smRegular" color="#475467">
                      {farmer.ta_number || "no TA number"}
                    </Text>
                  </Flex>
                  <input type="checkbox" checked={farmer.checked} onChange={() => handleChange(farmer.id)} />
                </Flex>
              );
            })}
          </Flex>
        ) : (
          <Text as="i" color="gray600" font="smMedium" textAlign="center">
            No members match your search
          </Text>
        )}

        {selected.length ? (
          <>
            <Box mt="20px">
              <Text color="gray600" font="smMedium">
                Selected
              </Text>
              <Flex mt="15px" gap="8px" flexWrap="wrap">
                {selected.map((s, index) => {
                  return (
                    <Badge key={index} colorScheme={badgeConstants.colorSchemes.SUCCESS}>
                      {s.name}
                    </Badge>
                  );
                })}
              </Flex>
            </Box>
            <Box mt={10}>
              <Button onClick={handleSubmit} display="block" type="button">
                Continue
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default AddExistingFarmersModal;
