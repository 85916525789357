import { toast } from "react-toastify";
import { authChecker } from "../../services/v1/authService";
import survey from "api/riskManagement";
import riskAgent from "api/riskAgent";
import agents from "api/agents";

const SET_ALL_RISK_SURVEY = "SET_ALL_RISK_SURVEY";
const LOAD_RISK_SURVEY = "LOAD_ALL_RISK_SURVEY";
const LOAD_AGENT_FARMERS = "LOAD_AGENT_FARMERS";
const SET_RISK_SURVEY_BY_ID = "SET_RISK_SURVEY_BY_ID";
const SET_ALL_RISK_AGENTS = "SET_ALL_RISK_AGENTS";
const SET_RISK_SURVEY_BY_TA_NUMBER = "SET_RISK_SURVEY_BY_TA_NUMBER";
const SEARCH_AGENT_FARMERS = "SEARCH_AGENT_FARMERS";
const UPDATE_RISK_AGENT = "UPDATE_RISK_AGENT";
const SET_ACTIVE_RISK_AGENT = "SET_ACTIVE_RISK_AGENT";
const SET_SELECTED_AGENT_FARMERS = "SET_SELECTED_AGENT_FARMERS";
const RESET_AGENT_FARMERS_LIST = "RESET_AGENT_FARMERS_LIST";
const RESET_SELECTED_AGENT_FARMERS = "RESET_SELECTED_AGENT_FARMERS";

export const getAllRiskSurvey = () => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: true,
    });

    const res = await survey.get("getAllRiskQes");

    const surveyMeta = {
      totalRiskSurvey: res?.data?.totalRiskSurvey,
      totalValidatedFarmers: res?.data?.totalValidatedFarmers,
      totalFlaggedFarmers: res?.data?.totalFlaggedFarmers,
    };
    // .filter(_questionnaireOption => _questionnaireOption.value.model === "survey");
    dispatch({
      type: SET_ALL_RISK_SURVEY,
      payload: res?.data?.data || [],
      surveyMeta,
    });
  } catch (error) {
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong fetching survey, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(
        error?.response?.data?.message ?? "Something went wrong fetching survey, try refreshing the page"
      );
    }
    toast.error("Something went wrong fetching survey, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: false,
    });
  }
};

export const getSurveyById = id => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: true,
    });

    const res = await survey.get(`getRiskSurveyById?id=${id}`);

    const surveyMeta = {
      surveyName: res?.data?.name,
      totalValidatedFarmers: res?.data?.totalValidatedFarmers,
      totalFlaggedFarmers: res?.data?.totalFlaggedFarmers,
      totalResponse: res?.data?.totalResponse,
    };
    // .filter(_questionnaireOption => _questionnaireOption.value.model === "survey");
    dispatch({
      type: SET_RISK_SURVEY_BY_ID,
      payload: res?.data?.farmers || [],
      surveyMeta,
    });
  } catch (error) {
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong fetching survey, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(
        error?.response?.data?.message ?? "Something went wrong fetching survey, try refreshing the page"
      );
    }
    toast.error("Something went wrong fetching survey, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: false,
    });
  }
};

export const getAllRiskAgents = () => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: true,
    });

    const res = await riskAgent.get("getAllRiskAgent");

    // .filter(_questionnaireOption => _questionnaireOption.value.model === "survey");
    dispatch({
      type: SET_ALL_RISK_AGENTS,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong fetching risk agents, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(
        error?.response?.data?.message ?? "Something went wrong fetching risk agents, try refreshing the page"
      );
    }
    toast.error("Something went wrong fetching risk agents, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: false,
    });
  }
};

export const getRiskSurveyByTaNumber = taNumber => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: true,
    });

    const res = await survey.get("getRiskSurveyByTan", { params: { taNumber } });

    dispatch({
      type: SET_RISK_SURVEY_BY_TA_NUMBER,
      payload: res?.data?.allSurveys?.data || null,
    });
  } catch (error) {
    dispatch({
      type: SET_RISK_SURVEY_BY_TA_NUMBER,
      payload: null,
    });
    if (error.response?.data?.message) {
      return toast.error(error.response?.data?.message);
    }
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(error?.response?.data?.message ?? "Something went wrong, try refreshing the page");
    }
    toast.error("Something went wrong, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: false,
    });
  }
};

export const searchForAgentFarmers = farmerName => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_AGENT_FARMERS,
      payload: true,
    });

    const res = await agents.get("searchForAgentFarmers", { params: { searchText: farmerName } });

    dispatch({
      type: SEARCH_AGENT_FARMERS,
      payload: res?.data?.data.filter(Boolean) || null,
    });
  } catch (error) {
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(error?.response?.data?.message ?? "Something went wrong, try refreshing the page");
    }
    toast.error("Something went wrong, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_AGENT_FARMERS,
      payload: false,
    });
  }
};

export const updateRiskAgentData = (id, data) => async dispatch => {
  try {
    authChecker();
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: true,
    });

    const res = await riskAgent.patch("updateRiskAgent", { agent: data }, { params: { id } });

    dispatch({
      type: UPDATE_RISK_AGENT,
      // payload: res?.data?.data.filter(Boolean) || null,
    });

    if (res.data.status === "success") {
      toast.success("Agents assigned successfully");
    }
  } catch (error) {
    if (error.response === undefined) {
      return toast.error(error?.message ?? "Something went wrong, try refreshing the page");
    }
    if (error.response.status === 401) {
      return toast.error(error?.response?.data?.message ?? "Something went wrong, try refreshing the page");
    }
    toast.error("Something went wrong, try refreshing the page");
  } finally {
    dispatch({
      type: LOAD_RISK_SURVEY,
      payload: false,
    });
  }
};

export const setActiveRow = id => {
  return {
    type: SET_ACTIVE_RISK_AGENT,
    payload: id,
  };
};

export const setSelectedAgentFarmers = selected => {
  return {
    type: SET_SELECTED_AGENT_FARMERS,
    payload: selected,
  };
};

export const resetAgentFarmersList = () => {
  return {
    type: RESET_AGENT_FARMERS_LIST,
    payload: [],
  };
};

export const resetSelectedAgentFarmers = () => {
  return {
    type: RESET_SELECTED_AGENT_FARMERS,
    payload: [],
  };
};

const initialState = {
  surveyData: [],
  surveyMeta: [],
  surveyByIdData: [],
  surveyByIdMeta: [],
  riskAgents: [],
  riskSurveyByTaNumber: null,
  agentFarmers: [],
  selectedAgentFarmers: [],
  activeRiskAgent: null,
  loadingData: false,
  loadingAgentFarmers: false,
};

const riskManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_RISK_SURVEY:
      return {
        ...state,
        surveyData: action.payload,
        surveyMeta: action.surveyMeta,
      };
    case SET_RISK_SURVEY_BY_ID:
      return {
        ...state,
        surveyByIdData: action.payload,
        surveyByIdMeta: action.surveyMeta,
      };
    case SET_ALL_RISK_AGENTS:
      return {
        ...state,
        riskAgents: action.payload,
      };
    case SET_RISK_SURVEY_BY_TA_NUMBER:
      return {
        ...state,
        riskSurveyByTaNumber: action.payload,
      };
    case SEARCH_AGENT_FARMERS:
      return {
        ...state,
        agentFarmers: action.payload,
      };
    case RESET_AGENT_FARMERS_LIST:
      return {
        ...state,
        agentFarmers: action.payload,
      };
    case UPDATE_RISK_AGENT:
      return {
        ...state,
      };
    case SET_ACTIVE_RISK_AGENT:
      return {
        ...state,
        activeRiskAgent: action.payload,
      };
    case SET_SELECTED_AGENT_FARMERS:
      return {
        ...state,
        selectedAgentFarmers: action.payload,
      };
    case RESET_SELECTED_AGENT_FARMERS:
      return {
        ...state,
        selectedAgentFarmers: action.payload,
      };
    case LOAD_RISK_SURVEY:
      return { ...state, loadingData: action.payload };
    case LOAD_AGENT_FARMERS:
      return { ...state, loadingAgentFarmers: action.payload };
    default:
      return state;
  }
};

export default riskManagementReducer;

// selector functions
export const getLoadingSurvey = state => state.legacyRiskManagement.loadingData;
export const getLoadingAgentFarmers = state => state.legacyRiskManagement.loadingAgentFarmers;
export const getRiskSurveyData = state => state.legacyRiskManagement.surveyData;
// export const getQuestionnaireOptions = state => state.legacyRiskManagement.questionnaireOptions;
export const getRiskSurveyMeta = state => state.legacyRiskManagement.surveyMeta;
export const getRiskSurveyByIdData = state => state.legacyRiskManagement.surveyByIdData;
export const getRiskSurveyByIdMeta = state => state.legacyRiskManagement.surveyByIdMeta;
export const getAllRiskAgentsData = state => state.legacyRiskManagement.riskAgents;
export const getRiskSurveyByTANumber = state => state.legacyRiskManagement.riskSurveyByTaNumber;
export const getAgentFarmers = state => state.legacyRiskManagement.agentFarmers;
export const getActiveRiskAgent = state => state.legacyRiskManagement.activeRiskAgent;
export const getSelectedAgentFarmers = state => state.legacyRiskManagement.selectedAgentFarmers;
