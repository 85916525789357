import { FormInput } from "components/inputs";
import { Divider } from "components/layout";
import { CountrySelect, LGASelect, RegionSelect, StateSelect, selectConstants } from "components/select";
import { StoreManagerSelect } from "components/select/storeManagerSelect";
import FormGridTitle from "components/v1/Forms/FormGridTitle";
import { assignStoreMangerToWarehouse } from "features/v1/warehouse";
import { Form, Formik } from "formik";
import { Badge, Box, Button, Flex, Text, badgeConstants } from "organic";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const initiateUpdateWarehouseValues = selectedWarehouse => ({
  store: {
    ta_number: selectedWarehouse?.store?.ta_number | "",
    name: selectedWarehouse?.store?.name || "",
    state: selectedWarehouse?.store?.state || "",
    lga: selectedWarehouse?.store?.lga || "",
  },
  capacity: selectedWarehouse?.capacity || "",
  inventory: selectedWarehouse?.inventory || "",
  region: selectedWarehouse?.region || "",
});

const EditStoreDetails = ({ selected, modal }) => {
  const initialValues = initiateUpdateWarehouseValues(selected);

  const [selectedCountry, setSelectedCountry] = useState();

  const dispatch = useDispatch();

  const handleAssignStoreManager = selectedManagerId => {
    dispatch(
      assignStoreMangerToWarehouse(
        {
          warehouseId: selected._id,
          storeManagerId: selectedManagerId,
        },
        { modal: modal }
      )
    );
  };

  return (
    <Box>
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue }) => (
          <Form>
            <Flex flexDirection="column" gap="1.5rem">
              <Box>
                <FormInput name="store.ta_number" label="Store ID" />
              </Box>
              <Box>
                <FormInput name="store.name" label="Store Name" />
              </Box>
              <Box>
                <CountrySelect onChange={setSelectedCountry} />
              </Box>
              <Box>
                <StateSelect
                  name="store.state"
                  countryName={selectedCountry}
                  label="State"
                  defaultValue={values.store.state}
                  onChange={val => setFieldValue("store.state", val)}
                />
              </Box>
              <Box>
                <LGASelect
                  name="store.lga"
                  countryName={selectedCountry}
                  state={values.store.state}
                  label="LGA / District"
                  defaultValue={values.store.lga}
                  onChange={val => setFieldValue("store.lga", val)}
                />
              </Box>
              <Box>
                <RegionSelect
                  name="region"
                  countryName={selectedCountry}
                  state={values.store.state}
                  label="Region"
                  defaultValue={values.region}
                  colorScheme={selectConstants.colorSchemes.DEFAULT}
                  onChange={val => setFieldValue("region", val)}
                />
              </Box>
              <Box>
                <Button type="submit" display="block">
                  Save
                </Button>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
      <Flex mt="2rem" gap="1rem" width="100%" flexDirection="column">
        <FormGridTitle title="Assign Store Manager" />
        <Divider />
        <StoreManagerSelect label="Store Manager" onChange={handleAssignStoreManager} />
        <Flex gap="1rem">
          <Text color="gray400" font="smMedium">
            Current Store Manager:
          </Text>
          <Badge
            colorScheme={
              (selected?.user?.length && badgeConstants.colorSchemes.PRIMARY) || badgeConstants.colorSchemes.GRAY
            }
          >
            {selected.user.length > 0
              ? `${selected?.user[0]?.name} (${selected?.user[0]?.phone_number})`
              : "No Assigned Manager"}
          </Badge>
        </Flex>
      </Flex>
    </Box>
  );
};

export default EditStoreDetails;
