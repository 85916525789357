import React from "react";
import CustomButton from "./CustomButton";

const AOSFormButton = props => {
  const { disabled, onClick, text, children, type } = props;

  return (
    <CustomButton
      onClick={onClick}
      type={type || "submit"}
      className="form-btn-1 ca-font-regular"
      disabled={disabled}
      text={text || undefined}
    >
      {children ? children : undefined}
    </CustomButton>
  );
};

export default AOSFormButton;
