import { EmptyState } from "components/appState";
import DataGridRow from "components/v1/DataGrid/DataGridRow";
import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
import { Flex, Text } from "organic";
import React from "react";
import { uniqueId } from "utils";
import { legacyFormatDate } from "utils/v1/tableUtils";

const ViewSurveyDetails = ({ selected }) => {
  return (
    <div>
      <DataGridTitle title="Survey Details" />
      <DataGridRow
        fields={[
          {
            label: "TA Number",
            value: selected?.ta_number,
          },
          {
            label: "Created",
            value: legacyFormatDate(selected?.created_at),
          },
        ]}
      />

      <DataGridTitle title="Survey Feedback" />
      <Flex flexDirection="column">
        {(Object.values(selected?.feedback)?.length && (
          <>
            {Object.keys(selected.feedback).map((feedbackKey, feedbackIndex) => (
              <Flex flexDirection="column" mt="1rem" key={uniqueId()}>
                <Text font="smMedium" color="gray600">
                  {feedbackIndex + 1}. {feedbackKey}
                </Text>
                <Text font="mdRegular">{selected.feedback[feedbackKey].join(", ")}</Text>
              </Flex>
            ))}
          </>
        )) || <EmptyState title="No Survey feedback" />}
      </Flex>
    </div>
  );
};

export default ViewSurveyDetails;
