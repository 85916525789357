/* eslint-disable no-unused-vars */
import React from "react";
import { PageHeader, PageWrapper } from "components/layout";
// import { ExportButton } from "components/export";
import { Table } from "components/table";
import { format } from "date-fns";

// hooks
import { uniqueId } from "utils";
import {
  useDataFilter,
  // useDisclosure,
  useTable,
} from "hooks";

import { Box, Flex, Icon, organicTheme, Text } from "organic";
import PieMetricCard from "components/card/pieMetricCard";
import { useNavigate } from "react-router-dom";
import { useGetFinanceReportingOverview } from "hooks/app/financeReporting/useGetFinanceReportingOverview";
import { useGetInvoice } from "hooks/app/marketplace";
import LongBarMetricCard from "components/card/longBarMetricCard";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Card from "components/card/card";

const FinanceReporting = () => {
  const navigate = useNavigate();
  const { filterParams } = useDataFilter();

  // eslint-disable-next-line no-unused-vars
  const { data: financeReportingOverviewData, isLoading: isfinanceReportingOverviewLoading } =
    useGetFinanceReportingOverview({
      params: {
        dateRange: filterParams.dateRange,
      },
    });

  const total_cost_of_sales = financeReportingOverviewData?.totalCostofSales || 0;
  const total_revenue = financeReportingOverviewData?.totalRevenue || 0;
  const total_gross_profit = financeReportingOverviewData?.totalGrossProfit || 0;
  const gross_profit_percentage = financeReportingOverviewData?.grossProfitPercentage?.toFixed(2) || 0;

  // table data
  // eslint-disable-next-line no-unused-vars
  const { data: financeReportingTableData, isLoading: isfinanceReportingOTableLoading } = useGetInvoice({
    params: {
      dateRange: filterParams.dateRange,
    },
  });

  const { tableProps, selectedRow: selectedTrade } = useTable({
    columns: [
      {
        id: "batchID",
        Header: "Batch ID ",
        accessor: "trade_id",
        Cell: ({ value }) => (
          <Text color="gray900" font="smSemiBold">
            {`${value}`}
          </Text>
        ),
      },
      {
        idFunction: uniqueId(),
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => <Text color="primary600" font="smSemiBold">{`${value}`}</Text>,
      },

      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value }) => {
          const formattedDate = format(new Date(value), "yyyy-MM-dd hh:mm a");

          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Total Quantity (KG)",
        accessor: "total_quantity",
      },
      {
        Header: "Total Amount (KES)",
        accessor: "total_amount",
      },
    ],
    data: financeReportingTableData?.data?.data || [],
    title: "All payments",
    useServerPagination: true,
    noEditAction: true,
    showCheckboxes: true,
  });

  // if (isfinanceReportingOverviewLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <PageWrapper>
      {/* <pre>
        <code>{JSON.stringify(financeReportingTableData?.data, null, 3)}</code>
      </pre> */}
      <Flex flexDirection="row" justifyContent="space-between">
        <PageHeader title={`Finance > Reporting`} subtitle="Your finance summary and activity."></PageHeader>

        {/* <ExportButton /> */}
      </Flex>

      <Box height="280px">
        {/* CARD WRAPPER */}
        <Flex
          flexDirection={["column", null, null, null, "row"]}
          alignItems="center"
          gap="2rem"
          height="82%"
          marginTop="30px"
        >
          {/* LEFT CARD CONTAINER*/}
          <Flex
            width="45%"
            height="100%"
            outline={`1px solid ${organicTheme.colors.gray200}`}
            borderRadius="0.375rem"
            p="24px"
          >
            <Flex flexDirection="column" width="50%" outline={`1px solid ${organicTheme.colors.gray200}`}>
              <Flex gap="0.5rem" width="100%" height="50%" flexDirection="column" justifyContent="center" pl="24px">
                <Text color="gray500" font="smMedium" whitespace="no-wrap">
                  Total Revenue (KES)
                </Text>
                <Text color="gray900" font="displayXsBold">
                  {total_revenue.toLocaleString()}
                </Text>
              </Flex>

              <Flex
                gap="0.5rem"
                width="100%"
                height="50%"
                flexDirection="column"
                justifyContent="center"
                outline={`1px solid ${organicTheme.colors.gray200}`}
              >
                <Text pl="24px" color="gray500" font="smMedium" whitespace="no-wrap">
                  Total Cost of Sales (KES)
                </Text>
                <Text pl="24px" color="gray900" font="displayXsBold">
                  {total_cost_of_sales.toLocaleString()}
                </Text>
              </Flex>
            </Flex>

            <Flex flexDirection="column" width="50%" height="100%" outline={`1px solid ${organicTheme.colors.gray200}`}>
              <Flex
                gap="0.5rem"
                width="100%"
                height="50%"
                flexDirection="column"
                justifyContent="center"
                outline={`1px solid ${organicTheme.colors.gray200}`}
              >
                <Text pl="24px" color="gray500" font="smMedium" whitespace="no-wrap">
                  Gross Profit Percentage
                </Text>

                <Text pl="24px" color="gray900" font="displayXsBold">
                  {`${gross_profit_percentage}%`}
                </Text>
              </Flex>

              <Flex
                gap="0.5rem"
                width="100%"
                height="50%"
                flexDirection="column"
                justifyContent="center"
                // outline={`1px solid ${organicTheme.colors.gray200}`}
                pl="24px"
              >
                <Text color="gray500" font="smMedium" whitespace="no-wrap">
                  Total Gross Profit (KES)
                </Text>
                <Text color="gray900" font="displayXsBold">
                  {total_gross_profit.toLocaleString()}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          {/* PIECHART */}
          {/* <PieMetricCard
            chartWidth={360}
            // header={""}
            data={[
              { name: "Total Revenue", value: total_revenue, currency: "KES" },
              { name: "Cost of Sales", value: total_cost_of_sales, currency: "KES" },
              { name: "Gross Profit", value: total_gross_profit, currency: "KES" },
            ]}
          /> */}
          {/* LONG BAR METRIC */}
          <Card width="55%" height={"250px"}>
            <ResponsiveContainer width="100%" height={250}>
              <BarChart
                width={150}
                height={200}
                title="Overview"
                data={[{ name: "Overview", total_revenue, total_cost_of_sales, total_gross_profit }]}
              >
                <Tooltip />
                <CartesianGrid strokeDasharray="3 3" />
                <Legend
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="bottom"
                  content={
                    <Flex gap="8px" width="100%">
                      <Text as="span" font="xsBold" color="#F63D68">
                        <Icon icon="circle" size={10} /> Total Revenue (KES)
                      </Text>
                      <Text as="span" font="xsBold" color="#48A928">
                        <Icon icon="circle" size={10} /> Cost of Sales (KES)
                      </Text>
                      <Text as="span" font="xsBold" color="#FB6514">
                        <Icon icon="circle" size={10} /> Gross Profit (KES)
                      </Text>
                    </Flex>
                  }
                />

                <Bar dataKey={"total_revenue"} fill="#F63D68" fontSize={8} name="Total Revenue (KES)" />
                <Bar dataKey={"total_cost_of_sales"} fill="#48A928" name="Cost of Sales (KES)" />
                <Bar dataKey={"total_gross_profit"} fill="#FB6514" name="Gross Profit (KES)" />
              </BarChart>
            </ResponsiveContainer>
            <Flex>
              <Text dataKey={"total_cost_of_sales"} color="#48A928" name="Cost of Sales (KES)" />
              <Text dataKey={"total_gross_profit"} color="#FB6514" name="Gross Profit (KES)" />
            </Flex>
          </Card>
        </Flex>
      </Box>
      <Table
        {...tableProps}
        onViewAction={() => {
          // {
          //   console.log({ selectedTrade });
          // }
          navigate(`/dashboard/finance/reporting/details/${selectedTrade.trade_id}/${selectedTrade.crop_type}`);
        }}
      />
    </PageWrapper>
  );
};

export default FinanceReporting;
