export default [
  {
    state: {
      name: "Mombasa",
      id: 1,
      locals: [
        { name: "Changamwe" },
        { name: "Jomvu" },
        { name: "Kisauni" },
        { name: "Likoni" },
        { name: "Mvita" },
        { name: "Nyali" },
      ],
    },
  },
  {
    state: {
      name: "Kwale",
      id: 2,
      locals: [{ name: "Kinango" }, { name: "Lunga Lunga" }, { name: "Msambweni" }, { name: "Matuga" }],
    },
  },
  {
    state: {
      name: "Kilifi",
      id: 3,
      locals: [
        { name: "Ganze" },
        { name: "Kaloleni" },
        { name: "Kilifi North" },
        { name: "Kilifi South" },
        { name: "Magarini" },
        { name: "Malindi" },
        { name: "Rabai" },
      ],
    },
  },
  {
    state: {
      name: "Tana River",
      id: 4,
      locals: [{ name: "Bura" }, { name: "Galole" }, { name: "Garsen" }],
    },
  },
  {
    state: {
      name: "Lamu",
      id: 5,
      locals: [{ name: "Lamu East" }, { name: "Lamu West" }],
    },
  },
  {
    state: {
      name: "Taita-Taveta",
      id: 6,
      locals: [{ name: "Mwatate" }, { name: "Taveta" }, { name: "Voi" }, { name: "Wundanyi" }],
    },
  },
  {
    state: {
      name: "Garissa",
      id: 7,
      locals: [
        { name: "Daadab" },
        { name: "Fafi" },
        { name: "Garissa Township" },
        { name: "Hulugho" },
        { name: "Ijara" },
        { name: "Lagdera" },
        { name: "Balambala" },
      ],
    },
  },
  {
    state: {
      name: "Wajir",
      id: 8,
      locals: [
        { name: "Eldas" },
        { name: "Tarbaj" },
        { name: "Wajir East" },
        { name: "Wajir West" },
        { name: "Wajir South" },
        { name: "Wajir North" },
      ],
    },
  },
  {
    state: {
      name: "Mandera",
      id: 9,
      locals: [
        { name: "Banissa" },
        { name: "Lafey" },
        { name: "Mandera East" },
        { name: "Mandera North" },
        { name: "Mandera South" },
        { name: "Mandera West" },
      ],
    },
  },
  {
    state: {
      name: "Marsabit",
      id: 10,
      locals: [{ name: "Laisamis" }, { name: "Moyale" }, { name: "North Hor" }, { name: "Saku" }],
    },
  },
  {
    state: {
      name: "Isiolo",
      id: 11,
      locals: [{ name: "Isiolo" }, { name: "Merti" }, { name: "Garbatulla" }],
    },
  },
  {
    state: {
      name: "Meru",
      id: 12,
      locals: [
        { name: "Buuri" },
        { name: "Igembe Central" },
        { name: "Igembe North" },
        { name: "Igembe South" },
        { name: "Imenti Central" },
        { name: "Imenti North" },
        { name: "Imenti South" },
        { name: "Tigania East" },
        { name: "Tigania West" },
      ],
    },
  },
  {
    state: {
      name: "Tharaka-Nithi",
      id: 13,
      locals: [
        { name: "Tharaka North" },
        { name: "Tharaka South" },
        { name: "Chuka" },
        { name: "Igambango'mbe" },
        { name: "Maara" },
        { name: "Chiakariga" },
        { name: "Muthambi" },
      ],
    },
  },
  {
    state: {
      name: "Embu",
      id: 14,
      locals: [{ name: "Manyatta" }, { name: "Mbeere North" }, { name: "Mbeere South" }, { name: "Runyenjes" }],
    },
  },
  {
    state: {
      name: "Kitui",
      id: 15,
      locals: [
        { name: "Kitui West" },
        { name: "Kitui Central" },
        { name: "Kitui Rural" },
        { name: "Kitui South" },
        { name: "Kitui East" },
        { name: "Mwingi North" },
        { name: "Mwingi West" },
        { name: "Mwingi Central" },
      ],
    },
  },
  {
    state: {
      name: "Machakos",
      id: 16,
      locals: [
        { name: "Kathiani" },
        { name: "Machakos Town" },
        { name: "Masinga" },
        { name: "Matungulu" },
        { name: "Mavoko" },
        { name: "Mwala" },
        { name: "Yatta" },
      ],
    },
  },
  {
    state: {
      name: "Makueni",
      id: 17,
      locals: [
        { name: "Kaiti" },
        { name: "Kibwezi West" },
        { name: "Kibwezi East" },
        { name: "Kilome" },
        { name: "Makueni" },
        { name: "Mbooni" },
      ],
    },
  },
  {
    state: {
      name: "Nyandarua",
      id: 18,
      locals: [
        { name: "Kinangop" },
        { name: "Kipipiri" },
        { name: "Ndaragwa" },
        { name: "Ol-Kalou" },
        { name: "Ol Joro Orok" },
      ],
    },
  },
  {
    state: {
      name: "Nyeri",
      id: 19,
      locals: [
        { name: "Kieni East" },
        { name: "Kieni West" },
        { name: "Mathira East" },
        { name: "Mathira West" },
        { name: "Mukurweini" },
        { name: "Nyeri Town" },
        { name: "Othaya" },
        { name: "Tetu" },
      ],
    },
  },
  {
    state: {
      name: "Kirinyaga",
      id: 20,
      locals: [
        { name: "Kirinyaga Central" },
        { name: "Kirinyaga East" },
        { name: "Kirinyaga" },
        { name: "Mwea East" },
        { name: "Mwea West" },
      ],
    },
  },
  {
    state: {
      name: "Murang'a",
      id: 21,
      locals: [
        { name: "Gatanga" },
        { name: "Kahuro" },
        { name: "Kandara" },
        { name: "Kangema" },
        { name: "Kigumo" },
        { name: "Kiharu" },
        { name: "Mathioya" },
        { name: "Murang'a South" },
      ],
    },
  },
  {
    state: {
      name: "Kiambu",
      id: 22,
      locals: [
        { name: "Gatundu North" },
        { name: "Gatundu South" },
        { name: "Githunguri" },
        { name: "Juja" },
        { name: "Kabete" },
        { name: "Kiambaa" },
        { name: "Kiambu" },
        { name: "Kikuyu" },
        { name: "Limuru" },
        { name: "Ruiru" },
        { name: "Thika Town" },
        { name: "Lira" },
      ],
    },
  },
  {
    state: {
      name: "Turkana",
      id: 23,
      locals: [
        { name: "Loima" },
        { name: "Turkana Central" },
        { name: "Turkana East" },
        { name: "Turkana North" },
        { name: "Turkana South" },
      ],
    },
  },
  {
    state: {
      name: "West Pokot",
      id: 24,
      locals: [{ name: "Central Pokot" }, { name: "North Pokot" }, { name: "Pokot South" }, { name: "West Pokot" }],
    },
  },
  {
    state: {
      name: "Samburu",
      id: 25,
      locals: [{ name: "Samburu East" }, { name: "Samburu North" }, { name: "Samburu West" }],
    },
  },
  {
    state: {
      name: "Trans-Nzoia",
      id: 26,
      locals: [
        { name: "Cherangany" },
        { name: "Endebess" },
        { name: "Kiminini" },
        { name: "Kwanza" },
        { name: "Saboti" },
      ],
    },
  },
  {
    state: {
      name: "Uasin Gishu",
      id: 27,
      locals: [
        { name: "Ainabkoi" },
        { name: "Kapseret" },
        { name: "Kesses" },
        { name: "Moiben" },
        { name: "Soy" },
        { name: "Turbo" },
      ],
    },
  },
  {
    state: {
      name: "Elgeyo-Marakwet",
      id: 28,
      locals: [{ name: "Keiyo North" }, { name: "Keiyo South" }, { name: "Marakwet East" }, { name: "Marakwet West" }],
    },
  },
  {
    state: {
      name: "Nandi",
      id: 29,
      locals: [
        { name: "Aldai" },
        { name: "Chesumei" },
        { name: "Emgwen" },
        { name: "Mosop" },
        { name: "Nandi Hills" },
        { name: "Tindiret" },
      ],
    },
  },
  {
    state: {
      name: "Baringo",
      id: 30,
      locals: [
        { name: "Baringo Central" },
        { name: "Baringo North" },
        { name: "Baringo South" },
        { name: "Eldama Ravine" },
        { name: "Mogotio" },
        { name: "Tiaty" },
      ],
    },
  },
  {
    state: {
      name: "Laikipia",
      id: 31,
      locals: [
        { name: "Laikipia Central" },
        { name: "Laikipia East" },
        { name: "Laikipia North" },
        { name: "Laikipia West" },
        { name: "Nyahururu" },
      ],
    },
  },
  {
    state: {
      name: "Nakuru",
      id: 32,
      locals: [
        { name: "Bahati" },
        { name: "Gilgil" },
        { name: "Kuresoi North" },
        { name: "Kuresoi South" },
        { name: "Molo" },
        { name: "Naivasha" },
        { name: "Nakuru Town East" },
        { name: "Nakuru Town West" },
        { name: "Njoro" },
        { name: "Rongai" },
        { name: "Subukia" },
      ],
    },
  },
  {
    state: {
      name: "Narok",
      id: 33,
      locals: [
        { name: "Narok East" },
        { name: "Narok North" },
        { name: "Narok South" },
        { name: "Narok West" },
        { name: "Transmara East" },
        { name: "Transmara West" },
      ],
    },
  },
  {
    state: {
      name: "Kajiado",
      id: 34,
      locals: [
        { name: "Isinya" },
        { name: "Kajiado Central" },
        { name: "Kajiado North" },
        { name: "Loitokitok" },
        { name: "Mashuuru" },
      ],
    },
  },
  {
    state: {
      name: "Kericho",
      id: 35,
      locals: [
        { name: "Ainamoi" },
        { name: "Belgut" },
        { name: "Bureti" },
        { name: "Kipkelion East" },
        { name: "Kipkelion West" },
        { name: "Soin/Sigowet" },
      ],
    },
  },
  {
    state: {
      name: "Bomet",
      id: 36,
      locals: [
        { name: "Bomet Central" },
        { name: "Bomet East" },
        { name: "Chepalungu" },
        { name: "Konoin" },
        { name: "Sotik" },
      ],
    },
  },
  {
    state: {
      name: "Kakamega",
      id: 37,
      locals: [
        { name: "Butere" },
        { name: "Shinyalu" },
        { name: "Ikolomani" },
        { name: "Khwisero" },
        { name: "Lugari" },
        { name: "Likuyani" },
        { name: "Lurambi" },
        { name: "Malava" },
        { name: "Mumias East" },
        { name: "Mumias West" },
        { name: "Matungu" },
        { name: "Navakholo" },
      ],
    },
  },
  {
    state: {
      name: "Vihiga",
      id: 38,
      locals: [{ name: "Emuhaya" }, { name: "Hamisi" }, { name: "Luanda" }, { name: "Sabatia" }, { name: "Vihiga" }],
    },
  },
  {
    state: {
      name: "Bungoma",
      id: 39,
      locals: [
        { name: "Bumula" },
        { name: "Kabuchai" },
        { name: "Kanduyi" },
        { name: "Kimilili" },
        { name: "Mt Elgon" },
        { name: "Sirisia" },
        { name: "Tongaren" },
        { name: "Webuye East" },
        { name: "Webuye West" },
      ],
    },
  },
  {
    state: {
      name: "Busia",
      id: 40,
      locals: [
        { name: "Bunyala" },
        { name: "Butula" },
        { name: "Samia" },
        { name: "Nambele" },
        { name: "Teso North" },
        { name: "Teso South" },
        { name: "Matayos" },
      ],
    },
  },
  {
    state: {
      name: "Siaya",
      id: 41,
      locals: [
        { name: "Alego Usonga" },
        { name: "Bondo" },
        { name: "Gem" },
        { name: "Rarieda" },
        { name: "Ugenya" },
        { name: "Unguja" },
      ],
    },
  },
  {
    state: {
      name: "Kisumu",
      id: 42,
      locals: [
        { name: "Kisumu Central" },
        { name: "Kisumu East" },
        { name: "Kisumu West" },
        { name: "Muhoroni" },
        { name: "Nyakach" },
        { name: "Nyando" },
        { name: "Seme" },
      ],
    },
  },
  {
    state: {
      name: "Homa Bay",
      id: 43,
      locals: [
        { name: "Homabay Town" },
        { name: "Kabondo" },
        { name: "Karachwonyo" },
        { name: "Kasipul" },
        { name: "Mbita" },
        { name: "Ndhiwa" },
        { name: "Rangwe" },
        { name: "Suba" },
      ],
    },
  },
  {
    state: {
      name: "Migori",
      id: 44,
      locals: [
        { name: "Awendo" },
        { name: "Kuria East" },
        { name: "Kuria West" },
        { name: "Mabera" },
        { name: "Ntimaru" },
        { name: "Rongo" },
        { name: "Suna East" },
        { name: "Suna West" },
        { name: "Uriri" },
      ],
    },
  },
  {
    state: {
      name: "Kisii",
      id: 45,
      locals: [
        { name: "Kitutu Chache North" },
        { name: "Kitutu Chache" },
        { name: "Nyaribari Masaba" },
        { name: "Nyaribari Chache" },
        { name: "Bomachoge Borabu" },
        { name: "Bomachoge Chache" },
        { name: "Bobasi" },
        { name: "South Mugirango" },
        { name: "Bonchari" },
      ],
    },
  },
  {
    state: {
      name: "Nyamira",
      id: 46,
      locals: [
        { name: "Borabu" },
        { name: "Manga" },
        { name: "Masaba North" },
        { name: "Nyamira North" },
        { name: "Nyamira South" },
      ],
    },
  },
  {
    state: {
      name: "Nairobi",
      id: 47,
      locals: [
        { name: "Dagoretti North" },
        { name: "Dagoretti South" },
        { name: "Embakasi Central" },
        { name: "Embakasi East" },
        { name: "Embakasi North" },
        { name: "Embakasi South" },
        { name: "Embakasi West" },
        { name: "Kamukunji" },
        { name: "Kasarani" },
        { name: "Kibra" },
        { name: "Lang'ata" },
        { name: "Makadara" },
        { name: "Mathare" },
        { name: "Roysambu" },
        { name: "Ruaraka" },
        { name: "Starehe" },
        { name: "Westlands" },
      ],
    },
  },
];
