import { Text } from "organic";
import React from "react";
import { classnames } from "utils";

const Panel = ({ children, title, headerElement, bodyBg = "default" }) => {
  const panelClasses = classnames("panel", {
    "panel--transparent": bodyBg === "transparent",
    "panel__body--default": bodyBg === "default",
  });
  const panelBodyClasses = classnames("panel__body", {
    "panel__body--transparent": bodyBg === "transparent",
    "panel__body--default": bodyBg === "default",
  });
  return (
    <div className={panelClasses}>
      <div className="panel__header">
        {headerElement ? (
          headerElement
        ) : (
          <>
            {title ? (
              <Text font="lgMedium" color="gray900">
                {title}
              </Text>
            ) : null}
          </>
        )}
      </div>
      <div className={panelBodyClasses}>{children}</div>
    </div>
  );
};

export { Panel };
