import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { getAllSupervisionCategories, getFarmSupervisionCategoriesData } from "features/v1/farmMonitoring";
import { getSeasonOptions } from "features/v1/season";
import { useDataFilter, useDisclosure, useTable } from "hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLabelFromValue } from "utils/v1/selectUtils";
import CreateCategoryForm from "views/forms/createCategoryForm";
import EditFarmMonitoringCategory from "./farmMonitoring/editFarmMonitoringCategory";
import AddSubcategories from "./farmMonitoring/addSubcategories";

const ADD_SUBCATEGORY_ACTION_KEY = "addSubcategories";

const FarmMonitoringSettingsPage = () => {
  let farmSupervisionCategoryData = useSelector(getFarmSupervisionCategoriesData);
  let seasonOptions = useSelector(getSeasonOptions);

  const dispatch = useDispatch();
  const createCategoryDisclosure = useDisclosure();
  const editCategoryDisclosure = useDisclosure();
  const addSubcategoriesDisclosure = useDisclosure();

  const { filterParams, isValidFilter } = useDataFilter();

  const { tableProps, selectedRow: selectedCategory } = useTable({
    data: farmSupervisionCategoryData,
    columns: [
      {
        Header: "Category",
        accessor: "category",
      },
      {
        id: "fmCategorySeason",
        Header: "Season",
        accessor: category => selectLabelFromValue(seasonOptions, category.season),
      },
      {
        id: "fmCategorySubcategoryCount",
        Header: "Subcategory",
        accessor: category => category.sub_category.join(",") || "None Yet",
      },
    ],
    title: "All Supervision Category",
    noViewAction: true,
  });

  React.useEffect(() => {
    if (isValidFilter) {
      dispatch(getAllSupervisionCategories(filterParams));
    }
  }, [dispatch, filterParams, isValidFilter]);

  return (
    <div>
      <Table
        {...tableProps}
        createAction={{ label: "Add New Category" }}
        onCreateAction={() => {
          createCategoryDisclosure.onOpen();
        }}
        onEditAction={() => {
          editCategoryDisclosure.onOpen();
        }}
        getExtraActions={() => [
          {
            label: "Add Subcategories",
            actionKey: ADD_SUBCATEGORY_ACTION_KEY,
            icon: "columns",
          },
        ]}
        onExtraAction={selectedAction => {
          if (selectedAction.actionKey === ADD_SUBCATEGORY_ACTION_KEY) {
            addSubcategoriesDisclosure.onOpen();
          }
        }}
      />
      {/* create categpory */}
      <Modal {...createCategoryDisclosure.modalProps}>
        <ModalContent title="Add New Category">
          <CreateCategoryForm modal={createCategoryDisclosure.modal} />
        </ModalContent>
      </Modal>
      {/* edit category */}
      <Modal
        open={editCategoryDisclosure.isOpen}
        onOpenChange={open => {
          //  example of on modal close
          if (open === false) {
            editCategoryDisclosure.onClose();
          }
        }}
      >
        <ModalContent title="Edit Category">
          <EditFarmMonitoringCategory modal={editCategoryDisclosure.modal} selected={selectedCategory} />
        </ModalContent>
      </Modal>

      {/* add subcategories */}
      <Modal
        open={addSubcategoriesDisclosure.isOpen}
        onOpenChange={open => {
          //  example of on modal close
          if (open === false) {
            addSubcategoriesDisclosure.onClose();
          }
        }}
      >
        <ModalContent title="Add Subcategories to Category">
          <AddSubcategories modal={addSubcategoriesDisclosure.modal} selected={selectedCategory} />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FarmMonitoringSettingsPage;
