import React from "react";
import { StyledAvatarGroup } from "./styledAvatarGroup";

export const AvatarGroup = ({ children, className, show }) => {
  const showCount = show ?? 4;
  const ignoreddisplayedItems = [].slice(0, showCount);
  const ignoredremainingItems = [].slice(showCount);
  return <StyledAvatarGroup className={className}>{children}</StyledAvatarGroup>;
};

const AvatarGroupRemainder = ({ count }) => {
  return (
    <>
      {count > 0 && (
        <p
          style={{
            border: "1px solid transparent",
            color: "#247809",
            borderRadius: "16px",
            backgroundColor: "#ECFFE5",
            margin: "2px",
            padding: "4px",
          }}
        >
          +{count}
        </p>
      )}
    </>
  );
};

AvatarGroup.Remainder = AvatarGroupRemainder;
