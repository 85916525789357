import { getOrg } from "services/v1/authService";
import { keyBoardTypes, questionInputTypes } from "utils/questionnaire-utils/questionnaire-utils";

let organization = getOrg();

/** SUBSCRIPTION PLAN AGRIC-INPUT FIELD */
export const subscriptionPlanInput = {
  input_name: "",
  input_type: "",
  input_quantity: 0,
  input_unit_measurement: "kg",
  unit_amount: "0",
};

/** SUBSCRIPTION PLAN DETAILS VALUES */
export const initialSubscriptionValues = {
  subscription_plan_name: "",
  duration: "",
  equity_contribution_amount: "0",
  total_input_loan_amount: "0",
  subscription_plan_inputs: [
    subscriptionPlanInput, //one default initial value
  ],
  subscription_plan_ouputs: {
    output_name: "",
    output_type: "",
    output_quantity: "0",
    input_unit_measurement: "kg",
    expected_repayment: "0",
    comment: "",
  },
};

/** ADD REGION TO SEASON FORM */
export const initiateAddRegionsToSeasonValues = season => {
  return { regions: season.regions || [] };
};

/** NEW SEASON VALUES */
export const inialCreateSeasonValues = {
  name: "",
  seasonId: "",
  target: 1,
  achieved: 1,
  duration: {
    start_date: new Date(),
    end_date: new Date(),
  },
  isActive: true,
  cropName: "",
  // regions: []
};

/** NEW CROP VALUES */
export const initialCreateCropValues = {
  name: "",
  season: "",
  crop_category: "",
};

/** ADD AGRIC-INPUTS TO CROP */
export const initialAddInputToCropValues = {
  inputTypes: [],
};
/** UPDATE CROP DETAILS  */
export const DEPRECATED_initiateUpdateCropValues = crop => {
  return {
    name: crop?.name || "",
    organization: organization,
    crop_category: crop?.crop_category || "",
    season: crop?.season,
  };
};

/** CREATE AGRIC-INPUT VALUES */
export const initialCreateInputValues = {
  name: "",
  organizationId: organization,
  seasonId: "",
  cropId: "",
  input_distribution: "",
};

/** ADD INPUT QTY PER HECTARE SIZE VALUES */
export const legacyInitiateSetInputQtyPerHectareSizeValues = input => {
  return { quantity_per_hectares: input?.quantity_per_hectares || [] };
};

/** NEW QUESTIONNAIRE VALUES */
export const DEPRECATED_initialCreateQuestionnaireValues = {
  name: "",
  organization: getOrg(),
  season: "",
  farm_operation_category: [],
  category_type: [],
  psychometrics_status: false,
  model: "",
  score_per_question: 0,
  cut_off_mark: 0,
  category_crop_type: "",
  status: "active",
  questions: [],
};

/** NEW QUESTION VALUES */
export const DEPRECATED_initialCreateSingleQuestionValue = {
  question_title: "",
  question_type: questionInputTypes[0].value,
  answer_options: [],
  correct_answer: [],
  isCompulsory: false,
  keyboardType: keyBoardTypes[0].value,
  maxLength: 1000,
};

/** NEW ROLE VALUES */
export const initialCreateRoleValues = {
  name: "",
  slug: "",
  scope: [],
};

/** NEW FARM SUPERVISION/MONITORING CATEGORY */
export const initiateSupervisionCategoryValues = (selected = {}) => ({
  category: selected?.category || "",
  season: selected?.season || "",
  sub_category: selected?.sub_category || [],
  organization,
});

export const initiateUpdateRegionValues = selected => ({
  value: selected?.value || "",
  label: selected?.label || "",
});
