import React from "react";
import { createSupervisionCategory } from "features/v1/farmMonitoring";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Box, Button } from "organic";
import { FormInput } from "components/inputs";
import { useDataFilter } from "hooks";
import { CreatableSelect, ProjectSelect } from "components/select";
import { farmSupervisionCategoryValidatioSchema } from "validators/farmMonitoringValidationSchemas";
import { getOrg } from "services/v1/authService";
let organization = getOrg();

function CreateCategoryForm({ modal }) {
  const dispatch = useDispatch();
  const { filterParams } = useDataFilter();

  const handleSubcategoryChange = (values, actionMeta, setFieldValue, __formValues) => {
    switch (actionMeta.action) {
      case "clear": {
        // answer options is cleared
        setFieldValue("sub_category", []);
        return;
      }
      case "create-option": {
        // new answer options is manually entered
        setFieldValue(
          "sub_category",
          values.map(val => val.value)
        );
        return;
      }
      case "select-option": {
        // an answer option that was previously removed was reselected
        setFieldValue(
          "subcateogry",
          values.map(val => val.value)
        );
        return;
      }
      case "remove-value": {
        if (!values) {
          setFieldValue("sub_category", []);
          return;
        }
        setFieldValue(
          "sub_category",
          values.map(val => val.value)
        );
        // an answer option that was created is removed from list (still available to reselect)
        return;
      }
      default: {
        return;
      }
    }
  };

  const initialValues = {
    category: "",
    season: "",
    sub_category: [],
    organization,
  };

  const handleCreateSupervisionCategory = (values, formOptions) => {
    dispatch(createSupervisionCategory(values, { formOptions, modal, filterParams }));
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={farmSupervisionCategoryValidatioSchema}
        onSubmit={handleCreateSupervisionCategory}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Box my={40}>
              <Box mb={4}>
                <FormInput required name="category" label="Category Name" placeholder="Name of Category" />
              </Box>
              <Box mb={4}>
                <CreatableSelect
                  isMulti
                  isClearable
                  name="sub_category"
                  components={{ DropdownIndicator: null }}
                  label="Subcategories"
                  placeholder="type a sub category and Hit Enter"
                  onChange={(value, actionMeta) => {
                    handleSubcategoryChange(value, actionMeta, setFieldValue, values);
                  }}
                />
              </Box>
              <Box mb={4}>
                <ProjectSelect
                  required
                  label="Season"
                  ignoreGlobalState
                  onChange={val => setFieldValue("season", val)}
                  colorScheme="default"
                />
              </Box>
              <Box mb={4}>
                <Button colorScheme="primary" display="block" type="submit" text="Create"></Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateCategoryForm;
