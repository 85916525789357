/* eslint-disable no-unused-vars */
import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Flex, Icon, Text } from "organic";
import { classnames } from "utils";

export const ModalContent = React.forwardRef(function DialogContent(
  {
    children,
    variant,
    __onCloseModal,
    className,
    size,
    width,
    title,
    doNotCloseOnClickOutOrEscKey,
    hidecloseicon,
    ...props
  },
  forwardedRef
) {
  // NOTE: this is the right approach, but it would be more effect with radix if this is applied to Root
  // DialogPrimitive.Root has a callback Prop call onOpenChange = (open :bool) => {}
  // this can call the onModalClose  if open === false
  // const handleCloseModal = () => {
  //   if (isFunction(onCloseModal)) {
  //     onCloseModal();
  //   }
  // };
  const isCentered = variant === "center";
  const contentClassnames = classnames(className, "dialog-content", {
    "dialog-content--md": size === "md",
    "dialog-content--center": isCentered,
  });

  return (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className="dialog-overlay" />
      <DialogPrimitive.Content
        // Don't close the Alert Dialog when clicking outside
        onEscapeKeyDown={event => doNotCloseOnClickOutOrEscKey && event.preventDefault()}
        onPointerDownOutside={event => doNotCloseOnClickOutOrEscKey && event.preventDefault()}
        onInteractOutside={event => doNotCloseOnClickOutOrEscKey && event.preventDefault()}
        className={contentClassnames}
        {...props}
        ref={forwardedRef}
        style={{ width: width }}
      >
        <Flex
          justifyContent="space-between"
          px={`${isCentered ? "0rem" : "2rem"}`}
          py={`${isCentered ? "0rem" : "2rem"}`}
        >
          <Text font="displayXsMedium">{title}</Text>
          {!hidecloseicon && (
            <DialogPrimitive.Close className="dialog-close">
              <Icon icon="x" size={24} />
            </DialogPrimitive.Close>
          )}
        </Flex>
        <Flex flexDirection="column" padding={`${isCentered ? "0rem" : "2rem"}`}>
          {children}
        </Flex>
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
});
ModalContent.displayName = "ModalContent";

export const Modal = DialogPrimitive.Root;
export const ModalTrigger = DialogPrimitive.Trigger;
