import { NavItem } from "organic";
import React from "react";

const SidebarNavItem = ({ children, iconOnly, icon, current, name, to, toggle, textOnly }) => {
  return (
    <NavItem iconOnly={iconOnly} current={current} icon={icon} name={name} to={to} toggle={toggle} textOnly={textOnly}>
      {children}
    </NavItem>
  );
};

export default SidebarNavItem;
