import { useMutation } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import Marketplace_API from "services/marketplaceApiService";

export function useToggleStatus() {
  return useMutation({
    mutationFn: Marketplace_API.toggleStatus,
    onSuccess: data => {
      appQueryClient.invalidateQueries([rqKeys.marketplace.fetchAllTrades, "approved"]);
      appQueryClient.invalidateQueries([rqKeys.marketplace.fetchAllTrades, "stocked"]);
      return data;
    },
    onError: useToggleStatusError => {
      const bug = new AOSClientError("Something went wrong updating trade");
      bug.report(useToggleStatusError);
      console.log({ useToggleStatusError });
    },
  });
}
