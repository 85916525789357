import { createGlobalStyle, css } from "styled-components/macro";
import _ from "lodash";
import typography from "../theme/mixins/_typography";
import colors from "organic/theme/colors";

/** generate a classname selector ".some-class-name" from an object key -  */
const ignoredgetClassNameFromObjectKey = (key = "", prefix = "") => {
  const classNameCasing = _.kebabCase(key);
  return "" + prefix + classNameCasing;
};

const allColors = Object.values(colors);

const globalRoot = css`
  html {
    font-size: 16px;
    ${typography.defaultFontFamily};
    ${props =>
      props.debug === true
        ? `
    * {
      outline-width: 1px;
      outline-color: ${allColors[Math.ceil(Math.random() * 100)]};
      outline-style: solid;
    }
    `
        : ""}
  }
`;

const GlobalStyleUtils = createGlobalStyle`
${globalRoot}
`;

// use organic typography scale by default
GlobalStyleUtils.defaultProps = {
  typography: typography,
  debug: false,
};

export default GlobalStyleUtils;
