import organization from "api/organization";
import { getOrg, setOrgName, setSenderId } from "services/v1/authService";

const GET_ALL_ORGANIZATIONS = "GET_ALL_ORGANIZATIONS";
const GET_CURRENT_ORGANIZATIONS = "GET_CURRENT_ORGANIZATIONS";
const LOAD_ORGANIZATIONS = "GET_ALL_ORGANIZATIONS";

export const getAllOrganizations = () => async dispatch => {
  try {
    dispatch({ type: LOAD_ORGANIZATIONS, payload: true });
    const allOrganizations = await organization.get("getAll", { ignoreOrgId: true });
    dispatch({ type: GET_ALL_ORGANIZATIONS, payload: allOrganizations?.data?.data || [] });
    dispatch({ type: LOAD_ORGANIZATIONS, payload: false });
    return dispatch(getCurrentOrganization());
  } catch (getAllOrganizationsError) {
    dispatch({ type: LOAD_ORGANIZATIONS, payload: false });
  }
};
export const getCurrentOrganization = () => async dispatch => {
  try {
    dispatch({ type: LOAD_ORGANIZATIONS, payload: true });
    let organizationId = getOrg();
    const params = {
      id: organizationId,
    };
    const allOrgs = await organization.get("getAll", { params, ignoreOrgId: true });
    let currentOrganization;
    if (!Array.isArray(allOrgs?.data?.data)) {
      currentOrganization = {};
    }
    currentOrganization = allOrgs.data.data.find(org => org._id === params.id);
    setOrgName(currentOrganization.name);
    setSenderId({ id: currentOrganization?.sender_id || null, status: currentOrganization?.sender_id_status || null });
    // dispatch({ type: GET_CURRENT_ORGANIZATIONS, payload: currentOrganization?.data?.data || {} });
    dispatch({ type: GET_CURRENT_ORGANIZATIONS, payload: currentOrganization || {} });
    dispatch({ type: LOAD_ORGANIZATIONS, payload: false });
  } catch (getOneOrganizationError) {
    dispatch({ type: LOAD_ORGANIZATIONS, payload: false });
  }
};

// export const setCurrentOrgData = data => dispatch => {
//   try {
//     dispatch({ type: GET_CURRENT_ORGANIZATIONS, payload: data || {} });
//   } catch (setOneOrganizationError) {
//     console.log({ setOneOrganizationError });
//   }
// };

const initialState = {
  allOrganizations: [],
  currentOrganization: {},
  loadingOrganizations: false,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORGANIZATIONS: {
      return { ...state, allOrganizations: action.payload };
    }
    case GET_CURRENT_ORGANIZATIONS: {
      return { ...state, currentOrganization: action.payload };
    }
    case LOAD_ORGANIZATIONS: {
      return { ...state, loadingOrganizations: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const getAllOrganizationsData = state => state.legacyOrganization.allOrganizations;
export const getCurrentOrganizationData = state => state.legacyOrganization.currentOrganization;
export const getLoadingOrganizations = state => state.legacyOrganization.loadingOrganizations;

export default organizationReducer;
