import axios from "axios";
import { getCurrentAosVersion, getCustomerID } from "services/customerService";
import authService from "../services/v1/authService";

import { resolveAdminApiBaseUrl } from "utils";

const baseApi = resolveAdminApiBaseUrl();

let API = baseApi + "/api/v1/agent";

const customerid = getCustomerID();
const aosVersion = getCurrentAosVersion();

const agents = axios.create({
  baseURL: API,
  headers: { "x-aos-customerid": customerid, "x-aos-version": aosVersion },
});

agents.defaults.headers.post["Content-Type"] = "application/json";
agents.defaults.headers["Access-Control-Allow-Origin"] = "*";

agents.interceptors.request.use(
  config => {
    const token = authService.getToken();
    const organization = authService.getOrg();
    config.params = config?.params || {};

    if (organization && !config?.ignoreOrgId) {
      config.params["organizationId"] = organization;
    }

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  err => {
    authService.tokenExpired(err);
    return Promise.reject(err);
  }
);

agents.interceptors.response.use(
  config => config,
  err => {
    authService.tokenExpired(err);
    return Promise.reject(err);
  }
);

export default agents;
