import agents from "api/agents";
import farmers from "api/farmers";
import subscription from "api/subscription";
import {
  mockAgentOverviewData,
  mockFarmerOverview,
  mockSubActivtyOverview,
  mockSubPaymentOverviewData,
} from "mockData/overview";
// import { mockAgentOverviewData, mockFarmerOverview, mockSubActivtyOverview, mockSubPaymentOverviewData } from "mockData/overview";
// import { mockAgentOverviewData, mockFarmerOverview } from "mockData/overview";
import { handleActionError } from "services/shared/AOSErrorService";
import generateXYData from "utils/v1/generateXYData";

const DEMO_MODE_KEY = "trackingId";

const isDemoMode = () => {
  return sessionStorage?.getItem?.(DEMO_MODE_KEY) ? true : false;
};

console.log({ first: isDemoMode() });

//actions
const GET_ALL_FARMERS_OVERVIEW = "GET_ALL_FARMERS_OVERVIEW";
const LOAD_ALL_FARMERS_OVERVIEW = "LOAD_ALL_FARMERS_OVERVIEW";

const GET_ALL_AGENTS_OVERVIEW = "GET_ALL_AGENTS_OVERVIEW";
const LOAD_ALL_AGENTS_OVERVIEW = "LOAD_ALL_AGENTS_OVERVIEW";

const GET_ALL_FARM_MAPPINGS_OVERVIEW = "GET_ALL_FARM_MAPPINGS_OVERVIEW";
const LOAD_ALL_FARM_MAPPINGS_OVERVIEW = "LOAD_ALL_FARM_MAPPINGS_OVERVIEW";

const GET_ALL_SUB_ACTIVITY_OVERVIEW = "GET_ALL_SUB_ACTIVITY_OVERVIEW";
const LOAD_ALL_SUB_ACTIVITY_OVERVIEW = "LOAD_ALL_SUB_ACTIVITY_OVERVIEW";

const GET_ALL_FARM_SUB_PAYMENT_OVERVIEW = "GET_ALL_FARM_SUB_PAYMENT_OVERVIEW";
const LOAD_ALL_FARM_SUB_PAYMENT_OVERVIEW = "LOAD_ALL_FARM_SUB_PAYMENT_OVERVIEW";

const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
const CHANGE_OFFSET_VALUE = "CHANGE_OFFSET_VALUE";
const SET_INFO_TO_DISPLAY = "SET_INFO_TO_DISPLAY";
const RESET_PAGINATION_VALUES_OVERVIEW = "RESET_PAGINATION_VALUES_OVERVIEW";

// const UNIT_COMMITMENT_FEE = 500;
//action creators
export const getAllFarmersOverview = params => async dispatch => {
  try {
    dispatch({ type: LOAD_ALL_FARMERS_OVERVIEW, payload: true });
    const farmerData = !isDemoMode()
      ? await farmers.get("getAllFarmersWithParamsOverview", { params })
      : await Promise.resolve(mockFarmerOverview());
    dispatch({
      type: GET_ALL_FARMERS_OVERVIEW,
      payload: farmerData.data.data,
    });

    dispatch({ type: LOAD_ALL_FARMERS_OVERVIEW, payload: false });
  } catch (getAllFarmersOverviewError) {
    handleActionError(getAllFarmersOverviewError);
  }
};

export const getAllAgentsOverview = params => async dispatch => {
  try {
    dispatch({ type: LOAD_ALL_AGENTS_OVERVIEW, payload: true });
    const agentData = !isDemoMode()
      ? await agents.get("getAllAgentsWithParamsOverview", { params })
      : await Promise.resolve(mockAgentOverviewData());
    dispatch({
      type: GET_ALL_AGENTS_OVERVIEW,
      payload: agentData.data.data,
      meta: agentData.data.meta,
    });

    dispatch({ type: LOAD_ALL_AGENTS_OVERVIEW, payload: false });
  } catch (getAllAgentsOverviewError) {
    dispatch({ type: LOAD_ALL_AGENTS_OVERVIEW, payload: false });
    handleActionError(getAllAgentsOverviewError);
  }
};

export const getAllFarmMappingsOverview = params => async dispatch => {
  try {
    dispatch({ type: LOAD_ALL_FARM_MAPPINGS_OVERVIEW, payload: true });
    //console.log("params: ",params)
    const resData = await farmers.get("getAllMappingsWithParamsOverview", { params });

    dispatch({
      type: GET_ALL_FARM_MAPPINGS_OVERVIEW,
      payload: resData.data.data,
      //pages: resData.data.pages,
      //total: resData.data.total,
    });

    dispatch({ type: LOAD_ALL_FARM_MAPPINGS_OVERVIEW, payload: false });
  } catch (getAllFarmMappingsOverviewError) {
    dispatch({ type: LOAD_ALL_FARM_MAPPINGS_OVERVIEW, payload: false });
    handleActionError(getAllFarmMappingsOverviewError);
  }
};

export const getAllSubActivityOverview = params => async dispatch => {
  try {
    dispatch({ type: LOAD_ALL_SUB_ACTIVITY_OVERVIEW, payload: true });
    // console.log("params: ",params)
    const resData = !isDemoMode()
      ? await subscription.get("getAllFarmSubActivityWithParamsOverview", { params })
      : await Promise.resolve(mockSubActivtyOverview());

    dispatch({
      type: GET_ALL_SUB_ACTIVITY_OVERVIEW,
      payload: resData.data.data,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_ALL_SUB_ACTIVITY_OVERVIEW, payload: false });
  } catch (getAllSubActivityWithParamsOverviewError) {
    dispatch({ type: LOAD_ALL_SUB_ACTIVITY_OVERVIEW, payload: false });
    handleActionError({ getAllSubActivityWithParamsOverviewError });
  }
};

export const getAllFarmSubPaymentOverview = params => async dispatch => {
  try {
    dispatch({ type: LOAD_ALL_FARM_SUB_PAYMENT_OVERVIEW, payload: true });
    // console.log("params: ",params)
    const resData = !isDemoMode()
      ? await subscription.get("getAllFarmSubPaymentsWithParamsOverview", { params })
      : await Promise.resolve(mockSubPaymentOverviewData());

    dispatch({
      type: GET_ALL_FARM_SUB_PAYMENT_OVERVIEW,
      payload: resData.data.data,
      total: resData.data.total,
    });

    dispatch({ type: LOAD_ALL_FARM_SUB_PAYMENT_OVERVIEW, payload: false });
  } catch (getAllFarmSubPaymentOverviewError) {
    dispatch({ type: LOAD_ALL_FARM_SUB_PAYMENT_OVERVIEW, payload: false });
    handleActionError({ getAllFarmSubPaymentOverviewError });
  }
};

export const changePageSize = perPage => dispatch => {
  dispatch({
    type: CHANGE_PAGE_SIZE,
    payload: perPage,
  });
};

export const resetPaginationValues = () => dispatch => {
  dispatch({
    type: RESET_PAGINATION_VALUES_OVERVIEW,
  });
};

export const handlePageChange = (pageNumber, pageSize) => dispatch => {
  dispatch({
    type: HANDLE_PAGE_CHANGE,
    payload: pageNumber,
  });
  dispatch(handleOffset(pageNumber, pageSize));
};

export const handleOffset = (pageNumber, pageSize) => dispatch => {
  const offsetValue = (pageNumber - 1) * pageSize;

  dispatch({
    type: CHANGE_OFFSET_VALUE,
    payload: offsetValue,
  });
};

export const setDisplay = arrayOfInfo => dispatch => {
  dispatch({
    type: SET_INFO_TO_DISPLAY,
    payload: arrayOfInfo,
  });
};

//reducer
const initialState = {
  // farmers api
  loadingFarmersOverview: false,
  agentInvolvedCount: 0,
  averageFarmersPerAgent: 0,
  averageGroupSize: 0,
  groupSize: 0,
  newFarmersCount: 0,
  oldFarmersCount: 0,
  totalFarmersCount: 0,
  groupsChartData: [],
  farmersChartData: [],
  groupsInfo: [], // graphData filtered by lead_farmer
  avergageFarmerPerAgentTarget: 1000,

  // agents api
  loadingAgentsOverview: false,
  agentChartData: [],
  overAllAgentCount: 0,

  // farm Mapping
  totalFieldSize: 0,
  averageFieldSize: 0,
  numberOfFields: 0,
  totalFieldsMappedInHectare: 0,
  mappedinfo: [],
  mappingChartData: [],

  // subscription api
  subscriptionActivityTotal: 0,
  loadingSubscriptionActivityOverview: false,
  subscriptionPaymentTotal: 0,
  loadingSubscriptionPaymentOverview: false,

  // pagiantion
  pages: 1,
  pageNumber: 1,
  offset: 0,
  pageSize: { label: "10", value: 10 },
  info: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FARMERS_OVERVIEW: {
      let {
        agentInvolvedCount,
        averageFarmersPerAgent,
        averageGroupSize,
        groupSize,
        oldFarmers,
        newFarmers,
        totalFarmers,
        graphData, //with just role & created_at meta
      } = action.payload;
      let groupInfo = graphData?.filter(g => g?.role === "lead_farmer");
      let groupsChartData = generateXYData(groupInfo),
        farmersChartData = generateXYData(graphData);

      return {
        ...state,
        totalFarmersCount: totalFarmers,
        newFarmersCount: newFarmers,
        oldFarmersCount: oldFarmers,
        agentInvolvedCount: agentInvolvedCount,
        averageFarmersPerAgent: averageFarmersPerAgent,
        groupSize: groupSize,
        groupInfo,
        groupsChartData,
        farmersChartData,
        averageGroupSize: averageGroupSize,
      };
    }
    case LOAD_ALL_FARMERS_OVERVIEW:
      return {
        ...state,
        loadingFarmersOverview: action.payload,
      };
    case GET_ALL_AGENTS_OVERVIEW: {
      let agentChartData = generateXYData(action.payload);

      return {
        ...state,
        agentChartData,
        overAllAgentCount: action.meta.totalDocCount,
      };
    }
    case LOAD_ALL_AGENTS_OVERVIEW:
      return {
        ...state,
        loadingAgentsOverview: action.payload,
      };
    case GET_ALL_FARM_MAPPINGS_OVERVIEW: {
      let { numberOfFields, totalFieldsMappedInHectare, averageFieldSize, mappedinfo } = action.payload;
      let mappingChartData = generateXYData(mappedinfo);

      return {
        ...state,
        totalFieldsMappedInHectare,
        averageFieldSize: averageFieldSize,
        numberOfFields,
        mappedinfo,
        mappingChartData,
      };
    }
    case LOAD_ALL_FARM_MAPPINGS_OVERVIEW:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_SUB_ACTIVITY_OVERVIEW:
      return {
        ...state,
        subscriptionActivityTotal: action.total,
      };
    case LOAD_ALL_SUB_ACTIVITY_OVERVIEW:
      return {
        ...state,
        loadingSubscriptionActivityOverview: action.payload,
      };
    case GET_ALL_FARM_SUB_PAYMENT_OVERVIEW:
      return {
        ...state,
        subscriptionPaymentTotal: action.total,
      };
    case LOAD_ALL_FARM_SUB_PAYMENT_OVERVIEW:
      return {
        ...state,
        loadingSubscriptionPaymentOverview: action.payload,
      };
    case CHANGE_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case HANDLE_PAGE_CHANGE:
      return { ...state, pageNumber: action.payload };
    case CHANGE_OFFSET_VALUE:
      return { ...state, offset: action.payload };
    case SET_INFO_TO_DISPLAY: {
      const infoToDisplay = action.payload.slice(state.offset, state.offset + state.pageSize.value);
      return { ...state, info: infoToDisplay };
    }
    default:
      return state;
  }
};

export const getOverviewLoadingFarmers = state => state.legacyOverview.loadingFarmersOverview;
export const getOverviewAgentInvolvedCount = state => state.legacyOverview.agentInvolvedCount;
export const getOverviewAverageFarmersPerAgent = state => state.legacyOverview.averageFarmersPerAgent;
export const getOverviewAverageGroupSize = state => state.legacyOverview.averageGroupSize;
export const getOverviewGroupSize = state => state.legacyOverview.groupSize;
export const getOverviewNewFarmersCount = state => state.legacyOverview.newFarmersCount;
export const getOverviewOldFarmersCount = state => state.legacyOverview.oldFarmersCount;
export const getOverviewTotalFarmersCount = state => state.legacyOverview.totalFarmersCount;
export const getOverviewGroupsChartData = state => state.legacyOverview.groupsChartData;
export const getOverviewFarmersChartData = state => state.legacyOverview.farmersChartData;
export const getOverviewGroupsInfo = state => state.legacyOverview.groupsInfo;
export const getOverviewAvergageFarmerPerAgentTarget = state => state.legacyOverview.avergageFarmerPerAgentTarget;
export const getOverviewLoadingAgents = state => state.legacyOverview.loadingAgentsOverview;
export const getOverviewAgentChartData = state => state.legacyOverview.agentChartData;
export const getOverviewOverAllAgentCount = state => state.legacyOverview.overAllAgentCount;
export const getOverviewTotalFieldSize = state => state.legacyOverview.totalFieldsMappedInHectare;
export const getOverviewAverageFieldSize = state => state.legacyOverview.averageFieldSize;
export const getOverviewNumberOfFields = state => state.legacyOverview.numberOfFields;
export const getOverviewMappingChartData = state => state.legacyOverview.mappingChartData;
export const getOverviewSubscriptionActivityTotal = state => state.legacyOverview.subscriptionActivityTotal;
export const getOverviewLoadingSubscriptionActivity = state => state.legacyOverview.loadingSubscriptionActivityOverview;
export const getOverviewSubscriptionPaymentTotal = state => state.legacyOverview.subscriptionPaymentTotal;
export const getOverviewLoadingSubscriptionPayment = state => state.legacyOverview.loadingSubscriptionPaymentOverview;

// pagination
export const getPages = state => state.overview.pages;
export const getPageNumber = state => state.overview.pageNumber;
export const getOffset = state => state.overview.offset;
export const getPageSize = state => state.overview.pageSize;
export const getInfo = state => state.overview.info;
