// import Axios from "axios";
import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";
import admin from "api/admin";
import { authChecker } from "../../services/v1/authService";
export const GET_ALL_INPUT_DISTRIBUTION = "GET_ALL_INPUT_DISTRIBUTION";
export const LOAD_ALL_INPUT_DISTRIBUTION = "LOAD_ALL_INPUT_DISTRIBUTION";
export const CLEARANCE_LOADING = "CLEARANCE_LOADING";

export const getAllInputDistribution = params => async dispatch => {
  try {
    authChecker();

    dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: true });
    const inputData = await admin.get("getAllFirstBatchDistributedInput", { params });

    dispatch({
      type: GET_ALL_INPUT_DISTRIBUTION,
      payload: inputData.data.data,
      inputDistMeta: inputData?.data?.meta || {},
    });

    dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: false });
  } catch (getAllInputDistributionError) {
    dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: false });
    console.log({ getAllInputDistributionError });
  }
};

export const searchInputDistribution =
  (params, { abortController }) =>
  async dispatch => {
    try {
      authChecker();
      dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: true });
      const inputData = await admin.get("inputDistribution/search", { params, signal: abortController.signal });

      dispatch({
        type: GET_ALL_INPUT_DISTRIBUTION,
        payload: inputData?.data?.data,
        inputDistMeta: inputData?.data?.meta || {},
      });
      dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: false });
    } catch (searchInputDistributionError) {
      dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: false });
      handleActionError(searchInputDistributionError);
    }
  };

export const setAllIDClearance =
  (data, { params, filter, onError, onSuccess }) =>
  async dispatch => {
    try {
      dispatch({ type: CLEARANCE_LOADING, payload: true });
      await admin.put("farmers/collected-input/clearedForOtherIds", { id_stages: data }, { params });
      toast.success("Cleared ID Stages updated successfully");
      dispatch({ type: CLEARANCE_LOADING, payload: false });
      onSuccess();
      return dispatch(getAllInputDistribution(filter));
    } catch (setAllIDClearanceError) {
      onError();
      dispatch({ type: CLEARANCE_LOADING, payload: false });
      handleActionError(setAllIDClearanceError);
    }
  };

export const getAllFirstBatchInputDistribution = params => async dispatch => {
  try {
    authChecker();

    dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: true });
    const inputData = await admin.get("getAllFirstBatchDistributedInput", { params });

    dispatch({
      type: GET_ALL_INPUT_DISTRIBUTION,
      payload: inputData.data.data,
      inputDistMeta: inputData?.data?.meta || {},
    });

    dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: false });
  } catch (getAllFirstBatchInputDistributionError) {
    dispatch({ type: LOAD_ALL_INPUT_DISTRIBUTION, payload: false });
    handleActionError(getAllFirstBatchInputDistributionError);
  }
};

const initialState = {
  inputDistributionData: [],
  inputDistMeta: {},
  loadingData: false,
  loadingClearance: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INPUT_DISTRIBUTION:
      return {
        ...state,
        inputDistributionData: action.payload,
        inputDistMeta: action.inputDistMeta,
      };
    case CLEARANCE_LOADING:
      return {
        ...state,
        loadingClearance: action.payload,
      };
    case LOAD_ALL_INPUT_DISTRIBUTION:
      return { ...state, loadingData: action.payload };
    default:
      return state;
  }
};

// selector functions
export const getInputDistributionData = state => state.legacyInputDistribution.inputDistributionData;
export const getInputDistMeta = state => state.legacyInputDistribution.inputDistMeta;
export const getLoadingData = state => state.legacyInputDistribution.loadingData;
export const getLoadingClearance = state => state.legacyInputDistribution.loadingClearance;
