/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Table } from "components/table";
import { useDispatch, useSelector } from "react-redux";
import { useDisclosure, useTable } from "hooks";
import { getAllWarehouses, getLoadingWarehouse, getWarehouseData } from "features/v1/warehouse";
import { Modal, ModalContent } from "components/modal";
import CreateStore from "./createStore";
import EditStoreDetails from "./editStoreDetails";

const StoresPage = () => {
  const dispatch = useDispatch();

  let warehouseData = useSelector(getWarehouseData);
  let loading = useSelector(getLoadingWarehouse);

  const editStoreDisclosure = useDisclosure();
  const createStoreModal = useDisclosure();

  const { tableProps, selectedRow: selectedStore } = useTable({
    data: warehouseData,
    columns: [
      {
        Header: "Store TA",
        accessor: "store.ta_number",
      },
      {
        Header: "Store Name",
        accessor: "store.name",
      },
      {
        Header: "Store State",
        accessor: "store.state",
      },
      {
        Header: "LGA",
        accessor: "store.lga",
      },
      {
        Header: "Region",
        accessor: mapperInfo => `${mapperInfo?.region || "N/A"}`,
      },
      // {
      //   Header: "Zone",
      //   accessor: mapperInfo => `${mapperInfo?.zone || "N/A"}`,
      // },
      {
        Header: "Supervisor Name",
        accessor: _store => getSupervisor(_store),
      },
    ],
    title: "All store",
    useServerPagination: false,
    noViewAction: true,
  });

  const getSupervisor = store => {
    if (store?.user?.length > 0) {
      return store?.user?.reduce((userList, curUser) => {
        return userList + `${curUser?.name}\n`;
      }, "");
    } else {
      return `${store?.supervisor?.name || "N/A"}`;
    }
  };

  useEffect(() => {
    dispatch(getAllWarehouses());
  }, []);

  return (
    <>
      <Table
        {...tableProps}
        loading={loading}
        // onViewAction={() => {
        //   farmMappingModal.showModal();
        // }}
        createAction={{ label: "Create a Store" }}
        onCreateAction={() => {
          createStoreModal.onOpen();
        }}
        onDataFilter={() => {
          // dispatch(getAllFarmMappingsWithParams({ ...filterParams }));
          dispatch(getAllWarehouses());
        }}
        onEditAction={(selectedAction, { filterParams }) => {
          editStoreDisclosure.onOpen();
        }}
        // onSearch={(params, { signal }) => {
        //   console.log("search callback", { text: params.searchText });
        //   dispatch(searchAllFarmMappingsWithParams(params, { signal }));
        // }}
      />

      <Modal {...createStoreModal.modalProps}>
        <ModalContent title="Create a Store">
          <CreateStore modal={createStoreModal.modal} />
        </ModalContent>
      </Modal>
      <Modal {...editStoreDisclosure.modalProps}>
        <ModalContent title={`Edit ${selectedStore?.store?.name || "Store"} Details`}>
          <EditStoreDetails selected={selectedStore} modal={editStoreDisclosure.modal} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default StoresPage;
