import { useMutation, useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import Marketplace_API from "services/marketplaceApiService";

export function useGetMarketplaceOverview({ params }) {
  const fetcher = async () => {
    const { data } = await Marketplace_API.fetchMarketplaceOverview(params);
    return data;
  };
  // get all overview
  return useQuery({
    queryKey: [rqKeys.marketplace.fetchMarketplaceOverview, ...Object.values(params)],
    queryFn: fetcher,
  });
}

export function useGetTrades({ params }) {
  const fetcher = async () => {
    const data = await Marketplace_API.fetchAllTrades(params);

    return data;
  };
  return useQuery({
    queryKey: [rqKeys.marketplace.fetchAllTrades, ...Object.values(params)],
    queryFn: fetcher,
  });
}

export function useGetSingleTrade({ params }) {
  return useQuery([rqKeys.marketplace.fetchSingleTrade, ...Object.values(params)], () =>
    Marketplace_API.fetchSingleTrade(params)
  );
}

export function useGetSingleTradeByTradeId({ params }) {
  return useQuery([rqKeys.marketplace.fetchSingleTradeByTradeId, ...Object.values(params)], () =>
    Marketplace_API.fetchSingleTradeByTradeId(params)
  );
}

export function useGetOverviewTrades({ params }) {
  const fetcher = async () => {
    const _data = await Marketplace_API.fetchAllTrades(params);
    const { data, meta } = _data;
    return { data, meta };
  };
  // get all overview
  return useQuery({
    queryKey: [rqKeys.marketplace.fetchOverviewTrades, ...Object.values(params.dateRange)],
    queryFn: fetcher,
  });
}

export function useGetActualTrades() {
  const { data, error, isLoading } = useQuery({
    queryKey: [rqKeys.marketplace.fetchTradesRecon],
    queryFn: async () => {
      console.log("Fetching trade ");
      const result = await Marketplace_API.fetchTradesRecon();
      console.log("Fetched trade  result:", result);
      return result;
    },
  });

  console.log("Trade  query state:", { data, error, isLoading });

  return { data, error, isLoading };
}

export function useGetActualTradeByTradeId({ params }) {
  const { data, error, isLoading } = useQuery({
    queryKey: [rqKeys.marketplace.fetchTradesReconByTradeId, ...Object.values(params)],
    queryFn: async () => {
      const result = await Marketplace_API.fetchTradesReconByTradeId(params);
      console.log("Fetched trade  result:", result);
      return result;
    },
  });

  console.log("Trade  query state:", { data, error, isLoading });

  return { data, error, isLoading };
}
export function useGetActualTradeById({ params }) {
  const { data, error, isLoading } = useQuery({
    queryKey: [rqKeys.marketplace.fetchTradesReconById, ...Object.values(params)],
    queryFn: async () => {
      const result = await Marketplace_API.fetchTradesReconById(params);
      return result?.[0] ? result[0] : null;
    },
  });

  console.log("Trade  query state:", { data, error, isLoading });

  return { data, error, isLoading };
}

export function useGetActualTradeOverview({ params }) {
  const { data, error, isLoading } = useQuery({
    queryKey: [rqKeys.marketplace.fetchTradesReconOverview, ...Object.values(params)],
    queryFn: async () => {
      console.log("Fetching trade overview with params:", params);
      const result = await Marketplace_API.fetchTradesReconOverview(params); // Update this line if fetchTradesReconOverview takes parameters
      console.log("Fetched trade overview result:", result);
      return result;
    },
  });

  console.log("Trade overview query state:", { data, error, isLoading });

  return { data, error, isLoading };
}

// logistics

export function useGetLogistics() {
  const { data, error, isLoading } = useQuery({
    queryKey: [rqKeys.marketplace.fetchAllLogistics],
    queryFn: async () => await Marketplace_API.fetchAllLogistics(),
  });

  return { data, error, isLoading };
}
// trade reconciliation
export function useGetTradeReconciliation({ params }) {
  return useQuery(rqKeys.marketplace.fetchAllTradeReconciliations, () =>
    Marketplace_API.fetchAllTradeReconciliations(params)
  );
}

// invoice

export function useGetInvoice({ params }) {
  const fetcher = async () => {
    const _data = await Marketplace_API.fetchAllInvoice(params);
    const { data, meta } = _data;
    return { data, meta };
  };

  return useQuery({
    queryKey: [rqKeys.marketplace.fetchAllInvoice, ...Object.values(Object.freeze(params))],
    queryFn: fetcher,
  });
}

export function useGetOneInvoice({ params }) {
  const fetcher = async () => {
    const _data = await Marketplace_API.fetchOneInvoice(params);
    const { data } = _data;
    console.log(_data);
    return { data };
  };

  return useQuery({
    queryKey: [rqKeys.marketplace.fetchAllInvoices, ...Object.values(Object.freeze(params))],
    queryFn: fetcher,
  });
}

export function useGetSingleInvoice({ params }) {
  const fetcher = async () => {
    const _data = await Marketplace_API.fetchSingleInvoiceById(params);
    const { data, meta } = _data;
    return { data, meta };
  };

  return useQuery({
    queryKey: [rqKeys.marketplace.fetchSingleInvoice, ...Object.values(params)],
    queryFn: fetcher,
  });
}

export function useGenerateInvoice() {
  return useMutation(Marketplace_API.generateInvoice, {
    // onMutate: submittedValues => {
    //   const transformedValues = submittedValues; // do some manipulation everytime before sending to mutate functions
    //   return transformedValues;
    // },
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.marketplace.generateInvoice);
      console.log({ data });
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong while generating invoice");
      bug.report(error);
    },
  });
}

// farmerpayment

export function useGetFarmerPayment({ params }) {
  return useQuery(
    [rqKeys.marketplace.fetchFarmerPayment, ...Object.values(params)],
    async () => await Marketplace_API.fetchFarmerPayment(params)
  );
}
