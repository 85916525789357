import React from "react";
import { FormGroup } from "reactstrap";
import PropTypes from "prop-types";

const FormGridRowPropTypes = {
  children: PropTypes.node,
  check: PropTypes.bool,
};

const FormGridRow = props => {
  const { children, check, className, style } = props;

  let classNames = `ca-font-regular ${className}`;
  return (
    <FormGroup className={classNames} check={check} style={style} row>
      {children}
    </FormGroup>
  );
};

FormGridRow.propTypes = FormGridRowPropTypes;

export default FormGridRow;
