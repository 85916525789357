import _ from "lodash";
import React, { useEffect, useState } from "react";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import PropTypes from "prop-types";
import { Select } from "components/select";
import { getStatesOptions } from "./options/stateSelectOptions";
import { useDidMountEffect } from "hooks";

const stateSelectPropTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

const StateSelect = ({
  onChange,
  value,
  defaultValue,
  name,
  label,
  countryCode = "NG",
  countryName,
  menuPlacement = "bottom",
  required,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const [statesOptions, setStatesOptions] = useState([]);

  const _handleChange = selectedValue => {
    setSelectedOption(selectOptionFromValue(statesOptions, selectedValue));
    if (_.isFunction(onChange)) onChange(selectedValue);
  };

  useDidMountEffect(() => {
    let newOption = selectOptionFromValue(statesOptions, value);
    if (newOption) {
      setSelectedOption(newOption);
    }
  }, [value]);

  useEffect(() => {
    if (defaultValue) setSelectedOption({ label: defaultValue, value: defaultValue });
  }, []);

  // useDidMountEffect(() => {
  //   if (defaultValue) {
  //     let newOption = selectOptionFromValue(statesOptions, defaultValue);
  //     if (newOption) {
  //       setSelectedOption(newOption);
  //     }
  //   }
  // }, []);

  useDidMountEffect(() => {
    const initStatesSelect = () => {
      //TODO: select country automatically or provide a select for countries
      // const ignoredcountries = findOrganizationCountries();
      let _states = getStatesOptions({ countryName });
      // console.log({ _states });
      setStatesOptions(_states);
    };

    // console.log({ countryName });
    initStatesSelect();
  }, [countryName, countryCode]);

  return (
    <>
      <Select
        name={name}
        placeholder="Select State"
        options={statesOptions}
        onChange={_handleChange}
        value={selectedOption}
        label={label}
        required={required}
        menuPlacement={menuPlacement}
      />
    </>
  );
};

StateSelect.propTypes = stateSelectPropTypes;

export { StateSelect };
