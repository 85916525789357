import React from "react";
import Select2 from "./select2";

const ModuleSelect = ({ label, placeholder, ...props }) => {
  const allModules = [
    { value: "farm_mapping", label: "Farming Mapping" },
    { value: "farm_monitoring", label: "Farm Monitoring" },
    { value: "harvest", label: "Harvest" },
    { value: "clearance", label: "Clearance" },
  ];

  return (
    <Select2
      label={label}
      placeholder={placeholder}
      //  isLoading={loadingRegions}
      options={allModules}
      {...props}
    />
  );
};

export default ModuleSelect;
