import { useState } from "react";
import _ from "lodash";
import { useLegacyDidMountEffect } from "hooks";

/**
 * hook to use with CustomModal component to manage visible state
 * @param {Object} config - optional config object
 * @param {function} config.onModalClose - calback to execute when modal gets Closed
 * Usage:
 * ======
 * import useCustomModal from "hooks/useCustomModal"
 *
 * ...
 * const someModal = useCustomModal()
 *
 * ...
 *
 * const handleSomeOperation = () => {
 *      someModal.hideModal()
 * }
 *
 * return (
 *      <CustomModal modal={someModal}>
 *        <SomeModalContent />
 *      </CustomModal>
 * )
 *
 * Config
 * =====
 * - **onModalClose** => type(function): perform any cleanup operation whenever a
 *                                   CustomModal is closed
 * - **actions** => type(object):  enable dynamic action handler, depending on the current
 *                              action key, the corresponding action callback is applied
 *
 */

function useCustomModal(config = {}) {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal = () => {
    // if (config?.onModalClose) config.onModalClose();
    setIsOpen(false);
  };
  const toggleModal = () => {
    setIsOpen(modalState => !modalState);
  };

  useLegacyDidMountEffect(() => {
    if (!isOpen && _.isFunction(config.onModalClose)) config.onModalClose();
  }, [isOpen]);

  return {
    isOpen,
    showModal,
    hideModal,
    toggleModal,
  };
}

export { useCustomModal };
