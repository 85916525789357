// import { EmptyState } from "components/appState";
import React from "react";
import { Outlet } from "react-router-dom";

const Messaging = () => {
  // return <EmptyState title="Bulk Messaging Feature" subtitle="Coming Soon..." />;
  return <Outlet />;
};

export default Messaging;
