export const tableConstants = {
  defaultActionKeys: {
    VIEW_ACTION_KEY: "view",
    EDIT_ACTION_KEY: "edit",
    DELETE_ACTION_KEY: "delete",
  },
  actionKeys: {
    VIEW_ACTION_KEY: "view",
    EDIT_ACTION_KEY: "edit",
    DELETE_ACTION_KEY: "delete",
    CREATE_ACTION_KEY: "create",
  },
};
