import { Select } from "components/select";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import genderOptions from "./options/genderSelectOptions";

const GenderSelect = props => {
  const { onChange, value, name, label, required, menuPlacement = "bottom", dependencies = [] } = props;

  const [selectedOption, setSelectedOption] = useState(null);

  const _handleChange = selectedValue => {
    setSelectedOption(selectOptionFromValue(genderOptions, selectedValue));
    if (_.isFunction(onChange)) onChange(selectedValue);
  };

  useEffect(() => {
    let newOption = selectOptionFromValue(genderOptions, value);
    if (newOption) setSelectedOption(newOption);
  }, [...dependencies]);

  return (
    <Select
      name={name}
      label={label}
      required={required}
      value={selectedOption}
      placeholder="Select Gender"
      options={genderOptions}
      onChange={_handleChange}
      menuPlacement={menuPlacement}
    />
  );
};

export { GenderSelect };
