import { getUserData } from "features/v1/auth";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserScope, initialRoleState, resolveRoleFlags } from "utils/ta-utils/role-utils";

const defaultUseRoleState = { ...initialRoleState, canWrite: [], canRead: [], canUpdate: [], role: null };

export function useAppRoles() {
  const [roleState, setRoleState] = useState(defaultUseRoleState);

  const user = useSelector(getUserData);

  let userScope = getUserScope();

  const isRole = useCallback(
    role => {
      return user?.role && `${user.role}` === `${role}`;
    },
    [user?.role]
  );

  useEffect(() => {
    if (!userScope) {
      return;
    }

    const _readableResources = [];
    const _writableResources = [];
    const _updatableResources = [];
    const scopeArray = userScope.split(",");

    scopeArray.forEach(scopeItem => {
      const [resource, permission] = scopeItem.split(".");
      if (permission === "read") {
        _readableResources.push(resource);
      }
      if (permission === "write") {
        _writableResources.push(resource);
      }
      if (permission === "update") {
        _updatableResources.push(resource);
      }
    });
    setRoleState(oldState => ({
      ...oldState,
      canRead: _readableResources,
      canWrite: _writableResources,
      canUpdate: _updatableResources,
    }));
  }, [userScope]);

  useEffect(() => {
    // setRoleFlags

    if (user?.role) {
      setRoleState(oldState => ({ ...oldState, ...resolveRoleFlags(user.role), role: user.role }));
    }
  }, [user.role]);

  return { ...roleState, isRole };
}
