import React, { useEffect, useState } from "react";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoles, getLoadingRoles, getRoleOptions } from "features/v1/role";
import { Select } from ".";

const RoleSelect = props => {
  const { onChange, name, label, defaultValue, required } = props;

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);
  const [permittedOptions, setPermittedOptions] = useState([]);

  let roleOptions = useSelector(getRoleOptions);
  let loading = useSelector(getLoadingRoles);

  const _handleChange = selectedValue => {
    setSelectedOption(selectOptionFromValue(roleOptions, selectedValue));
    if (_.isFunction(onChange)) onChange(selectedValue);
  };

  useEffect(() => {
    let newOption = selectOptionFromValue(roleOptions, defaultValue);
    if (newOption) setSelectedOption(newOption);
  }, []);

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  useEffect(() => {
    setPermittedOptions(() => {
      const filteredRoles = roleOptions.filter(
        role => !role.scope.includes("all.read") || !role.scope.includes("all.write")
      );
      return [...filteredRoles];
    });
  }, [roleOptions]);

  return (
    <Select
      name={name}
      label={label}
      loading={loading}
      required={required}
      value={selectedOption}
      onChange={_handleChange}
      options={permittedOptions}
      placeholder="Select User Role"
    />
  );
};

export { RoleSelect };
