import { useMutation } from "@tanstack/react-query";
import { apiConstants } from "constants/apiConstants";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import { axiosService } from "services/fetchService";
import Marketplace_API from "services/marketplaceApiService";
import { getApiUrl } from "utils";

// trade
export function useCreateTrade() {
  return useMutation({
    mutationFn: values => {
      return Marketplace_API.createTrade(values);
    },
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.marketplace.createTrade);
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong creating create");
      bug.report(error);
    },
  });
}
export function useUpdateTrade() {
  return useMutation({
    mutationFn: Marketplace_API.updateTrade,
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.marketplace.fetchAllTrades);
      appQueryClient.invalidateQueries(rqKeys.marketplace.fetchAllTradeReconciliations);
      console.log({ data });
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong updating trade");
      bug.report(error);
      console.log({ error });
    },
  });
}

// logistics

export function useCreateLogistics() {
  return useMutation(Marketplace_API.createLogistics, {
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.marketplace.fetchAllLogistics);
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong creating logistics");
      bug.report(error);
    },
  });
}

export function useUpdatelogistics() {
  return useMutation(Marketplace_API.updateLogistics, {
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.marketplace.updateLogistics);
      console.log({ data });
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong creating create");
      bug.report(error);
    },
  });
}

// trade reconcilation
const createTradeReconciliation = async values => {
  const url = getApiUrl({ endpointName: apiConstants.endpoints.marketplace, path: "trade/createActualTrade" });
  const { data } = await axiosService({ method: "POST", url, data: values });
  return data;
};

export function useCreateTradeReconciliation() {
  return useMutation({
    mutationFn: createTradeReconciliation,
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.marketplace.fetchAllTradeReconciliations);
      console.log({ data });
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong  trade reconciliation");
      bug.report(error);
    },
  });
}

export function useUpdateTradeReconcililation() {
  return useMutation(Marketplace_API.updateTradeReconciliation, {
    onSuccess: data => {
      appQueryClient.invalidateQueries(rqKeys.marketplace.updateLogistics);
      console.log({ data });
      return data;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong creating create");
      bug.report(error);
    },
  });
}
