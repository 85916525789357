/* eslint-disable no-unused-vars */
import { Table } from "components/table";
import {
  getAllFarmerPaymentsWithParams,
  getFarmerPaymentDateRange,
  getFarmerPaymentsData,
  getFarmerPaymentsMeta,
  searchAllFarmerPaymentsWithParams,
} from "features/v1/farmerPayment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRiskAgentTable from "./useRiskAgentTable";
import { setActiveRow } from "features/v1/riskManagement";

const RiskAgent = () => {
  const dispatch = useDispatch();

  const { tableProps, selectedRow } = useRiskAgentTable();

  const getExtraActions = () => {
    const paymentExtraActions = [{ label: "View", actionKey: "viewAgents" }];

    return paymentExtraActions;
  };

  useEffect(() => {
    dispatch(setActiveRow(null));
  }, []);

  return (
    <Table
      {...tableProps}
      onSearch={(params, { abortController }) => {
        dispatch(
          searchAllFarmerPaymentsWithParams(
            { ...params },
            { abortController } // cancel token
          )
        );
      }}
      onDataFilter={({ seasonId, region, dateRange, nPerPage, page }) => {
        dispatch(getFarmerPaymentDateRange(dateRange.map(d => new Date(d).toISOString())));
        dispatch(
          getAllFarmerPaymentsWithParams({
            nPerPage,
            page,
            dateRange,
          })
        );
      }}
      onExtraAction={({ actionKey }) => {
        if (actionKey === "viewAgents") {
          // navigate("/dashboard/risk/survey/1");
        }
      }}
      getExtraActions={getExtraActions}
    />
  );
};

export default RiskAgent;
