import { Table } from "components/table";
import { useGetFarmerPayment, useGetSingleTrade } from "hooks/app/marketplace";
import { Badge, Flex, Text, badgeConstants } from "organic";
import React from "react";
import { useTable } from "hooks";
import { appGet, capitalize } from "utils";
import { useParams } from "react-router-dom";

const page = 1;
const nPerPage = 20;
const payment_type = "trade";

export const resolveMarketplacePaymentStatusColor = status => {
  switch (`${status}`?.toLowerCase?.()) {
    case "Pending":
      return badgeConstants.colorSchemes.ORANGE;
    case "Processed":
      return badgeConstants.colorSchemes.SUCCESS;
    case "Declined":
      return badgeConstants.colorSchemes.ERROR;
  }
};

const Aggregator = () => {
  const { tradeId } = useParams();
  const { data } = useGetSingleTrade({ params: { id: tradeId } });
  const trade_id = appGet(data, "trade_id", "");
  const { data: paymentData } = useGetFarmerPayment({ params: { trade_id: trade_id, payment_type, page, nPerPage } });
  const {
    tableProps,
    //  selectedRow: selectedTrade
  } = useTable({
    data: appGet(paymentData, "data", []),
    columns: [
      {
        Header: "ID",
        accessor: "trade_id",
      },
      {
        id: "agent",
        Header: "Agent/Coordinator",
        accessor: data =>
          `${appGet(data, "farmer.agent.first_name", "")} ${appGet(data, "farmer.agent.last_name", "")}`,
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Date Created",
        accessor: data => {
          const createdAt = appGet(data, "created_at", "");
          if (createdAt) {
            const date = new Date(createdAt);
            return date.toLocaleDateString();
          }
          return "";
        },
      },
      {
        Header: "Farmer Names",
        accessor: data => appGet(data, "farmer.first_name", ""),
      },
      {
        Header: "Farmers Reg Number",
        accessor: data => appGet(data, "farmer.farmer_id", ""),
      },
      {
        Header: "Crop Type",
        accessor: data => appGet(data, "crop_type.name", ""),
      },
      {
        Header: "Units (KG)",
        accessor: data => appGet(data, "unit", ""),
      },
      {
        Header: "Price Per (KG)",
        accessor: data => appGet(data, "price", ""),
      },
      {
        Header: "No. of Bags",
        accessor: data => appGet(data, "no_of_bags", ""),
      },
      {
        Header: "Amount (KES)",
        accessor: data => appGet(data, "amount", ""),
      },
      {
        Header: "Account Number",
        accessor: data => appGet(data, "accountNumber", ""),
      },
      {
        Header: "Status",
        accessor: "payment_status",
        Cell: ({ value }) => (
          <Badge colorScheme={resolveMarketplacePaymentStatusColor(value)}>{capitalize(value)}</Badge>
        ),
      },
    ],
    useServerPagination: true,
    serverPaginationMeta: paymentData?.meta || {},
    title: "All aggregator",
    noAction: true,
  });

  return (
    <Flex flexDirection="column">
      <Table {...tableProps} />
    </Flex>
  );
};

export default Aggregator;
