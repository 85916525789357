import { FormInput } from "components/inputs";
import React from "react";

export const ActualQuantityRejectedField = () => {
  return (
    <FormInput
      label="Actual Quantity Rejected (KG)"
      type="number"
      placeholder="Actual Quantity Rejected (KG)"
      name="actual_quantity_rejected"
      required
    />
  );
};
