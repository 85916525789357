/* eslint-disable no-unused-vars */
import { Table } from "components/table";
import {
  getAllFarmerPaymentsWithParams,
  getFarmerPaymentDateRange,
  getFarmerPaymentsData,
  getFarmerPaymentsMeta,
  searchAllFarmerPaymentsWithParams,
} from "features/v1/farmerPayment";
import { Flex } from "organic";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useRiskSurveyTable from "./useRiskSurveyTable";
import { useNavigate } from "react-router-dom";
import { getRiskSurveyMeta } from "features/v1/riskManagement";

const RiskSurvey = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const riskSurveyMeta = useSelector(getRiskSurveyMeta);

  const { tableProps, selectedRow } = useRiskSurveyTable();

  const getExtraActions = payment => {
    const paymentExtraActions = [
      // { label: "Edit details", actionKey: "editDetails" },
      { label: "View risk agents", actionKey: "viewAgents" },
    ];

    return paymentExtraActions;
  };

  const data = [
    {
      title: "Total Risk Surveys",
      value: riskSurveyMeta?.totalRiskSurvey || 0,
    },
    {
      title: "Total Validated Farmers ",
      value: riskSurveyMeta?.totalValidatedFarmers || 0,
    },
    {
      title: "Total Flagged Farmers ",
      value: riskSurveyMeta?.totalFlaggedFarmers || 0,
    },
  ];

  return (
    <>
      <Flex flexDirection="column">
        <div className="payments-summary">
          <div className="payments-summary__grid">
            {data.map((d, index) => {
              return (
                <div className="payments-summary__gridItem" key={index}>
                  <div className="payments-summary__gridItem__Title">{d.title}</div>
                  <div className="payments-summary__gridItem__Value">{d.value.toLocaleString()}</div>
                </div>
              );
            })}
          </div>
        </div>
        <Table
          {...tableProps}
          onSearch={(params, { abortController }) => {
            dispatch(
              searchAllFarmerPaymentsWithParams(
                { ...params },
                { abortController } // cancel token
              )
            );
          }}
          onDataFilter={({ seasonId, region, dateRange, nPerPage, page }) => {
            dispatch(getFarmerPaymentDateRange(dateRange.map(d => new Date(d).toISOString())));
            dispatch(
              getAllFarmerPaymentsWithParams({
                nPerPage,
                page,
                dateRange,
              })
            );
          }}
          onExtraAction={({ actionKey }) => {
            if (actionKey === "viewAgents") {
              navigate(`/dashboard/risk/survey/${selectedRow._id}`);
            }
            // if (actionKey === "reject") {
            //   rejectionCommentModal.onOpen();
            // }
            // if (actionKey === "payout") {
            //   confirmPayoutModal.onOpen();
            // }
            // if (actionKey === "decline") {
            //   declinePaymentModal.onOpen();
            // }
          }}
          getExtraActions={getExtraActions}
        />
      </Flex>
    </>
  );
};

export default RiskSurvey;
