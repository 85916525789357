import AverageFieldSize from "views/charts/averageFieldSize";
import AverageGroupSize from "views/charts/averageGroupsSize";
import NoOfFieldsMapped from "views/charts/noOfFieldsMapped";
import NoOfSubscriptions from "views/charts/noOfSubscriptions";
import TotalAgents from "views/charts/totalAgents";
import TotalFarmers from "views/charts/totalFarmers";
import TotalFieldsMapped from "views/charts/totalFieldsMapped";
import TotalGroups from "views/charts/totalGroups";

export const TOTAL_GROUPS_KEY = "totalGroups";
export const TOTAL_FARMERS_KEY = "totalFarmers";
export const AVERAGE_GROUP_SIZE_KEY = "averageGroupSize";
export const AVERAGE_FIELD_SIZE_KEY = "averageFieldSize";
export const TOTAL_FIELDS_MAPPED_KEY = "totalFieldsMapped";
export const NO_OF_FIELDS_MAPPED_KEY = "noOfFieldsMapped";
export const NO_OF_SUBSCRIPTIONS_KEY = "noOfSubscriptions";
export const TOTAL_AGENTS_KEY = "totalAgents";

export const leftGridSchema = [
  {
    key: TOTAL_GROUPS_KEY,
    title: "Total Groups",
    subtitle: "Groups",
    type: "area",
    height: 122,
    width: "100%",
    component: TotalGroups,
  },
  {
    key: TOTAL_FARMERS_KEY,
    title: "Total Farmers",
    subtitle: "Farmers",
    type: "area",
    height: 122,
    width: "100%",
    component: TotalFarmers,
  },
  {
    key: AVERAGE_GROUP_SIZE_KEY,
    title: "Average Group Size",
    subtitle: "People",
    type: "area",
    height: 122,
    width: "100%",
    component: AverageGroupSize,
  },
  {
    key: AVERAGE_FIELD_SIZE_KEY,
    title: "Average Field Size",
    subtitle: "Hectares",
    type: "area",
    height: 122,
    width: "100%",
    component: AverageFieldSize,
  },

  {
    key: TOTAL_FIELDS_MAPPED_KEY,
    title: "Total Fields Mapped",
    subtitle: "Hectares",
    type: "area",
    height: 122,
    width: "100%",
    component: TotalFieldsMapped,
  },
  {
    key: NO_OF_FIELDS_MAPPED_KEY,
    title: "No. of Fields Mapped",
    subtitle: "Feilds",
    type: "area",
    height: 122,
    width: "100%",
    component: NoOfFieldsMapped,
  },
  {
    key: NO_OF_SUBSCRIPTIONS_KEY,
    title: "No of Subscriptions",
    subtitle: "Subscriptions",
    type: "area",
    height: 122,
    width: "100%",
    component: NoOfSubscriptions,
  },

  {
    key: TOTAL_AGENTS_KEY,
    title: "Total Agents",
    subtitle: "Agents",
    type: "area",
    height: 122,
    width: "100%",
    component: TotalAgents,
  },
];
