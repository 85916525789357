import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCustomModal, useDisclosure, useTable } from "hooks";
import { formatAmount, legacyFormatDate } from "utils/v1/tableUtils";

import {
  getAllClearedFarmersWithParams,
  getClearedFarmersData,
  getClearedFarmersMeta,
  //   getLoadingClearedGroups,
  searchClearedGroupWithParams,
} from "features/v1/farmerClearance";
import { Table } from "components/table";
import { getLeadFarmerFromList } from "utils";
import { Modal, ModalContent } from "components/modal";
import ViewClearanceDetails from "./viewClearanceDetails";
import UpdateGroupFarmSize from "./updateGroupFarmSize";

const ClearedFarmersPage = () => {
  const dispatch = useDispatch();

  let clearedGroupsData = useSelector(getClearedFarmersData);
  let clearedGroupsMeta = useSelector(getClearedFarmersMeta);
  //   let loading = useSelector(getLoadingClearedGroups);

  const ignoredclearedGroupModal = useCustomModal();

  const viewModal = useDisclosure();
  const editModal = useDisclosure();

  const { tableProps, selectedRow: selectedClearance } = useTable({
    data: clearedGroupsData,
    columns: [
      {
        Header: "Group ID",
        accessor: "groupId",
      },
      {
        id: "leadFarmerFullName",
        Header: "Lead Farmer Name",
        accessor: group =>
          `${getLeadFarmerFromList(group.farmers)?.first_name || "N/A"} ${
            getLeadFarmerFromList(group.farmers)?.last_name || "N/A"
          }`,
      },
      {
        id: "leadFarmerPhone",
        Header: "Lead Farmer Phone",
        accessor: group => getLeadFarmerFromList(group.farmers)?.phone_number || "N/A",
      },
      {
        id: "leadFarmerTA",
        Header: "Lead Farmer TA",
        accessor: group => getLeadFarmerFromList(group.farmers)?.ta_number || "N/A",
      },
      {
        id: "clearedDate",
        Header: "Date",
        accessor: group => legacyFormatDate(group.created_at),
      },
      {
        id: "commitentFeeTotal",
        Header: "Commitment Fee Total",
        accessor: group => (
          <span>
            {formatAmount(group.commitmentFeeAmount)} {group.commitmentFeePaid && "(Paid)"}
          </span>
        ),
      },
      {
        Header: "Equity Contribution Total",
        accessor: group => (
          <span>
            {formatAmount(group.equityContributionAmount)} {group.paidEquityContribution && "(Paid)"}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: group => (group.clearedForInputDistribution ? "Cleared" : "Not Cleared"),
      },
    ],
    title: "Cleared Farmers",
    useServerPagination: true,
    serverPaginationMeta: clearedGroupsMeta,
  });

  return (
    <>
      <Table
        {...tableProps}
        onDataFilter={params => {
          dispatch(getAllClearedFarmersWithParams(params));
        }}
        onSearch={(params, { abortController }) => {
          dispatch(searchClearedGroupWithParams(params, { abortController }));
        }}
        onViewAction={() => {
          viewModal.onOpen();
        }}
        onEditAction={() => {
          editModal.onOpen();
        }}
      />
      {/* view clearance */}
      <Modal
        open={viewModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            viewModal.onClose();
          }
        }}
      >
        <ModalContent size="md" title="Group Clearance Details">
          <ViewClearanceDetails selected={selectedClearance} />
        </ModalContent>
      </Modal>
      {/* edit groupn farm size */}
      <Modal
        open={editModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            editModal.onClose();
          }
        }}
      >
        <ModalContent size="md" title={`Edit Group ${selectedClearance?.groupId || ""} Farm Size`}>
          <UpdateGroupFarmSize selected={selectedClearance} modal={editModal.modal} />
        </ModalContent>
      </Modal>
      {/* <EditGroupFarmSize selected={selectedGroup} modal={clearedGroupModal} paginationMeta={clearedGroupsMeta} />
      <ViewClearedGroup selected={selectedGroup} modal={clearedGroupModal} paginationMeta={clearedGroupsMeta} /> */}
    </>
  );
};

export default ClearedFarmersPage;
