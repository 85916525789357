import React from "react";
import warningIcon from "../../assets/icons/warning-icon.svg";
import { Box, Button, Flex, Text } from "organic";
import { capitalize } from "utils";

export const ConfirmDialog = ({ heading, subHeading, loading, modal, confirmationText, onConfirm }) => {
  return (
    <Flex px="1.5rem" py="1.5rem" alignItems="center" flexDirection="column" justifyContent="center" textAlign="center">
      <img width={50} src={warningIcon} alt="reject-icon" />
      <Text font="xlMedium" mt={2}>
        {heading}
      </Text>
      <Box mt={2}>
        <Text font="smRegular" color="#667085" dangerouslySetInnerHTML={{ __html: subHeading }}></Text>
      </Box>
      <Flex mt={40} gap="20px" width="100%">
        <Button
          display="block"
          type="button"
          colorScheme="secondaryGray"
          isDisabled={loading}
          onClick={() => modal.hideModal}
        >
          Cancel
        </Button>
        <Button onClick={onConfirm} display="block" type="button" isDisabled={loading}>
          {capitalize(confirmationText)}
        </Button>
      </Flex>
    </Flex>
  );
};
