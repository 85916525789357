import React from "react";
import DatePicker from "react-date-picker";

const CustomSingleDatePicker = props => {
  const { date, handleDateChange } = props;

  return <DatePicker value={date} onChange={handleDateChange} format="dd.MM.y" />;
};

export default CustomSingleDatePicker;
