import styled, { css } from "styled-components/macro";

export const StyledAvatar = styled.div`
  ${props => css`
    width: ${props.size};
    height: ${props.size};
    border-radius: ${props.borderRadius};
    background-color: ${props.backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      color: ${props.iconColor};
      path {
        color: ${props.iconColor};
      }
    }
    button {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      &:active {
        opacity: 0.6;
      }
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.6;
    }
    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 2px ${props.focusColor};
    }
  `}
`;
