import * as Yup from "yup";

export const createAdminValidator = Yup.object().shape({
  role: Yup.string().required("user role is required"),
  first_name: Yup.string().required("first name is required"),
  last_name: Yup.string().required("last name is required"),
  email: Yup.string().required("email is requierd").email("Invalid email"),
  phone_number: Yup.string().required("phone number (User ID is required)"),
  password: Yup.string().required("please enter a valid password"),
  gender: Yup.string().required("gender is required"),
  region: Yup.string().required("region is required"),
  zone: Yup.string(), // .required("zone is required"),
  state: Yup.string().required("state is required"),
  lga: Yup.string().required("lga is required"),
  organization: Yup.string(),
});
