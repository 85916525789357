import React, { useState, useMemo, useEffect, useRef } from "react";
import { EmptyState } from "components/appState";
import { ConfirmDialog, RejectWithCommentDialog } from "components/confirmDialog";
import { PageHeader, PageWrapper, Panel, Divider, VisibilityWrapper, FormGridHeader } from "components/layout";
import { Modal, ModalContent } from "components/modal";
import { selectConstants } from "components/select";
import { useDisclosure } from "hooks";
import { useGetSingleTrade, useUpdateTrade } from "hooks/app/marketplace";
import {
  Badge,
  Button,
  Flex,
  Icon,
  Text,
  Box,
  Input,
  Avatar,
  TextBox,
  TextArea,
  badgeConstants,
  AvatarGroup,
} from "organic";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { appGet, capitalize, deleteObjProps, formatDate, queryStringToObject } from "utils";
import { Formik, Form } from "formik";
import CurrencySelect from "components/select/currencySelect";
import { SummaryGrid } from "components/grid";
import numeral from "numeral";
import Aggregator from "./aggregatorTable";
import { useAllAdminData, useAppRoles } from "hooks/app";
import { handleFormSubmission } from "services/formSubmissionService";
import { tradeStatuses } from "../marketplaceTradePage";
import { ScopedView } from "components/layout";
import { resources } from "services/v1/rolesAndPermissionsService";
import { ProfitMarginField } from "../tradeForm/profitMarginField";
import { TotalInvestmentField } from "../tradeForm/totalInvestmentField";
import { PriceToPayInput } from "../tradeForm/priceToPayInput";
import TargetQuantityInput from "../tradeForm/targetQuantityInput";
import { AnticipatedProfitField } from "../tradeForm/anticipatedProfitField";
import { SellingPriceInput } from "../tradeForm/sellingPriceInput";
import { CostPriceField } from "../tradeForm/costPriceField";
import { CropTypeField } from "./cropTypeField";
import { CommissionFeeInput } from "../tradeForm/commissionFeeInput";
import { AssignAgentsField } from "../tradeForm/assignAgentsField";
import { TradeRevenueField } from "../tradeForm/tradeRevenueField";
import { LogisticsTypeField } from "../tradeForm/logisticsTypeField";
import { sanitizeMongoDoc } from "utils/v1/sanitize";
import { TruncatedList } from "components/list";
import { useSelector } from "react-redux";
import { getUserData } from "features/v1/auth";

const PAGE_VIEW_MODE = "view";
const PAGE_EDIT_MODE = "edit";
const TRADE_DETAILS = "tradeDetails";
const AGGREGATOR = "aggregator";
const COMMENT_TAB = "commentTab";
const ACTIVITY_TAB = "activityTab";

const getEditTradeInitialValues = trade => {
  return {
    initiated_by: appGet(trade, "initiated_by", ""),
    crop_type: appGet(trade, "crop_type", ""),
    cost_price: appGet(trade, "cost_price", ""),
    selling_price: appGet(trade, "selling_price", ""),
    anticipated_profit: appGet(trade, "anticipated_profit", ""),
    logistics_type: appGet(trade, "logistics_type", [])?.map?.(type => {
      return {
        name: appGet(type, "name", ""),
        amount: appGet(type, "amount", ""),
        unit: appGet(type, "unit", ""),
        price_per_unit: appGet(type, "price_per_unit", ""),
      };
    }),
    logistics_cost: appGet(trade, "logistics_cost", "0"),
    target_quantity: appGet(trade, "target_quantity", "0"),
    assigned_agents: appGet(trade, "assigned_agents", []),
    price_to_pay: appGet(trade, "price_to_pay", "0"),
    commission_fee: appGet(trade, "commission_fee", "0"),
    total_investment: appGet(trade, "total_investment", "0"),
    status: appGet(trade, "status", ""),
    profit_margin: appGet(trade, "profit_margin", "0"),
    commodity_average: appGet(trade, "commodity_average", 0),
    declined_comment: appGet(trade, "declined_comment", ""),
    trade_id: appGet(trade, "trade_id", ""),
    target_achieved: appGet(trade, "target_achieved", 0),
    trade_farmers: appGet(trade, "trade_farmers", 0),
    percentage_profit: appGet(trade, "percentage_profit", "0"),
    total_trade_price: appGet(trade, "total_trade_price", "0"),
    trade_finance_fee: appGet(trade, "trade_finance_fee", "0"),
    approved_by: appGet(trade, "approved_by", ""),
  };
};
export const resolveMarketplaceTradeStatusColor = status => {
  switch (`${status}`?.toLowerCase?.()) {
    case "pending":
      return badgeConstants.colorSchemes.ORANGE;
    case "approved":
      return badgeConstants.colorSchemes.SUCCESS;
    case "declined":
      return badgeConstants.colorSchemes.ERROR;
    case "stocked":
      return badgeConstants.colorSchemes.PURPLE;
    case "delivered":
      return badgeConstants.colorSchemes.BLUE;
    case "finalised":
      return badgeConstants.colorSchemes.BLUE;
    case "closed":
      return badgeConstants.colorSchemes.SUCCESS;
    default:
      return badgeConstants.colorSchemes.GRAY;
  }
};

const TradeDetails = () => {
  const user = useSelector(getUserData);
  // router hooks
  const { tradeId } = useParams();
  const [searchParams, __] = useSearchParams();
  const navigate = useNavigate();

  // custom hooks
  const appRoles = useAppRoles();
  const { getSingleAdmin } = useAllAdminData();

  const confirmApproveModal = useDisclosure();
  const confirmRejectModal = useDisclosure();

  // query hooks
  const { data, isLoading, error } = useGetSingleTrade({ params: { id: tradeId } });

  // mutation hooks
  const { mutateAsync } = useUpdateTrade();

  // useMemo
  const isApprovedStatus = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(data, "status", options?.status) === tradeStatuses.APPROVED;
  }, [searchParams, data]);

  const isPendingStatus = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(data, "status", options?.status) === tradeStatuses.PENDING;
  }, [searchParams, data]);

  const isDeclinedStatus = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(data, "status", options?.status) === tradeStatuses.DECLINED;
  }, [searchParams, data]);

  const mode = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(options, "mode", "view");
  }, [searchParams]);

  const isEditMode = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(options, "mode", "view") === PAGE_EDIT_MODE;
  }, [searchParams]);

  const canCommercialSpecialistEdit = useMemo(() => {
    const _isPending = appGet(data, "status", queryStringToObject(searchParams)?.status) === tradeStatuses.PENDING;
    const _isDeclined = appGet(data, "status", queryStringToObject(searchParams)?.status) === tradeStatuses.DECLINED;
    return isEditMode && (_isPending || _isDeclined) && appRoles?.role && appRoles.role === "commercialSpecialist";
  }, [appRoles.role, data, isEditMode, searchParams]);

  const canRegionalManagerEdit = useMemo(() => {
    const _isPending = appGet(data, "status", queryStringToObject(searchParams)?.status) === tradeStatuses.PENDING;
    const _isDeclined = appGet(data, "status", queryStringToObject(searchParams)?.status) === tradeStatuses.DECLINED;
    const _isApproved = appGet(data, "status", queryStringToObject(searchParams)?.status) === tradeStatuses.APPROVED;
    return (
      isEditMode && (_isPending || _isDeclined || _isApproved) && appRoles?.role && appRoles.role === "regionalManager"
    );
  }, [appRoles.role, data, isEditMode, searchParams]);

  const initialValues = useMemo(() => getEditTradeInitialValues(data), [data]);

  // useState
  const [activeTopTab, setActiveTopTab] = useState(TRADE_DETAILS);
  const [activeBottomTab, setActiveBottomTab] = useState(isPendingStatus ? COMMENT_TAB : ACTIVITY_TAB);
  const [createdBy, setCreatedBy] = useState();
  const createdByRef = useRef(null);

  // functions and callback
  const getActiveTopTab = tabId => {
    return activeTopTab === tabId;
  };

  const getActiveBottomTab = tabId => {
    return activeBottomTab === tabId;
  };

  const createOption = item => {
    return item
      ? {
          value: item,
          label: item,
        }
      : null;
  };

  const handleTopTabChange = (e, tabId) => {
    e.preventDefault();
    if (activeTopTab === tabId) {
      return;
    }
    setActiveTopTab(tabId);
  };

  const handleBottomTabChange = (e, tabId) => {
    e.preventDefault();
    if (activeTopTab === tabId) {
      return;
    }
    setActiveBottomTab(tabId);
  };

  const handleUpdateTrade = async (values, __formOptions) => {
    // const currentUser = getCurrentUser();
    let _values = { ...values };

    _values.assigned_agents = _values.assigned_agents.map(sanitizeMongoDoc);
    _values.logistics_type = _values.logistics_type.map(sanitizeMongoDoc);
    _values.target_achieved = numeral(_values.target_achieved).format("0.00");

    // remove trailing spaces
    _values?.declined_comment?.length && (_values.declined_comment = _values.declined_comment.trim());

    !_values?.declined_comment?.length && (_values.declined_comment = "No Comment");

    _values.commodity_average = numeral(_values?.total_trade_price || 0)
      .divide(_values?.trade_farmers || 1)
      .value();

    // remove backend updated fields
    deleteObjProps(_values, ["percentage_profit", "trade_farmers"]);

    delete _values.trade_farmers;

    if (_values.status === "declined") {
      await handleFormSubmission({
        submitFn: mutateAsync,
        values: { data: { ..._values, approved_by: user.userId }, id: tradeId },
        successMsg: "trade declined succesfully",
        errorMsg: "Something went wrong while declining trade, please try again",
        onSuccess() {
          confirmRejectModal.onClose();
          navigate(-1);
        },
      });

      return;
    }

    if (_values.status === "approved") {
      await handleFormSubmission({
        submitFn: mutateAsync,
        values: { data: { ..._values, approved_by: user.userId }, id: tradeId },
        config: {},
        successMsg: "trade approved succesfully",
        errorMsg: "Something went wrong while approving trade, please try again",
        onSuccess() {
          confirmApproveModal.onClose();
          navigate(-1);
        },
      });
      return;
    }

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: { data: { ..._values, approved_by: user.userId }, id: tradeId },
      successMsg: "trade updated succesfully",
      errorMsg: "Something went wrong while updating trade, please try again",
      onSuccess() {
        navigate(-1);
      },
    });
  };

  // useEffect hooks
  useEffect(() => {
    if (createdByRef?.current) {
      setCreatedBy(createdByRef.current);
      return;
    }
    if (data && !createdByRef?.current) {
      const user = getSingleAdmin(appGet(data, "initiated_by", ""));
      console.log({ user });
      createdByRef.current = user;
      setCreatedBy(user);
    }
  }, [data, getSingleAdmin]);

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > Trade" subtitle="Your trade summary and activity." />
      </Flex>
      <Panel
        bodyBg="transparent"
        headerElement={
          <TradeDetailsHeader
            onClickAction={() => {
              navigate(-1);
            }}
            name={appGet(data, "trade_id", "--")}
            isLoading={isLoading}
            mode={mode}
          />
        }
      >
        <VisibilityWrapper isVisible={!isPendingStatus} justifyContent="center">
          <SummaryGrid
            data={[
              { title: "Crop Type", value: appGet(data, "crop_type", "") },
              { title: "Target (KG)", value: appGet(data, "target_quantity", "") },
              { title: "Target Acheived (KG)", value: appGet(data, "target_achieved", "") },
              {
                title: "Target Remaining (KG)",
                value: numeral(appGet(data, "target_quantity", 0))
                  .subtract(appGet(data, "target_achieved", 0))
                  .format("0,0"),
              },
              {
                title: "Status",
                component: (
                  <Badge as="span" colorScheme={resolveMarketplaceTradeStatusColor(appGet(data, "status", ""))}>
                    {capitalize(appGet(data, "status", ""))}
                  </Badge>
                ),
              },
            ]}
          />
        </VisibilityWrapper>
        <ScopedView resource={resources.farmerPayment}>
          <div className="inner-tabs">
            <div className="inner-tabs__header">
              <Button
                variant="unstyled"
                className="inner-tabs__trigger"
                data-status={getActiveTopTab(TRADE_DETAILS) ? "active" : "inactive"}
                onClick={e => handleTopTabChange(e, TRADE_DETAILS)}
              >
                <Text font="smMedium" color={activeTopTab === TRADE_DETAILS ? "gray700" : "gray500"}>
                  Trade Details
                </Text>
              </Button>

              <Button
                className="inner-tabs__trigger"
                variant="unstyled"
                data-status={getActiveTopTab(AGGREGATOR) ? "active" : "inactive"}
                onClick={e => handleTopTabChange(e, AGGREGATOR)}
              >
                <Text font="smMedium" color={activeTopTab === AGGREGATOR ? "gray700" : "gray500"}>
                  All Aggregator
                </Text>
              </Button>
            </div>
          </div>
        </ScopedView>
        <Divider my="2rem" />

        <div className="inner-tabs__body">
          <VisibilityWrapper width="100%" isVisible={activeTopTab === TRADE_DETAILS} display="block">
            <Flex flexDirection="column" gap="1rem" bg="white" p="24px">
              {error || isLoading || !data ? (
                <EmptyState title="Something went wrong" subtitle="Unable to fetch trade details, Trying again..." />
              ) : (
                <Box>
                  <Formik initialValues={initialValues} onSubmit={handleUpdateTrade}>
                    {({ values, setFieldValue, handleSubmit }) => {
                      return (
                        <>
                          <Form>
                            <Box>
                              <Flex flexDirection="column" width="100%" gap="1rem">
                                {/* Croptype and cost price */}
                                <Flex gap="1rem">
                                  <VisibilityWrapper
                                    width="100%"
                                    isVisible={isEditMode}
                                    placeholder={<TextBox width="100%" label="Crop Type" value={values.crop_type} />}
                                  >
                                    <CropTypeField />
                                  </VisibilityWrapper>
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox width="100%" label=" Target Quantity " value={values.target_quantity} />
                                    }
                                  >
                                    <TargetQuantityInput />
                                  </VisibilityWrapper>
                                </Flex>
                                <FormGridHeader>Logistics Type</FormGridHeader>

                                <VisibilityWrapper
                                  width="100%"
                                  display="block"
                                  isVisible={isEditMode}
                                  placeholder={
                                    <>
                                      {/* Logistics Type and Logistics Cost */}
                                      {values.logistics_type?.length ? (
                                        <>
                                          {values.logistics_type.map((logistics, index) => (
                                            <Flex width="100%" gap="1rem" my={1} key={index}>
                                              <VisibilityWrapper
                                                width="100%"
                                                display="block"
                                                isVisible={isEditMode}
                                                placeholder={
                                                  <TextBox
                                                    width="100%"
                                                    label={index === 0 ? "Logistics Type" : ""}
                                                    value={logistics.name}
                                                  />
                                                }
                                              >
                                                <Box width="100%" gap="1rem">
                                                  <Input
                                                    label={index === 0 ? "Logistics Type" : ""}
                                                    required={index === 0}
                                                    value={logistics.name}
                                                    type="text"
                                                  />
                                                </Box>
                                              </VisibilityWrapper>
                                              <VisibilityWrapper
                                                width="100%"
                                                display="block"
                                                isVisible={isEditMode}
                                                placeholder={
                                                  <TextBox
                                                    width="100%"
                                                    label={index === 0 ? "Unit" : ""}
                                                    value={logistics.unit}
                                                  />
                                                }
                                              >
                                                <Box width="100%" gap="1rem" marginLeft={4}>
                                                  <Input
                                                    label={index === 0 ? "Unit" : ""}
                                                    required={index === 0}
                                                    value={logistics.unit}
                                                    type="text"
                                                  />
                                                </Box>
                                              </VisibilityWrapper>
                                              <VisibilityWrapper
                                                width="100%"
                                                display="block"
                                                isVisible={isEditMode}
                                                placeholder={
                                                  <TextBox
                                                    width="100%"
                                                    label={index === 0 ? "Price" : ""}
                                                    value={logistics.price_per_unit}
                                                  />
                                                }
                                              >
                                                <Box width="100%" gap="1rem">
                                                  <Input
                                                    label={index === 0 ? "Price" : ""}
                                                    required={index === 0}
                                                    value={logistics.price_per_unit}
                                                    type="text"
                                                  />
                                                </Box>
                                              </VisibilityWrapper>
                                              <VisibilityWrapper
                                                width="100%"
                                                display="block"
                                                isVisible={isEditMode}
                                                placeholder={
                                                  <TextBox
                                                    width="100%"
                                                    label={index === 0 ? "Amount" : ""}
                                                    value={logistics.amount}
                                                  />
                                                }
                                              >
                                                <Box width="100%">
                                                  <Input
                                                    label={index === 0 ? "Amount" : ""}
                                                    required={index === 0}
                                                    value={logistics.amount}
                                                    type="number"
                                                    rightAddon={
                                                      <CurrencySelect
                                                        placeholder="Currency"
                                                        colorScheme={selectConstants.colorSchemes.UNSTYLED}
                                                        value={createOption(logistics.currency)}
                                                      />
                                                    }
                                                  />
                                                </Box>
                                              </VisibilityWrapper>
                                            </Flex>
                                          ))}
                                        </>
                                      ) : (
                                        <Box mx={4}>
                                          <Text font="smRegular" color="gray500">
                                            No Logistics Type
                                          </Text>
                                        </Box>
                                      )}
                                    </>
                                  }
                                >
                                  <LogisticsTypeField />
                                </VisibilityWrapper>

                                <Flex width="100%" gap="1rem">
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox width="100%" label="Agency Commission" value={values.commission_fee} />
                                    }
                                  >
                                    <CommissionFeeInput />
                                  </VisibilityWrapper>
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox
                                        width="100%"
                                        label="Assigned Agents"
                                        value={
                                          <TruncatedList
                                            items={values.assigned_agents}
                                            renderItem={(agent, __) => (
                                              <AvatarGroup>
                                                <Avatar
                                                  key={agent.phone_number}
                                                  size="xs"
                                                  name={`${agent.first_name?.trim?.()} ${agent.last_name?.trim?.()}`}
                                                  tooltipContent={
                                                    <Flex bg="white" p="1rem">
                                                      <Text font="smRegular">
                                                        {agent.first_name?.trim?.()} {agent.last_name?.trim?.()} (
                                                        {agent.phone_number})
                                                      </Text>
                                                    </Flex>
                                                  }
                                                />
                                              </AvatarGroup>
                                            )}
                                          />
                                        }
                                      />
                                    }
                                  >
                                    <AssignAgentsField />
                                  </VisibilityWrapper>
                                </Flex>

                                <Flex width="100%" gap="1rem">
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox width="100%" label="Price to Pay Farmers" value={values.price_to_pay} />
                                    }
                                  >
                                    <PriceToPayInput />
                                  </VisibilityWrapper>
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox width="100%" label="Selling price " value={values.selling_price} />
                                    }
                                  >
                                    <SellingPriceInput />
                                  </VisibilityWrapper>
                                </Flex>
                                <Flex gap="1rem">
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox
                                        width="100%"
                                        label="Trade Revenue"
                                        value={values.trade_finance_fee || 0}
                                      />
                                    }
                                  >
                                    <TradeRevenueField />
                                  </VisibilityWrapper>
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox
                                        width="100%"
                                        label="Total Investment"
                                        value={values.total_investment || 0}
                                      />
                                    }
                                  >
                                    <TotalInvestmentField />{" "}
                                  </VisibilityWrapper>
                                </Flex>
                                <Flex width="100%" gap="1rem">
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox width="100%" label="Profit Margin" value={values.profit_margin} />
                                    }
                                  >
                                    <ProfitMarginField />
                                  </VisibilityWrapper>
                                </Flex>
                                <Flex gap="1rem" marginBottom="14px">
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={<TextBox width="100%" label="Cost Price" value={values.cost_price} />}
                                  >
                                    <CostPriceField />
                                  </VisibilityWrapper>{" "}
                                  <VisibilityWrapper
                                    width="100%"
                                    display="block"
                                    isVisible={isEditMode}
                                    placeholder={
                                      <TextBox
                                        width="100%"
                                        label="Anticipated Profit"
                                        value={values.anticipated_profit}
                                      />
                                    }
                                  >
                                    <AnticipatedProfitField />
                                  </VisibilityWrapper>
                                </Flex>
                                {/* Comment and Activity */}
                                <ScopedView></ScopedView>
                                <VisibilityWrapper
                                  display="block"
                                  width="100%"
                                  justifyContent="center"
                                  isVisible={isPendingStatus}
                                >
                                  <div className="inner-tabs">
                                    <div className="inner-tabs__header">
                                      <Button
                                        variant="unstyled"
                                        className="inner-tabs__trigger"
                                        data-status={getActiveBottomTab(COMMENT_TAB) ? "active" : "inactive"}
                                        onClick={e => handleBottomTabChange(e, COMMENT_TAB)}
                                      >
                                        <Text
                                          font="smMedium"
                                          color={getActiveBottomTab(COMMENT_TAB) ? "gray700" : "gray500"}
                                        >
                                          Leave Comment
                                        </Text>
                                      </Button>
                                      <Button
                                        className="inner-tabs__trigger"
                                        variant="unstyled"
                                        data-status={getActiveBottomTab(ACTIVITY_TAB) ? "active" : "inactive"}
                                        onClick={e => handleBottomTabChange(e, ACTIVITY_TAB)}
                                      >
                                        <Text
                                          font="smMedium"
                                          color={getActiveBottomTab(ACTIVITY_TAB) ? "gray700" : "gray500"}
                                        >
                                          Activity
                                        </Text>
                                      </Button>
                                    </div>
                                    <VisibilityWrapper
                                      isVisible={
                                        isApprovedStatus &&
                                        appRoles?.role !== "commercialSpecialist" &&
                                        appRoles?.role !== "regionalManager"
                                      }
                                      justifyContent="center"
                                      width="100%"
                                    >
                                      {/* replace typical bottom tabs with static display */}
                                      <Text textAlign="center" font="smMedium" color="gray500">
                                        Recent Activity
                                      </Text>
                                    </VisibilityWrapper>
                                  </div>
                                </VisibilityWrapper>
                                <Box mx="1rem" my="0.5rem">
                                  <Divider />
                                  <VisibilityWrapper
                                    width="100%"
                                    py="1rem"
                                    display="block"
                                    isVisible={getActiveBottomTab(COMMENT_TAB) && isPendingStatus}
                                  >
                                    <TextArea
                                      value={values.declined_comment}
                                      onChange={e => setFieldValue("declined_comment", e.target.value)}
                                      name="declined_comment"
                                      label="Comment"
                                      placeholder="Enter your comment here"
                                    />
                                  </VisibilityWrapper>
                                </Box>
                                <VisibilityWrapper
                                  width="100%"
                                  isVisible={getActiveBottomTab(ACTIVITY_TAB) || !isPendingStatus}
                                >
                                  <Flex alignItems="center" gap="12px" justifyContent="space-between" width="100%">
                                    <Flex gap="12px">
                                      <Flex>
                                        <Avatar
                                          size="md"
                                          src={appGet(createdBy, "current.avatar_url", null)}
                                          name={`${appGet(createdBy, "current.first_name", "N")} ${appGet(
                                            createdBy,
                                            "current.last_name",
                                            "A"
                                          )}`}
                                        />
                                      </Flex>
                                      <Flex flexDirection="column">
                                        <Text color="gray600">
                                          {appGet(createdBy, "first_name", "No First Name")}&nbsp;
                                          {appGet(createdBy, "last_name", "No Last Name")}
                                        </Text>
                                        <Flex>
                                          <Text color="gray600">Created Trade</Text>
                                        </Flex>
                                      </Flex>
                                    </Flex>
                                    <Box>
                                      <Text color="gray600" font="smRegular">
                                        {formatDate(data?.created_at || "")}
                                      </Text>
                                    </Box>
                                  </Flex>
                                </VisibilityWrapper>
                              </Flex>
                              <VisibilityWrapper
                                width="100%"
                                isVisible={canCommercialSpecialistEdit || canRegionalManagerEdit}
                                placeholder={
                                  isEditMode ? (
                                    <Text font="smMedium" color="gray500">
                                      {appGet(appRoles, "role", "you")} are NOT Authorised to edit{" "}
                                      {appGet(data, "status", "this")} trades
                                    </Text>
                                  ) : null
                                }
                                gap="1rem"
                                my="2rem"
                                mx="1rem"
                                justifyContent="flex-end"
                              >
                                <Box>
                                  <Button
                                    type="button"
                                    colorScheme="secondaryGray"
                                    onClick={() => {
                                      navigate("/dashboard/marketplace/marketplaceTrade");
                                    }}
                                  >
                                    <Text color="gray700">Cancel</Text>
                                  </Button>
                                </Box>
                                <Box alignItems="start">
                                  <Button type="submit">
                                    <Text color="white" textAlign="center">
                                      Update Trade
                                    </Text>
                                  </Button>
                                </Box>
                              </VisibilityWrapper>

                              <VisibilityWrapper
                                isVisible={
                                  !isEditMode &&
                                  (isPendingStatus || isDeclinedStatus) &&
                                  appRoles?.role &&
                                  appRoles.role === "regionalManager"
                                }
                                gap="1rem"
                                my="2rem"
                                mx="1rem"
                                justifyContent="flex-end"
                                placeholder={
                                  !isEditMode ? (
                                    <Text font="smMedium" color="gray500">
                                      Only Regional Managers can aprove or reject
                                    </Text>
                                  ) : null
                                }
                              >
                                <Box>
                                  <Button
                                    type="button"
                                    colorScheme="secondaryGray"
                                    isDisabled={values?.status === tradeStatuses.DECLINED}
                                    onClick={() => {
                                      confirmRejectModal.onOpen();
                                    }}
                                  >
                                    <Text color="gray700">Reject</Text>
                                  </Button>
                                </Box>
                                <Box alignItems="start">
                                  <Button
                                    type="button"
                                    isDisabled={values?.status === tradeStatuses.APPROVED}
                                    onClick={() => {
                                      confirmApproveModal.onOpen();
                                    }}
                                  >
                                    <Text color="white" textAlign="center">
                                      Approve
                                    </Text>
                                  </Button>
                                </Box>
                              </VisibilityWrapper>
                            </Box>
                          </Form>
                          <Modal {...confirmApproveModal.modalProps}>
                            <ModalContent variant="center" width={550}>
                              <ConfirmDialog
                                heading="You are approving this trade"
                                subHeading={`<p>
                                Are you sure you want to approve this trade? <br />This action cannot be reversed.<br />
                             <br /><em>comment: ${
                               appGet(values, "declined_comment", false) ? values.declined_comment : "No Comment"
                             }</em>
                           </p>`}
                                confirmationText={`Confirm`}
                                modal={confirmApproveModal.modal}
                                loading={isLoading}
                                onConfirm={() => {
                                  setFieldValue("status", "approved");
                                  handleSubmit();
                                }}
                              />
                            </ModalContent>
                          </Modal>
                          <Modal {...confirmRejectModal.modalProps}>
                            <ModalContent variant="center" width={550}>
                              <RejectWithCommentDialog
                                comment={appGet(values, "declined_comment", "No Comment")}
                                setComment={comment => {
                                  setFieldValue("declined_comment", comment);
                                }}
                                handleSubmit={() => {
                                  setFieldValue("status", "declined");
                                  handleSubmit();
                                }}
                                loading={isLoading}
                                modal={confirmRejectModal.modal}
                              />
                            </ModalContent>
                          </Modal>
                        </>
                      );
                    }}
                  </Formik>
                </Box>
              )}
            </Flex>
          </VisibilityWrapper>
          <ScopedView resource={resources.farmerPayment} permissionRequired="read">
            <VisibilityWrapper width="100%" isVisible={activeTopTab === AGGREGATOR} display="block">
              <Aggregator />
            </VisibilityWrapper>
          </ScopedView>
        </div>
      </Panel>
    </PageWrapper>
  );
};

const TradeDetailsHeader = ({ onClickAction, name, isLoading, mode }) => {
  return (
    <Flex alignItems="center" gap="1rem">
      <Button type="button" variant="unstyled" onClick={onClickAction}>
        <Icon icon="arrow-left" color="gray700" size={20} />
      </Button>
      <Text as="span" font="lgMedium" color="gray900">
        Back to Trade
      </Text>
      <Badge as="span" colorScheme={mode === PAGE_VIEW_MODE ? "blue" : "success"}>
        {mode === PAGE_EDIT_MODE ? "Editing" : "Viewing"} {isLoading ? "Loading Trade Details..." : `ID ${name}`}
      </Badge>
    </Flex>
  );
};

export default TradeDetails;
