import _ from "lodash";
import { signOut } from "./authService";
import { appGet, capitalize } from "utils";

const SCOPE = "scope";

export const basicRoles = {
  SUB_ADMIN: "subAdmin",
  REGIONAL_MANAGER: "regionalManager",
  ZONAL_MANAGER: "zonalManager",
  OPERATIONAL_MANAGER: "operationalManager",
  FINANCE: "finance",
  STORE_MANAGER: "storeManager",
  AGENT: "agent",
};

export const resourceTypes = {
  all: "all",
  agent: "agent",
  clearFarmer: "clearFarmer",
  crop: "crop",
  farmer: "farmer",
  farmerAccount: "farmerAccount",
  farmerCollectedInput: "farmerCollectedInput",
  farmerPayment: "farmerPayment",
  farmerSeason: "farmerSeason",
  farmMapper: "farmMapper",
  farmMonitoring: "farmMonitoring",
  farmSubActivity: "farmSubActivity",
  farmSubPayment: "farmSubPayment",
  farmSupervision: "farmSupervision",
  farmSupervisionCategory: "farmSupervisionCategory",
  harvest: "harvest",
  inputType: "inputType",
  language: "language",
  questionnaire: "questionnaire",
  season: "season",
  subscription: "subscription",
  survey: "survey",
  marketPlace: "marketPlace",
};

export const resources = resourceTypes;

export const resourceNames = {
  all: "Organisation",
  agent: "Agent",
  clearFarmer: "Farmer Clearance",
  crop: "Crop",
  farmer: "Farmer",
  farmerAccount: "Farmer Account",
  farmerCollectedInput: "Input Distribution",
  farmerPayment: "Farmer Payments",
  farmerSeason: "Farmer Season",
  farmMapper: "Farm Mapper",
  farmMonitoring: "Farm Monitoring",
  farmSubActivity: "Farm Subscription Activity",
  farmSubPayment: "Farm Subscription Payment",
  farmSupervision: "Farm Supervision",
  farmSupervisionCategory: "Farm Supervision Category",
  harvest: "Harvest",
  inputType: "Input Type",
  language: "Language",
  questionnaire: "Questionnaire",
  season: "Season",
  subscription: "Subscription",
  survey: "Survey",
  marketPlace: "Market Place",
};

export const resourceOptions = [
  { label: resourceNames.agent, value: resourceTypes.agent },
  { label: resourceNames.clearFarmer, value: resourceTypes.clearFarmer },
  { label: resourceNames.crop, value: resourceTypes.crop },
  { label: resourceNames.farmer, value: resourceTypes.farmer },
  { label: resourceNames.farmerAccount, value: resourceTypes.farmerAccount },
  { label: resourceNames.farmerCollectedInput, value: resourceTypes.farmerCollectedInput },
  { label: resourceNames.farmerPayment, value: resourceTypes.farmerPayment },
  { label: resourceNames.farmerSeason, value: resourceTypes.farmerSeason },
  { label: resourceNames.farmMapper, value: resourceTypes.farmMapper },
  { label: resourceNames.farmMonitoring, value: resourceTypes.farmMonitoring },
  { label: resourceNames.farmSubActivity, value: resourceTypes.farmSubActivity },
  { label: resourceNames.farmSubPayment, value: resourceTypes.farmSubPayment },
  { label: resourceNames.farmSupervision, value: resourceTypes.farmSupervision },
  { label: resourceNames.farmSupervisionCategory, value: resourceTypes.farmSupervisionCategory },
  { label: resourceNames.harvest, value: resourceTypes.harvest },
  { label: resourceNames.inputType, value: resourceTypes.inputType },
  { label: resourceNames.language, value: resourceTypes.language },
  { label: resourceNames.questionnaire, value: resourceTypes.questionnaire },
  { label: resourceNames.season, value: resourceTypes.season },
  { label: resourceNames.subscription, value: resourceTypes.subscription },
  { label: resourceNames.survey, value: resourceTypes.survey },
  { label: resourceNames.marketPlace, value: resourceTypes.marketPlace },
];

export const initialRoleState = {
  isSuperAdmin: false,
  isSubAdmin: false,
  isOperationalManager: false,
  isFinance: false,
  isStoreManager: false,
  isRegionalManager: false,
  isZonalManager: false,
};

export const scopeOptions = () => {
  const readScopeOptions = Object.entries(resourceNames).map(resource => ({
    label: `View All ${resource[1]}(s)`,
    value: `${resource[0]}.read`,
  }));
  const writeScopeOptions = Object.entries(resourceNames).map(resource => ({
    label: `Create/Update ${resource[1]}(s)`,
    value: `${resource[0]}.write`,
  }));
  const updateScopeOptions = Object.entries(resourceNames).map(resource => ({
    label: `Create/Update ${resource[1]}(s)`,
    value: `${resource[0]}.update`,
  }));

  return [...readScopeOptions, ...writeScopeOptions, ...updateScopeOptions];
};

export const resolveRoleFlags = role => {
  if (role) {
    switch (role) {
      case "zonalManager":
        return { ...initialRoleState, isZonalManager: true };
      case "regionalManager":
        return { ...initialRoleState, isRegionalManager: true };
      case "storeManager":
        return { ...initialRoleState, isStoreManager: true };
      case "finance":
        return { ...initialRoleState, isFinance: true };
      case "operationalManager":
        return { ...initialRoleState, isOperationalManager: true };
      case "subAdmin":
        return { ...initialRoleState, isSubAdmin: true };
      case "superAdmin":
        return { ...initialRoleState, isSuperAdmin: true };
      default:
        return { ...initialRoleState };
    }
  }
  return initialRoleState;
};

export const setUserScope = scope => {
  if (!scope) {
    return;
  }
  if (_.isString(scope)) {
    sessionStorage.setItem(SCOPE, scope);
    return;
  }

  if (_.isArray(scope)) {
    const scopeString = scope.join(",");
    sessionStorage.setItem(SCOPE, scopeString);
  }
};
export const getUserScope = () => {
  const userScope = sessionStorage.getItem(SCOPE);
  if (userScope) {
    return userScope;
  }
  signOut();
  return null;
};
export const removeUserScope = () => {
  sessionStorage.removeItem(SCOPE);
};

export const getScopeDescriptor = userScope => {
  const [resource, permission] = userScope.split(".");
  const resourceDescriptor = appGet(resources, resource, "Unknown Resource");
  const permissionDescriptor = capitalize(permission);

  return `${permissionDescriptor} all ${resourceDescriptor} Data`;
};
