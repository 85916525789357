import { getCurrentOrganization, getCurrentOrganizationData, getLoadingOrganizations } from "features/v1/organization";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrg, getOrgName, getSenderId } from "services/v1/authService";

export function useOrgData() {
  const dispatch = useDispatch();

  let currentOganization = useSelector(getCurrentOrganizationData);
  let loadingOrganizations = useSelector(getLoadingOrganizations);

  let isMultiCountry = useMemo(() => {
    if (currentOganization) {
      //   const _currentOrg = getOrgById(allOrgs, orgId);
      // if(_currentOrg && currentOrg.count)
      return currentOganization.country?.length > 1;
    }
    return null;
  }, [currentOganization]);

  let orgCountries = useMemo(() => {
    if (currentOganization) {
      return currentOganization.country;
    }
    return null;
  }, [currentOganization]);
console.log({currentOganization});
  // let orgName = useMemo(() => currentOganization?.name || "", [currentOganization]);

  useEffect(() => {
    if (!currentOganization) {
      dispatch(getCurrentOrganization());
    }
  }, []);

  return {
    loading: loadingOrganizations,
    orgId: getOrg(),
    orgName: getOrgName(),
    senderId: getSenderId()?.id || "",
    senderIdStatus: getSenderId()?.status || "",
    currentOrg: currentOganization,
    isMultiCountry,
    orgCountries: orgCountries || [],
  };
}
