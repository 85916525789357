import React, { useEffect, useState } from "react";
import Select2 from "./select2";
import { getLGAOptionsFromStateAndCountryName } from "./options/stateSelectOptions";

const LGASelect2 = ({ selectedCountry, selectedState, ...props }) => {
  const [selectedStateLGAs, setSelectedStateLGAs] = useState([]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      let newOptions = getLGAOptionsFromStateAndCountryName(selectedState, selectedCountry);
      if (newOptions) setSelectedStateLGAs(newOptions);
    } else {
      setSelectedStateLGAs([]);
    }
  }, [selectedCountry, selectedState]);

  return (
    <Select2
      label="LGA"
      placeholder="Select LGA"
      options={selectedStateLGAs}
      isDisabled={!selectedCountry || !selectedState}
      {...props}
    />
  );
};

export default LGASelect2;
