import * as yup from "yup";

export const createContractorInitialValue = {
  contractor_category: "",
  contractor_name: "",
  phone_number: "",
};

export const initiateUpdateContractorValues = _contractor => ({
  _id: _contractor?._id,
  contractor_bank_details: {
    bank_name: _contractor?.contractor_bank_details?.bank_name,
    bank_account_name: _contractor?.contractor_bank_details?.bank_account_name,
    bank_account_number: _contractor?.contractor_bank_details?.bank_account_number,
    bank_code: _contractor?.contractor_bank_details?.bank_code,
    bvn: _contractor?.contractor_bank_details?.bv,
  },
  contractor_category: _contractor?.contractor_category,
  contractor_name: _contractor?.contractor_name,
  phone_number: _contractor?.phone_number,
});

yup.addMethod(yup.string, "integer", function () {
  return this.matches(/^\d+$/, "The field should have digits only");
});

export const createContractorValidationSchema = yup.object().shape({
  contractor_category: yup.string().required("contractor category is required"),
  contractor_name: yup.string().required("contractor name is required"),
  phone_number: yup.string().required("contractor phone number is required"),
});
