import React from "react";
import { NumberRangeInput } from "components/inputs";
import { Flex, Icon, Text, organicTheme } from "organic";
import { Pagination as PaginationPrimitive } from "react-headless-pagination";
import { classnames } from "utils";

export const paginationConstants = {
  initialState: { PAGE_SIZE: 20, PAGE_INDEX: 0 },
  defaultState: { pageSize: 20, pageIndex: 0 },
};
const Pagination = ({
  pageIndex,
  pageSize,
  canPreviousPage,
  canNextPage,
  gotoPage,
  // pageOptions,
  setPageSize,
  pageCount,
  useAlternatePagination,
}) => {
  const prevBtnClassNames = classnames({
    "pagination__previous-btn": useAlternatePagination !== true,
    prev: useAlternatePagination === true,
  });
  const nextBtnClassNames = classnames({
    "pagination__next-btn": useAlternatePagination !== true,
    next: useAlternatePagination === true,
  });
  if (useAlternatePagination === true) {
    return (
      <Flex px="16px" pt="12px" pb="1rem">
        <PaginationPrimitive
          className="alternate-pagination"
          currentPage={pageIndex}
          totalPages={pageCount}
          setCurrentPage={gotoPage}
          edgePageCount={3}
          middlePagesSiblingCount={3}
          truncableText="..."
          truncableClassName="alternate-pagination-nav__page-btn"
        >
          <PaginationPrimitive.PrevButton className={prevBtnClassNames} disabled={!canPreviousPage}>
            <span className="arrow-icon">
              <Icon icon="arrow-left" color="#344054" size="20px" />
            </span>
          </PaginationPrimitive.PrevButton>
          <span className="current-page">
            Page <span className="current-page__bold">{pageIndex + 1}</span> of{" "}
            <span className="current-page__bold">{pageCount}</span>
          </span>
          <PaginationPrimitive.NextButton className={nextBtnClassNames} disabled={!canNextPage}>
            <span className="arrow-icon">
              <Icon icon="arrow-right" color="#344054" size="20px" />
            </span>
          </PaginationPrimitive.NextButton>
        </PaginationPrimitive>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection={["column", "_", "_", "row"]}
      gap="0.5rem"
      justifyContent="space-between"
      px="24px"
      pt="12px"
      pb="1rem"
    >
      <NumberRangeInput
        options={[10, 20, 50, 100, 1000]}
        value={pageSize}
        defaultValue={paginationConstants.initialState.PAGE_SIZE}
        onChange={setPageSize}
      />

      <Flex flexDirection="row">
        <PaginationPrimitive
          className="pagination"
          currentPage={pageIndex}
          totalPages={pageCount}
          setCurrentPage={gotoPage}
          edgePageCount={3}
          middlePagesSiblingCount={3}
          truncableText="..."
          truncableClassName="pagination-nav__page-btn"
        >
          <PaginationPrimitive.PrevButton disabled={!canPreviousPage} className={prevBtnClassNames}>
            <Icon size={12} icon="arrow-left" mr="3px" color={organicTheme.colors.gray700} />
            <Text font="smMedium" color="gray800">
              Previous
            </Text>
          </PaginationPrimitive.PrevButton>
          <nav className="pagination-nav">
            <ul className="pagination-nav__list">
              <PaginationPrimitive.PageButton
                activeClassName="pagination-nav__page-btn--active"
                as={<button />}
                className="pagination-nav__page-btn"
              />
            </ul>
          </nav>
          <PaginationPrimitive.NextButton disabled={!canNextPage} className={nextBtnClassNames}>
            <Text font="smMedium" color="gray800">
              Next
            </Text>
            <Icon size={12} icon="arrow-right" ml="3px" color={organicTheme.colors.gray700} />
          </PaginationPrimitive.NextButton>
        </PaginationPrimitive>
      </Flex>
    </Flex>
  );
};

export default React.memo(Pagination);
