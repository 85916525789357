import nigerianStates from "./nigerianStates";
import kenyaStates from "./kenyaStates";
import ugandaStates from "./ugandaStates";
import sierraLeoneStates from "./sierraLeoneStates";

/** map exisiting states data to their ISO 3166-1 alpha-2 code */
const statesByCountryCode = {
  NG: nigerianStates,
  KE: kenyaStates,
  UG: ugandaStates,
  SL: sierraLeoneStates,
};

/** map exisiting states data to their Name as recognised by the system */
const statesByCountryName = {
  Nigeria: nigerianStates,
  Kenya: kenyaStates,
  Uganda: ugandaStates,
  "Sierra Leone": sierraLeoneStates,
};

export const getStatesByCountryCode = countryCode => {
  if (Object.hasOwn(statesByCountryCode, countryCode)) {
    return statesByCountryCode[`${countryCode}`];
  }
  return [];
};
export const getStatesByCountryName = countryName => {
  if (Object.hasOwn(statesByCountryName, countryName)) {
    return statesByCountryName[`${countryName}`];
  }
  return [];
};

export const getStatesOptions = ({ countryCode, countryName } = { countryCode: "NG" }) => {
  let statesWithLGA = [];

  if (countryName && Object.keys(statesByCountryName).includes(countryName)) {
    statesWithLGA = getStatesByCountryName(countryName);
  } else if (countryCode && Object.keys(statesByCountryCode).includes(countryCode)) {
    statesWithLGA = getStatesByCountryCode(countryCode);
  }
  return statesWithLGA.map(stateItem => ({
    label: stateItem.state.name,
    value: stateItem.state.name,
    id: stateItem.state.id,
    locals: stateItem.state.locals,
    countryCode,
    countryName,
  }));
};

export const getLGAOptionsFromStateAndCountryCode = (stateValue, countryCode = "NG") => {
  const statesOptions = getStatesOptions({ countryCode });
  console.log({ statesOptions });
  let stateFromStatesOptions = statesOptions.find(state => state.value === stateValue);
  if (stateFromStatesOptions) {
    return stateFromStatesOptions.locals.map(local => {
      return {
        label: local.name,
        value: local.name,
      };
    });
  }
  return [];
};
export const getLGAOptionsFromStateAndCountryName = (stateValue, countryName = "Nigeria") => {
  const statesOptions = getStatesOptions({ countryName });
  let stateFromStatesOptions = statesOptions.find(state => state.value === stateValue);
  if (stateFromStatesOptions) {
    return stateFromStatesOptions.locals.map(local => {
      return {
        label: local.name,
        value: local.name,
      };
    });
  }
  return [];
};
