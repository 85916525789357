import React, { useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Input } from "organic";

const FormInputPropTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.func]),
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  // onChange: PropTypes.func,
  required: PropTypes.bool,
  rightAddon: PropTypes.node,
  textArea: PropTypes.bool,
  icon: PropTypes.string,
  hint: PropTypes.string,
};

const FormInput = React.forwardRef((props, forwardedRef) => {
  const {
    placeholder,
    required,
    name,
    type,
    label,
    icon,
    disabled: isDisabledProp = false,
    defaultValue, // for prefilled fields
    rightAddon,
    rightAddonProps,
    validate,
    hint,
    isValid,
    isDisabled,
    hidePasswordToggle,
    showCopy,
    ...restProps
  } = props;

  // const formFieldRef = useRef();

  const inputProps = { ...restProps };

  const [field, meta, helper] = useField({ name, type, validate, required });

  useEffect(() => {
    if (defaultValue) {
      helper.setValue(defaultValue, false);
    }
  }, []);

  return (
    <>
      <Input
        {...field}
        icon={icon}
        hint={hint}
        placeholder={placeholder}
        ref={forwardedRef}
        inputProps={inputProps}
        isInvalid={isValid}
        errorText={meta.error}
        isDisabled={isDisabledProp || isDisabled} // support both 'disabled and isDisabled'
        isRequired={required}
        rightAddon={rightAddon}
        rightAddonProps={rightAddonProps}
        label={label}
        type={type}
        hidePasswordToggle={hidePasswordToggle}
        showCopy={showCopy}
      />
    </>
  );
});

FormInput.displayName = "FormInput";
FormInput.propTypes = FormInputPropTypes;
export { FormInput };
