import { Box, Text } from "organic";
import React from "react";
import RecipientsList from "./recipientsList";

const ViewAdddressBookContacts = ({ addressBook }) => {
  return (
    <Box>
      <Text font="mdRegular">
        <Text as="span" font="mdSemibold">
          Name:{" "}
        </Text>
        {addressBook.address_book_title}
      </Text>
      <RecipientsList recipients={addressBook.content} />
    </Box>
  );
};

export default ViewAdddressBookContacts;
