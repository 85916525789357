import React from "react";
import PropTypes from "prop-types";

import { Box, Flex, Text } from "organic";
import { isNotEmptyObject } from "utils";

const DataStructurePropTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      component: PropTypes.node,
    })
  ).isRequired,
};

const DataStructure = props => {
  const { fields } = props;

  return (
    <Box>
      {fields?.length ? (
        fields.map((field, fieldIndex) =>
          isNotEmptyObject(field) ? (
            <Flex flexDirection="column" py="1rem" key={fieldIndex.toString()}>
              <Text font="smBold">{field?.label || ""}</Text>
              <Text mt="0.25rem">{field?.component ? field.component : `${field?.value || "N/A"}`}</Text>
            </Flex>
          ) : (
            <Flex key={fieldIndex.toString()} />
          )
        )
      ) : (
        <h3>No fields to display</h3>
      )}
    </Box>
  );
};

DataStructure.propTypes = DataStructurePropTypes;

export default DataStructure;
