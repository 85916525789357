import { FormInput, FormTextArea } from "components/inputs";
import { createTemplate } from "features/v1/bulkSms";
import { Form, Formik } from "formik";
import { Box, Button, Flex, buttonConstants } from "organic";
import React from "react";
import { useDispatch } from "react-redux";
import MessageCounter from "./messageCounter";
import { createTemplateValidationSchema } from "validators/bulkSmsValidationSchemas";

const CreateTemplate = ({ modal }) => {
  const dispatch = useDispatch();

  const handleCreateTemplate = (values, formOptions) => {
    dispatch(createTemplate(values, { formOptions, modal }));
  };
  return (
    <Box>
      <Formik
        initialValues={{ template_name: "", content: "", status: "active" }}
        validationSchema={createTemplateValidationSchema}
        onSubmit={handleCreateTemplate}
      >
        {({ values }) => (
          <Form>
            <Flex flexDirection="column" gap="20px">
              <Box>
                <FormInput name="template_name" label="Template Name" placeholder="Enter Template Name" />
              </Box>
              <Box>
                <FormTextArea name="content" label="Write Message" placeholder="Enter your message content here" />
              </Box>
              <MessageCounter message={values?.content || ""} />
              <Box mt="44px">
                <Flex gap="20px">
                  <Button
                    display="block"
                    colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}
                    onClick={() => {
                      modal.hideModal();
                    }}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" display="block">
                    Save Template
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateTemplate;
