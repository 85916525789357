import { FormInput } from "components/inputs";
import { CropCategorySelect } from "components/select";
import { updateCrop } from "features/v1/cropsAndInput";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import React from "react";
import { useDispatch } from "react-redux";
import { getOrg } from "services/v1/authService";
import { updateCropValidationSchema } from "validators/cropsValidationSchemas";

let organization = getOrg();

/** UPDATE CROP DETAILS  */
export const initiateUpdateCropValues = crop => {
  return {
    name: crop?.name || "",
    organization: organization,
    crop_category: crop?.crop_category || "",
    season: crop?.season,
  };
};

const EditCropDetails = props => {
  const { selected, modal } = props;

  const dispatch = useDispatch();

  const initialUpdateCropValues = initiateUpdateCropValues(selected);

  const handleUpdateCrop = (values, formOptions) => {
    dispatch(updateCrop(values, { formOptions, modal, cropId: selected._id }));
  };

  return (
    <Box>
      <Formik
        initialValues={initialUpdateCropValues}
        validationSchema={updateCropValidationSchema}
        onSubmit={handleUpdateCrop}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Flex flexDirection="column" gap="1rem">
              <Box>
                <FormInput name="name" defaultValue={values.name} label="Crop Name" required />
              </Box>
              <Box>
                <CropCategorySelect
                  name="crop_category"
                  required
                  defaultValue={values?.crop_category}
                  label="Crop Category"
                  onChange={val => setFieldValue("crop_category", val)}
                />
              </Box>
              <Box>
                <Button display="block" type="submit">
                  Save Crop Details
                </Button>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditCropDetails;
