import React from "react";
import { classnames, hyphenate } from "utils";

const SummaryGrid = ({ data, rowSize = data.length }) => {
  const summaryGridClasses = classnames("summary__grid", {
    "summary__grid--1-col": rowSize === 1,
    "summary__grid--2-col": rowSize === 2,
    "summary__grid--3-col": rowSize === 3,
    "summary__grid--4-col": rowSize === 4,
    "summary__grid--5-col": rowSize === 5,
    "summary__grid--6-col": rowSize === 6,
    "summary__grid--7-col": rowSize === 7,
    "summary__grid--8-col": rowSize === 8,
    "summary__grid--9-col": rowSize === 9,
    "summary__grid--10-col": rowSize === 10,
    "summary__grid--11-col": rowSize === 11,
    "summary__grid--12-col": rowSize === 12,
  });

  return (
    <div className="summary">
      <div className={summaryGridClasses}>
        {data?.length ? (
          data.map((datum, datumIndex) => (
            <div className="summary__gridItem" key={hyphenate([datum?.title, "summary-grid-item", datumIndex])}>
              <div className="summary__gridItem__Title">{datum?.title || "No Title"}</div>
              <div className="summary__gridItem__Value">
                {datum?.component ? datum.component : datum?.value?.toLocaleString("en-US") || "--"}
              </div>
            </div>
          ))
        ) : (
          <div className="summary">
            <div className="summary__grid">
              <div className="summary__gridItem">
                <div className="summary__gridItem__Title">No Data</div>
                <div className="summary__gridItem__Value">{"--"}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { SummaryGrid };
