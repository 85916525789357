import React, { useState } from "react";
import _ from "lodash";
import { useDataFilter, useDidMountEffect } from "hooks";
import agents from "api/agents";
import { handleActionError } from "services/shared/AOSErrorService";
import { SearchInput } from "components/inputs";
import { hasMinLength } from "utils";
import { Box, Button, Text } from "organic";

const SEARCH_MIN_LENGTH = 4;

const SearchAgents = ({ onChange }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // const { isOn, toggle } = useToggle(false);
  const { filterParams, isValidFilter } = useDataFilter();

  // const handleToggle = () => {
  //   if (useTrigger && _.isFunction(onClickTrigger)) {
  //     onClickTrigger();
  //   }
  //   if (onMouseDown) {
  //     onMouseDown();
  //   }
  //   if (onClick) {
  //     onClick();
  //   }
  //   toggle();
  // };

  const searchAgents = async (term, { abortController }) => {
    try {
      setLoading(true);
      const params = {
        ...filterParams,
        searchText: term,
      };
      const agentsResult = await agents.get("searchEditZones", { params, signal: abortController.signal });
      setSearchResults(agentsResult.data.data);
      setLoading(false);
    } catch (searchAgentsError) {
      setLoading(false);
      setSearchResults([]);
      handleActionError(searchAgentsError);
    }
  };

  const handleSearchTermChange = term => {
    setSearchTerm(term);
  };

  const handleAgentSelect = _agent => {
    if (_.isFunction(onChange)) {
      onChange(_agent);
    }
    setSearchTerm("");
    // toggle();
  };

  useDidMountEffect(() => {
    try {
      if (hasMinLength(searchTerm, SEARCH_MIN_LENGTH) && isValidFilter) {
        console.count("search effect");
        let abortController = new AbortController();
        searchAgents(searchTerm, { abortController });
      }
    } catch (error) {
      // TODO: implement erroh handling
      console.log("an error occured");
    }
  }, [searchTerm]);

  return (
    <Box px="4px">
      <SearchInput
        onChange={handleSearchTermChange}
        value={searchTerm}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        data-gramm="false" // grammarly stay away
        placeholder="Search"
        type="text"
      />
      <Box mt="16px">
        <Text color="gray500" font="smMedium">
          {loading ? "loading..." : " "}
        </Text>
        {(searchResults?.length &&
          searchResults.map((result, resultIndex) => {
            return (
              <Button
                colorScheme="tertiaryColor"
                key={resultIndex.toString()}
                onClick={() => handleAgentSelect(result)}
              >
                {`${result.first_name} ${result.last_name}  (${result.phone_number})`}
              </Button>
            );
          })) || (
          <Text mt="1rem" color="gray500" font="smMedium">
            Search an agent
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default SearchAgents;
