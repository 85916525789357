import React from "react";
import { ModalHeader } from "reactstrap";
import _ from "lodash";

const CustomModalHeader = props => {
  const { toggle = false, close, title, component } = props;
  return (
    <ModalHeader
      close={close}
      toggle={toggle}
      className="form-header-color ca-font-medium"
      cssModule={{ "modal-title": "modal-title sub-modal-header" }}
    >
      {_.isObjectLike(component) ? component : title}
    </ModalHeader>
  );
};

export default CustomModalHeader;
