import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

// FIXME: Refactor and Inherit or Compose ConfirmModal from CustomModal
const ConfirmModal = ({ onConfirm, disableConfirm, isOpen, message }) => {
  return (
    <Modal size="sm" isOpen={isOpen} className="modal-position">
      <ModalBody>{message ? message : "Are you sure you want to continue?"}</ModalBody>
      <ModalFooter>
        <Button
          size="sm"
          disabled={disableConfirm || false}
          className="text-bold mx-2"
          type="button"
          onClick={() => onConfirm(true)}
        >
          Proceed
        </Button>
        <Button size="sm" type="button" color="danger" className="text-bold mx-2" onClick={() => onConfirm(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
