import { PageHeader } from "components/layout";
import { Table } from "components/table";
import { PageWrapper } from "components/layout";
import { useTable } from "hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDraft, getAllDraftData, getAllDrafts } from "features/v1/bulkSms";
import { capitalize, formatDate, objectToStringifiedQueryString } from "utils";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import { Badge, badgeConstants } from "organic";

const campaignStatusBadges = {
  inactive: badgeConstants.colorSchemes.ORANGE,
  processing: badgeConstants.colorSchemes.BLUE_LIGHT,
  active: badgeConstants.colorSchemes.SUCCESS,
};

const Drafts = () => {
  const dispatch = useDispatch();
  let drafts = useSelector(getAllDraftData);
  const navigate = useNavigate();

  const { tableProps } = useTable({
    columns: [
      {
        Header: "Campaign Title",
        accessor: "campaign_title",
      },
      { Header: "Character Count", accessor: "size" },
      {
        id: "messageCount",
        Header: "No. of Messages",
        accessor: campaign => numeral(numeral(campaign.content?.length).divide(160).value()).add(1).format("0"),
      },
      {
        id: "draftDate",
        Header: "Date/Time",
        accessor: draft => formatDate(draft.created_at),
      },
      {
        id: "campaignStatus",
        Header: "Status",
        accessor: "campaign_status",
        Cell: ({ value }) => <Badge colorScheme={campaignStatusBadges[`${value}`]}>{capitalize(value)}</Badge>,
      },
    ],
    data: drafts,
    title: "All Drafts",
    noViewAction: true,
    canDelete: true,
  });

  React.useEffect(() => {
    dispatch(getAllDrafts());
  }, []);

  return (
    <PageWrapper>
      <PageHeader title="Messaging > Drafts" subtitle="Manage all SMS campaign and activity." />
      <Table
        {...tableProps}
        onEditAction={(selectedAction, __config) => {
          const payload = objectToStringifiedQueryString({
            draft: selectedAction.selected,
          });
          navigate(`/dashboard/messaging/create-campaign?${payload}`);
        }}
        onDeleteAction={(selectedAction, __config) => {
          dispatch(deleteDraft(selectedAction.selected._id));
        }}
      />
    </PageWrapper>
  );
};

export default Drafts;
