import React from "react";
import { Button, ModalFooter } from "reactstrap";
import _ from "lodash";
import { PulseLoader } from "react-spinners";
function CustomModalFooter(props) {
  const {
    modal,
    actionLabel,
    actionType = "button",
    onClickAction,
    noAction,
    actionDisabled,
    actionComponent,
    cancelLabel,
    loading,
  } = props;

  const _handleCancel = () => {
    if (_.isFunction(modal?.hideModal)) {
      modal.hideModal();
    } else {
      // TODO: implement erroh handling
      console.log("an error occured");
    }
  };

  return (
    <ModalFooter>
      {noAction ? null : (
        <ActionComponent
          actionComponent={actionComponent}
          loading={loading}
          actionType={actionType}
          actionDisabled={actionDisabled}
          onClickAction={onClickAction}
          actionLabel={actionLabel}
        />
      )}

      <Button className="form-btn-2 ca-font-regular" onClick={_handleCancel}>
        {cancelLabel || "Cancel"}
      </Button>
    </ModalFooter>
  );
}

/**
 * Action Button(s) for a modal
 * @returns a button or clickable component
 */

const ActionComponent = ({ actionComponent, loading, actionType, actionDisabled, onClickAction, actionLabel }) => {
  // avoid onClick errors is handler is not valid
  const _handleAction = () => {
    if (_.isFunction(onClickAction)) {
      onClickAction();
    }
  };
  if (actionComponent) {
    return actionComponent;
  }

  return (
    <Button
      disabled={actionDisabled || false}
      className="form-btn-1 ca-font-regular"
      type={actionType || "button"}
      onClick={_handleAction}
    >
      {loading && <PulseLoader size={8} loading={loading} color={"#21c570"} />}&nbsp;{actionLabel || "Save"}
    </Button>
  );
};

export default CustomModalFooter;
