import styled, { css } from "styled-components/macro";
import { typography } from "styled-system";

const OrganicLink = styled.a`
  ${typography}
  ${props => css`
    color: ${props.theme.colors.primary700};
  `}
`;

export default OrganicLink;
