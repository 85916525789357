import React, { useState } from "react";
import { Select } from ".";
import { useSelector } from "react-redux";
import { getFarmMappingOptions } from "features/v1/farmMapping";
import { selectOptionFromValue } from "utils/v1/selectUtils";

export const FarmMappingSelect = ({ onChange }) => {
  const [selectedFarmMapping, setSelectedFarmMapping] = useState();
  const options = useSelector(getFarmMappingOptions);

  const handleMappingSelectChange = value => {
    console.log({ value });
    const selectedOption = selectOptionFromValue(options, value);
    setSelectedFarmMapping(selectedOption);
    onChange?.(value);
  };

  return (
    <Select
      value={selectedFarmMapping}
      onChange={handleMappingSelectChange}
      options={options}
      placeholder={"start typing a name or id to Locate a farm"}
    />
  );
};
