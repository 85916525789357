import clsx from "clsx";
import { default as lodashCapitalize } from "lodash/capitalize";
import { default as lodashIsString } from "lodash/isString";
import { default as lodashSnakeCase } from "lodash/snakeCase";

function classnames(...args) {
  return clsx(...args);
}

function capitalize(...args) {
  const result = lodashCapitalize(...args);
  return result;
}

function snakeCase(...args) {
  const result = lodashSnakeCase(...args);
  return result;
}

function isString(...args) {
  return lodashIsString(...args);
}

/**
 *@function removeSpecialCharacters removes special characters in search box,
 * that are not alphanumeric or hyphen
 *
 * @param {string} term search query
 * @returns {string} edited string
 */
function removeSpecialCharacters(term, config = { removeHyphen: false }) {
  if (typeof term !== "string") return term;
  const newString = term.replace(/[^a-zA-Z0-9-]/g, match => {
    if (config.removeHyphen === true) return "";
    // Preserve hyphen characters
    return match === "-" ? "-" : "";
  });
  return newString;
}

/**
 *@function hasMinLength checks that a string reaches a provided min length
 *
 * @param {string} str string value
 * @returns {Boolean} true if string reaches min length - false if arg is not string or empty string
 */
function hasMinLength(value, minLength = 1) {
  if (value?.length && typeof value === "string") {
    return value.length >= minLength;
  } else {
    return false;
  }
}
function spaceOutCamelCase(str) {
  // Add a space before each uppercase letter except the first one
  const spacedStr = str.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter
  const capitalizedStr = lodashCapitalize(spacedStr);

  return capitalizedStr;
}

const hyphenate = (strArray = []) => `${strArray.join("-")}`;

function validateUUID(str) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
}

function truncateText(text, maxLength) {
  if (text.length <= maxLength) return text;
  const splitString = text.split(" ");
  let stringLength = 0;
  const words = [];

  for (let i = 0; i < splitString.length; i++) {
    stringLength += splitString[i].length + 1;
    if (stringLength > maxLength) {
      break;
    }
    words.push(splitString[i]);
  }

  return words.join(" ") + "...";
}

const removeWhitespace = string => {
  if (!string || !isString(string)) return "";

  return string.replace(/\s/g, "");
};

function separateCamelCase(inputString) {
  // Use regular expression to insert space before each capital letter
  return inputString.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export {
  classnames,
  capitalize,
  spaceOutCamelCase,
  validateUUID,
  removeSpecialCharacters,
  hasMinLength,
  hyphenate,
  isString,
  truncateText,
  removeWhitespace,
  snakeCase,
  separateCamelCase,
};
