import { Button } from "organic";
import { parseSize } from "organic/utils/shared-utils";
import React, { useState } from "react";

const TruncatedList = ({ items, limit, renderItem, onShowAll }) => {
  const [showAll, setShowAll] = useState(false);

  const truncatedItems = showAll ? items : items.slice(0, limit);
  const remainingCount = items.length - truncatedItems.length;

  return (
    <>
      {truncatedItems.map((item, index) => renderItem(item, index))}
      {remainingCount > 0 && !showAll && (
        <Button
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
          bg="primary50"
          width={parseSize("xs")}
          height={parseSize("xs")}
          display="inline"
          varaint="unstyled"
          onClick={() => {
            setShowAll(true);
            onShowAll?.(items);
          }}
        >
          +{remainingCount}
        </Button>
      )}
    </>
  );
};

TruncatedList.defaultProps = {
  limit: 5,
  renderItem: (item, index) => <div key={index}>{JSON.stringify(item)}</div>,
  items: [],
};

export { TruncatedList };
