import dayjs from "dayjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllFarmSupervision,
  getFarmSupervisionData,
  getFsMeta,
  // getLoadingFarmSupervisionData,
} from "features/v1/farmMonitoring";

import { useTable } from "hooks";
import farmSupervision from "api/farmSupervision";
// import ViewFarmSupervisionDetails from "../../DataTables/FarmMonitoring/ViewFarmSupervisionDetails";
import { PageWrapper } from "components/layout";
import { Table } from "components/table";
import { Flex } from "organic";
import { ExportButton } from "components/export";
import { PageHeader } from "components/layout";

const MonitoringPage = () => {
  // const farmSupervisionModal = useCustomModal();

  const dispatch = useDispatch();

  // let loadingFarmSupervisionData = useSelector(getLoadingFarmSupervisionData);
  let farmSupervisionData = useSelector(getFarmSupervisionData);
  let fsMeta = useSelector(getFsMeta);

  const { tableProps } = useTable({
    data: farmSupervisionData,
    columns: [
      {
        Header: "Field Name",
        accessor: "fieldName",
      },
      {
        Header: "Crop",
        accessor: "farmer.crop",
      },
      {
        Header: "TA Number",
        accessor: "farmer.ta_number",
      },
      {
        id: "SUpervisionDate",
        Header: "Date",
        accessor: col => dayjs(col?.supervisionDate).format("YYYY-MM-DD (HH:mm:ss A)"),
      },
      {
        Header: "Category",
        accessor: "supervision_category",
      },
      {
        Header: "Sub Category",
        accessor: "supervision_sub_category",
      },
      {
        Header: "Longtitude",
        accessor: "coordinates.longtitude",
      },
      {
        Header: "Latitude",
        accessor: "coordinates.latitude",
      },
      {
        Header: "Message",
        accessor: "message",
      },
    ],
    title: "Farm Supervison Data",
    useServerPagination: true,
    serverPaginationMeta: fsMeta,
  });

  const quickFarmSupervisionExport = async () => {
    const res = (
      await farmSupervision.get("download", {
        params: tableProps.filterParams,
      })
    ).data;
    return res;
  };
  const advancedFarmSupervisionExport = async ({ filterParams, columnWhitelist }) => {
    let params = { columnWhitelist, ...filterParams };
    const res = await farmSupervision.get("download/advance", {
      params,
    });
    // console.log(res)
    return res;
  };

  const getFarmSupervisionExportKeys = async () => {
    return await farmSupervision.get("download/getMetaKeys");
  };

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Farm > Monitoring" subtitle="your farmers summary and actiities" />
        <ExportButton
          quickExportConfig={{ apiCall: quickFarmSupervisionExport }}
          advancedExportConfig={{
            apiCall: advancedFarmSupervisionExport,
            getKeys: getFarmSupervisionExportKeys,
          }}
        />
      </Flex>
      <Table
        {...tableProps}
        onDataFilter={params => {
          dispatch(getAllFarmSupervision(params));
        }}
      />
      {/* <ViewFarmSupervisionDetails modal={farmSupervisionModal} selected={selected} /> */}
    </PageWrapper>
  );
};

export default MonitoringPage;
