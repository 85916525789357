export const countryOptions = [
  {
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    label: "Kenya",
    value: "Kenya",
  },
  {
    label: "Uganda",
    value: "Uganda",
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone",
  },
];

/** map bewteen country code and country name */
export const countriesByCountryCode = {
  NG: "Nigeria",
  KE: "Kenya",
  UG: "Uganda",
  SL: "Sierra Leone",
};

/** map bewteen country code and country name */
export const countriesByCountryName = {
  Nigeria: "NG",
  Kenya: "KE",
  Uganda: "UG",
  "Sierra Leone": "SL",
};

export function getCountryNameFromCountryCode(countryCode) {
  return countriesByCountryCode[countryCode] || null;
}

export function getCountryCodeFromCountryName(countryName) {
  return countriesByCountryName[countryName] || null;
}
