import { formatDate } from "utils/date-utils/date-utils";
import { capitalize } from "utils/shared-utils/text-utils";

/**
 * a function to generate a descriptive and timestamped file name for csv files
 * @param  {string} val - a name that describes the data in the csv file
 */
export const generateCsvFilename = val =>
  `AOS${capitalize(val?.toString() || "")}QuickExport_${formatDate(new Date())}.csv`;

export const generatePassword = () => {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const symbols = "!@#$%^&*()-_+=<>?";

  let password = "";

  // Ensure at least one character from each category
  password += lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
  password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
  password += numbers.charAt(Math.floor(Math.random() * numbers.length));
  const symbol = symbols.charAt(Math.floor(Math.random() * symbols.length));
  password += symbol;

  const remainingLength = Math.floor(Math.random() * 4) + 4; // Minimum 4 characters after ensuring each type of character

  // Add random characters to meet the total length
  for (let i = 0; i < remainingLength; i++) {
    const randomCategory = Math.floor(Math.random() * 3); // Excluding symbols for the first character
    switch (randomCategory) {
      case 0:
        password += lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length));
        break;
      case 1:
        password += uppercaseChars.charAt(Math.floor(Math.random() * uppercaseChars.length));
        break;
      case 2:
        password += numbers.charAt(Math.floor(Math.random() * numbers.length));
        break;
    }
  }

  // Shuffle the password string
  password = password
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");

  // Ensure password does not start with a symbol
  if (symbols.includes(password.charAt(0))) {
    const newCharIndex = Math.floor(Math.random() * (password.length - 1)) + 1;
    const temp = password.charAt(newCharIndex);
    password = password.substring(0, newCharIndex) + password.charAt(0) + password.substring(newCharIndex + 1);
    password = password.substring(0, 0) + temp + password.substring(0 + 1);
  }

  // Ensure password contains only one symbol
  let countSymbols = 0;
  for (let char of password) {
    if (symbols.includes(char)) {
      countSymbols++;
      if (countSymbols > 1) {
        // Replace additional symbols with random characters
        password = password.replace(char, lowercaseChars.charAt(Math.floor(Math.random() * lowercaseChars.length)));
      }
    }
  }

  // Trim password if it exceeds the maximum length
  password = password.substring(0, 11);

  return password;
};
