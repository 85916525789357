import { FormInput } from "components/inputs";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import React from "react";

const TargetQuantityInput = () => {
  return (
    <FormInput
      name="target_quantity"
      type="number"
      min={0}
      max={MAX_NUM_VALUE}
      step={0.01}
      placeholder="Target Quantity"
      label="Target Quantity (KG)"
      required
    />
  );
};

export default TargetQuantityInput;
