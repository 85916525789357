import { FormInput } from "components/inputs";
import { ProjectSelect } from "components/select";
import { updateFarmSupervisionCategory } from "features/v1/farmMonitoring";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import React from "react";
import { useDispatch } from "react-redux";
import { farmSupervisionCategoryValidatioSchema } from "validators/farmSupervisionCategoryValidationSchemas";

const EditFarmMonitoringCategory = ({ selected, modal }) => {
  const dispatch = useDispatch();

  const handleUpdateFarmSupervisionCategory = (values, formOptions) => {
    delete values.sub_category;
    dispatch(updateFarmSupervisionCategory(values, { formOptions, modal, id: selected._id, season: values.season }));
  };

  return (
    <div>
      {" "}
      <Formik
        initialValues={{
          category: selected.category,
          season: selected.season,
          organization: selected.organization,
        }}
        validationSchema={farmSupervisionCategoryValidatioSchema}
        onSubmit={handleUpdateFarmSupervisionCategory}
      >
        {({ setFieldValue }) => (
          <Form>
            <Flex flexDirection="column">
              <Box width="100%" mt="1rem">
                <FormInput
                  label="Category Name"
                  required
                  name="category"
                  defaultValue={selected.category}
                  placeholder="enter category name"
                />
              </Box>
              <Box width="100%" mt="1rem">
                <ProjectSelect
                  label="Change Season"
                  ignoreGlobalState
                  defaultValue={selected.season}
                  onChange={val => setFieldValue("season", val)}
                />
              </Box>
            </Flex>
            <Box mt="2rem">
              <Box>
                <Button display="block" type="submit" text="Update" />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditFarmMonitoringCategory;
