import { Form, Formik } from "formik";
import { useCreateTrade } from "hooks/app/marketplace";
import numeral from "numeral";
import React, { useLayoutEffect } from "react";
import { Box, Button, Flex } from "organic";
// import { tryCatchWrapper } from "services/AOSErrorService";
import { MAX_NUM_VALUE, MIN_NUM_VALUE } from "constants/sharedConstants";
import { handleFormSubmission } from "services/formSubmissionService";
import { getCurrentUser } from "services/v1/authService";
import { appGet } from "utils";
import { createTradeValidationSchema } from "validators/marketplaceValidatorSchemas";
import { AssignAgentsField } from "../tradeForm/assignAgentsField";
import { SellingPriceInput } from "../tradeForm/sellingPriceInput";
import TargetQuantityInput from "../tradeForm/targetQuantityInput";
import { CropTypeField } from "./cropTypeField";
import { PriceToPayInput } from "../tradeForm/priceToPayInput";
import { CommissionFeeInput } from "../tradeForm/commissionFeeInput";
import { AnticipatedProfitField } from "../tradeForm/anticipatedProfitField";
import { CostPriceField } from "../tradeForm/costPriceField";
import { TradeRevenueField } from "../tradeForm/tradeRevenueField";
import { ProfitMarginField } from "../tradeForm/profitMarginField";
import { TotalInvestmentField } from "../tradeForm/totalInvestmentField";
import { LogisticsTypeField } from "../tradeForm/logisticsTypeField";
import { useAppRoles } from "hooks/app";
import { VisibilityWrapper } from "components/layout";
import { FormErrorMessage } from "components/form";

const getNumericalValues = values => {
  return {
    cost_price: appGet(values, "cost_price", "0"),
    selling_price: appGet(values, "selling_price", "0"),
    anticipated_profit: appGet(values, "anticipated_profit", "0"),
    logistics_cost: appGet(values, "logistics_cost", "0"),
    target_quantity: appGet(values, "target_quantity", "0"),
    price_to_pay: appGet(values, "price_to_pay", "0"),
    profit_margin: appGet(values, "profit_margin", "0"),
    trade_finance_fee: appGet(values, "trade_finance_fee", "0"),
    total_investment: appGet(values, "total_investment", "0"),
    commission_fee: appGet(values, "commission_fee", "0"),
  };
};

export const confirmValues = values => {
  if (
    Object.values(getNumericalValues(values)).every(
      value => numeral(value).value() < MAX_NUM_VALUE && numeral(value).value() > MIN_NUM_VALUE
    )
  ) {
    return true;
  }
  return false;
};

const stringifyLogisticsValues = logisticsType => {
  return {
    ...logisticsType,
    unit: numeral(logisticsType.unit).value().toFixed(2),
    price_per_unit: numeral(logisticsType.price_per_unit).value().toFixed(2),
    amount: numeral(logisticsType.amount).value().toFixed(2),
  };
};

const CreateTrade = ({ modal }) => {
  const { mutateAsync, isLoading: isCreating } = useCreateTrade();

  const initialValues = {
    initiated_by: getCurrentUser()?.userId || "",
    crop_type: "",
    cost_price: "",
    selling_price: "",
    anticipated_profit: "",
    logistics_type: [],
    logistics_cost: "",
    target_quantity: "",
    profit_margin: "",
    assigned_agents: [],
    price_to_pay: "",
    commission_fee: "",
    trade_finance_fee: "",
    total_investment: "",
    status: "pending",
    balance_quantity: "",
    currency: "",
  };

  const onSubmit = async (values, formOptions) => {
    if (!values?.initiated_by) {
      values.initiated_by = getCurrentUser().userId;
    }
    const _values = {
      logistics_type: values.logistics_type.map(stringifyLogisticsValues),
      status: "pending",
      crop_type: appGet(values, "crop_type", ""),
      cost_price: numeral(values.cost_price).value().toFixed(2),
      selling_price: numeral(values.selling_price).value().toFixed(2),
      anticipated_profit: numeral(values.anticipated_profit).value().toFixed(2),
      logistics_cost: numeral(values.logistics_cost).value().toFixed(2),
      target_quantity: numeral(values.target_quantity).value().toFixed(2),
      assigned_agents: values.assigned_agents,
      price_to_pay: numeral(values.price_to_pay).value().toFixed(2),
      commission_fee: numeral(values.commission_fee).value().toFixed(2),
      trade_finance_fee: numeral(values.trade_finance_fee).value().toFixed(2),
      total_investment: numeral(values.total_investment).value().toFixed(2),
      initiated_by: getCurrentUser()?.userId || "",
      profit_margin: numeral(values.profit_margin).value().toFixed(2),
      balance_quantity: numeral(values.target_quantity).value().toFixed(2),
      currency: values.currency, //default for select field
    };

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: _values,
      config: { formOptions },
      errorMsg: "Failed to create trade kindly try again",
      successMsg: "Trade created successfully",
      onSuccess() {
        modal.hideModal();
      },
      onError(error) {
        console.log({ error });
      },
    });
  };
  const appRoles = useAppRoles();

  useLayoutEffect(() => {});
  return (
    <Box>
      {/* wrap everything in a block level element for auto 100% width */}
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={createTradeValidationSchema}>
        {({ isSubmitting, errors, values }) => {
          console.log({ values, errors });
          return (
            <Form>
              <FormErrorMessage name="initiated_by" />
              <Box>
                <Flex flexDirection="column" width="100%" gap="1rem">
                  <Flex gap="1rem">
                    {/* crop_type */}
                    <Flex width="50%" gap="1rem">
                      <CropTypeField />
                    </Flex>
                    {/* target quantity */}
                    <Box width="50%">
                      <TargetQuantityInput />
                    </Box>
                  </Flex>
                  {/* logistics type */}
                  <LogisticsTypeField />

                  <Flex width="100%" gap="1rem" alignItems="center">
                    {/* agency commission  */}
                    <Box width="50%">
                      <CommissionFeeInput />
                    </Box>

                    {/* assigned agents */}
                    <Box width="50%">
                      <AssignAgentsField />
                    </Box>
                  </Flex>
                  <Flex width="100%" gap="1rem" alignItems="center">
                    {/* price to pay farmers */}
                    <Box width="50%">
                      <PriceToPayInput />
                    </Box>
                    {/* selling price */}
                    <Box width="50%">
                      <SellingPriceInput />
                    </Box>
                  </Flex>

                  <Flex width="100%" gap="1rem">
                    {/* trade revenue */}
                    <Box width="50%">
                      <TradeRevenueField />
                    </Box>
                    {/* total investment */}
                    <Box width="50%">
                      <TotalInvestmentField />
                    </Box>
                  </Flex>
                  <Flex width="100%" gap="1rem">
                    {/* anticipated profit */}
                    <Box width="50%">
                      <AnticipatedProfitField />
                    </Box>
                    {/* profit margin */}
                    <Box width="50%">
                      <ProfitMarginField />
                    </Box>
                  </Flex>
                  <Flex width="100%" gap="1rem">
                    {/* cost price */}
                    <Box width="50%">
                      <CostPriceField />
                    </Box>
                  </Flex>
                </Flex>
                <VisibilityWrapper
                  isVisible={appRoles?.role === "commercialSpecialist"}
                  justifyContent="center"
                  gap="1rem"
                  my="1rem"
                  width="100%"
                >
                  <Button
                    size="lg"
                    display="block"
                    type="button"
                    onClick={() => {
                      modal.hideModal();
                    }}
                    colorScheme="secondaryGray"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button size="lg" display="block" type="submit" disabled={isSubmitting || isCreating}>
                    {isCreating ? "Creating Trade..." : "Create Trade"}
                  </Button>
                </VisibilityWrapper>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreateTrade;
