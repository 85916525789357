import { FormInput } from "components/inputs";
import { FormGridHeader } from "components/layout";
import { selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import { TableActions, tableConstants } from "components/table";
import { FieldArray, useFormikContext } from "formik";
import numeral from "numeral";
import { Box, Button, Flex, Text, buttonConstants } from "organic";
import colors from "organic/theme/colors";
import React, { useEffect } from "react";
import { hyphenate, sum } from "utils";
import { createSelectOption } from "utils/v1/selectUtils";

const getInvoiceItemFieldName = ({ fieldName, index }) => `invoice_item[${index}]${fieldName}`;

const getInvoiceItem = item => {
  return {
    description: item?.type || "",
    quantity: item?.quantity || 0,
    rates: item?.rates || 0,
    amount: item?.amount || 0,
  };
};
const createInvoiceItem = data => {
  return getInvoiceItem(data);
};

const calcInvoiceAmount = ({ quantity, rates }) => {
  return numeral(quantity || 0)
    .multiply(rates || 0)
    .value();
};

export const InvoiceItemField = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const newInvoiceItem = [...values.invoice_item];

    newInvoiceItem.forEach((item, index) => {
      setFieldValue(
        getInvoiceItemFieldName({ fieldName: "amount", index }),
        calcInvoiceAmount({ quantity: item.quantity, rates: item.rates })
      );
    });

    const InvoiceItemTotal = numeral(sum(newInvoiceItem?.map?.(type => numeral(type?.amount || 0).value()))).value();
    const netInvoiceItemQuantities = newInvoiceItem.map(item => numeral(item.quantity).value());
    const totalQuantity = netInvoiceItemQuantities.reduce((acc, val) => acc + val, 0);

    setFieldValue("total_amount", InvoiceItemTotal);
    setFieldValue("total_quantity", totalQuantity);
  }, [values.invoice_item]);

  return (
    <FieldArray
      name="invoice_item"
      render={helpers => {
        return (
          <Flex py="4rem" flexDirection="column" gap="1rem">
            <FormGridHeader>Invoice Item</FormGridHeader>
            {values.invoice_item.map((invoiceItems, invoiceItemsIndex) => {
              return (
                <Flex
                  key={hyphenate([values.tradeId, "invoice-item", invoiceItemsIndex])}
                  width="100%"
                  gap="1rem"
                  mx="1rem"
                  alignItems="center"
                >
                  <Box width="30%">
                    <FormInput
                      name={getInvoiceItemFieldName({ fieldName: `description`, index: invoiceItemsIndex })}
                      label={invoiceItemsIndex === 0 ? "Description" : ""}
                    />
                  </Box>
                  <Box width="10%">
                    <FormInput
                      name={getInvoiceItemFieldName({ index: invoiceItemsIndex, fieldName: "quantity" })}
                      label={invoiceItemsIndex === 0 ? "Quantity" : ""}
                      type="number"
                    />
                  </Box>
                  <Box width="20%">
                    <FormInput
                      name={getInvoiceItemFieldName({ index: invoiceItemsIndex, fieldName: "rates" })}
                      label={invoiceItemsIndex === 0 ? "Rates" : ""}
                      type="number"
                    />
                  </Box>
                  <Box width="30%">
                    <FormInput
                      name={getInvoiceItemFieldName({ fieldName: `amount`, index: invoiceItemsIndex })}
                      label={invoiceItemsIndex === 0 ? "Amount" : ""}
                      defaultValue={invoiceItems.amount}
                      isDisabled
                      readOnly
                      rightAddon={
                        <CurrencySelect
                          onChange={currencyValue => {
                            setFieldValue(`currency`, currencyValue);
                          }}
                          value={createSelectOption(values.currency, values.currency)}
                          colorScheme={selectConstants.colorSchemes.UNSTYLED}
                          placeholder="Currency"
                        />
                      }
                    />
                  </Box>
                  <Box width="1%" mt="20px">
                    <TableActions
                      canDelete
                      noEditAction
                      noViewAction
                      onSelect={({ actionKey }) => {
                        if (actionKey === tableConstants.defaultActionKeys.DELETE_ACTION_KEY) {
                          helpers.remove(invoiceItemsIndex);
                        }
                      }}
                      row={values.invoice_item[invoiceItemsIndex]}
                    />
                  </Box>
                </Flex>
              );
            })}
            <Flex justifyContent="flex-end" width="100%" gap="1rem" alignItems="center">
              <Flex width="30%"></Flex>
              <Flex width="22%"></Flex>
              <Flex width="22%" justifyContent="flex-end">
                <Text font="smMedium" color="gray500">
                  Total Amount
                </Text>
              </Flex>
              <Flex width="22%" gap="0.5rem">
                <Text as="span" font="mdMedium" color="gray700">
                  {numeral(values.total_amount).format("0,0.00")}
                </Text>
                <Text as="span" font="mdMedium" color="gray700">
                  {values.currency}
                </Text>
              </Flex>
            </Flex>
            <Flex width="4%"></Flex>
            {/* add newtInvoiceItem type */}
            <Flex alignItems="center" color={colors.primary600}>
              <Button
                leftIcon="plus-circle"
                colorScheme={buttonConstants.colorSchemes.TERTIARY_COLOR}
                type="button"
                onClick={() => helpers.push(createInvoiceItem())}
              >
                <Text> Add Type</Text>
              </Button>
            </Flex>
          </Flex>
        );
      }}
    />
  );
};
