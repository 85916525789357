// import FarmerAvatar from "components/v1/common/FarmerAvatar";
import React from "react";
import { Box, Flex, Text } from "organic";

import DataGridRow from "components/v1/DataGrid/DataGridRow";
import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
import { getGroupIdFromTA } from "utils/v1/TAUtils";
import { formatRole } from "utils";

const ViewFarmerDetails = ({ selected }) => {
  return (
    <Flex flexDirection="column" justifyContent="space-around">
      <Box my={4}>
        <Flex justifyContent="space-between">
          <Text> {selected?.agent?.first_name} </Text>
          <Text> {selected?.agent?.last_name}</Text>
        </Flex>
        <Flex>
          <Text className="farmer-profile-label-agent">Status</Text>
          <Text className="farmer-profile-first-meta">
            {selected?.status === "active" && "Active"}
            {selected?.status === "inactive" && "Inactive"}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text>
          {formatRole(selected?.role)}
          {selected?.role === "lead_farmer" && "Lead Farmer"}
          {selected?.role === "farmer" && "Farmer"}

          {selected?.role === "secretary" && "Secretary"}
        </Text>
        <DataGridTitle title="User Details" />
        <DataGridRow
          fields={[
            {
              label: "Group ID",
              value: getGroupIdFromTA(selected?.ta_number),
            },
            {
              label: "User ID",
              value: selected?.ta_number,
            },
          ]}
        />
        <DataGridRow
          fields={[
            {
              label: "First Name",
              value: selected?.first_name,
            },
            {
              label: "Last Name",
              value: selected?.last_name,
            },
          ]}
        />

        <DataGridRow
          fields={[
            {
              label: "Middle Name",
              value: selected?.middle_name,
            },
            {
              label: "Phone Number",
              value: selected?.phone_number,
            },
          ]}
        />
        <DataGridRow
          fields={[
            {
              label: "Phone Number Owner",
              value: selected?.phone_number_owner,
            },
            {
              label: "Gender",
              value: selected?.gender,
            },
          ]}
        />
        <DataGridTitle title="Demographic Details" />
        <DataGridRow
          fields={[
            {
              label: "Age",
              value: selected?.age,
            },
            {
              label: "State",
              value: selected?.state,
            },
          ]}
        />
        <DataGridRow
          fields={[
            {
              label: "LGA",
              value: selected?.lga,
            },
            {
              label: "Village",
              value: selected?.village,
            },
          ]}
        />
        <DataGridRow
          fields={[
            {
              label: "District",
              value: selected?.district,
            },
            {},
          ]}
        />
        <DataGridTitle title="Bank Detials" />
        <DataGridRow
          fields={[
            {
              label: "Bank Name",
              value: selected?.bank_name ? selected.bank_name : "Not Updated",
            },
            {
              label: "Bank Name",
              value: selected?.bank_account_name ? selected.bank_account_name : "Not Updated",
            },
          ]}
        />
        <DataGridRow
          fields={[
            {
              label: "Account Number",
              value: selected?.bank_account_number ? selected?.bank_account_number : "Not Updated",
            },
            {
              label: "BVN",
              value: selected?.bvn ? selected.bvn : "Not Updated",
            },
          ]}
        />
      </Box>
    </Flex>
  );
};

export default ViewFarmerDetails;
