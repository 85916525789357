import { Table } from "components/table";
import { useTable } from "hooks";
import { useSelector } from "react-redux";
import { getAllRolesData } from "features/v1/role";

import React from "react";

const Roles = ({ onSelectRole }) => {
  let roleData = useSelector(getAllRolesData);
  console.log(roleData);

  const handleRoleClick = role => {
    onSelectRole(role);
  };
  const { tableProps } = useTable({
    data: roleData,
    columns: [
      {
        Header: "Role",
        accessor: "name",
      },
    ],
    title: "All Roles",
    useAlternatePagination: true,
    noViewAction: true,
    noDateRangeFilter: true,
    // noSearch: true,
    noLocalFilter: true,
    showCheckboxes: false,
    onSelect: handleRoleClick,
  });
  //   const roleEditModal = useDisclosure;
  return (
    <Table
      {...tableProps}
      onEditAction={selectedAction => {
        //   roleEditModal.onOpen();
        onSelectRole(selectedAction.selected);
      }}
    />
  );
};

export default Roles;
