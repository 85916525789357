import { Text, organicTheme } from "organic";
import React from "react";
import { PieChart as RePieChart, Pie, Legend, ResponsiveContainer } from "recharts";
import { isNumber } from "utils";

const PIE_LEGEND_HEIGHT = 16;
const PIE_TO_LEGEND_SPACING = 12;

const defaultPieData = [{ name: "", value: 400, fill: organicTheme.colors.gray100 }];

const PieChart = ({ data, width = "100%", height }) => {
  const [pieData, setPieData] = React.useState([]);

  const handleZeroValueData = React.useCallback(_data => {
    if (_data.length === 0) {
      setPieData(defaultPieData);
    }

    let hasInvalidValues = _data
      .map(datum => datum.value)
      .every(dataValue => !dataValue || dataValue === 0 || !isNumber(dataValue));
    if (hasInvalidValues) {
      setPieData(defaultPieData);
    }

    setPieData(_data);
  }, []);

  React.useLayoutEffect(() => {
    handleZeroValueData(data);
  }, [data, handleZeroValueData]);

  return (
    <ResponsiveContainer
      width={width}
      height={"100%"}
      minHeight={height + PIE_TO_LEGEND_SPACING + PIE_LEGEND_HEIGHT}
      minWidth={width}
      className={"pie-chart-responsive-container"}
    >
      <RePieChart width={95} height={93} margin={{ bottom: 28, top: 0, left: 0, right: 0 }}>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={52}
          legendType="none"
        />
        <Legend
          iconSize={7}
          height={PIE_LEGEND_HEIGHT}
          iconType="circle"
          layout="horizontal"
          align="center"
          verticalAlign="bottom"
          formatter={renderLegendText}
          wrapperStyle={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            whiteSpace: "nowrap",
            alignItems: "center",
            bottom: 0,
            marginTop: PIE_TO_LEGEND_SPACING,
          }}
        />
      </RePieChart>
    </ResponsiveContainer>
  );
};

PieChart.defaultProps = {
  data: defaultPieData,
};

const renderLegendText = (value, __entry) => {
  return (
    <Text as="span" font="smRegular" color="gray500">
      {value}
    </Text>
  );
};

export default PieChart;
