import React, { useEffect } from "react";
import { getFilterDateRange, getFilterRegion, getFilterSeasonId } from "features/v1/filter";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isValidDate } from "utils";

/** Custom hook to easily access the global values of the CustomFilter Component
 *  from any other component
 */
const useDataFilter = (config = {}) => {
  const { ignoreDateRange, ignoreProject, ignoreRegion, noDateRange, noProject, noRegion } = {
    ...defaultDataFilterConfig,
    ...config,
  };

  const [isValidFilter, setIsValidFilter] = React.useState(false);

  let seasonId = useSelector(getFilterSeasonId);
  let dateRange = useSelector(getFilterDateRange);
  let region = useSelector(getFilterRegion);

  const getFilterParams = React.useMemo(() => {
    return {
      dateRange,
      seasonId,
      region,
    };
  }, [dateRange, region, seasonId]);

  const isValidDateRange = React.useMemo(() => {
    if (noDateRange || ignoreDateRange) {
      return true;
    }

    return !noDateRange && !ignoreDateRange && validateDateRange(dateRange) ? true : false;
  }, [dateRange, ignoreDateRange, noDateRange]);

  const isValidProjectId = React.useMemo(() => {
    if (noProject === true || ignoreProject === true) {
      return true;
    }

    return !noProject && !ignoreProject && validateProjectId(seasonId) ? true : false;
  }, [ignoreProject, noProject, seasonId]);

  const isValidRegion = React.useMemo(() => {
    if (noRegion || ignoreRegion) {
      return true;
    }

    return !noRegion && !ignoreRegion && validateRegion(region) ? true : false;
  }, [ignoreRegion, noRegion, region]);

  useEffect(() => {
    // validate that all filter values are valid according to config
    if (!isValidProjectId) {
      toast("kindly select a project", { toastId: "filterToast" });
      setIsValidFilter(false);
      return;
    }
    if (!isValidDateRange) {
      toast("kindly select date range", { toastId: "filterToast" });
      setIsValidFilter(false);
      return;
    }
    if (!isValidRegion) {
      toast("kindly select a region", { toastId: "filterToast" });
      setIsValidFilter(false);
      return;
    }
    if (seasonId && dateRange && region) {
      setIsValidFilter(true);
      return;
    }
  }, [seasonId, dateRange, region]);

  const _isValidFilter = React.useMemo(() => isValidFilter, [isValidFilter]);

  //   reusable global access to filter params
  return {
    isValidFilter: _isValidFilter,
    filterParams: getFilterParams,
    isValidDateRange,
    isValidProjectId,
    isValidRegion,
  };
};

const validateDateRange = _dateRange => {
  if (!Array.isArray(_dateRange)) {
    return false;
  }

  if (_dateRange.length === 0 || _dateRange.length < 2) {
    return false;
  }

  const [startDate, endDate] = _dateRange;

  return isValidDate(startDate) && isValidDate(endDate) ? true : false;
};

const validateProjectId = _projectId => {
  return _projectId || typeof _projectId !== typeof null ? true : false;
};
const validateRegion = _region => {
  return _region || typeof _region !== typeof null ? true : false;
};

export const defaultDataFilterConfig = {
  ignoreProject: false,
  noProject: false,
  ignoreDateRange: false,
  noDateRange: false,
  ignoreRegion: false,
  noRegion: false,
};

export { useDataFilter };
