/* eslint-disable no-unused-vars */
import { Box, Button, Flex, Text, organicTheme } from "organic";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/accordion";
import LoginDetailsForm from "./loginDetailsForm";
import BasicInformationForm from "./basicInformationForm";
import InviteMembers from "./inviteMembers";
import { Modal, ModalContent } from "components/modal";
import SuccessDialog from "components/confirmDialog/successDialog";
import { useDisclosure, useOrgData } from "hooks";
import { useCreateContractualFarmer } from "hooks/app/farmer/useCreateContractualFarmer";
import { useGetFarmerIdRange } from "hooks/app/farmer/useGetFarmerIdRange";
import { useSelector } from "react-redux";
import authService from "services/v1/authService";
import { getSeasonOptions } from "features/v1/season";
import { toast } from "react-toastify";
import { handleFormSubmission } from "services/formSubmissionService";
import { useChangeMemberToContractual } from "hooks/app/farmer/useChangeMemberToContractual";
import tickIcon from "../../../../assets/icons/tick-icon.svg";

const loginDetailsInitialValues = {
  phone_number: "",
  password: "",
};

export const basicInfoInitialValues = {
  seasonId: "",
  region: "",
  first_name: "",
  last_name: "",
  middle_name: "",
  phone_number: "",
  email: "",
  gender: "",
  age: 0,
  country: "",
  state: "",
  lga: "",
  village: "",
  district: "",
  address: "",
};

const generateFarmerId = (number, currentOrg) => {
  let numStr = String(number);

  let numZeros = 10 - numStr.length;
  let prefix = currentOrg?.prefix_initial || "";
  let result = prefix + "0".repeat(numZeros) + numStr;
  // the generate farmer id is suppose to be dynamic base on org prefix. org prefix is gotten from useOrgdata. current org is the value of where the prefix is

  return result;
};

const CreateContractualFarmer = ({ modal, selectedFarmer }) => {
  const organization = authService.getOrg();
  const seasonOptions = useSelector(getSeasonOptions);
  const { currentOrg } = useOrgData();

  const { mutateAsync, isLoading: createContractualFarmerLoading } = useCreateContractualFarmer();
  const { mutateAsync: changeMemberMutateAsync, isLoading: changeMemberFarmerLoading } = useChangeMemberToContractual({
    agentId: selectedFarmer?.agent?.agent_id,
  });
  const [isSectionOneValid, setIsSectionOneValid] = useState(false);
  const [isSectionTwoValid, setIsSectionTwoValid] = useState(false);
  const [isSectionThreeValid, setIsSectionThreeValid] = useState(false);

  const [memberFarmersList, setMemberFarmersList] = useState([]);

  useEffect(() => {
    setIsSectionThreeValid(memberFarmersList.length > 0);
  }, [memberFarmersList]);

  const [loginDetails, setLoginDetails] = useState(loginDetailsInitialValues);

  const [basicInfo, setBasicInfo] = useState(basicInfoInitialValues);

  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (selectedFarmer) {
      setLoginDetails({
        phone_number: selectedFarmer.phone_number,
        password: "",
      });
      setBasicInfo({
        seasonId: selectedFarmer.season.seasonId,
        region: "",
        first_name: selectedFarmer.first_name,
        last_name: selectedFarmer.last_name,
        middle_name: selectedFarmer.middle_name,
        email: selectedFarmer.agent.email,
        gender: selectedFarmer.gender,
        age: selectedFarmer.age,
        country: selectedFarmer.country,
        state: selectedFarmer.state,
        lga: selectedFarmer.lga,
        village: selectedFarmer.village,
        district: selectedFarmer.district,
        address: selectedFarmer.address,
      });
    }
  }, [selectedFarmer]);

  // The number of member farmers to be added + 1 which is the contractual farmer
  // For moving a member farmer to a contractual farmer, the member farmer's current farmerId is used so the number of ids to be generated reduces by 1
  const totalFarmers = selectedFarmer ? memberFarmersList.length : memberFarmersList.length + 1;

  const handleMutation = async farmerIdRange => {
    if (farmerIdRange.startRange === null || farmerIdRange.endRange === null) {
      toast.error("Use Wet Season 2024 for test");
      return;
    }
    const contractualFarmerPayload = {
      ...loginDetails,
      ..._.omit(basicInfo, ["seasonId", "age", "country", "lga", "phone_number"]),
      avatar_url: avatarUrl,
      farmer_id: selectedFarmer ? selectedFarmer?.farmer_id : generateFarmerId(farmerIdRange.startRange, currentOrg),
      country: basicInfo.country,
      lga: basicInfo.lga,
      age: basicInfo.age.toString(),
      status: "active",
      role: "lead_farmer",
      season: {
        seasonId: basicInfo.seasonId,
        name: seasonOptions.find(season => season.value === basicInfo.seasonId).label,
      },
      isContractual: true,
      organization: organization,
    };

    const memberFarmersIdRange = [];

    const startRange = selectedFarmer ? farmerIdRange.startRange : farmerIdRange.startRange + 1;

    for (let i = startRange; i <= farmerIdRange.endRange; i++) {
      memberFarmersIdRange.push(i);
    }

    let memberFarmersPayload = memberFarmersList;

    for (let i = 0; i < memberFarmersPayload.length; i++) {
      let item = memberFarmersPayload[i];

      item = {
        ..._.omit(item, ["age", "country", "lga"]),
        farmer_id: generateFarmerId(memberFarmersIdRange[i]),
        country: item.country,
        lga: item.lga,
        age: item.age.toString(),
        status: "active",
        role: "farmer",
        season: {
          seasonId: basicInfo.seasonId,
          name: seasonOptions.find(season => season.value === basicInfo.seasonId).label,
        },
      };

      memberFarmersPayload[i] = item;
    }

    const payload = {
      contractualFarmer: contractualFarmerPayload,
      memberFarmers: memberFarmersPayload,
    };

    await handleFormSubmission({
      submitFn: selectedFarmer ? changeMemberMutateAsync : mutateAsync,
      values: payload,
      config: {},
      errorMsg: selectedFarmer
        ? "Failed to change member to contractual farmer"
        : "Failed to create contractual farmer",
      successMsg: "",
      onSuccess() {
        confirmCreateModal.onOpen();
      },
      onError(error) {
        console.log({ error });
      },
    });
  };

  const { isFetching: farmerIdRangeLoading, refetch } = useGetFarmerIdRange(
    {
      seasonId: basicInfo.seasonId,
      totalFarmers: totalFarmers,
    },
    handleMutation
  );

  const confirmCreateModal = useDisclosure();

  const onSubmit = () => {
    refetch();
  };

  const allValid = isSectionOneValid && isSectionTwoValid && isSectionThreeValid;

  const formIsSubmitting = farmerIdRangeLoading || createContractualFarmerLoading;

  return (
    <>
      {/* <pre>
        <code>{JSON.stringify(avatarUrl, null, 2)}</code>
      </pre> */}
      <Accordion defaultValue={1} type="single" collapsible>
        <AccordionItem value={1}>
          <AccordionTrigger>
            <Flex gap="10px">
              {isSectionOneValid ? <ValidSectionIcon /> : <InvalidSectionIcon />}
              <Text>Login Details</Text>
            </Flex>
          </AccordionTrigger>
          <AccordionContent>
            <LoginDetailsForm
              setIsSectionValid={setIsSectionOneValid}
              loginDetails={loginDetails}
              setLoginDetails={setLoginDetails}
            />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value={2}>
          <AccordionTrigger>
            <Flex gap="10px">
              {isSectionTwoValid ? <ValidSectionIcon /> : <InvalidSectionIcon />}
              <Text>Basic Information</Text>
            </Flex>
          </AccordionTrigger>
          <AccordionContent>
            <BasicInformationForm
              setIsSectionValid={setIsSectionTwoValid}
              basicInfo={basicInfo}
              setBasicInfo={setBasicInfo}
              avatarUrl={avatarUrl}
              setAvatarUrl={setAvatarUrl}
            />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value={3}>
          <AccordionTrigger>
            <Flex gap="10px">
              {isSectionThreeValid ? <ValidSectionIcon /> : <InvalidSectionIcon />}
              <Text>Invite Members</Text>
            </Flex>
          </AccordionTrigger>
          <AccordionContent>
            <InviteMembers memberFarmersList={memberFarmersList} setMemberFarmersList={setMemberFarmersList} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Button mt="2rem" display="block" type="submit" isDisabled={!allValid || formIsSubmitting} onClick={onSubmit}>
        {formIsSubmitting ? "Submitting..." : "Submit to Create CF"}
      </Button>
      <Modal {...confirmCreateModal.modalProps}>
        <ModalContent variant="center" width={400} doNotCloseOnClickOutOrEscKey hidecloseicon>
          <SuccessDialog
            heading="Well done!!! "
            subHeading="<p>
            You have successfully created a<br />contractual farmer.
          </p>"
            confirmationText="Continue to Dashboard"
            onConfirm={() => {
              modal.modal.hideModal();
              confirmCreateModal.onClose();
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateContractualFarmer;

const ValidSectionIcon = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      background={organicTheme.colors.primary50}
      width="24px"
      height="24px"
      borderRadius="50%"
    >
      <img width={13} src={tickIcon} alt="check-icon" />
    </Flex>
  );
};

const InvalidSectionIcon = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      background={organicTheme.colors.gray200}
      width="24px"
      height="24px"
      borderRadius="50%"
    >
      <Box background={organicTheme.colors.gray400} width="8px" height="8px" borderRadius="50%"></Box>
    </Flex>
  );
};
