import React from "react";
import { PageWrapper } from "components/layout";
import { Box, Flex, Text, organicTheme } from "organic";
import { hyphenate } from "utils";
import { PageHeader } from "components/layout";
import { Table } from "components/table";
import { useDataFilter, useDisclosure, useTable } from "hooks";
import { ExportButton } from "components/export";
import { Modal } from "components/modal";
// import ViewActualTrade from "./editModal/viewActualTrade";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import { useGetActualTradeOverview, useGetActualTrades } from "hooks/app/marketplace";

const MarketplaceReconciliationPage = () => {
  // const page = 1;
  // const nPerPage = 10;
  const { filterParams } = useDataFilter();

  const { data: overviewData } = useGetActualTradeOverview({
    params: { dateRange: filterParams.dateRange },
  });

  const { data } = useGetActualTrades({});

  const estimatedTradeOverview = [
    {
      value: numeral(overviewData?.totalEstimatedTargetQuantity).format("0,0.00"),
      title: "Targeted Quantity (KG)",
    },
    {
      value: numeral(overviewData?.totalEstimatedTargetAchieved).format("0,0.00"),
      title: "Target Achieved (KG)",
    },
    {
      value: numeral(overviewData?.totalEstimatedRemaining).format("0,0.00"),
      title: "Target Remaining (KG) ",
    },
    {
      value: numeral(overviewData?.averageEstimatedUnitPrice).format("0,0.00"),
      title: "Average Unit Price (KES)",
    },
  ];

  const actualTradeOverview = [
    {
      value: numeral(overviewData?.totalActualSourced).format("0,0.00"),
      title: "Total Sourced (KG)",
    },
    {
      value: numeral(overviewData?.totalActualSold).format("0,0.00"),
      title: "Total Sold (KG)",
    },
    {
      value: numeral(overviewData?.totalActualUnsold).format("0,0.00"),
      title: "Total Unsold (KG) ",
    },
    {
      value: numeral(overviewData?.averageUnitPrice).format("0,0.00"),
      title: "Average Unit Price (KES)",
    },
  ];

  const navigate = useNavigate();

  const { tableProps, selectedRow: selectedTrade } = useTable({
    columns: [
      {
        Header: "Trade Ref ID ",
        accessor: "trade_id",
      },
      {
        Header: "Crop Type",
        accessor: "crop_type",
      },
      {
        Header: "Quantity (KG)",
        accessor: "target_quantity",
      },
      {
        Header: "Cost Price",
        accessor: "cost_price",
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
      },
      {
        Header: "Profit ",
        accessor: "anticipated_profit",
      },
      {
        Header: "Profit Margin (%)",
        accessor: "profit_margin",
      },
      // {
      //   Header: "Trade Reconciliation",
      //   accessor: "trade_reconciliation",
      //   Cell: ({ row }) => {
      //     return <Button
      //     // onClick={() => handleGenerateInvoice(row.original)}
      //     >Generate Invoice</Button>;
      //   },
      // },
    ],
    data: data?.data || [],
    title: "All trade reconciliation",
    useServerPagination: true,
    showCheckboxes: true,
  });
  const tradeEditModal = useDisclosure();

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > Trade Reconciliation" subtitle="Your trade summary and activity." />
        <ExportButton />
      </Flex>
      <Box height="387px">
        <Flex gap="2rem" justifyContent="center" height="100%">
          <Flex
            flexDirection="column"
            width="100%"
            outline={`1px solid ${organicTheme.colors.gray100}`}
            borderRadius="0.375rem"
            overflow="hidden"
            maxWidth="520px"
          >
            <Flex flexDirection="column" bg="warning50" px="1.5rem" py="1.25rem">
              <Text font="xlSemibold" color="gray900">
                Total Estimated Trade
              </Text>
              <Text color="gray600" font="mdRegular">
                Overview of estimated trade key metrics
              </Text>
            </Flex>
            <Flex flexWrap="wrap" alignItems="center" height="292px" p="24px" justifyContent="center" bg="white">
              {estimatedTradeOverview.map((tradeOverview, tradeOverviewIndex) => (
                <Flex
                  key={hyphenate(["tradeOverview", tradeOverview.title, tradeOverviewIndex])}
                  width="50%"
                  height="122px"
                >
                  <Flex
                    gap="0.5rem"
                    width="100%"
                    flexDirection="column"
                    outline={`1px solid ${organicTheme.colors.gray100}`}
                    pt="24px"
                    pb="24px"
                    pl="24px"
                  >
                    <Text color="gray500" font="smMedium" whitespace="no-wrap">
                      {tradeOverview.title}
                    </Text>
                    <Text color="gray900" font="displayXsBold">
                      {tradeOverview.value}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
          {/* Actual Card */}
          <Flex
            flexDirection="column"
            width="100%"
            borderRadius="0.75rem"
            outline={`1px solid ${organicTheme.colors.gray100}`}
            overflow="hidden"
            maxWidth="520px"
          >
            <Flex flexDirection="column" bg="primary50" px="1.5rem" py="1.25rem">
              <Text font="xlSemibold" color="gray900">
                Total Actual Trade
              </Text>
              <Text>Overview of estimated trade key metrics</Text>
            </Flex>
            <Flex flexWrap="wrap" alignItems="center" height="292px" p="24px" justifyContent="center" bg="white">
              {actualTradeOverview.map((tradeOverview, tradeOverviewIndex) => (
                <Flex
                  key={hyphenate(["tradeOverview", tradeOverview.title, tradeOverviewIndex])}
                  width="50%"
                  height="122px"
                  outline={`1px solid ${organicTheme.colors.gray100}`}
                >
                  <Flex gap="0.5rem" maxWidth="162px" flexDirection="column" pt="24px" pb="24px" pl="24px">
                    <Text color="gray500" font="smMedium" whitespace="no-wrap">
                      {tradeOverview.title}
                    </Text>
                    <Text color="gray900" font="displayXsBold">
                      {tradeOverview.value}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Table
        {...tableProps}
        onEditAction={() => {
          navigate(`/dashboard/marketplace/marketplaceReconciliation/edit/${selectedTrade.trade_id}`);
        }}
        onViewAction={() => {
          navigate(`/dashboard/marketplace/marketplaceReconciliation/${selectedTrade.trade_id}?mode=view`);
        }}
      />

      <Modal
        open={tradeEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            tradeEditModal.onClose();
          }
        }}
      ></Modal>
      {/* 
      <Modal
        open={actualTradeViewModal.isOpen}
        onOpenChange={(open) => {
          if (!open) {
            actualTradeViewModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Actual Trade" size="md">
          <ViewActualTrade selected={selectedTrade} modal={actualTradeViewModal.modal} />
        </ModalContent>
      </Modal> 
      */}
    </PageWrapper>
  );
};

export default MarketplaceReconciliationPage;
