import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import ManageModule_API from "services/ManageModuleService";

export function useGetAllExceptGroups({ params }) {
  const fetcher = async () => {
    const { data } = await ManageModule_API.getAllExceptGroups(params);
    return data;
  };
  // get all overview
  return useQuery({
    queryKey: [rqKeys.modules.getAllExceptGroups, ...Object.values(params)],
    queryFn: fetcher,
    enabled: !!params.season,
  });
}
