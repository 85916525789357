import React from "react";
import DataGridRow from "components/v1/DataGrid/DataGridRow";
import DataGridTitle from "components/v1/DataGrid/DataGridTitle";
// import FormGridRow from "components/v1/Forms/FormGridRow";
import FormGridTitle from "components/v1/Forms/FormGridTitle";
import { getUserData } from "features/v1/auth";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";
// import { initiateCreateFieldValues } from "../Farmers/farmerInitialValues";
import { getSelectedSeason } from "features/v1/season";
import { createFarmMappingInfo } from "features/v1/farmMapping";
// import LeagacyCropSelect from "components/v1/Select/CropSelect";
import HectareSizeSelect from "components/v1/Select/HectareSizeSelect";
import * as yup from "yup";
import { CropSelect } from "components/select";
import { Box, Button, Flex } from "organic";
import { useDataFilter } from "hooks";
// import { getLoadingFarmers } from "features/farmers";
const CreateFieldForFarmer = props => {
  const { selected, modal } = props;

  // let initialValues = initiateCreateFieldValues(selected);

  let { _id } = useSelector(getUserData);
  const { filterParams } = useDataFilter();

  let selectedSeason = useSelector(getSelectedSeason);
  // let loadingFarmers = useSelector(getLoadingFarmers);

  const dispatch = useDispatch();

  const handleCreateField = (values, formOptions) => {
    console.log("submitted");
    dispatch(createFarmMappingInfo(values, { modal, formOptions, selected, seasonId: filterParams.seasonId }));
  };

  return (
    <Formik
      onSubmit={handleCreateField}
      initialValues={{
        farm_size: 0,
        crop_type: "",
        created_by: _id,
        season: selectedSeason?.value,
        agent: selected.agent,
      }}
      validationSchema={() =>
        yup.object().shape({
          farm_size: yup.number().required(),
          crop_type: yup.string().required(),
          agent: yup.string().required("agent for this farmer was not found"),
        })
      }
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          <DataGridTitle title="Farmer Details" />
          <DataGridRow
            fields={[
              {
                label: "Agent",
                value: `${selected?.agent?.first_name || "N/A"} ${selected?.agent?.last_name || "N/A"}`,
              },
              {
                label: "Farmer",
                value: `${selected?.first_name} ${selected?.last_name}`,
              },
            ]}
          />
          <FormGridTitle title="New Field Details" />

          <Box mb={5}>
            <Label>Farm Size </Label>
            <HectareSizeSelect onChange={val => setFieldValue("farm_size", val)} />
          </Box>
          <Box mb={5}>
            <CropSelect
              label="Crop Type"
              name="crop_type"
              onChange={val => {
                console.log(val);
                setFieldValue("crop_type", val);
              }}
            />
          </Box>
          <Flex style={{ gap: "20px" }}>
            <Button type="button" colorScheme="secondaryGray" onClick={modal.hideModal}>
              Cancel
            </Button>
            <Button type="submit" isDisabled={isSubmitting}>
              Save Mapping Details
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CreateFieldForFarmer;
