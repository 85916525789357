import _ from "lodash";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

const ignoredErrorReportPayload = {
  // required
  appUrl: "", // eg. https://thrive-aos-web.herokuapp.com/dashboard/farmers
  user: {}, // userinfo from db
  errorCode: "", // generated from app
  error: {}, // Error obj
  errorInfo: {}, // Object with component stack trace
  // optional and not always accurate
  deviceDetails: {},
  ipAddress: "",
  ipInfo: {}, // ip adress and other info
  browserInfo: {}, //object with browser name, version and isMobile(bool)
};

class AOSError extends Error {
  constructor(code, message) {
    const fullMsg = message ? `${code}: ${message}` : code;
    super(fullMsg);
    this.name = code;
    this.code = code;
    this.message = fullMsg;
    this.isAOSError = true;

    Error.captureStackTrace(this, AOSError);
  }

  toString() {
    return this.message;
  }

  report = exception => {
    Sentry.captureException(exception);
  };

  notify = () => {
    toast.error(this.message);
  };
}
export class AOSTypeError extends TypeError {
  constructor(code, message) {
    const fullMsg = message ? `${code}: ${message}` : code;
    super(fullMsg);
    this.name = code;
    this.code = code;
    this.message = fullMsg;

    Error.captureStackTrace(this, AOSTypeError);
  }

  toString() {
    return this.message;
  }

  report = exception => {
    Sentry.captureException(exception);
  };
}
export class AOSSyntaxError extends SyntaxError {
  constructor(code, message) {
    const fullMsg = message ? `${code}: ${message}` : code;
    super(fullMsg);
    this.name = code;
    this.code = code;
    this.message = fullMsg;

    Error.captureStackTrace(this, AOSSyntaxError);
  }

  toString() {
    return this.message;
  }

  report = exception => {
    Sentry.captureException(exception);
  };
}
export class AOSReferenceError extends ReferenceError {
  constructor(code, message) {
    const fullMsg = message ? `${code}: ${message}` : code;
    super(fullMsg);
    this.name = code;
    this.code = code;
    this.message = fullMsg;

    Error.captureStackTrace(this, AOSReferenceError);
  }

  toString() {
    return this.message;
  }

  report = exception => {
    Sentry.captureException(exception);
  };
}

export class AOSNetworkError extends AOSError {
  constructor(message) {
    super("NetworkError", message);
  }
}

export class AOSServerError extends AOSError {
  constructor(message) {
    super("ServerError", message);
  }
}
export class AOSClientError extends AOSError {
  constructor(message) {
    super("AOSError", message);
  }
}

// function reportError(Exception) {
//   Sentry.captureException(Exception);
// }
// handleAction Errors from axios calls
export const handleActionError = (error, name = "Error: ", message = "Action Failed") => {
  if (error.response) {
    switch (error.response) {
      case error.response.status === 400: {
        // Bad Request
        break;
      }
      case error.response.status === 401: {
        // Unauthorized
        break;
      }
      case error.response.status === 402: {
        // Payment Required
        break;
      }
      case error.response.status === 403: {
        // Forbidden
        break;
      }
      case error.response.status === 404: {
        // Not Found
        break;
      }
      case error.response.status === 405: {
        // Mehtod No Allowed
        break;
      }
      case error.response.status === 406: {
        // Not Acceptable
        break;
      }
      case error.response.status === 407: {
        // Not Found
        break;
      }
      case error.response.status === 500: {
        // Internal Server Error
        break;
      }
      case error.response.status === 501: {
        // Not Implemented
        break;
      }
      case error.response.status === 502: {
        // Bad gateway
        break;
      }
      case error.response.status === 503: {
        // Service Unavailable
        break;
      }
      case error.response.status === 504: {
        // Gateway Timeout
        break;
      }
      default: {
        break;
      }
    }

    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(name, " ", error.response.data);
    if (_.isString(error.response.data)) {
      toast.error(error.response.data);
      console.log(error.response.data);
      return;
    }
    toast.error(error.response?.message || error.response?.data?.message || message);
    console.log(name, " ", error.response.status);
    console.log(name, " ", error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(name, " ", error.request);
    return;
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log(name, " ", error.message);
    toast.error(error.message);
    return;
  }

  // console.log("error object", error.toJSON());
};

export function handleBrokenPromises() {
  window.addEventListener("unhandledrejection", event => {
    console.error(event.reason);
    // report the error here
    const bug = new AOSClientError("An unhandled error just occured: there's an error you're not catching");
    bug.report(event);
    bug.notify();
  });
}

export function isAOSError(error) {
  return error?.isAOSError || false;
}

export function tryCatchWrapper(fn) {
  return function (...args) {
    try {
      fn(...args);
    } catch (error) {
      if (isAOSError(error)) {
        error.report(error);
        error.notify();
      }
      console.log("trycatchWrapper: ", { error });
    }
  };
}
