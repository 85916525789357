import React from "react";
import Select2 from "./select2";
import { useSelector } from "react-redux";
import { getLoadingRegions, getRegionOptions } from "features/v1/regions";

const RegionSelect2 = ({ label, placeholder, ...props }) => {
  const loadingRegions = useSelector(getLoadingRegions);
  const regionOptions = useSelector(getRegionOptions);

  return (
    <Select2 label={label} placeholder={placeholder} isLoading={loadingRegions} options={regionOptions} {...props} />
  );
};

export default RegionSelect2;
