import React, { useEffect, useState } from "react";
import { isFunction } from "utils";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import { Select } from ".";

export const CROP_CATEGORY_OPTIONS = [
  { label: "Crop", value: "crop" },
  { label: "Livestock", value: "livestock" },
];
const CropCategorySelect = ({ onChange, defaultValue, isMulti, ...restProps }) => {
  const [selectedCropCategory, setSelectedCropCategory] = useState();

  const handleCropCategoryChange = val => {
    if (!val) {
      setSelectedCropCategory(null);
      return;
    }
    let selected;
    let onChangeValue;

    if (isMulti) {
      selected = val;
      if (Array.isArray(selected)) {
        setSelectedCropCategory(selected);
      }
      onChangeValue = selected.map(item => item.value);
    }

    if (!isMulti) {
      selected = selectOptionFromValue(CROP_CATEGORY_OPTIONS, val);
      if (selected) {
        setSelectedCropCategory(selected);
      }
      onChangeValue = val;
    }

    if (isFunction(onChange)) onChange(onChangeValue);
  };

  useEffect(() => {
    if (defaultValue && isMulti) {
      const _defaultSelected = defaultValue.map(_val => selectOptionFromValue(CROP_CATEGORY_OPTIONS, _val));
      if (_defaultSelected) {
        setSelectedCropCategory(_defaultSelected);
      }
    }

    if (defaultValue && !isMulti) {
      const _defaultSelected = selectOptionFromValue(CROP_CATEGORY_OPTIONS, defaultValue);
      if (_defaultSelected) {
        setSelectedCropCategory(_defaultSelected);
      }
    }
  }, []);

  return (
    <Select
      options={CROP_CATEGORY_OPTIONS}
      // defaultValue={selectedCropCategory}
      onChange={handleCropCategoryChange}
      value={selectedCropCategory}
      isMulti={isMulti}
      label="Crop or Livesctock"
      {...restProps}
    />
  );
};

export { CropCategorySelect };
