// eslint-disable-next-line no-unused-vars
import React from "react";
import * as SeparatorPrimitives from "@radix-ui/react-separator";
import { Box } from "organic";

const BaseDivider = SeparatorPrimitives.Root;

const Divider = (
  orientation = "horizontal",
  className,
  decoratrive = false,
  children,
  asChild = false,
  ...restProps
) => {
  return (
    <Box {...restProps}>
      <BaseDivider className={className} orientation={orientation} decorative={decoratrive} asChild={asChild}>
        {children}
      </BaseDivider>
    </Box>
  );
};

Divider.defaultProps = {
  className: "divider divider--light",
};

export { Divider };
