import React from "react";
import { useDataFilter, useLegacyDidMountEffect, useToggle } from "hooks";
import { Box, Button, Flex, Text } from "organic";
import { useDispatch, useSelector } from "react-redux";
import { DateRangeFilter } from "components/filter";
import { PageWrapper } from "components/layout";
import { rightGridSchema } from "components/v1/Charts/newChart/rightGridData";
import { leftGridSchema } from "components/v1/Charts/newChart/leftGridData";
import {
  getAllAgentsOverview,
  getAllFarmMappingsOverview,
  getAllFarmSubPaymentOverview,
  getAllFarmersOverview,
  getAllSubActivityOverview,
} from "features/v1/overview";
import ChartGrid from "components/chart/chartGrid";
import { getUserData } from "features/v1/auth";
import { PageHeader } from "components/layout";
import { DEMO_MODE_KEY } from "constants/demoConstants";

const OVERVIEW_GRID_LEFT_ROW_HEIGHT = 249;
const OVERVIEW_GRID_RIGHT_GRID_HEIGHT = 207;

const OVERVIEW_GRID_LEFT_GRID_NAME = "overviewLeftGrid";
const OVERVIEW_GRID_RIGHT_GRID_NAME = "overviewRightGrid";

const LEFT_COL_COUNT = 2;
const RIGHT_COL_COUNT = 1;

// const UNIT_COMMITMENT_FEE = 500;
const isDemoMode = sessionStorage?.getItem?.(DEMO_MODE_KEY) ? true : false;

function OverviewPage() {
  const dispatch = useDispatch();

  let { first_name } = useSelector(getUserData);

  const { isValidFilter, filterParams: options } = useDataFilter();
  const { isOn: canEditLayout, toggle: toggleCanEditLayout } = useToggle();

  const resetLayout = () => {};

  const handleSave = () => {
    toggleCanEditLayout(false);
  };

  useLegacyDidMountEffect(() => {
    if (isValidFilter === true || isDemoMode) {
      dispatch(getAllFarmersOverview(options)); //farmer
      dispatch(getAllAgentsOverview(options)); //agent
      dispatch(getAllFarmMappingsOverview(options)); //farmMapper
      dispatch(getAllSubActivityOverview(options)); // subscription
      dispatch(getAllFarmSubPaymentOverview(options)); // subscription payment
    }
  }, [options, isValidFilter]);

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between">
        <PageHeader
          title={`Welcome Back${first_name ? ", " + first_name : ""}`}
          subtitle="Your operations summary and activity."
        />
        <Flex flexDirection="row" alignItems="center">
          <Box marginRight="12px">
            <DateRangeFilter />
          </Box>
          {canEditLayout ? (
            <>
              <Button ml="0.725rem" colorScheme="tertiaryColor" onClick={resetLayout}>
                Reset Layout
              </Button>
              <Button onClick={handleSave}>Save Layout</Button>
            </>
          ) : (
            <Button leftIcon="layout" onClick={() => toggleCanEditLayout(true)}>
              <Text as="span" font="smSemibold">
                Edit Layout
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
      <div className="overview-chart-grid">
        <div className="overview-chart-grid__left">
          <ChartGrid
            gridData={leftGridSchema}
            gridName={OVERVIEW_GRID_LEFT_GRID_NAME}
            isEditable={canEditLayout}
            rowHeight={OVERVIEW_GRID_LEFT_ROW_HEIGHT}
            cols={LEFT_COL_COUNT}
            gridGap={16}
          />
        </div>
        <div className="overview-chart-grid__right">
          <ChartGrid
            gridData={rightGridSchema}
            isEditable={canEditLayout}
            gridName={OVERVIEW_GRID_RIGHT_GRID_NAME}
            rowHeight={OVERVIEW_GRID_RIGHT_GRID_HEIGHT}
            cols={RIGHT_COL_COUNT}
            gridGap={16}
          />
        </div>
      </div>
    </PageWrapper>
  );
}

export default OverviewPage;
