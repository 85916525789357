import React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { classnames } from "utils";

const Switch = ({ size, colorScheme, onCheckedChange, ...restOfSwitchProps }) => {
  let switchClassnames = classnames("switch", {
    "switch--sm": size === "sm",
    "switch--md": size === "md",
    "switch--light": colorScheme === "light",
    "switch--dark": colorScheme === "dark",
  });

  return (
    <SwitchPrimitives.Root
      className={switchClassnames}
      onCheckedChange={e => onCheckedChange?.(e)}
      {...restOfSwitchProps}
    >
      <SwitchPrimitives.Thumb className="switch__thumb" />
    </SwitchPrimitives.Root>
  );
};

Switch.defaultProps = {
  size: "md",
  colorScheme: "dark",
};

export { Switch };
