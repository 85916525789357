import React from "react";
import * as DropdownMenuPrimitives from "@radix-ui/react-dropdown-menu";
import { Icon } from "organic";
import { classnames } from "utils";

const DropdownMenu = DropdownMenuPrimitives.Root;

const DropdownTrigger = DropdownMenuPrimitives.Trigger;
DropdownTrigger.defaultProps = {
  className: "default-dropdown__trigger",
};

const DropdownContent = React.forwardRef(function (
  { children, className, unstyled, ...restOfDropdownContentProps },
  forwardedRef
) {
  const dropdownContentClassNames = classnames(className, { "default-dropdown__content": unstyled === true });
  return (
    <DropdownMenuPrimitives.Portal>
      <DropdownMenuPrimitives.Content
        className={dropdownContentClassNames}
        sideOffset={5}
        ref={forwardedRef}
        {...restOfDropdownContentProps}
      >
        {children}
      </DropdownMenuPrimitives.Content>
    </DropdownMenuPrimitives.Portal>
  );
});

DropdownContent.displayName = "DropdownContent";

const DropdownLabel = DropdownMenuPrimitives.Label;
DropdownLabel.defaultProps = {
  className: "default-dropdown__label",
};

const DropdownItem = DropdownMenuPrimitives.Item;
DropdownItem.defaultProps = {
  className: "default-dropdown__item",
};

const DropdownGroup = DropdownMenuPrimitives.Group;
DropdownGroup.defaultProps = {
  className: "default-dropdown__group",
};

const DropdownCheckboxItem = React.forwardRef(function (
  { children, ...restOfDropdownCheckbocItemProps },
  forwardedRef
) {
  return (
    <DropdownMenuPrimitives.DropdownMenuCheckboxItem {...restOfDropdownCheckbocItemProps} ref={forwardedRef}>
      {children}
      <DropdownMenuPrimitives.ItemIndicator>
        {restOfDropdownCheckbocItemProps.checked === "indeterminate" && <Icon icon="minus" />}
        {restOfDropdownCheckbocItemProps.checked === true && <Icon icon="check" />}
      </DropdownMenuPrimitives.ItemIndicator>
    </DropdownMenuPrimitives.DropdownMenuCheckboxItem>
  );
});

DropdownCheckboxItem.displayName = "DropdownCheckboxItem";
DropdownCheckboxItem.defaultProps = {
  className: "default-dropdown__checkbox-item",
};

const DropdownRadioGroup = DropdownMenuPrimitives.RadioGroup;
DropdownRadioGroup.defaultProps = {
  className: "default-dropdown__radio-group",
};

const DropdownRadioItem = React.forwardRef(function ({ children, ...restOfDropdownRadioItemProps }, forwardedRef) {
  return (
    <DropdownMenuPrimitives.RadioItem {...restOfDropdownRadioItemProps} ref={forwardedRef}>
      {children}
      <DropdownMenuPrimitives.ItemIndicator>
        <Icon icon="check" />
      </DropdownMenuPrimitives.ItemIndicator>
    </DropdownMenuPrimitives.RadioItem>
  );
});

DropdownRadioItem.displayName = "DropdownRadioItem";
DropdownRadioItem.defaultProps = {
  className: "default-dropdown__radio-item",
};

const DropdownSeparator = DropdownMenuPrimitives.DropdownMenuSeparator;
DropdownSeparator.defaultProps = {
  className: "default-dropdown__separator",
};

export {
  DropdownMenu,
  DropdownTrigger,
  DropdownContent,
  DropdownLabel,
  DropdownItem,
  DropdownGroup,
  DropdownRadioGroup,
  DropdownRadioItem,
  DropdownSeparator,
};
