import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { getAllContactData, getAllContacts } from "features/v1/bulkSms";
import { useDisclosure, useTable } from "hooks";
import { Box } from "organic";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, isFunction } from "utils";
import ViewAdddressBookContacts from "./viewAdddressBookContacts";

const USE_CONTACT_BOOK_ACTION_KEY = "useContactBook";

const ContactBook = ({ onSelect }) => {
  const dispatch = useDispatch();
  let contactBooks = useSelector(getAllContactData);

  const viewContactsDisclosure = useDisclosure();
  const { tableProps, selectedRow: selectedAdresBook } = useTable({
    data: contactBooks,
    columns: [
      { Header: "Title", accessor: "address_book_title" },
      { id: "contactCount", Header: "Numbers Of Contacts", accessor: contactBook => `${contactBook.content?.length}` },
      { id: "contactBookDate", Header: "Created on", accessor: contactBook => formatDate(contactBook.created_at) },
    ],
    noLocalFilter: true,
    noDateRangeFilter: true,
    noEditAction: true,
    title: "All Contacts",
  });

  const getContactBookExtraActions = () => {
    return [{ actionKey: USE_CONTACT_BOOK_ACTION_KEY, label: "Use these contacts" }];
  };

  useEffect(() => {
    dispatch(getAllContacts());
  }, []);

  return (
    <Box>
      <Table
        {...tableProps}
        getExtraActions={getContactBookExtraActions}
        onViewAction={() => {
          viewContactsDisclosure.onOpen();
        }}
        onExtraAction={(selectedAction, { __filterParams }) => {
          if (selectedAction.actionKey === USE_CONTACT_BOOK_ACTION_KEY) {
            if (isFunction(onSelect)) {
              onSelect(selectedAction.selected);
            }
          }
        }}
      />

      <Modal {...viewContactsDisclosure.modalProps}>
        <ModalContent title="View Contact Book Details">
          <ViewAdddressBookContacts addressBook={selectedAdresBook} />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ContactBook;
