/* eslint-disable no-unused-vars */
import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { isFunction } from "utils";
import { Badge } from "organic";

export const PageTabs = ({ tabs, onValueChange }) => {
  return (
    <Tabs.Root className="page-tabs" defaultValue="tab1" onValueChange={onValueChange}>
      <Tabs.List className="page-tabs__list" aria-label="A Page with Tabs and tabbed content">
        {tabs.map((tab, index) => (
          <Tabs.Trigger key={index} className="page-tabs__trigger" value={`tab${index + 1}`}>
            {tab.title} {<Badge>{tab?.badge ?? ""}</Badge> || null}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {tabs.map((tab, index) => (
        <Tabs.Content key={index} className="page-tabs__content" value={`tab${index + 1}`}>
          {isFunction(tab.content) ? tab.content() : tab.content}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
};
