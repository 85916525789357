import language from "api/language";
import { toast } from "react-toastify";

// actions
const GET_TERMS_AND_CONDITIONS = "GET_TERMS_AND_CONDITIONS";
const LOAD_GET_TERMS_AND_CONDITIONS = "LOAD_GET_TERMS_AND_CONDITIONS";

export const createTermsAndConditions = (values, submitProps, isDeleteAction) => async __dispatch => {
  const sanitizedValues = values.termsAndConditions.map(term => {
    if (term.created_at && term._id) {
      delete term.created_at;
      delete term._id;
      return term;
    }
    return term;
  });
  const response = await language.post("/create", { termsAndConditions: sanitizedValues });

  if (response.status === 200) {
    if (isDeleteAction) {
      toast.success("Term and Condition deleted successfully");
    } else {
      toast.success("Term and Condition created successfully");
    }
    __dispatch(getAllTermsAndConditions());
    submitProps?.resetForm();
    submitProps?.setSubmitting(false);
  } else if (response.status === 401) {
    toast.error("You are not authorized to do that!");
    submitProps?.setSubmitting(false);
  }

  await (() => submitProps?.setSubmitting(false));
  // i dont know if it works
  return {
    setSubmitting: submitProps?.setSubmitting,
    setErrors: submitProps?.setErrors,
  };
};

export const getAllTermsAndConditions = () => async dispatch => {
  try {
    dispatch({
      type: LOAD_GET_TERMS_AND_CONDITIONS,
      payload: true,
    });
    const termsAndConditions = await language.get("/latest");

    dispatch({
      type: GET_TERMS_AND_CONDITIONS,
      payload: termsAndConditions.data.data[0].termsAndConditions,
    });
  } catch (error) {
    dispatch({
      type: LOAD_GET_TERMS_AND_CONDITIONS,
      payload: false,
    });
  }
};

// reducer
const initialState = {
  termsAndConditions: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditions: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getTermsAndConditions = state => state.legacyLanguage.termsAndConditions;
export const getTermsAndConditionsLoading = state => state.legacyLanguage.loading;
