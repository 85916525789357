import survey from "api/survey";
import { handleActionError } from "services/shared/AOSErrorService";

const GET_ALL_SURVEYS = "GET_ALL_SURVEYS";
const LOAD_SURVEYS = "LOAD_SURVEYS";

/** Get all Surveys */
export const getAllSurveys = params => async dispatch => {
  try {
    dispatch({ type: LOAD_SURVEYS, payload: true });
    const surveysRes = await survey.get("", { params });

    if (surveysRes.status === 200) {
      dispatch({
        type: GET_ALL_SURVEYS,
        payload: {
          surveysData: surveysRes.data.data,
          surveysMeta: surveysRes?.data?.meta || {},
        },
      });
    }
    dispatch({ type: LOAD_SURVEYS, payload: false });
  } catch (getAllSurveysError) {
    dispatch({ type: LOAD_SURVEYS, payload: false });
    handleActionError({ getAllSurveysError });
  }
};

const initialState = {
  surveysData: [],
  surveysMeta: {},
  loadingSurveys: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_SURVEYS:
      return { ...state, ...payload };
    case LOAD_SURVEYS:
      return { ...state, loadingSurveys: payload };

    default:
      return state;
  }
};

export const getSurveyData = state => state.legacySurvey.surveysData;
export const getLoadingSurveyData = state => state.legacySurvey.loadingSurveys;
