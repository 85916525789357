import React, { useEffect } from "react";
import { Text } from "organic";
import { useTable } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { getAllRiskAgents, getAllRiskAgentsData } from "features/v1/riskManagement";
import AssignAgentsPopover from "./assignAgentsPopover";

const useRiskAgentTable = () => {
  const dispatch = useDispatch();
  const allRiskAgents = useSelector(getAllRiskAgentsData);

  useEffect(() => {
    dispatch(getAllRiskAgents());
  }, []);

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: cell => {
        const row = cell.row.original;
        return (
          <Text color="primary600" font="smMedium">
            {`${row?.first_name} ${row?.last_name}`}
          </Text>
        );
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Gender",
      accessor: "gender",
      Cell: ({ value }) => {
        return <span>{value}</span>;
      },
    },
    {
      Header: "Region",
      accessor: "region",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "No. of Assigned Farmers",
      accessor: "assigned_farmers",
      Cell: ({ value }) => <span>{value ? value : "--"}</span>,
    },
    {
      Header: "",
      accessor: "action__",
      Cell: cell => {
        const id = cell.row.original._id;
        const riskAgent = cell.row.original;
        return <AssignAgentsPopover riskAgent={riskAgent} id={id} />;
      },
    },
  ];

  // 6576d6a1b901835c01343b99

  const riskAgentsTable = useTable({
    data: allRiskAgents,
    columns,
    title: "All Risk Agents",
    noDateRangeFilter: true,
    noActions: true,
    // callbacks
  });
  return riskAgentsTable;
};

export default useRiskAgentTable;
