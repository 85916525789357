import { Modal, ModalContent } from "components/modal";
import { Table } from "components/table";
import { getAllContractors, getAllContractorsData } from "features/v1/contractors";
import { useDisclosure, useTable } from "hooks";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateContractorForm from "views/forms/createContractorForm";
// import CreateContractorForm from "views/forms/CreateContractorForm";

const ContractorSettingsPage = () => {
  let allContractorsData = useSelector(getAllContractorsData);

  const dispatch = useDispatch();

  const { tableProps } = useTable({
    data: allContractorsData,
    columns: [
      {
        Header: "Contactor ID",
        accessor: "contractor_id",
      },
      {
        Header: "Contractor Name",
        accessor: "contractor_name",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
      },
      {
        Header: "Category",
        accessor: "contractor_category",
      },
    ],
    title: "All Contractors",
    noViewAction: true,
  });

  useEffect(() => {
    dispatch(getAllContractors());
  }, []);
  const createContractorDisclosure = useDisclosure();

  return (
    <div>
      <Table
        {...tableProps}
        createAction={{ label: "Add New Contractor" }}
        onCreateAction={() => {
          createContractorDisclosure.onOpen();
        }}
      />
      <Modal
        open={createContractorDisclosure.isOpen}
        onOpenChange={open => {
          //  example of on modal close
          if (open === false) {
            createContractorDisclosure.onClose();
          }
        }}
      >
        <ModalContent title="Add New Contractors">
          <CreateContractorForm />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ContractorSettingsPage;
