import { FormInput } from "components/inputs";
import { Tooltip } from "components/modal";
import { GenderSelect } from "components/select";
// import OverlayLoader from "components/v1/Loader/OverlayLoader";
import { getLoadingUser, getUserData, updateUser } from "features/v1/auth";
import { Form, Formik } from "formik";
// import { useToggle } from "hooks";
import { useScope } from "hooks/app";
import { Badge, Box, Button, Flex, Text } from "organic";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScopeDescriptor } from "services/v1/rolesAndPermissionsService";
import { appGet, formatRole } from "utils";
import AvatarUrl from "utils/ta-utils/avatar-url";

const EditUserForm = ({ modal }) => {
  // const { isOn, toggle } = useToggle();
  const loadingUser = useSelector(getLoadingUser);
  const { avatar_url, role, first_name, last_name, email, gender, phone_number } = useSelector(getUserData);
  const { userScope } = useScope();
  const dispatch = useDispatch();
  const handleUpdateUser = (values, formOptions) => {
    dispatch(updateUser({ ...values }, { formOptions, modal, onSuccess: formOptions.resetForm }));
  };

  return (
    <div>
      <Formik
        initialValues={{
          first_name: first_name,
          last_name: last_name,
          gender: gender,
          phone_number: phone_number,
          email: email,
        }}
        onSubmit={handleUpdateUser}
        enableReinitialize
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <Flex flexDirection="column" gap="1rem">
                <Flex alignItems="center" flexDirection="column">
                  <AvatarUrl avatarUrl={avatar_url} size="150px" borderRadius="50%" />
                  <Tooltip
                    content={
                      <Box bg="white" zIndex={999999} width={300} height={400}>
                        {userScope?.map?.((scope, scopeIndex) => {
                          return (
                            <Badge key={scopeIndex?.toString?.()} size="xs">
                              {getScopeDescriptor(scope)}
                            </Badge>
                          );
                        })}
                      </Box>
                    }
                  >
                    <Badge size="lg">
                      <Text as="span" textTransform="capitalize">
                        {formatRole(role)}
                      </Text>
                    </Badge>
                  </Tooltip>
                </Flex>
                <Box>
                  <FormInput label="First Name" name="first_name" defaultValue={first_name} />
                </Box>
                <Box>
                  <FormInput label="Last Name" name="last_name" defaultValue={last_name} />
                </Box>
                <Box>
                  <FormInput label="Phone Number" name="phone_number" defaultValue={phone_number} />
                </Box>
                <Box>
                  <FormInput label="Email Address" name="email" defaultValue={email} />
                </Box>
                {/* <Box>
                  <FormTextArea label="Email Address" name="email" defaultValue={email} rows={8} col={10} />
                </Box> */}

                <Box>
                  <GenderSelect
                    label="Gender"
                    onChange={value => {
                      setFieldValue("gender", value);
                    }}
                    value={appGet(values, "gender")}
                  />
                </Box>
                <Flex>
                  <Button isDisabled={loadingUser} type="submit">
                    Save Changes
                  </Button>
                </Flex>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditUserForm;
