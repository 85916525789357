import { ExportButton } from "components/export";
import { Divider, PageHeader, PageWrapper, Panel, VisibilityWrapper } from "components/layout";
import { Box, Button, Flex, Icon, Text, buttonConstants } from "organic";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MobilePreview from "../settings/questionnaire/mobilePreview";
import { ErrorMessage, Form, Formik } from "formik";
import { FormInput, FormTextArea } from "components/inputs";
import { createSMSCampaignValidationSchema } from "validators/messagingValidationSchemas";
import { CountrySelect, Select } from "components/select";
import { PageTabs } from "components/pageTabs";
import ChooseRecipient from "./chooseRecipient";
import ImportContacts from "./importContacts";
import ContactBook from "./contactBook";
import { useOrgData } from "hooks";
import { debounce, hyphenate, queryStringToObject } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  createCampaign,
  createDraft,
  getAllPhoneNumbersWithParams,
  getAllTemplateData,
  getAllTemplates,
} from "features/v1/bulkSms";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import MessageCounter from "./messageCounter";
import MessageBubbles from "./messageBubbles";
import RecipientsList from "./recipientsList";
import bulkSms from "api/bulkSms";
import { toast } from "react-toastify";
import { getSenderId } from "services/v1/authService";
import { getCurrentOrganization } from "features/v1/organization";
// import { isValidIntlPhoneNumber } from "validators/phoneNumberValidators";
// import { selectOptionFromValue } from "utils/selectUtils";
// import parsePhoneNumber from 'libphonenumber-js'

const createSelectOptionFromTemplate = template => ({
  label: template.template_name,
  value: template._id,
  content: template.content,
});

const initiateCampaignInitValues = (
  { draft = null, template = null, senderId = "" } = { draft: null, template: null, senderId: "" }
) => {
  if (draft) {
    return {
      campaign_title: draft?.campaign_title || "",
      sender_id: senderId || "AOS",
      content: draft?.content || "",
      size: draft?.size || "",
      recipient: draft?.recipient || [],
      country_code: "",
      // status: draft?.status || "processing",
    };
  }

  if (template) {
    return {
      campaign_title: template?.template_name || "",
      sender_id: senderId || "AOS",
      content: template?.content || "",
      size: template?.content?.length || "",
      recipient: [],
      country_code: "",
      // status: "processing",
    };
  }
  return {
    campaign_title: "",
    sender_id: senderId || "AOS",
    content: "",
    size: "",
    recipient: [],
    country_code: "",
  };
};

const CreateCampaign = () => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateOptions, setTemplateOptions] = useState([]);
  const [hint, setHint] = useState("");
  const [isCampaignNameValid, setIsCampaignNameValid] = useState();
  const [savingDraft, setSavingDraft] = useState(false);
  const [recipientErrors, setRecipientErrors] = useState();
  const [campaignCountry, setCampaignCountry] = useState("");

  let validateTitleAbortRef = useRef(null);

  const [searchParams, __] = useSearchParams();

  const dispatch = useDispatch();

  const naviagate = useNavigate();

  let messageTemplates = useSelector(getAllTemplateData);
  // let recipients = useSelector(getAllPhoneNumbersData);

  const { senderId, senderIdStatus, loading } = useOrgData();

  const initialValues = useMemo(() => {
    return initiateCampaignInitValues({ ...queryStringToObject(searchParams), senderId });
  }, [senderId, searchParams]);

  const senderIdHint = useMemo(() => {
    if (campaignCountry === "KE") "Using Swift_INFO";
    if (Boolean(senderIdStatus) === true) "Your organisation bulk sms sender ID is not activated, kindly contact sales";
    if (Boolean(senderIdStatus) !== true) "Sender Id is active";
  }, [campaignCountry, senderIdStatus]);

  const validateCampaignTitle = async (value, { abortController }) => {
    try {
      if (value?.length === 0) {
        setHint("");
        return null;
      }

      const response = await bulkSms.get("validateCampaignTitle", {
        params: { campaign_title: value },
        signal: abortController.signal,
      });
      return response;
    } catch (validateCampaignTitleError) {
      console.log({ validateCampaignTitleError });
      setHint(`Hurray Campaign title is available`);
      return validateCampaignTitleError?.response || null;
    }
  };

  const asyncValidate = async campaignTitle => {
    let abortController = new AbortController();
    if (validateTitleAbortRef?.current) {
      validateTitleAbortRef.current.abort();
    } else {
      validateTitleAbortRef.current = abortController;
    }
    const response = await validateCampaignTitle(campaignTitle, { abortController });

    return new Promise(resolve => {
      try {
        console.log({ data: response });
        if (response?.data?.status === "success") {
          setIsCampaignNameValid(false);
          setHint(`Campaign title already in use by you`);
          resolve(`Campaign with title '${campaignTitle}' already exists, Try a different name`);
          return;
        }
        if (response?.data?.status === "failure") {
          setIsCampaignNameValid(true);
          setHint(`Hurray! '${campaignTitle}' Campaign title is available`);
          // resolve();
          return;
        }
      } catch (asyncValidateError) {
        console.log({ asyncValidateError });
      }
    });
  };

  const debouncedValidateCampaignTitle = debounce(val => asyncValidate(val), 1500);

  const handleSendCampaign = (values, formOptions) => {
    if (recipientErrors?.length) {
      toast.error("recipients contains errors kindly confirm all phone numbers are valid");
      return;
    }
    const allValues = { ...values, size: `${values.content.length}` };
    dispatch(createCampaign(allValues, { formOptions }));
  };

  const handleDraftCampaign = values => {
    setSavingDraft(true);
    const allValues = { ...values, size: `${values.content.length}` };

    dispatch(
      createDraft(allValues, {
        onSuccess: () => {
          setSavingDraft(false);
        },
      })
    );
  };

  useEffect(() => {
    const queryParams = queryStringToObject(searchParams);
    if (queryParams?.template) {
      const selectedTemplate = createSelectOptionFromTemplate(queryParams.template);
      if (selectedTemplate) {
        setSelectedTemplate(() => {
          return selectedTemplate;
        });
      }
    }

    if (messageTemplates?.length) {
      setTemplateOptions(() => {
        return messageTemplates.map(createSelectOptionFromTemplate);
      });
    }
  }, [messageTemplates, searchParams]);

  useEffect(() => {
    if (!templateOptions?.length) {
      dispatch(getAllTemplates());
    }
  }, []);

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" alignItems="center">
        <PageHeader title="Messaging > SMS Campaign" subtitle="Manage all SMS campaign and activity." />
        <ExportButton />
      </Flex>
      <Formik
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={createSMSCampaignValidationSchema}
        onSubmit={handleSendCampaign}
      >
        {({ values, setFieldValue, isSubmitting, setFieldError }) => {
          console.log({ values });
          return (
            <Form>
              <Panel
                headerElement={
                  <Button
                    variant="unstyled"
                    leftIcon="arrow-left"
                    onClick={() => {
                      naviagate("/dashboard/messaging");
                      // TODO: add a confirmation for existing - especially if form is dirty
                    }}
                  >
                    <Text font="lgMedium">Back to Campaigns</Text>
                  </Button>
                }
              >
                <Divider />

                <Box width="100%" height="100%">
                  <Flex>
                    {/* left side */}
                    <Flex width="100%" p="24px">
                      <Flex flexDirection="column" width="100%" gap="20px">
                        <Box width="100%">
                          <FormInput
                            label="Campaign Title"
                            required
                            name="campaign_title"
                            validate={debouncedValidateCampaignTitle}
                            hint={hint}
                            isInvalid={!isCampaignNameValid}
                          />
                        </Box>
                        <Box>
                          <FormInput
                            label="Sender ID"
                            defaultValue={senderId}
                            name="sender_id"
                            disabled
                            required
                            value={getSenderId()?.id}
                            hint={senderIdHint}
                            rightAddon={
                              <Button
                                isDisabled={loading}
                                onClick={() => {
                                  dispatch(getCurrentOrganization());
                                }}
                                variant={buttonConstants.variants.UNSTYLED}
                              >
                                <Icon icon="rotate-cw" />
                              </Button>
                            }
                          />
                          {!values?.sender_id && <Text font="xsMedium">default sender ID will be used</Text>}
                        </Box>
                        <Flex width="100%" gap="1rem">
                          <Select
                            options={templateOptions}
                            value={selectedTemplate}
                            onChange={val => {
                              const _selectedTemplate = selectOptionFromValue(templateOptions, val);
                              setSelectedTemplate(_selectedTemplate);
                              setFieldValue("content", _selectedTemplate.content);
                            }}
                            label="From Template"
                          />
                          <CountrySelect
                            onChange={val => {
                              setFieldValue("country_code", val);
                              if (val === "KE") {
                                setFieldValue("sender_id", "Swift_INFO");
                              } else {
                                setFieldValue("sender_id", senderId);
                              }
                              setCampaignCountry(val);
                            }}
                            label="Country"
                            useCountryCode
                            required
                          />
                        </Flex>
                        {/* recipients tabs */}
                        <VisibilityWrapper width="100%" display="block" isVisible={values?.country_code?.length > 0}>
                          <PageTabs
                            onValueChange={() => {
                              setFieldValue("recipient", []);
                            }}
                            tabs={[
                              {
                                title: "Choose your Recipients",
                                content: (
                                  <ChooseRecipient
                                    onSelect={filterParams => {
                                      dispatch(
                                        getAllPhoneNumbersWithParams(filterParams, {
                                          onSuccess(data) {
                                            setFieldError("recipient", "Recipients list contains some errors");
                                            const parsedResult = data.map(datum => {
                                              if (datum.indexOf("+") === 0) {
                                                return datum;
                                              }
                                              if (datum.indexOf("0") === 0) {
                                                return datum.slice(1);
                                              }
                                              return `+${datum}`;
                                            });
                                            setFieldValue("recipient", parsedResult);
                                          },
                                        })
                                      );
                                    }}
                                  />
                                ),
                              },
                              {
                                title: "Import Contacts",
                                content: (
                                  <ImportContacts
                                    onImport={numbers => {
                                      console.log({ numbers });
                                      if (!numbers?.length) {
                                        setRecipientErrors([]);
                                      }
                                      setFieldValue("recipient", numbers);
                                    }}
                                    countryCode={values.country_code}
                                    onError={errors => {
                                      setRecipientErrors(errors);
                                      if (errors?.length > 0) setFieldError("recipient", errors[0]?.message);
                                    }}
                                  />
                                ),
                              },
                              {
                                title: "Contact Book",
                                content: (
                                  <ContactBook
                                    onSelect={contacts => {
                                      console.log({ contacts });
                                      setFieldValue("recipient", contacts.content);
                                    }}
                                  />
                                ),
                              },
                            ]}
                          />
                        </VisibilityWrapper>
                        {/* <VisibilityWrapper isVisible={values?.recipient?.length}> */}
                        <RecipientsList recipients={values.recipient} countryCode={values.country_code} />
                        {recipientErrors?.length ? (
                          <Text font="xsRegular" as="span">
                            Issue
                          </Text>
                        ) : null}
                        {/* </VisibilityWrapper> */}

                        <ErrorMessage
                          name="recipient"
                          render={val => (
                            <Text color="error800" font="xsRegular">
                              {val}
                            </Text>
                          )}
                        />
                        {recipientErrors?.map((singleError, errorIndex) => (
                          <Text font="xsRegular" color="error800" key={hyphenate(["err", singleError, errorIndex])}>
                            {singleError.message}
                          </Text>
                        ))}
                        <Divider />
                        <Box mt="20px">
                          <FormTextArea
                            name="content"
                            label="Write Message"
                            placeholder="Enter your message content here"
                          />
                        </Box>
                        <MessageCounter message={values.content} />
                      </Flex>
                    </Flex>
                    <Divider orientation="vertical" />
                    {/* right side */}
                    <Flex width="100%" flexDirection="column">
                      <Flex height="67px" alignItems="center">
                        <Text font="lgMedium">Preview</Text>
                      </Flex>
                      <MobilePreview title={values?.sender_id || " "}>
                        <MessageBubbles
                          text={
                            values?.content ||
                            "Enter your message content here and you can see a preview inside the phone on the right."
                          }
                        />
                      </MobilePreview>
                    </Flex>
                  </Flex>
                </Box>
              </Panel>
              <Flex width="100%" justifyContent="flex-end" gap="1rem">
                <Button
                  colorScheme="secondaryGray"
                  type="button"
                  onClick={() => {
                    handleDraftCampaign(values);
                  }}
                  disabled={isSubmitting || savingDraft}
                >
                  Save Draft
                </Button>
                <Button type="submit" disabled={isSubmitting || savingDraft}>
                  Send Now
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </PageWrapper>
  );
};

export default CreateCampaign;
