import React from "react";
import { Text, Flex, Box, Button, Icon } from "organic";
import { Panel } from "components/layout";
import { mapModuleIdsToLabels } from "./mapIdsToLabels";

const ManageModuleDetails = ({ setOnView, selectedModule }) => {
  return (
    <Panel
      headerElement={
        <Flex
          justifyContent="center"
          alignItems="center"
          outline={`1px solid #C3C3C3`}
          borderRadius="4px"
          width="90px"
          height="35px"
          gap="12px"
          onClick={() => setOnView(false)}
        >
          <Button colorScheme="">
            <Icon icon={"arrow-left"} size={20} color={"#A6A6A6"} />
            <Text font="smMedium" color="#646464" ml="8px">
              Back
            </Text>
          </Button>
        </Flex>
      }
    >
      <Flex flexDirection="column" gap="30px" width="90%" height="100%" ml="30px" mt="20px" mb="30px">
        <Box>
          <Text color="#A6A6A6" font="smBold">
            Project/Season
          </Text>
          <Text color="gray600" font="mdSemibold">
            {selectedModule.season.name}
          </Text>
        </Box>
        <Box>
          <Text color="#A6A6A6" font="smBold">
            Active Modules
          </Text>
          <Flex>
            {selectedModule.modules.map((module, index) => (
              <React.Fragment key={index}>
                <Text color="gray600" font="mdSemibold">
                  {mapModuleIdsToLabels(module)}
                </Text>
                {index < selectedModule.modules.length - 1 && selectedModule.modules.length > 1 && (
                  <Text color="gray600" font="mdSemibold" mr="2px">
                    ,
                  </Text>
                )}
              </React.Fragment>
            ))}
          </Flex>
        </Box>
        <Box>
          <Text color="#A6A6A6" font="smBold">
            Selected Region
          </Text>
          <Flex>
            {selectedModule.region.map((region, index) => (
              <React.Fragment key={index}>
                <Text color="gray600" font="mdSemibold">
                  {region.label}
                </Text>
                {index < selectedModule.region.length - 1 && selectedModule.region.length > 1 && (
                  <Text color="gray600" font="mdSemibold" mr="2px">
                    ,
                  </Text>
                )}
              </React.Fragment>
            ))}
          </Flex>
        </Box>
        <Box>
          <Text color="#A6A6A6" font="smBold">
            Except by Group
          </Text>
          {selectedModule.exceptGroups.length > 0 ? (
            <Flex>
              {selectedModule.exceptGroups.map((group, index) => (
                <React.Fragment key={index}>
                  <Text color="gray600" font="mdSemibold">
                    {group.agentName}
                  </Text>
                  {index < selectedModule.exceptGroups.length - 1 && selectedModule.exceptGroups.length > 1 && (
                    <Text color="gray600" font="mdSemibold" mr="2px">
                      ,
                    </Text>
                  )}
                </React.Fragment>
              ))}
            </Flex>
          ) : (
            "--"
          )}
        </Box>
      </Flex>
    </Panel>
  );
};

export default ManageModuleDetails;
