import React from "react";
import { CHART_HEADER_HEIGHT, ChartHeader } from "./chartHeader";
import { uniqueId } from "utils";
import { Flex } from "organic";

const ChartHeadersList = ({ headers = [] }) => {
  return (
    <Flex flexDirection="row" width="100%" height={CHART_HEADER_HEIGHT}>
      {headers.map(headerProps => {
        return <ChartHeader key={uniqueId()} {...headerProps} />;
      })}
    </Flex>
  );
};

export default ChartHeadersList;
