import numeral from "numeral";
import * as yup from "yup";

const MAX_LENGTH = 9999999999;
const MIN_LENGTH = -1;

const valueErrorMessage = fieldsName =>
  `${fieldsName} must be between ${MIN_LENGTH} and  ${numeral(MAX_LENGTH).format("0,0")}`;

export const createTradeValidationSchema = yup.object({
  initiated_by: yup.string().required("unable to get user info, kindly log out and log in again"),
  crop_type: yup.string().required("kindly select a crop type"),
  cost_price: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("cost price"))
    .max(MAX_LENGTH, valueErrorMessage("cost price"))
    .required("cost price cannot be empty"),
  selling_price: yup
    .number(MAX_LENGTH, "selling price is too high, kindly adjust it, should be less than 15 digit number")
    .required("kindly enter selling price"),
  anticipated_profit: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("anticipated profit"))
    .max(MAX_LENGTH, valueErrorMessage("anticipated profit"))
    .required("anticipated profit cannot be empty"),
  logistics_type: yup.array().required("kindly enter logistics type"),
  logistics_cost: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("logistics cost"))
    .max(MAX_LENGTH, valueErrorMessage("logistics cost"))
    .required("logistics cost cannopt be empty, add some logisitcs types"),
  target_quantity: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("target quantity"))
    .max(MAX_LENGTH, `${valueErrorMessage("Target Quantity")}`)
    .required("kindly enter target quantity"),
  assigned_agents: yup.array().required("kindly assign some agents to this trade"),
  price_to_pay: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("price to pay farmers"))
    .max(MAX_LENGTH, valueErrorMessage("Price to Pay Farmers "))
    .required("kindly enter price to pay"),
  commission_fee: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("commission fee"))
    .max(MAX_LENGTH, valueErrorMessage("commission fee"))
    .required("kindly enter commission fee"),
  // trade revenue
  trade_finance_fee: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("trade revenue"))
    .max(MAX_LENGTH, valueErrorMessage("trade revenue"))
    .required("trade finance fee cannopt be empty"),
  total_investment: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("total investment"))
    .max(MAX_LENGTH, valueErrorMessage("total investment"))
    .required("total investment cannot be empty"),
  profit_margin: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("profit margin"))
    .max(MAX_LENGTH, valueErrorMessage("Profit Margin%"))
    .required("profit margin cannot be empty"),
  status: yup.string().required("kindly enter status"),
  currency: yup.string().required("kindly select a currency"),
});

export const createActualTradeValidationSchema = yup.object({
  trade_id: yup.string().required("unable to get trade id"),
  initiated_by: yup.string().required("unable to get user info"),
  crop_type: yup.string().required("kindly select a crop type"),
  cost_price: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("cost price"))
    .max(MAX_LENGTH, valueErrorMessage("cost price"))
    .required("cost price cannot be empty"),
  selling_price: yup
    .number(MAX_LENGTH, "selling price is too high, kindly adjust it, should be less than 15 digit number")
    .required("kindly enter selling price"),
  anticipated_profit: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("anticipated profit"))
    .max(MAX_LENGTH, valueErrorMessage("anticipated profit"))
    .required("anticipated profit cannot be empty"),
  logistics_type: yup.array().required("kindly enter logistics type"),
  logistics_cost: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("logistics cost"))
    .max(MAX_LENGTH, valueErrorMessage("logistics cost"))
    .required("logistics cost cannopt be empty, add some logisitcs types"),
  target_quantity: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("target quantity"))
    .max(MAX_LENGTH, `${valueErrorMessage("Target Quantity")}`)
    .required("kindly enter target quantity"),
  price_to_pay: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("price to pay farmers"))
    .max(MAX_LENGTH, valueErrorMessage("Price to Pay Farmers "))
    .required("kindly enter price to pay"),
  commission_fee: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("commission fee"))
    .max(MAX_LENGTH, valueErrorMessage("commission fee"))
    .required("kindly enter commission fee"),
  // trade revenue
  total_trade_price: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("trade revenue"))
    .max(MAX_LENGTH, valueErrorMessage("trade revenue"))
    .required("trade finance fee cannot be empty"),
  total_investment: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("total investment"))
    .max(MAX_LENGTH, valueErrorMessage("total investment"))
    .required("total investment cannot be empty"),
  profit_margin: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("profit margin"))
    .max(MAX_LENGTH, valueErrorMessage("Profit Margin%"))
    .required("profit margin cannot be empty"),
  status: yup.string().required("kindly enter status"),
  currency: yup.string().required("kindly select a currency"),
  actual_quantity_sourced: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("actual quantity accepted"))
    .max(MAX_LENGTH, valueErrorMessage("actual quantity accepted"))
    .required("actual quantity accepted cannot be empty"),
  actual_quantity_rejected: yup
    .number()
    .min(MIN_LENGTH, valueErrorMessage("actual quantity rejected"))
    .max(MAX_LENGTH, valueErrorMessage("actual quantity rejected"))
    .required("actual quantity rejected cannot be empty"),
});

export const generateInvoiceValidationSchema = yup.object({
  initiated_by: yup.string().required("unable to get user info"),
  recipient_email: yup.string().required("kindly enter recipient email"),
  to: yup.string().required("kindly enter recipeint name"),
  date_issued: yup.string().required("kindly enter date issued"),
  date_due: yup.string().required("kindly enter date due"),
  invoice_item: yup.array().required("kindly enter invoice items"),
  trade_id: yup.string().required("kindly enter trade id"),
  crop_type: yup.string().required("kindly enter crop type"),
  total_amount: yup.number().required("kindly enter total amount"),
  total_quantity: yup.number().required("kindly enter total quantity"),
});
