/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageWrapper } from "components/layout";
import { Badge, Box, Button, Flex, badgeConstants } from "organic";
import { hyphenate } from "utils";
import { PageHeader } from "components/layout";
import PaymentsSummary from "./paymentsSummaryTiles";
import PaymentsContent from "./paymentsContent";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  farmerApprovedPaymentRequestStatus,
  farmerPaymentRequestStatus,
  getAllFarmerPaymentsNotPaginated,
  getAllFarmerPaymentsWithParams,
  getFarmerPaymentsData,
  getFarmerPaymentsDataNonPaginated,
  getFarmerPaymentsMeta,
  getLoadingFarmerPayment,
  getNonPaginatedDataParams,
  setNonPaginatedDataParams,
} from "features/v1/farmerPayment";
import usePaymentsTable from "./usePaymentsTable";
import { financePage } from "routes/dashboardRoutes";
import { Select } from "components/select";
import { getAllCrops, getCurSeasonCropsData } from "features/v1/cropsAndInput";
import { useDataFilter } from "hooks";

const SUMMARY = "tab1";
const APPROVED = "tab2";
const REJECTED = "tab3";
const PENDING = "tab4";

const PaymentsPage = ({ page }) => {
  const { filterParams, isValidProjectId, isValidRegion, isValidDateRange } = useDataFilter();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || "tab1";
  const navigate = useNavigate();
  const [selectedCropType, setSelectedCropType] = useState(null);

  let farmerPaymentsDataNonPaginated = useSelector(getFarmerPaymentsDataNonPaginated);
  const nonPaginatedDataParams = useSelector(getNonPaginatedDataParams);
  const farmerPaymentsData = useSelector(getFarmerPaymentsData);
  const farmerPaymentsMeta = useSelector(getFarmerPaymentsMeta);
  const farmerPaymentLoading = useSelector(getLoadingFarmerPayment);
  const cropsData = useSelector(getCurSeasonCropsData);

  // Filter records by date
  const fromDate = new Date(filterParams.dateRange[0]);
  const toDate = new Date(filterParams.dateRange[1]);

  farmerPaymentsDataNonPaginated = farmerPaymentsDataNonPaginated.filter(p => {
    const createdAt = new Date(p.created_at);
    return createdAt >= fromDate && createdAt <= toDate;
  });

  const cropTypes = cropsData.map(c => ({
    label: c.name,
    value: c._id,
  }));

  const isPaymentsPage = page === financePage.PAYMENT;

  const { tableProps, selectedRow: selectedPayment } = usePaymentsTable(
    farmerPaymentsData,
    farmerPaymentsMeta,
    farmerPaymentLoading,
    page,
    activeTab
  );

  useEffect(() => {
    if (isValidProjectId && isValidDateRange && isValidRegion) {
      dispatch(getAllFarmerPaymentsNotPaginated(nonPaginatedDataParams));
    }
  }, [dispatch, nonPaginatedDataParams, activeTab, isValidProjectId, isValidRegion, isValidDateRange]);

  useEffect(() => {
    dispatch(getAllCrops());
  }, []);

  const tabs = [
    {
      tabId: SUMMARY,
      title: (
        <span>
          Summary&nbsp;
          <CountBadge
            value={
              isPaymentsPage
                ? farmerPaymentsDataNonPaginated?.length
                : farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED).length
            }
          />
        </span>
      ),
    },
    {
      tabId: APPROVED,
      title: (
        <span>
          {`${isPaymentsPage ? "Approved" : "Payout"}`}{" "}
          <CountBadge
            value={
              isPaymentsPage
                ? farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.APPROVED).length
                : farmerPaymentsDataNonPaginated?.filter(
                    p =>
                      p.status === farmerPaymentRequestStatus.APPROVED &&
                      p.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED
                  ).length
            }
          />
        </span>
      ),
    },
    {
      tabId: REJECTED,
      title: (
        <span>
          {`${isPaymentsPage ? "Rejected" : "Declined"}`}{" "}
          <CountBadge
            value={
              isPaymentsPage
                ? farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.REJECTED).length
                : farmerPaymentsDataNonPaginated?.filter(
                    p =>
                      p.status === farmerPaymentRequestStatus.APPROVED &&
                      p.payment_status === farmerApprovedPaymentRequestStatus.DECLINED
                  ).length
            }
          />
        </span>
      ),
    },
    {
      tabId: PENDING,
      title: (
        <span>
          Pending{" "}
          <CountBadge
            value={
              isPaymentsPage
                ? farmerPaymentsDataNonPaginated?.filter(p => p.status === farmerPaymentRequestStatus.PENDING).length
                : farmerPaymentsDataNonPaginated?.filter(
                    p =>
                      p.status === farmerPaymentRequestStatus.APPROVED &&
                      p.payment_status === farmerApprovedPaymentRequestStatus.PENDING
                  ).length
            }
          />
        </span>
      ),
    },
  ];

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" align-Items="center">
        <PageHeader
          title={`Finance > ${isPaymentsPage ? "Manager" : "Payments"}`}
          subtitle="Your finance summary and activity."
        />
      </Flex>
      <nav className="page-tabs__list">
        {tabs?.map((item, index) => (
          <div
            onClick={() => {
              navigate({
                pathname: "",
                search: `?tab=${item.tabId}`,
              });
            }}
            key={hyphenate(["pagetab-nav", index, item?.tabId])}
            className={`page-tabs__trigger ${item.tabId === activeTab ? "active" : ""}`}
          >
            {item.title}
          </div>
        ))}
      </nav>
      <Flex mt="5" mb="2rem" alignItems="center">
        <Box width={200}>
          <Select
            options={cropTypes}
            onChange={selectedCropType => {
              setSelectedCropType(cropTypes.find(option => option.value === selectedCropType));
              dispatch(setNonPaginatedDataParams({ crop_type: selectedCropType }));
            }}
            value={selectedCropType}
            placeholder="Select crop type"
          />
        </Box>
        <Button
          onClick={() => {
            setSelectedCropType(null);
            dispatch(setNonPaginatedDataParams({}));
          }}
          ml="5"
          isDisabled={selectedCropType === null}
        >
          Reset filter
        </Button>
      </Flex>

      {activeTab === "tab1" && <PaymentsSummary page={page} />}
      <PaymentsContent
        tableProps={tableProps}
        selectedPayment={selectedPayment}
        selectedCropType={selectedCropType}
        activeTab={activeTab}
        page={page}
        key={`${activeTab}${page}${selectedCropType?.value}`}
      />
    </PageWrapper>
  );
};

export default PaymentsPage;

const CountBadge = ({ value }) => <Badge colorScheme={badgeConstants.colorSchemes.GRAY}>{value}</Badge>;
