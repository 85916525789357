import { FormInput } from "components/inputs";
import React from "react";
import { MAX_NUM_VALUE } from "constants/sharedConstants";

export const CommissionFeeInput = () => {
  return (
    <FormInput
      name="commission_fee"
      type="number"
      min={0}
      max={MAX_NUM_VALUE}
      label="Commission Fee"
      placeholder="Commission Fee"
      required
    />
  );
};
