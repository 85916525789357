import { FormInput } from "components/inputs";
import { selectConstants } from "components/select";
import CurrencySelect from "components/select/currencySelect";
import { MAX_NUM_VALUE } from "constants/sharedConstants";
import { useFormikContext } from "formik";
import { useDidMountEffect } from "hooks";
import numeral from "numeral";
import React from "react";
import { createSelectOption } from "utils/v1/selectUtils";

const calcTradeTotalInvestment = ({ priceToPayFarmers, targetQuantity, logisticsTotal, commissionFee }) => {
  const totalPriceToPay = numeral(priceToPayFarmers || 0)
    .multiply(targetQuantity || 0)
    .value();
  console.log("totalPriceToPay", totalPriceToPay);
  return numeral(totalPriceToPay || 0)
    .add(logisticsTotal || 0)
    .add(commissionFee || 0)
    .value()
    .toFixed(2);
};

export const ActualTotalInvestmentField = () => {
  const { setFieldValue, values } = useFormikContext();

  useDidMountEffect(() => {
    setFieldValue(
      "total_investment",
      calcTradeTotalInvestment({
        priceToPayFarmers: values.price_to_pay,
        targetQuantity: values.actual_quantity_sourced,
        logisticsTotal: values.logistics_cost,
        commissionFee: values.commission_fee,
      })
    );
  }, [values.price_to_pay, values.actual_quantity_sourced, values.logistics_cost, values.commission_fee]);

  return (
    <FormInput
      label="Total Investment "
      name="total_investment"
      isDisabled
      readOnly
      type="number"
      hint={"Price to pay farmers * Actual Quantity Accepted + Logistics Cost + Commission Fee"}
      max={MAX_NUM_VALUE}
      min={0}
      required
      rightAddon={
        <CurrencySelect
          onChange={currencyValue => {
            setFieldValue("currency", currencyValue);
          }}
          value={createSelectOption(values.currency, values.currency)}
          colorScheme={selectConstants.colorSchemes.UNSTYLED}
          placeholder="Currency"
        />
      }
    />
  );
};
