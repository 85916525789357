export const logisticsTypeSelectOptions = [
  {
    label: "Aggregation Cost",
    value: "Aggregation Cost",
  },
  {
    label: "Weighing Cost",
    value: "Weighing Cost",
  },
  {
    label: "Loading Cost",
    value: "Loading Cost",
  },
  {
    label: "Off-loading Cost",
    value: "Off-loading Cost",
  },
  {
    label: "Taxes/CESS",
    value: "Taxes/CESS",
  },
  {
    label: "Packaging",
    value: "Packaging",
  },
  {
    label: "Harvesting Cost",
    value: "Harvesting Cost",
  },
  {
    label: "Transport",
    value: "Transport",
  },
  {
    label: "Border Clearance",
    value: "Border Clearance",
  },
  {
    label: "Contingency Fee",
    value: "Contingency Fee",
  },
  {
    label: "Not Applicable",
    value: "Not Applicable",
  },
];
