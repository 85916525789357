import React from "react";
import { Navigate } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Loader from "components/v1/Loader/RedirectLoader";

export default function Redirect({ pathTo }) {
  return (
    <Container className="p-5 my-5 text-center">
      <Loader />

      <Row className="align-items-center">
        <Col className="mb-4">
          <span className="display-4 text-muted">Redirecting, please wait...</span>
        </Col>
      </Row>
      <Navigate to={{ pathname: pathTo }} />
    </Container>
  );
}
