import styled, { css } from "styled-components";
import shouldForwardProp from "@styled-system/should-forward-prop";
import { space } from "styled-system";
import StyledButton from "./styledButton";

const StyledButtonGroup = styled.div.withConfig({
  shouldForwardProp,
})`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  ${props => css`
    border: 1px solid ${props.theme.colors.gray300};
  `}
  ${props =>
    props.rounded === true
      ? css`
          border-radius: 8px;
        `
      : css``}
      
      & ${StyledButton} {
    display: flex;
    padding: 0px 1rem;
    height: 40px;
    ${props => css`
      border-left: 1px solid ${props.theme.colors.gray300};

      &:hover {
        background-color: ${props.theme.colors.gray50};
      }
    `}
    :first-child, :last-child {
      border: none;
    }
    ${props => css`
      :last-child {
        border-left: 1px solid ${props.theme.colors.gray300};
      }
    `}

    ${props =>
      props.isActive === true
        ? css`
            background-color: ${props.theme.colors.gray50};
          `
        : css``}
  }
  ${space}
`;

export default StyledButtonGroup;
