/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Box, Button, Flex } from "organic";
import "./mapIdsToLabels";

import styled from "styled-components";
import RegionSelect2 from "components/select/regionSelect2";
import ModuleSelect from "components/select/moduleSelect";
import ExceptGroup from "components/select/exceptGroup";
import { moduleModuleValidationSchema } from "validators/manageModuleValidationSchema";
import ProjectSelect2 from "components/select/projectSelect2";
import { handleFormSubmission } from "services/formSubmissionService";
import { useEditModule } from "hooks/app/manageModule/useEditModule";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getRegionOptions } from "features/v1/regions";

const WideButton = styled.button`
  width: 190px;
`;
export const EditModule = ({ selected, modal }) => {
  const projectReference = useRef();
  const regionOptions = useSelector(getRegionOptions);
  const [selectedSeason, setSelectedSeason] = useState("");
  const { mutateAsync } = useEditModule();

  const createModuleInitialValues = {
    project: selected.season._id,
    region: selected.region.map(region => region.value),
    preferred_modules: selected.modules.map(module => module),
    exceptGroups: selected.exceptGroups.map(group => JSON.stringify(group)),
  };

  const handleModuleEdit = async values => {
    const payload = {
      id: selected?._id,
      season: values.project,
      region: values.region.map(r => {
        const region = regionOptions.find(region => region.value === r);
        return region._id;
      }),
      modules: values.preferred_modules,
      exceptGroups: values.exceptGroups.map(group => JSON.parse(group)),
    };
    await handleFormSubmission({
      submitFn: mutateAsync,
      values: {
        data: payload,
      },
      config: {},
      errorMsg: "Failed to edit module",
      successMsg: "",
      onSuccess() {
        toast.success("Module Updated");
        modal.hideModal();
      },
      onError(error) {
        console.log({ error });
      },
    });
  };

  return (
    <Formik
      initialValues={createModuleInitialValues}
      validationSchema={moduleModuleValidationSchema}
      onSubmit={handleModuleEdit}
      enableReinitialize
    >
      {({ values, isSubmitting, setFieldValue }) => {
        useEffect(() => {
          setSelectedSeason(values.project);
          if (projectReference.current !== undefined && projectReference.current !== values.project) {
            setFieldValue("exceptGroups", []);
          }
          projectReference.current = values.project;
        }, [values.project]);
        return (
          <>
            <Form>
              <Flex flexDirection="column" gap="1rem">
                <Box>
                  <ProjectSelect2 name="project" label="Project" placeholder="Select Project" />
                </Box>
                <Box>
                  <RegionSelect2 name="region" label="Select Regions" placeholder="Select Regions" isMulti />
                </Box>
                <Box>
                  <ModuleSelect name="preferred_modules" label="Activate preferred modules" isMulti />
                </Box>
                <Box>
                  <ExceptGroup name="exceptGroups" selectedSeason={selectedSeason} isMulti />
                </Box>
              </Flex>

              <Flex justifyContent="space-between" mt="3rem">
                <Button
                  type="button"
                  display="block"
                  as={WideButton}
                  colorScheme="secondaryGray"
                  onClick={modal.hideModal}
                  isDisabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button type="submit" as={WideButton} display="block" isDisabled={isSubmitting}>
                  Save Changes
                </Button>
              </Flex>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
