/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Box, Button, Flex } from "organic";
import { Form, Formik } from "formik";
import { useDisclosure } from "hooks";
import { Modal, ModalContent } from "components/modal";
import SuccessDialog from "components/confirmDialog/successDialog";
import { moduleModuleValidationSchema } from "validators/manageModuleValidationSchema";
import { handleFormSubmission } from "services/formSubmissionService";
import { useCreateModule } from "hooks/app/manageModule/useCreateModule";
import ExceptGroup from "components/select/exceptGroup";
import ModuleSelect from "components/select/moduleSelect";
import ProjectSelect2 from "components/select/projectSelect2";
import RegionSelect2 from "components/select/regionSelect2";
import { useSelector } from "react-redux";
import { getRegionOptions } from "features/v1/regions";

const CreateModule = ({ modal }) => {
  const regionOptions = useSelector(getRegionOptions);
  const confirmCreateModal = useDisclosure();
  const { mutateAsync } = useCreateModule();
  const [selectedSeason, setSelectedSeason] = useState("");

  const createModuleInitialValues = {
    project: "",
    region: [],
    preferred_modules: [],
    exceptGroups: [],
  };

  const onSubmit = async values => {
    const payload = {
      season: values.project,
      region: values.region.map(r => {
        const region = regionOptions.find(region => region.value === r);
        return region._id;
      }),
      modules: values.preferred_modules,
      exceptGroups: values.exceptGroups.map(group => JSON.parse(group)),
    };

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: payload,
      config: {},
      successMsg: "",
      errorProps: {
        message: "Error creating module",
        msg400: "Error creating module",
        msg401: "Unauthorized",
      },
      onSuccess() {
        confirmCreateModal.onOpen();
      },
      onError(error) {
        console.log({ error });
      },
    });
  };

  return (
    <>
      <Formik
        initialValues={createModuleInitialValues}
        validationSchema={moduleModuleValidationSchema}
        onSubmit={onSubmit}
        validateOnMount={false}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          useEffect(() => {
            setSelectedSeason(values.project);
            setFieldValue("exceptGroups", []);
          }, [values.project]);
          return (
            <>
              <Form>
                <Flex flexDirection="column" gap="1rem">
                  <Box>
                    <ProjectSelect2 name="project" label="Project *" placeholder="Select Project" />
                  </Box>

                  <Box>
                    <RegionSelect2 name="region" label="Choose by Region  *" placeholder="Select Regions" isMulti />
                  </Box>

                  <Box>
                    <ModuleSelect
                      name="preferred_modules"
                      label="Activate Preferred Modules *"
                      placeholder="Select Preferrd Module"
                      isMulti
                    />
                  </Box>
                  <Box>
                    <ExceptGroup
                      name="exceptGroups"
                      placeholder="Select Groups"
                      selectedSeason={selectedSeason}
                      isMulti
                    />
                  </Box>
                </Flex>
                <Button mt="2rem" display="block" type="submit" isDisabled={isSubmitting}>
                  Create Module
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
      {/* Modal for confirmation */}
      <Modal {...confirmCreateModal.modalProps}>
        <ModalContent variant="center" width={400} doNotCloseOnClickOutOrEscKey hidecloseicon>
          <SuccessDialog
            heading="Well done!!! "
            subHeading="<p>You have successfully created a<br />Module.</p>"
            confirmationText="Continue to Dashboard"
            onConfirm={() => {
              modal.hideModal();
              confirmCreateModal.onClose();
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateModule;
