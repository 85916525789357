import { useMutation } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import { appQueryClient } from "index";
import { AOSClientError } from "services/shared/AOSErrorService";
import Onboarding_API from "services/onboardingApiService";

export function useUploadImage(onUploadProgress, handleSuccess) {
  return useMutation({
    mutationFn: formData => {
      return Onboarding_API.uploadImage(formData, onUploadProgress);
    },
    onSuccess: ({
      data: {
        data: { avatar },
      },
    }) => {
      handleSuccess(avatar);
      appQueryClient.invalidateQueries(rqKeys.onboarding.uploadImage);
      return avatar;
    },
    onError: error => {
      // handle error
      const bug = new AOSClientError("Something went wrong while uploading image");
      bug.report(error);
    },
  });
}
