import { FormInput, FormTextArea } from "components/inputs";
import { updateTemplate } from "features/v1/bulkSms";
import { Form, Formik } from "formik";
import { Box, Button, Flex, buttonConstants } from "organic";
import React from "react";
import { useDispatch } from "react-redux";
import MessageCounter from "./messageCounter";
import { StatusSelect } from "components/select";

const initiateTemplateValues = template => {
  return {
    template_name: template?.template_name || "",
    content: template?.content || "",
    status: template?.status || "",
  };
};

const EditTemplate = ({ modal, selected }) => {
  const dispatch = useDispatch();

  const initialValues = initiateTemplateValues(selected);

  const handleEditTemplate = (values, formOptions) => {
    dispatch(updateTemplate(values, { formOptions, modal }));
  };

  return (
    <Box>
      <Formik initialValues={initialValues} onSubmit={handleEditTemplate}>
        {({ values, setFieldValue }) => (
          <Form>
            <Flex flexDirection="column" gap="20px">
              <Box>
                <FormInput name="template_name" label="Template Name" placeholder="Enter Template Name" />
              </Box>
              <Box>
                <FormTextArea name="content" label="Write Message" placeholder="Enter your message content here" />
              </Box>
              <Box>
                <StatusSelect
                  name="status"
                  label="Status"
                  onChange={val => {
                    setFieldValue("status", val);
                  }}
                  defaultValue={values.status}
                />
              </Box>
              <MessageCounter message={values.content} />
              <Box mt="44px">
                <Flex gap="20px">
                  <Button
                    display="block"
                    colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}
                    onClick={() => {
                      modal.hideModal();
                    }}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" display="block">
                    Save Template
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditTemplate;
