import styled, { css } from "styled-components";
import shouldForwardProp from "@styled-system/should-forward-prop";
import _typography from "organic/theme/mixins/_typography";
import colors from "organic/theme/colors";
import { space } from "styled-system";

const StyledBadge = styled.span.withConfig({
  shouldForwardProp,
})`
  text-align: center;
  border-radius: 1rem;
  ${props => props.sm && _typography.xsMedium}
  ${props => props.md && _typography.smMedium}
    ${props => props.lg && _typography.smMedium}

    ${props =>
    props.sm === true
      ? css`
          padding: 2px 8px;
        `
      : css``}
    ${props =>
    props.md === true
      ? css`
          padding: 2px 10px;
        `
      : css``}
    ${props =>
    props.lg === true
      ? css`
          padding: 4px 12px;
        `
      : css``}

    ${props =>
    props.blue === true
      ? css`
          background-color: ${colors.blue50};
          color: ${colors.blue700};
        `
      : css``}
    ${props =>
    props.blueGray === true
      ? css`
          background-color: ${colors.blueGray50};
          color: ${colors.blueGray700};
        `
      : css``}
    ${props =>
    props.blueLight === true
      ? css`
          background-color: ${colors.blueLight50};
          color: ${colors.blueLight700};
        `
      : css``}
    ${props =>
    props.error === true
      ? css`
          background-color: ${colors.error50};
          color: ${colors.error700};
        `
      : css``}
    ${props =>
    props.gray === true
      ? css`
          background-color: ${colors.gray50};
          color: ${colors.gray700};
        `
      : css``}
    ${props =>
    props.indigo === true
      ? css`
          background-color: ${colors.indigo50};
          color: ${colors.indigo700};
        `
      : css``}
    ${props =>
    props.orange === true
      ? css`
          background-color: ${colors.orange50};
          color: ${colors.orange700};
        `
      : css``}
    ${props =>
    props.pink === true
      ? css`
          background-color: ${colors.pink50};
          color: ${colors.pink700};
        `
      : css``}
    ${props =>
    props.primary === true
      ? css`
          background-color: ${colors.primary50};
          color: ${colors.primary700};
        `
      : css``}
    ${props =>
    props.purple === true
      ? css`
          background-color: ${colors.purple50};
          color: ${colors.purple700};
        `
      : css``}
    ${props =>
    props.rose === true
      ? css`
          background-color: ${colors.rose50};
          color: ${colors.rose700};
        `
      : css``}
    ${props =>
    props.success === true
      ? css`
          background-color: ${colors.success50};
          color: ${colors.success700};
        `
      : css``}
    ${props =>
    props.warning === true
      ? css`
          background-color: ${colors.warning50};
          color: ${colors.warning700};
        `
      : css``}
      ${space}
`;
export default StyledBadge;
