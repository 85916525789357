import React from "react";
import { Table } from "components/table";
import { Badge, Box, Button, Flex, Icon, Text, TextBox } from "organic";
import { useTable } from "hooks";
import { useGetSingleInvoice, useGetSingleTrade } from "hooks/app/marketplace";
import numeral from "numeral";
import { PageHeader, PageWrapper, Panel } from "components/layout";
import { useNavigate, useParams } from "react-router";
import { appGet } from "utils";

const ViewInvoice = () => {
  //   const page = 1;
  //   const nPerPage = 20;
  const { tradeId } = useParams();

  const { data } = useGetSingleTrade({ params: { id: tradeId } });
  console.log({ data });

  const trade_id = appGet(data, "trade_id", "");

  const { data: invoiceData } = useGetSingleInvoice({ params: { tradeId: trade_id } });
  console.log({ invoiceData });
  const { tableProps } = useTable({
    data: appGet(invoiceData, "data", []),
    columns: [
      {
        Header: "Invoice ID",
        accessor: "invoice_id",
      },
      {
        Header: "Recipient Email",
        accessor: "recipient_email",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Bill To",
        accessor: "to",
      },
      {
        Header: "Date of Issue",
        accessor: data => {
          const dateIssued = appGet(data, "date_issued", "");
          if (dateIssued) {
            const parsedDate = new Date(dateIssued);
            return parsedDate.toLocaleDateString();
          }
          return "";
        },
      },
      {
        Header: "Issued On",
        accessor: data => {
          const dateDue = appGet(data, "date_due", "");
          if (dateDue) {
            const parsedDate = new Date(dateDue);
            return parsedDate.toLocaleDateString();
          }
          return "";
        },
      },
      {
        Header: " Quantity (KG)",
        accessor: trade => numeral(trade.total_quantity).format("0,0.00"),
      },
      {
        Header: "Amount",
        accessor: trade => numeral(trade.total_amount).format("0,0.00"),
      },
    ],
    useServerPagination: true,
    serverPaginationMeta: invoiceData?.meta || {},
    title: "All invoice history",
    noEditAction: true,
    noViewAction: true,
    noAction: false,
  });
  const navigate = useNavigate();

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > Trade" subtitle="Your trade summary and activity." />
      </Flex>
      <Panel
        headerElement={
          <ViewInvoiceHeader
            onClickAction={() => {
              navigate(-1);
            }}
            id={appGet(data, "trade_id", "--")}
          />
        }
      >
        <Flex flexDirection="column" px="16px">
          <Flex gap="1rem" px="16px" pt="12px" pb="1rem">
            <Box width="50%">
              <TextBox label="Crop Type" value={appGet(data, "crop_type", "")} />
            </Box>
            <Box width="50%">
              <TextBox label="Total Cost Price" value={appGet(data, "cost_price", "")} />
            </Box>
          </Flex>
          <Flex gap="1rem" px="16px" pt="12px" pb="1rem">
            <Box width="50%">
              <TextBox label="Target Quantity" value={appGet(data, "target_quantity", "")} />
            </Box>
            <Box width="50%">
              <TextBox label="Sourced Quantity" value={appGet(data, "target_achieved", "")} />
            </Box>
          </Flex>

          <Table {...tableProps} />
        </Flex>
      </Panel>
    </PageWrapper>
  );
};

export default ViewInvoice;

const ViewInvoiceHeader = ({ onClickAction, id }) => {
  return (
    <Flex alignItems="center" gap="1rem">
      <Button variant="unstyled" onClick={onClickAction}>
        <Icon icon="arrow-left" color="gray700" size={20} />
      </Button>
      <Text as="span" font="lgMedium" color="gray900">
        Back to invoice <Badge colorScheme="success">{id}</Badge>
      </Text>
    </Flex>
  );
};
