import React from "react";
import Select2 from "./select2";
import { useSelector } from "react-redux";
import { getLoadingSeasons, getSeasonOptions } from "features/v1/season";

const ProjectSelect2 = ({ label, placeholder, ...props }) => {
  const loadingSeasons = useSelector(getLoadingSeasons);
  const seasonOptions = useSelector(getSeasonOptions);

  return (
    <Select2 label={label} placeholder={placeholder} isLoading={loadingSeasons} options={seasonOptions} {...props} />
  );
};

export default ProjectSelect2;
