import React, { useState } from "react";
import { useSelector } from "react-redux";
import // getAllInputDistribution,
//  getInputDistMeta,
// getInputDistributionData,
// getLoadingData,
// searchInputDistribution,
"features/v1/inputDistribution";
import adminApi from "api/admin";
import { useDisclosure, useTable } from "hooks";
import { PageWrapper, PageHeader } from "components/layout";
import { selectLabelFromValue } from "utils/v1/selectUtils";
import { getRegionOptions } from "features/v1/regions";
import { Table } from "components/table";
import { Flex } from "organic";
import { ExportButton } from "components/export";
import { formatDate } from "utils";
import { Modal, ModalContent } from "components/modal";
import ViewInputDistributionDetails from "./farm/inputDistribution/viewInputDistributionDetails";
import { useGetInputDistribution } from "hooks/app/inputDistribution/get";

const InputDistributionPage = () => {
  const [fetchParams, setFetchParams] = useState({});
  let regionOption = useSelector(getRegionOptions);
  const IDModal = useDisclosure();
  const { data: inputDistributionData } = useGetInputDistribution({
    params: fetchParams,
  });
  const { tableProps, selectedRow: selectedInputDist } = useTable({
    data: inputDistributionData,
    columns: [
      {
        Header: "Group ID",
        accessor: "groupID",
      },
      {
        id: "IDDate",
        Header: "Date",
        accessor: dist => formatDate(dist?.onboarded_at),
      },
      {
        Header: "Crop",
        accessor: "crop_type",
      },
      {
        Header: "Total Farm Size(ha)",
        accessor: "farm_size",
      },
      {
        Header: "Warehouse",
        accessor: "warehouse.store.name",
      },
      {
        id: "IDRegion",
        Header: "Region ",
        accessor: dist => selectLabelFromValue(regionOption, dist?.region) || "N/A",
      },
      {
        Header: "Distributed By",
        accessor: "distributedBy",
      },
      {
        id: "IDStatus",
        Header: "Status",
        accessor: dist => {
          return dist?.hasFarmerCollectedInput ? "Collected" : "Not Collected";
        },
      },
    ],
    title: "Input Distribution",
    noEditAction: true,
    onFilter: params => {
      setFetchParams({ ...params });
    },
  });

  const quickInputDistributionExport = async () => {
    return (await adminApi.get("inputDistribution/download", { params: tableProps.filterParams })).data;
  };

  const getFarmersMetaKeys = async () => {
    return await adminApi.get("inputDistribution/download/getMetaKeys");
  };

  const advanceInputDistributionExport = async ({ filterParams, columnWhitelist }) => {
    let params = { columnWhitelist, ...filterParams };
    let result = await adminApi.get("inputDistribution/download/advance", {
      params,
    });
    return result;
  };

  return (
    <PageWrapper>
      <Flex justifyContent="space-between" align-Items="center">
        <PageHeader title="Input Distribution" />
        <ExportButton
          quickExportConfig={{
            apiCall: quickInputDistributionExport,
          }}
          advancedExportConfig={{
            apiCall: advanceInputDistributionExport,
            getKeys: getFarmersMetaKeys,
          }}
        />
      </Flex>
      <Table
        {...tableProps}
        onViewAction={__options => {
          IDModal.onOpen();
        }}
      />

      <Modal
        open={IDModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            IDModal.onClose();
          }
        }}
      >
        <ModalContent title="View Input Distributed Details">
          <ViewInputDistributionDetails selected={selectedInputDist} modal={IDModal.modal} />
        </ModalContent>
      </Modal>
    </PageWrapper>
  );
};

export default InputDistributionPage;
