import { Flex } from "organic";
import React from "react";

const VisibilityWrapper = ({ placeholder, isVisible, display, children, ...restProps }) => {
  return (
    <>
      <Flex {...restProps} display={isVisible !== true ? display : "none"}>
        {placeholder}
      </Flex>
      <Flex {...restProps} display={isVisible === true ? display : "none"}>
        {children}
      </Flex>
    </>
  );
};
VisibilityWrapper.defaultProps = {
  display: "flex",
};

export { VisibilityWrapper };
