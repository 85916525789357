import { getAllWarehouses, getWarehouseData } from "features/v1/warehouse";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const WarehouseSummaryPage = () => {
  const warehouseData = useSelector(getWarehouseData);
  const dispatch = useDispatch();

  const data = [
    { title: "Total No of Warehouse", value: warehouseData?.length },
    { title: "Total Inbound", value: null },
    { title: "Total Outbound", value: null },
    { title: "Total Input Distributed", value: null },
    { title: "Total Harvest Collected", value: null },
    { title: "Rice", value: null },
    { title: "Maize", value: null },
    { title: "Urea", value: null },
    { title: "NPK", value: null },
    { title: "DAP", value: null },
    { title: "Thread & Needle", value: null },
  ];

  useEffect(() => {
    dispatch(getAllWarehouses());
  }, []);

  return (
    <div className="warehouse-summary">
      <div className="warehouse-summary__grid">
        {data.map((d, index) => {
          return (
            <div className="warehouse-summary__gridItem" key={index}>
              <div className="warehouse-summary__gridItem__Title">{d.title}</div>
              <div className="warehouse-summary__gridItem__Value">{d?.value?.toLocaleString("en-US") || "--"}</div>
            </div>
          );
        })}
        <div className="warehouse-summary__gridItem">
          <div className="warehouse-summary__gridItem__Title">More soon</div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseSummaryPage;
