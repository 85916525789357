// endpoints to be integrated

import bulkSms from "api/bulkSms";
import farmerSeason from "api/farmerseason";
import { mockSmsCampaignMessagesReportList, mockSmsCampaignOverview } from "mockData/messaging";
import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";
import { getSubmissionConfigOptions } from "utils/v1/apiUtils";
import { sanitizeMongoDoc } from "utils/v1/sanitize";

/** 
Bulk Sms Model

 */
const SET_ALL_CAMPAIGN = "SET_ALL_CAMPAIGN";
const LOADING_CAMPAIGN = "LOADING_CAMPAIGN";
const SET_CAMPAIGN_OVERVIEW = "SET_ALL_CAMPAIGN";
const LOADING_CAMPAIGN_OVERVIEW = "LOADING_CAMPAIGN_OVERVIEW";
const SET_ALL_CONTACT = "SET_ALL_CONTACT";
const LOADING_CONTACT = "LOADING_CONTACT";
const SET_ALL_DRAFT = "SET_ALL_DRAFT";
const LOADING_DRAFT = "LOADING_DRAFT";
const SET_ALL_TEMPLATES = "SET_ALL_TEMPLATES";
const LOADING_TEMPLATES = "LOADING_TEMPLATES";
const SET_ALL_CAMPAIGN_REPORT = "SET_ALL_CAMPAIGN_REPORT";
const LOADING_CAMPAIGN_REPORT = "LOADING_CAMPAIGN_REPORT";
const SET_ALL_PHONE_NUMBERS = "SET_ALL_PHONE_NUMBERS";
const LOADING_PHONE_NUMBERS = "LOADING_PHONE_NUMBERS";

/** Fetch all Campaign */
export const getAllCampaigns = () => async dispatch => {
  try {
    dispatch({
      type: LOADING_CAMPAIGN,
      payload: true,
    });
    const response = await bulkSms.get("getAll", { ignoreOrgId: true });
    const campaigns = response.data.data.filter(item => item.send_status === "publish");
    const drafts = response.data.data.filter(item => item.send_status === "unpublish");

    dispatch({
      type: SET_ALL_CAMPAIGN,
      payload: campaigns,
      allDraftMeta: response.data?.meta || {},
    });
    dispatch({
      type: SET_ALL_DRAFT,
      payload: drafts,
      allDraftMeta: response.data?.meta || {},
    });

    dispatch({
      type: LOADING_CAMPAIGN,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_CAMPAIGN,
      payload: false,
    });
    handleActionError(error, "GetAllCampaignsError", "Something Went Wrong Fetching Campaign, kindly try again");
  }
};
export const createCampaign =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions } = getSubmissionConfigOptions(config);
    try {
      // if (hasFormOptions) {
      //   config.formOptions.setSubmitting(true);
      // }
      const params = {
        sendStatus: "publish",
      };
      dispatch({
        type: LOADING_CAMPAIGN,
        payload: true,
      });
      await bulkSms.post("create", values, { params, ignoreOrgId: true });
      dispatch({
        type: LOADING_CAMPAIGN,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      toast.success("campaign created successfuly, Sending messages shortly...");
      return dispatch(getAllCampaigns());
    } catch (error) {
      dispatch({
        type: LOADING_CAMPAIGN,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      handleActionError(error, "Create Campaign Error", "Couldn't Create Campaign");
    }
  };
export const sendCampaign =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions } = getSubmissionConfigOptions(config);
    try {
      // if (hasFormOptions) {
      //   config.formOptions.setSubmitting(true);
      // }
      dispatch({
        type: LOADING_CAMPAIGN,
        payload: true,
      });
      await bulkSms.post("", values, { ignoreOrgId: true });
      dispatch({
        type: LOADING_CAMPAIGN,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      return dispatch(getAllCampaigns());
    } catch (error) {
      dispatch({
        type: LOADING_CAMPAIGN,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      handleActionError(error, "Send Campaign Error", "Couldn't Send Campaign");
    }
  };

export const createManyCampaigns = () => {};

// overview
export const getCampaignOverview = () => async dispatch => {
  try {
    dispatch({
      type: LOADING_CAMPAIGN_OVERVIEW,
      payload: true,
    });
    // const response = await bulkSms.get("", { ignoreOrgId: true });
    // if (response.status === 200) {
    //   dispatch({
    //     type: SET_ALL_CAMPAIGN,
    //     payload: response.data.data,
    //     allCampaignMeta: response.data?.meta || {},
    //   });
    // }
    const response = mockSmsCampaignOverview();
    dispatch({
      type: SET_CAMPAIGN_OVERVIEW,
      payload: response,
      allCampaignMeta: {},
    });

    dispatch({
      type: LOADING_CAMPAIGN_OVERVIEW,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_CAMPAIGN_OVERVIEW,
      payload: false,
    });
    handleActionError(
      error,
      "GetCampaignOverviewError",
      "Something Went Wrong Fetching Campaign Stats, kindly try again"
    );
  }
};

// Contacts

export const getAllContacts = () => async dispatch => {
  try {
    dispatch({
      type: LOADING_CONTACT,
      payload: true,
    });
    const response = await bulkSms.get("addressBook/getAll", { ignoreOrgId: true });
    if (response.status === 200) {
      dispatch({
        type: SET_ALL_CONTACT,
        payload: response.data.data,
        allContactMeta: response.data?.meta || {},
      });
    }
    dispatch({
      type: LOADING_CONTACT,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_CONTACT,
      payload: false,
    });
    handleActionError(error, "GetAllContactsError", "Something Went Wrong Fetching Contacts, kindly try again");
  }
};
export const createContact =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions } = getSubmissionConfigOptions(config);
    try {
      // if (hasFormOptions) {
      //   config.formOptions.setSubmitting(true);
      // }
      dispatch({
        type: LOADING_CONTACT,
        payload: true,
      });
      await bulkSms.post("addressBook/create", values, { ignoreOrgId: true });
      dispatch({
        type: LOADING_CONTACT,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      toast.success("address book created successfuly");
      return dispatch(getAllContacts());
    } catch (error) {
      dispatch({
        type: LOADING_CONTACT,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      handleActionError(error, "Create Contact Error", "Couldn't Create Contact");
    }
  };

export const createManyContacts = () => {};

// Drafts
export const getAllDrafts = () => async dispatch => {
  try {
    dispatch({
      type: LOADING_DRAFT,
      payload: true,
    });
    const params = {
      sendStatus: "unpublish",
    };
    const response = await bulkSms.get("getAll", { params, ignoreOrgId: true });
    const campaigns = response.data.data.filter(item => item.send_status === "publish");
    const drafts = response.data.data.filter(item => item.send_status === "unpublish");

    dispatch({
      type: SET_ALL_CAMPAIGN,
      payload: campaigns,
      allDraftMeta: response.data?.meta || {},
    });
    dispatch({
      type: SET_ALL_DRAFT,
      payload: drafts,
      allDraftMeta: response.data?.meta || {},
    });
    // }
    dispatch({
      type: LOADING_DRAFT,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_DRAFT,
      payload: false,
    });
    handleActionError(error, "GetAllDraftsError", "Something Went Wrong Fetching Drafts, kindly try again");
  }
};

export const createDraft =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions } = getSubmissionConfigOptions(config);
    try {
      // if (hasFormOptions) {
      //   config.formOptions.setSubmitting(true);
      // }

      const params = {
        sendStatus: "unpublish",
      };
      dispatch({
        type: LOADING_DRAFT,
        payload: true,
      });
      await bulkSms.post("create", values, { params, ignoreOrgId: true });
      dispatch({
        type: LOADING_DRAFT,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      return dispatch(getAllDrafts());
    } catch (error) {
      dispatch({
        type: LOADING_DRAFT,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      handleActionError(error, "Create Draft Error", "Couldn't Create Draft");
    }
  };

export const updateDraft =
  (values, config = {}) =>
  async dispatch => {
    const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);

    try {
      dispatch({ type: LOADING_DRAFT, payload: true });
      const body = sanitizeMongoDoc(values);
      await bulkSms.put("", body);
      dispatch({ type: LOADING_DRAFT, payload: false });
      if (hasModal) {
        config.modal.hideModal();
      }
      return dispatch(getAllDrafts());
    } catch (err) {
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      dispatch({ type: LOADING_DRAFT, payload: false });
      handleActionError(err, "Update Contractor Error");
    }
  };

export const deleteDraft = id => async dispatch => {
  try {
    dispatch({
      type: LOADING_DRAFT,
      payload: true,
    });
    await bulkSms.patch("/toggleStatus", {}, { params: { id } });

    dispatch({
      type: LOADING_DRAFT,
      payload: false,
    });
    dispatch({
      type: LOADING_DRAFT,
      payload: false,
    });
  } catch (err) {
    handleActionError(err, "Delete Draft Error");
  }
};

// Templates

export const getAllTemplates = () => async dispatch => {
  try {
    dispatch({
      type: LOADING_TEMPLATES,
      payload: true,
    });
    const response = await bulkSms.get("template/getAll", { ignoreOrgId: true });
    // const response = { data: { data: mockSmsTemplateList({ count: 40 }) } };
    dispatch({
      type: SET_ALL_TEMPLATES,
      payload: response.data.data,
      allDraftMeta: response.data?.meta || {},
    });

    dispatch({
      type: LOADING_TEMPLATES,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_TEMPLATES,
      payload: false,
    });
    handleActionError(error, "GetAllTemplatesError", "Something Went Wrong Fetching Templates, kindly try again");
  }
};

export const createTemplate =
  (values, config = {}) =>
  async dispatch => {
    const { hasFormOptions, hasModal } = getSubmissionConfigOptions(config);
    try {
      // if (hasFormOptions) {
      //   config.formOptions.setSubmitting(true);
      // }
      dispatch({
        type: LOADING_TEMPLATES,
        payload: true,
      });
      await bulkSms.post("template/create", values, { ignoreOrgId: true });
      dispatch({
        type: LOADING_TEMPLATES,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      if (hasModal) {
        config.modal.hideModal();
      }
      return dispatch(getAllTemplates());
    } catch (error) {
      dispatch({
        type: LOADING_TEMPLATES,
        payload: false,
      });
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      handleActionError(error, "Create Tempalte Error", "Couldn't Create Tempalate");
    }
  };

export const updateTemplate =
  (values, config = {}) =>
  async dispatch => {
    const { hasModal, hasFormOptions } = getSubmissionConfigOptions(config);
    const params = { id: values._id };
    try {
      dispatch({ type: LOADING_TEMPLATES, payload: true });
      const body = sanitizeMongoDoc(values);
      await bulkSms.put("template/update", body, { params });
      dispatch({ type: LOADING_TEMPLATES, payload: false });
      if (hasModal) {
        config.modal.hideModal();
      }
      return dispatch(getAllTemplates());
    } catch (err) {
      if (hasFormOptions) {
        config.formOptions.setSubmitting(false);
      }
      dispatch({ type: LOADING_DRAFT, payload: false });
      handleActionError(err, "Update Contractor Error");
    }
  };

export const deleteTemplate = id => async dispatch => {
  try {
    dispatch({
      type: LOADING_TEMPLATES,
      payload: true,
    });
    await bulkSms.patch("template/toggleStatus", { status: "inactive" }, { params: { id } });
    dispatch({
      type: LOADING_TEMPLATES,
      payload: false,
    });
    return dispatch(getAllTemplates());
  } catch (err) {
    dispatch({
      type: LOADING_TEMPLATES,
      payload: false,
    });
    handleActionError(err, "Delete Draft Error");
  }
};

// Campaign Reports
export const getCampaignReport = __campaignId => async dispatch => {
  try {
    dispatch({
      type: LOADING_CAMPAIGN_REPORT,
      payload: true,
    });
    // const response = await bulkSms.get("", { ignoreOrgId: true });
    // if (response.status === 200) {
    //   dispatch({
    //     type: SET_ALL_CAMPAIGN,
    //     payload: response.data.data,
    //     allCampaignMeta: response.data?.meta || {},
    //   });
    // }
    const response = mockSmsCampaignMessagesReportList();
    dispatch({
      type: SET_ALL_CAMPAIGN_REPORT,
      payload: response,
    });

    dispatch({
      type: LOADING_CAMPAIGN_REPORT,
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: LOADING_CAMPAIGN_REPORT,
      payload: false,
    });
    handleActionError(
      error,
      "GetCampaignReportError",
      "Something Went Wrong Fetching Campaign Report, kindly try again"
    );
  }
};

/** Fetch phone numbers with params */
export const getAllPhoneNumbersWithParams =
  (params, config = { onSuccess() {}, onError() {} }) =>
  async dispatch => {
    try {
      dispatch({
        type: LOADING_PHONE_NUMBERS,
        payload: true,
      });
      const response = await farmerSeason.get("getPhoneNumberWithParams", { params, ignoreOrgId: true });
      if (response.status === 200) {
        dispatch({
          type: SET_ALL_PHONE_NUMBERS,
          payload: response.data,
        });
      }

      dispatch({
        type: LOADING_PHONE_NUMBERS,
        payload: false,
      });

      config?.onSuccess?.(response.data);
    } catch (error) {
      dispatch({
        type: LOADING_PHONE_NUMBERS,
        payload: false,
      });
      config?.onError?.(error);
      handleActionError(
        error,
        "GetAllPhoneNumbersError",
        "Something Went Wrong Fetching Phone Numbers, kindly try again"
      );
    }
  };

export const setPhoneNumbersData =
  (numbers = []) =>
  dispatch => {
    dispatch({
      type: SET_ALL_PHONE_NUMBERS,
      payload: numbers,
    });
  };

const initialState = {
  allCampaignData: [],
  allCampaignMeta: {},
  loadingCampaign: false,
  campaignReport: [],
  loadingCampaignReport: false,
  campaignOverview: {},
  loadingCampaignOverview: false,
  allContactData: [],
  allContactMeta: {},
  loadingContact: false,
  allDraftData: [],
  allDraftMeta: {},
  loadingDraft: false,
  allTemplateData: [],
  allTemplateMeta: {},
  loadingTemplate: false,
  allPhoneNumbersData: [],
  loadingPhoneNumbers: false,
};

const bulkSmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_CAMPAIGN:
      return {
        ...state,
        allCampaignData: action.payload,
        allCampaignMeta: action.allCampaignMeta,
      };
    case LOADING_CAMPAIGN:
      return {
        ...state,
        loadingCampaign: action.payload,
      };
    case SET_CAMPAIGN_OVERVIEW:
      return {
        ...state,
        campaignOverview: action.payload,
      };
    case LOADING_CAMPAIGN_OVERVIEW:
      return {
        ...state,
        loadingCampaignOverview: action.payload,
      };
    case SET_ALL_CONTACT:
      return {
        ...state,
        allContactData: action.payload,
        allContactMeta: action.allCampaignMeta,
      };
    case LOADING_CONTACT:
      return {
        ...state,
        loadingContact: action.payload,
      };
    case SET_ALL_DRAFT:
      return {
        ...state,
        allDraftData: action.payload,
        allDraftMeta: action.allDraftMeta,
      };
    case LOADING_DRAFT:
      return {
        ...state,
        loadingDraft: action.payload,
      };
    case SET_ALL_TEMPLATES:
      return {
        ...state,
        allTemplateData: action.payload,
        allTempalteMeta: action.allTemplateMeta,
      };
    case LOADING_TEMPLATES:
      return {
        ...state,
        loadingTemplate: action.payload,
      };
    case SET_ALL_CAMPAIGN_REPORT:
      return {
        ...state,
        campaignReport: action.payload,
      };
    case LOADING_CAMPAIGN_REPORT:
      return {
        ...state,
        loadingCampaignReport: action.payload,
      };
    case SET_ALL_PHONE_NUMBERS:
      return {
        ...state,
        allPhoneNumbersData: action.payload,
      };
    case LOADING_PHONE_NUMBERS:
      return {
        ...state,
        loadingPhoneNumbers: action.payload,
      };
    default:
      return state;
  }
};

export const getAllCampaignData = state => state.legacyBulkSms.allCampaignData;
export const getAllCampaignMeta = state => state.legacyBulkSms.allCampaignMeta;
export const getLoadingCampaign = state => state.legacyBulkSms.loadingCampaign;
export const getAllContactData = state => state.legacyBulkSms.allContactData;
export const getAllContactMeta = state => state.legacyBulkSms.allContactMeta;
export const getLoadingContact = state => state.legacyBulkSms.loadingContact;
export const getAllDraftData = state => state.legacyBulkSms.allDraftData;
export const getAllDraftMeta = state => state.legacyBulkSms.allDraftMeta;
export const getLoadingDraft = state => state.legacyBulkSms.loadingDraft;
export const getAllTemplateData = state => state.legacyBulkSms.allTemplateData;
export const getAllTemplateMeta = state => state.legacyBulkSms.allTemplateMeta;
export const getLoadingTemplate = state => state.legacyBulkSms.loadingTemplate;
export const getCampaignReportData = state => state.legacyBulkSms.campaignReport;
export const getLoadingCampaignReport = state => state.legacyBulkSms.loadingCampaignReport;
export const getCampaignOverviewData = state => state.legacyBulkSms.campaignOverview;
export const getLoadingCampaignOverview = state => state.legacyBulkSms.loadingCampaignOverview;
export const getAllPhoneNumbersData = state => state.legacyBulkSms.allPhoneNumbersData;
export const getLoadingPhoneNumbers = state => state.legacyBulkSms.loadingPhoneNumbers;

export default bulkSmsReducer;
