/* eslint-disable no-unused-vars */
import React from "react";
import { PageWrapper } from "components/layout";
import { Flex } from "organic";
import { PageTabsNav } from "components/pageTabs";
import { ExportButton } from "components/export";
import { VisibilityWrapper } from "components/layout/visibilityWrapper";
import harvest from "api/harvest";
import { legacyGenerateCsvFilename } from "utils/v1/exportUtils";
import { useDataFilter } from "hooks";
import { getCurrentPageTabId } from "features/v1/dashboard";
import { useSelector } from "react-redux";
import { PageHeader } from "components/layout";

const SUMMARY_TAB_ID = "warehouseSummary";
const STORE_TAB_ID = "warehouseStores";
const HARVEST_TAB_ID = "warehouseHarvest";

const tabs = [
  // { tabId: SUMMARY_TAB_ID, title: "Summary", path: "warehouseSummary" },
  { tabId: STORE_TAB_ID, title: "Store", path: "stores" },
];

const WarehousePage = () => {
  let currentPageTabId = useSelector(getCurrentPageTabId);

  const { filterParams } = useDataFilter();
  const quickExportHarvestData = async () => {
    const data = (await harvest.get("downloadHarvest", { params: filterParams })).data;
    return data;
  };

  const getHarvestMetaKeys = async () => {
    return await harvest.get("downloadharvest/getMetaKeys");
  };

  const advancedExportHarvestData = async ({ filterParams, columnWhitelist }) => {
    const params = { columnWhitelist, ...filterParams };
    return await harvest.get("downloadHarvest/advance", { params });
  };
  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" align-Items="center">
        <PageHeader title="Warehouse" subtitle="Your farmers summary and activity." />
        <VisibilityWrapper isVisible={currentPageTabId === HARVEST_TAB_ID}>
          <ExportButton
            quickExportConfig={{
              fileName: legacyGenerateCsvFilename("farmMapping"),
              activeTab: currentPageTabId,
              apiCalls: {
                // inbound: quickExportBoundData,
                // outbound: quickExportBoundData,
                harvest: quickExportHarvestData,
              },
            }}
            advancedExportConfig={{
              apiCall: advancedExportHarvestData,
              getKeys: getHarvestMetaKeys,
            }}
          />
        </VisibilityWrapper>
      </Flex>
      <PageTabsNav tabs={tabs} />
    </PageWrapper>
  );
};

export default WarehousePage;
