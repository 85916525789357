import { nanoid } from "nanoid";
import { Flex, Text } from "organic";
import React, { useMemo } from "react";
import { chunk } from "utils";

const MessageBubbles = ({ text, messageSize = 160 }) => {
  let bubbles = useMemo(() => {
    // if (!text || typeof string !== "string") {
    //   return [];
    // }
    return chunk(text.split(""), messageSize);
  }, [messageSize, text]);
  return (
    <div>
      <Flex width="100%" flexDirection="column" gap="2rem">
        {bubbles?.map(bubble => (
          <Flex
            key={nanoid()}
            bg="gray100"
            borderRadius="0px 8px 8px 8px"
            alignItems="center"
            py="10px"
            px="14px"
            maxWidth="233px"
          >
            <Text font="smRegular" color="gray900">
              {bubble && bubble.join("")}
            </Text>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

export default MessageBubbles;
