import harvest from "api/harvest";
import { toast } from "react-toastify";
import Axios from "axios";
import { handleActionError } from "services/shared/AOSErrorService";

// Ducks pattern
// actions
// const ALL_FARM_SUBSCRIPTION_PAYMENTS = "ALL_FARM_SUBSCRIPTION_PAYMENTS";
const GET_ALL_HARVEST_PAYMENT = "GET_ALL_HARVEST_PAYMENT";
const LOAD_HARVEST_PAYMENT = "LOAD_ALL_HARVEST_PAYMENT";
const GET_ALL_HARVEST_DATA = "GET_ALL_HARVEST_DATA";
const LOAD_HARVEST_DATA = "LOAD_HARVEST_DATA";
// const LOAD_SEARCH = "LOAD_SEARCH";

// action creators
export const getAllHarvestPaymentWithParams = params => async dispatch => {
  try {
    dispatch({ type: LOAD_HARVEST_PAYMENT, payload: true });
    const resData = await harvest.get("getAllHarvestsWithParams", { params, ignoreOrgId: true });

    dispatch({
      type: GET_ALL_HARVEST_PAYMENT,
      payload: resData.data.data,
      harvestPaymentMeta: resData?.data?.meta || {},
    });

    dispatch({ type: LOAD_HARVEST_PAYMENT, payload: false });
  } catch (getAllHarvestPaymentWithParamsError) {
    dispatch({ type: LOAD_HARVEST_PAYMENT, payload: false });
    handleActionError({ getAllHarvestPaymentWithParamsError });
  }
};

export const searchAllHarvestPaymentWithParams = (params, cancelToken) => async dispatch => {
  try {
    dispatch({ type: LOAD_HARVEST_PAYMENT, payload: true });
    const result = await harvest.get("search", { params, cancelToken });
    if (result.status === "success") {
      dispatch({
        type: GET_ALL_HARVEST_PAYMENT,
        payload: result.data.data,
        harvestPaymentMeta: result?.data?.meta || {},
      });

      dispatch({ type: LOAD_HARVEST_PAYMENT, payload: false });
    }
  } catch (err) {
    dispatch({ type: LOAD_HARVEST_DATA, payload: false });
    handleActionError(err, "Search Harvest Payment Error");
    dispatch({ type: LOAD_HARVEST_DATA, payload: false });
    if (Axios.isCancel(err)) return;
  }
};

export const getAllHarvestDataWithParams = params => async dispatch => {
  try {
    dispatch({ type: LOAD_HARVEST_DATA, payload: true });

    const result = await harvest.get("getAllHarvestsWithParams", { params, ignoreOrgId: true });

    dispatch({
      type: GET_ALL_HARVEST_DATA,
      payload: result.data.data,
      allHarvestMeta: result?.data?.meta || {},
    });
    dispatch({ type: LOAD_HARVEST_DATA, payload: false });
  } catch (error) {
    handleActionError(error, "Get Harvest Data Error");
    toast.error("Something went wrong fetching harvests, try refreshing the page");
    dispatch({ type: LOAD_HARVEST_DATA, payload: false });
  }
};

// reducer
const initialState = {
  allHarvestPayments: [],
  harvestPaymentsCount: 0,
  harvestPaymentMeta: {},
  harvestPaymentTotalAmountPaid: 0,
  harvestTarget: 5500000,
  loadingHarvestPayment: false,
  allHarvestData: [],
  allHarvestMeta: {},
  loadingHarvestData: false,
};

function middlewareFilterPaymentsData(state, action) {
  try {
    let _data = action.payload;
    // let commitmentFeesTotalAmountPaid = 0;
    // commitmentFeesTotalAmountPaid =
    //   _data.length >= 1
    //     ? Math.ceil(state.commitmentFeePerFieldMapped * _data.length)
    //     : 0;
    //for(var i = 0; i < _data.length)
    return {
      ...state,
      allHarvestPayments: _data,
      harvestPaymentMeta: action.harvestPaymentMeta,
      pages: action.pages,
      total: action.total,
    };
  } catch (error) {
    console.log({ error });
  }
}

const harvestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_HARVEST_PAYMENT:
      return middlewareFilterPaymentsData(state, action);
    case LOAD_HARVEST_PAYMENT:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_HARVEST_DATA:
      return {
        ...state,
        allHarvestData: action.payload,
        allHarvestMeta: action.allHarvestMeta,
      };
    case LOAD_HARVEST_DATA:
      return {
        ...state,
        loadingHarvestData: action.payload,
      };
    default:
      return state;
  }
};

// selector functions
export const getAllHarvestPayments = state => state.legacyHarvest.allHarvestPayments;
export const getHarvestPaymentsCount = state => state.legacyHarvest.harvestPaymentsCount;
export const getHarvestPaymentMeta = state => state.legacyHarvest.harvestPaymentMeta;
export const getHarvestPaymentTotalAmountPaid = state => state.legacyHarvest.harvestPaymentTotalAmountPaid;
export const getHarvestTarget = state => state.legacyHarvest.harvestTarget;
export const getLoadingHarvestPayment = state => state.legacyHarvest.loadingHarvestPayment;
export const getAllHarvestData = state => state.legacyHarvest.allHarvestData;
export const getAllHarvestMeta = state => state.legacyHarvest.allHarvestMeta;
export const getLoadingHarvestData = state => state.legacyHarvest.loadingHarvestData;

export default harvestReducer;
