import { FieldArray, useFormikContext } from "formik";
import React from "react";
import QuestionListItem, { AddBlockButton } from "./questionListItem";
import { appGet, hyphenate } from "utils";
import { questionInputTypes, questionTypeIcons } from "utils/questionnaire-utils/questionnaire-utils";
import { selectLabelFromValue } from "utils/v1/selectUtils";
import { toast } from "react-toastify";

const QuestionList = React.forwardRef(
  (
    {
      badge,
      currentQuestionIndex,
      currentQuestionKey,
      handleScroll,
      questionKey,
      setCurrentQuestionIndex,
      setCurrentQuestionKey,
      editOnly,
      readOnly,
      questionnaire,
      ...props
    },
    forwardedRef
  ) => {
    const { values, setFieldValue, dirty, errors } = useFormikContext();

    const questions = appGet(values, questionKey, []);
    const blockCount = questions.length;

    const setQuestionFields = ({ index, question }) => {
      setFieldValue(`${questionKey}.${index}.${question.answer_options}`, appGet(question, "answer_options"));
      setFieldValue(`${questionKey}.${index}.${question.correct_answer}`, appGet(question, "correct_answer"));
      setFieldValue(`${questionKey}.${index}.${question.keyboardType}`, appGet(question, "keyboardType"));
      setFieldValue(`${questionKey}.${index}.${question.maxLength}`, appGet(question, "maxLength"));
    };

    const deleteQuestion = ({ helper, index }) => {
      helper.remove(index);
      toast.info("question deleted", { delay: 500 });
    };

    const selectQuestion = ({ key, index }) => {
      setCurrentQuestionKey(key);
      setCurrentQuestionIndex(index);
    };

    React.useEffect(() => {
      handleScroll();
    }, []);
    return (
      <>
        {/* block list */}
        <FieldArray
          {...props}
          innerRef={forwardedRef}
          name={questionKey}
          render={arrayHelpers => (
            <>
              {(questions?.length &&
                questions.map((question, questionIndex) => {
                  return (
                    <QuestionListItem
                      key={hyphenate([questionKey, "question", questionIndex])}
                      icon={appGet(questionTypeIcons, `${question?.question_type}`)}
                      title={appGet(question, "question_title", "")}
                      subtitle={selectLabelFromValue(questionInputTypes, question?.question_type)}
                      currentQuestionIndex={currentQuestionIndex}
                      currentQuestionKey={currentQuestionKey}
                      index={questionIndex}
                      questionKey={questionKey}
                      touched={appGet(dirty, [questionKey, questionIndex])}
                      errors={appGet(errors, [questionKey, questionIndex])}
                      onSelect={() => {
                        selectQuestion({ key: questionKey, index: questionIndex });
                        setQuestionFields({ index: questionIndex, question });
                      }}
                      onDelete={() => deleteQuestion({ helper: arrayHelpers, index: questionIndex })}
                      badge={badge}
                      readOnly={readOnly}
                    />
                  );
                })) ||
                null}
              {!readOnly && !editOnly ? (
                <AddBlockButton
                  title="Add block"
                  index={-1}
                  questionnaire={questionnaire}
                  onSelect={question => {
                    arrayHelpers.push(question);
                    selectQuestion({ index: blockCount, key: questionKey });
                  }}
                  onAddBlockChange={open => {
                    if (open === true) {
                      handleScroll();
                    }
                  }}
                />
              ) : null}
            </>
          )}
        />
      </>
    );
  }
);

QuestionList.displayName = "QuestionList";

export default QuestionList;
