import React from "react";
import { Badge } from "reactstrap";

const CustomBadge = ({ children, color, ...restProps }) => {
  return (
    <Badge color={color} {...restProps}>
      {children}
    </Badge>
  );
};

export default CustomBadge;
