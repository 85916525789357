const totalFarmers = 758897;
export const mockOverviewConstants = {
  FILTER_DATE_RANGE: ["2023-11-21T14:02:14.595Z", "2023-11-21T14:01:38.620Z"],
  FARMER_COUNT: totalFarmers,
  GROUP_COUNT: 250388,
  ACTIVE_AGENTS: 10374,
  AVG_GROUP_SIZE: 3,
  AVG_FIELD_SIZE: 1.52,
  AVG_FARMER_PER_AGENT: 65,
  AGENT_COUNT: 12903,
  RETURNING_FARMERS: 0,
  NEW_FARMERS: totalFarmers,
  COMMITMENT_FEE_AMOUNT: 374666500,
  SUBSCRIPTION_COUNT: 749333,
  EQUITY_CONTRIB_AMOUNT: 59994664999,
  SAMPLE_SIZE: 10,
  DATE_RANGE_SAMPLE: [
    "2018-08-14T13:57:07.092Z",
    "2018-08-14T13:56:53.979Z",
    "2019-08-14T13:56:41.970Z",
    "2019-08-14T13:55:16.798Z",
    "2020-08-14T13:55:00.543Z",
    "2020-08-14T13:53:44.152Z",
    "2021-08-14T13:53:32.866Z",
    "2021-08-14T13:47:52.417Z",
    "2022-08-14T13:47:37.937Z",
    "2023-08-14T13:47:20.968Z",
    "2018-08-14T13:57:07.092Z",
    "2018-08-14T13:56:53.979Z",
    "2019-08-14T13:56:41.970Z",
    "2019-08-14T13:55:16.798Z",
    "2020-08-14T13:55:00.543Z",
    "2020-08-14T13:53:44.152Z",
    "2021-08-14T13:53:32.866Z",
    "2021-08-14T13:47:52.417Z",
    "2022-08-14T13:47:37.937Z",
    "2023-08-14T13:47:20.968Z",
    "2018-08-14T13:57:07.092Z",
    "2018-08-14T13:56:53.979Z",
    "2019-08-14T13:56:41.970Z",
    "2019-08-14T13:55:16.798Z",
    "2020-08-14T13:55:00.543Z",
    "2020-08-14T13:53:44.152Z",
    "2021-08-14T13:53:32.866Z",
    "2021-08-14T13:47:52.417Z",
    "2022-08-14T13:47:37.937Z",
    "2023-08-14T13:47:20.968Z",
    "2018-08-14T13:57:07.092Z",
    "2018-08-14T13:56:53.979Z",
    "2019-08-14T13:56:41.970Z",
    "2019-08-14T13:55:16.798Z",
    "2020-08-14T13:55:00.543Z",
    "2020-08-14T13:53:44.152Z",
    "2021-08-14T13:53:32.866Z",
    "2021-08-14T13:47:52.417Z",
    "2022-08-14T13:47:37.937Z",
    "2023-08-14T13:47:20.968Z",
  ],
  generatorConfig: {
    farmers: {
      2017: {
        January: 416,
        February: 412,
        March: 438,
        April: 438,
        May: 447,
        June: 430,
        July: 422,
        August: 410,
        September: 440,
        October: 447,
        November: 423,
        December: 417,
        Total: 5000,
      },
      2018: {
        January: 1332,
        February: 1266,
        March: 1327,
        April: 1298,
        May: 1293,
        June: 1288,
        July: 1301,
        August: 1311,
        September: 1267,
        October: 1314,
        November: 1301,
        December: 1292,
        Total: 15000,
      },
      2019: {
        January: 4300,
        February: 4084,
        March: 4215,
        April: 4091,
        May: 4123,
        June: 4028,
        July: 4172,
        August: 4347,
        September: 4352,
        October: 4048,
        November: 4184,
        December: 4276,
        Total: 50000,
      },
      2020: {
        January: 12306,
        February: 12856,
        March: 13051,
        April: 12862,
        May: 12574,
        June: 12454,
        July: 12645,
        August: 12981,
        September: 12675,
        October: 12451,
        November: 12634,
        December: 12555,
        Total: 154000,
      },
      2021: {
        January: 27510,
        February: 27522,
        March: 27626,
        April: 27536,
        May: 27494,
        June: 27450,
        July: 27581,
        August: 27653,
        September: 27550,
        October: 27512,
        November: 27474,
        December: 27582,
        Total: 330000,
      },
      2022: {
        January: 47863,
        February: 48115,
        March: 47978,
        April: 48247,
        May: 48053,
        June: 47845,
        July: 47932,
        August: 48065,
        September: 48189,
        October: 47991,
        November: 48126,
        December: 47351,
        Total: 574365,
      },
      2023: {
        January: 63789,
        February: 63873,
        March: 63634,
        April: 63502,
        May: 63689,
        June: 63435,
        July: 63917,
        August: 63769,
        September: 63554,
        October: 63842,
        November: 63962,
        December: 63450,
        Total: 765468,
      },
    },
  },
};
