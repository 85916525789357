import React, { useEffect, useState } from "react";

import statusSelectOptions from "./options/statusSelectOptions";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import { Select } from "components/select";
import { isFunction } from "utils";

const StatusSelect = props => {
  const { onChange, defaultValue, label, name, required } = props;

  const [selectedStatus, setSelectedStatus] = useState();

  async function _handleChange(selectedValue) {
    setSelectedStatus(selectOptionFromValue(statusSelectOptions, selectedValue));
    if (isFunction(onChange)) onChange(selectedValue);
  }
  useEffect(() => {
    if (defaultValue) {
      setSelectedStatus(selectOptionFromValue(statusSelectOptions, defaultValue));
    }
  }, []);

  return (
    <Select
      value={selectedStatus}
      label={label}
      name={name}
      onChange={_handleChange}
      options={statusSelectOptions}
      required={required}
      placeholder="Select Status"
    />
  );
};

StatusSelect.propTypes = {};

export { StatusSelect };
