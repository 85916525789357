import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { leafletMapConstants } from "./mapConstants";
import { ErrorState } from "components/appState";
import "leaflet/dist/leaflet.css";
import { LeafletMarker, LeafletPolygon } from ".";
import { nanoid } from "nanoid";

const defaultStyles = {
  width: "100%",
  height: "349px",
  borderRadius: "0.5rem",
  backgroundColor: "rgba(130, 130, 130, 0.00)",
  position: "relative",
};

const LeafletMap = React.forwardRef(({ children, width, height, markers, polygons }, forwardedRef) => {
  const [internalMap, setInternalMap] = useState();

  const mapStyles = Object.assign({}, defaultStyles, { width, height });

  const _polygons = useMemo(() => {
    if (internalMap) {
      console.log("there's internal map for polygons");
      {
        return polygons?.length
          ? polygons.map(polygon => {
              return <LeafletPolygon key={nanoid()} {...polygon} />;
            })
          : null;
      }
    }
    console.log("there was no internal map for polygons");
  }, [internalMap, polygons]);

  const _markers = useMemo(() => {
    if (internalMap) {
      console.log("there's internal map for markers");
      {
        return markers?.length
          ? markers
              // .filter(mapping => mapping?.coordinate)
              .map(marker => {
                return <LeafletMarker key={nanoid()} {...marker} />;
              })
          : null;
      }
    }
    console.log("there was no internal map for markers");
  }, [internalMap, markers]);

  useEffect(() => {
    if (internalMap) {
      forwardedRef.current = internalMap;
    }
  }, [internalMap]);

  return (
    <ErrorState useTooltip>
      <MapContainer
        {...leafletMapConstants.defaults.MAP_OPTIONS}
        style={mapStyles}
        whenReady={event => {
          try {
            setInternalMap(event.target);
          } catch (error) {
            console.log("[MAPERROR]: Map Failed to initialised", { error });
          }
        }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors and ThriveAgric Tech'
          keepBuffer={10}
          detectRetina
          updateWhenIdle
          updateWhenZooming
        />
        {_markers}
        {_polygons}
        {children}
      </MapContainer>
    </ErrorState>
  );
});

LeafletMap.displayName = "LeafletMap";

LeafletMap.defaultProps = {
  center: leafletMapConstants.defaults.POSTION,
  zoom: 12,
  width: "100%",
  height: "349px",
  polygons: [],
  markers: [],
};

export { LeafletMap };
