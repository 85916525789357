import styled, { css } from "styled-components/macro";
import shouldForwardProp from "@styled-system/should-forward-prop";
import { background, borders, layout, overflow, overflowX, overflowY, space } from "styled-system";

const StyledImage = styled.img.withConfig({
  shouldForwardProp,
})`
  ${props => css`
    ${space}
    ${background}
  ${layout}
  ${overflow}
  ${overflowX}
  ${overflowY}
  ${borders}

  ${props?.cursor
      ? css`
          cursor: ${props.cursor};
        `
      : css``}
  `}
`;

export default StyledImage;
