import React, { useEffect, useState } from "react";
import { Select } from ".";
import _ from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectOptionFromValue } from "utils/v1/selectUtils";
import {
  getAllSeasons,
  getLoadingSeasons,
  getSeasonData,
  getSeasonOptions,
  getSelectedSeason,
  setSelectedSeason as setGlobalSelectedSeason,
} from "features/v1/season";

const projectSelectPropTypes = {
  onChange: PropTypes.func,
  ignoreGlobalState: PropTypes.bool,
  name: PropTypes.string,
};

const projectSelectDefaultProps = {
  onChange: null,
};

const ProjectSelect = props => {
  const { onChange, useAll, defaultValue, ignoreGlobalState = false, ...rest } = props;

  const [_selectedSeason, _setSelectedSeason] = useState();

  const dispatch = useDispatch();

  const seasonData = useSelector(getSeasonData);
  const loadingSeasons = useSelector(getLoadingSeasons);
  const seasonOptions = useSelector(getSeasonOptions);
  const selectedSeason = useSelector(getSelectedSeason);

  async function _handleSelect(seasonValue) {
    if (!ignoreGlobalState) {
      dispatch(setGlobalSelectedSeason(seasonValue));
    }
    if (_.isFunction(onChange)) onChange(seasonValue);
  }

  useEffect(() => {
    if (seasonData.length < 1) {
      dispatch(getAllSeasons());
    }
  }, []);

  useEffect(() => {
    if (!ignoreGlobalState && selectedSeason) {
      setGlobalSelectedSeason(selectedSeason);
    }
  }, []);

  useEffect(() => {
    if (ignoreGlobalState && defaultValue) {
      _setSelectedSeason(selectOptionFromValue(seasonOptions, defaultValue));
    }
  }, []);

  return (
    <>
      <Select
        {...rest}
        options={useAll ? [{ label: "All Projects", value: seasonOptions?.[0] }] : seasonOptions}
        loading={loadingSeasons}
        onChange={_handleSelect}
        placeholder={loadingSeasons ? "loading projects" : "Select Project"}
        value={ignoreGlobalState || useAll ? _selectedSeason : selectedSeason}
      />
    </>
  );
};

ProjectSelect.propTypes = projectSelectPropTypes;
ProjectSelect.defaultProps = projectSelectDefaultProps;

export { ProjectSelect };
