import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const dashboardLayoutConstants = {
  actions: {
    EXPAND_SIDEBAR: "EXPAND_SIDEBAR",
    COLLAPSE_SIDEBAR: "COLLAPSE_SIDEBAR",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    CURRENT_PAGE_TAB: "CURRENT_PAGE_TAB",
    SET_DATA: "SET_DATA",
  },
};

const defaultDashbaordLayoutState = {
  sidebarExpanded: true,
  currentPageTabId: null,
  // arbitrary data - you can store anything here
  // to make it gloablly available accross dahsboard pages
  data: {},
};

const DashboardLayoutContext = React.createContext();

function dashboardLayoutReducer(state, action) {
  const { actions } = dashboardLayoutConstants;
  switch (action.type) {
    case actions.EXPAND_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: true,
      };
    }
    case actions.COLLAPSE_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: false,
      };
    }
    case actions.TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarExpanded: !state.sidebarExpanded,
      };
    }
    case actions.CURRENT_PAGE_TAB: {
      return {
        ...state,
        currentPageTabId: action.payload,
      };
    }
    case actions.SET_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    default: {
      throw new Error(`Unhandled DashboardLayout Action ${action.type}`);
    }
  }
}

const setData = dispatch => _stateSlice => {
  dispatch({ type: dashboardLayoutConstants.actions.SET_DATA, payload: _stateSlice });
};

function DashboardLayoutProvider({ children }) {
  const reduxDispatch = useDispatch();
  const [dashboardState, dispatch] = React.useReducer(dashboardLayoutReducer, defaultDashbaordLayoutState);
  const location = useLocation();

  const data = React.useMemo(() => dashboardState.data, [dashboardState.data]);

  const locationKey = useRef(location?.key || "");
  let value = {
    dashboardState,
    dispatch,
    setData,
    data,
  };

  useEffect(() => {
    if (locationKey.current !== location.key) {
      console.log("pageTabs", { location });
      if (location?.state?.tabId) {
        dispatch({ type: dashboardLayoutConstants.actions.CURRENT_PAGE_TAB, payload: location?.state?.tabId });
        reduxDispatch({ type: dashboardLayoutConstants.actions.CURRENT_PAGE_TAB, payload: location?.state?.tabId });
      } else {
        dispatch({ type: dashboardLayoutConstants.actions.CURRENT_PAGE_TAB, payload: "" });
        reduxDispatch({ type: dashboardLayoutConstants.actions.CURRENT_PAGE_TAB, payload: "" });
      }
      locationKey.current = location.key;
    }
  }, [location]);

  useEffect(() => {
    console.log({ dashboardState });
  }, [dashboardState]);

  return <DashboardLayoutContext.Provider value={value}>{children}</DashboardLayoutContext.Provider>;
}

function useDashboardLayout() {
  const context = React.useContext(DashboardLayoutContext);

  if (context === undefined) {
    throw new Error("Dashboard context Provider Unavailable: useDashboardLayout must be used within Dashboard Pages");
  }

  return { dashboardState: context.dashboardState, dispatch: context.dispatch };
}

export {
  DashboardLayoutProvider,
  useDashboardLayout,
  dashboardLayoutConstants as DEPLRECATED_dashboardLayoutConstants,
};
