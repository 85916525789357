import React, { useEffect, useState } from "react";
import { Select } from ".";
import { useSelector } from "react-redux";
import { getLoadingQuestionnaire, getQuestionnaireOptions } from "features/v1/questionnaire";
// import { isString } from "utils";
import { allQuestionnaireTypes } from "utils/questionnaire-utils/questionnaire-utils";
import { removeWhitespace } from "utils";

export const QuestionnaireSelect = ({ model, onChange, ...props }) => {
  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState([]);

  let loading = useSelector(getLoadingQuestionnaire);
  let allQuestionnaireOptions = useSelector(getQuestionnaireOptions);

  const ignoredallModels = allQuestionnaireTypes.map(questionnaire => questionnaire.value);

  useEffect(() => {
    // if(isString(model) && allModels.includes(model)) {
    console.log({ model, allQuestionnaireOptions });
    if (model) {
      const result = allQuestionnaireOptions.filter(
        questionnaire => removeWhitespace(questionnaire.value.model) === removeWhitespace(model)
      );
      setFilteredQuestionnaires(result);
    } else {
      setFilteredQuestionnaires(allQuestionnaireOptions);
    }
  }, [model]);

  return (
    <Select
      label="Select an existing questionnaire"
      {...props}
      options={filteredQuestionnaires}
      isLoading={loading}
      onChange={onChange}
    />
  );
};
