import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import Marketplace_API from "services/marketplaceApiService";

export function useGetFinanceReportingOverview({ params }) {
  // console.log({ insight: params });
  const fetcher = async () => {
    const _data = await Marketplace_API.fetchFinanceReportingOverview(params);
    // const { data } = _data;
    return _data;
  };
  // get all overview
  return useQuery({
    queryKey: [rqKeys.marketplace.fetchFinanceReporting, ...Object.values(params)],
    queryFn: fetcher,
  });
}
