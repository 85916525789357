/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Badge, Flex, Text, badgeConstants } from "organic";
import { useTable } from "hooks";
import { farmerPaymentRequestStatus } from "features/v1/farmerPayment";
import { truncateText } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { getAllRiskSurvey, getRiskSurveyData } from "features/v1/riskManagement";
import { format } from "date-fns";

const useRiskSurveyTable = () => {
  const dispatch = useDispatch();
  const allRiskSurvey = useSelector(getRiskSurveyData);

  useEffect(() => {
    dispatch(getAllRiskSurvey());
  }, []);

  const columns = [
    {
      Header: "Survey Name",
      accessor: "name",
      Cell: ({ value }) => {
        return (
          <Text color="primary600" font="smMedium">
            {value}
          </Text>
        );
      },
    },
    {
      Header: "Farm Category",
      accessor: "farm_operation_category",
      Cell: ({ value }) => {
        return <span>{value[0] || "--"}</span>;
      },
    },
    {
      Header: "Farm Type",
      accessor: "category_crop_type",
      Cell: ({ value }) => {
        return <span>{value}</span>;
      },
    },
    {
      Header: "Date Created",
      accessor: "created_at",
      Cell: ({ value }) => <span>{format(new Date(value), "yyyy-MM-dd")}</span>,
    },
    {
      Header: "No of Responses",
      accessor: "totalNoOfResponses",
      Cell: ({ value }) => {
        return <span>{value}</span>;
      },
    },
    {
      Header: "No of Questions",
      accessor: "noOfQuestions",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Total Number of Farmers",
      accessor: "totalNoOfFarmer",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        return (
          <>
            {value === "active" ? (
              <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>Active</Badge>
            ) : (
              <Badge colorScheme={badgeConstants.colorSchemes.ERROR}>Inactive</Badge>
            )}
          </>
        );
      },
    },
  ];

  const paymentsTable = useTable({
    data: allRiskSurvey,
    columns,
    title: "All Risk Survey",
    noDateRangeFilter: true,
    // noSearch: true,
    noViewAction: true,
    noEditAction: true,
    // Don't show any actions on payout tab
    noActions: false,
    // callbacks
  });
  return paymentsTable;
};

export default useRiskSurveyTable;
