import { Box, Button, Flex, Text } from "organic";
import React from "react";

export const RejectWithCommentDialog = ({ loading = false, modal, handleSubmit, comment, setComment }) => {
  const onSubmit = e => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Flex flexDirection="column" px="2rem" py="2rem">
      <Text mb={30} font="displayXsMedium">
        Comment
      </Text>
      <form onSubmit={onSubmit}>
        <Text as="p" font="smMedium" color="gray500" marginBottom="0.5rem">
          Declined transactions will result in a notification to the agent, giving the reason for failure.
        </Text>
        <Box mb={7}>
          <textarea
            className="modal-textarea"
            onChange={e => setComment(e.target.value)}
            value={comment}
            name=""
            id=""
            cols="50"
            rows="20"
            placeholder="Please state your reason here"
          ></textarea>
        </Box>
        <Flex gap="20px" justifyContent="space-between">
          <Button display="block" type="button" colorScheme="secondaryGray" onClick={modal.hideModal}>
            Cancel
          </Button>
          <Button display="block" type="submit" isDisabled={!comment.length || loading}>
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};
