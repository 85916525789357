import LegacyEmptyState from "components/v1/AppState/EmptyState";
import ConfirmModal from "components/v1/ConfirmModal";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { Modal, ModalBody } from "reactstrap";
import OverlayLoader from "../Loader/OverlayLoader";
import CustomModalFooter from "./CustomModalFooter";
import CustomModalHeader from "./CustomModalHeader";

const CustomModalPropTypes = {
  actionComponent: PropTypes.node,
  actionDisabled: PropTypes.bool,
  actionLabel: PropTypes.string,
  actionType: PropTypes.string,
  cancelLabel: PropTypes.string,
  children: PropTypes.node,
  confirmAction: PropTypes.bool,
  confirmCancel: PropTypes.bool,
  confirmCancelMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  handleModalToggle: PropTypes.func,
  headerComponent: PropTypes.node,
  isOpen: PropTypes.bool,
  modal: PropTypes.object.isRequired,
  noAction: PropTypes.bool,
  onClickAction: PropTypes.func,
  // onClick: PropTypes.func, // FIXME: ought to be onClickCancel, for confirmCancel flow
  onSubmitAction: PropTypes.func, // to submit
  sm: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  showForm: PropTypes.bool,
  onCloseModal: PropTypes.func,
};
const CustomModalDefaultProps = {
  isOpen: false,
  handleModalToggle: () => {},
  actionType: "button",
};

const CustomModal = props => {
  const {
    // a component to replace the default Action Button
    actionComponent,
    // bool - if "Save" button should be disabled
    actionDisabled,
    // label for the "Save" button in footer
    actionLabel,
    // label - for the "Cancel" button in the Footer
    cancelLabel,
    children,
    confirmAction,
    // confirmCancel,
    noAction, // for modal that just show info
    // confirmCancelMessage,
    confirmMessage: confirmActionMessage,
    handleModalToggle,
    headerComponent,
    isOpen,
    modal,
    // callback for the "Save" button in footer
    onClickAction,
    actionType, // the value of 'type' for "Save" button
    sm,
    title,
    loading,
    onCloseModal,
  } = props;
  // TODO: add functionality to confirm action or confirm cancelAction
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // const [showCancelModal, setShowCancelModal] = useState(false);

  // TODO: create a similar reusable utitility
  const handleError = (msg = "") => {
    // for now just to console err message
    // later will implement a proper way for custom error from compoenents
    console.log("Custom Modal Error: ", msg.toString());
  };

  useEffect(() => {
    return () => {
      if (onCloseModal) {
        onCloseModal();
      }
    };
  }, []);

  // action button clicked
  const handleOnClickAction = () => {
    if (confirmAction) {
      setShowConfirmModal(true);
    }
    if (!confirmAction) {
      if (_.isFunction(onClickAction)) {
        onClickAction();
      }
    }
  };

  const _handleConfirmAction = isActionConfirmed => {
    if (isActionConfirmed && _.isFunction(onClickAction)) {
      onClickAction();
    }

    if (!isActionConfirmed) {
      setShowConfirmModal(false);
    }
  };

  // close button clicked
  const _handleCloseBtn = () => {
    if (_.isFunction(modal?.hideModal)) {
      modal.hideModal();
    } else {
      handleError("please provide an instance of useCustomModal");
    }
  };

  const closeBtn = <FaRegWindowClose className="mt-2 text-danger close-btn" onClick={_handleCloseBtn} />;

  return (
    <Modal
      size={sm ? "sm" : "lg"}
      isOpen={modal?.isOpen || isOpen}
      toggle={modal?.toggleModal || handleModalToggle}
      className="modal-position"
      contentClassName="overview-modal"
    >
      <OverlayLoader active={loading}>
        <CustomModalHeader
          close={closeBtn}
          toggle={modal?.toggleModal || handleModalToggle}
          title={title || "Untitled Modal"}
          component={headerComponent || undefined}
        />
        <ModalBody>
          {children ? children : <LegacyEmptyState message="Empty Modal" />}
          {confirmAction ? (
            // confirm modal
            <ConfirmModal
              onConfirm={_handleConfirmAction}
              disableConfirm={actionDisabled}
              isOpen={showConfirmModal}
              message={confirmActionMessage}
            />
          ) : // this same component but different props for cancel modal
          null}
          {/* {confirmCancel ? (
            <ConfirmModal onConfirm={_handleConfirmCancel} isOpen={showCancelModal} message={confirmCancelMessage} />
          ) : null} */}
        </ModalBody>
        <CustomModalFooter
          loading={loading}
          actionLabel={actionLabel}
          actionType={actionType}
          cancelLabel={cancelLabel}
          onClickAction={handleOnClickAction}
          actionDisabled={actionDisabled}
          actionComponent={actionComponent}
          noAction={noAction}
          modal={modal}
        />
      </OverlayLoader>
    </Modal>
  );
};

CustomModal.propTypes = CustomModalPropTypes;
CustomModal.defaultProps = CustomModalDefaultProps;

export default CustomModal;
