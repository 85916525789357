import React, { useState, useMemo, useEffect, useRef } from "react";
import { PageHeader, PageWrapper, Panel, Divider, VisibilityWrapper, FormGridHeader } from "components/layout";

import { Badge, Button, Flex, Icon, Text, Box, TextBox } from "organic";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { appGet, formatDate, queryStringToObject } from "utils";

import { useAllAdminData, useAppRoles } from "hooks/app";
import { Modal, ModalContent } from "components/modal";
import { ConfirmDialog } from "components/confirmDialog";
import { useDisclosure } from "hooks";
import { useGetActualTradeByTradeId, useGetSingleTradeByTradeId, useUpdateTrade } from "hooks/app/marketplace";
import { handleFormSubmission } from "services/formSubmissionService";
import numeral from "numeral";
import { tradeStatuses } from "../marketplaceTradePage";

const ESTIMATED_TRADE = "estimatedTrade";
const ACTUAL_TRADE = "actualTrade";

const TradeReconciliationDetails = () => {
  const { tradeId } = useParams();

  const [searchParams, __] = useSearchParams();

  const { data: actualTrade, isLoading } = useGetActualTradeByTradeId({ params: { tradeId } });
  const { data: estimatedTrade, __isLoading } = useGetSingleTradeByTradeId({ params: { tradeId } });

  const [activeTopTab, setAcitveTopTab] = useState(ESTIMATED_TRADE);

  const { getSingleAdmin } = useAllAdminData();

  const [__createdBy, setCreatedBy] = useState();

  const navigate = useNavigate();

  const confirmApproveModal = useDisclosure();
  const createdByRef = useRef(null);

  const mode = useMemo(() => {
    const options = queryStringToObject(searchParams);
    return appGet(options, "mode", "view");
  }, [searchParams]);

  const isActiveTopTab = tabId => {
    if (activeTopTab === tabId) {
      return true;
    } else {
      return false;
    }
  };

  const appRoles = useAppRoles();
  const { mutateAsync } = useUpdateTrade();

  const handleTopTabChange = (e, tabId) => {
    e.preventDefault();
    if (activeTopTab === tabId) {
      return;
    }
    setAcitveTopTab(tabId);
  };

  const handleCloseEstimatedTrade = async values => {
    console.log("triggered", values);
    const trade = estimatedTrade;

    const _values = {
      trade_id: appGet(trade, "trade_id", ""),
      initiated_by: appGet(trade, "initiated_by", ""),
      approved_by: appGet(trade, "approved_by", ""),
      crop_type: appGet(trade, "crop_type", ""),
      cost_price: numeral(appGet(trade, "cost_price", "")).format("0.00"),
      selling_price: numeral(appGet(trade, "selling_price", "")).format("0.00"),
      anticipated_profit: numeral(appGet(trade, "anticipated_profit", "")).format("0.00"),
      profit_margin: numeral(appGet(trade, "profit_margin", "")).format("0.00"),
      logistics_type: appGet(trade, "logistics_type", []).map(type => ({
        name: appGet(type, "name", ""),
        unit: numeral(appGet(type, "unit", "")).format("0.00"),
        price_per_unit: numeral(appGet(type, "price_per_unit", "")).format("0.00"),
        amount: numeral(appGet(type, "amount", "")).format("0.00"),
        id: appGet(type, "_id", ""),
      })),
      logistics_cost: numeral(appGet(trade, "logistics_cost", "")).format("0.00"),
      target_quantity: numeral(appGet(trade, "target_quantity", "")).format("0.00"),
      price_to_pay: numeral(appGet(trade, "price_to_pay", "")).format("0.00"),
      commission_fee: numeral(appGet(trade, "commission_fee", "")).format("0.00"),
      total_investment: numeral(appGet(trade, "total_investment", "")).format("0.00"),
      trade_finance_fee: numeral(appGet(trade, "trade_finance_fee", "")).format("0.00"),
      currency: appGet(trade, "currency", ""),
      status: tradeStatuses.CLOSED,
    };

    await handleFormSubmission({
      submitFn: mutateAsync,
      values: {
        id: estimatedTrade._id,
        data: _values,
      },
      successMsg: "trade closed succesfully",
      errorMsg: "failed to close trade, kindly try again",
      onSuccess() {
        navigate("/dashboard/marketplace/marketplaceReconciliation");
      },
    });
  };

  useEffect(() => {
    if (createdByRef?.current) {
      setCreatedBy(createdByRef.current);
      return;
    }
    if (actualTrade && !createdByRef?.current) {
      const user = getSingleAdmin(appGet(actualTrade, "initiated_by", ""));
      console.log({ user });
      createdByRef.current = user;
      setCreatedBy(user);
    }
  }, [actualTrade, getSingleAdmin]);

  return (
    <PageWrapper>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
        <PageHeader title="Marketplace > Trade" subtitle="Your trade summary and activity." />
      </Flex>
      <Panel
        bodyBg="transparent"
        headerElement={
          <TradeReconciliationDetailsHeader
            onClickAction={() => navigate(-1)}
            name={appGet(actualTrade, "trade_id", "--")}
            isLoading={isLoading}
            mode={mode}
          />
        }
      >
        <Flex flexDirection="column" gap="1rem" bg="white" p="24px">
          <Flex width="100%" gap="1rem">
            <TextBox width="100%" label="Crop Type" value={actualTrade?.crop_type} />
            <TextBox width="100%" label="Last Updated" value={formatDate(actualTrade?.updated_at)} />
          </Flex>
          <div className="inner-tabs">
            <div className="inner-tabs__header">
              <Button
                variant="unstyled"
                className="inner-tabs__trigger"
                data-status={isActiveTopTab(ESTIMATED_TRADE) ? "active" : "inactive"}
                onClick={e => handleTopTabChange(e, ESTIMATED_TRADE)}
              >
                <Text font="smMedium" color={activeTopTab === ESTIMATED_TRADE ? "gray700" : "gray500"}>
                  Estimated Trade
                </Text>
              </Button>

              <Button
                className="inner-tabs__trigger"
                variant="unstyled"
                data-status={isActiveTopTab(ACTUAL_TRADE) ? "active" : "inactive"}
                onClick={e => handleTopTabChange(e, ACTUAL_TRADE)}
              >
                <Text font="smMedium" color={activeTopTab === ACTUAL_TRADE ? "gray700" : "gray500"}>
                  Actual Trade
                </Text>
              </Button>
            </div>
          </div>
          <Divider my="2rem" />
          <div className="inner-tabs__body">
            <VisibilityWrapper width="100%" isVisible={activeTopTab === ESTIMATED_TRADE} display="block">
              {/* {error || isLoading || !data ? (
                <EmptyState title="Something went wrong" subtitle="Unable to fetch trade details, Trying again..." />
              ) : ( */}
              <Box>
                <Box>
                  <Flex flexDirection="column" width="100%" gap="1rem">
                    <Flex width="100%" gap="1rem">
                      <TextBox width="100%" label="Selling Price" value={estimatedTrade?.selling_price} />
                      <TextBox width="100%" label="Cost Price" value={estimatedTrade?.cost_price} />
                    </Flex>
                    <Flex width="100%" gap="1rem">
                      <TextBox width="100%" label="Profit Margin (%)" value={estimatedTrade?.profit_margin} />
                      <TextBox width="100%" label="Anticipated Profit" value={estimatedTrade?.anticipated_profit} />
                    </Flex>
                    <FormGridHeader>Logistics Type</FormGridHeader>

                    {estimatedTrade?.logistics_type?.length ? (
                      <>
                        {estimatedTrade?.logistics_type.map((logistics, index) => (
                          <Flex width="100%" gap="1rem" my={1} key={index}>
                            <TextBox width="100%" label={index === 0 ? "Logistics Type" : ""} value={logistics.name} />
                            <TextBox width="100%" label={index === 0 ? "Unit" : ""} value={logistics.unit} />
                            <TextBox width="100%" label={index === 0 ? "Price" : ""} value={logistics.price_per_unit} />
                            <TextBox width="100%" label={index === 0 ? "Amount" : ""} value={logistics.amount} />
                          </Flex>
                        ))}
                      </>
                    ) : (
                      <>
                        <Box mx={4}>
                          <Text font="smRegular" color="gray500">
                            No Logistics Type
                          </Text>
                        </Box>
                      </>
                    )}

                    <Flex width="100%" gap="1rem">
                      <TextBox width="100%" label="Target Qty(Kg)" value={estimatedTrade?.target_quantity} />

                      <TextBox width="100%" label="Price to Pay Farmers" value={estimatedTrade?.price_to_pay} />
                    </Flex>
                    <Flex width="100%" gap="1rem">
                      <TextBox width="100%" label="Trade Revenue " value={estimatedTrade?.trade_finance_fee || 0} />

                      <TextBox width="100%" label="Agency Commission" value={estimatedTrade?.commission_fee || 0} />
                    </Flex>

                    {/* Trade finance and total investment */}
                    <Flex width="100%" gap="1rem">
                      <TextBox width="100%" label="Total Investment" value={estimatedTrade?.total_investment || 0} />
                    </Flex>
                  </Flex>
                </Box>
              </Box>
              {/* )} */}
              <Flex justifyContent="flex-end" mt="2rem">
                {appRoles?.isRole("commercialSpecialist") || appRoles.isRole("regionalManager") ? (
                  <Text font="smRegular" color="gray500">
                    Go to actual trade tab above to close trade
                  </Text>
                ) : (
                  <Text font="smRegular" color="gray500">
                    only regional manager and commercial specialist can perform actions here
                  </Text>
                )}
              </Flex>
            </VisibilityWrapper>

            <VisibilityWrapper width="100%" isVisible={activeTopTab === ACTUAL_TRADE} display="block">
              <Box>
                <Flex flexDirection="column" width="100%" gap="1rem">
                  <Flex width="100%" gap="1rem">
                    <TextBox width="100%" label="Selling Price" value={actualTrade?.selling_price} />
                    <TextBox width="100%" label="Cost Price" value={actualTrade?.cost_price} />
                  </Flex>
                  <Flex width="100%" gap="1rem">
                    <TextBox width="100%" label="Profit Margin (%)" value={actualTrade?.profit_margin} />
                    <TextBox width="100%" label="Anticipated Profit" value={actualTrade?.anticipated_profit} />
                  </Flex>
                  <FormGridHeader>Logistics Type</FormGridHeader>

                  {actualTrade?.logistics_type?.length ? (
                    <>
                      {actualTrade?.logistics_type.map((logistics, index) => (
                        <Flex width="100%" gap="1rem" my={1} key={index}>
                          <TextBox width="100%" label={index === 0 ? "Logistics Type" : ""} value={logistics.name} />
                          <TextBox width="100%" label={index === 0 ? "Unit" : ""} value={logistics.unit} />
                          <TextBox width="100%" label={index === 0 ? "Price" : ""} value={logistics.price_per_unit} />
                          <TextBox width="100%" label={index === 0 ? "Amount" : ""} value={logistics.amount} />
                        </Flex>
                      ))}
                    </>
                  ) : (
                    <>
                      <Box mx={4}>
                        <Text font="smRegular" color="gray500">
                          No Logistics Type
                        </Text>
                      </Box>
                    </>
                  )}
                  <Flex width="100%" gap="1rem">
                    <TextBox
                      width="100%"
                      label="Actual Quantity Sourced (KG) "
                      value={actualTrade?.actual_quantity_sourced}
                    />

                    <TextBox
                      width="100%"
                      label="Actual Quantity Rejected (KG) "
                      value={actualTrade?.actual_quantity_rejected}
                    />
                  </Flex>
                  <Flex width="100%" gap="1rem">
                    <TextBox width="100%" label="Target Qty(Kg)" value={actualTrade?.target_quantity} />

                    <TextBox width="100%" label="Price to Pay Farmers" value={actualTrade?.price_to_pay} />
                  </Flex>
                  <Flex width="100%" gap="1rem">
                    <TextBox width="100%" label="Trade Revenue " value={actualTrade?.trade_finance_fee || 0} />

                    <TextBox width="100%" label="Agency Commission" value={actualTrade?.commission_fee || 0} />
                  </Flex>

                  {/* Trade finance and total investment */}
                  <Flex width="100%" gap="1rem">
                    <TextBox width="100%" label="Total Investment" value={actualTrade?.total_investment || 0} />
                  </Flex>
                </Flex>
              </Box>
              <VisibilityWrapper
                isVisible={appRoles?.isRole("commercialSpecialist") || appRoles.isRole("regionalManager")}
                justifyContent="flex-end"
                gap="1rem"
                my="1rem"
                width="100%"
                placeholder={
                  !appRoles?.isRole("commercialSpecialist") || !appRoles?.isRole("regionalManager")
                    ? "Only commercial specialist and regional manager can close a trade"
                    : null
                }
              >
                <Button
                  type="button"
                  onClick={() => {
                    confirmApproveModal.onOpen();
                  }}
                  isDisabled={__isLoading}
                >
                  <Text color="white" textAlign="center">
                    Close Trade
                  </Text>
                </Button>
              </VisibilityWrapper>
              <Modal {...confirmApproveModal.modalProps}>
                <ModalContent variant="center" width={550}>
                  <ConfirmDialog
                    heading="Are you sure you want to conclude this trade?"
                    subHeading={`<p>
                    Closing this trade will not permit any further edits.Also, this action cannot be reversed.
                           </p>`}
                    confirmationText={`Confirm`}
                    modal={confirmApproveModal.modal}
                    onConfirm={() => {
                      handleCloseEstimatedTrade();
                    }}
                  />
                </ModalContent>
              </Modal>
            </VisibilityWrapper>
          </div>
        </Flex>
      </Panel>
    </PageWrapper>
  );
};

const TradeReconciliationDetailsHeader = ({ onClickAction, name, isLoading }) => {
  return (
    <Flex alignItems="center" gap="1rem">
      <Button type="button" variant="unstyled" onClick={onClickAction}>
        <Icon icon="arrow-left" color="gray700" size={20} />
      </Button>
      <Text as="span" font="lgMedium" color="gray900">
        Back to Trade Reconciliation
      </Text>
      <Badge as="span" colorScheme="blue">
        Viewing{isLoading ? "Loading Trade Details..." : `ID ${name}`}
      </Badge>
    </Flex>
  );
};

export default TradeReconciliationDetails;
