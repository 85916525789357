/* eslint-disable no-unused-vars */
import { Table } from "components/table";
import {
  getAllFarmerPaymentsWithParams,
  getFarmerPaymentDateRange,
  searchAllFarmerPaymentsWithParams,
} from "features/v1/farmerPayment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRiskFarmerTable from "./useRiskFarmerTable";
import { useDisclosure } from "hooks";
import { Modal, ModalContent } from "components/modal";
import ViewFarmerSurvey from "../riskSurvey/viewFarmerSurvey";

const Farmers = () => {
  const viewFarmerSurveyDisclosure = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tableProps, selectedRow: selectedFarmer } = useRiskFarmerTable();

  const getExtraActions = payment => {
    const paymentExtraActions = [
      // { label: "Edit details", actionKey: "editDetails" },
      { label: "View", actionKey: "viewFarmerSurvey" },
      // { label: "Go to agents", actionKey: "viewAgents" },
    ];

    return paymentExtraActions;
  };

  return (
    <>
      <Table
        {...tableProps}
        onSearch={(params, { abortController }) => {
          dispatch(
            searchAllFarmerPaymentsWithParams(
              { ...params },
              { abortController } // cancel token
            )
          );
        }}
        onDataFilter={({ seasonId, region, dateRange, nPerPage, page }) => {
          dispatch(getFarmerPaymentDateRange(dateRange.map(d => new Date(d).toISOString())));
          dispatch(
            getAllFarmerPaymentsWithParams({
              nPerPage,
              page,
              dateRange,
            })
          );
        }}
        onExtraAction={({ actionKey }) => {
          if (actionKey === "viewFarmerSurvey") {
            viewFarmerSurveyDisclosure.onOpen();
          }
          // if (actionKey === "reject") {
          //   rejectionCommentModal.onOpen();
          // }
          // if (actionKey === "payout") {
          //   confirmPayoutModal.onOpen();
          // }
          // if (actionKey === "decline") {
          //   declinePaymentModal.onOpen();
          // }
        }}
        getExtraActions={getExtraActions}
      />
      <Modal {...viewFarmerSurveyDisclosure.modalProps}>
        <ModalContent title="View Farmers Survey">
          <ViewFarmerSurvey
            closeModal={() => viewFarmerSurveyDisclosure.onClose()}
            modal={viewFarmerSurveyDisclosure.modal}
            selected={selectedFarmer}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default Farmers;
