import React from "react";
import { Button, Flex, Icon, Text } from "organic";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import warningIcon from "assets/icons/warning-icon.svg";
import { classnames, isFunction } from "utils";

const AlertDialog = React.forwardRef(
  (
    { heading, subHeading, confirmationText, title, variant, onSuccess, onAbort, disclosure, width, ...props },
    forwardedRef
  ) => {
    const handleSuccess = () => {
      if (isFunction(onSuccess)) {
        onSuccess();
      }
      disclosure.onClose();
    };
    const handleAbort = () => {
      if (isFunction(onAbort)) {
        onAbort();
      }
      disclosure?.onClose();
    };
    const contentClassnames = classnames("dialog-content dialog-content--center", {});
    return (
      <DialogPrimitive.Root {...disclosure.modalProps}>
        <DialogPrimitive.Portal>
          <DialogPrimitive.Overlay className="dialog-overlay" />
          <DialogPrimitive.Content className={contentClassnames} {...props} ref={forwardedRef} style={{ width: width }}>
            <Flex justifyContent="space-between" px={"0rem"} py={"0rem"}>
              <Text font="displayXsMedium">{title}</Text>
              <DialogPrimitive.Close className="dialog-close">
                <Icon icon="x" size={24} />
              </DialogPrimitive.Close>
            </Flex>
            <Flex flexDirection="column" padding={"0rem"}>
              <Flex
                px="1.5rem"
                py="1.5rem"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
              >
                <img width={50} src={warningIcon} alt="reject-icon" />
                <Text font="xlMedium" mt={2}>
                  {heading}
                </Text>
                <Text font="smRegular" mt={2} color="#667085">
                  {subHeading}
                </Text>
                <Flex mt={40} style={{ gap: "20px" }} justifyContent="space-between">
                  <Button
                    px={[5, 7, 10]}
                    display="block"
                    type="button"
                    colorScheme="secondaryGray"
                    onClick={handleAbort}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSuccess} variant={variant} px={[5, 7, 10]} display="block" type="submit">
                    {confirmationText}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
      // <Modal isOpen={disclosure.isOpen} onOpenChange={isOpen => {
      //   if(!isOpen) {
      //     disclosure.onClose()
      //   }
      // }}>
      //   <ModalContent variant="center">

      //   </ModalContent>
      // </Modal>
    );
  }
);

AlertDialog.displayName = "AlertDialog";
AlertDialog.defaultProps = {
  width: 650,
};

export { AlertDialog };
