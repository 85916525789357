import { countBy } from "lodash";

export default function generateXYData(dataArray) {
  // console.time("generateXYData")
  const sliceDate = date => date.slice(0, 10);
  // let dateSet = new Set();
  let createdDates = dataArray.map(rawDatum => rawDatum.created_at).sort();
  const xYData = Object.entries(countBy(createdDates, sliceDate)).map(datum => {
    return { x: datum[0], y: datum[1] };
  });
  // console.timeEnd("generateXYData")
  return xYData;
}
