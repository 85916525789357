import { Table } from "components/table";
import { useGetTrades } from "hooks/app/marketplace";
import { Avatar, AvatarGroup, Badge, Flex, Text } from "organic";
import React from "react";
import { tradeStatuses } from "../marketplaceTradePage";
import { Modal, ModalContent } from "components/modal";
import { useDisclosure, useTable } from "hooks";
import { ConfirmDialog, RejectWithCommentDialog } from "components/confirmDialog";
import numeral from "numeral";
import { resolveMarketplaceTradeStatusColor } from "../marketplaceOverviewPage";
import { appGet, capitalize } from "utils";
import CreateTrade from "./createTrade";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppRoles } from "hooks/app";
import { TruncatedList } from "components/list";

const PendingTrades = () => {
  const { data } = useGetTrades({ params: { status: tradeStatuses.PENDING } });
  const [rejectComment, setRejectComment] = React.useState("");

  const appRoles = useAppRoles();
  const navigate = useNavigate();

  const confirmModal = useDisclosure();
  const rejectModal = useDisclosure();

  const createModal = useDisclosure();

  const { tableProps, selectedRow: selectedTrade } = useTable({
    data: data?.data ?? [],
    columns: [
      {
        Header: "ID",
        accessor: "trade_id",
      },
      {
        id: "cropType",
        Header: "Crop Type",
        accessor: "crop_type",
        Cell: ({ value }) => (
          <Text color="primary500" font="smMedium" textTransform="capitalize">
            {value}
          </Text>
        ),
      },
      {
        Header: "Cost Price",
        accessor: trade => numeral(trade.cost_price).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Selling Price",
        accessor: trade => numeral(trade.selling_price).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Logisitics Total",
        accessor: trade => numeral(trade.logistics_cost).format("0,0.00") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Target Quantitity",
        accessor: trade => numeral(trade.target_quantity).format("0,0.00"),
      },
      {
        Header: "Assigned Agents",
        accessor: "assigned_agents",
        Cell: ({ value }) => {
          return (
            <AvatarGroup>
              <TruncatedList
                items={value}
                renderItem={agent => (
                  <Avatar size="xs" key={agent._id} name={`${agent.first_name} ${agent.last_name}`} />
                )}
              />
            </AvatarGroup>
          );
        },
      },
      {
        Header: "Total Investment",
        accessor: trade => numeral(trade.total_investment).format("0,0") + " " + appGet(trade, "currency", ""),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <Badge colorScheme={resolveMarketplaceTradeStatusColor(value)}>{capitalize(value)}</Badge>,
      },
    ],
    useServerPagination: true,
    serverPaginationMeta: data?.meta || {},
    title: "All pending trades",
    noEditAction: !appRoles.isRole("commercialSpecialist") && !appRoles.isRole("regionalManager"),
  });

  return (
    <Flex flexDirection="column">
      <Table
        {...tableProps}
        createAction={!appRoles.isRole("commercialSpecialist") ? null : { label: "Create Trade" }}
        onCreateAction={() => {
          createModal.onOpen();
        }}
        onEditAction={() => {
          if (appRoles.isRole("commercialSpecialist")) {
            navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=edit`);
          } else {
            toast.info("Confirming you have permission to edit pending trades... kindly try a gain shortly");
          }
        }}
        onViewAction={() => {
          navigate(`/dashboard/marketplace/marketplaceTrade/${selectedTrade._id}?mode=view`);
        }}
      />

      {/* create trade modal */}
      <Modal {...createModal.modalProps}>
        <ModalContent title="Create Trade" width={863}>
          <CreateTrade modal={createModal.modal} />
        </ModalContent>
      </Modal>

      {/*confirm action modal  */}
      <Modal
        open={confirmModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            confirmModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={550}>
          <ConfirmDialog
            heading="Do you want to continue?"
            subHeading="<p>
            Are you sure you want to confirm payout? <br /> This action cannot be reversed
          </p>"
            confirmationText="Confirm"
            modal={confirmModal.modal}
            onConfirm={() => {
              confirmModal.modal.hideModal();
            }}
          />
        </ModalContent>
      </Modal>
      <Modal
        open={rejectModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            rejectModal.onClose();
          }
        }}
      >
        <ModalContent variant="center" width={650}>
          <RejectWithCommentDialog
            comment={rejectComment}
            setComment={setRejectComment}
            selected={selectedTrade}
            modal={rejectModal.modal}
            handleSubmit={() => {}}
          />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default PendingTrades;
