import { EmptyState } from "components/appState";
import { FormInput } from "components/inputs";
import { VisibilityWrapper } from "components/layout";
import { getLoadingCurSeasonInputs, setInputQtyPerHectareSize } from "features/v1/cropsAndInput";
import { FieldArray, Form, Formik } from "formik";
import { Button, Flex, Input, Text, buttonConstants } from "organic";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hyphenate } from "utils";
// import { setInputQtyPerHectareSizeValidationSchema } from "validators/inputsValidationSchemas";

/** ADD INPUT QTY PER HECTARE SIZE VALUES */
export const initiateSetInputQtyPerHectareSizeValues = input => {
  return { quantity_per_hectares: input?.quantity_per_hectares || [] };
};

const SetQuantityPerHectare = ({ selected, modal }) => {
  let loadingInputs = useSelector(getLoadingCurSeasonInputs);
  const dispatch = useDispatch();

  const initialSetInputQtyPerHectareValues = initiateSetInputQtyPerHectareSizeValues(selected);

  const handleSetInputQtyPerHectare = (values, formOptions) => {
    dispatch(
      setInputQtyPerHectareSize(values, {
        modal,
        formOptions,
        inputId: selected?._id,
        seasonId: selected.season,
        cropId: selected.crop,
      })
    );
  };

  const handleRemoveItem = ({ index, helper }) => {
    helper.remove(index);
  };
  return (
    <>
      <Formik
        onSubmit={handleSetInputQtyPerHectare}
        initialValues={initialSetInputQtyPerHectareValues}
        // validationSchema={setInputQtyPerHectareSizeValidationSchema}
      >
        {({ values }) => {
          console.log({ values });
          return (
            <Form>
              <Flex flexDirection="column" gap="2rem">
                <FieldArray
                  name="quantity_per_hectares"
                  render={renderHelper => (
                    <>
                      <VisibilityWrapper
                        flexDirection="column"
                        gap="1.5rem"
                        isVisible={!values?.quantity_per_hectares?.length}
                      >
                        <EmptyState title="No values set" subtitle="Click below to add new values" />
                      </VisibilityWrapper>
                      <Flex flexDirection="column" gap="2rem">
                        <Flex alignItems="center" gap="1.5rem">
                          <Input isDisabled color="gray800" value={`Quantity of ${selected?.name || "Input"}`} />
                          <Input isDisabled color="gray800" value={`Size in Hectares`} />
                          <Input isDisabled color="gray800" value="" />
                        </Flex>
                        <Flex flexDirection="column" gap="1.5rem">
                          {values?.quantity_per_hectares?.map((quantityPerHectare, quantityPerHectareIndex) => (
                            <Flex
                              gap="1rem"
                              alignItems="center"
                              // justifyContent="center"
                              key={hyphenate(["quantity_per_hectares-item", quantityPerHectareIndex])}
                            >
                              <FormInput
                                // label="Size in Hectares"
                                type="number"
                                defaultValue={quantityPerHectare?.hectare_size || undefined}
                                placeholder="Size in Hectares"
                                name={`quantity_per_hectares.${quantityPerHectareIndex}.hectare_size`}
                              />
                              <FormInput
                                // label={`Quantity of ${selected?.name || "Input"}`}
                                defaultValue={quantityPerHectare?.hectare_value || undefined}
                                placeholder={`Quantity of ${selected?.name || "Input"}`}
                                name={`quantity_per_hectares.${quantityPerHectareIndex}.hectare_value`}
                              />
                              <Button
                                onClick={() =>
                                  handleRemoveItem({ index: quantityPerHectareIndex, helper: renderHelper, values })
                                }
                                variant={buttonConstants.variants.DESTRUCTIVE}
                                colorScheme={buttonConstants.colorSchemes.LINK_COLOR}
                                leftIcon="minus-circle"
                              >
                                Remove
                              </Button>
                            </Flex>
                          ))}
                        </Flex>
                        <Flex justifyContent="flex-end">
                          <Button
                            isLoading={loadingInputs}
                            isDisabled={loadingInputs}
                            onClick={() =>
                              renderHelper.push({
                                hectare_size: "",
                                hectare_value: "",
                              })
                            }
                            leftIcon="plus-circle"
                            colorScheme={buttonConstants.colorSchemes.LINK_COLOR}
                          >
                            <Text font="smMedium">Add New Quantity Per Hectare Size</Text>
                          </Button>
                        </Flex>
                      </Flex>
                    </>
                  )}
                />
                <Button display="block" type="submit">
                  Save Changes
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SetQuantityPerHectare;
