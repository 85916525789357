import React from "react";
import filterSvg from "assets/icons/filter.svg";

import RowRange from "./rowRange";
import { Badge, Box, Button, Flex, Icon, Image, Text, badgeConstants, organicTheme } from "organic";
import { SearchInput } from "components/inputs";
import { DateRangeFilter } from "components/filter";
import { DropdownContent, DropdownMenu, DropdownTrigger } from "components/dropdown";
import { TableMultiSelectActions } from "./tableActions";
import { CreateTableAction } from "./defaultTableActions";

const TableContainer = ({
  children,
  pageIndex,
  onSearch,
  totalItems,
  pageSize,
  title,
  createAction,
  onCreate,
  noDateRangeFilter,
  noLocalFilter,
  noSearch,
  showCheckboxes,
  multiSelectedRows,
  getMultiSelectActions,
  multiSelectActions,
  onMultiSelect,
  loading,
}) => {
  return (
    <div className="table-container">
      {/* table container header */}
      <Flex flexDirection={["column", "_", "_", "row"]} gap="0.5rem" alignItems="center" justifyContent="space-between">
        <Flex>{!noSearch ? <SearchInput onChange={onSearch} /> : null}</Flex>
        <Flex flexDirection={["column", "_", "_", "_", "row"]} gap="0.5rem">
          {!noDateRangeFilter ? <DateRangeFilter /> : null}
          {!noLocalFilter ? (
            <Button colorScheme="secondaryGray">
              <Image src={filterSvg} alt="filter icon" size="20px" mr="0.5rem" />
              <p>Filter</p>
            </Button>
          ) : null}
          {createAction ? (
            <Box ml="0.5rem">
              <CreateTableAction action={createAction} onClick={onCreate} />
            </Box>
          ) : null}
        </Flex>
      </Flex>

      <div className="table-wrapper">
        <div className="table-wrapper__header">
          <Flex flexDirection={["column", "_", "_", "row"]} gap="0.5rem" justifyContent="flex-start">
            <Text font="lgMedium" color="gray900">
              {loading ? "Loading..." : title}
            </Text>
            <RowRange pageIndex={pageIndex} pageSize={pageSize} totalItems={totalItems} />
          </Flex>
          <Flex justifyContent="flex-end">
            {multiSelectedRows?.length ? (
              <>
                <Badge
                  colorScheme={badgeConstants.colorSchemes.PURPLE}
                >{`${multiSelectedRows.length} Items Selected`}</Badge>
                {showCheckboxes === true ? (
                  <DropdownMenu>
                    <DropdownTrigger>
                      <Icon icon="more-vertical" color={organicTheme.colors.gray900} size={20} />
                    </DropdownTrigger>
                    <DropdownContent unstyled>
                      <TableMultiSelectActions
                        actions={multiSelectActions}
                        rows={multiSelectedRows}
                        getter={getMultiSelectActions}
                        onSelect={onMultiSelect}
                      />
                    </DropdownContent>
                  </DropdownMenu>
                ) : null}
              </>
            ) : (
              ""
            )}
          </Flex>
        </div>
        {children}
      </div>
    </div>
  );
};

export default TableContainer;
