import React from "react";
import * as Sentry from "@sentry/react";
import { EmptyState, ErrorState } from "components/appState";

export const PageWrapper = Sentry.withProfiler(props => {
  const { children } = props;
  return (
    <div className="page-wrapper">
      <ErrorState>{children ? children : <EmptyState message="There's Nothing on this page yet" />}</ErrorState>
    </div>
  );
});
