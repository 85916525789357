/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Table } from "components/table";
import { Flex, Text, Icon, Box } from "organic";
import { useDisclosure, useTable } from "hooks";
import { useGetManageModule, useDeleteModule } from "hooks/app/manageModule";

import { Modal, ModalContent } from "components/modal";
import { uniqueId } from "utils";
import CreateModule from "./createModule";
import { EditModule } from "./editModule";
import { mapModuleIdsToLabels } from "./mapIdsToLabels";
import ManageModuleDetails from "./manageModuleDetails";
import { handleFormSubmission } from "services/formSubmissionService";
import { toast } from "react-toastify";

const ManageModulePage = () => {
  const createModuleModal = useDisclosure();
  const editModuleModal = useDisclosure();

  const [onView, setOnView] = useState(false);

  const { data: manageModuleData, isloading: ismanageModuleLoading } = useGetManageModule({
    params: {},
  });

  const { tableProps, selectedRow: selectedModule } = useTable({
    columns: [
      {
        id: "projectID",
        Header: (
          <Flex flexDirection="row" gap="4px" alignItems="center">
            <Text color="gray500" font="smSemiBold">
              Project/Season
            </Text>
            <Icon icon={"arrow-down"} size={16}></Icon>
          </Flex>
        ),
        accessor: "season.name",
        width: 100,
        Cell: ({ value }) => (
          <Text color="gray900" font="smSemiBold">
            {value}
          </Text>
        ),
      },
      {
        idFunction: uniqueId(),
        id: "activeModules",
        Header: "Active Modules",
        accessor: "modules",
        width: 180,
        Cell: ({ value }) => (
          <Flex flexDirection="row" gap="4px">
            {value.slice(0, 2).map((module, index) => (
              <Box key={index} background="#F2F4F7" borderRadius="16px" p="2px 8px">
                <Text color="gray700" font="smSemiBold">
                  {mapModuleIdsToLabels(module)}
                </Text>
              </Box>
            ))}
            {value.length > 2 ? (
              <Box background="#F2F4F7" borderRadius="16px" p="2px 8px">
                <Text color="gray700" font="smSemiBold">{`+${value?.length - 2}`}</Text>
              </Box>
            ) : (
              ""
            )}
          </Flex>
        ),
      },

      {
        Header: "Selected Region",
        accessor: "region",
        Cell: ({ value }) => (
          <Flex flexDirection="row" gap="4px">
            {value.slice(0, 2).map((region, index) => (
              <Box key={index} background="#F2F4F7" borderRadius="16px" p="2px 8px">
                <Text color="gray700" font="smSemiBold">
                  {region.label}
                </Text>
              </Box>
            ))}
            {value.length > 2 ? (
              <Box background="#F2F4F7" borderRadius="16px" p="2px 8px">
                <Text color="gray700" font="smSemiBold">{`+${value?.length - 2}`}</Text>
              </Box>
            ) : (
              ""
            )}
          </Flex>
        ),
      },
      {
        Header: "Except by Group",
        accessor: "exceptGroups",
        Cell: ({ value }) => (
          <Flex flexDirection="row" gap="4px">
            {value.length > 0 ? (
              value.slice(0, 1).map((group, index) => (
                <Box key={index} background="#F2F4F7" borderRadius="16px" p="2px 8px">
                  <Text color="gray700" font="smSemiBold">
                    {group.agentName}
                  </Text>
                </Box>
              ))
            ) : (
              <Box background="#F2F4F7" borderRadius="16px" p="2px 8px">
                <Text color="gray700" font="smSemiBold">
                  ---
                </Text>
              </Box>
            )}
            {value.length > 1 ? (
              <Box background="#F2F4F7" borderRadius="16px" p="2px 8px">
                <Text color="gray700" font="smSemiBold">{`+${value.length - 1}`}</Text>
              </Box>
            ) : (
              ""
            )}
          </Flex>
        ),
      },
    ],

    data: manageModuleData?.data.map(data => data) || [],
    title: "All modules",
    useServerPagination: true,
    noEditAction: false,
    showCheckboxes: true,
  });

  const { mutateAsync: deleteModuleAsync } = useDeleteModule(selectedModule?._id);

  const handleDelete = async () => {
    await handleFormSubmission({
      submitFn: deleteModuleAsync,
      values: {},
      config: {},
      errorMsg: "Failed to delete module",
      successMsg: "",
      onSuccess() {
        toast.success("Module deleted");
      },
      onError(error) {
        console.log({ error });
      },
    });
  };

  return (
    <Flex>
      {!onView && (
        <Table
          {...tableProps}
          createAction={{ label: "New Module" }}
          onCreateAction={() => {
            createModuleModal.onOpen();
          }}
          canDelete
          onViewAction={() => {
            setOnView(true);
          }}
          onEditAction={(selectedModule, { filterParams }) => {
            editModuleModal.onOpen();
          }}
          onDeleteAction={handleDelete}
        />
      )}
      {onView && <ManageModuleDetails setOnView={setOnView} selectedModule={selectedModule} />}

      <Modal {...createModuleModal.modalProps}>
        <ModalContent title="New Module">
          <CreateModule modal={createModuleModal.modal} />
        </ModalContent>
      </Modal>

      <Modal {...editModuleModal.modalProps}>
        <ModalContent title={`Edit Module`}>
          <EditModule selected={selectedModule} modal={editModuleModal.modal} />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ManageModulePage;
