import numeral from "numeral";
import { Badge, Box, Flex, Icon, Text, badgeConstants } from "organic";
import React from "react";

const MessageCounter = ({ message = "" } = { message: "" }) => {
  return (
    <Box>
      <Flex gap="20px">
        <Flex alignItems="center" gap="0.5rem">
          <Icon icon="info" size={16} />
          <Text font="smRegular" color="gray500">
            Characters count
          </Text>
          <Badge colorScheme={badgeConstants.colorSchemes.GRAY}>{numeral(message.length).format("0")}/ 160</Badge>
        </Flex>
        <Flex alignItems="center" gap="0.5rem">
          <Icon icon="info" size={16} />
          <Text font="smRegular" color="gray500">
            SMS count
          </Text>
          <Badge colorScheme={badgeConstants.colorSchemes.GRAY}>
            {numeral(numeral(message.length).divide(160).value()).add(1).format("0")}
            {/* {Number(Number(values?.content?.length / 160) + Number(1)).toFixed(0)} */}
          </Badge>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MessageCounter;
