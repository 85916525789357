import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLegacyDidMountEffect } from "hooks";
import { EmptyState } from "components/appState";
import { Box, Flex, Text } from "organic";

const AdvancedExportMetasListPropTypes = {
  exportKeys: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

const AdvancedExportMetasList = props => {
  const { exportKeys = [], onChange = () => {} } = props;
  // advanced downlaod
  const [selectedMeta, setSelectedMeta] = useState(exportKeys);

  const _selectMeta = meta => {
    setSelectedMeta(oldMetasList => oldMetasList.concat(meta));
    return;
  };
  const _unslectMeta = meta => {
    setSelectedMeta(oldMetasList => oldMetasList.filter(item => item !== meta));
    return;
  };

  const _handleChange = e => {
    if (e.target.checked) {
      _selectMeta(e.target.name);
    }
    if (!e.target.checked) {
      _unslectMeta(e.target.name);
    }
    return;
  };

  useEffect(() => {
    onChange(exportKeys);
  }, []);

  useLegacyDidMountEffect(() => {
    onChange(selectedMeta);
  }, [selectedMeta]);

  return (
    <>
      <Text font="smMedium" color="gray500">
        Scroll on the list of metas to see more
      </Text>
      <Flex flexDirection="row" flexWrap="wrap" overflowY="auto" height="60vh" border="1px solid #e4e4e4">
        {/* {exportMetas?.length && exportMetas?.metaData
          ? exportMetas?.metaData.map((item, index) => (
              <Col key={index.toString()} sm={6}>
                <ExportMetaListItem onChange={_handleChange} item={item} />
              </Col>
            ))
          : exportMetas.map((item, index) => (
              <Col key={index.toString()} sm={6}>
                <ExportMetaListItem onChange={_handleChange} item={item} />
              </Col>
            ))} */}
        {exportKeys?.length ? (
          exportKeys.map((item, index) => (
            <Flex width="50%" key={index.toString()}>
              <AdvancedExportMetasListItem onChange={_handleChange} item={item} />
            </Flex>
          ))
        ) : (
          <Box height="100%">
            <EmptyState message="No Columns to Select, Export will return all columns" />
          </Box>
        )}
      </Flex>
    </>
  );
};

const AdvancedExportMetasListItemPropsTypes = {
  onChange: PropTypes.func,
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const AdvancedExportMetasListItem = ({ onChange = () => {}, item }) => (
  <div
    style={{
      // borderTop: '2px',
      // borderTopStyle: 'solid',
      // borderTopColor: '#B5B5B5',
      // maxWidth: "300px",
      margin: "0px 20px 10px 20px",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // maxWidth: "250px",
        justifyContent: "flex-start",
        margin: "10px 20px 10px 20px",
      }}
    >
      <div>
        <input type="checkbox" id={item.name} name={item?.name || item || "N/A"} onChange={onChange} defaultChecked />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: "0.8re",
        }}
      >
        {/* <img src={menuIcon} alt="bars" /> */}
        <span
          style={{
            position: "relative",
            left: "28px",
          }}
        >
          {item?.name || item || "N/A"}
        </span>
      </div>
    </div>
  </div>
);

AdvancedExportMetasList.propTypes = AdvancedExportMetasListPropTypes;
AdvancedExportMetasListItem.propTypes = AdvancedExportMetasListItemPropsTypes;

export { AdvancedExportMetasList };
