/**
 * @function sanitize
 * @param {Object} values - Object whose props you want to delete
 * @param {number} numberOfDeletes - number of props to delete
 * @param {array} propsToDelete - list of props to be deleted by name
 * @returns {Object} sanitized Object
 */
export const sanitize = (values, numberOfDeletes, propsToDelete) => {
  if (typeof values !== "object") {
    return;
  }
  const objectToSanitize = { ...values };
  let itemsToDelete;
  if (numberOfDeletes) {
    itemsToDelete = numberOfDeletes;
  } else {
    itemsToDelete = 1;
  }
  for (let i = 0; i < itemsToDelete; i++) {
    if (Object.prototype.hasOwnProperty.call(objectToSanitize, propsToDelete[i])) {
      delete objectToSanitize[propsToDelete[i]];
    }
  }

  return objectToSanitize;
};

export const remove_id = obj => {
  if (obj?._id) delete obj._id;

  return obj;
};
export const remove__v = obj => {
  if (obj?._id) delete obj.__v;

  return obj;
};
/**
 * a function to remove all the fields in  a mongoDB doc that are not required
 * for making an update
 *
 * @param  {Object} doc a mongoDB document that needs to be sanitised for update
 * @param  {Array} extraFields (optional) if there are some extra fields in an original doc
 *                              you don't want to send back to the server for an update
 *                              add then name of the field as a string in the extrFields array
 */
export const sanitizeMongoDoc = (doc, extraFields = []) => {
  if (extraFields.length > 0) {
    extraFields.forEach(field => {
      // if (doc[`${field}`])
      if (doc[`${field}`]) delete doc[`${field}`];
    });
  }
  if (doc?._id) delete doc._id;
  if (doc?.__v || doc?.__v === 0) delete doc.__v;
  if (doc?.created_at) delete doc.created_at;
  if (doc?.updated_at) delete doc.updated_at;
  if (doc?.onboarded_at) delete doc.onboarded_at;
  if (doc?.password_hash) delete doc.password_hash;
  if (doc?.verifyToken) delete doc.verifyToken;
  return doc;
};

export const removeObjProps = (obj, props = []) => {
  if (props.length > 0) {
    props.forEach(prop => {
      if (obj[`${prop}`]) delete obj[`${prop}`];
    });
  }
  return obj;
};
