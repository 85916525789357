import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import { props, createShouldForwardProp } from "@styled-system/should-forward-prop";
import _typography from "organic/theme/mixins/_typography";
import { NavLink } from "react-router-dom";
import { Icon, Flex, Box, Text } from "organic";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "components/modal";

// props
const shouldForwardProp = createShouldForwardProp([...props, "iconOnly", "current", "toggle"]);
/**
 * Nav Item child component of Nav Styled Component
 */
export const BaseNavItem = styled(NavLink).withConfig({
  shouldForwardProp,
})`
  /* base style*/
  ${props => css`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content space-between;
    border-radius: 6px;
    width: 100%;
    min-height: 40px;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: transparent;
    ${Text} {
      color: ${props.theme.colors.gray700};
      ${_typography.mdMedium}
      margin-left: 14px;
    }
    svg {
      stroke: ${props.theme.colors.gray500};
    }

    &:hover {
      background-color: ${props.theme.colors.primary50};
      color: ${props.theme.colors.primary500};
      ${Text} {
        color: ${props.theme.colors.primary500};
      }
      svg {
        stroke: ${props.theme.colors.primary700};
      }
    }

    &.active {
      background-color: ${props.theme.colors.primary50};
      color: ${props.theme.colors.primary500};
      ${Text} {
        color: ${props.theme.colors.primary500};
      }
      svg {
        stroke: ${props.theme.colors.primary700};
      }
    }

    /* when iconOnly is true */
    ${
      props.iconOnly === true
        ? css`
            justify-content: center;
            ${Text} {
              display: none;
            }
          `
        : ""
    }
    /* when iconOnly is true */
    ${
      props.current === true
        ? css`
            background-color: ${props.theme.colors.primary50};
            color: ${props.theme.colors.primary500};
            ${Text} {
              color: ${props.theme.colors.primary500};
            }
            svg {
              stroke: ${props.theme.colors.primary700};
            }
          `
        : ""
    }
  `}
`;

const NavToggleButton = styled.button`
  ${props => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content space-between;
    border-radius: 6px;
    border:none;
    outline:none;
    width: 100%;
    min-height: 40px;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 0px;
    cursor: pointer;
    background-color: transparent;
    color: ${props.theme.colors.gray700};
    & ${Flex} ${Text} {
      color: ${props.theme.colors.gray700};
      margin-left: 14px;
      ${_typography.mdMedium}
    }
    &:hover {
      background-color: ${props.theme.colors.primary50};
      color: ${props.theme.colors.primary500};
      ${Flex} ${Text} {
        color: ${props.theme.colors.primary500};
      }
      svg {
        stroke: ${props.theme.colors.primary700};
      }
    }
    &:focus {
      background-color: ${props.theme.colors.primary50};
      color: ${props.theme.colors.primary500};
      ${Text} {
        color: ${props.theme.colors.primary500};
      }
      svg {
        stroke: ${props.theme.colors.primary700};
      }
    }
    &:disabled {
      background-color: #b5f2a0;
      color: ${props.theme.colors.primary500};
      ${Text} {
        color: ${props.theme.colors.primary500};
      }
      svg {
        stroke: ${props.theme.colors.primary700};
      }
    }
    ${
      props.current === true
        ? css`
            background-color: ${props.theme.colors.primary50};
            color: ${props.theme.colors.primary500};
            & ${Flex} ${Text} {
              color: ${props.theme.colors.primary500};
            }
            svg {
              stroke: ${props.theme.colors.primary700};
            }
          `
        : ""
    }
    `}
`;

const NAV_ITEM_COLLAPPSED_ICON = "chevron-down";
const NAV_ITEM_EXPANDED_ICON = "chevron-up";

const NavItem = props => {
  const _icon = props?.icon && !props?.textOnly ? <Icon type="feather" icon={props.icon} /> : null;
  const [toggleCollapsed, setToggleCollpsed] = useState(true);
  let toggleIcon = toggleCollapsed ? NAV_ITEM_COLLAPPSED_ICON : NAV_ITEM_EXPANDED_ICON;

  const handleToggleClicked = () => {
    setToggleCollpsed(!toggleCollapsed);
  };
  const ignoredhandleToggleMouseOver = () => {
    if (toggleCollapsed) {
      setToggleCollpsed(false);
    }
  };
  // const handleToggleMouseOut = () => {
  //   if(!toggleCollapsed) {
  //       setToggleCollpsed(true)
  //   }
  // }

  if (props?.toggle === true) {
    // toggle nested navItems and navigate
    let showTogglePopout = props.iconOnly;
    return (
      <>
        {/* navItem serves as wrapper for subNav */}
        {!props?.iconOnly ? (
          <>
            <NavToggleButton current={props?.current} width="100%" onClick={handleToggleClicked}>
              <Flex
                flexDirection="row"
                alignItems="center"
                px="0.75rem"
                width="100%"
                justifyContent={props?.iconOnly ? "center" : "flex-start"}
              >
                {props?.icon ? _icon : null}
                {!props.iconOnly ? (
                  <Text ml="14px" as="span">
                    {props?.name}{" "}
                  </Text>
                ) : null}
              </Flex>
              {!props?.iconOnly ? <Icon type="feather" icon={toggleIcon} /> : null}
            </NavToggleButton>
            <Box
              pl={[0, 0, ""]}
              display={showTogglePopout ? "none" : "block"}
              height={toggleCollapsed ? "0px" : "100%"}
              overflow={toggleCollapsed ? "hidden" : "visible"}
            >
              {(props?.children && props.children) || null}
            </Box>{" "}
          </>
        ) : (
          <HoverCard openDelay={0}>
            <HoverCardTrigger>
              <NavToggleButton current={props?.current} width="100%" onClick={handleToggleClicked}>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  px="0.75rem"
                  width="100%"
                  justifyContent={props?.iconOnly ? "center" : "flex-start"}
                >
                  {props?.icon ? _icon : null}
                  {!props.iconOnly ? (
                    <Text ml="14px" as="span">
                      {props?.name}{" "}
                    </Text>
                  ) : null}
                </Flex>
                {!props?.iconOnly ? <Icon type="feather" icon={toggleIcon} /> : null}
              </NavToggleButton>
            </HoverCardTrigger>
            <HoverCardContent unstyled side="right" sideOffset={10} align="start" alignOffset={128}>
              <Box zIndex={90}>{(props?.children && props.children) || null}</Box>
            </HoverCardContent>
          </HoverCard>
        )}
      </>
    );
  }
  return (
    <BaseNavItem {...props}>
      <Flex flexDirection="row" alignItems="center" px="0.75rem">
        {props?.icon ? _icon : null}
        <Text as="span">{!props?.iconOnly ? props.name : null}</Text>
      </Flex>
    </BaseNavItem>
  );
};
const defaultNavItemProps = {
  current: null, // Current
  toggle: null, // Toggle
  iconOnly: null, // Icon
  textOnly: null,
  name: "",
  icon: null,
};

NavItem.defaultProps = defaultNavItemProps;

export default NavItem;
