import { Nav, Text } from "organic";
import React, { forwardRef, useCallback, useMemo } from "react";
import { hyphenate } from "utils";
import SidebarNavItem from "./sidebarNavItem";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSidebarExpanded } from "features/v1/dashboard";
import { Tooltip } from "components/modal";

const SidebarNav = forwardRef(({ filterRoutes, routes, ignoreChildren }, forwardedRef) => {
  const _filterRoutesCallback = useCallback(filterRoutes, [routes]);

  const sidebarExpanded = useSelector(getSidebarExpanded);

  const location = useLocation();

  const isCurrentParentRoute = (route = { children: [] }) => {
    let _isCurrentParent = false;
    const currentPagePath = location.pathname.slice(location.pathname.lastIndexOf("/") + 1);
    if (Array.isArray(route.children)) {
      _isCurrentParent = route.children.findIndex(child => child?.path === currentPagePath) > -1;
    }
    return _isCurrentParent;
  };

  const filteredRoutes = useMemo(() => {
    return routes.filter(unfilteredRoute => _filterRoutesCallback(unfilteredRoute));
  }, [_filterRoutesCallback, routes]);

  const hasSubmenu = navItem => {
    return Object.prototype.hasOwnProperty.call(navItem, "children");
  };

  const removeAsterix = path => path.replace("*", "");

  return (
    <Nav ref={forwardedRef}>
      {filteredRoutes.map((route, routeIndex) => {
        if (route.invisible) return null;

        let _routeKey = hyphenate(["sidebar", "nav-item", routeIndex]);

        if (hasSubmenu(route)) {
          let parentRoutePath = removeAsterix(route.path);

          if (route.children.every(childRoute => childRoute?.invisible)) {
            return (
              <Tooltip
                key={_routeKey}
                content={
                  <Text color="white" font="smMedium">
                    {route.name}
                  </Text>
                }
                openOnHover={!sidebarExpanded}
                colorScheme="green"
                side="right"
                sideOffset={10}
                align={route?.children ? "start" : "center"}
              >
                <SidebarNavItem
                  to={parentRoutePath}
                  key={_routeKey}
                  icon={route.icon}
                  name={route.name}
                  current={isCurrentParentRoute(route)}
                  iconOnly={!sidebarExpanded}
                />
              </Tooltip>
            );
          }

          return (
            <Tooltip
              key={_routeKey}
              content={
                <Text color="white" font="smMedium">
                  {route.name}
                </Text>
              }
              openOnHover={!sidebarExpanded}
              colorScheme="green"
              side="right"
              sideOffset={10}
              align={route?.children ? "start" : "center"}
            >
              <SidebarNavItem
                key={_routeKey}
                name={route.name}
                to={parentRoutePath}
                icon={route.icon}
                current={isCurrentParentRoute(route)}
                iconOnly={!sidebarExpanded}
                toggle={!ignoreChildren}
              >
                <Nav>
                  {route.children.map((subRoute, subRouteIndex) => {
                    const _subRouteKey = hyphenate(["sidebar-nav-item", routeIndex, "subroute", subRouteIndex]);
                    if (subRoute?.invisible || subRoute?.index) {
                      return null;
                    }
                    let _path = subRoute.path;
                    if (hasSubmenu(subRoute)) {
                      _path = removeAsterix(_path);
                    }
                    return (
                      <SidebarNavItem
                        key={_subRouteKey}
                        to={`${parentRoutePath}${_path}`}
                        name={subRoute.name}
                        textOnly
                        current={isCurrentParentRoute(subRoute)}
                      />
                    );
                  })}
                </Nav>
              </SidebarNavItem>
            </Tooltip>
          );
        }

        return (
          <Tooltip
            key={_routeKey}
            content={
              <Text color="white" font="smMedium">
                {route.name}
              </Text>
            }
            openOnHover={!sidebarExpanded}
            colorScheme="green"
            side="right"
            sideOffset={10}
            align={route?.children ? "start" : "center"}
          >
            <SidebarNavItem
              to={route.path}
              icon={route.icon}
              name={route.name}
              current={isCurrentParentRoute(route)}
              iconOnly={!sidebarExpanded}
            />
          </Tooltip>
        );
      })}
    </Nav>
  );
});

SidebarNav.displayName = "SidebarNav";

SidebarNav.defaultProps = {
  routes: [],
  filterRoutes(__route) {
    return true;
  },
  ignoreChildren: false,
};

export { SidebarNav };
