import React, { useEffect, useState } from "react";
import { Select } from ".";
import { useDispatch, useSelector } from "react-redux";
import { isFunction } from "utils";
import { getAllQuestionnaire, getLoadingQuestionnaire, getQuestionnaireData } from "features/v1/questionnaire";
import { getAllCrops, getCurSeasonCropsData } from "features/v1/cropsAndInput";
import { FARMER_ONBOARDING_QUESTIONNAIRE, allQuestionnaireTypes } from "utils/questionnaire-utils/questionnaire-utils";

export const QuestionnaireModelSelect = ({ onChange, name }) => {
  const [questionnaireTypeOptions, setQuestionnaireTypeOptions] = useState([]);

  const dispatch = useDispatch();

  let allQuestionnaires = useSelector(getQuestionnaireData);
  let allCrops = useSelector(getCurSeasonCropsData);

  let loading = useSelector(getLoadingQuestionnaire);

  const _handleSelectQuestionnaire = value => {
    if (isFunction(onChange)) onChange(value);
    return value;
  };

  /** get all the category types (crop names) created for current org */
  const getAllCategoryTypes = () => {
    return allCrops.map(crop => crop.name);
  };

  /** attempt to get all the category_types(crops) of currently existing Farmer Onboarding Questionnaires */
  const getAllExistingFarmerOnboardingCategoryTypes = () => {
    return (
      allQuestionnaires
        .filter(questionnaire => {
          // get all existing farmer onboarding type questionnaire
          return questionnaire.model === FARMER_ONBOARDING_QUESTIONNAIRE;
        })
        // extract their category types (crop name)
        .map(farmerOnboardingQuestionnaire => {
          return farmerOnboardingQuestionnaire.category_type;
        })
        // return an array of the category types (crop name) of all existing questionnaire
        .reduce((prevCategoryTypes, currentCategoryTypes) => {
          return [...prevCategoryTypes, ...currentCategoryTypes];
        }, [])
    );
  };

  const filterQuestionnaireTypes = () => {
    const existingFarmerOnboardingCategoryTypes = getAllExistingFarmerOnboardingCategoryTypes();
    const allCategoryTypes = getAllCategoryTypes();
    // do we have an existing farmer onboarding questionnaire for every category type (crop)
    const isAllFarmerOnboardingQuestionnaireCreated = allCategoryTypes.every(category_type =>
      existingFarmerOnboardingCategoryTypes.includes(category_type)
    );

    // if we've created onboarding questionnaires for all crops remove Farmer Onboarding from list of questionnaires to create
    if (isAllFarmerOnboardingQuestionnaireCreated) {
      setQuestionnaireTypeOptions(() =>
        allQuestionnaireTypes.filter(_questionnaireType => _questionnaireType.value !== FARMER_ONBOARDING_QUESTIONNAIRE)
      );
      return;
    }
    setQuestionnaireTypeOptions(allQuestionnaireTypes);
  };

  useEffect(() => {
    filterQuestionnaireTypes();
  }, [allQuestionnaires, allCrops]);

  useEffect(() => {
    if (!allQuestionnaires?.length) {
      dispatch(getAllQuestionnaire());
    }
  }, []);

  useEffect(() => {
    if (!allCrops?.length) {
      dispatch(getAllCrops());
    }
  }, []);
  return (
    <Select
      options={questionnaireTypeOptions}
      loading={loading}
      name={name}
      placeholder="Select a Questionnaire Type"
      onChange={_handleSelectQuestionnaire}
      label="Questionnaire Type"
    />
  );
};
