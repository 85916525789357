import { ProjectSelect, Select, selectConstants } from "components/select";
import { getRegionData } from "features/v1/regions";
import { useCallbackRef } from "hooks";
import { Box, Flex, Text } from "organic";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { isFunction } from "utils";

const recipientTypeOptions = [
  { label: "Farmers", value: "farmer" },
  { label: "Agents", value: "agent" },
];

const ChooseRecipient = ({ onSelect }) => {
  const [type, setType] = useState();
  const [project, setProject] = useState();
  const [region, setRegion] = useState();
  const [error, setError] = useState("");

  let allReqionOptions = useSelector(getRegionData);

  const onSelectCallback = useCallbackRef(onSelect, [region, type, project]);

  const filteredRegionOptions = useMemo(() => {
    // note: If sms is to agent, region and seasonId fields are compulsory
    if (type === "agent") {
      return [...allReqionOptions];
    }
    if (type === "farmer") {
      return [{ label: "All Regions", value: "all" }, ...allReqionOptions];
    }

    return [];
  }, [allReqionOptions, type]);

  useEffect(() => {
    if (type === "agents" && region === "all") {
      setError("You can't select All Regions for Agents");
    } else if (isFunction(onSelect) && type && project && region) {
      onSelectCallback({ to: type, seasonId: project, region });
    }
  }, [onSelectCallback, project, region, type]);

  return (
    <Flex flexDirection="column">
      <Flex py="24px" gap="1rem" width="100%">
        <Box width="100%">
          <Select
            required
            options={recipientTypeOptions}
            label="Recipient type"
            onChange={val => {
              setType(val);
            }}
          />
        </Box>
        <Box width="100%">
          <ProjectSelect required label="Select a project" ignoreGlobalState onChange={setProject} />
        </Box>
        <Box width="100%">
          <Select
            options={filteredRegionOptions}
            colorScheme={selectConstants.colorSchemes.DEFAULT}
            label="Select a region"
            value={undefined}
            onChange={setRegion}
            required
          />
        </Box>
      </Flex>
      <Flex>
        <Text font="xsRegular" color="grey100">
          kindly select all three options
        </Text>
        {error ? (
          <Text font="xsRegular" color="error500">
            {error}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default ChooseRecipient;
