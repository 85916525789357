/* eslint-disable no-unused-vars */
import React from "react";
// import CustomSelect from "components/v1/CustomSelect/CustomSelect";
import { createBulkContractors, createContractor, getLoadingContractors } from "features/v1/contractors";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Flex } from "organic";
import { FormInput } from "components/inputs";
import { Select } from "components/select";
import Divider from "components/v1/common/Divider";
import {
  createContractorInitialValue,
  createContractorValidationSchema,
} from "validators/contractorsValidationSchemas";
// import CSVFileSelect from "components/v1/FileSelect/UploadCsv";

function CreateContractorForm() {
  const dispatch = useDispatch();
  // let loadingContractors = useSelector(getLoadingContractors);

  const handleCreateContractor = (values, formOptions) => {
    dispatch(createContractor(values, { formOptions }));
  };

  // const handleBulkUpload = (values, __config) => {
  //   console.log({ values });
  //   dispatch(createBulkContractors(values, {}));
  // };

  return (
    <div>
      <Formik
        initialValues={createContractorInitialValue}
        validationSchema={createContractorValidationSchema}
        onSubmit={handleCreateContractor}
      >
        {({ isSubmitting, setFieldValue, errors }) => {
          return (
            <Form>
              <Flex flexDirection="column" gap="1rem">
                <Box>
                  <FormInput required name="contractor_name" label="Contractor Name" />
                </Box>
                <Box>
                  <FormInput required name="phone_number" label="Contractor Phone Number" />
                </Box>
                <Box>
                  <Select
                    onChange={val => setFieldValue("contractor_category", val)}
                    options={[
                      {
                        label: "Thresher",
                        value: "thresher",
                      },
                      {
                        label: "Transporter",
                        value: "transporter",
                      },
                    ]}
                    placeholder="Select contractor category"
                    name="contractor_category"
                    label="Contractor Category"
                    required
                  />
                </Box>

                <Button mt="2rem" type="submit" colorScheme="primary" display="block" disabled={isSubmitting}>
                  Create Contractor
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
      {/* <Divider text="OR" /> */}
      {/* <CSVFileSelect
        cols={["contractor_category", "contractor_name", "phone_number"]}
        sample={[
          {
            contractor_category: "",
            contractor_name: "",
            phone_number: "",
          },
        ]}
        name="ContractorsUpload"
        appendOrgId
        orgIdKey="organization"
        onSend={handleBulkUpload}
        loading={loadingContractors}
      /> */}
    </div>
  );
}

export default CreateContractorForm;
