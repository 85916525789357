/** Extract Info about config object passed on formSubmission
 * @param {Object} config - a config option that has been passed to form Submit handler
 *
 * config could contain any number of optional props
 *
 */
export const getSubmissionConfigOptions = (submissionConfig = {}) => {
  let hasModal = submissionConfig?.modal; // modal instance, if form was submitted from modal
  let hasFormOptions = submissionConfig?.formOptions; // formikBag / formikOptions from formik instance
  let hasFilterParams = submissionConfig?.filterParams; // custom filter data, if provided

  return {
    hasModal,
    hasFormOptions,
    hasFilterParams,
    config: submissionConfig,
  };
};
